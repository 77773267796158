import _ from 'lodash';

/**
 * @desc Returns the title an instance should have, based on the instance itself and the connector
 * @return {String}
 */
export const instanceTitle = (instance = {}, connector = {}, short = false) => {
  const instanceTitle = _.get(instance, 'title');
  const connectorType = _.get(connector, 'type');
  const instanceType = _.get(instance, 'type');
  const connectorTitle = _.get(connector, short ? 'shortTitle' : 'title');
  if(instanceTitle) return instanceTitle;
  if(connectorTitle) return connectorTitle;
  if(instanceType) return instanceType;
  if(connectorType) return connectorType;
  return '';
};

export const instanceStatusToClass = status => {
  switch(status) {
    default: return status;
    case 'setup': return 'primary';
    case 'inactive': return 'warning';
    case 'paused': return 'warning';
    case 'error': return 'danger';
    case 'active': return 'success';
  }
};

export const instanceStatusToName = status => {
  switch(status) {
    default: return status;
    case 'setup': return 'Setup';
    case 'inactive': return 'Inactive';
    case 'paused': return 'Paused';
    case 'error': return 'Error';
    case 'active': return 'Active';
  }
};

export const instanceSettingsDisabled = status => {
  return status === 'active' || status === 'inactive';
};
