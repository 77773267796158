import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const accountFormTogglePart = (type, part, show) => {
  return {type, part, show};
};

export const accountFormHideAllParts = type => {
  return {type};
};

export const accountFields = types => {
  return apiAction({
    endpoint: '/api/account',
    method: 'GET',
    types
  });
};

export const accountBilling = types => {
  return apiAction({
    endpoint: '/api/account/billing',
    method: 'GET',
    types
  });
}

export const accountUpdate = (types, form) => {
  return apiAction({
    endpoint: '/api/account',
    method: 'PATCH',
    json: form,
    types
  });
};

export const accountSupportForm = (types, json) => apiAction({
  endpoint: '/api/account/support/form',
  method: 'POST',
  types,
  json
});

export const accountCardList = types => {
  return apiAction({
    endpoint: '/api/account/card',
    method: 'GET',
    types
  });
};

export const accountCardAdd = (types, json) => {
  return apiAction({
    endpoint: '/api/account/card',
    method: 'POST',
    types,
    json
  });
};

export const accountCardDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/account/card/${id}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {id}}))
  });
};

export const accountSubscriptionIndex = (types) => {
  return apiAction({
    endpoint: '/api/account/subscription',
    method: 'GET',
    types
  });
};

export const accountSubscriptionCreate = (types, json = {}) => {
  return apiAction({
    endpoint: '/api/account/subscription',
    method: 'POST',
    types: types.map(type => ({type, meta: {subscriptionId: null}})),
    json,
  });
};

export const accountSubscriptionNew = (type) => {
  return {type, meta: {}};
};

export const accountSubscriptionChangePlanQuantity = (type, subscriptionId, planId, quantity) => {
  return {type, meta: {subscriptionId, planId, quantity}};
};

export const accountSubscriptionRemovePlan = (type, subscriptionId, planId, deleted = true) => {
  return {type, meta: {subscriptionId, planId, deleted}};
};

export const accountSubscriptionAddPlan = (type, subscriptionId, planId) => {
  return {type, meta: {subscriptionId, planId}};
};

export const accountSubscriptionUndo = (type, subscriptionId) => {
  return {type, meta: {subscriptionId}};
};

export const accountSubscriptionUpdate = (types, subscriptionId, json) => {
  return apiAction({
    endpoint: `/api/account/subscription/${subscriptionId}`,
    method: 'PATCH',
    types: types.map(type => ({type, meta: {subscriptionId}})),
    json,
  });
};

export const accountSubscriptionDelete = (types, subscriptionId) => {
  return apiAction({
    endpoint: `/api/account/subscription/${subscriptionId}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {subscriptionId}})),
  });
};

export const accountSubscriptionReadPaymentIntent = (types, subscriptionId) => {
  return apiAction({
    endpoint: `/api/account/subscription/${subscriptionId}/paymentIntent`,
    method: 'GET',
    types: types.map(type => ({type, meta: {subscriptionId}})),
  });
};

export const accountSubscriptionPreview = (types, subscriptionId, json = {}) => {
  return apiAction({
    endpoint: '/api/account/subscription/preview',
    method: 'POST',
    json,
    types: types.map(type => ({type, meta: {subscriptionId}})),
  });
};

export const accountInvoiceIndex = (types, query = {}) => {
  return apiAction({
    endpoint: appendQuery( '/api/account/invoice', query),
    method: 'GET',
    types
  });
};

export const accountInvoiceRead = (types, id) => {
  return apiAction({
    endpoint: `/api/account/invoice/${id}`,
    method: 'GET',
    types
  });
};

export const accountInstanceList = (types) => {
  return apiAction({
    endpoint: '/api/account/instance',
    method: 'GET',
    types
  });
};

export const accountChildrenRead = types => {
  return apiAction({
    endpoint: '/api/account/children',
    method: 'GET',
    types
  });
};

export const accountChildrenDelete = (types, childUserId) => {
  return apiAction({
    endpoint: `/api/account/children/${childUserId}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {childUserId}})),
  });
};
