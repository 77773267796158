import {
  INSTANCE_MWTD_TEST_DEVICE_ADD,
  INSTANCE_MWTD_TEST_CLEAR,
  INSTANCE_MWTD_TEST_DEVICE_REMOVE,
  INSTANCE_MWTD_TEST_DEVICE,
  INSTANCE_MWTD_SYNC_DEVICE,
  INSTANCE_MWTD_TEST_DEVICE_ADD_SUCCESS,
  INSTANCE_MWTD_TEST_DEVICE_SUCCESS,
  INSTANCE_MWTD_SYNC_DEVICE_SUCCESS,
} from 'client/redux/mwtd/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isAdding: false,
  searchFieldDeviceId: '',
  devices: [],
};

const defaultDevice = {
  isSyncing: false,
  isTesting: false,
  error: null,
  deviceId: null,
  topdeskAsset: null,
  mwDevice: null,
  notices: null,
  wasLast: null,
};

const getDeviceId = action => _.get(action, 'meta.deviceId');
const getDeviceIndex = (deviceId, devices = []) => devices.findIndex(device => device.deviceId === deviceId);

// reduce a single device
const reduceDevice = (state = defaultDevice, action) => {
  const successPickProps = ['notices', 'topdeskAsset', 'mwDevice', 'deviceId'];
  state = reduceByTypes(INSTANCE_MWTD_TEST_DEVICE_ADD, state, action, {requestProp: 'isAdding', successPickProps});
  state = reduceByTypes(INSTANCE_MWTD_TEST_DEVICE, state, action, {requestProp: 'isTesting', successPickProps});
  state = reduceByTypes(INSTANCE_MWTD_SYNC_DEVICE, state, action, {requestProp: 'isSyncing', successPickProps});
  if(action.type === INSTANCE_MWTD_SYNC_DEVICE_SUCCESS) state.wasLast = 'synced';
  if(action.type === INSTANCE_MWTD_TEST_DEVICE_SUCCESS) state.wasLast = 'tested';
  return state;
};

// these actions should pass to reduceDevice
const deviceActions = [
  ...INSTANCE_MWTD_TEST_DEVICE,
  ...INSTANCE_MWTD_SYNC_DEVICE,
  INSTANCE_MWTD_TEST_DEVICE_ADD_SUCCESS,
];

const mutateAt = (list, index, mutator, ...mutatorArgs) => {
  const newList = [...list];
  if(newList[index]) newList[index] = mutator(...mutatorArgs);
  return newList;
};

// reduce an array of devices
const reduceDevices = (devices = [], action) => {
  if(deviceActions.includes(action.type)) {
    const deviceIndex = getDeviceIndex(getDeviceId(action), devices);
    if(deviceIndex === -1) return [...devices, reduceDevice({...defaultDevice}, action)];
    return mutateAt(devices, deviceIndex, reduceDevice, devices[deviceIndex], action);
  }
  switch(action.type) {
    default: return devices;
    case INSTANCE_MWTD_TEST_CLEAR: return [];
    case INSTANCE_MWTD_TEST_DEVICE_REMOVE: {
      const deviceIndex = getDeviceIndex(getDeviceId(action), devices);
      const newDevices = [...devices];
      newDevices.splice(deviceIndex, 1);
      return newDevices;
    }
  }
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_MWTD_TEST_DEVICE_ADD, state, action, {requestProp: 'isAdding', successPickProps: []});
  return {
    ...state,
    devices: reduceDevices(state.devices, action),
  };
};
