import React, { Component } from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { paymentMethodSelect2, paranoidSelect2 } from 'client/redux/user/invoice/constants';
import { UserIdSearcher } from 'client/components/Form/Select2Searchers';
import DateRangePickerWrap from 'client/components/Form/DateRangePickerWrap';
import 'react-bootstrap-daterangepicker/css/daterangepicker.css';

export default class UserInvoiceTableFilterForm extends Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  handleChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render() {
    const { status, userId, createdAtStart, createdAtEnd, paranoid } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <DateRangePickerWrap
              startName="createdAtStart"
              startValue={createdAtStart}
              endName="createdAtEnd"
              endValue={createdAtEnd}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="userInvoiceTableFilterPaymentMethod"
              name="paymentMethod"
              className="form-control"
              data={paymentMethodSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any payment method'}}
              onChange={this.handleChange}
              defaultValue={status}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <UserIdSearcher
              id="userInvoiceTableFilterUserId"
              className="form-control"
              name="userId"
              onChange={val => this.handleChange({target: {name: 'userId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any user'}}
              defaultValue={userId}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="userInvoiceTableFilterParanoid"
              name="paranoid"
              className="form-control"
              data={paranoidSelect2}
              options={{minimumResultsForSearch: 20}}
              onChange={this.handleChange}
              defaultValue={paranoid}
            />
          </div>
        </div>
      </div>
    );
  }

}

