import propTypes from 'prop-types';

export const codeSettingsShape = propTypes.shape({
  topText: propTypes.string,
  topTextShow: propTypes.bool,
  bottomText: propTypes.string,
  bottomTextShow: propTypes.bool,
  qrCodeBgColor: propTypes.string,
  qrCodeFgColor: propTypes.string,
  qrCodeBgExtend: propTypes.bool,
  features: propTypes.arrayOf(propTypes.string),
  fieldMapOrdinal: propTypes.string,
  infoTextOrdinal: propTypes.string,
});
