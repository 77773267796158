import React from 'react';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import { topdeskFieldNameToNatural } from 'client/redux/field/constants';
import FieldMapTargetFields from 'client/components/FieldMap/FieldMapTargetFields';

const availableSources = [
  {type: 'FieldSourceToken'},
  {type: 'FieldSourceResolve'},
  {
    type: 'FieldSourceObject',
    contextKeys: ['form'],
    columns: [
      'personalNumber',
      'personIsVerified',
      'anonymous',
      'firstName',
      'lastName',
      'street',
      'postcode',
      'city',
      'request',
      'relativesStringList',
    ],
  },
  {type: 'FieldSourceUserInput'},
  {type: 'FieldSourceUserSelect'},
  {type: 'FieldSourceUserSelectFromResolver'},
];

export class PVTDOptionsIncidentFieldMap extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    endpointIds: propTypes.object.isRequired,
    isReading: propTypes.bool.isRequired,
    fieldMap: propTypes.object,
    disabled: propTypes.bool,
  }

  render() {
    const { isReading, fieldMap, disabled, endpointIds } = this.props;
    return (
      <>
        <h3 className="wizard-title md-mb-10 xs-mb-5 xs-mt-20 md-mt-5">
          TOPdesk Incident Field Map
          <span className="panel-subtitle">
            Determine how the new incident is created in TOPdesk
          </span>
        </h3>
        <div className="form-horizontal">
          <BeLoading loading={isReading}>
            {fieldMap && (
              <FieldMapTargetFields
                fieldMap={fieldMap}
                fieldName="incidentFieldMap"
                fieldBase="pvtdOptionsIncidentFieldMap"
                disabled={disabled}
                availableSources={availableSources}
                getLabel={topdeskFieldNameToNatural}
                endpointIds={endpointIds}
              />
            )}
          </BeLoading>
        </div>
      </>
    );
  }

}

export class PVTDOptionsBranchFieldMap extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    endpointIds: propTypes.object.isRequired,
    isReading: propTypes.bool.isRequired,
    fieldMap: propTypes.object,
    disabled: propTypes.bool,
  }

  render() {
    const { isReading, fieldMap, disabled, endpointIds } = this.props;
    return (
      <>
        <h3 className="wizard-title md-mb-10 xs-mb-5 xs-mt-20 md-mt-5">
          TOPdesk Branch Field Map
          <span className="panel-subtitle">
            Determine how the branch is managed in TOPdesk
          </span>
        </h3>
        <div className="form-horizontal">
          <BeLoading loading={isReading}>
            {fieldMap && (
              <FieldMapTargetFields
                fieldMap={fieldMap}
                fieldName="branchFieldMap"
                fieldBase="pvtdOptionsBranchFieldMap"
                disabled={disabled}
                availableSources={availableSources}
                getLabel={topdeskFieldNameToNatural}
                endpointIds={endpointIds}
              />
            )}
          </BeLoading>
        </div>
      </>
    );
  }

}

export class PVTDOptionsPersonFieldMap extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    endpointIds: propTypes.object.isRequired,
    isReading: propTypes.bool.isRequired,
    fieldMap: propTypes.object,
    disabled: propTypes.bool,
  }

  render() {
    const { isReading, fieldMap, disabled, endpointIds } = this.props;
    return (
      <>
        <h3 className="wizard-title md-mb-10 xs-mb-5 xs-mt-20 md-mt-5">
          TOPdesk Person Field Map
          <span className="panel-subtitle">
            Determine how the person is managed in TOPdesk
          </span>
        </h3>
        <div className="form-horizontal">
          <BeLoading loading={isReading}>
            {fieldMap && (
              <FieldMapTargetFields
                fieldMap={fieldMap}
                fieldName="personFieldMap"
                fieldBase="pvtdOptionsPersonFieldMap"
                disabled={disabled}
                availableSources={availableSources}
                getLabel={topdeskFieldNameToNatural}
                endpointIds={endpointIds}
              />
            )}
          </BeLoading>
        </div>
      </>
    );
  }

}
