import React from 'react';
import propTypes from 'prop-types';
import { ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';

export default class ButtonSoftDeleteConfirmModal extends React.Component {

  static propTypes = {
    anchor: propTypes.bool.isRequired,
    text: propTypes.bool.isRequired,
    id: propTypes.string.isRequired,
    onDelete: propTypes.func.isRequired,
    isSoftDeleted: propTypes.bool.isRequired,
  }

  static defaultProps = {
    anchor: false,
    text: false,
    className: 'btn btn-default',
  }

  handleCallback = () => {
    const { onDelete, id, isSoftDeleted } = this.props;
    return onDelete(id, isSoftDeleted);
  }

  render() {
    const { isSoftDeleted, anchor, text, className } = this.props;
    const modalText = isSoftDeleted ? 'Are you sure you want to permanently delete this object?' : 'Are you sure you want to archive this object?'
    const confirmProps = {title: `Confirm object ${isSoftDeleted ? 'deletion' : 'archival'}`, modalText};
    if(isSoftDeleted) {
      confirmProps.classNameSuffix = 'danger';
    }
    return (
      <ModalConfirmButton
        className={className}
        anchor={anchor}
        callback={this.handleCallback}
        contentProps={confirmProps}
        Content={ModalContentConfirmWarning}
      >
        {isSoftDeleted && (
          <span>
            <i className="icon mdi mdi-delete"></i>
            {text && 'Delete'}
          </span>
        )}
        {!isSoftDeleted && (
          <span>
            <i className="icon mdi mdi-block"></i>
            {text && 'Archive'}
          </span>
        )}
      </ModalConfirmButton>
    );
  }

}
