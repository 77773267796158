import React, { Component } from 'react';
import propTypes from 'prop-types';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import SupportButtonDirection from 'client/components/Support/components/SupportButtonDirection';
import classNames from 'classnames';
import { Placeholder } from 'client/components/Helpers/Strings';
import _ from 'lodash';

export default class SupportQuestionPanel extends Component {

  static propTypes = {
    question: propTypes.object.isRequired,
    onUpdateQuestion: propTypes.func.isRequired,
    onDeleteQuestion: propTypes.func.isRequired,
    isLastQuestion: propTypes.bool.isRequired,
    isFirstQuestion: propTypes.bool.isRequired
  }

  state = {
    isEditing: false,
    title: '',
    markdown: '',
  }

  handleClickEdit = ev => {
    this.setState({isEditing: true});
  }

  handleClickToggleVisible = ev => {
    const { question, onUpdateQuestion } = this.props;
    const update = {isVisible: !question.isVisible};
    return onUpdateQuestion(question.id, update);
  }

  handleChange = ev => {
    this.setState({[ev.target.name]: ev.target.value});
  }

  handleUpdate = ev => {
    const { question, onUpdateQuestion } = this.props;
    const update = {title: this.state.title, markdown: this.state.markdown};
    return onUpdateQuestion(question.id, update).then(() => {
      setTimeout(() => {
        this.setState({isEditing: false});
      }, 1);
    });
  }

  componentDidMount() {
    const { question } = this.props;
    this.setState({title: question.title, markdown: question.markdown});
  }

  renderEditButtons() {
    if(this.state.isEditing) {
      return (
        <ButtonWaitingPromise className="btn btn-success btn-icon" onClick={this.handleUpdate}>
          <i className="icon mdi mdi-check"></i>{' '}
          Save
        </ButtonWaitingPromise>
      );
    } 
    return (
      <button className="btn btn-default btn-icon" onClick={this.handleClickEdit}>
        <i className="icon mdi mdi-edit"></i>
      </button>
    );
  }

  renderVisibleButton() {
    const isVisible = _.get(this.props, 'question.isVisible', false);
    const icon = isVisible ? 'mdi-eye-off' : 'mdi-eye';
    const text = isVisible ? 'Make invisible' : 'Make visible';
    return (
      <ButtonWaitingPromise className="btn btn-default btn-icon" onClick={this.handleClickToggleVisible}>
        <i className={classNames('icon mdi', icon)}></i>{' '}
        {text}
      </ButtonWaitingPromise>
    );
  }

  renderInner() {
    const { question } = this.props;
    if(this.state.isEditing) {
      const { title, markdown } = this.state;
      return (
        <div className="panel-body-inner">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="title"
              value={title}
              placeholder="Enter title"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              name="markdown"
              value={markdown}
              placeholder="Enter markdown"
              onChange={this.handleChange}
              rows="15"
            ></textarea>
          </div>
        </div>
      );
    }

    return (
      <div className="panel-body-inner">
        <Placeholder isEmpty={!question.html} placeholder={<p><em>(No body)</em></p>}>
          <div dangerouslySetInnerHTML={{__html: question.html}}></div>
        </Placeholder>
      </div>
    );
  }

  render() {
    const { question, isLastQuestion, isFirstQuestion, onUpdateQuestion, onDeleteQuestion } = this.props;
    return (
      <div>
        {this.renderInner()}
        <IsAdmin>
          <div className="panel-body-inner panel-body-actions clearfix">
            <div className="pull-right">
              {this.renderEditButtons()}{' '}
              {this.renderVisibleButton()}{' '}
              <SupportButtonDirection
                direction="up"
                disabled={isFirstQuestion}
                onClick={ev => onUpdateQuestion(question.id, {order: question.order - 1})}
              />{' '}
              <SupportButtonDirection
                direction="down"
                disabled={isLastQuestion}
                onClick={ev => onUpdateQuestion(question.id, {order: question.order + 1})}
              />{' '}
              <ButtonWaitingPromise className="btn btn-default btn-icon" onClick={ev => onDeleteQuestion(question.id)}>
                <i className="icon mdi mdi-delete"></i>
              </ButtonWaitingPromise>
            </div>
          </div>
        </IsAdmin>
      </div>
    );
  }

}
