import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import OPTDOptionsForm from 'client/components/OPTD/components/OPTDOptionsForm';
import OPTDTester from 'client/components/OPTD/components/OPTDTester';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import { connect } from 'react-redux';
import { instanceOptdTestClear } from 'client/redux/optd/testActions';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class OPTDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  componentWillUnmount() {
    // clear everything from the test when we unmount
    this.props.instanceOptdTestClear();
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>OpManager → TOPdesk</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={OPTDOptionsForm}
            TesterComponent={OPTDTester}
            endpointOrder={['opmanager', 'topdesk']}
          />
        </MainContent>
      </Content>
    );
  }

}

export default connect(null, { instanceOptdTestClear })(OPTDSetup);
