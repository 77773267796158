import {
  INSTANCE_AITD_TEST_INCIDENT_ADD,
  INSTANCE_AITD_TEST_CLEAR,
  INSTANCE_AITD_TEST_INCIDENT_REMOVE,
  INSTANCE_AITD_SYNC_INCIDENT,
  INSTANCE_AITD_TEST_INCIDENT_ADD_SUCCESS,
  INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH,
} from 'client/redux/aitd/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isAdding: false,
  searchFieldIncidentId: '',
  incidents: [],
};

const defaultIncident = {
  isSyncing: false,
  isGettingObjectGraph: false,
  error: null,
  objectGraph: null,
  topdeskIncidentId: null,
  topdeskIncident: null,
  notices: null,
};

const getIncidentId = action => _.get(action, 'meta.topdeskIncidentId');
const getIncidentIndex = (topdeskIncidentId, incidents = []) => incidents.findIndex(incident => incident.topdeskIncident?.id === topdeskIncidentId);

// reduce a single topdeskIncident
const reduceIncident = (state = defaultIncident, action) => {
  const successPickProps = ['notices', 'topdeskIncident', 'topdeskIncidentId'];
  state = reduceByTypes(INSTANCE_AITD_TEST_INCIDENT_ADD, state, action, {requestProp: 'isAdding', successPickProps});
  state = reduceByTypes(INSTANCE_AITD_SYNC_INCIDENT, state, action, {
    requestProp: (state, action, isSyncing) => ({
      isSyncing,
      notices: null,
    }),
    successPickProps,
  });
  state = reduceByTypes(INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH, state, action, {
    requestProp: 'isGettingObjectGraph',
    successProp: 'objectGraph',
  });
  return state;
};

// these actions should pass to reduceIncident
const topdeskIncidentActions = [
  ...INSTANCE_AITD_SYNC_INCIDENT,
  ...INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH,
  INSTANCE_AITD_TEST_INCIDENT_ADD_SUCCESS,
];

const mutateAt = (list, index, mutator, ...mutatorArgs) => {
  const newList = [...list];
  if(newList[index]) newList[index] = mutator(...mutatorArgs);
  return newList;
};

// reduce an array of topdeskIncidents
const reduceIncidents = (topdeskIncidents = [], action) => {
  if(topdeskIncidentActions.includes(action.type)) {
    const topdeskIncidentIndex = getIncidentIndex(getIncidentId(action), topdeskIncidents);
    if(topdeskIncidentIndex === -1) return [...topdeskIncidents, reduceIncident({...defaultIncident}, action)];
    return mutateAt(topdeskIncidents, topdeskIncidentIndex, reduceIncident, topdeskIncidents[topdeskIncidentIndex], action);
  }
  switch(action.type) {
    default: return topdeskIncidents;
    case INSTANCE_AITD_TEST_CLEAR: return [];
    case INSTANCE_AITD_TEST_INCIDENT_REMOVE: {
      const topdeskIncidentIndex = getIncidentIndex(getIncidentId(action), topdeskIncidents);
      const newIncidents = [...topdeskIncidents];
      newIncidents.splice(topdeskIncidentIndex, 1);
      return newIncidents;
    }
  }
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_AITD_TEST_INCIDENT_ADD, state, action, {requestProp: 'isAdding', successPickProps: []});
  return {
    ...state,
    topdeskIncidents: reduceIncidents(state.topdeskIncidents, action),
  };
};
