export const INSTANCE_MWTD_READ_STATISTICS_REQUEST = 'INSTANCE_MWTD_READ_STATISTICS_REQUEST';
export const INSTANCE_MWTD_READ_STATISTICS_SUCCESS = 'INSTANCE_MWTD_READ_STATISTICS_SUCCESS';
export const INSTANCE_MWTD_READ_STATISTICS_FAILURE = 'INSTANCE_MWTD_READ_STATISTICS_FAILURE';
export const INSTANCE_MWTD_READ_STATISTICS = [INSTANCE_MWTD_READ_STATISTICS_REQUEST, INSTANCE_MWTD_READ_STATISTICS_SUCCESS, INSTANCE_MWTD_READ_STATISTICS_FAILURE];

export const INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET = 'INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET';

export const INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST = 'INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST';
export const INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS = 'INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS';
export const INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE = 'INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE';
export const INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ = [INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST, INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS, INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE];

export const INSTANCE_MWTD_OPTIONS_READ_META_REQUEST = 'INSTANCE_MWTD_OPTIONS_READ_META_REQUEST';
export const INSTANCE_MWTD_OPTIONS_READ_META_SUCCESS = 'INSTANCE_MWTD_OPTIONS_READ_META_SUCCESS';
export const INSTANCE_MWTD_OPTIONS_READ_META_FAILURE = 'INSTANCE_MWTD_OPTIONS_READ_META_FAILURE';
export const INSTANCE_MWTD_OPTIONS_READ_META = [INSTANCE_MWTD_OPTIONS_READ_META_REQUEST, INSTANCE_MWTD_OPTIONS_READ_META_SUCCESS, INSTANCE_MWTD_OPTIONS_READ_META_FAILURE];

export const INSTANCE_MWTD_TEST_DEVICE_ADD_REQUEST = 'INSTANCE_MWTD_TEST_DEVICE_ADD_REQUEST';
export const INSTANCE_MWTD_TEST_DEVICE_ADD_SUCCESS = 'INSTANCE_MWTD_TEST_DEVICE_ADD_SUCCESS';
export const INSTANCE_MWTD_TEST_DEVICE_ADD_FAILURE = 'INSTANCE_MWTD_TEST_DEVICE_ADD_FAILURE';
export const INSTANCE_MWTD_TEST_DEVICE_ADD = [INSTANCE_MWTD_TEST_DEVICE_ADD_REQUEST, INSTANCE_MWTD_TEST_DEVICE_ADD_SUCCESS, INSTANCE_MWTD_TEST_DEVICE_ADD_FAILURE];

export const INSTANCE_MWTD_TEST_DEVICE_REMOVE = 'INSTANCE_MWTD_TEST_DEVICE_REMOVE';
export const INSTANCE_MWTD_TEST_CLEAR = 'INSTANCE_MWTD_TEST_CLEAR';

export const INSTANCE_MWTD_TEST_DEVICE_REQUEST = 'INSTANCE_MWTD_TEST_DEVICE_REQUEST';
export const INSTANCE_MWTD_TEST_DEVICE_SUCCESS = 'INSTANCE_MWTD_TEST_DEVICE_SUCCESS';
export const INSTANCE_MWTD_TEST_DEVICE_FAILURE = 'INSTANCE_MWTD_TEST_DEVICE_FAILURE';
export const INSTANCE_MWTD_TEST_DEVICE = [INSTANCE_MWTD_TEST_DEVICE_REQUEST, INSTANCE_MWTD_TEST_DEVICE_SUCCESS, INSTANCE_MWTD_TEST_DEVICE_FAILURE];

export const INSTANCE_MWTD_SYNC_DEVICE_REQUEST = 'INSTANCE_MWTD_SYNC_DEVICE_REQUEST';
export const INSTANCE_MWTD_SYNC_DEVICE_SUCCESS = 'INSTANCE_MWTD_SYNC_DEVICE_SUCCESS';
export const INSTANCE_MWTD_SYNC_DEVICE_FAILURE = 'INSTANCE_MWTD_SYNC_DEVICE_FAILURE';
export const INSTANCE_MWTD_SYNC_DEVICE = [INSTANCE_MWTD_SYNC_DEVICE_REQUEST, INSTANCE_MWTD_SYNC_DEVICE_SUCCESS, INSTANCE_MWTD_SYNC_DEVICE_FAILURE];
