import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import Icon from 'client/components/Be/Icon';
import LinkQuery from 'client/components/Helpers/LinkQuery';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import { instanceDashboardRead } from 'client/redux/instance/dashboardActions';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import classNames from 'classnames';
import InstanceOptions from 'client/components/Instance/InstanceOptions';
import InstanceEndpoints from 'client/components/Instance/InstanceEndpoints';
import InstanceEventTable from 'client/components/Instance/components/InstanceEventTable';
import InstanceTrialAlert from 'client/components/Instance/components/InstanceTrialAlert';
import { connect } from 'react-redux';
import _ from 'lodash';

class InstanceDashboard extends React.Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    logs: propTypes.array,
    instance: propTypes.object,
    subscription: propTypes.object,
    connector: propTypes.object,
    endpointsByType: propTypes.object,
    endpointOrder: propTypes.array,
    tab: propTypes.string.isRequired,
    onSelectTab: propTypes.func.isRequired,
    OptionsFormComponent: propTypes.elementType,
    optionsFormComponentProps: propTypes.object,
    TesterComponent: propTypes.elementType,
    tabOrder: propTypes.array,
    tabComponents: propTypes.object,
  }

  defaultTabOrder = [
    {name: 'auth', displayName: 'Credentials', icon: 'mdi-account-box-o'},
    {name: 'options', displayName: 'Options', icon: 'mdi-settings'},
    {name: 'test', displayName: 'Test', icon: 'mdi-swap-alt'},
    {name: 'events', displayName: 'Events', icon: 'mdi-alert-circle-o'},
  ]

  getData(id) {
    return this.props.instanceDashboardRead(id);
  }

  componentDidMount() {
    this.getData(this.props.id);
  }

  handleEndpointUpdated = () => {
    return this.getData(this.props.id);
  }

  tabIsActive(compareTab) {
    const { tab } = this.props;
    return tab === compareTab;
  }

  tabPaneClasses(tab, extraClasses = '') {
    return classNames('tab-pane', 'cont', this.tabIsActive(tab) ? 'active' : '', extraClasses);
  }

  renderTab(key) {
    const {
      isReading,
      error,
      endpointsByType,
      instance,
      connector,
      OptionsFormComponent,
      optionsFormComponentProps,
      TesterComponent,
      logs,
      endpointOrder,
      optionalEndpoints,
      tabComponents = {},
    } = this.props;

    if(!this.tabIsActive(key) || isReading || error || !instance || !endpointsByType) {
      return null;
    }

    // properties common to most tabs
    const props = {
      instance,
      endpointsByType,
      isSetupMode: false,
    };

    switch(key) {
      default: {
        const stepComponentCreator = tabComponents[key];
        if(!stepComponentCreator) return <div>Nothing here yet</div>;
        return stepComponentCreator(props);
      }

      case 'auth': return <InstanceEndpoints
        {...props}
        endpointOrder={endpointOrder}
        optionalEndpoints={optionalEndpoints}
        onEndpointUpdated={this.handleEndpointUpdated}
      />

      case 'options': return <InstanceOptions
        instanceId={instance.id}
        {...props}
        instanceStatus={instance.status}
        initialValues={instance.settings}
        connectorType={connector.type}
        FormComponent={OptionsFormComponent}
        formComponentProps={optionsFormComponentProps}
      />

      case 'test': return <TesterComponent {...props} />

      case 'events': return <InstanceEventTable logs={logs} />

    }
  }

  renderEventsNavItem() {
    return (
      <NavItem eventKey={'events'} title="Events">
        <span className="icon mdi mdi-alert-circle-o"></span>
        Events
      </NavItem>
    );
  }

  getTabList() {
    const { tabOrder } = this.props;
    const { defaultTabOrder } = this;
    const tabs = tabOrder || defaultTabOrder;
    return tabs.map(tab => {
      const defaultTab = defaultTabOrder.find(({name}) => name === tab.name);
      if(defaultTab) return {...defaultTab, ...tab};
      return {...tab};
    });
  }

  renderTabNavItems() {
    return this.getTabList().map(tab => {
      const { name, icon, displayName } = tab;
      return (
        <NavItem key={name} eventKey={name} title={displayName}>
          <span className={`icon mdi ${icon}`}></span>
          {displayName}
        </NavItem>
      );
    });
  }

  renderTabBody() {
    return this.getTabList().map(tab => {
      const { name } = tab;
      let tabInnerPaddingClasses = 'xs-pb-20 xs-pt-20 xs-pl-30 xs-pr-30';
      if(name === 'events') tabInnerPaddingClasses = '';
      return (
        <div key={name} className={this.tabPaneClasses(name, tabInnerPaddingClasses)}>
          {this.renderTab(name)}
        </div>
      );
    });
  }

  render() {
    const { error, tab, isReading, onSelectTab, subscription, instance } = this.props;
    return (
      <div>
        {subscription && instance && <InstanceTrialAlert subscription={subscription} instanceStatus={instance.status} />}
        <div className="panel panel-default panel-border">
          <div className="panel-heading" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>Control panel</div>
            {instance && (
              <IsAdmin>
                <div>
                  <LinkQuery
                    className="btn btn-default xs-mr-5"
                    to="/instance"
                    query={{id: instance.id}}
                    title="Search for instance"
                  >
                    <Icon icon="search" />
                  </LinkQuery>
                </div>
              </IsAdmin>
            )}
          </div>
          <div className="tab-container">
            <Nav bsStyle="tabs" activeKey={tab} onSelect={onSelectTab}>
              {this.renderTabNavItems()}
            </Nav>
            <BeLoading className="tab-content xs-p-0 xs-m-0" loading={isReading}>
              <BeRemoteErrorAlert error={error} />
              {this.renderTabBody()}
            </BeLoading>
          </div>
        </div>
      </div>
    );
  }

}

const actions = {
  instanceDashboardRead,
};

const mapStateToProps = state => ({
  ...state.instance.dashboard,
  currentUserRole: _.get(state, 'ui.self.role', 'nobody'),
});

export default connect(mapStateToProps, actions)(InstanceDashboard);
