import { apiAction } from 'client/redux/apiHelpers';

export const endpointOpmanagerRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/opmanager/${id}`,
    method: 'GET',
    types
  });
};

export const endpointOpmanagerEditCredentials = (type, isEditing) => {
  return {
    type,
    isEditing
  };
};

export const endpointOpmanagerUpdateCredentials = (types, id, credentials) => {
  return apiAction({
    endpoint: `/api/endpoint/opmanager/${id}/credentials`,
    method: 'PATCH',
    types,
    json: credentials
  });
};

export const endpointOpmanagerTestCredentials = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/opmanager/${id}/credentials/test`,
    method: 'POST',
    types,
    json: {}
  });
};

export const endpointOpmanagerAssetTemplateFieldTargets = (types, id, assetTemplateId) => apiAction({
  endpoint: `/api/endpoint/opmanager/${id}/assetTemplate/${assetTemplateId}/fieldTargets`,
  method: 'GET',
  types, // NOTE: Do not map meta here
});
