import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import { USER_INVOICE_DELETE_SUCCESS } from 'client/redux/user/invoice/constants';
import _ from 'lodash';

export const onUserInvoiceDelete = deleteActionResult => dispatch => {
  if(deleteActionResult) {
    const { type, meta } = deleteActionResult;
    if(type === USER_INVOICE_DELETE_SUCCESS) {
      const wasForced = _.get(meta, 'query.forced') === '1';
      const message = wasForced ? 'The invoice was permanentely deleted' : 'The invoice was deleted';
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'user_invoice_delete_success', {
        className: 'color warning',
        title: 'Deletion succeded',
        message,
        closeAfter: 3000
      }));
    }
  }
};
