import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm } from 'redux-form';
import { isNotEmpty, isString, isURL } from 'client/redux/validators';
import { FormErrors } from 'client/components/Form/FormErrors';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';

// redux-form unregister/register bug
const validateByField = {
  apiUrl: [isString(), isNotEmpty(), isURL()],
  username: [isString(), isNotEmpty()],
  password: [isString(), isNotEmpty()]
};

class EndpointFastForm extends PureComponent {

  static defaultProps = {
    isEditing: false,
    onEdit: function() {}
  }

  static propTypes = {
    isEditing: propTypes.bool.isRequired,
    onEdit: propTypes.func,
    remoteError: propTypes.object,
    initialValues: propTypes.object.isRequired,
    endpointState: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onTest: propTypes.func.isRequired,
    isTesting: propTypes.bool.isRequired,
    isUpdating: propTypes.bool.isRequired
  }

  hasInitialValues() {
    const { initialValues } = this.props;
    return Boolean(initialValues.username && initialValues.password && initialValues.apiUrl);
  }

  isDisabled() {
    return this.hasInitialValues() && !this.props.isEditing;
  }

  fieldProps = {base: 'endpointFastForm', metaOptions: {defaultState: null, ignorePristine: true}}

  render() {
    const { isTesting, remoteError } = this.props;
    const { testOk, testTimestamp } = this.props.endpointState;
    const isDisabled = this.isDisabled();
    return (
			<Form className="group-border-dashed" onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <FormErrors className="xs-mt-0 xs-mb-10" error={remoteError} />
        <div>
          <Field
            {...this.fieldProps}
            name="apiUrl"
            component={FormReduxInput}
            type="text"
            label="FastAPI URL"
            disabled={isDisabled}
            validate={validateByField.apiUrl}
            helpText="Enter a https URL to the API"
          />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Field
              {...this.fieldProps}
              name="username"
              component={FormReduxInput}
              type="text"
              label="Username"
              disabled={isDisabled}
              placeholder="Enter FastAPI username"
              validate={validateByField.username}
            />
          </div>
          <div className="col-lg-6">
            <Field
              {...this.fieldProps}
              name="password"
              component={FormReduxInput}
              type="password"
              label="Password"
              disabled={isDisabled}
              placeholder="Enter FastAPI password"
              validate={validateByField.password}
            />
          </div>
        </div>
        <div className="form-group xs-mb-0">
          <EndpointTestButton
            isTesting={isTesting}
            isDisabled={!isDisabled}
            onTest={this.props.onTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
          />{' '}
          {this.renderSubmitButton()}
          <div className="clearfix"></div>
        </div>
      </Form>
    );
  }

  renderSubmitButton() {
    const { isUpdating, valid, onEdit } = this.props;
    if(this.isDisabled()) {
      return <button className="btn btn-default pull-right" type="button" onClick={onEdit}>Edit credentials</button>;
    } else {
      return (
        <ButtonWaiting type="submit" disabled={!valid} className="btn btn-default pull-right" isWaiting={isUpdating}>
          Save credentials
        </ButtonWaiting>
      );
    }
  }

}

export default reduxForm({
  form: 'endpointFast',
  enableReinitialize: true
})(EndpointFastForm);
