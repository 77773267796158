import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import BeLoading from 'client/components/Be/BeLoading';
import QRTDCodeCreatorLocationTableFilter from 'client/components/QRTD/QRTDCodeCreatorLocationTableFilter';
import { ModalObjectInspectorButton } from 'client/components/Helpers/Modal';
import {
  TableCheckbox,
  TableCheckboxManagerControlled,
} from 'client/components/Helpers/Table';

class QRTDCodeCreatorLocationTable extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    locations: propTypes.array.isRequired,
    loading: propTypes.bool.isRequired,
    filter: propTypes.object.isRequired,
  }

  static defaultProps = {
    locations: [],
  }

  render() {
    const {
      className,
      handleSelectAll,
      handleSelectRow,
      isAllSelected,
      isRowSelected,
      loading,
      onFilter,
      filter,
      locations,
      endpointTopdeskId,
    } = this.props;
    return (
      <div className={classNames('panel-table', className)}>
        <div className="panel-heading panel-heading-divider">
          Locations in TOPdesk
        </div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <QRTDCodeCreatorLocationTableFilter
              endpointTopdeskId={endpointTopdeskId}
              onFilter={onFilter}
              filter={filter}
            />
            <div className="be-datatable-body no-overflow">
              <BeLoading loading={loading}>
                <table className="table table-striped table-hover dataTable">
                  <thead>
                    <tr>
                      <th>
                        <TableCheckbox
                          id="qrCodeCreatorTableSelectAllLocations"
                          onSelectAll={handleSelectAll}
                          isSelected={isAllSelected()}
                          beagle={false}
                        />
                      </th>
                      <th>Name/ID</th>
                      <th>Branch</th>
                      <th>Room Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="no-border-x">
                    {locations.length < 1 && <TableEmptyRow numCols={5} />}
                    {locations.length > 0 && locations.map((location, index) => (
                      <TableRow
                        key={location.id}
                        tabIndex={index + 1}
                        location={location}
                        handleSelectRow={handleSelectRow}
                        isSelected={isRowSelected(location.id)}
                      />
                    ))}
                  </tbody>
                </table>
              </BeLoading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function TableEmptyRow({numCols}) {
  return (
    <tr>
      <td className="empty" colSpan={numCols}>
        No locations to show
      </td>
    </tr>
  );
}

class TableRow extends React.PureComponent {
  render() {
    const { location, handleSelectRow, isSelected, tabIndex } = this.props;
    const branchName = _.get(location, 'branch.name');
    return (
      <tr key={location.id}>
        <td>
          <TableCheckbox
            base="qrCodeCreatorTableSelectAllLocations"
            id={location.id}
            onSelect={handleSelectRow}
            isSelected={isSelected}
            beagle={false}
            tabIndex={tabIndex}
          />
        </td>
        <td>{location.name}</td>
        <td>{branchName}</td>
        <td>{location.roomNumber}</td>
        <td className="text-right">
          <ModalObjectInspectorButton
            object={location}
            title={location.name}
          />
        </td>
      </tr>
    );
  }
}

export default TableCheckboxManagerControlled(QRTDCodeCreatorLocationTable, {
  allIds: function(props) {
    const locations = props.locations || [];
    return locations.map(location => _.get(location, 'id'));
  },
});
