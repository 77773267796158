import React, { Component } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

const stringOrDate = propTypes.oneOfType([
  propTypes.string,
  propTypes.instanceOf(Date)
]);

export default class Time extends Component {

  static propTypes = {
    time: stringOrDate,
    format: propTypes.string
  }

  static defaultProps = {
    format: 'YYYY-MM-DD HH:mm:ss'
  }

  render() {
    const { time, format } = this.props;
    const date = moment(time);
    if(!date.isValid()) return '<invalid date>';
    return (
      <time
        title={date.toString()}
        dateTime={date.toString()}>
        {date.format(format)}
      </time>
    );
  }

}

/**
 * @desc Handles Stripe UNIX second format
 */
export class StripeTime extends Time {

  static propTypes = {
    time: propTypes.number,
    format: propTypes.string,
  }

  render() {
    const { time, format } = this.props;
    const date = moment(time*1000);
    if(!date.isValid()) return '<invalid date>';
    return <time dateTime={date.toString()}>{date.format(format)}</time>
  }

}

/**
 * @desc Prints the billing interval for a Stripe Subscription, i.e. "25th every month" or "January 28th every year
 */
export class StripeBillingInterval extends React.Component {

  static propTypes = {
    anchor: propTypes.number,
    interval: propTypes.string,
  }

  render() {
    const { anchor, interval } = this.props;
    const date = moment(anchor * 1000);
    if(!date.isValid()) return '<invalid date>';
    switch(interval) {
      default: return `${date.format('YYYY-MM-DD')} every ${interval}`;
      case 'month': return `the ${date.format('Do')} every month`;
      case 'year': return `${date.format('MMMM Do')} every year`;
    }
  }

}

export class TimeRelative extends Component {

  static propTypes = {
    time: stringOrDate
  }

  render () {
    const { time } = this.props;
    const date = moment(time);
    return <time dateTime={date.toString()}>{date.fromNow()}</time>
  }

}
