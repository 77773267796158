import * as actionCreators from 'client/redux/endpoint/skatteverket/actionCreators';
import * as constants from 'client/redux/endpoint/skatteverket/constants';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';
import { instanceOptionalEndpointDelete } from 'client/redux/instance/actionCreators';

export const endpointSkatteverketPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(constants.ENDPOINT_SKATTEVERKET_PRELOADED, endpoint));
};

export const endpointSkatteverketRead = id => dispatch => {
  return dispatch(actionCreators.endpointSkatteverketRead(constants.ENDPOINT_SKATTEVERKET_READ, id));
};

export const endpointSkatteverketDelete = (instanceId, endpointType) => dispatch => {
  return dispatch(instanceOptionalEndpointDelete(constants.ENDPOINT_SKATTEVERKET_DELETE, instanceId, endpointType));
};

export const endpointSkatteverketEditSettings = isEditing => dispatch => {
  return dispatch(actionCreators.endpointSkatteverketEditSettings(constants.ENDPOINT_SKATTEVERKET_EDIT_SETTINGS, isEditing));
};

export const endpointSkatteverketUpdateSettings = (id, settings) => dispatch => {
  return dispatch(actionCreators.endpointSkatteverketUpdateSettings(constants.ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS, id, settings));
};

export const endpointSkatteverketTest = (id) => dispatch => {
  return dispatch(actionCreators.endpointSkatteverketTest(constants.ENDPOINT_SKATTEVERKET_TEST, id));
};
