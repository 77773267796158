import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { LOGOUT_SUCCESS, AUTH_SWITCH_USER_SUCCESS, AUTH_SWITCH_USER_RETURN_SUCCESS } from 'client/redux/auth/constants';
import account from 'client/redux/account/rootReducer';
import aitd from 'client/redux/aitd/rootReducer';
import grtd from 'client/redux/grtd/rootReducer';
import fstd from 'client/redux/fstd/rootReducer';
import fatd from 'client/redux/fatd/rootReducer';
import fatdi from 'client/redux/fatdi/rootReducer';
import optd from 'client/redux/optd/rootReducer';
import mwtd from 'client/redux/mwtd/rootReducer';
import qrtd from 'client/redux/qrtd/rootReducer';
import pvtd from 'client/redux/pvtd/rootReducer';
import swtd from 'client/redux/swtd/rootReducer';
import appError from 'client/redux/appError/reducer';
import auth from 'client/redux/auth/reducer';
import confirmUser from 'client/redux/confirmUser/reducer';
import connector from 'client/redux/connector/rootReducer';
import dashboard from 'client/redux/dashboard/rootReducer';
import endpoint from 'client/redux/endpoint/rootReducer';
import flashAlert from 'client/redux/flashAlert/reducer';
import forgotPassword from 'client/redux/forgotPassword/reducer';
import gritter from 'client/redux/gritter/reducer';
import instance from 'client/redux/instance/rootReducer';
import login from 'client/redux/auth/loginReducer';
import marketplace from 'client/redux/marketplace/reducer';
import navbar from 'client/redux/navbar/rootReducer';
import news from 'client/redux/news/rootReducer';
import plan from 'client/redux/plan/rootReducer';
import resetPassword from 'client/redux/resetPassword/reducer';
import settings from 'client/redux/settings/rootReducer';
import signup from 'client/redux/signup/reducer';
import support from 'client/redux/support/reducer';
import ui from 'client/redux/ui/reducer';
import uiFragment from 'client/redux/uiFragment/rootReducer';
import user from 'client/redux/user/rootReducer';

const appReducer = history => combineReducers({
  aitd,
  account,
  appError,
  auth,
  confirmUser,
  connector,
  dashboard,
  endpoint,
  fatd,
  fatdi,
  flashAlert,
  forgotPassword,
  form,
  fstd,
  gritter,
  grtd,
  instance,
  login,
  marketplace,
  mwtd,
  swtd,
  navbar,
  news,
  optd,
  plan,
  pvtd,
  qrtd,
  resetPassword,
  router: connectRouter(history),
  settings,
  signup,
  support,
  ui,
  uiFragment,
  user,
});

const rootReducer = history => (state, action) => {
  switch(action.type) {
    default: return appReducer(history)(state, action);
    // these actions reset the state
    case AUTH_SWITCH_USER_SUCCESS:
    case AUTH_SWITCH_USER_RETURN_SUCCESS:
    case LOGOUT_SUCCESS: {
      const { router, auth } = state; // only keep these properties on logout
      return appReducer(history)({router, auth}, action);
    }
  }
};

export default rootReducer;
