import React from 'react';
import propTypes from 'prop-types';
import Alert from 'client/components/Be/Alert';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/fstd/testActions';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import FSTDTestFile from 'client/components/FSTD/FSTDTestFile';
import FSTDTesterFile from 'client/components/FSTD/components/FSTDTesterFile';
import _ from 'lodash';

class FSTDTester extends React.Component {

  static propTypes = {
    testFile: propTypes.object,
    instance: propTypes.object.isRequired,
    endpointsByType: propTypes.object.isRequired,
    notices: propTypes.array,
  }

  handleTest = method => {
    const { instance: { id } } = this.props;
    return this.props.instanceFstdFileTest(id, method);
  }

  renderAlert() {
    const { testFile } = this.props;
    if(!testFile) {
      return (
        <Alert alertType="warning" icon={true}>
          <strong>Warning:</strong> You need to upload a test file above before you can use this tool
        </Alert>
      );
    }
    return (
      <Alert alertType="primary" icon={true}>
        <strong>Note:</strong> Clicking "Sync" below will create or update real data in TOPdesk.
      </Alert>
    );
  }

  render() {
    const { instance, testFile, notices, isTesting, error, method } = this.props;
		return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_fstd_test_help" subTitle="Here you can test the options with a real CSV file">
          Test synchronization
        </InstanceHelpHeader>
        <hr />
        <FSTDTestFile instanceId={instance.id} />
        <hr />
        {this.renderAlert()}
        <div className="list-group">
          <FSTDTesterFile
            file={testFile}
            notices={notices}
            isTesting={isTesting}
            onTest={this.handleTest}
            method={method}
            error={error}
          />
        </div>
      </div>
		);
  }
}

const actions = {
  ...testActions,
};

const mapStateToProps = state => ({
  ...state.fstd.test,
  testFile: _.get(state.fstd, 'testFile.testFile', null),
});

export default connect(mapStateToProps, actions)(FSTDTester);
