import { INSTANCE_LOG_INDEX, INSTANCE_LOG_INDEX_ORDERBY, INSTANCE_LOG_INDEX_SET_PAGE, INSTANCE_LOG_INDEX_FILTER } from 'client/redux/instance/log/constants';
import { instanceLog } from 'client/redux/instance/log/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const instanceLogIndex = query => dispatch => {
  return dispatch(instanceLog(INSTANCE_LOG_INDEX, query));
};

export const instanceLogIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(INSTANCE_LOG_INDEX_ORDERBY, orderBy, orderDirection));
};

export const instanceLogIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(INSTANCE_LOG_INDEX_SET_PAGE, page));
};

export const instanceLogIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(INSTANCE_LOG_INDEX_FILTER, filter));
};
