import { USER_INDEX, USER_UPDATE, USER_DELETE, USER_INDEX_SET_COLUMNS } from 'client/redux/user/constants';
import { tableSetColumns } from 'client/redux/table/actionCreators';
import * as actions from 'client/redux/user/actionCreators';

export const userIndex = query => dispatch => {
  return dispatch(actions.user(USER_INDEX, query));
};

export const userDelete = (id, query) => dispatch => {
  return dispatch(actions.userDelete(USER_DELETE, id, query));
};

export const userUpdate = (id, update, query) => dispatch => {
  return dispatch(actions.userUpdate(USER_UPDATE, id, update, query));
};

export const userIndexSetColumns = columns => dispatch => dispatch(tableSetColumns(USER_INDEX_SET_COLUMNS, columns));
