import React from 'react';
import propTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import { Form, Field, reduxForm } from 'redux-form';
import Alert from 'client/components/Be/Alert';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import _ from 'lodash';

export default class FSTDFieldMapperItemLinksManager extends React.Component {

  static propTypes = {
    idSuffix: propTypes.string.isRequired,
    disabled: propTypes.bool,
    columns: propTypes.array,
    topdeskEndpointId: propTypes.string.isRequired,
  }

  static defaultProps = {
    disabled: false,
    columns: [],
  }

  state = {
    addFormOpen: false,
  }

  setAddFormClosed = () => {
    this.setState({addOpenForm: false});
  }

  setAddFormOpen = () => {
    this.setState({addOpenForm: true});
  }

  addLink = (columnName, assetTemplateId) => {
    const { input: { onChange } } = this.props;
    onChange({...this.getValue(), [columnName]: assetTemplateId});
  }

  removeLink = columnName => {
    const { input: { onChange } } = this.props;
    onChange(_.omit(this.getValue(), columnName));
  }

  renderLinks() {
    const { disabled } = this.props;
    const value = this.getValue();
    if(!_.keys(value).length) return (
      <tr className="empty">
        <td colSpan={3}>No columns linked to assets yet</td>
      </tr>
    );
    return _.map(value, (assetTemplateId, columnName) => (
      <tr key={columnName}>
        <td>{columnName}</td>
        <td>{assetTemplateId}</td>
        <td>
          <button disabled={disabled} type="button" onClick={() => this.removeLink(columnName)} className="btn btn-default btn-sm center-block">
            <i className="btn-icon icon mdi mdi-close"></i>
          </button>
        </td>
      </tr>
    ));
  }

  getValue() {
    const { input: { value } } = this.props;
    return _.isPlainObject(value) ? value : {};
  }

  render() {
    const { addOpenForm } = this.state;
    const { columns, topdeskEndpointId, disabled, idSuffix } = this.props;
    const value = this.getValue();
    return (
      <div>
        <table className="table table-condensed table-bordered">
          <tbody>
            <tr>
              <th className="xs-pl-10">Column</th>
              <th>Asset Template Name</th>
              <th></th>
            </tr>
            {this.renderLinks()}
          </tbody>
        </table>
        <div className="text-center xs-mt-15">
          <button type="button" disabled={disabled} onClick={this.setAddFormOpen} className="btn btn-default btn-rounded">New column link</button>
          <AddLinkModal
            open={addOpenForm || false}
            idSuffix={idSuffix}
            columns={columns}
            links={value}
            onClose={this.setAddFormClosed}
            onAdd={this.addLink}
            topdeskEndpointId={topdeskEndpointId}
          />
        </div>
      </div>
    );
  }

}

class AddLinkModal extends React.Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    links: propTypes.object.isRequired,
    columns: propTypes.array,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    onAdd: propTypes.func.isRequired,
    topdeskEndpointId: propTypes.string.isRequired,
    idSuffix: propTypes.string.isRequired,
  }

  handleSubmit = fields => {
    const { columnName, assetTemplateId } = fields;
    this.props.onAdd(columnName, assetTemplateId);
    this.props.onClose();
  }

  renderBody() {
    const { open, columns, links, topdeskEndpointId, idSuffix } = this.props;
    if(!open) return null;
    return (
      <AddLinkFormConnected
        form={`fstdFieldMapperAddLinkForm${idSuffix}`}
        idSuffix={idSuffix}
        onSubmit={this.handleSubmit}
        links={links}
        columns={columns}
        topdeskEndpointId={topdeskEndpointId}
      />
    );
  }

  render() {
    const { onClose, open } = this.props;
    return (
      <Modal id="fstdFieldMapperAddLinkModal" dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>Add Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
      </Modal>
    );
  }

}

class AddLinkForm extends React.Component {

  static propTypes = {
    links: propTypes.object.isRequired,
    columns: propTypes.array,
    onSubmit: propTypes.func.isRequired,
    topdeskEndpointId: propTypes.string.isRequired,
    idSuffix: propTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.fieldProps = {
      base: 'fstdFieldMapperAddLinkForm' + (props.idSuffix || ''),
      metaOptions: {defaultState: null, ignorePristine: false},
    };
  }

  getColumnData() {
    const mappedColumns = _.keys(this.props.links) || [];
    const columns = this.props.columns || [];
    return columns.filter(name => !mappedColumns.includes(name)).map(text => ({id: text, text}));
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      error,
      invalid,
      topdeskEndpointId,
    } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <Alert alertType="primary" icon={true}>
          <strong>Note:</strong> Asset to link will be found by searching for the name found in the column
        </Alert>
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              component={FormReduxSelect2}
              select2Options={{tags: true, minimumInputLength: 0, minimumResultsForSearch: 0, dropdownParent: '#fstdFieldMapperAddLinkModal'}}
              name="columnName"
              label="Column Name"
              data={this.getColumnData()}
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              component={FormReduxSelect2}
              select2Component={EndpointTopdeskRemoteSearcher}
              select2ComponentProps={{forWhat: 'assetTemplate', endpointId: topdeskEndpointId}}
              select2Options={{minimumInputLength: 0, dropdownParent: '#fstdFieldMapperAddLinkModal'}}
              name="assetTemplateId"
              label="Asset Template Name"
            />
          </div>
        </div>
        <div className="text-right">
          <button type="button" onClick={handleSubmit(onSubmit)} disabled={invalid} className="btn btn-primary btn-lg">
            <i className="icon icon-left mdi mdi-icon mdi-check"></i>{' '}
            Create
          </button>
        </div>
      </Form>
    );
  }

}

// validate so that we don't create a link with the same key/column name
const validateAddForm = (values, props) => {
  const errors = {};
  const { columnName, assetTemplateId } = values;
  const { links } = props;
  if(!assetTemplateId) errors.assetTemplateId = 'Field must be set';
  if(!columnName) errors.columnName = 'Field must be set';
  if(links[columnName]) errors.columnName = 'This column has already been mapped';
  return errors;
};

const AddLinkFormConnected = reduxForm({
  enableReinitialize: false,
  validate: validateAddForm,
})(AddLinkForm);
