import { DASHBOARD_NEWS_LATEST, DASHBOARD_NEWS_DELETE } from 'client/redux/dashboard/constants';
import { newsLatest, newsDelete } from 'client/redux/news/actionCreators';

export const dashboardNewsLatest = () => dispatch => {
  return dispatch(newsLatest(DASHBOARD_NEWS_LATEST, {}));
};

export const dashboardNewsDelete = id => dispatch => {
  return dispatch(newsDelete(DASHBOARD_NEWS_DELETE, id));
};
