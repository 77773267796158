import { CONNECTOR_INDEX, CONNECTOR_INDEX_ORDERBY, CONNECTOR_INDEX_SET_PAGE, CONNECTOR_INDEX_FILTER } from 'client/redux/connector/constants';
import * as actions from 'client/redux/connector/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const connectorIndex = query => dispatch => {
  return dispatch(actions.connector(CONNECTOR_INDEX, query));
};

export const connectorIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(CONNECTOR_INDEX_ORDERBY, orderBy, orderDirection));
};

export const connectorIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(CONNECTOR_INDEX_SET_PAGE, page));
};

export const connectorIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(CONNECTOR_INDEX_FILTER, filter));
};
