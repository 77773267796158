import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ShortId } from 'client/components/Helpers/Strings';
import LinkQuery from 'client/components/Helpers/LinkQuery';
import Icon from 'client/components/Be/Icon';
import classNames from 'classnames';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import { TableThSort, TablePagination, TableShowCount } from 'client/components/Helpers/Table';
import Time from 'client/components/Helpers/Time';
import InstanceLogFragmentTableFilterForm from 'client/components/InstanceLogFragment/InstanceLogFragmentTableFilterForm';
import InstanceLogDescription from 'client/components/InstanceLog/components/InstanceLogDescription'
import LogLevelLabel from 'client/components/Helpers/LogLevelLabel';
import BeLoading from 'client/components/Be/BeLoading';

export default class InstanceLogFragmentTable extends Component {

  static defaultProps = {
    logFragments: []
  }

  static propTypes = {
    logFragments: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onRefresh: propTypes.func,
    loading: propTypes.bool,
  }

  renderInstanceLogFragments() {
    const { logFragments } = this.props;
    if(!logFragments || !logFragments.length) return (
      <tr>
        <td className="empty" colSpan={8}>No log fragments to show</td>
      </tr>
    );
    return logFragments.map(log => {
      const { id, time, level, type, connectorInstanceLogId } = log;
      return (
        <tr key={id}>
          <td><Time time={time} format="YYYY-MM-DD HH:mm:ss" /></td>
          <td><ShortId id={id} /></td>
          <td><ShortId id={connectorInstanceLogId} /></td>
          <td><LogLevelLabel level={level} /></td>
          <td>{type}</td>
          <td><InstanceLogDescription {...log} maxLineLength={100} /></td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: log, title: type, inspector: 'object'}}>
                <Icon icon="search" />
              </ModalButton>
            </div>
          </td>
        </tr>
      )
    })
  }

  render() {
    const {
      count,
      filter,
      limit,
      loading,
      onFilter,
      onOrderBy,
      onSetPage,
      orderBy,
      orderDirection,
      page,
      pages,
    } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading panel-heading-divider">
          Table of Instance Log Fragments
          <div className="tools">

            <LinkQuery
              title="Download JSON"
              to="/api/instance/log/fragment/json"
              query={filter}
              target="_blank"
            >
              <Icon icon="download" />
            </LinkQuery>
          </div>
        </div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <InstanceLogFragmentTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            <div className="be-datatable-body">
              <BeLoading loading={loading}>
                <table className="table table-striped table-hover dataTable">
                  <thead>
                    <tr>
                      <TableThSort column="time" {...sortProps}>Time</TableThSort>
                      <TableThSort column="id" {...sortProps}>ID</TableThSort>
                      <th>Job</th>
                      <TableThSort column="level" {...sortProps}>Level</TableThSort>
                      <th>Type</th>
                      <th>Description</th>
                      <th className="actions"></th>
                    </tr>
                  </thead>
                  <tbody className="no-border-x">
                    {this.renderInstanceLogFragments()}
                  </tbody>
                </table>
              </BeLoading>
            </div>
            <div className="row be-datatable-footer">
              <div className="col-sm-5 sm-mb-0 xs-mb-15">
                <div className="dataTables_info">
                  <TableShowCount limit={limit} count={count} pluralization={['log', 'logs']} />
                </div>
              </div>
              <div className="col-md-7">
                <div className="dataTables_paginate paging_simple_numbers">
                  <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

};
