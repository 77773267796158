import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const user = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/user', query),
    method: 'GET',
    types
  });
};

export const userRead = (types, id) => {
  return apiAction({
    endpoint: `/api/user/${id}`,
    method: 'GET',
    types
  });
};

export const userCreate = (types, json) => {
  return apiAction({
    endpoint: '/api/user',
    method: 'POST',
    json,
    types
  });
};

export const userCall = (types, id, method, json) => {
  return apiAction({
    endpoint: `/api/user/${id}/call/${method}`,
    method: 'POST',
    json,
    types: types.map(type => ({type, meta: {method}})),
  });
};

export const userUpdate = (types, id, update, query = {}) => {
  return apiAction({
    endpoint: appendQuery(`/api/user/${id}`, query),
    method: 'PATCH',
    json: update,
    types
  });
};

export const userDelete = (types, id, query = {}) => {
  return apiAction({
    endpoint: appendQuery( `/api/user/${id}`, query),
    method: 'DELETE',
    json: {},
    types
  });
};

export const userUpdateState = (types, userId, json) => {
  return apiAction({
    endpoint: `/api/user/${userId}/state`,
    method: 'PATCH',
    json,
    types
  });
};

export const userInvoiceRead = (types, id) => {
  return apiAction({
    endpoint: `/api/user/invoice/${id}`,
    method: 'GET',
    types
  });
};
