import React, { Component } from 'react';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { BeFormFieldErrors } from 'client/components/Be';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Field, reduxForm } from 'redux-form';
import { isFilled, isString, isEmail } from 'client/redux/validators';
import { FormReduxInput } from 'client/components/Form/FormRedux';

const validateByField = {
  email: [isString(), isFilled(), isEmail()]
};

class ForgotPasswordForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
    isWaiting: propTypes.bool
  }

  render() {
    const metaOptions = {ignorePristine: true, showSuccess: true};
    const { invalid, isWaiting, error } = this.props;
    return (
			<Form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <p>We'll send you an email to reset your password.</p>
        <div className="form-group xs-pt-10">
          <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
          <Field
            base="forgotPasswordForm"
            name="email"
            component={FormReduxInput}
            type="email"
            placeholder="Enter E-mail"
            metaOptions={metaOptions}
            validate={validateByField.email}
          />
        </div>
        <p className="xs-pt-5 xs-mb-0">
          Don't remember your email? <a href="mailto:support@linknow.io">Contact Support</a>.
        </p>
        <div className="form-group xs-pt-5">
					<div className="form-group row login-submit">
						<div className="col-xs-6">
              <Link className="btn btn-default btn-xl" to="/login">Back to Login</Link>
						</div>
						<div className="col-xs-6">
              <ButtonWaiting type="submit" disabled={invalid} className="btn-primary btn-xl btn-block" isWaiting={isWaiting}>Reset Password</ButtonWaiting>
						</div>
					</div>
        </div>
			</Form>
    );
  }

};

export default reduxForm({
  form: 'forgotPassword'
})(ForgotPasswordForm);
