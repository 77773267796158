import { ENDPOINT_FAST_READ, ENDPOINT_FAST_UPDATE_CREDENTIALS, ENDPOINT_FAST_TEST_CREDENTIALS, ENDPOINT_FAST_PRELOADED, ENDPOINT_FAST_EDIT_CREDENTIALS } from 'client/redux/endpoint/fast/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isEditing: false,
  isReading: false,
  isUpdating: false,
  isTesting: false,
  error: null,
  endpoint: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(ENDPOINT_FAST_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(ENDPOINT_FAST_UPDATE_CREDENTIALS, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(ENDPOINT_FAST_TEST_CREDENTIALS, state, action, {requestProp: 'isTesting', successSpread: true});
  switch(action.type) {
    case ENDPOINT_FAST_EDIT_CREDENTIALS: return {
      ...state,
      isEditing: action.isEditing
    };
    case ENDPOINT_FAST_PRELOADED: return {
      ...state,
      isReading: false,
      endpoint: action.endpoint,
      error: null,
    };
    default:
      return state
  }
};
