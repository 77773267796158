import { NAVBAR_COLOR_SET } from 'client/redux/navbar/constants';

const defaultState = {
  color: 'primary'
};

export default function colorReducer(state = defaultState, action) {
  switch(action.type) {
    default: return state;
    case NAVBAR_COLOR_SET: return {
      ...state,
      color: action.newColor || null
    }
  }
};
