import {
  INSTANCE_FSTD_FIELDMAPPER_NEW,
  INSTANCE_FSTD_FIELDMAPPER_NEW_DELETE,
  INSTANCE_FSTD_FIELDMAPPER_EDIT,
  INSTANCE_FSTD_FIELDMAPPER_READ,
  INSTANCE_FSTD_FIELDMAPPER_SAVE,
  INSTANCE_FSTD_FIELDMAPPER_DELETE,
  INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS,
} from 'client/redux/fstd/constants';
import { endpointTopdeskAssetTemplateFieldTargets } from 'client/redux/endpoint/topdesk/actionCreators';
import * as actionCreators from 'client/redux/fstd/actionCreators';

export const instanceFstdFieldMapperNew = (currentTestFileName = null) => dispatch => {
  return dispatch(actionCreators.instanceFstdFieldMapperNew(INSTANCE_FSTD_FIELDMAPPER_NEW, currentTestFileName));
};

export const instanceFstdFieldMapperReadFieldTargets = (topdeskEndpointId, fileName, assetTemplateId) => dispatch => dispatch(endpointTopdeskAssetTemplateFieldTargets(INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS.map(type => ({type, meta: {fileName, assetTemplateId}})), topdeskEndpointId, assetTemplateId));

export const instanceFstdFieldMapperEdit = (fileName, assetTemplateId, isEditing) => dispatch => dispatch(actionCreators.instanceFstdFieldMapperEdit(INSTANCE_FSTD_FIELDMAPPER_EDIT, fileName, assetTemplateId, isEditing));

export const instanceFstdFieldMapperNewDelete = () => dispatch => dispatch(actionCreators.instanceFstdFieldMapperNewDelete(INSTANCE_FSTD_FIELDMAPPER_NEW_DELETE));

export const instanceFstdFieldMapperRead = instanceId => dispatch => dispatch(actionCreators.instanceFstdFieldMapperRead(INSTANCE_FSTD_FIELDMAPPER_READ, instanceId));

export const instanceFstdFieldMapperSave = (instanceId, fileName, assetTemplateId, map = {}, links = {}, assignments = {}, settings) => dispatch => dispatch(actionCreators.instanceFstdFieldMapperSave(INSTANCE_FSTD_FIELDMAPPER_SAVE, instanceId, fileName, assetTemplateId, map, links, assignments, settings));

export const instanceFstdFieldMapperDelete = (instanceId, fileName, assetTemplateId) => dispatch => dispatch(actionCreators.instanceFstdFieldMapperDelete(INSTANCE_FSTD_FIELDMAPPER_DELETE, instanceId, fileName, assetTemplateId));
