import { ENDPOINT_LOG_INDEX, ENDPOINT_LOG_INDEX_ORDERBY, ENDPOINT_LOG_INDEX_SET_PAGE, ENDPOINT_LOG_INDEX_FILTER } from 'client/redux/endpoint/log/constants';
import { endpointLog } from 'client/redux/endpoint/log/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const endpointLogIndex = query => dispatch => {
  return dispatch(endpointLog(ENDPOINT_LOG_INDEX, query));
};

export const endpointLogIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(ENDPOINT_LOG_INDEX_ORDERBY, orderBy, orderDirection));
};

export const endpointLogIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(ENDPOINT_LOG_INDEX_SET_PAGE, page));
};

export const endpointLogIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(ENDPOINT_LOG_INDEX_FILTER, filter));
};
