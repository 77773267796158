import { combineReducers } from 'redux';

import options from 'client/redux/swtd/optionsReducer';
import statistics from 'client/redux/swtd/statisticsReducer';
import test from 'client/redux/swtd/testReducer';

const swtdReducer = combineReducers({
  options,
  statistics,
  test,
});

export default swtdReducer;
