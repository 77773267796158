import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import { ENDPOINT_INDEX_DELETE_SUCCESS } from 'client/redux/endpoint/constants';

export const onEndpointDelete = deleteActionResult => dispatch => {
  if(deleteActionResult) {
    const { type } = deleteActionResult;
    if(type === ENDPOINT_INDEX_DELETE_SUCCESS) {
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'endpoint_delete_success', {
        className: 'color warning',
        title: 'Deletion succeded',
        message: 'The endpoint was deleted',
        closeAfter: 3000
      }));
    }
  }
};
