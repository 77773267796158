import React from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/aitd/testActions';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import AITDTesterAddTopdeskIncident from 'client/components/AITD/AITDTesterAddTopdeskIncident';
import AITDTesterIncident from 'client/components/AITD/AITDTesterIncident';

class AITDTester extends React.Component {

  state = {
    topdeskIncidentId: '',
  }

  instanceId() {
    return _.get(this.props, 'instance.id');
  }

  onClickAddTopdeskIncident = () => {
    const { topdeskIncidentId } = this.state;
    if(!topdeskIncidentId) return;
    this.props.instanceAitdTestIncidentAdd(this.instanceId(), topdeskIncidentId).then(() => {
      this.setState({topdeskIncidentId: ''});
    });
  }

  onClickRemoveTopdeskIncident = topdeskIncidentId => {
    return this.props.instanceAitdTestIncidentRemove(topdeskIncidentId);
  }

  onClickSyncTopdeskIncident = topdeskIncidentId => {
    return this.props.instanceAitdSyncIncident(this.instanceId(), topdeskIncidentId);
  }

  onClickObjectGraphTopdeskIncident = topdeskIncidentId => {
    return this.props.instanceAitdTestObjectGraph(this.instanceId(), topdeskIncidentId);
  }

  onChangeTopdeskIncidentId = topdeskIncidentId => {
    this.setState({topdeskIncidentId});
  }

  render() {
    const { topdeskIncidentId } = this.state;
    const {
      endpointsByType: { topdesk },
      error,
      topdeskIncidents,
      isAdding,
    } = this.props;
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_aitd_test_help" subTitle="Here you can test the incident callback">
          Test Incident Callback
        </InstanceHelpHeader>

        <BeRemoteErrorAlert error={error} />

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> Clicking "Sync" on an added object below will create or update real data.
          </div>
        </div>

        <h4>TOPdesk Incidents</h4>
        <div className="list-group">
          {Object.keys(topdeskIncidents).length > 0 && _.map(topdeskIncidents, (incident, index) => (
            <AITDTesterIncident
              key={index}
              incident={incident}
              onRemove={this.onClickRemoveTopdeskIncident}
              onSync={this.onClickSyncTopdeskIncident}
              onTestObjectGraph={this.onClickObjectGraphTopdeskIncident}
            />
          ))}
          {Object.keys(topdeskIncidents).length < 1 && (
            <div className="list-group-item">
              No TOPdesk Incidents added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <AITDTesterAddTopdeskIncident
              endpointId={topdesk.id}
              objectId={topdeskIncidentId}
              onChange={this.onChangeTopdeskIncidentId}
              onAdd={this.onClickAddTopdeskIncident}
              isAdding={isAdding}
            />
          </div>
        </div>

      </div>
    );
  }
}

const actions = {
  ...testActions,
};

export default connect(state => state.aitd.test, actions)(AITDTester);
