import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class FATDIPanel extends Component {

  static propTypes = {
    onCall: propTypes.func.isRequired,
  }

  render() {
    const { onCall } = this.props;
    return (
      <div>
        <div>
          <ButtonWaitingPromise onClick={ev => onCall('syncFastCases')} type="button" className="btn btn-default">
            Synchronize Fast Cases
          </ButtonWaitingPromise>{' '}
          <ButtonWaitingPromise onClick={ev => onCall('syncFastErrands')} type="button" className="btn btn-default">
            Synchronize Fast Errands
          </ButtonWaitingPromise>{' '}
          <ButtonWaitingPromise onClick={ev => onCall('syncFastOrders')} type="button" className="btn btn-default">
            Synchronize Fast Orders
          </ButtonWaitingPromise>{' '}
        </div>
        <hr />
        <div>
          <div className="btn-group-vertical btn-space">
            <ButtonWaitingPromise onClick={ev => onCall('resetCaseLastChangedTimestamp')} type="button" className="btn btn-default">
              Reset "Fi2Case Last Changed" timestamp
            </ButtonWaitingPromise>{' '}
            <ButtonWaitingPromise onClick={ev => onCall('resetErrandLastChangedTimestamp')} type="button" className="btn btn-default">
              Reset "Fi2Errand Last Changed" timestamp
            </ButtonWaitingPromise>
            <ButtonWaitingPromise onClick={ev => onCall('resetOrderLastChangedTimestamp')} type="button" className="btn btn-default">
              Reset "Fi2Order Last Changed" timestamp
            </ButtonWaitingPromise>
          </div>
        </div>
      </div>
    );
  }

}
