import React, { Component } from 'react';
import { App, Content, MainContent } from 'client/components/App';
import { SplashFooter, SplashHeader } from 'client/components/Branding';
import { CONFIRM_USER_SUCCESS } from 'client/redux/confirmUser/constants';
import { setBodyClass } from 'client/redux/bodyClass';
import { Helmet } from 'react-helmet';
import { confirmUser } from 'client/redux/confirmUser/actions';
import { redirectAndFlash } from 'client/redux/flashAlert/actions';
import { connect } from 'react-redux';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import _ from 'lodash';

class ConfirmUser extends Component {

  handleClickConfirm = () => {
    const token = _.get(this.props, 'match.params.token');
    return this.props.confirmUser(token).then(result => {
      if(result.type === CONFIRM_USER_SUCCESS) {
        return this.props.redirectAndFlash('/', {
          alertType: 'success',
          strong: 'Confirmed!',
          msg: 'Thank you for confirming your account.'
        });
      }
    });
  }

  componentDidMount() {
    // confirm directly on mount
    return this.handleClickConfirm();
  }

  render() {
    setBodyClass('be-splash-screen');
    const { isConfirming, error } = this.props;
    return (
      <App className="be-login be-signup">
        <Helmet>
          <title>Confirm your account</title>
        </Helmet>
        <Content>
          <MainContent className="container-fluid">
            <div className="splash-container sign-up">
              <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
                <SplashHeader className="panel-heading">Press the button to confirm your account</SplashHeader>
                <div className="panel-body xs-pb-20">
                  <BeRemoteErrorAlert error={error} />
                  <div className="text-center xs-pb-20">
                    <ButtonWaiting className="btn btn-success btn-xl" isWaiting={isConfirming} onClick={this.handleClickConfirm}>Confirm your account</ButtonWaiting>
                  </div>
                </div>
              </div>
              <SplashFooter />
            </div>
          </MainContent>
        </Content>
      </App>
    );
  }
}

export default connect(state => state.confirmUser, { confirmUser, redirectAndFlash })(ConfirmUser);
