import { ACCOUNT_INVOICE_READ } from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: null,
  invoice: null
};

export default function invoiceReadReducer(state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_INVOICE_READ, state, action, {
    requestProp: 'isReading',
    successSpread: true,
    successPickProps: [
      'invoice', 
      'invoiceObject', 
      'sender', 
      'recipient', 
      'reverseCharge', 
      'instances', 
      'instancesByStripePlanId'
    ],
  });
  return state;
};
