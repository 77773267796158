import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import EndpointTopdeskForm from 'client/components/Endpoint/components/EndpointTopdeskForm';
import { connect } from 'react-redux';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';
import * as topdeskActions from 'client/redux/endpoint/topdesk/actions';
import BeLoading from 'client/components/Be/BeLoading';
import _ from 'lodash';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { AlertWithMessage } from 'client/components/Be/Alert';

class EndpointTopdesk extends React.Component {

  static defaultProps = {
    onUpdated: function() {},
    isSetupMode: true
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    onUpdated: propTypes.func,
    isSetupMode: propTypes.bool
  }

  componentDidMount() {
    if(this.props.preloadedEndpoint) {
      this.props.endpointTopdeskPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointTopdeskRead(this.props.endpointId);
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props;
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointTopdeskRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  handleUpdate = (credentials = {}) => {
    return this.props.endpointTopdeskUpdateCredentials(this.props.endpointId, credentials)
      .then(getResponseToFormErrorHandler())
      .then(result => {
        this.props.endpointTopdeskEditCredentials(false);
      });
  }

  handleTest = ev => {
    return this.props.endpointTopdeskTestCredentials(this.props.endpointId);
  }

  handleClickEdit = ev => {
    this.props.endpointTopdeskEditCredentials(true);
  }

  dataToFields(data = {}) {
    return {
      apiUrl: data.apiUrl || '',
      username: data.username || '',
      password: data.password || '',
      appPassword: data.appPassword || '',
    };
  }

  renderForm() {
    const { endpoint, isReading, isSetupMode, isEditing, isTesting, isUpdating, error } = this.props;
    if(isReading || !endpoint) return null;
    if(isSetupMode || isEditing) {
      return (
        <EndpointTopdeskForm
          hasOldStylePassword={this.hasOldStylePassword()}
          initialValues={this.dataToFields(endpoint.settings)}
          isEditing={isEditing}
          endpointState={endpoint.state}
          onEdit={this.handleClickEdit}
          onSubmit={this.handleUpdate}
          onTest={this.handleTest}
          isTesting={isTesting}
          isUpdating={isUpdating}
          remoteError={error}
        />
      );
    } else {
      const { testOk, testTimestamp } = endpoint.state;
      return (
        <div>
          {!isSetupMode && <EndpointTestButton
            isTesting={isTesting}
            onTest={this.handleTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
          />}
          <button type="button" className="btn btn-default pull-right" onClick={this.handleClickEdit}>
            <i className="icon icon-left mdi mdi-edit"></i>
            {' '}Edit credentials
          </button>
        </div>
      );
    }
  }

  hasOldStylePassword() {
    return Boolean(_.get(this.props, 'endpoint.settings.password'));
  }

  render() {
    const { isReading, error } = this.props;

    const endpointHasError = _.get(this.props, 'endpoint.status') === 'error';
    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              TOPdesk
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_topdesk_credentials_help'}}
              />
            </div>
            <span className="panel-subtitle">Enter and test your TOPdesk credentials below</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading loading={isReading} className="xs-p-20">
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {endpointHasError && (
              <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
                <strong>Error:</strong> There is a problem with this endpoint.
              </AlertWithMessage>
            )}
            {this.hasOldStylePassword() && (
              <AlertWithMessage icon={true} className="alert-icon" alertType="primary">
                <strong>Recommended:</strong> Change to a TOPdesk application password
              </AlertWithMessage>
            )}
            {this.renderForm()}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.topdesk, topdeskActions)(EndpointTopdesk);
