import { apiAction } from 'client/redux/apiHelpers';

export const logout = types => {
  return apiAction({
    endpoint: '/api/auth/logout',
    method: 'POST',
    types,
    json: {}
  });
};

export const login = (types, form) => {
  return apiAction({
    endpoint: '/api/auth/login',
    method: 'POST',
    types,
    json: form
  });
};

export const switchUser = (types, userId) => {
  return apiAction({
    endpoint: `/api/auth/switchUser/${userId}`,
    method: 'POST',
    json: {},
    types
  });
};

export const switchUserReturn = types => {
  return apiAction({
    endpoint: '/api/auth/switchUserReturn',
    method: 'POST',
    json: {},
    types
  });
};
