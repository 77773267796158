import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AlertWithMessage } from 'client/components/Be/Alert';

export default class ConnectorSelectPlanTaxAlert extends Component {

  static propTypes = {
    taxPercentage: propTypes.number.isRequired,
    hasVatNumber: propTypes.bool.isRequired,
  }

  alertType() {
    const { hasVatNumber } = this.props;
    return hasVatNumber ? 'success' : 'warning';
  }

  message() {
    const { hasVatNumber, taxPercentage } = this.props;
    switch(hasVatNumber) {
      default:
      case false: return (
        <span>
          Your current VAT rate of {taxPercentage} % is not included in the price below.<br />
          <Link to="/account/billing">Add your EU VAT number </Link>
          to be charged the correct rate of 0 % unless your business is located in Sweden.
          <br />
          If you are outside of the EU and do not have a VAT number, contact support so that we can change your tax rate manually.
        </span>
      );
      case true: return (
        <span>
          Your current VAT rate of {taxPercentage} % is not included in the price below. Contact support if this is not the correct rate.
        </span>
      );
    }
  }

  render() {
    const { className, taxPercentage } = this.props;
    const classes = classNames(className, 'alert-icon');
    if(taxPercentage === 0) return null;
    return (
      <AlertWithMessage icon={true} className={classes} alertType={this.alertType()}>
        {this.message()}
      </AlertWithMessage>
    );
  }

}
