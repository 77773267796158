import { combineReducers } from 'redux';

import test from 'client/redux/optd/testReducer';
import options from 'client/redux/optd/optionsReducer';
import statistics from 'client/redux/optd/statisticsReducer';

const optdReducer = combineReducers({
  test,
  options,
  statistics,
});

export default optdReducer;
