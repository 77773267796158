import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';
import _ from 'lodash';

export default class FSTDTesterFile extends React.Component {

  static propTypes = {
    file: propTypes.object,
    onTest: propTypes.func.isRequired,
    method: propTypes.string,
  }

  handleClickTest = method => {
    return this.props.onTest(method);
  }

  renderNoticeTableRows(notices) {
    if(!notices || !notices.length) {
      return (
        <tr>
          <td colSpan="3">
            <em>No action taken with this file</em>
          </td>
        </tr>
      );
    }
    const { file: { originalName }, method } = this.props;
    const futureTense = method === 'readOnly';
    return notices.map((notice, index) => {
      const { meta } = notice;
      let subject = originalName;
      if(meta && !_.isUndefined(meta.recordNumber)) {
        subject += ` ${meta.recordNumber}`;
      }
      return (
        <NoticeRow
          key={index}
          notice={notice}
          subject={subject}
          futureTense={futureTense}
        />
      );
    });
  }

  renderNoticeTable() {
    const { notices, error } = this.props;
    if(error) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {this.renderNoticeTableRows(notices)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { file = {}, error } = this.props;
    if(!file) return null;
    const { originalName } = file;
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-file xs-mr-5"></i>
            {originalName}
          </div>
          <span className="btn-group pull-right">
            <ButtonWaitingPromise type="button" onClick={() => this.handleClickTest('readWrite')} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>
              Sync
            </ButtonWaitingPromise>
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} />
        {this.renderNoticeTable()}
      </div>
    );
  }

};
