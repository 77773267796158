import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput, FormReduxTextarea } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm } from 'redux-form';
import { isNotEmpty, isString, isURL } from 'client/redux/validators';
import { FormErrors } from 'client/components/Form/FormErrors';

const validateByField = {
  apiBaseUrl: [isString(), isNotEmpty(), isURL()],
  apiOauthTokenUrl: [isString(), isNotEmpty(), isURL()],
  oauthClientId: [isString(), isNotEmpty()],
  oauthClientSecret: [isString(), isNotEmpty()],
  apiGwClientId: [isString(), isNotEmpty()],
  apiGwClientSecret: [isString(), isNotEmpty()],
  clientKey: [isString()],
  clientSecret: [isString()],
  bestallningsidentitet: [isString(), isNotEmpty()],
  organisationsnummer: [isString(), isNotEmpty()],
};

class EndpointSkatteverketForm extends Component {

  static defaultProps = {
    isEditing: false,
    onEdit: function() {}
  }

  static propTypes = {
    isEditing: propTypes.bool.isRequired,
    onEdit: propTypes.func,
    remoteError: propTypes.object,
    initialValues: propTypes.object.isRequired,
    endpointState: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    isUpdating: propTypes.bool.isRequired,
  }

  render() {
    const {
      remoteError,
      error,
      isUpdating,
      valid,
      onEdit,
      onSubmit,
      isEditing,
      initialValues,
      handleSubmit,
    } = this.props;

    const hasInitialValues = Object.keys(validateByField).every(field => {
      return Boolean(initialValues[field]);
    });
    const disabled = hasInitialValues && !isEditing;

    const fieldProps = {
      base: 'endpointSkatteverketForm',
      metaOptions: {defaultState: null, ignorePristine: true},
    };

    return (
			<Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <FormErrors className="xs-mt-0 xs-mb-10" error={error || remoteError} />
        <div>
          <Field
            {...fieldProps}
            name="apiBaseUrl"
            component={FormReduxInput}
            type="text"
            label="API Base URL"
            disabled={disabled}
            validate={validateByField.apiBaseUrl}
            helpText="Example: https://api.test.skatteverket.se/folkbokforing/folkbokforingsuppgifter-for-offentliga-aktorer/v2"
            required
          />
          <Field
            {...fieldProps}
            name="apiOauthTokenUrl"
            component={FormReduxInput}
            type="text"
            label="API Oauth Token URL"
            disabled={disabled}
            validate={validateByField.apiOauthTokenUrl}
            helpText="Example: https://sysorgoauth2.test.skatteverket.se/oauth2/v1/sysorg/token"
            required
          />
          <Field
            {...fieldProps}
            name="oauthClientId"
            component={FormReduxInput}
            type="text"
            label="OAuth Client ID"
            disabled={disabled}
            validate={validateByField.oauthClientId}
            required
          />
          <Field
            {...fieldProps}
            name="oauthClientSecret"
            component={FormReduxInput}
            type="text"
            label="OAuth Client Secret"
            disabled={disabled}
            validate={validateByField.oauthClientSecret}
            required
          />
          <Field
            {...fieldProps}
            name="apiGwClientId"
            component={FormReduxInput}
            type="text"
            label="APIgw Client ID"
            disabled={disabled}
            validate={validateByField.apiGwClientId}
            required
          />
          <Field
            {...fieldProps}
            name="apiGwClientSecret"
            component={FormReduxInput}
            type="text"
            label="APIgw Client Secret"
            disabled={disabled}
            validate={validateByField.apiGwClientSecret}
            required
          />
          <Field
            {...fieldProps}
            name="bestallningsidentitet"
            component={FormReduxInput}
            type="text"
            label="Beställningsidentitet"
            disabled={disabled}
            validate={validateByField.bestallningsidentitet}
            helpText="Example: 00000236-FO01-0001"
            required
          />
          <Field
            {...fieldProps}
            name="organisationsnummer"
            component={FormReduxInput}
            type="text"
            label="Organisationsnummer"
            disabled={disabled}
            validate={validateByField.organisationsnummer}
            helpText="Example: 162021004748"
            required
          />
          <Field
            {...fieldProps}
            name="clientCert"
            component={FormReduxTextarea}
            type="text"
            label="Client Certificate (x509)"
            disabled={disabled}
            placeholder="Enter a client certificate formatted as x509"
            validate={validateByField.clientCert}
          />
          <Field
            {...fieldProps}
            name="clientKey"
            component={FormReduxTextarea}
            type="text"
            label="Private Client key (x509)"
            disabled={disabled}
            placeholder="Enter a private client key formatted as x509"
            validate={validateByField.clientKey}
          />
        </div>
        <div className="form-group xs-mb-0">
          {disabled && (
            <button className="btn btn-default pull-right" type="button" onClick={onEdit}>
              Edit settings
            </button>
          )}
          {!disabled && (
            <ButtonWaiting
              type="submit"
              disabled={!valid}
              className="btn btn-default pull-right"
              isWaiting={isUpdating}
            >
              Save and test
            </ButtonWaiting>
          )}
          <div className="clearfix"></div>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'endpointSkatteverket',
  enableReinitialize: true
})(EndpointSkatteverketForm);
