import { INSTANCE_LOG_INDEX, INSTANCE_LOG_INDEX_ORDERBY, INSTANCE_LOG_INDEX_SET_PAGE, INSTANCE_LOG_INDEX_FILTER } from 'client/redux/instance/log/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  logs: null,
  filter: {connectorId: '', type: '', level: '', timeStart: '', timeEnd: '', connectorInstanceId: ''},
  page: 1,
  orderBy: 'time',
  orderDirection: 'DESC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_LOG_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceTableActions([INSTANCE_LOG_INDEX_FILTER, INSTANCE_LOG_INDEX_SET_PAGE, INSTANCE_LOG_INDEX_ORDERBY], state, action);
  return state;
};
