import React, { Component } from 'react';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import { instanceDashboardRead } from 'client/redux/instance/dashboardActions';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { UserSubscriptionStatusLabel } from 'client/components/Helpers/StatusLabel';
import { connect } from 'react-redux';
import _ from 'lodash';
import 'client/components/Instance/components/InstanceWidgets.css';

class InstanceWidgetPlan extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    instance: propTypes.object,
    plan: propTypes.object,
    subscription: propTypes.object,
    shouldReadInstance: propTypes.bool.isRequired
  }

  static defaultProps = {
    shouldReadInstance: false
  }

  getData(id) {
    return this.props.instanceDashboardRead(id);
  }

  componentDidMount() {
    if(this.props.shouldReadInstance) {
      this.getData(this.props.id);
    }
  }

  renderPlanName() {
    const { plan, instance } = this.props;
    if(!instance) return <span></span>;
    if(!plan) return <span>No active plan</span>;
    return <abbr title={plan.name} className="plan-name">{plan.name}</abbr>;
  }

  renderPlanStatus() {
    const status = _.get(this.props, 'subscription.status', null);
    return <UserSubscriptionStatusLabel className="subscription-status-label" status={status} />
  }

  renderPlanButton() {
    const { subscription, isParentInstance } = this.props;
    if (isParentInstance) return null;
    if(!subscription || subscription.status === 'canceled') return (
      <Link disabled={isParentInstance} className="btn btn-danger btn-sm pull-right" to="/account/subscriptions">
        <i className="icon icon-left mdi mdi-alert-circle"></i>{' '}
        Add plan
      </Link>
    );

    if(['past_due', 'unpaid'].includes(subscription.status)) {
      return (
        <Link className="btn btn-danger btn-sm pull-right" to="/account/billing">
          <i className="icon icon-left mdi mdi-alert-circle"></i>{' '}
          Resolve
        </Link>
      );
    }

    if(subscription.status === 'trialing') {
      return (
        <Link className="btn btn-primary btn-sm pull-right" to="/account/subscriptions">
          <i className="icon icon-left mdi mdi-shopping-cart"></i>{' '}
          Buy connector
        </Link>
      );
    }

    return <Link className="btn btn-default btn-sm pull-right" to="/account/subscriptions">Change plan</Link>;
  }

  getPlanBorderClass() {
    const { instance, subscription } = this.props;
    if(!instance) return '';
    if(!subscription || ['past_due', 'canceled', 'unpaid'].includes(subscription.status)) return 'panel-border-color-danger';
    if(subscription.status === 'trialing') return 'panel-border-color-primary';
    return 'panel-border-color-success';
  }

  render() {
    const { isReading, instance } = this.props;
    const panelClasses = classNames('panel panel-border instance-widget-plan', this.getPlanBorderClass());
    return (
      <div className={panelClasses}>
        <BeLoading style={{height: '100%'}} loading={isReading}>
          <div className="panel-heading">
            {this.renderPlanName()}
          </div>
          <div className="panel-body xs-pt-0">
            <div>
              {instance && this.renderPlanStatus()}
              {instance && this.renderPlanButton()}
            </div>
          </div>
        </BeLoading>
      </div>
    );
  }

}

const actions = {
  instanceDashboardRead
};

export default connect(state => state.instance.dashboard, actions)(InstanceWidgetPlan);
