import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/fatdi/testActions';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import FATDITesterAddObject from 'client/components/FATDI/components/FATDITesterAddObject';
import FATDITesterObject from 'client/components/FATDI/components/FATDITesterObject';

class FATDITester extends Component {

  instanceId() {
    return _.get(this.props, 'instance.id');
  }

  handleAddObject = (objectType, objectId) => {
    this.props.instanceFatdiTestObjectAdd(this.instanceId(), objectType, objectId).then(() => {
      return this.props.instanceFatdiObjectSearchFieldChange(objectType, '');
    });
  }

  handleSearchFieldChange = (objectType, objectId) => {
    return this.props.instanceFatdiObjectSearchFieldChange(objectType, objectId);
  }

  handleRemoveObject = (objectType, objectId) => {
    return this.props.instanceFatdiTestObjectRemove(objectType, objectId);
  }

  handleTestObjectGraph = (objectType, objectId) => {
    return this.props.instanceFatdiTestObjectGraph(this.instanceId(), objectType, objectId);
  }

  handleSyncObject = (objectType, objectId) => {
    return this.props.instanceFatdiSyncObject(this.instanceId(), objectType, objectId);
  }

  render() {
    const {
      endpointsByType: { fast, topdesk },
      searchFields,
      isAddingFastCase,
      isAddingFastErrand,
      isAddingFastOrder,
      isAddingTopdeskIncident,
      fastCases,
      fastOrders,
      fastErrands,
      topdeskIncidents,
    } = this.props;
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_fatdi_test_help" subTitle="Here you can test the options on real objects">
          Test Synchronization
        </InstanceHelpHeader>

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> Clicking "Sync" on an added object below will create or update real data.
          </div>
        </div>

        <h4>TOPdesk Incidents</h4>
        <div className="list-group">
          {Object.keys(topdeskIncidents).length > 0 && _.map(topdeskIncidents, (objectContainer, id) => (
            <FATDITesterObject
              key={id}
              id={id}
              objectContainer={objectContainer}
              objectType="topdeskIncident"
              onRemove={this.handleRemoveObject}
              onSync={this.handleSyncObject}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
          {Object.keys(topdeskIncidents).length < 1 && (
            <div className="list-group-item">
              No TOPdesk Incidents added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <FATDITesterAddObject
              endpointId={topdesk.id}
              searchForWhat="incidents"
              objectId={searchFields.topdeskIncident}
              objectType="topdeskIncident"
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddObject}
              isAdding={isAddingTopdeskIncident}
            />
          </div>
        </div>

        <h4>Fast Cases</h4>
        <div className="list-group">
          {Object.keys(fastCases).length > 0 && _.map(fastCases, (objectContainer, id) => (
            <FATDITesterObject
              key={id}
              id={id}
              objectContainer={objectContainer}
              objectType="fastCase"
              onRemove={this.handleRemoveObject}
              onSync={this.handleSyncObject}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
          {Object.keys(fastCases).length < 1 && (
            <div className="list-group-item">
              No Fast Cases added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <FATDITesterAddObject
              endpointId={fast.id}
              searchForWhat="case"
              objectId={searchFields.fastCase}
              objectType="fastCase"
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddObject}
              isAdding={isAddingFastCase}
            />
          </div>
        </div>

        <h4>Fast Errands</h4>
        <div className="list-group">
          {Object.keys(fastErrands).length > 0 && _.map(fastErrands, (objectContainer, id) => (
            <FATDITesterObject
              key={id}
              id={id}
              objectContainer={objectContainer}
              objectType="fastErrand"
              onRemove={this.handleRemoveObject}
              onSync={this.handleSyncObject}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
          {Object.keys(fastErrands).length < 1 && (
            <div className="list-group-item">
              No Fast Errands added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <FATDITesterAddObject
              endpointId={fast.id}
              searchForWhat="errand"
              objectId={searchFields.fastErrand}
              objectType="fastErrand"
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddObject}
              isAdding={isAddingFastErrand}
            />
          </div>
        </div>

        <h4>Fast Orders</h4>
        <div className="list-group">
          {Object.keys(fastOrders).length > 0 && _.map(fastOrders, (objectContainer, id) => (
            <FATDITesterObject
              key={id}
              id={id}
              objectContainer={objectContainer}
              objectType="fastOrder"
              onRemove={this.handleRemoveObject}
              onSync={this.handleSyncObject}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
          {Object.keys(fastOrders).length < 1 && (
            <div className="list-group-item">
              No Fast Orders added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <FATDITesterAddObject
              endpointId={fast.id}
              searchForWhat="order"
              objectId={searchFields.fastOrder}
              objectType="fastOrder"
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddObject}
              isAdding={isAddingFastOrder}
            />
          </div>
        </div>

      </div>
    );
  }
}

const actions = {
  ...testActions,
};

export default connect(state => state.fatdi.test, actions)(FATDITester);
