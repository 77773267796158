export const PLAN_INDEX_REQUEST = 'PLAN_INDEX_REQUEST';
export const PLAN_INDEX_SUCCESS = 'PLAN_INDEX_SUCCESS';
export const PLAN_INDEX_FAILURE = 'PLAN_INDEX_FAILURE';
export const PLAN_INDEX = [PLAN_INDEX_REQUEST, PLAN_INDEX_SUCCESS, PLAN_INDEX_FAILURE];

export const PLAN_DELETE_REQUEST = 'PLAN_DELETE_REQUEST';
export const PLAN_DELETE_SUCCESS = 'PLAN_DELETE_SUCCESS';
export const PLAN_DELETE_FAILURE = 'PLAN_DELETE_FAILURE';
export const PLAN_DELETE = [PLAN_DELETE_REQUEST, PLAN_DELETE_SUCCESS, PLAN_DELETE_FAILURE];

export const PLAN_INDEX_STRIPE_SYNC_REQUEST = 'PLAN_INDEX_STRIPE_SYNC_REQUEST';
export const PLAN_INDEX_STRIPE_SYNC_SUCCESS = 'PLAN_INDEX_STRIPE_SYNC_SUCCESS';
export const PLAN_INDEX_STRIPE_SYNC_FAILURE = 'PLAN_INDEX_STRIPE_SYNC_FAILURE';
export const PLAN_INDEX_STRIPE_SYNC = [PLAN_INDEX_STRIPE_SYNC_REQUEST, PLAN_INDEX_STRIPE_SYNC_SUCCESS, PLAN_INDEX_STRIPE_SYNC_FAILURE];

export const PLAN_READ_REQUEST = 'PLAN_READ_REQUEST';
export const PLAN_READ_SUCCESS = 'PLAN_READ_SUCCESS';
export const PLAN_READ_FAILURE = 'PLAN_READ_FAILURE';
export const PLAN_READ = [PLAN_READ_REQUEST, PLAN_READ_SUCCESS, PLAN_READ_FAILURE];

export const PLAN_INDEX_ORDERBY = 'PLAN_INDEX_ORDERBY';

export const PLAN_INDEX_SET_PAGE = 'PLAN_INDEX_SET_PAGE';

export const PLAN_INDEX_FILTER = 'PLAN_INDEX_FILTER';

export const PLAN_PANEL_CLEAR = 'PLAN_PANEL_CLEAR';

export const PLAN_PANEL_READ_REQUEST = 'PLAN_PANEL_READ_REQUEST';
export const PLAN_PANEL_READ_SUCCESS = 'PLAN_PANEL_READ_SUCCESS';
export const PLAN_PANEL_READ_FAILURE = 'PLAN_PANEL_READ_FAILURE';
export const PLAN_PANEL_READ = [PLAN_PANEL_READ_REQUEST, PLAN_PANEL_READ_SUCCESS, PLAN_PANEL_READ_FAILURE];

export const PLAN_PANEL_UPDATE_REQUEST = 'PLAN_PANEL_UPDATE_REQUEST';
export const PLAN_PANEL_UPDATE_SUCCESS = 'PLAN_PANEL_UPDATE_SUCCESS';
export const PLAN_PANEL_UPDATE_FAILURE = 'PLAN_PANEL_UPDATE_FAILURE';
export const PLAN_PANEL_UPDATE = [PLAN_PANEL_UPDATE_REQUEST, PLAN_PANEL_UPDATE_SUCCESS, PLAN_PANEL_UPDATE_FAILURE];

export const intervalSelect2 = [
  {id: 'year', text: 'Year'},
  {id: 'month', text: 'Month'},
  {id: 'week', text: 'Week'},
  {id: 'day', text: 'Day'}
];

export const typeSelect2 = [
  {id: 'connector', text: 'Connector'},
  {id: 'category', text: 'Category'},
  {id: 'everything', text: 'Everything'}
];

export const isSubscribableSelect2 = [
  {id: false, text: 'Not subscribable'},
  {id: true, text: 'Subscribable'}
];

export const typeToNatural = type => {
  switch(type) {
    default: return type;
    case 'connector': return 'Connector';
    case 'category': return 'Category';
    case 'everything': return 'Everything';
  }
};
