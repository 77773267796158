import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import BeSpinner from 'client/components/Be/BeSpinner/BeSpinner';

export default class BeLoading extends Component {

  static propTypes = {
    positionStatic: propTypes.bool,
    loading: propTypes.bool,
    style: propTypes.object,
    spinnerClass: propTypes.string
  }

  static defaultProps = {
    spinnerClass: '',
    positionStatic: false,
    loading: false,
    style: {}
  }

  render() {
    const { loading, className, style, positionStatic, spinnerClass } = this.props;
    const classes = classNames(className, 'be-loading', loading ? 'be-loading-active' : '');
    const divStyle = {...style};
    if(positionStatic) divStyle.position = 'static';
    return (
      <div className={classes} style={divStyle}>
        {this.props.children}
        {loading && <BeSpinner className={spinnerClass} />}
      </div>
    );
  }

}
