import * as constants from 'client/redux/qrtd/constants';
import { v4 as uuid } from 'uuid';
import { reduceByTypes } from 'client/redux/apiHelpers';
import { get, isUndefined, omitBy } from 'lodash';

const defaultState = {
  chosenAssetIds: [],
  chosenLocationIds: [],
  isReadingInstance: false,
  isReadingAssets: false,
  isReadingLocations: false,
  instance: null,
  error: null,
  assets: null,
  locations: null,
  codes: [],
  codeSettingsByCodeId: {},
  incidentFieldMaps: [],
  infoTexts: [],
  settings: {
    size: 9,
    renderQrCodes: false,
    defaultCodeSettings: {
      topText: 'Report a problem...',
      topTextShow: true,
      bottomText: '%name%',
      bottomTextShow: true,
      qrCodeBgColor: '#FFFFFF',
      qrCodeFgColor: '#000000',
      qrCodeBgExtend: false,
      features: [],
      fieldMapOrdinal: '',
      infoTextOrdinal: '',
    }
  },
};

export default function printPageReducer(state = defaultState, action) {

  state = reduceByTypes(constants.INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META, state, action, {
    requestProp: 'isReadingInstance',
    successPickProps: ['instance', 'endpointsByType', 'infoTexts', 'incidentFieldMaps'],
    errorProp: 'error',
  });

  state = reduceByTypes(constants.INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS, state, action, {
    requestProp: 'isReadingAssets',
    successPickProps: ['assets'],
    errorProp: 'error',
  });

  state = reduceByTypes(constants.INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS, state, action, {
    requestProp: 'isReadingLocations',
    successPickProps: ['locations'],
    errorProp: 'error',
  });

  switch(action.type) {
    default: return state;

    // whenever assets are successfully read, corresponding codes should created or updated
    case constants.INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_SUCCESS: {
      const codes = state.assets.reduce((codes, asset) => {
        const matching = codes.find(code => code.asset && code.asset.unid === asset.unid);
        if(matching) {
          matching.asset = asset;
        } else {
          codes.push({
            id: uuid(),
            asset,
          });
        }
        return codes;
      }, state.codes.slice(0));
      return {
        ...state,
        codes,
      };
    }

    case constants.INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_SUCCESS: {
      const codes = state.locations.reduce((codes, location) => {
        const matching = codes.find(code => code.location && code.location.id === location.id);
        if(matching) {
          matching.location = location;
        } else {
          codes.push({
            id: uuid(),
            location,
          });
        }
        return codes;
      }, state.codes.slice(0));
      return {
        ...state,
        codes,
      };
    }

    case constants.INSTANCE_QRTD_PRINT_PAGE_CREATE_CODE: return {
      ...state,
      codes: [
        {id: uuid()},
        ...state.codes,
      ],
    };

    case constants.INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_SUCCESS: return {
      ...state,
      // some settings can be changed globally on the actual instance settings database row
      // these settings are copied into the print page settings here
      settings: {
        ...state.settings,
        size: parseInt(get(action, 'payload.instance.settings.defaultQrPrintPageSize'), 10) || 9,
        defaultCodeSettings: {
          ...state.settings.defaultCodeSettings,
          topText: get(action, 'payload.instance.settings.defaultTopText') || '',
          bottomText: get(action, 'payload.instance.settings.defaultBottomText') || '',
          qrCodeFgColor: get(action, 'payload.instance.settings.defaultQrCodeFgColor') || '',
          qrCodeBgColor: get(action, 'payload.instance.settings.defaultQrCodeBgColor') || '',
          qrCodeBgExtend: get(action, 'payload.instance.settings.defaultQrCodeBgExtend') || false,
          features: get(action, 'payload.instance.settings.defaultLinkTemplate.features') || [],
          fieldMapOrdinal: String(get(action, 'payload.instance.settings.defaultLinkTemplate.fieldMapOrdinal')) || '',
          infoTextOrdinal: String(get(action, 'payload.instance.settings.defaultLinkTemplate.infoTextOrdinal')) || '',
        },
      },
    };

    case constants.INSTANCE_QRTD_PRINT_PAGE_UPDATE_CODE_SETTING: return {
      ...state,
      codeSettingsByCodeId: {
        ...state.codeSettingsByCodeId,
        [action.codeId]: action.settings ? omitBy({...get(state, ['codeSettingsByCodeId', action.codeId], {}), ...action.settings}, isUndefined) : {},
      },
    };

    case constants.INSTANCE_QRTD_PRINT_PAGE_UPDATE_SETTING: return {
      ...state,
      settings: {
        ...state.settings,
        [action.key]: action.value,
      },
    };

    case constants.INSTANCE_QRTD_PRINT_PAGE_SET_CHOSEN_IDS: return {
      ...state,
      chosenAssetIds: action.assetIds,
      chosenLocationIds: action.locationIds,
    };
  }
};
