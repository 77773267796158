import React from 'react';
import Item from 'client/components/App/LeftSidebar/LeftSidebarItem';
import ItemWithSubmenu from 'client/components/App/LeftSidebar/LeftSidebarItemWithSubmenu';
import classNames from 'classnames';
import $ from 'jquery';
import { uiProps } from 'client/redux/ui/actions';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import LeftSidebarInstanceList from 'client/components/App/LeftSidebar/LeftSidebarInstanceList';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BeLoading from 'client/components/Be/BeLoading';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'client/components/App/LeftSidebar/LeftSidebar.css';

class LeftSidebar extends React.Component {

  static propTypes = {
    connectorInstancesByStatus: propTypes.object,
    connectorsById: propTypes.object
  }

  TOGGLE_SPEED = 300;

  state = {
    sidebarOpen: false
  }

  componentDidMount() {
    this.props.uiProps();
  }

  toggleSidebar = ev => {
    const nextOpen = !this.state.sidebarOpen;
    this.setState({sidebarOpen: nextOpen}, () => {
      const spacer = $(this.elSidebarSpacer);
      spacer.slideToggle(this.TOGGLE_SPEED, () => {
        spacer.removeAttr('style').toggleClass('open');
      });
    });
  }

  render() {
    const { connectorsById, isWaiting, connectorInstancesByStatus } = this.props;
    if(!connectorInstancesByStatus || !connectorsById) return null;
    const selfUserId = this.props?.self?.id;
    const { setup, active, inactive, paused, error } = this.props.connectorInstancesByStatus;
    const toggleButtonClasses = classNames('left-sidebar-toggle', this.state.sidebarOpen ? 'open' : '');
    return (
      <div className="be-left-sidebar ln-left-sidebar">
        <div className="left-sidebar-wrapper">
          <a className={toggleButtonClasses} onClick={this.toggleSidebar} href="#">Menu</a>{/*eslint-disable-line*/}
          <div className="left-sidebar-spacer" ref={el => { this.elSidebarSpacer = el; }}>
            <PerfectScrollbar className="left-sidebar-scroll ps-container ps-theme-default">
              <BeLoading className="left-sidebar-content" loading={isWaiting}>
                <ul className="sidebar-elements">
                  <li className="divider">Menu</li>
                  <Item to="/" activeOnExact={true} icon="mdi-home">Home</Item>
                  <Item to="/marketplace" icon="mdi-collection-item">Marketplace</Item>
                  <Item to="/support" icon="mdi-help-outline">Support</Item>
                </ul>
                <LeftSidebarInstanceList
                  title="Waiting for setup"
                  forKey="setup"
                  instances={setup}
                  connectorsById={connectorsById}
                  selfUserId={selfUserId}
                />
                <LeftSidebarInstanceList
                  title="Active"
                  forKey="active"
                  instances={active}
                  connectorsById={connectorsById}
                  selfUserId={selfUserId}
                />
                <LeftSidebarInstanceList
                  title="Inactive"
                  forKey="inactive"
                  instances={inactive}
                  connectorsById={connectorsById}
                  selfUserId={selfUserId}
                />
                <LeftSidebarInstanceList
                  title="Paused"
                  forKey="paused"
                  instances={paused}
                  connectorsById={connectorsById}
                  selfUserId={selfUserId}
                />
                <LeftSidebarInstanceList
                  title="Error"
                  forKey="error"
                  instances={error}
                  connectorsById={connectorsById}
                  selfUserId={selfUserId}
                />
                <IsAdmin>
                  <ul className="sidebar-elements">
                    <li className="divider">Admin</li>
                    <ItemWithSubmenu index={true} to="/user" icon="mdi-accounts" title="Users" activeFor={["/user/log", "/user/subscription"]}>
                      <Item activeOnExact={true} icon="mdi-accounts" to="/user">User Overview</Item>
                      <Item icon="mdi-view-list" to="/user/log">User Logs</Item>
                      <Item icon="mdi-group" to="/user/subscription">User Subscriptions</Item>
                      <Item icon="mdi-receipt" to="/user/invoice">User Invoices</Item>
                    </ItemWithSubmenu>
                    <ItemWithSubmenu index={true} to="/instance" icon="mdi-swap-alt" title="Instances" activeFor={["/instance/log"]}>
                      <Item activeOnExact={true} icon="mdi-swap-alt" to="/instance">Instance Overview</Item>
                      <Item activeOnExact={true} icon="mdi-view-list" to="/instance/log">Instance Logs</Item>
                      <Item activeOnExact={true} icon="mdi-view-list" to="/instance/log/fragment">Ins. Log Fragments</Item>
                    </ItemWithSubmenu>
                    <ItemWithSubmenu index={true} to="/endpoint" icon="mdi-input-power" title="Endpoints" activeFor={["/endpoint/log"]}>
                      <Item activeOnExact={true} icon="mdi-input-power" to="/endpoint">Endpoint Overview</Item>
                      <Item icon="mdi-view-list" to="/endpoint/log">Endpoint Logs</Item>
                    </ItemWithSubmenu>
                    <Item to="/connector" icon="mdi-collection-item">Connectors</Item>
                    <Item to="/plan" icon="mdi-layers">Plans</Item>
                    <Item to="/settings" icon="mdi-settings">Settings</Item>
                    <Item to="/uiFragments" icon="mdi-settings">UI Fragments</Item>
                  </ul>
                </IsAdmin>
              </BeLoading>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    )
  }

}

// TODO pure: false? Doesn't sound good (to make Link active class update)
export default connect(state => state.ui, { uiProps }, null, {pure: false})(LeftSidebar);
