import { INSTANCE_UPDATE_TITLE } from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isUpdating: false,
};

export default function titleModalReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_UPDATE_TITLE, state, action, {requestProp: 'isUpdating', successPickProps: []});
  return state;
};
