import React from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/swtd/testActions';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import SWTDTesterComputer from 'client/components/SWTD/SWTDTesterComputer';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { EndpointSnowRemoteSearcher } from 'client/components/Form/Select2Searchers';

class SWTDTester extends React.Component {

  state = {
    computerId: '',
  }

  instanceId() {
    return _.get(this.props, 'instance.id');
  }

  handleAddComputer = () => {
    const { computerId } = this.state;
    if(!computerId) return;
    this.props.instanceSwtdTestComputerAdd(this.instanceId(), computerId).then(() => {
      this.setState({computerId: ''});
    });
  }

  handleRemoveComputer = computerId => {
    return this.props.instanceSwtdTestComputerRemove(computerId);
  }

  handleSyncComputer = computerId => {
    return this.props.instanceSwtdSyncComputer(this.instanceId(), computerId);
  }

  handleTestObjectGraph = snowComputerId => {
    return this.props.instanceSwtdTestObjectGraph(this.instanceId(), snowComputerId);
  }

  handleComputerIdChange = computerId => {
    this.setState({computerId});
  }

  render() {
    const {
      endpointsByType: { snow: { id:snowEndpointId } },
      isAdding,
      computers,
      error,
    } = this.props;
    const { computerId } = this.state;
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_swtd_test_help" subTitle="Here you can simulate the options on real Snow computers">
          Test Synchronization
        </InstanceHelpHeader>

        <BeRemoteErrorAlert error={error} />

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> Clicking "Sync" on an added Snow Computer below will create or update real data in TOPdesk.
          </div>
        </div>

        <div className="list-group">
          {computers.map(computer => (
            <SWTDTesterComputer
              key={computer.snowComputerId}
              id={computer.snowComputerId}
              computer={computer}
              onRemove={this.handleRemoveComputer}
              onSync={this.handleSyncComputer}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
        </div>

        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <div className="form-group">
              <div className="input-group">
                <EndpointSnowRemoteSearcher
                  id="snowTesterSelectComputer"
                  name="computerId"
                  endpointId={snowEndpointId}
                  forWhat="computer"
                  onChange={this.handleComputerIdChange}
                  options={{allowClear: true, placeholder: 'Search by Snow computer ID or hostname', minimumInputLength: 1}}
                  defaultValue={computerId}
                />
                <span className="input-group-btn">
                  <ButtonWaiting
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleAddComputer}
                    isWaiting={isAdding}
                    disabled={!computerId}
                  >
                    Add computer
                  </ButtonWaiting>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const actions = {
  ...testActions,
};

export default connect(state => state.swtd.test, actions)(SWTDTester);
