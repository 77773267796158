import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ACCOUNT_CARD_ADD_SUCCESS } from 'client/redux/account/constants';
import { injectStripe } from 'react-stripe-elements';
import AccountAddCardForm  from 'client/components/Account/components/AccountAddCardForm';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { accountCardAdd } from 'client/redux/account/cardAddActions';
import propTypes from 'prop-types';

class AccountAddCard extends Component {

  static propTypes = {
    onAdd: propTypes.func
  }

  static defaultProps = {
    onAdd: function() {}
  }

  handleSubmitForm = ({address}) => {
    const { stripe, accountCardAdd } = this.props;
    const stripeFields = {
      type: 'card',
      name: address.name,
      address_line1: address.line1,
      address_line2: address.line2,
      address_state: address.state,
      address_city: address.city,
      address_zip: address.zip,
      address_country: address.country,
    };

    return stripe.createToken(stripeFields).then(result => {
      const { token } = result;
      return accountCardAdd({tokenId: token.id}).then(getResponseToFormErrorHandler()).then(result => {
        if(result.type === ACCOUNT_CARD_ADD_SUCCESS) {
          return this.props.onAdd();
        }
      });
    });
  }

  render() {
    const { error } = this.props;
    return (
      <div>
        <AccountAddCardForm onSubmit={this.handleSubmitForm} />
        <BeRemoteErrorAlert className="xs-mt-20" error={error} />
      </div>
    );
  }

}

export default injectStripe((connect(state => state.account.cardAdd, { accountCardAdd}))(AccountAddCard));
