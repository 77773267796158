import React, { Component } from 'react';
import * as newsEditPanelActions from 'client/redux/news/editPanelActions';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import NewsPanelForm from 'client/components/News/components/NewsPanelForm';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';

class NewsEditPanel extends Component {

  static propTypes = {
    onSave: propTypes.func,
    newsItem: propTypes.object.isRequired
  }

  handleUpdate = fields => {
    const id = this.props.newsItem.id;
    const { onSave } = this.props;
    return this.props.newsEditPanelUpdate(id, fields).then(getResponseToFormErrorHandler()).then(onSave);
  }

  render() {
    const { error, newsItem } = this.props;
    return (
      <div>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        <NewsPanelForm
          onSubmit={this.handleUpdate}
          error={error}
          initialValues={newsItem}
        />
      </div>
    );
  }

}

const actions = {
  ...newsEditPanelActions,
};

export default connect(state => state.news.editPanel, actions)(NewsEditPanel);
