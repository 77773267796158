import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/user/invoice/indexActions';
import { onUserInvoiceDelete } from 'client/redux/user/invoice/eventHandlerActions';
import UserInvoiceTable from 'client/components/UserInvoice/components/UserInvoiceTable';
import _ from 'lodash';
import { getStateSearch } from 'client/redux/apiHelpers';

class UserInvoiceIndex extends Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    const filter = {...this.props.filter, ...this.props.search};
    return this.handleFilter(filter);
  }

  getData = (overrideWith = {}) => {
    const { orderBy, orderDirection, page, filter } = this.props;
    return this.props.userInvoiceIndex({...filter, orderBy, orderDirection, page, ...overrideWith});
  }

  handleOrderBy = (orderBy, orderDirection) => {
    this.props.userInvoiceIndexOrderBy(orderBy, orderDirection);
    return this.getData({orderBy, orderDirection});
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    this.props.userInvoiceIndexSetPage(page);
    return this.getData({page});
  }

  handleFilter = filter => {
    this.props.userInvoiceIndexFilter(filter);
    return this.getDataDebounced(filter);
  }

  handleDeleteUserInvoice = (id, force = false) => {
    const query = force ? {force: '1', paranoid: '0'} : {};
    return this.props.userInvoiceDelete(id, query).then(this.props.onUserInvoiceDelete).then(this.getData);
  }

  render() {
    const { isReading, invoices, orderBy, orderDirection, page, pages, limit, offset, count, filter, error } = this.props;
    return (
      <Content>
        <Helmet>
          <title>User Invoice Overview</title>
        </Helmet>
        <MainContent>
          <BeRemoteErrorAlert error={error} />
          <UserInvoiceTable
            className="panel-default panel-border"
            loading={isReading}
            invoices={invoices}
            onOrderBy={this.handleOrderBy}
            onSetPage={this.handleSetPage}
            onDeleteUserInvoice={this.handleDeleteUserInvoice}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onFilter={this.handleFilter}
            onRefresh={this.getData}
            filter={filter}
            count={count}
            limit={limit}
            offset={offset}
            page={page}
            pages={pages}
          />
        </MainContent>
      </Content>
    );
  }

}

const mapStateToProps = state => ({
  ...state.user.invoiceIndex,
  search: getStateSearch(state),
});

const actions = {
  ...indexActions,
  onUserInvoiceDelete,
};

export default connect(mapStateToProps, actions)(UserInvoiceIndex);
