import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import * as accountInvoiceReadActions from 'client/redux/account/invoiceReadActions';
import UserInvoice from 'client/components/UserInvoice/components/UserInvoice';
import _ from 'lodash';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

class AccountInvoiceRead extends Component {

  invoiceId(props) {
    return _.get(props || this.props, 'match.params.invoiceId');
  }

  componentDidMount() {
    this.props.accountInvoiceRead(this.invoiceId());
  }

  getTitle() {
    const number = _.get(this.props, 'invoiceObject.number');
    if(!number) return 'Account Invoice';
    return `Account Invoice #${number}`;
  }

  render() {
    const {
      isReading,
      error,
      invoiceObject,
      invoice,
      sender,
      recipient,
      reverseCharge,
      instances,
      instancesByStripePlanId,
    } = this.props;
    const vatConversion = _.get(invoice, 'vatConversion');
    return (
      <Content>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <MainContent>
          <BeRemoteErrorAlert error={error} />
          <BeLoading positionStatic={true} loading={isReading}>
            {invoiceObject && <UserInvoice
              invoice={invoiceObject}
              vatConversion={vatConversion}
              sender={sender}
              recipient={recipient}
              showCharges={true}
              instances={instances}
              instancesByStripePlanId={instancesByStripePlanId}
              reverseCharge={reverseCharge}
            />}
          </BeLoading>
        </MainContent>
      </Content>
    );
  }
}

const actions = {
  ...accountInvoiceReadActions,
};

export default connect(state => state.account.invoiceRead, actions)(AccountInvoiceRead);
