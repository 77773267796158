import { INSTANCE_SETUP_READ, INSTANCE_SETUP_UPDATE, INSTANCE_SETUP_UPDATE_STATUS } from 'client/redux/instance/constants';
import * as actionCreators from 'client/redux/instance/actionCreators';

export const instanceSetupRead = id => dispatch => {
  return dispatch(actionCreators.instanceSetupRead(INSTANCE_SETUP_READ, id));
};

export const instanceSetupUpdate = (id, form) => dispatch => {
  return dispatch(actionCreators.instanceSetupUpdate(INSTANCE_SETUP_UPDATE, id, form));
};

export const instanceSetupUpdateStatus = (id, newStatus) => dispatch => {
  return dispatch(actionCreators.instanceUpdateStatus(INSTANCE_SETUP_UPDATE_STATUS, id, newStatus));
};
