import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const endpointTopdeskRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/topdesk/${id}`,
    method: 'GET',
    types
  });
};

export const endpointTopdeskEditCredentials = (type, isEditing) => {
  return {
    type,
    isEditing
  };
};

export const endpointTopdeskUpdateCredentials = (types, id, credentials) => {
  return apiAction({
    endpoint: `/api/endpoint/topdesk/${id}/credentials`,
    method: 'PATCH',
    types,
    json: credentials
  });
};

export const endpointTopdeskTestCredentials = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/topdesk/${id}/credentials/test`,
    method: 'POST',
    types,
    json: {}
  });
};

export const endpointTopdeskAssetTemplateFieldTargets = (types, id, assetTemplateNameOrId) => apiAction({
  endpoint: `/api/endpoint/topdesk/${id}/assetTemplate/${assetTemplateNameOrId}/fieldTargets`,
  method: 'GET',
  types, // NOTE: Do not map meta here
});

export const endpointTopdeskSearchRemoteAssets = (types, id, query = {}) => apiAction({
  endpoint: appendQuery(`/api/endpoint/topdesk/${id}/searchRemote/assets`, query),
  method: 'GET',
  types: types.map(type => ({type, meta: {id, query}})),
});

export const endpointTopdeskSearchRemoteLocations = (types, id, query = {}) => apiAction({
  endpoint: appendQuery(`/api/endpoint/topdesk/${id}/searchRemote/locations`, query),
  method: 'GET',
  types: types.map(type => ({type, meta: {id, query}})),
});
