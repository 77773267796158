export const USER_LOG_INDEX_REQUEST = 'USER_LOG_INDEX_REQUEST';
export const USER_LOG_INDEX_SUCCESS = 'USER_LOG_INDEX_SUCCESS';
export const USER_LOG_INDEX_FAILURE = 'USER_LOG_INDEX_FAILURE';
export const USER_LOG_INDEX = [USER_LOG_INDEX_REQUEST, USER_LOG_INDEX_SUCCESS, USER_LOG_INDEX_FAILURE];

export const USER_LOG_INDEX_ORDERBY = 'USER_LOG_INDEX_ORDERBY';

export const USER_LOG_INDEX_SET_PAGE = 'USER_LOG_INDEX_SET_PAGE';

export const USER_LOG_INDEX_FILTER = 'USER_LOG_INDEX_FILTER';

export const levelSelect2 = [
  {id: 'error', text: 'Error'},
  {id: 'warning', text: 'Warning'},
  {id: 'info', text: 'Info'},
  {id: 'success', text: 'Success'}
];
