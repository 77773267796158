import { apiAction } from 'client/redux/apiHelpers';

export const signup = (types, form) => {
  return apiAction({
    endpoint: '/api/user/signup',
    method: 'POST',
    types,
    json: form
  });
};

