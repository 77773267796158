import { ENDPOINT_GRAPH_READ, ENDPOINT_GRAPH_PRELOADED, ENDPOINT_GRAPH_TEST_CONSENT } from 'client/redux/endpoint/graph/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isTesting: false,
  error: null,
  endpoint: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(ENDPOINT_GRAPH_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(ENDPOINT_GRAPH_TEST_CONSENT, state, action, {requestProp: 'isTesting', successSpread: true});
  switch(action.type) {
    case ENDPOINT_GRAPH_PRELOADED: return {
      ...state,
      isReading: false,
      endpoint: action.endpoint,
      error: null,
    };
    default:
      return state
  }
};
