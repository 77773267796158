import * as actionCreators from 'client/redux/account/actionCreators';
import {
  ACCOUNT_FORM_TOGGLE_PART,
  ACCOUNT_FORM_HIDE_ALL_PARTS,
  ACCOUNT_FIELDS,
  ACCOUNT_UPDATE,
  ACCOUNT_CHILDREN_READ,
  ACCOUNT_CHILDREN_DELETE,
} from 'client/redux/account/constants';

export const accountFormTogglePart = (part, show) => dispatch => {
  return dispatch(actionCreators.accountFormTogglePart(ACCOUNT_FORM_TOGGLE_PART, part, show));
};

export const accountFormHideAllParts = () => dispatch => {
  return dispatch(actionCreators.accountFormHideAllParts(ACCOUNT_FORM_HIDE_ALL_PARTS));
};

export const accountFields = () => dispatch => {
  return dispatch(actionCreators.accountFields(ACCOUNT_FIELDS));
};

export const accountUpdate = form => dispatch => {
  return dispatch(actionCreators.accountUpdate(ACCOUNT_UPDATE, form));
};

export const accountChildrenRead = () => dispatch => {
  return dispatch(actionCreators.accountChildrenRead(ACCOUNT_CHILDREN_READ));
};

export const accountChildrenDelete = (childUserId) => dispatch => {
  return dispatch(actionCreators.accountChildrenDelete(ACCOUNT_CHILDREN_DELETE, childUserId));
};
