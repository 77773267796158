import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceFatdiCall = (types, id, method, data = {}) => {
  return apiAction({
    endpoint: `/api/instance/fatdi/${id}/call/${method}`,
    method: 'POST',
    json: data,
    types
  });
};

export const instanceFatdiReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/fatdi/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceFatdiReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/fatdi/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceFatdiTestObjectAdd = (types, id, objectType, objectId) => {
  const meta = {objectType, objectId};
  return apiAction({
    endpoint: `/api/instance/fatdi/${id}/test/${objectType}/${objectId}`,
    method: 'GET',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceFatdiTestObjectGraph = (types, id, objectType, objectId) => {
  const meta = {objectType, objectId};
  return apiAction({
    endpoint: `/api/instance/fatdi/${id}/test/${objectType}/${objectId}/objectGraph`,
    method: 'GET',
    types: types.map(type => ({type, meta})),
    options: { timeout: 600000 },
  });
};

export const instanceFatdiSyncObject = (types, id, objectType, objectId) => {
  const meta = {objectType, objectId, sync: true};
  return apiAction({
    endpoint: `/api/instance/fatdi/${id}/sync/${objectType}/${objectId}`,
    method: 'POST',
    types: types.map(type => ({type, meta})),
    options: { timeout: 600000 },
  });
};

export const instanceFatdiTestObjectRemove = (type, objectType, objectId) => {
  return {type, meta: {objectType, objectId}};
};

export const instanceFatdiObjectTestClear = type => {
  return {type};
};

export const instanceFatdiObjectSearchFieldChange = (type, objectType, objectId) => {
  return {type, meta: {objectType, objectId}};
};
