import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/connector/indexActions';
import ConnectorTable from 'client/components/ConnectorIndex/components/ConnectorTable';
import { getStateSearch } from 'client/redux/apiHelpers';
import _ from 'lodash';

class ConnectorIndex extends Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    const filter = {...this.props.filter, ...this.props.search};
    return this.handleFilter(filter);
  }

  getData = (overrideWith = {}) => {
    const { orderBy, orderDirection, page, filter } = this.props;
    return this.props.connectorIndex({...filter, orderBy, orderDirection, page, ...overrideWith});
  }

  handleOrderBy = (orderBy, orderDirection) => {
    this.props.connectorIndexOrderBy(orderBy, orderDirection);
    return this.getData({orderBy, orderDirection});
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    this.props.connectorIndexSetPage(page);
    return this.getData({page});
  }

  handleFilter = newFilter => {
    this.props.connectorIndexFilter(newFilter);
    this.getDataDebounced();
  }

  renderTitle() {
    return (
      <Helmet>
        <title>Connector Index Overview</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const { isReading, connectors, orderBy, orderDirection, page, pages, limit, offset, count, error, filter } = this.props;
    if(error) return this.renderError(error);
    return (
      <Content>
        <MainContent>
          {this.renderTitle()}
          <ConnectorTable
            className="panel-default panel-border"
            onOrderBy={this.handleOrderBy}
            onSetPage={this.handleSetPage}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onFilter={this.handleFilter}
            onRefresh={this.getData}
            filter={filter}
            connectors={connectors}
            count={count}
            limit={limit}
            offset={offset}
            page={page}
            pages={pages}
            loading={isReading}
          />
        </MainContent>
      </Content>
    );
  }

}

const mapStateToProps = state => ({
  ...state.connector.index,
  search: getStateSearch(state),
});

export default connect(mapStateToProps, indexActions)(ConnectorIndex);
