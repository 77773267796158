import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import { ObjectInspector, chromeLight } from 'react-inspector';
import SwitchButton from 'client/components/Form/SwitchButton';
import { IconAlert } from 'client/components/Be/Icon';
import classNames from 'classnames';

export class ModalButton extends Component {

  static defaultProps = {
    modalProps: {},
    onClose: function() {},
    anchor: false,
    title: ''
  }

  static propTypes = {
    ButtonComponent: propTypes.func,
    ModalComponent: propTypes.elementType.isRequired,
    modalProps: propTypes.object,
    onClose: propTypes.func,
    disabled: propTypes.bool,
    anchor: propTypes.bool,
    title: propTypes.string
  }

  state = {
    isOpen: false
  }

  closeModal = data => {
    this.setState(() => ({isOpen: false}), () => {
      this.props.onClose(data);
    });
  }

  openModal = ev => {
    this.setState({isOpen: true});
    ev.preventDefault();
  }

  renderClicker() {
    const { className, title, children, disabled } = this.props;
    const { anchor } = this.props;
    if(anchor) {
      return (
        <a key="clicker" className={className} href="#" onClick={this.openModal} title={title}>{children}</a> // eslint-disable-line
      );
    }
    return (
      <button
        key="clicker"
        type="button"
        className={className}
        disabled={disabled}
        onClick={this.openModal}
        title={title}>
        {children}
      </button>
    );
  }

  render() {
    const { ModalComponent } = this.props;
    const { isOpen } = this.state;
    const modalProps = {...this.props.modalProps};
    return (
      <>
        {this.renderClicker()}
        <ModalComponent key="modal" {...modalProps} open={isOpen} onClose={this.closeModal} />
      </>
    );
  }

}

export class ModalClose extends Component {

  static propTypes = {
    onClose: propTypes.func.isRequired
  }

  render() {
    const { onClose } = this.props;
    return (
      <button onClick={onClose} type="button" data-dismiss="modal" aria-hidden="true" className="close modal-close">
        <span className="mdi mdi-close"></span>
      </button>
    );
  }

}

/**
 * @description A predefined modal that shows an object as formatted json
 */
export class ModalFormattedObject extends Component {

  static propTypes = {
    title: propTypes.string.isRequired,
    object: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
    onClose: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
  }

  static theme = {
    ...chromeLight,
    BASE_BACKGROUND_COLOR: 'transparent',
  }

  state = {
    inspectorIsObject: true,
  }

  handleChangeInspector = ev => {
    this.setState({
      inspectorIsObject: ev.target.checked,
    });
  }

  render() {
    const { onClose, open, title, object } = this.props;
    const { inspectorIsObject } = this.state;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose}>
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="xs-pt-15">
          {open && (
            <div>
              <div className="xs-mb-10">
                <small className="xs-mr-5">Inspector: </small>
                <SwitchButton
                  id="modalFormattedObjectShowInspector"
                  value={inspectorIsObject}
                  onChange={this.handleChangeInspector}
                  size="sm"
                />
              </div>
              <pre className="well">
                {inspectorIsObject ? (
                  <ObjectInspector theme={ModalFormattedObject.theme} data={object} expandLevel={1} />
                ) : (
                  <>{JSON.stringify(object, null, 2)}</>
                )}
              </pre>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

}

/**
 * @description A simple modal with a title
 */
export class ModalSimple extends Component {

  static propTypes = {
    id: propTypes.string,
    title: propTypes.node,
    isOpen: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    dialogClassName: propTypes.string,
    bodyClassName: propTypes.string,
  }

  render() {
    const { id, children, title, isOpen, onClose, dialogClassName, bodyClassName } = this.props;
    return (
      <Modal id={id} dialogClassName={dialogClassName} show={isOpen} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
        <Modal.Body className={bodyClassName}>
          {children}
        </Modal.Body>
      </Modal>
    );
  }

}

/**
 * @description A modal that opens with a confirmation dialog, callbacking if an action was confirmed
 */
export class ModalConfirmButton extends Component {

  static propTypes = {
    callback: propTypes.func.isRequired,
    disabled: propTypes.bool,
    Content: propTypes.func.isRequired,
    anchor: propTypes.bool,
    active: propTypes.bool,
    contentProps: propTypes.object,
  }

  static defaultProps = {
    contentProps: {},
    disabled: false,
    anchor: false,
    active: true,
  }

  state = {
    isOpen: false
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  openModal = ev => {
    const { active, callback } = this.props;
    ev.preventDefault();
    if (active) {
      this.setState({isOpen: true});
    } else {
      this.closeModal();
      return callback();
    }
  }

  closeModal = () => {
    if(!this.willUnmount) this.setState({isOpen: false});
  }

  handleYes = () => {
    const promise = this.props.callback();
    if(promise && promise.then) {
      return promise.then(() => {
        return this.closeModal();
      });
    }
    return this.closeModal();
  }

  handleNo = () => {
    this.closeModal();
  }

  renderClicker() {
    const { anchor, className, title, children, disabled } = this.props;

    if(anchor) {
      return (
        <a href="#" className={className} onClick={this.openModal} title={title}>{children}</a> // eslint-disable-line
      );
    }
    return (
      <button type="button" className={className} onClick={this.openModal} disabled={disabled}>
        {children}
      </button>
    );
  }

  render() {
    const { isOpen } = this.state;
    const { dialogClassName, Content, contentProps } = this.props;
    return (
      <>
        {this.renderClicker()}
        <ModalSimple key="modal" dialogClassName={dialogClassName} onClose={this.closeModal} isOpen={isOpen}>
          <Content {...contentProps} onYes={this.handleYes} onNo={this.handleNo} />
        </ModalSimple>
      </>
    );
  }

}

export class ModalContentConfirm extends React.Component {

  static propTypes = {
    type: propTypes.string,
    title: propTypes.node,
    text: propTypes.node,
    onYes: propTypes.func.isRequired,
    onNo: propTypes.func.isRequired,
    yesText: propTypes.node,
    noText: propTypes.node,
  }

  static defaultProps = {
    type: 'primary',
    title: 'Confirm action',
    text: 'Are you sure you wish to proceed?',
    yesText: 'Yes',
    noText: 'No',
  }

  render() {
    const {
      title,
      text,
      onYes,
      onNo,
      yesText,
      noText,
      type,
      icon,
    } = this.props;
    return (
      <div className="text-center">
        <div className={`text-${type}`}>
          <IconAlert className="modal-main-icon" type={type} icon={icon} />
        </div>
        <h3>{title}</h3>
        <p>{text}</p>
        <div className="xs-mt-50">
          {yesText && <ButtonWaitingPromise className={classNames('btn btn-space', `btn-${type}`)} onClick={onYes}>{yesText}</ButtonWaitingPromise>}
          {noText && <ButtonWaitingPromise type="button" data-dismiss="modal" className="btn btn-space btn-default" onClick={onNo}>{noText}</ButtonWaitingPromise>}
        </div>
      </div>
    );
  }

}

/**
 * @desc Body for showing a modal confirming a warning
 */
export class ModalContentConfirmWarning extends Component {

  static propTypes = {
    title: propTypes.node,
    text: propTypes.node,
    onYes: propTypes.func.isRequired,
    onNo: propTypes.func.isRequired
  }

  static defaultProps = {
    title: 'Confirm action',
    text: 'Are you sure you wish to proceed?'
  }

  render () {
    const { title, text, onYes, onNo } = this.props;
    return (
      <ModalContentConfirm
        type="warning"
        title={title}
        text={text}
        onYes={onYes}
        onNo={onNo}
        noText="Cancel"
        yesText="Proceed"
      />
    );
  }

}

export class ModalObjectInspectorButton extends React.Component {

  static propTypes = {
    object: propTypes.object.isRequired,
    title: propTypes.string.isRequired,
    showInspect: propTypes.bool.isRequired,
  }

  static defaultProps = {
    showInspect: false,
  }

  render() {
    const { object, title, showInspect, className } = this.props;
    return (
      <ModalButton anchor className={className || 'btn btn-default'} ModalComponent={ModalFormattedObject} modalProps={{object, title}}>
        <i className="icon mdi mdi-search"></i>{' '}
        {showInspect && <span>Inspect</span>}
      </ModalButton>
    );
  }

}
