import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import BeLoading from 'client/components/Be/BeLoading';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { connect } from 'react-redux';
import * as subscriptionActions from 'client/redux/account/subscriptionActions';
import AccountSubscriptionManager from 'client/components/AccountSubscription/components/AccountSubscriptionManager';

class AccountSubscription extends Component {

  getData = () => {
    return this.props.accountSubscriptionIndex();
  }

  componentDidMount() {
    this.getData();
  }

  handleNew = () => {
    return this.props.accountSubscriptionNew();
  }

  render() {
    const {
      error,
      isReading,
      subscriptions,
      subscribablePlans,
      allPlansByStripeId,
      paymentContext = {},
      hasNew,
    } = this.props;
    const { mustAddCardToSubscribe = true } = paymentContext;
    return (
      <Content>
        <Helmet>
          <title>Account Plan Subscriptions Overview</title>
        </Helmet>
        <MainContent>
          <div className="panel panel-default panel-border panel-border-color panel-border-color-primary panel-account-billing">
            <div className="panel-heading panel-heading-divider">
              Subscriptions
              <span className="panel-subtitle">Manage your plans and subscriptions below</span>
            </div>
            <BeRemoteErrorAlert className="xs-m-20 xs-mb-0" error={error} />
            <div className="xs-p-0 panel-body">
              <BeLoading className="xs-p-20" loading={isReading}>
                {!isReading &&
                  <AccountSubscriptionManager
                    subscriptions={subscriptions}
                    subscribablePlans={subscribablePlans}
                    allPlansByStripeId={allPlansByStripeId}
                    mustAddCardToSubscribe={mustAddCardToSubscribe}
                    hasNew={hasNew}
                    onNew={this.handleNew}
                  />
                }
              </BeLoading>
            </div>
          </div>
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  ...subscriptionActions,
};

const mapStateToProps = state => ({
  ...state.account.subscription,
  hasNew: state.account.subscription.subscriptions.some(({isNew}) => isNew),
});

export default connect(mapStateToProps, actions)(AccountSubscription);
