export const USER_INVOICE_INDEX_REQUEST = 'USER_INVOICE_INDEX_REQUEST';
export const USER_INVOICE_INDEX_SUCCESS = 'USER_INVOICE_INDEX_SUCCESS';
export const USER_INVOICE_INDEX_FAILURE = 'USER_INVOICE_INDEX_FAILURE';
export const USER_INVOICE_INDEX = [USER_INVOICE_INDEX_REQUEST, USER_INVOICE_INDEX_SUCCESS, USER_INVOICE_INDEX_FAILURE];

export const USER_INVOICE_READ_REQUEST = 'USER_INVOICE_READ_REQUEST';
export const USER_INVOICE_READ_SUCCESS = 'USER_INVOICE_READ_SUCCESS';
export const USER_INVOICE_READ_FAILURE = 'USER_INVOICE_READ_FAILURE';
export const USER_INVOICE_READ = [USER_INVOICE_READ_REQUEST, USER_INVOICE_READ_SUCCESS, USER_INVOICE_READ_FAILURE];

export const USER_INVOICE_DELETE_REQUEST = 'USER_INVOICE_DELETE_REQUEST';
export const USER_INVOICE_DELETE_SUCCESS = 'USER_INVOICE_DELETE_SUCCESS';
export const USER_INVOICE_DELETE_FAILURE = 'USER_INVOICE_DELETE_FAILURE';
export const USER_INVOICE_DELETE = [USER_INVOICE_DELETE_REQUEST, USER_INVOICE_DELETE_SUCCESS, USER_INVOICE_DELETE_FAILURE];

export const USER_INVOICE_READ_FORM = 'USER_INVOICE_READ_FORM';

export const USER_INVOICE_INDEX_ORDERBY = 'USER_INVOICE_INDEX_ORDERBY';

export const USER_INVOICE_INDEX_SET_PAGE = 'USER_INVOICE_INDEX_SET_PAGE';

export const USER_INVOICE_INDEX_FILTER = 'USER_INVOICE_INDEX_FILTER';

export const paymentMethodSelect2 = [
  {id: 'automatic', text: 'Automatic'},
  {id: 'manual', text: 'Manual'},
];

export const paranoidSelect2 = [
  {id: '0', text: 'Show archived'},
  {id: '1', text: 'Hide archived'},
];
