import { PLAN_PANEL_READ, PLAN_PANEL_UPDATE, PLAN_PANEL_CLEAR } from 'client/redux/plan/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isUpdating: false,
  plan: null
};

export default function panelReducer(state = defaultState, action) {
  state = reduceByTypes(PLAN_PANEL_READ, state, action, {requestProp: 'isReading', successPickProps: ['plan']});
  state = reduceByTypes(PLAN_PANEL_UPDATE, state, action, {requestProp: 'isUpdating', successPickProps: ['plan']});
  switch(action.type) {
    default: return state;
    case PLAN_PANEL_CLEAR: return defaultState;
  }
};
