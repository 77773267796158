import {
  ACCOUNT_FIELDS,
  ACCOUNT_UPDATE_REQUEST, ACCOUNT_UPDATE_SUCCESS, ACCOUNT_UPDATE_FAILURE,
  ACCOUNT_FORM_TOGGLE_PART, ACCOUNT_FORM_HIDE_ALL_PARTS
} from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isLoading: false,
  isUpdating: false,
  wasUpdated: false,
  fields: {},
  showPart: {email: false, password: false}
};

export default function settingsReducer(state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_FIELDS, state, action, {requestProp: 'isLoading', successSpread: true});
  switch(action.type) {
    case ACCOUNT_FORM_HIDE_ALL_PARTS: return {
      ...state,
      showPart: {email: false, password: false}
    };
    case ACCOUNT_FORM_TOGGLE_PART: return {
      ...state,
      showPart: {...state.showPart, [action.part]: action.show}
    };
    case ACCOUNT_UPDATE_REQUEST: return {
      ...state,
      isUpdating: true,
      wasUpdated: false
    };
    case ACCOUNT_UPDATE_FAILURE:
      return {
      ...state,
      isUpdating: false,
      error: action.payload
    };
    case ACCOUNT_UPDATE_SUCCESS: return {
      ...state,
      isUpdating: false,
      wasUpdated: action.payload.ok,
      fields: action.payload.fields,
      showPart: {email: false, password: false}
    };
    default:
      return state
  }
};
