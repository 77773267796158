import React  from 'react';
import * as _ from 'lodash';
import propTypes from 'prop-types';
import classNames from 'classnames';
import BeLoading from 'client/components/Be/BeLoading';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { Modal } from 'react-bootstrap';
import { getMustacheError } from 'client/helpers/templates';
import { FormErrors } from 'client/components/Form/FormErrors';
import { connect } from 'react-redux';
import { MustacheHelpBlock } from 'client/components/QRTD/QRTDPrintPageHelpers';
import * as optionsActions from 'client/redux/qrtd/optionsActions';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import { Field } from 'redux-form';
import { FormReduxTextarea } from 'client/components/Form/FormRedux';
import {
  INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_FAILURE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_FAILURE
} from 'client/redux/qrtd/constants';
import {
  ModalButton,
  ModalConfirmButton,
  ModalContentConfirmWarning,
  ModalClose
} from 'client/components/Helpers/Modal';

const successSave = [
  INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_SUCCESS,
];

const errorSave = [
  INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_FAILURE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_FAILURE,
];

const validateMustache = str => {
  return getMustacheError(str);
};

export class ConnectedQRTDOptionsFormInfoTextEditor extends React.Component {

  static propTypes = {
    instanceId: propTypes.string,
    infoTexts: propTypes.array,
    infoTextRows: propTypes.number,
    errorInfoText: propTypes.any,
    disabled: propTypes.bool,
    isReadingInfoTexts: propTypes.bool,
    isCreatingInfoText: propTypes.bool,
    isDeletingInfoText: propTypes.bool,
    isUpdatingInfoText: propTypes.bool,
  }

  handleCreateInfoText = create => {
    const { instanceId } = this.props;
    return this.props.instanceQrtdOptionsInfoTextCreate(instanceId, create).then(result => {
      if (successSave.includes(result.type)) {
        return this.props.instanceQrtdOptionsInfoTextRead(instanceId).then(() => result);
      }
      return result;
    });
  }

  handleUpdateInfoText = (update, ordinal) => {
    const { instanceId } = this.props;
    return this.props.instanceQrtdOptionsInfoTextUpdate(instanceId, ordinal, update).then(result => {
      if (successSave.includes(result.type)) {
        return this.props.instanceQrtdOptionsInfoTextRead(instanceId).then(() => result);
      }
      return result;
    });
  }

  handleDeleteInfoText = ordinal => {
    const { instanceId } = this.props;
    return this.props.instanceQrtdOptionsInfoTextDelete(instanceId, ordinal).then(result => {
      if (result.type === INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_SUCCESS) {
        return this.props.instanceQrtdOptionsInfoTextRead(instanceId).then(() => result);
      }
      return result;
    });
  }

  renderDeleteButton (id) {
    const { disabled } = this.props;
    const confirmProps = {
      title: 'Confirm info text deletion',
      text: (
        <>
          <span>Are you sure you want to delete this info text?</span><br />
          <span>Any QR-codes that you have printed connected to this text will show the default text after deletion.</span>
        </>
      ),
    };
    return (
      <ModalConfirmButton className="btn btn-default btn-sm" callback={() => this.handleDeleteInfoText(id)} contentProps={confirmProps} Content={ModalContentConfirmWarning} disabled={disabled}>
        <i className="icon mdi mdi-delete"></i>
      </ModalConfirmButton>
    );
  }

  render () {
    const {
      disabled,
      initialValues,
      infoTexts,
      isReadingInfoTexts,
      errorInfoText,
      infoTextRows,
    } = this.props;
    const { infoText } = initialValues;
    return (
      <div className="well xs-pb-10">
        <InstanceHelpHeader
          subTitle="Customization options for the info page"
          helpFragmentId="instance_qrtd_settings_info_tab_help"
        >
          Info Tab Customization
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorInfoText} />
        <Field
          base="qrtdOptions"
          component={FormReduxTextarea}
          after={(<MustacheHelpBlock />)}
          label="Default info tab text (Mustache + Markdown)"
          validate={[validateMustache]}
          name="infoText"
          value={infoText || ''}
          disabled={disabled}
          maxlen="10000"
          rows={infoTextRows}
        />
        <BeLoading loading={isReadingInfoTexts}>
          <table className="table table-condensed table-sm xs-mb-10">
            <thead>
              <tr>
                <th className="xs-p-5">#</th>
                <th className="xs-p-5">Title</th>
                <th className="xs-p-5"></th>
              </tr>
            </thead>
            <tbody>
              {infoTexts && infoTexts.map(infoText => (
                <tr key={infoText.ordinal}>
                  <td>{infoText.ordinal}</td>
                  <td>{infoText.title}</td>
                  <td className="text-right">
                    <ModalButton className="btn btn-default btn-sm xs-mr-5" title="Edit info text" ModalComponent={InfoTextModal} modalProps={{isNew: false, infoText, onSave: this.handleUpdateInfoText, disabled}}>
                      <i className="icon mdi mdi-edit"></i>
                    </ModalButton>
                    {this.renderDeleteButton(infoText.ordinal)}
                  </td>
                </tr>
              ))}
              {(!infoTexts || !infoTexts.length) && (
                <tr>
                  <td colSpan="3">No custom info texts created yet</td>
                </tr>
              )}
            </tbody>
          </table>
        </BeLoading>

        <div className="text-right">
          <ModalButton disabled={disabled} className="btn btn-primary" title="Open modal to create a new info text" ModalComponent={InfoTextModal} modalProps={{isNew: true, onSave: this.handleCreateInfoText}}>
            Create new info text
          </ModalButton>
        </div>
      </div>
    );
  }

}

class InfoTextModal extends React.Component {

  static propTypes = {
    isNew: propTypes.bool.isRequired,
    infoText: propTypes.object,
    onClose: propTypes.func.isRequired,
    onSave: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    errorModalInfoText: propTypes.any,
    disabled: propTypes.bool,
  }

  static defaultProps = {
    isNew: false,
  }

  state = {
    title: '',
    infoText: '',
    saveError: null,
    dirty: false,
    mustacheError: false,
    mustacheErrorText: null,
  }

  handleChange = ev => {
    this.setState({
      dirty: true,
      [ev.target.name]: ev.target.value,
    });
  }

  handleReset = () => {
    const { isNew, infoText } = this.props;
    if (isNew) {
      this.setState({title: '', infoText: '', dirty: false});
    } else {
      this.setState({...infoText, dirty: false});
    }
  }

  handleResetAndClose = () => {
    this.handleReset();
    this.props.onClose();
  }

  handleSaveAndClose = () => {
    const { onSave, onClose } = this.props;
    const ordinal = this.state.ordinal;
    const obj = _.pick(this.state, 'title', 'infoText');
    this.setState({saveError: null});
    return onSave(obj, ordinal).then(result => {
      if (successSave.includes(result.type)) {
        return onClose();
      } else if (errorSave.includes(result.type)) {
        this.setState({saveError: result.payload});
      }
    });
  }

  handleSave = () => {
    const { onSave } = this.props;
    const ordinal = this.state.ordinal;
    const obj = _.pick(this.state, 'title', 'infoText');
    this.setState({saveError: null});
    return onSave(obj, ordinal).then(result => {
      if (errorSave.includes(result.type)) {
        this.setState({saveError: result.payload});
      } else {
        this.setState({dirty: false});
      }
    });
  }

  validateInfoText = () => {
    const { infoText } = this.state;
    const error = getMustacheError(infoText);
    this.setState({
      mustacheError: Boolean(error),
      mustacheErrorText: error ? error : null,
    });
  }

  componentDidUpdate (prevProps) {
    const { open, infoText, isNew } = this.props;
    if (open && !prevProps.open) {
      this.setState({title: '', infoText: ''});
      if (isNew) {
      } else if (infoText) {
        this.setState({...infoText});
      }
    }
  }

  render() {
    const { onClose, open, isNew, disabled } = this.props;
    const {
      ordinal,
      title,
      infoText,
      mustacheError,
      mustacheErrorText,
      dirty,
      saveError,
    } = this.state;
    const infoTextError = mustacheError || !infoText || infoText.length < 1;
    const titleError = !title || title.length < 1;
    const canSave = !infoTextError && !titleError;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>
            {isNew && (<>New Info Text</>)}
            {!isNew && (<>Edit Info Text {ordinal}</>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="xs-pt-10">
          <div className={classNames('form-group xs-mt-10', titleError ? 'has-error' : '')}>
            <label className="control-label" htmlFor="qrtdOptionsInfoTextModalTitle">
              Title
            </label>
            <input
              id="qrtdOptionsInfoTextModalTitle"
              className="form-control"
              value={title}
              name="title"
              disabled={disabled}
              onChange={this.handleChange}
            />
            <FormErrors error={titleError ? 'Title is required' : null} />
          </div>
          <div className={classNames('form-group xs-mt-10', infoTextError ? 'has-error' : '')}>
            <label className="control-label" htmlFor="qrtdOptionsInfoTextModalInfoText">
              Info Text (Mustache + Markdown)
            </label>
            <textarea
              id="qrtdOptionsInfoTextModalInfoText"
              className="form-control"
              value={infoText}
              rows={Math.max(4, getNumberOfRows(infoText))}
              name="infoText"
              disabled={disabled}
              onChange={this.handleChange}
              onBlur={this.validateInfoText}
            />
            <MustacheHelpBlock />
            <FormErrors error={infoTextError ? 'Text is required' : null} />
            <FormErrors error={mustacheError ?  mustacheErrorText : null} />
          </div>
          {saveError && <BeRemoteErrorAlert error={saveError} />}
          <hr />
          <div className="text-right">
            {!isNew && (
              <>
                <button
                  disabled={!dirty || disabled}
                  type="button"
                  className="btn btn-default btn-lg"
                  onClick={this.handleReset}
                >
                  Reset
                </button>{' '}
                <ButtonWaitingPromise
                  disabled={!dirty || disabled || Boolean(!canSave)}
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={this.handleSave}
                >
                  Save
                </ButtonWaitingPromise>{' '}
                <ModalConfirmButton
                  active={dirty}
                  className="btn btn-default btn-lg"
                  callback={this.handleResetAndClose}
                  contentProps={{title: 'Are you sure you want to close the modal without saving?', text: ''}}
                  Content={ModalContentConfirmWarning}
                >
                  Close
                </ModalConfirmButton>
              </>
            )}
            {isNew && (
              <ButtonWaitingPromise
                disabled={!dirty || disabled || Boolean(!canSave)}
                type="button"
                className="btn btn-primary btn-lg"
                onClick={this.handleSaveAndClose}
              >
                Save + Close
              </ButtonWaitingPromise>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

const actions = {
  ...optionsActions,
};

const mapStateToProps = state => state.qrtd.options;

export default connect(mapStateToProps, actions)(ConnectedQRTDOptionsFormInfoTextEditor);

function getNumberOfRows(str) {
  return str.split('\n').length;
}
