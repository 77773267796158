import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { BeFormFieldErrors } from 'client/components/Be';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Field, reduxForm } from 'redux-form';
import { isFilled, isString, isEmail } from 'client/redux/validators';
import { metaToValidationState } from 'client/redux/formHelpers';

const validateByField = {
  email: [isString(), isFilled(), isEmail()],
  password: [isString(), isFilled()],
};

class LoginForm extends Component {

  static propTypes = {
    isLoggingIn: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
    canSignup: propTypes.bool
  }

  renderCheckboxComponent = data => {
    return (
      <FormGroup className="row login-tools" validationState={metaToValidationState(data.meta)}>
        <div className="col-md-6 login-remember">
          <div className="be-checkbox">
            <input id="loginRememberMe" {...data.input} type={data.type} />
            <label htmlFor="loginRememberMe">Remember Me</label>
          </div>
        </div>
        <div className="col-md-6 login-forgot-password">
          <Link to="/forgotPassword">Forgot Password?</Link>
        </div>
      </FormGroup>
    );
  }

  fieldProps = {base: 'loginForm', metaOptions: {defaultState: null, ignorePristine: true}};

  renderSignupLink() {
    const { canSignup } = this.props;
    if(canSignup) {
      return <Link className="btn btn-default btn-xl" to="/signup">Sign up</Link>
    }
    return <a className="btn btn-default btn-xl disabled">Signup disabled</a> // eslint-disable-line
  }

  render() {
    const { onSubmit, error, handleSubmit, isLoggingIn, canSignup  } = this.props;
    return (
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div className="login-form">
          <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
          <Field
            {...this.fieldProps}
            name="email"
            component={FormReduxInput}
            type="email"
            placeholder="Enter E-mail"
            validate={validateByField.email}
          />
          <Field
            {...this.fieldProps}
            name="password"
            component={FormReduxInput}
            type="password"
            placeholder="Enter password"
            validate={validateByField.password}
          />
          <Field
            {...this.fieldProps}
            name="rememberMe"
            component={this.renderCheckboxComponent}
            type="checkbox"
          />
					<div className="form-group row">
						<div className="col-md-6">
              {canSignup && <Link style={{display: 'block'}} className="btn btn-default btn-xl xs-mb-10 md-mb-0" to="/signup">Sign up</Link>}
              {!canSignup && <a style={{display: 'block'}} disabled href="#/" className="btn btn-default btn-xl disabled xs-mb-10 md-mb-0">Signup disabled</a>}
						</div>
						<div className="col-md-6">
              <ButtonWaiting type="submit" style={{display: 'block', width: '100%'}} className="btn-primary btn-xl" isWaiting={isLoggingIn}>
                Login
              </ButtonWaiting>
						</div>
					</div>
				</div>
			</Form>
    );
  }

};

export default reduxForm({
  form: 'login',
})(LoginForm);
