import validator from 'validator';

export const isString = (msg = 'Must be non-empty') => value => !value || typeof(value) === 'string' ?  undefined : msg;
export const isEmail = (msg = 'Not a valid Email') => value => !value || validator.isEmail(value) ? undefined : msg;
export const isFilled = (msg = 'Must be non-empty') => value => !validator.isEmpty(value || '') ? undefined : msg;
export const isNotEmpty = (msg = 'Must be non-empty') => value => !validator.isEmpty(value || '') ? undefined : msg;
export const isEqualTo = (compare, msg = 'Value is incorrect') => value => value === compare ? undefined : msg;
export const isURL = (compare, msg = 'Value is not an URL') => value => !value || validator.isURL(value) ? undefined : msg;
export const isUUID = (compare, msg = 'Value is not an UUID') => value => !value || validator.isUUID(value) ? undefined : msg;
export const isJSON = (compare, msg = 'Value is not valid JSON') => value => {
  try {
    JSON.parse(value);
  } catch(err) {
    return msg;
  }
  return undefined;
}

export const isNonEmptyString = [isString(), isFilled()];

export const isFilledUUID = [isString(), isFilled(), isUUID()];
