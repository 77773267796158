import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { PLAN_INDEX_STRIPE_SYNC_SUCCESS, PLAN_DELETE_SUCCESS } from 'client/redux/plan/constants';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { connect } from 'react-redux';
import { gritterAlertPush } from 'client/redux/gritter/actions';
import * as indexActions from 'client/redux/plan/indexActions';
import PlanTable from 'client/components/Plan/components/PlanTable';
import _ from 'lodash';

class PlanIndex extends Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    return this.getData();
  }

  getData = (overrideWith = {}) => {
    const { orderBy, orderDirection, page, filter } = this.props;
    return this.props.planIndex({...filter, orderBy, orderDirection, page});
  }

  handleClickStripeSync = ev => {
    return this.props.planIndexStripeSync().then(result => {
      if(result.type === PLAN_INDEX_STRIPE_SYNC_SUCCESS) return this.getData();
    })
  }

  handleDeletePlan = planId => {
    return this.props.planDelete(planId).then(result => {
      if(!result || result.type !== PLAN_DELETE_SUCCESS) return;
      this.props.gritterAlertPush(`plan_delete_${planId}_success`, {
        className: 'color warning',
        title: 'Deletion succeded',
        message: 'The plan was deleted locally',
        closeAfter: 3000
      });
      return this.getData();
    });
  }

  handleOrderBy = (orderBy, orderDirection) => {
    this.props.planIndexOrderBy(orderBy, orderDirection);
    return this.getData({orderBy, orderDirection});
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    this.props.planIndexSetPage(page);
    return this.getData({page});
  }

  handleFilter = filter => {
    this.props.planIndexFilter(filter);
    return this.getDataDebounced(filter);
  }

  render() {
    const { isReading, isSyncing, plans, orderBy, orderDirection, page, pages, limit, offset, count, filter, error } = this.props;
    return (
      <Content>
        <MainContent>
          <Helmet>
            <title>Plan Overview</title>
          </Helmet>
          <BeRemoteErrorAlert error={error} />
          <div className="panel panel-default panel-border">
            <div className="panel-body xs-p-20">
              <ButtonWaiting className="btn btn-default" isWaiting={isSyncing} onClick={this.handleClickStripeSync}>
                <i className="icon mdi mdi-refresh-sync"></i>{' '}
                Sync with Stripe
              </ButtonWaiting>
            </div>
          </div>
          <PlanTable
            className="panel-default panel-border"
            loading={isReading}
            plans={plans}
            onOrderBy={this.handleOrderBy}
            onSetPage={this.handleSetPage}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onFilter={this.handleFilter}
            onRefresh={this.getData}
            onDeletePlan={this.handleDeletePlan}
            filter={filter}
            count={count}
            limit={limit}
            offset={offset}
            page={page}
            pages={pages}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  ...indexActions,
  gritterAlertPush
}

export default connect(state => state.plan.index, actions)(PlanIndex);
