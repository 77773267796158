import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import EndpointAzureOpenAiForm from 'client/components/Endpoint/EndpointAzureOpenAiForm';
import { connect } from 'react-redux';
import * as azureOpenAiActions from 'client/redux/endpoint/azureOpenAi/actions';
import BeLoading from 'client/components/Be/BeLoading';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { AlertWithMessage } from 'client/components/Be/Alert';

class EndpointAzureOpenAi extends React.Component {

  static defaultProps = {
    onUpdated: function() {},
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    instanceId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    onUpdated: propTypes.func,
  }

  componentDidMount() {
    if (this.props.preloadedEndpoint) {
      this.props.endpointAzureOpenAiPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointAzureOpenAiRead(this.props.endpointId);
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointAzureOpenAiRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  handleUpdate = (settings = {}) => {
    return this.props.endpointAzureOpenAiUpdateSettings(this.props.endpointId, settings)
      .then(getResponseToFormErrorHandler())
      .then(result => {
        this.props.endpointAzureOpenAiEditSettings(false);
      });
  }

  handleClickEdit = ev => {
    this.props.endpointAzureOpenAiEditSettings(true);
  }

  dataToFields(data = {}) {
    return {
      apiUrl: data.apiUrl || '',
      apiKey: data.apiKey || '',
    };
  }

  render() {
    const {
      endpoint,
      error,
      isEditing,
      isReading,
      isUpdating,
      isSetupMode,
    } = this.props;

    const endpointHasError = endpoint?.status === 'error';

    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              Azure Open AI
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_azureOpenAi_settings_help'}}
              />
            </div>
            <span className="panel-subtitle">Enter your Azure Open AI credentials below</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading loading={isReading} className="xs-p-20">
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {endpointHasError && (
              <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
                <strong>Error:</strong> There is a problem with this endpoint.
              </AlertWithMessage>
            )}
            {endpoint && (
              <>
                {isEditing || isSetupMode ? (
                  <EndpointAzureOpenAiForm
                    initialValues={this.dataToFields(endpoint.settings)}
                    isEditing={isEditing}
                    endpointState={endpoint.state}
                    onEdit={this.handleClickEdit}
                    onSubmit={this.handleUpdate}
                    isUpdating={isUpdating}
                    remoteError={error}
                  />
                ) : (
                  <>
                    {!isEditing && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="btn-group pull-right clearfix">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={this.handleClickEdit}
                            >
                              <i className="icon icon-left mdi mdi-edit"></i>
                              {' '}Edit credentials
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.azureOpenAi, azureOpenAiActions)(EndpointAzureOpenAi);
