import React from 'react';
import propTypes from 'prop-types';
import UIFragmentHelpButton from 'client/components/UIFragment/UIFragmentHelpButton';
import * as metaActions from 'client/redux/pvtd/metaActions';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import { connect } from 'react-redux';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import EndpointTopdeskIdField from 'client/components/Endpoint/components/EndpointTopdeskIdField';
import {
  PVTDOptionsBranchFieldMap,
  PVTDOptionsPersonFieldMap,
  PVTDOptionsIncidentFieldMap,
} from 'client/components/PVTD/PVTDOptionsFieldMaps';
import Sticky from 'react-stickynode';

class PVTDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    this.props.instancePvtdReadMeta(instanceId);
  }

  handleReset = ev => {
    this.props.reset();
  }

  render() {
    const {
      dirty,
      disabled,
      invalid,
      isSetupMode,
      onSubmit,
      onNudgeStep,
      isReading,
      isUpdating,
      instanceId,
      branchFieldMap,
      incidentFieldMap,
      personFieldMap,
      error,
      endpointsByType: { topdesk },
      initialValues: {
        topdeskAnonymousBranchId,
        topdeskPersonIdField,
      },
    } = this.props;
    const endpointIds = {topdeskId: topdesk.id};
		return (
      <Form id="instancePvtdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instancePvtdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <BeRemoteErrorAlert error={error} />
        <div className="xs-mt-20 row">

          <div className="col-md-6">

            <div className="well xs-mb-10">
              <h3 className="wizard-title xs-mb-20">
                Branch Settings
                <UIFragmentHelpButton
                  className="btn btn-rounded btn-default btn-xs pull-right"
                  fragmentId="instance_pvtd_branch_settings_help"
                />
                <span className="panel-subtitle">These settings control how branches are created</span>
              </h3>
              <PVTDOptionsBranchFieldMap
                instanceId={instanceId}
                endpointIds={endpointIds}
                isReading={isReading}
                fieldMap={branchFieldMap}
                disabled={disabled}
              />
            </div>

            <div className="well xs-mb-10">
              <h3 className="wizard-title xs-mb-20">
                Person Settings
                <UIFragmentHelpButton
                  className="btn btn-rounded btn-default btn-xs pull-right"
                  fragmentId="instance_pvtd_person_settings_help"
                />
                <span className="panel-subtitle">These settings control how verified and non-anonymous persons are created</span>
              </h3>
              <Field
                base="pvtdOptions"
                component={FormReduxInput}
                label="TOPdesk Person field storing the personal number"
                name="topdeskPersonIdField"
                value={topdeskPersonIdField || ''}
                disabled={disabled}
                maxlen="100"
                placeholder="optionalFields1.text1"
              />
              <PVTDOptionsPersonFieldMap
                instanceId={instanceId}
                endpointIds={endpointIds}
                isReading={isReading}
                fieldMap={personFieldMap}
                disabled={disabled}
              />
            </div>

          </div>

          <div className="col-md-6">
            <div className="well xs-mb-10">
              <h3 className="wizard-title xs-mb-20">
                Incident Settings
                <UIFragmentHelpButton
                  className="btn btn-rounded btn-default btn-xs pull-right"
                  fragmentId="instance_pvtd_incident_settings_help"
                />
                <span className="panel-subtitle">These settings control how incidents are created</span>
              </h3>
              <EndpointTopdeskIdField
                base="pvtdOptions"
                name="topdeskAnonymousBranchId"
                endpointId={topdesk.id}
                searchForWhat="branch"
                value={topdeskAnonymousBranchId}
                disabled={disabled}
                placeholderText="Do not set a branch on the incident when anonymous"
                helpText="Set the branch in TOPdesk on the incident when the user wants to be anonymous"
              />
              <hr />
              <PVTDOptionsIncidentFieldMap
                instanceId={instanceId}
                endpointIds={endpointIds}
                isReading={isReading}
                fieldMap={incidentFieldMap}
                disabled={disabled}
              />
            </div>
          </div>

        </div>
      </Form>
		);
  }

}

function mapStateToProps (state) {
  const selector = formValueSelector('pvtdOptions');
  const fields = ['asdf']; // the value of these fields need to be available when rendering
  return {
    ...state.pvtd.meta,
    currentFormValues: selector(state, ...fields),
  };
}

const actions = {instancePvtdReadMeta: metaActions.instancePvtdReadMeta};
export default connect(mapStateToProps, actions)(reduxForm({
  form: 'pvtdOptions',
  enableReinitialize: true,
})(PVTDOptionsForm));
