import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import FSTDOptionsForm from 'client/components/FSTD/components/FSTDOptionsForm';
import FSTDTester from 'client/components/FSTD/components/FSTDTester';
import FSTDFieldMapper from 'client/components/FSTD/FSTDFieldMapper';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import { connect } from 'react-redux';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class FSTDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>CSV → TOPdesk Assets</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            stepComponents={{
              fieldMapper: props => <FSTDFieldMapper {...props} />
            }}
            OptionsFormComponent={FSTDOptionsForm}
            TesterComponent={FSTDTester}
            endpointOrder={['files', 'topdesk']}
          />
        </MainContent>
      </Content>
    );
  }

}

export default connect()(FSTDSetup);
