import React from 'react';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';

export default class AITDTesterAddTopdeskIncident extends React.Component {

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    objectId: propTypes.string,
    onAdd: propTypes.func,
    onChange: propTypes.func.isRequired,
    isAdding: propTypes.bool
  }

  handleClick = ev => {
    const { objectId } = this.props;
    this.props.onAdd(objectId);
  }

  handleChange = value => {
    this.props.onChange(value);
  }

  render() {
    const { endpointId, objectId, isAdding } = this.props;
    return (
      <div className="input-group xs-mb-15">
        <EndpointTopdeskRemoteSearcher
          id={`aitdTesterSelectTopdeskIncident`} 
          name="objectId"
          endpointId={endpointId}
          forWhat="incidents"
          onChange={this.handleChange}
          options={{allowClear: true, placeholder: 'Search by TOPdesk incident ID or number', minimumInputLength: 1}}
          defaultValue={objectId}
        />
        <span className="input-group-btn">
          <ButtonWaiting
            disabled={!objectId || isAdding}
            onClick={this.handleClick}
            type="button"
            className="btn btn-primary"
            isWaiting={isAdding}
          >
            Add incident
          </ButtonWaiting>
        </span>
      </div>
    );
  }

}
