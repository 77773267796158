import React from 'react';
import { MainContent, Content } from 'client/components/App';
import SwitchButton from 'client/components/Form/SwitchButton';
import * as invoiceReadActions from 'client/redux/user/invoice/readActions';
import UserInvoice from 'client/components/UserInvoice/components/UserInvoice';
import _ from 'lodash';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import 'client/components/UserInvoice/UserInvoiceRead.css';

class UserInvoiceRead extends React.Component {

  state = {
    showPaymentStatus: false,
  }

  invoiceId(props) {
    return _.get(props || this.props, 'match.params.invoiceId');
  }

  getData = () => {
    const { form } = this.props;
    this.props.userInvoiceRead(this.invoiceId(), form);
  }

  componentDidMount() {
    this.getData();
  }

  toggleShowCharges = () => {
    const { showPaymentStatus } = this.state;
    this.setState({showPaymentStatus: !showPaymentStatus});
  }

  handleCheckedChange = ev => {
    const { checked, name } = ev.target;
    this.props.userInvoiceReadForm({[name]: checked});
    this.getData();
  }

  renderInvoice() {
    const {
      invoice,
      invoiceObject,
      sender,
      recipient,
      reverseCharge,
      form,
      instances,
      instancesByStripePlanId,
    } = this.props;
    if(!invoiceObject) return null;
    const { showPaymentStatus } = this.state;
    const { vatConversion } = invoice;
    const { hideCredit } = form;
    return (
      <div>
        <div className="panel panel-border switch-button-panel">
          <div className="panel-body xs-p-20">
            <div className="">
              <div className="form-group pull-left xs-mb-0 xs-mr-20">
                <label className="control-label">Show status</label>
                <SwitchButton
                  id="userInvoiceReadShowChargesSwitch"
                  value={showPaymentStatus}
                  onChange={this.toggleShowCharges}
                />
              </div>
              <div className="form-group pull-left xs-mb-0">
                <label className="control-label">Hide credit</label>
                <SwitchButton
                  id="userInvoiceReadShowCreditSwitch"
                  name="hideCredit"
                  value={hideCredit}
                  onChange={this.handleCheckedChange}
                />
              </div>
            </div>
          </div>
        </div>
        <UserInvoice
          invoice={invoiceObject}
          vatConversion={vatConversion}
          sender={sender}
          recipient={recipient}
          showPaymentStatus={showPaymentStatus}
          instances={instances}
          instancesByStripePlanId={instancesByStripePlanId}
          reverseCharge={reverseCharge}
        />
      </div>
    );
  }

  getTitle() {
    const number = _.get(this.props, 'invoiceObject.number');
    if(!number) return 'User Invoice';
    return `User Invoice #${number}`;
  }

  render() {
    const { isReading, error } = this.props;
    return (
      <Content>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <MainContent>
          <BeRemoteErrorAlert error={error} />
          <BeLoading positionStatic={true} loading={isReading}>
            {this.renderInvoice()}
          </BeLoading>
        </MainContent>
      </Content>
    );
  }
}

const actions = {
  ...invoiceReadActions,
};

export default connect(state => state.user.invoiceRead, actions)(UserInvoiceRead);
