import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

class RouteRole extends Component {

  static propTypes = {
    anyRole: propTypes.array.isRequired
  }

  render() {
    const { currentUserRole, anyRole, isLoggedIn, isRehydrated, ...rest } = this.props;
    if(!isRehydrated) {
      return null;
    }

    if(!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    if(!anyRole.some(role => currentUserRole === role)) {
      return <Redirect to="/" />;
    }

    return <Route {...rest} />
  }

}

const mapStateToProps = state => {
  const auth = state.auth || {};
  return {
    isRehydrated: auth.isRehydrated,
    isLoggedIn: auth.isLoggedIn,
    currentUserRole: auth.self && auth.self.role ? auth.self.role : 'nobody'
  };
};

export default connect(mapStateToProps)(RouteRole);
