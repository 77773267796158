import { apiAction } from 'client/redux/apiHelpers';

export const endpointSkatteverketRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/skatteverket/${id}`,
    method: 'GET',
    types
  });
};

export const endpointSkatteverketEditSettings = (type, isEditing) => {
  return {
    type,
    isEditing
  };
};

export const endpointSkatteverketUpdateSettings = (types, id, settings) => {
  return apiAction({
    endpoint: `/api/endpoint/skatteverket/${id}/settings`,
    method: 'PATCH',
    types,
    json: settings
  });
};

export const endpointSkatteverketTest = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/skatteverket/${id}/test`,
    method: 'POST',
    types,
    json: {}
  });
};
