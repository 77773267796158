export const INSTANCE_GRTD_READ_STATISTICS_REQUEST = 'INSTANCE_GRTD_READ_STATISTICS_REQUEST';
export const INSTANCE_GRTD_READ_STATISTICS_SUCCESS = 'INSTANCE_GRTD_READ_STATISTICS_SUCCESS';
export const INSTANCE_GRTD_READ_STATISTICS_FAILURE = 'INSTANCE_GRTD_READ_STATISTICS_FAILURE';
export const INSTANCE_GRTD_READ_STATISTICS = [INSTANCE_GRTD_READ_STATISTICS_REQUEST, INSTANCE_GRTD_READ_STATISTICS_SUCCESS, INSTANCE_GRTD_READ_STATISTICS_FAILURE];

export const INSTANCE_GRTD_READ_META_REQUEST = 'INSTANCE_GRTD_READ_META_REQUEST';
export const INSTANCE_GRTD_READ_META_SUCCESS = 'INSTANCE_GRTD_READ_META_SUCCESS';
export const INSTANCE_GRTD_READ_META_FAILURE = 'INSTANCE_GRTD_READ_META_FAILURE';
export const INSTANCE_GRTD_READ_META = [INSTANCE_GRTD_READ_META_REQUEST, INSTANCE_GRTD_READ_META_SUCCESS, INSTANCE_GRTD_READ_META_FAILURE];

export const INSTANCE_GRTD_TEST_USER_ADD_REQUEST = 'INSTANCE_GRTD_TEST_USER_ADD_REQUEST';
export const INSTANCE_GRTD_TEST_USER_ADD_SUCCESS = 'INSTANCE_GRTD_TEST_USER_ADD_SUCCESS';
export const INSTANCE_GRTD_TEST_USER_ADD_FAILURE = 'INSTANCE_GRTD_TEST_USER_ADD_FAILURE';
export const INSTANCE_GRTD_TEST_USER_ADD = [INSTANCE_GRTD_TEST_USER_ADD_REQUEST, INSTANCE_GRTD_TEST_USER_ADD_SUCCESS, INSTANCE_GRTD_TEST_USER_ADD_FAILURE];

export const INSTANCE_GRTD_RELOAD_USER_REQUEST = 'INSTANCE_GRTD_RELOAD_USER_REQUEST';
export const INSTANCE_GRTD_RELOAD_USER_SUCCESS = 'INSTANCE_GRTD_RELOAD_USER_SUCCESS';
export const INSTANCE_GRTD_RELOAD_USER_FAILURE = 'INSTANCE_GRTD_RELOAD_USER_FAILURE';
export const INSTANCE_GRTD_RELOAD_USER = [INSTANCE_GRTD_RELOAD_USER_REQUEST, INSTANCE_GRTD_RELOAD_USER_SUCCESS, INSTANCE_GRTD_RELOAD_USER_FAILURE];

export const INSTANCE_GRTD_TEST_USER_REMOVE = 'INSTANCE_GRTD_TEST_USER_REMOVE';
export const INSTANCE_GRTD_TEST_CLEAR = 'INSTANCE_GRTD_TEST_CLEAR';

export const INSTANCE_GRTD_TEST_USER_REQUEST = 'INSTANCE_GRTD_TEST_USER_REQUEST';
export const INSTANCE_GRTD_TEST_USER_SUCCESS = 'INSTANCE_GRTD_TEST_USER_SUCCESS';
export const INSTANCE_GRTD_TEST_USER_FAILURE = 'INSTANCE_GRTD_TEST_USER_FAILURE';
export const INSTANCE_GRTD_TEST_USER = [INSTANCE_GRTD_TEST_USER_REQUEST, INSTANCE_GRTD_TEST_USER_SUCCESS, INSTANCE_GRTD_TEST_USER_FAILURE];

export const INSTANCE_GRTD_SYNC_USER_REQUEST = 'INSTANCE_GRTD_SYNC_USER_REQUEST';
export const INSTANCE_GRTD_SYNC_USER_SUCCESS = 'INSTANCE_GRTD_SYNC_USER_SUCCESS';
export const INSTANCE_GRTD_SYNC_USER_FAILURE = 'INSTANCE_GRTD_SYNC_USER_FAILURE';
export const INSTANCE_GRTD_SYNC_USER = [INSTANCE_GRTD_SYNC_USER_REQUEST, INSTANCE_GRTD_SYNC_USER_SUCCESS, INSTANCE_GRTD_SYNC_USER_FAILURE];
