export const ENDPOINT_SNOW_PRELOADED = 'ENDPOINT_SNOW_PRELOADED';

export const ENDPOINT_SNOW_READ_REQUEST = 'ENDPOINT_SNOW_READ_REQUEST';
export const ENDPOINT_SNOW_READ_SUCCESS = 'ENDPOINT_SNOW_READ_SUCCESS';
export const ENDPOINT_SNOW_READ_FAILURE = 'ENDPOINT_SNOW_READ_FAILURE';
export const ENDPOINT_SNOW_READ = [ENDPOINT_SNOW_READ_REQUEST, ENDPOINT_SNOW_READ_SUCCESS, ENDPOINT_SNOW_READ_FAILURE];

export const ENDPOINT_SNOW_EDIT_SETTINGS = 'ENDPOINT_SNOW_EDIT_SETTINGS';

export const ENDPOINT_SNOW_UPDATE_SETTINGS_REQUEST = 'ENDPOINT_SNOW_UPDATE_SETTINGS_REQUEST';
export const ENDPOINT_SNOW_UPDATE_SETTINGS_SUCCESS = 'ENDPOINT_SNOW_UPDATE_SETTINGS_SUCCESS';
export const ENDPOINT_SNOW_UPDATE_SETTINGS_FAILURE = 'ENDPOINT_SNOW_UPDATE_SETTINGS_FAILURE';
export const ENDPOINT_SNOW_UPDATE_SETTINGS = [ENDPOINT_SNOW_UPDATE_SETTINGS_REQUEST, ENDPOINT_SNOW_UPDATE_SETTINGS_SUCCESS, ENDPOINT_SNOW_UPDATE_SETTINGS_FAILURE];

export const ENDPOINT_SNOW_TEST_REQUEST = 'ENDPOINT_SNOW_TEST_REQUEST';
export const ENDPOINT_SNOW_TEST_SUCCESS = 'ENDPOINT_SNOW_TEST_SUCCESS';
export const ENDPOINT_SNOW_TEST_FAILURE = 'ENDPOINT_SNOW_TEST_FAILURE';
export const ENDPOINT_SNOW_TEST = [ENDPOINT_SNOW_TEST_REQUEST, ENDPOINT_SNOW_TEST_SUCCESS, ENDPOINT_SNOW_TEST_FAILURE];
