import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Time from 'client/components/Helpers/Time';
import { ModalButton, ModalFormattedObject, ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import { EndpointStatusLabel } from 'client/components/Helpers/StatusLabel';
import {
  TableCheckbox,
  TableThSort,
  TableCheckboxManager,
  TablePagination,
  TableShowCount,
  TableUserCell,
} from 'client/components/Helpers/Table';
import EndpointTableFilterForm from 'client/components/Endpoint/components/EndpointTableFilterForm';
import BeLoading from 'client/components/Be/BeLoading';
import EndpointEditModal from 'client/components/Endpoint/components/EndpointEditModal';

class EndpointTable extends Component {

  static defaultProps = {
    endpoints: []
  }

  static propTypes = {
    endpoints: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onDeleteEndpoint: propTypes.func,
    onRefresh: propTypes.func,
    loading: propTypes.bool,
  }

  renderDeleteButton(id) {
    const { onDeleteEndpoint } = this.props;
    const confirmProps = {
      title: 'Confirm endpoint deletion',
      text: 'Are you sure you want to delete this endpoint?'
    };
    return (
      <ModalConfirmButton className="btn btn-default" callback={() => onDeleteEndpoint(id)} contentProps={confirmProps} Content={ModalContentConfirmWarning}>
        <i className="icon mdi mdi-delete"></i>
      </ModalConfirmButton>
    );
  }

  handleModalClosed = (data = {}) => {
    if(data.wasUpdated) return this.props.onRefresh();
    return Promise.resolve();
  }

  renderEndpoints() {
    const { endpoints, handleSelectRow, isRowSelected } = this.props;
    if(!endpoints || !endpoints.length) return (
      <tr>
        <td className="empty" colSpan={8}>No endpoints to show</td>
      </tr>
    );
    return endpoints.map(endpoint => {
      const {
        id,
        type,
        status,
        createdAt,
        updatedAt,
        settings: { logLevel },
        User,
      } = endpoint;
      return (
        <tr key={id}>
          <td>
            <TableCheckbox
              base="endpointTableSelect"
              id={id}
              onSelect={handleSelectRow}
              isSelected={isRowSelected(id)}
            />
          </td>
          <td className="cell-detail">
            <span>{type}</span>
            <span className="cell-detail-description">{id}</span>
          </td>
          <td><EndpointStatusLabel status={status} /></td>
          <td>{logLevel || ''}</td>
          <td><Time time={createdAt} format="YYYY-MM-DD" /></td>
          <td><Time time={updatedAt} format="YYYY-MM-DD" /></td>
          <TableUserCell user={User} />
          <td className="text-right">
            <div className="btn-group">
              <Link className="btn btn-default" to={`/endpoint/log?endpointId=${endpoint.id}`} title="Show endpoint logs">
                <i className="icon mdi mdi-view-list"></i>
              </Link>
            </div>{' '}
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: endpoint, title: type}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>
              <ModalButton className="btn btn-default" title="Edit Endpoint" ModalComponent={EndpointEditModal} onClose={this.handleModalClosed} modalProps={{endpoint}}>
                <span className="icon mdi mdi-settings"></span>
              </ModalButton>
              {this.renderDeleteButton(id)}
            </div>
          </td>
        </tr>
      )
    })
  }

  renderTable() {
    const { handleSelectAll, isAllSelected, orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                <th>
                  <TableCheckbox
                    id="endpointTableSelectAll"
                    onSelectAll={handleSelectAll}
                    isSelected={isAllSelected()}
                  />
                </th>
                <TableThSort column="id" {...sortProps}>Type/ID</TableThSort>
                <TableThSort column="status" {...sortProps}>Status</TableThSort>
                <th>Log</th>
                <TableThSort column="createdAt" {...sortProps}>Created</TableThSort>
                <TableThSort column="updatedAt" {...sortProps}>Updated</TableThSort>
                <th>User</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderEndpoints()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['endpoint', 'endpoints']} />
          </div>
        </div>
        <div className="col-sm-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { onFilter, filter } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of Endpoints</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <EndpointTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

export default TableCheckboxManager(EndpointTable, {
  allIds: function(props) {
    const endpoints = props.endpoints || [];
    return endpoints.map(endpoint => endpoint.id);
  } 
});
