import React from 'react';
import propTypes from 'prop-types';
import $ from 'jquery';

export default class AppHasLoaded extends React.Component {

  static propTypes = {
    when: propTypes.bool.isRequired,
  }

  static defaultProps = {
    when: true,
  }

  onLoad() {
    // remove loading indicator
    $('body > .loader').remove();
  }

  componentDidMount() {
    if(this.props.when) {
      this.onLoad();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.when === true && this.props.when !== prevProps.when) {
      this.onLoad();
    }
  }

  render() {
    // return this.props.children;
    const { when, children } = this.props;
    if(!when) return null;
    return (
      <>
        {children}
      </>
    );
  }

}
