import { apiAction } from 'client/redux/apiHelpers';

export const endpointSnowRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/snow/${id}`,
    method: 'GET',
    types
  });
};

export const endpointSnowEditSettings = (type, isEditing) => {
  return {
    type,
    isEditing
  };
};

export const endpointSnowUpdateSettings = (types, id, settings) => {
  return apiAction({
    endpoint: `/api/endpoint/snow/${id}/credentials`,
    method: 'PATCH',
    types,
    json: settings
  });
};

export const endpointSnowTest = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/snow/${id}/credentials/test`,
    method: 'POST',
    types,
    json: {}
  });
};
