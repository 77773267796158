import { USER_LOG_INDEX, USER_LOG_INDEX_ORDERBY, USER_LOG_INDEX_SET_PAGE, USER_LOG_INDEX_FILTER } from 'client/redux/user/log/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  logs: null,
  filter: {userId: '', type: '', level: '', ip: '', timeStart: '', timeEnd: ''},
  page: 1,
  orderBy: 'time',
  orderDirection: 'DESC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(USER_LOG_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceTableActions([USER_LOG_INDEX_FILTER, USER_LOG_INDEX_SET_PAGE, USER_LOG_INDEX_ORDERBY], state, action);
  return state;
};
