import { uiProps } from 'client/redux/ui/actionCreators';
import { UI_PROPS } from 'client/redux/ui/constants';
import { INSTANCE_UPDATE_TITLE, INSTANCE_UPDATE_TITLE_SUCCESS } from 'client/redux/instance/constants';
import * as actionCreators from 'client/redux/instance/actionCreators';

export const instanceTitleModalUpdateTitle = (id, newTitle) => dispatch => {
  return dispatch(actionCreators.instanceUpdateTitle(INSTANCE_UPDATE_TITLE, id, newTitle)).then(result => {
    if(result && result.type === INSTANCE_UPDATE_TITLE_SUCCESS) {
      return dispatch(uiProps(UI_PROPS, {refresh: true})).then(() => result);
    }
    return result;
  });
};
