export const UI_FRAGMENTS_INDEX_REQUEST = 'UI_FRAGMENTS_INDEX_REQUEST';
export const UI_FRAGMENTS_INDEX_SUCCESS = 'UI_FRAGMENTS_INDEX_SUCCESS';
export const UI_FRAGMENTS_INDEX_FAILURE = 'UI_FRAGMENTS_INDEX_FAILURE';
export const UI_FRAGMENTS_INDEX = [UI_FRAGMENTS_INDEX_REQUEST, UI_FRAGMENTS_INDEX_SUCCESS, UI_FRAGMENTS_INDEX_FAILURE];

export const UI_FRAGMENTS_INDEX_DELETE_REQUEST = 'UI_FRAGMENTS_INDEX_DELETE_REQUEST';
export const UI_FRAGMENTS_INDEX_DELETE_SUCCESS = 'UI_FRAGMENTS_INDEX_DELETE_SUCCESS';
export const UI_FRAGMENTS_INDEX_DELETE_FAILURE = 'UI_FRAGMENTS_INDEX_DELETE_FAILURE';
export const UI_FRAGMENTS_INDEX_DELETE = [UI_FRAGMENTS_INDEX_DELETE_REQUEST, UI_FRAGMENTS_INDEX_DELETE_SUCCESS, UI_FRAGMENTS_INDEX_DELETE_FAILURE];

export const UI_FRAGMENTS_READ_REQUEST = 'UI_FRAGMENTS_READ_REQUEST';
export const UI_FRAGMENTS_READ_SUCCESS = 'UI_FRAGMENTS_READ_SUCCESS';
export const UI_FRAGMENTS_READ_FAILURE = 'UI_FRAGMENTS_READ_FAILURE';
export const UI_FRAGMENTS_READ = [UI_FRAGMENTS_READ_REQUEST, UI_FRAGMENTS_READ_SUCCESS, UI_FRAGMENTS_READ_FAILURE];

export const UI_FRAGMENTS_UPDATE_REQUEST = 'UI_FRAGMENTS_UPDATE_REQUEST';
export const UI_FRAGMENTS_UPDATE_SUCCESS = 'UI_FRAGMENTS_UPDATE_SUCCESS';
export const UI_FRAGMENTS_UPDATE_FAILURE = 'UI_FRAGMENTS_UPDATE_FAILURE';
export const UI_FRAGMENTS_UPDATE = [UI_FRAGMENTS_UPDATE_REQUEST, UI_FRAGMENTS_UPDATE_SUCCESS, UI_FRAGMENTS_UPDATE_FAILURE];

export const UI_FRAGMENTS_CREATE_REQUEST = 'UI_FRAGMENTS_CREATE_REQUEST';
export const UI_FRAGMENTS_CREATE_SUCCESS = 'UI_FRAGMENTS_CREATE_SUCCESS';
export const UI_FRAGMENTS_CREATE_FAILURE = 'UI_FRAGMENTS_CREATE_FAILURE';
export const UI_FRAGMENTS_CREATE = [UI_FRAGMENTS_CREATE_REQUEST, UI_FRAGMENTS_CREATE_SUCCESS, UI_FRAGMENTS_CREATE_FAILURE];

export const rendererSelect2 = [
  {id: '', text: 'Default'},
  {id: 'inline', text: 'Inline tags only'},
  {id: 'nomustache', text: 'No mustache'},
];
