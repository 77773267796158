import React, { Component } from 'react';
import * as connectorPanelActions from 'client/redux/connector/panelActions';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import { connect } from 'react-redux';
import ConnectorPanelForm from 'client/components/ConnectorPanel/components/ConnectorPanelForm';
import { CONNECTOR_PANEL_UPDATE_SUCCESS } from 'client/redux/connector/constants';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { gritterAlertPush } from 'client/redux/gritter/actions';

class ConnectorPanel extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    onUpdated: propTypes.func
  }

  handleUpdate = fields => {
    const { id, onUpdated } = this.props;
    return this.props.connectorPanelUpdate(id, fields).then(getResponseToFormErrorHandler()).then(result => {
      if(result.type !== CONNECTOR_PANEL_UPDATE_SUCCESS) return;
      setTimeout(onUpdated, 1);
      return this.props.gritterAlertPush('planUpdateSuccess', {
        className: 'color success',
        title: 'Update complete',
        message: 'The connector was updated',
        closeAfter: 3000
      });
    });
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.connectorPanelRead(id);
  }

  getInitialFormValues() {
    const { title, shortTitle, isActive, type } = this.props.connector;
    return {title, shortTitle, isActive, type};
  }

  render() {
    const { connector, isReading, error } = this.props;
    return (
      <BeLoading loading={isReading} positionStatic={true}>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        {connector &&
          <ConnectorPanelForm
            error={error}
            onSubmit={this.handleUpdate}
            initialValues={this.getInitialFormValues()}
          />
        }
      </BeLoading>
    );
  }

}

const actions = {
  ...connectorPanelActions,
  gritterAlertPush,
};

export default connect(state => state.connector.panel, actions)(ConnectorPanel);
