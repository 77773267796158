import { SETTINGS_INDEX } from 'client/redux/settings/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  settings: null
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(SETTINGS_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  return state;
};
