import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MainContent, Content } from 'client/components/App';
import { CONNECTOR_SELECT_PLAN_CREATE_SUCCESS } from 'client/redux/connector/constants';
import { Helmet } from 'react-helmet';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import ConnectorSelectPlanTable from 'client/components/ConnectorSelectPlan/components/ConnectorSelectPlanTable';
import AccountAddCardModal from 'client/components/Account/components/AccountAddCardModal';
import { connectorInstantiate } from 'client/redux/connector/actions';
import { onConnectorInstantiate } from 'client/redux/connector/eventHandlerActions';
import * as connectorSelectPlanActions from 'client/redux/connector/selectPlanActions';
import _ from 'lodash';
import 'client/components/ConnectorSelectPlan/ConnectorSelectPlan.css';

class ConnectorSelectPlan extends Component {

  state = {
    addCardModalOpen: false,
    addCardModalPlanId: null
  }

  connectorId(props) {
    return _.get(props || this.props, 'match.params.connectorId');
  }

  getData = () => {
    return this.props.connectorSelectPlan(this.connectorId());
  }

  componentDidMount() {
    this.getData();
  }

  handleSelectPlanWithPaymentSource = planId => {
    const connectorId = this.props.connector.id;
    return this.props.connectorSelectPlanCreate(planId).then(result => {
      if(result.type === CONNECTOR_SELECT_PLAN_CREATE_SUCCESS) {
        // proceed with instantiation
        return this.props.connectorInstantiate(connectorId).then(this.props.onConnectorInstantiate);
      }
    });
  }

  userCanSelectWithoutAddingCard() {
    const { paymentContext = {} } = this.props;
    const { hasPaymentSource, hasManualPayment, hasAccountCredit } = paymentContext;
    return Boolean(hasPaymentSource || (hasManualPayment && hasAccountCredit));
  }

  handleSelectPlan = plan => {
    if(this.userCanSelectWithoutAddingCard()) {
      return this.handleSelectPlanWithPaymentSource(plan.id);
    } else {
      this.setState({
        addCardModalOpen: true,
        addCardModalPlanId: plan.id
      });
    }
  }

  handleCardAdded = () => {
    const { addCardModalPlanId } = this.state;
    return this.handleSelectPlanWithPaymentSource(addCardModalPlanId);
  }

  closeModal = ev => {
    this.setState({
      addCardModalOpen: false,
      addCardModalPlanId: null
    });
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  renderTitle() {
    return (
      <Helmet>
        <title>Subscribe to a plan to activate the connector</title>
      </Helmet>
    );
  }

  render() {
    const { isReading, error, connector, plans, paymentContext } = this.props;
    const { addCardModalOpen } = this.state;
    if(error) return this.renderError(error);
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeLoading positionStatic={true} loading={isReading}>
            {!isReading && plans && <ConnectorSelectPlanTable
              connector={connector}
              plans={plans}
              userCanSelectWithoutAddingCard={this.userCanSelectWithoutAddingCard()}
              paymentContext={paymentContext}
              onSelectPlan={this.handleSelectPlan}
            />}
          </BeLoading>
          <AccountAddCardModal isOpen={addCardModalOpen} onClose={this.closeModal} onCardAdded={this.handleCardAdded} />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  ...connectorSelectPlanActions,
  connectorInstantiate,
  onConnectorInstantiate,
};

export default connect(state => state.connector.selectPlan, actions)(ConnectorSelectPlan);
