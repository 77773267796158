import React  from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import Icon from 'client/components/Be/Icon';
import { isFilled, isString, isUUID } from 'client/redux/validators';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { escapeDottedName } from 'client/redux/formHelpers';
import { connect } from 'react-redux';
import * as optionsActions from 'client/redux/mwtd/optionsActions';
import { topdeskFieldNameToNatural } from 'client/redux/field/constants';
import FieldMapFormComponent from 'client/components/FieldMap/FieldMapFormComponent';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import { FormReduxSelect2, FormReduxTextareaJSON, FormReduxInput } from 'client/components/Form/FormRedux';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import { targetToLabel } from 'client/redux/field/constants';
import Sticky from 'react-stickynode';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';

const validators = {
  isString: [isString()],
  isNonEmptyString: [isString(), isFilled()],
  isFilledUUID: [isString(), isFilled(), isUUID()],
  isUUID: [isString(), isUUID()]
};

class ConnectedMWTDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  handleReset = ev => {
    this.props.reset();
  }

  componentDidMount() {
    const { instanceId } = this.props;
    const assetTemplateId = _.get(this.props, 'initialValues.deviceAssetTemplateId');
    this.props.instanceMwtdOptionsReadMeta(instanceId);
    this.getAssetFieldTargets(assetTemplateId);
  }

  getAssetFieldTargets = assetTemplateId => {
    if(assetTemplateId) {
      const topdeskEndpointId = _.get(this.props, 'endpointsByType.topdesk.id');
      return this.props.instanceMwtdOptionsAssetFieldTargetsRead(topdeskEndpointId, assetTemplateId);
    }
    return this.props.instanceMwtdOptionsAssetFieldTargetsReset();
  }

  handleDeviceAssetTemplateIdChange = (ev, value) => {
    return this.getAssetFieldTargets(value);
  }

  render() {
    const {
      currentFormValues,
      dirty,
      disabled,
      endpointsByType,
      errorFieldTargets,
      errorMeta,
      initialValues,
      instanceId,
      invalid,
      isReadingFieldTargets,
      isReadingMeta,
      isSetupMode,
      isUpdating,
      meta,
      onNudgeStep,
      onSubmit,
    } = this.props;
    const fieldTargets = _.get(this.props, 'assetFieldTargets.fieldTargets', []);
		return (
      <Form id="instanceMwtdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceMwtdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20 row">
          <div className="col-md-6">
            <IncidentSettings
              initialValues={initialValues}
              disabled={disabled}
              endpointsByType={endpointsByType}
            />
            <IncidentFieldMap
              initialValues={initialValues}
              disabled={disabled}
              endpointsByType={endpointsByType}
              isReadingMeta={isReadingMeta}
              errorMeta={errorMeta}
              meta={meta}
            />
          </div>
          <div className="col-md-6">
            <AssetSettings
              initialValues={initialValues}
              disabled={disabled}
              endpointsByType={endpointsByType}
              onDeviceAssetTemplateIdChange={this.handleDeviceAssetTemplateIdChange}
            />
            <AssetFieldMap
              initialValues={initialValues}
              disabled={disabled}
              endpointsByType={endpointsByType}
              isReadingFieldTargets={isReadingFieldTargets}
              errorFieldTargets={errorFieldTargets}
              fieldTargets={fieldTargets}
              currentFormValues={currentFormValues}
            />
            <WebserviceSettings
              initialValues={initialValues}
              disabled={disabled}
              endpointsByType={endpointsByType}
              instanceId={instanceId}
              meta={meta}
            />
          </div>
        </div>
      </Form>
		);
  }
}

function QuickCopyAddon(props) {
  const { value } = props;
  return (
    <CopyToClipboard text={value}>
      <div className="input-group-btn">
        <Button title="Copy to clipboard"><Icon icon="copy" /></Button>
      </div>
    </CopyToClipboard>
  );
}

class WebserviceSettings extends React.Component {

  render() {
    const {
      instanceId,
      disabled,
      initialValues: { wsPassword },
      meta,
    } = this.props;
    const wsUrl = _.get(meta, 'wsUrl', '');
    const wsTokens = _.get(meta, 'wsTokens', '');
    return (
      <div className="well xs-pb-10">
        <InstanceHelpHeader
          subTitle="Options to copy into 'Custom Integration' in Managed Workplace"
          helpFragmentId="instance_mwtd_settings_soap_webservice_help"
        >
          SOAP Webservice Settings
        </InstanceHelpHeader>
        <div>
          <div className="form-group">
            <label className="control-label" htmlFor="mwtdOptionsWsUsername">
              Username
            </label>
            <div className="input-group">
              <input
                id="mwtdOptionsWsUsername"
                className="form-control"
                type="text"
                readOnly
                value={instanceId}
              />
              <QuickCopyAddon value={instanceId} />
            </div>
          </div>
          <Field
            base="mwtdOptions"
            component={FormReduxInput}
            name="wsPassword"
            value={wsPassword || ''}
            disabled={disabled}
            label="Password"
            validate={validators.isNonEmptyString}
          />
          <div className="form-group">
            <label className="control-label" htmlFor="mwtdOptionsWsURL">
              URL
            </label>
            <div className="input-group">
              <input
                id="mwtdOptionsWsURL"
                className="form-control"
                type="text"
                readOnly
                value={wsUrl}
              />
              <QuickCopyAddon value={wsUrl} />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="mwtdOptionsWsMethod">
              WS Method
            </label>
            <input
              id="mwtdOptionsWsMethod"
              className="form-control"
              type="text"
              readOnly
              value="SaveIncident"
            />
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="mwtdOptionsWsTokens">
              Tokens
            </label>
            <textarea
              id="mwtdOptionsWsTokens"
              className="form-control"
              type="text"
              readOnly
              value={wsTokens}
              rows="25"
            />
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="mwtdOptionsWsResponseType">
              Response Type
            </label>
            <input
              id="mwtdOptionsWsResponseType"
              className="form-control"
              type="text"
              readOnly
              value="XML"
            />
          </div>
        </div>
      </div>
    );
  }

}

function IncidentFieldMap(props) {
  const {
    isReadingMeta,
    errorMeta,
    disabled,
    endpointsByType,
    meta,
    initialValues,
  } = props;
  return (
    <div className="well form-horizontal xs-mb-10">
      <InstanceHelpHeader
        subTitle="Mapping from an MW alert to a new TOPdesk Incident"
        helpFragmentId="instance_mwtd_settings_incident_fieldmap_help"
      >
        Field Map for new Incidents
      </InstanceHelpHeader>
      <BeRemoteErrorAlert error={errorMeta} />
      <BeLoading loading={isReadingMeta} className="xs-mt-20">
        <IncidentFieldMapTargets
          disabled={disabled}
          endpointsByType={endpointsByType}
          meta={meta}
          initialValues={initialValues}
        />
      </BeLoading>
    </div>
  );
}

class IncidentFieldMapTargets extends React.Component {

  getAvailableAlertSources() {
    const columns = _.get(this.props, 'meta.alertFields');
    if(!_.isArray(columns)) return [];
    return [
      {type: 'FieldSourceResolve'},
      {type: 'FieldSourceObject', columns},
    ];
  }

  render() {
    const {
      endpointsByType: { topdesk: { id: topdeskEndpointId }},
      disabled,
      meta,
      initialValues,
    } = this.props;

    const fieldMap = _.get(meta, 'fieldMap.incident');
    const fieldTargets = _.get(meta, 'fieldMap.incident.targets', []);
    if(!fieldTargets || !fieldTargets.length) {
      return <p className="xs-m-0">There is nothing here yet.</p>;
    }

    return fieldTargets.map(target => {
      const { name } = target;
      let defaultSource = _.get(fieldMap, ['defaultTargetToSourceMap', name]);
      if(!defaultSource) defaultSource = {type: 'FieldSourceIgnore'};
      const defaultValue = _.get(initialValues, ['incidentFieldMap', name]);
      return (
        <Field
          key={`incidentFieldMap_${name}`}
          name={`incidentFieldMap.${escapeDottedName(name)}`}
          base={`mwtdOptionsIncidentFieldMap${name}`}
          defaultValue={defaultValue}
          component={FieldMapFormComponent}
          label={topdeskFieldNameToNatural(name)}
          disabled={disabled}
          target={target}
          defaultSource={defaultSource}
          availableSources={this.getAvailableAlertSources()}
          endpointIds={{topdeskId: topdeskEndpointId}}
        />
      );
    });
  }

}

class AssetFieldMap extends React.Component {

  static propTypes = {
    isReadingFieldTargets: propTypes.bool.isRequired,
    errorFieldTargets: propTypes.object,
    fieldTargets: propTypes.array.isRequired,
    currentFormValues: propTypes.object.isRequired,
  }

  getAssetTemplateId() {
    const initialName = _.get(this.props, 'initialValues.deviceAssetTemplateId');
    const currentName = _.get(this.props, 'currentFormValues.deviceAssetTemplateId');
    return !_.isUndefined(currentName) ? currentName : initialName;
  }

  render() {
    const {
      isReadingFieldTargets,
      errorFieldTargets,
    } = this.props;

    const assetTemplateId = this.getAssetTemplateId();
    if(!assetTemplateId) {
      return null;
    }

    return (
      <div className="well form-horizontal">
        <InstanceHelpHeader
          subTitle="Mapping from an Managed Workplace Device to a TOPdesk Asset Template"
          helpFragmentId="instance_mwtd_settings_asset_fieldmap_help"
        >
          Asset Field Map
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorFieldTargets} />
        <BeLoading loading={isReadingFieldTargets} className="xs-mt-20">
          {this.renderAssetFieldMapTargets()}
        </BeLoading>
      </div>
    );
  }

  getDefaultSource(fieldName) {
    switch(fieldName) {
      case 'name': return {type: 'FieldSourceObject', props: {fieldName: 'device.name'}};
      default: return {type: 'FieldSourceIgnore'};
    }
  }

  getAvailableDeviceSources() {
    // these are the fields available in a ManagedWorkplaceDeviceObject
    const columns = [
      'device.devid',
      'device.devguid',
      'device.sysname.0',
      'device.sysdescr.0',
      'device.alias',
      'device.lastup',
      'device.Status',
      'device.omid',
      'device.iswmi',
      'device.issnmp',
      'device.name',
      'device.macaddress',
      'device.notes',
      'varSite.varfrdnm',
      'varSite.siteguid',
      'varSite.sitecode',
      'varSite.sitecmp',
      'varSite.clientid',
      'varSite.sitestreet',
      'varSite.sitecity',
      'varSite.sitestprov',
      'varSite.sitepcode',
      'varSite.sitecntry',
      'varSite.sitecontact',
      'varSite.sitephn',
      'varSite.siteeml',
      'varSite.siteinstdate',
      'varSite.siteomver',
      'varSite.sitestatus',
      'varGuid.vardomain',
      'varGuid.company',
      'varGuid.varguid',
      'varGuid.status',
      'assetsByType.site[0].nm',
      'assetsByType.site[0].descr',
      'assetsByType.site[0].tmcreated',
      'assetsByType.site[0].invtag',
      'assetsByType.site[0].physloc',
      'assetsByType.site[0].warexpdt',
      'assetsByType.site[0].comment',
      'assetsByType.site[0].notes',
      'assetsByType.site[0].addr',
      'assetsByType.site[0].macaddr',
      'assetsByType.site[0].dnsnm',
      'assetsByType.site[0].sysnm',
      'assetsByType.site[0].tmlastup',
      'assetsByType.site[0].lastlognm',
      'assetsByType.site[0].defgw',
      'assetsByType.site[0].iswmi',
      'assetsByType.site[0].issnmp',
      'assetsByType.cpu[0].idx',
      'assetsByType.cpu[0].cpunm',
      'assetsByType.cpu[0].cpuver',
      'assetsByType.cpu[0].speed',
      'assetsByType.disk[0].idx',
      'assetsByType.disk[0].cpunm',
      'assetsByType.disk[0].cpuver',
      'assetsByType.disk[0].speed',
      'assetsByType.app[0].appnm',
      'assetsByType.mon[0].appnm',
      'assetsByType.nwkcrd[0].idx',
      'assetsByType.nwkcrd[0].nm',
      'assetsByType.nwkcrd[0].isdhcp',
      'assetsByType.nwkcrd[0].macaddr',
      'assetsByType.optdrv[0].drv',
      'assetsByType.optdrv[0].vendor',
      'assetsByType.qfe[0].hfid',
      'assetsByType.qfe[0].instdt',
      'assetsByType.qfe[0].descr',
      'assetsByType.sys[0].mdl',
      'assetsByType.sys[0].vndr',
      'assetsByType.sys[0].biosver',
      'assetsByType.sys[0].physmem',
      'assetsByType.sys[0].nm',
      'assetsByType.sys[0].tz',
      'assetsByType.sys[0].osnm',
      'assetsByType.sys[0].winver',
      'assetsByType.sys[0].winsp',
      'assetsByType.sys[0].windir',
      'assetsByType.sys[0].winprodkey',
      'assetsByType.sys[0].domrole',
      'assetsByType.sys[0].dom',
      'assetsByType.vid[0].idx',
      'assetsByType.vid[0].nm',
      'assetsByType.vid[0].colorbits',
      'assetsByType.vid[0].vidres',
      'assetsByType.vid[0].drvver',
      'allAppsList',
      'mwDeviceUrl',
    ];
    return [
      {type: 'FieldSourceObject', columns},
    ];
  }

  renderAssetFieldMapTargets() {
    const {
      fieldTargets,
      endpointsByType: { topdesk: { id:topdeskEndpointId }},
      disabled,
    } = this.props;

    if(!fieldTargets || !fieldTargets.length) {
      return <p className="xs-m-0">There is nothing here yet.</p>;
    }

    return fieldTargets.map(target => {
      const { name } = target;
      return (
        <Field
          key={`deviceAssetFieldMap_${name}`}
          name={`deviceAssetFieldMap.${name}`}
          base={`mwtdOptionsDeviceAssetTemplateId${name}`}
          component={FieldMapFormComponent}
          label={targetToLabel(target)}
          disabled={disabled}
          target={target}
          defaultSource={this.getDefaultSource(name)}
          availableSources={this.getAvailableDeviceSources()}
          endpointIds={{topdeskId: topdeskEndpointId}}
        />
      );
    });
  }
}

function AssetSettings(props) {
  const {
    disabled,
    endpointsByType: { topdesk },
    initialValues: { deviceAssetTemplateId, deviceGroupToTemplateMap, mwBaseUrl },
    onDeviceAssetTemplateIdChange,
  } = props;
  return (
    <div className="well xs-pb-10">
      <InstanceHelpHeader
        subTitle="Options for how TOPdesk Assets are created by Managed Workplace Devices"
        helpFragmentId="instance_mwtd_settings_assets_help"
      >
        Asset Settings
      </InstanceHelpHeader>
      <div>
        <Field
          base="mwtdOptionsDeviceAssetTemplateId"
          name="deviceAssetTemplateId"
          label="Asset Template for Managed Workplace Devices"
          component={FormReduxSelect2}
          select2ComponentProps={{endpointId: topdesk.id, forWhat: 'assetTemplateId'}}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2Options={{minimumInputLength: 1, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Do not create assets'}}
          disabled={disabled}
          defaultValue={deviceAssetTemplateId}
          validate={validators.isUUID}
          onChange={onDeviceAssetTemplateIdChange}
        />
      </div>
      <div>
        <InstanceHelpHeader subTitle="Define how class codes are translated into asset template names">
          Group Name to Asset Template ID map
        </InstanceHelpHeader>
        <Field
          base="mwtdOptionsDeviceGroupToTemplateMap"
          component={FormReduxTextareaJSON}
          getDefaultValue={() => []}
          name="deviceGroupToTemplateMap"
          placeholder="Define which device group should map to which asset template id"
          showAllRows={true}
          value={deviceGroupToTemplateMap}
          disabled={disabled}
        />
        {/* <Field */}
        {/*   base="mwtdOptionsDeviceAssetTemplateId" */}
        {/*   name="deviceAssetTemplateIdForWorkstations" */}
        {/*   label="Asset Template for Devices in the 'Workstations' group" */}
        {/*   component={FormReduxSelect2} */}
        {/*   select2ComponentProps={{endpointId: topdesk.id, forWhat: 'assetTemplateId'}} */}
        {/*   select2Component={EndpointTopdeskRemoteSearcher} */}
        {/*   select2Options={{minimumInputLength: 1, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Create assets with default template'}} */}
        {/*   helpText="Only devices that belong to the 'Workstations' group will be created with this template" */}
        {/*   disabled={disabled} */}
        {/*   defaultValue={deviceAssetTemplateIdForWorkstations} */}
        {/*   validate={validators.isUUID} */}
        {/* /> */}
      </div>
      <div>
        <Field
          base="mwtdOptions"
          component={FormReduxInput}
          name="mwBaseUrl"
          value={mwBaseUrl || ''}
          disabled={disabled}
          label="Managed Workplace Base URL"
        />
      </div>
    </div>
  );
}

function IncidentSettings(props) {
  const {
    disabled,
    endpointsByType: { topdesk },
    initialValues: { incidentProcessingStatusWhenClosedId },
  } = props;
  return (
    <div className="well xs-pb-10">
      <InstanceHelpHeader
        subTitle="Options for how TOPdesk Incidents are created by Managed Workplace Alerts"
        helpFragmentId="instance_mwtd_settings_incidents_help"
      >
        Incident Settings
      </InstanceHelpHeader>
      <div>
        <Field
          base="mwtdOptionsIncidentProcessingStatusWhenClosedId"
          component={FormReduxSelect2}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2ComponentProps={{forWhat: 'processingStatus', endpointId: topdesk.id}}
          select2Options={{minimumInputLength: 1, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Do not update processing status'}}
          name="incidentProcessingStatusWhenClosedId"
          defaultValue={incidentProcessingStatusWhenClosedId}
          disabled={disabled}
          label="Incident Processing Status for cleared Managed Workplace Alerts"
          validate={validators.isUUID}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const selector = formValueSelector('mwtdOptions');
  // the value of these fields need to be available when rendering
  return {
    ...state.mwtd.options,
    currentFormValues: {
      deviceAssetTemplateId: selector(state, 'deviceAssetTemplateId'),
    },
  };
}

const actions = {
  ...optionsActions,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'mwtdOptions',
  enableReinitialize: true,
})(ConnectedMWTDOptionsForm));
