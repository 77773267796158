import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { Tabs, Tab } from 'react-bootstrap';
import { push } from 'connected-react-router';
import Icon from 'client/components/Be/Icon';
import AccountCards from 'client/components/Account/components/AccountCards';
import { accountBilling, accountBillingUpdate, accountAddressUpdate } from 'client/redux/account/billingActions';
import AccountBillingSettingsForm from 'client/components/Account/components/AccountBillingSettingsForm';
import { StripeAmount } from 'client/components/Helpers/Strings';
import AccountInvoiceIndex from 'client/components/AccountInvoice/AccountInvoiceIndex';
import AccountBillingAddressForm from 'client/components/Account/components/AccountBillingAddressForm';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import _ from 'lodash';
import 'client/components/Account/AccountBilling.css';

class AccountBilling extends Component {

  getData = () => {
    return this.props.accountBilling();
  }

  componentDidMount() {
    this.getData();
  }

  activeTab(props) {
    return _.get(props || this.props, 'match.params.tab', 'settings');
  }

  handleSelectTab = newTab => {
    return this.props.push(`/account/billing/${newTab}`);
  }

  handleBillingUpdate = fields => {
    return this.props.accountBillingUpdate(fields).then(getResponseToFormErrorHandler()).then(this.getData);
  }

  handleAddressUpdate = fields => {
    return this.props.accountAddressUpdate(fields);
  }

  renderPanelHeader() {
    switch(this.activeTab()) {
      default:
      case 'settings': return (
        <div className="panel-heading">
          Billing info
          <span className="panel-subtitle">Enter your billing information below</span>
        </div>
      );
      case 'invoices': return (
        <div className="panel-heading">
          Invoices
          <span className="panel-subtitle">List all invoices</span>
        </div>
      );
    }
  }

  getAddressFormValues() {
    const fields = ['name', 'line1', 'line2', 'city', 'state', 'zip', 'country'];
    const address = _.pick(_.get(this.props, 'address', {}), ...fields);
    return {address};
  }

  getBillingFormValues() {
    return _.pick(_.get(this.props, 'billing'), 'vatNumber');
  }

  renderAccountBalance() {
    const { billing } = this.props;
    if(!billing) return null;
    const { accountCurrency, accountBalance } = billing;
    const str = accountBalance >= 0 ? 'You owe' : 'Your credit';
    return (
      <span>
        <strong>{str}:</strong> <StripeAmount currency={accountCurrency} amount={-1 * accountBalance} />
      </span>
    );
  }

  renderCardsOrAddress() {
    const { billing } = this.props;
    if(!billing) return null;
    const { paymentMethod } = billing;
    switch(paymentMethod) {
      default:
      case 'automatic': return <AccountCards />
      case 'manual': return <AccountBillingAddressForm initialValues={this.getAddressFormValues()} onSubmit={this.handleAddressUpdate} />
    }
  }

  renderCardsOrAddressHeader() {
    const paymentMethod = _.get(this.props, 'billing.paymentMethod', 'automatic');
    switch(paymentMethod) {
      default:
      case 'automatic': return (
        <h4>
          Address &amp; card
          <span className="panel-subtitle">Enter your billing address and credit card information</span>
        </h4>
      );
      case 'manual': return (
        <h4>
          Invoice Address
          <span className="panel-subtitle">Enter your billing address</span>
        </h4>
      );
    }
  }

  renderBillingOverview() {
    const { isReading } = this.props;
    const taxPercentage = _.get(this.props, 'billing.taxPercentage', 0);
    return (
      <div id="payment-methods-card" className="xs-p-20 xs-pb-0">
        <div className="row">
          <div className="col-md-6">
            {this.renderCardsOrAddressHeader()}
            <div className="well xs-p-20" style={{position: 'relative'}}>
              {this.renderCardsOrAddress()}
            </div>
          </div>
          <div className="col-md-6">
            <h4>
              Current Account Balance
              <span className="panel-subtitle">Shows how much credit you have or how much you owe us</span>
            </h4>
            <div className="well xs-p-20" style={{position: 'relative'}}>
              {this.renderAccountBalance()}
            </div>
            <h4>
              VAT number
              <span className="panel-subtitle">Enter your EU VAT number if applicable</span>
            </h4>
            <div className="well xs-pb-0">
              <BeLoading loading={isReading}>
                <AccountBillingSettingsForm initialValues={this.getBillingFormValues()} onSubmit={this.handleBillingUpdate} taxPercentage={taxPercentage} />
              </BeLoading>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { error } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Account Billing Overview</title>
        </Helmet>
        <MainContent>
          <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
          <div className="panel panel-default panel-border panel-border-color panel-border-color-primary panel-account-billing">
            {this.renderPanelHeader()}
            <div className="tab-container">
            <Tabs id="account-billing-tabs" activeKey={this.activeTab()} onSelect={this.handleSelectTab}>
              <Tab eventKey="settings" title={<span><Icon icon="card" />Billing information</span>}>
                {this.renderBillingOverview()}
              </Tab>
              <Tab eventKey="invoices" title={<span><Icon icon="receipt" />Invoices</span>}>
                <AccountInvoiceIndex />
              </Tab>
            </Tabs>
            </div>
          </div>
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  accountBilling,
  accountBillingUpdate,
  accountAddressUpdate,
  push,
};

export default connect(state => state.account.billing, actions)(AccountBilling);
