import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

class HasRole extends React.Component {

  static propTypes = {
    currentUserRole: propTypes.string.isRequired,
    requiredRole: propTypes.oneOfType([propTypes.string, propTypes.array]),
  }

  render() {
    const { children, currentUserRole, requiredRole } = this.props;
    if(_.isString(requiredRole) && currentUserRole !== requiredRole) return null;
    if(_.isArray(requiredRole) && !requiredRole.some(role => currentUserRole === role)) return null;
    return children;
  }

}

const getMapStateToProps = (extendWith = {}) => state => {
  return {
    currentUserRole: _.get(state, 'ui.self.role', 'nobody'),
    ...extendWith
  };
};

export const IsRole = connect(getMapStateToProps({}))(HasRole);
export const IsAdmin = connect(getMapStateToProps({requiredRole: 'admins'}))(HasRole);
export const IsPartner = connect(getMapStateToProps({requiredRole: 'partners'}))(HasRole);
export const IsUser = connect(getMapStateToProps({requiredRole: 'users'}))(HasRole);
