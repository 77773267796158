import React, { Component } from 'react';

class PageHeader extends Component {

  render() {
    return (
      <div className="page-head">
        <h2 className="page-head-title xs-mt-20">{this.props.children}</h2>
      </div>
    );
  }

}

export default PageHeader;
