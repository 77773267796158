import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import { INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS } from 'client/redux/fstd/constants';

export const onInstanceFstdFieldMapperDelete = deleteActionResult => dispatch => {
  if(deleteActionResult) {
    const { type } = deleteActionResult;
    if(type === INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS) {
      const message = 'The field map was deleted';
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'fstd_fieldmap_delete_success', {
        className: 'color warning',
        title: 'Deletion succeded',
        message,
        closeAfter: 3000
      }));
    }
  }
};
