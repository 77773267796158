import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';

class GRTDTesterUser extends Component {

  static propTypes = {
    id: propTypes.string,
    user: propTypes.object,
    onRemove: propTypes.func,
    onReload: propTypes.func,
    onSync: propTypes.func
  }

  handleClickSync = ev => {
    this.props.onSync(this.props.id);
  }

  handleClickReload = ev => {
    this.props.onReload(this.props.id);
  }

  handleClickRemove = ev => {
    this.props.onRemove(this.props.id);
  }

  renderNoticeTableRows(notices) {
    if(!notices || !notices.length) {
      return (
        <tr>
          <td colSpan="3">
            <em>No action taken with this user</em>
          </td>
        </tr>
      );
    }

    const { user = {} } = this.props;
    const { wasLast, graphUser } = user;
    const futureTense = wasLast !== 'synced';

    return notices.map((notice, index) => {
      return (
        <NoticeRow
          key={index}
          notice={notice}
          subject={graphUser.displayName}
          futureTense={futureTense}
        />
      );
    });
  }

  renderNoticeTable() {
    const { user } = this.props;
    const { notices, wasLast, error } = user;
    if(!wasLast && !error) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {this.renderNoticeTableRows(notices)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    if(!user) return null;
    const { graphUser, shouldBeOperator, shouldBePerson, shouldHandleOperators, shouldHandlePersons, error } = user;
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-account xs-mr-5"></i>
            {graphUser.displayName}
          </div>
          <span className="btn-group pull-right">
            <ButtonWaiting type="button" onClick={this.handleClickSync} isWaiting={user.isSyncing} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>{' '}
              Sync
            </ButtonWaiting>
            <ButtonWaiting type="button" onClick={this.handleClickReload} isWaiting={user.isReloading} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-refresh"></i>{' '}
              Reload
            </ButtonWaiting>
            <button onClick={this.handleClickRemove} type="button" className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-delete"></i>{' '}
              Remove from test
            </button>
          </span>
          <span className="pull-right small" style={{lineHeight: '30px'}}>
            {shouldHandlePersons && (
              <span className="xs-mr-10">
                Should be TOPdesk Person: <strong>{shouldBePerson ? 'Yes' : 'No'}</strong>
              </span>
            )}
            {shouldHandleOperators && (
              <span className="xs-mr-10">
                Should be TOPdesk Operator: <strong>{shouldBeOperator ? 'Yes' : 'No'}</strong>
              </span>
            )}
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} />
        {this.renderNoticeTable()}
      </div>
    );
  }

};

export default GRTDTesterUser;
