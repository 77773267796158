import * as actionCreators from 'client/redux/grtd/actionCreators';
import { instanceReadStatistics } from 'client/redux/instance/actionCreators';
import { INSTANCE_GRTD_READ_STATISTICS } from 'client/redux/grtd/constants';

export const instanceGrtdReadStatistics = (id, statistic, query) => dispatch => {
  switch(statistic) {
    default: return dispatch(instanceReadStatistics(INSTANCE_GRTD_READ_STATISTICS, id, statistic, query));
    case 'graphUsersProcessed': return dispatch(actionCreators.instanceGrtdReadStatistics(INSTANCE_GRTD_READ_STATISTICS, id, statistic, query));
  }
};
