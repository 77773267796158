export const INSTANCE_OPTD_TEST_ALARM_ADD_REQUEST = 'INSTANCE_OPTD_TEST_ALARM_ADD_REQUEST';
export const INSTANCE_OPTD_TEST_ALARM_ADD_SUCCESS = 'INSTANCE_OPTD_TEST_ALARM_ADD_SUCCESS';
export const INSTANCE_OPTD_TEST_ALARM_ADD_FAILURE = 'INSTANCE_OPTD_TEST_ALARM_ADD_FAILURE';
export const INSTANCE_OPTD_TEST_ALARM_ADD = [INSTANCE_OPTD_TEST_ALARM_ADD_REQUEST, INSTANCE_OPTD_TEST_ALARM_ADD_SUCCESS, INSTANCE_OPTD_TEST_ALARM_ADD_FAILURE];

export const INSTANCE_OPTD_TEST_ALARM_REMOVE = 'INSTANCE_OPTD_TEST_ALARM_REMOVE';
export const INSTANCE_OPTD_TEST_CLEAR = 'INSTANCE_OPTD_TEST_CLEAR';
export const INSTANCE_OPTD_TEST_ALARM_SEARCH_FIELD_CHANGE = 'INSTANCE_OPTD_TEST_ALARM_SEARCH_FIELD_CHANGE';

export const INSTANCE_OPTD_TEST_ALARM_REQUEST = 'INSTANCE_OPTD_TEST_ALARM_REQUEST';
export const INSTANCE_OPTD_TEST_ALARM_SUCCESS = 'INSTANCE_OPTD_TEST_ALARM_SUCCESS';
export const INSTANCE_OPTD_TEST_ALARM_FAILURE = 'INSTANCE_OPTD_TEST_ALARM_FAILURE';
export const INSTANCE_OPTD_TEST_ALARM = [INSTANCE_OPTD_TEST_ALARM_REQUEST, INSTANCE_OPTD_TEST_ALARM_SUCCESS, INSTANCE_OPTD_TEST_ALARM_FAILURE];

export const INSTANCE_OPTD_SYNC_ALARM_REQUEST = 'INSTANCE_OPTD_SYNC_ALARM_REQUEST';
export const INSTANCE_OPTD_SYNC_ALARM_SUCCESS = 'INSTANCE_OPTD_SYNC_ALARM_SUCCESS';
export const INSTANCE_OPTD_SYNC_ALARM_FAILURE = 'INSTANCE_OPTD_SYNC_ALARM_FAILURE';
export const INSTANCE_OPTD_SYNC_ALARM = [INSTANCE_OPTD_SYNC_ALARM_REQUEST, INSTANCE_OPTD_SYNC_ALARM_SUCCESS, INSTANCE_OPTD_SYNC_ALARM_FAILURE];

export const INSTANCE_OPTD_READ_STATISTICS_REQUEST = 'INSTANCE_OPTD_READ_STATISTICS_REQUEST';
export const INSTANCE_OPTD_READ_STATISTICS_SUCCESS = 'INSTANCE_OPTD_READ_STATISTICS_SUCCESS';
export const INSTANCE_OPTD_READ_STATISTICS_FAILURE = 'INSTANCE_OPTD_READ_STATISTICS_FAILURE';
export const INSTANCE_OPTD_READ_STATISTICS = [INSTANCE_OPTD_READ_STATISTICS_REQUEST, INSTANCE_OPTD_READ_STATISTICS_SUCCESS, INSTANCE_OPTD_READ_STATISTICS_FAILURE];

export const INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_RESET = 'INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_RESET';

export const INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST = 'INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST';
export const INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS = 'INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS';
export const INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE = 'INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE';
export const INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ = [INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST, INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS, INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE];

export const INSTANCE_OPTD_OPTIONS_READ_META_REQUEST = 'INSTANCE_OPTD_OPTIONS_READ_META_REQUEST';
export const INSTANCE_OPTD_OPTIONS_READ_META_SUCCESS = 'INSTANCE_OPTD_OPTIONS_READ_META_SUCCESS';
export const INSTANCE_OPTD_OPTIONS_READ_META_FAILURE = 'INSTANCE_OPTD_OPTIONS_READ_META_FAILURE';
export const INSTANCE_OPTD_OPTIONS_READ_META = [INSTANCE_OPTD_OPTIONS_READ_META_REQUEST, INSTANCE_OPTD_OPTIONS_READ_META_SUCCESS, INSTANCE_OPTD_OPTIONS_READ_META_FAILURE];
