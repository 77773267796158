import React from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';

export default class QRTDCodeCreatorAssetTableFilter extends React.Component {

  static propTypes = {
    templates: propTypes.array,
    filter: propTypes.object.isRequired,
    onFilter: propTypes.func,
    endpointTopdeskId: propTypes.string.isRequired,
  }

  static defaultProps = {
    filter: {},
  }

  handleChange = ev => {
    return this.handleChangeVal(ev.target.value, ev.target.name);
  }

  handleChangeVal = (value, name) => {
    const newFilter = {...this.props.filter, [name]: value};
    return this.props.onFilter(newFilter);
  }

  render() {
    const {
      filter: { searchTerm, nameFragment, templateName, branchId },
      endpointTopdeskId,
      templates,
    } = this.props;
    const templateData = templates.map(({text}) => ({id: text, text}));
    return (
      <div className="row be-datatable-header xs-p-5">
        <div className="col-md-3 xs-pr-10 xs-pl-15">
          <div className="dataTables_length form-group xs-mb-10">
            <input
              id="qrCodeCreatorAssetTableFilterAssetName"
              type="text"
              className="form-control"
              name="nameFragment"
              onChange={this.handleChange}
              placeholder="Any asset name"
              value={nameFragment}
            />
          </div>
        </div>
        <div className="col-md-3 xs-pr-10 xs-pl-15">
          <div className="dataTables_length form-group xs-mb-10">
            <input
              id="qrCodeCreatorAssetTableFilterSearchTerm"
              type="text"
              className="form-control"
              name="searchTerm"
              onChange={this.handleChange}
              placeholder="Search for asset"
              value={searchTerm}
            />
          </div>
        </div>
        <div className="col-md-3 xs-pr-10 xs-pl-10">
          <div className="dataTables_length form-group xs-mb-10">
            <Select2Wrap
              id="qrCodeCreatorAssetTableFilterAssetTemplateName"
              name="templateName"
              className="form-control"
              data={templateData}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any asset template'}}
              onChange={this.handleChange}
              defaultValue={templateName || ''}
            />
          </div>
        </div>
        <div className="col-md-3 xs-pr-10 xs-pl-10">
          <div className="dataTables_length form-group xs-mb-10">
            <EndpointTopdeskRemoteSearcher
              id="qrCodeCreatorAssetTableFilterBranchId" 
              name="branchId"
              endpointId={endpointTopdeskId}
              forWhat="branch"
              onChange={this.handleChangeVal}
              options={{allowClear: true, placeholder: 'Search by branch', minimumInputLength: 1}}
              defaultValue={branchId || ''}
            />
          </div>
        </div>
      </div>
    );
  }

}
