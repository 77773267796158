import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import BeLoading from 'client/components/Be/BeLoading';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';
import { HelpButton } from 'client/components/Helpers/Help';
import * as actions from 'client/redux/endpoint/graph/actions';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { AlertWithMessage } from 'client/components/Be/Alert';

class EndpointGraph extends React.Component {

  static defaultProps = {
    onUpdated: function() {},
    isSetupMode: true
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    setupMode: propTypes.bool,
    onUpdated: propTypes.func
  }

  componentDidMount() {
    if(this.props.preloadedEndpoint) {
      this.props.endpointGraphPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointGraphRead(this.props.endpointId);
    }
  }

  componentDidUpdate (prevProps) {
    const { endpointId } = this.props;
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointGraphRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  getConsentUrl() {
    const urlBase = process.env.REACT_APP_GRAPH_CONSENT_URL_BASE || 'http://linknow.test:3000/api/endpoint/graph';
    const state = {origin: window.location.href, id: this.props.endpointId};
    const stateString = encodeURIComponent(JSON.stringify(state));
    const url = urlBase + `/${this.props.endpointId}/getConsent?state=${stateString}`;
    return url;
  }

  handleTest = ev => {
    return this.props.endpointGraphTestConsent(this.props.endpointId);
  }

  renderContent() {
    const { isReading, isTesting, endpoint } = this.props;
    if(isReading || !endpoint) return null;

    const { consentWasGiven, testOk, testTimestamp  } = endpoint.state;
    const consentUrl = this.getConsentUrl();
    if(consentWasGiven) {
      return (
        <div>
          <EndpointTestButton
            isTesting={isTesting}
            onTest={this.handleTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
            labelOk="Consent OK"
            labelTest="Test consent"
          />
          <a href={consentUrl} className="btn btn-default pull-right">
            <i className="icon icon-left mdi mdi-plus-circle-o"></i>{' '}
            Authorize again
          </a>{' '}
        </div>
      );
    }
    return (
      <div className="text-center">
        <a href={consentUrl} className="btn btn-primary">
          Authorize application
        </a>
      </div>
    );
  }

  renderStatusAlert() {
    const { endpoint } = this.props;
    if(endpoint && endpoint.status === 'error') {
      return (
        <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
          <strong>Error:</strong> There is a problem with this endpoint.
        </AlertWithMessage>
      )
    }
    return null;
  }

  render() {
    const { isReading, error } = this.props;
    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              Microsoft Graph
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_graph_authorize_help'}}
              />
            </div>
            <span className="panel-subtitle">Authorize the Microsoft Graph application below</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading className="xs-p-20" loading={isReading}>
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {this.renderStatusAlert()}
            {this.renderContent()}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.graph, actions)(EndpointGraph);
