import { apiAction } from 'client/redux/apiHelpers';

export const settingsIndex = (types) => {
  return apiAction({
    endpoint: `/api/settings`,
    method: 'GET',
    types
  });
};

export const settingsRead = (types, id) => {
  return apiAction({
    endpoint: `/api/settings/${id}`,
    method: 'GET',
    types
  });
};

export const settingsUpdate = (types, id, update = {}) => {
  return apiAction({
    endpoint: `/api/settings/${id}`,
    method: 'PATCH',
    json: update,
    types
  });
};
