import * as actionCreators from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH, GRITTER_ALERT_DISMISS } from 'client/redux/gritter/constants';

export const gritterAlertPush = (id, props = {}) => dispatch => {
  return dispatch(actionCreators.gritterAlertPush(GRITTER_ALERT_PUSH, id, props));
};

export const gritterAlertDismiss = id => dispatch => {
  return dispatch(actionCreators.gritterAlertDismiss(GRITTER_ALERT_DISMISS, id));
};
