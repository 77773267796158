import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const userSubscriptionIndex = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/user/subscription', query),
    method: 'GET',
    types,
  });
};

export const userSubscriptionRead = (types, id) => {
  return apiAction({
    endpoint: `/api/user/subscription/${id}`,
    method: 'GET',
    types,
  });
};

export const userSubscriptionDelete = (types, id, query = {}) => {
  return apiAction({
    endpoint: appendQuery( `/api/user/subscription/${id}`, query),
    method: 'DELETE',
    types: types.map(type => ({type, meta: {id, query}})),
  });
};
