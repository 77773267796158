import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { isActiveSelect2 } from 'client/redux/connector/constants';
import { FormReduxSelect2, FormReduxInput } from 'client/components/Form/FormRedux';
import { isFilled, isString } from 'client/redux/validators';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';

const validateFilled = [isString(), isFilled()];

class ConnectorPanelForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
  }

  fieldProps() {
    return {base: 'connectorPanelForm', metaOptions: {defaultState: null, ignorePristine: true}};
  }

  render() {
    const { handleSubmit, onSubmit, submitting, invalid, error } = this.props;
    const fieldProps = this.fieldProps();
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <Field
          {...fieldProps}
          type="text"
          name="title"
          component={FormReduxInput}
          validate={validateFilled}
          label="Title"
        />
        <Field
          {...fieldProps}
          type="text"
          name="shortTitle"
          component={FormReduxInput}
          validate={validateFilled}
          label="Short Title"
        />
        <Field
          {...fieldProps}
          name="isActive"
          component={FormReduxSelect2}
          label="Active"
          data={isActiveSelect2}
          select2ComponentProps={{unwrapValue: true}}
        />
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'connectorPanel',
  enableReinitialize: true
})(ConnectorPanelForm);
