import React from 'react';
import propTypes from 'prop-types';
import { FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap';

export class SearchInput extends React.Component {

  static propTypes = {
    name: propTypes.string.isRequired,
    onSetValue: propTypes.func.isRequired,
    initialValue: propTypes.string,
  }

  state = {
    validationState: null,
    value: '{}',
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.initialValue && prevState.value === '{}' && this.state.value !== '{}') {
      this.setState({value: this.props.initialValue});
    }
  }

  handleChange = ev => {
    const value = ev.target.value;
    let validationState = null;
    if(!this.isValid(value)) {
      validationState = 'error';
    }
    this.setState({value, validationState});
  }

  handleCommit = () => {
    const { name } = this.props;
    const { value } = this.state;
    if(this.isValid(value)) {
      this.props.onSetValue(name, value);
    }
  }

  isValid(value) {
    try {
      JSON.parse(value);
    } catch(err) {
      return false;
    }
    return true;
  }

  render() {
    const { value, validationState } = this.state;
    return (
      <FormGroup
        className="dataTables_length"
        validationState={validationState}
      >
        <InputGroup>
          <FormControl
            id="instanceLogTableFilterSearch"
            onChange={this.handleChange}
            type="text"
            style={{fontFamily: 'monospace'}}
            value={value}
          />
          <InputGroup.Button>
            <Button onClick={this.handleCommit}>Search</Button>
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>
    );
  }

}
