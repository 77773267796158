import { apiAction } from 'client/redux/apiHelpers';

export const endpointManagedworkplaceEditCredentials = (type, isEditing) => {
  return {type, isEditing};
};

export const endpointManagedworkplaceUpdateCredentials = (types, id, credentials) => {
  return apiAction({
    endpoint: `/api/endpoint/managedworkplace/${id}/credentials`,
    method: 'PATCH',
    types,
    json: credentials,
  });
};

export const endpointManagedworkplaceTestCredentials = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/managedworkplace/${id}/credentials/test`,
    method: 'POST',
    types,
    json: {},
  });
};
