import { combineReducers } from 'redux';

import qrCodeCreator from 'client/redux/qrtd/qrCodeCreatorReducer';
import printPage from 'client/redux/qrtd/printPageReducer';
import statistics from 'client/redux/qrtd/statisticsReducer';
import options from 'client/redux/qrtd/optionsReducer';
import imageUpload from 'client/redux/qrtd/imageUploadReducer';

const qrtdReducer = combineReducers({
  options,
  printPage,
  qrCodeCreator,
  statistics,
  imageUpload,
});

export default qrtdReducer;
