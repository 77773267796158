import React, { Component } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';
import ObjectRow from 'client/components/Helpers/ObjectRow';
import { IsAdmin } from 'client/components/Helpers/IsRole'

const ObjectGraph = ({objectGraph}) => {
  const keys = _.without(_.keys(objectGraph), 'ok', 'code');
  return (
    <>
      {keys.map(id => (
        <ObjectRow subject={id} key={id} object={objectGraph[id]} />
      ))}
    </>
  );
};

export default class FATDITesterObject extends Component {

  static propTypes = {
    id: propTypes.string,
    objectContainer: propTypes.object,
    objectType: propTypes.string.isRequired,
    onRemove: propTypes.func,
    onTestObjectGraph: propTypes.func,
    onSync: propTypes.func
  }

  handleClickTestObjectGraph = ev => {
    this.props.onTestObjectGraph(this.props.objectType, this.props.id);
  }

  handleClickSync = ev => {
    this.props.onSync(this.props.objectType, this.props.id);
  }

  handleClickRemove = ev => {
    this.props.onRemove(this.props.objectType, this.props.id);
  }

  renderNoticeTableRows(notices) {
    const objectContainer = this.props.objectContainer || {};
    if(!notices || !notices.length) {
      return (
        <tr>
          <td colSpan="3">
            <em>No action taken with this object</em>
          </td>
        </tr>
      );
    }
    const { objectType } = this.props;
    const { wasLast } = objectContainer;
    const object = objectContainer[objectType];

    const futureTense = wasLast !== 'synced';

    return notices.map((notice, index) => {
      return (
        <NoticeRow
          key={index}
          notice={notice}
          subject={object.id}
          futureTense={futureTense}
        />
      );
    });
  }

  renderNoticeTable() {
    const { objectContainer } = this.props;
    const { objectGraph, notices } = objectContainer;
    if(!notices && !objectGraph) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {objectGraph && <ObjectGraph objectGraph={objectGraph} />}
              {notices && this.renderNoticeTableRows(notices)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { objectContainer, objectType } = this.props;
    if (!objectContainer) return null;
    const { error, isGettingObjectGraph, isSyncing  } = objectContainer;
    const object = objectContainer[objectType];
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-assignment xs-mr-5"></i>
            {objectType}
            {object && objectType === 'topdeskIncident' && (
              <>
                : {object.number} (status: {object.status})
              </>
            )}
            {object && objectType === 'fastOrder' && (
              <>
                : {object.id} (status: {object.localStatus} / {object.globalStatus})
              </>
            )}
            {object && !['fastOrder', 'topdeskIncident'].includes(objectType) && (
              <>
                : {object.id} (status: {object.status})
              </>
            )}
          </div>
          <span className="btn-group pull-right">
            <IsAdmin>
              <ButtonWaiting type="button" onClick={this.handleClickTestObjectGraph} isWaiting={isGettingObjectGraph} className="btn btn-rounded btn-default">
                <i className="icon icon-left mdi mdi-flash"></i>{' '}
                Object Graph
              </ButtonWaiting>
            </IsAdmin>
            <ButtonWaiting type="button" onClick={this.handleClickSync} isWaiting={isSyncing} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>{' '}
              Sync
            </ButtonWaiting>
            <button onClick={this.handleClickRemove} type="button" className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-delete"></i>{' '}
              Remove from test
            </button>
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} canShowDetails />
        {this.renderNoticeTable()}
      </div>
    );
  }

};
