import React, { Component } from 'react';
import NavbarUser from 'client/components/App/Navbar/NavbarUser';
import { Link } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';
import { connect } from 'react-redux';
import { Logo } from 'client/components/Branding';
import ConnectedNavbarSupportForm from 'client/components/App/Navbar/ConnectedNavbarSupportForm';

class Navbar extends Component {

  title() {
    const { location } = this.props;
    const titles = [
      {match: {exact: true, path: '/'}, title: 'Welcome to the LINKNOW dashboard'},
      {match: {exact: true, path: '/marketplace'}, title: 'Marketplace'},
      {match: {exact: true, path: '/connector'}, title: 'Connector Overview'},
      {match: {exact: true, path: '/support'}, title: 'Support'},
      {match: {exact: true, path: '/instance/:connectorId/plan'}, title: 'Subscribe to a plan'},
      {match: {exact: true, path: '/instance/aitd/:instanceId/setup'}, title: 'Microsoft Azure AI → TOPdesk Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/aitd/:instanceId/:tab?'}, title: 'Microsoft Azure AI → TOPdesk Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/fatd/:instanceId/setup'}, title: 'Fast API → TOPdesk Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/fatd/:instanceId/:tab?'}, title: 'Fast API → TOPdesk Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/fatdi/:instanceId/setup'}, title: 'Fast API WP 1 → TOPdesk Incidents Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/fatdi/:instanceId/:tab?'}, title: 'Fast API WP 1 → TOPdesk Incidents Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/grtd/:instanceId/setup'}, title: 'Microsoft Graph → TOPdesk Users Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/grtd/:instanceId/:tab?'}, title: 'Microsoft Graph → TOPdesk Users Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/fstd/:instanceId/setup'}, title: 'CSV → TOPdesk Assets Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/fstd/:instanceId/:tab?'}, title: 'CSV → TOPdesk Assets Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/optd/:instanceId/setup'}, title: 'OpManager → TOPdesk Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/optd/:instanceId/:tab?'}, title: 'OpManager → TOPdesk Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/mwtd/:instanceId/setup'}, title: 'Barracuda Managed Workplace → TOPdesk Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/mwtd/:instanceId/:tab?'}, title: 'Barracuda Managed Workplace → TOPdesk Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/swtd/:instanceId/setup'}, title: 'Snow Atlas → TOPdesk Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/swtd/:instanceId/:tab?'}, title: 'Snow Atlas → TOPdesk Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/qrtd/:instanceId/setup'}, title: 'QR Codes → TOPdesk Incidents Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/qrtd/:instanceId/:tab?'}, title: 'QR Codes → TOPdesk Incidents Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/pvtd/:instanceId/setup'}, title: 'Person Verification → TOPdesk Incidents Setup Wizard', instanceTitleSuffix: 'Wizard', useInstanceTitle: true},
      {match: {exact: true, path: '/instance/pvtd/:instanceId/:tab?'}, title: 'Person Verification → TOPdesk Incidents Overview', instanceTitleSuffix: 'Overview', useInstanceTitle: true},
      {match: {exact: true, path: '/account/invoice/:invoiceId'}, title: 'Invoice'},
      {match: {exact: true, path: '/account/billing/:tab?'}, title: 'Billing information'},
      {match: {exact: true, path: '/account/subscriptions'}, title: 'Plan Subscriptions'},
      {match: {exact: true, path: '/account/settings'}, title: 'Settings Overview'},
      {match: {exact: true, path: '/account/children'}, title: 'Sub-Accounts'},
      {match: {exact: true, path: '/endpoint/log'}, title: 'Endpoint Logs Overview'},
      {match: {exact: true, path: '/endpoint'}, title: 'Endpoints Overview'},
      {match: {exact: true, path: '/user/log'}, title: 'User Logs Overview'},
      {match: {exact: true, path: '/user/invoice'}, title: 'User Invoices Overview'},
      {match: {exact: true, path: '/user/invoice/:invoiceId'}, title: 'User Invoice View'},
      {match: {exact: true, path: '/user/subscription'}, title: 'User Subscriptions Overview'},
      {match: {exact: true, path: '/user'}, title: 'Users Overview'},
      {match: {exact: true, path: '/instance/log/fragment'}, title: 'Instance Log Fragments Overview'},
      {match: {exact: true, path: '/instance/log'}, title: 'Instance Logs Overview'},
      {match: {exact: true, path: '/instance'}, title: 'Instances Overview'},
      {match: {exact: true, path: '/plan'}, title: 'Plans Overview'},
      {match: {exact: true, path: '/settings'}, title: 'Settings Overview'},
      {match: {exact: true, path: '/uiFragments'}, title: 'UI Fragments Overview'},
    ];
    const found = titles.find(title => matchPath(location.pathname, title.match));
    if (!found) return 'No title';
    if (found.useInstanceTitle) return this.props.currentInstanceTitle + (found.instanceTitleSuffix ? ' ' + found.instanceTitleSuffix : '');
    return found.title;
  }

  logoShouldBeWhite() {
    const { color } = this.props;
    return !!color; // anything non-falsy means the logo should be white
  }

  render() {
    return (
      <nav className="navbar navbar-default navbar-fixed-top be-top-header">
        <div className="container-fluid">
          <div className="navbar-header">
            <Link to="/" className="navbar-brand" style={{width: 'auto', backgroundImage: 'none'}}>
              <Logo white={this.logoShouldBeWhite()} />
            </Link>
          </div>
          <div className="be-right-navbar">
            <NavbarUser />
            <div className="page-title">{this.title()}</div>
            <ConnectedNavbarSupportForm />
          </div>
        </div>
      </nav>
    );
  }

}

const mapStateToProps = state => ({
  color: state.navbar?.color?.color,
  currentInstanceTitle: state?.instance?.view?.instance?.displayTitle,
});

export default withRouter(connect(mapStateToProps)(Navbar));
