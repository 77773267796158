import { combineReducers } from 'redux';

import test from 'client/redux/fatdi/testReducer';
import statistics from 'client/redux/fatdi/statisticsReducer';
import options from 'client/redux/fatdi/optionsReducer';

const fatdiReducer = combineReducers({
  test,
  statistics,
  options,
});

export default fatdiReducer;
