import React from 'react';

export default class FieldMapTargetFieldsFilter extends React.Component {

  handleChange = ev => {
    const { onChange } = this.props;
    onChange(ev.target.value);
  }

  render () {
    const { base, value } = this.props;
    return (
      <div className="xs-mb-10">
        <label className="radio-inline xs-pt-0">
          <input
            type="radio"
            id={base + 'FieldMapFilterEverything'}
            name={base + 'filter'}
            onChange={this.handleChange}
            value="everything"
            checked={value === 'everything'}
          />
          Show all
        </label>
        <label className="radio-inline xs-pt-0">
          <input
            type="radio"
            id={base + 'FieldMapFilterDefined'}
            name={base + 'filter'}
            onChange={this.handleChange}
            value="defined"
            checked={value === 'defined'}
          />
          Only defined
        </label>
        <label className="radio-inline xs-pt-0">
          <input
            type="radio"
            id={base + 'FieldMapFilterUserDefined'}
            name={base + 'filter'}
            onChange={this.handleChange}
            value="user_defined"
            checked={value === 'user_defined'}
          />
          Only user-defined
        </label>
      </div>
    );
  }
}
