import React from 'react';
import 'babel-polyfill';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore, { history } from 'client/redux/configureStore';
import registerServiceWorker from 'client/registerServiceWorker';
import { ConnectedRouter } from 'connected-react-router'
import RouterRoot from 'client/components/Router/RouterRoot';

import 'linknow-beagle/dist/html/assets/lib/material-design-icons/css/material-design-iconic-font.css';
import 'linknow-beagle/dist/html/assets/lib/select2/css/select2.css';
import 'linknow-beagle/dist/html/assets/lib/datatables/css/dataTables.bootstrap.css';
import 'linknow-beagle/dist/html/assets/lib/jquery.gritter/css/jquery.gritter.css';
import 'linknow-beagle/dist/html/assets/css/style.css';

const store = configureStore({});

const root = document.getElementById('root');
createRoot(root).render(
  <Provider store={store}>
      <ConnectedRouter history={history}>
        <RouterRoot />
      </ConnectedRouter>
  </Provider>,
  
);
registerServiceWorker();
