import { INSTANCE_GRTD_READ_META } from 'client/redux/grtd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  error: null,
  fieldMap: null,
  graphFields: null,
};

export default function metaReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_GRTD_READ_META, state, action, {requestProp: 'isReading', successPickProps: ['fieldMap', 'graphFields']});
  return state;
};
