import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { fieldTargetPropToNatural, fieldTargetTypeToNatural, fieldTargetPropToValue } from 'client/redux/field/constants';

export default class FieldMapTargetInfo extends React.Component {

  static propTypes = {
    target: propTypes.object.isRequired,
  }

  renderRows() {
    const { target: { name, type, props } } = this.props;
    return [
      <KV key="name" desc="Field ID">{name}</KV>,
      <KV key="type" desc="Type">{fieldTargetTypeToNatural(type)}</KV>,
      ...(_.map(props, (value, key) => (
        <KV key={key} desc={fieldTargetPropToNatural(key)}>{fieldTargetPropToValue(key, value)}</KV>
      ))),
    ];
  }

  render() {
    return (
      <div className="xs-mb-20">
        <label className="control-label">Field Target</label>
        <table className="table table-condensed table-bordered xs-mb-0">
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }
}

const KV = ({desc, children}) => (
  <tr>
    <th>{desc}</th>
    <td>{children}</td>
  </tr>
);
