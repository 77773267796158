import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class OPTDPanel extends Component {

  static propTypes = {
    onCall: propTypes.func.isRequired,
  }

  render() {
    const { onCall } = this.props;
    return (
      <div>
        <div>
          <ButtonWaitingPromise onClick={ev => onCall('syncOneInstanceDevices')} type="button" className="btn btn-default">
            Synchronize Devices
          </ButtonWaitingPromise>{' '}
          <ButtonWaitingPromise onClick={ev => onCall('syncOneInstanceAlarms')} type="button" className="btn btn-default">
            Synchronize Alarms
          </ButtonWaitingPromise>{' '}
        </div>
        <hr />
        <div>
          <div className="btn-group-vertical btn-space">
            <ButtonWaitingPromise onClick={ev => onCall('resetGetUpdatedAlarmsLastFromTime')} type="button" className="btn btn-default">
              Reset "GetUpdatedAlarms" last timestamp
            </ButtonWaitingPromise>{' '}
          </div>
        </div>
      </div>
    );
  }

}
