import { USER_INVOICE_READ, USER_INVOICE_READ_FORM } from 'client/redux/user/invoice/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: null,
  invoice: null,
  form: {hideCredit: true},
};

export default function readReducer(state = defaultState, action) {
  state = reduceByTypes(USER_INVOICE_READ, state, action, {
    requestProp: 'isReading',
    successSpread: true,
    successPickProps: [
      'invoice', 
      'invoiceObject', 
      'sender', 
      'recipient', 
      'reverseCharge', 
      'instances', 
      'instancesByStripePlanId'
    ],
  });
  if(action.type === USER_INVOICE_READ_FORM) {
    state = {...state, form: {...state.form, ...action.form}};
  }
  return state;
};
