import { apiAction } from 'client/redux/apiHelpers';

export const uiFragmentIndex = (types) => {
  return apiAction({
    endpoint: `/api/ui/fragment`,
    method: 'GET',
    types: types,
  });
};

export const uiFragmentRead = (types, id) => {
  return apiAction({
    endpoint: `/api/ui/fragment/${id}`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}}))
  });
};

export const uiFragmentUpdate = (types, id, json) => {
  return apiAction({
    endpoint: `/api/ui/fragment/${id}`,
    method: 'PATCH',
    json,
    types: types.map(type => ({type, meta: {id}}))
  });
};

export const uiFragmentCreate = (types, id, json) => {
  return apiAction({
    endpoint: `/api/ui/fragment/${id}`,
    method: 'POST',
    json,
    types: types.map(type => ({type, meta: {id}}))
  });
};

export const uiFragmentDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/ui/fragment/${id}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {id}}))
  });
};
