import React from 'react';
import { Link } from 'react-router-dom';
import { appendQuery } from 'client/helpers/urlHelpers';

export default function LinkQuery (props) {
  const { to, query, children, ...restOfProps } = props;
  const finalTo = appendQuery(to, query);
  return (
    <Link {...restOfProps} to={finalTo}>
      {children}
    </Link>
  );
}
