import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import AccountSubscriptionItemRow from 'client/components/AccountSubscription/components/AccountSubscriptionItemRow';
import { UserSubscriptionStatusLabel } from 'client/components/Helpers/StatusLabel';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { ModalConfirmButton, ModalContentConfirm } from 'client/components/Helpers/Modal';
import { ModalSimple } from 'client/components/Helpers/Modal';
import { StripeInterval, CurrencyName } from 'client/components/Helpers/Strings';
import AccountSubscriptionChoosePlanForm from 'client/components/AccountSubscription/components/AccountSubscriptionChoosePlanForm';
import * as subscriptionActions from 'client/redux/account/subscriptionActions';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { connect } from 'react-redux';
import _ from 'lodash';
import { StripeTime, StripeBillingInterval } from 'client/components/Helpers/Time';
import { StripeContext } from 'client/components/App/StripeContext';
import AccountSubscriptionFixProblem from 'client/components/AccountSubscription/AccountSubscriptionFixProblem';
import { ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_SUCCESS } from 'client/redux/account/constants';
import 'client/components/AccountSubscription/components/AccountSubscriptionPanel.css';

/**
 * @desc Represents a panel describing a Subscription
 */
class AccountSubscriptionPanel extends React.Component {

  static propTypes = {
    allPlansByStripeId: propTypes.object.isRequired,
    subscribablePlans: propTypes.array.isRequired,
    subscriptionId: propTypes.string,
  }

  state = {
    isChanged: false,
    choosePlanModalOpen: false,
  }

  componentDidMount() {
    const { isNew } = this.props;
    if(isNew) {
      this.setState({choosePlanModalOpen: true});
    }
  }

  /**
   * @desc Gets a list of all plans available for selection in this subscription
   * @return {Plan[]}
   */
  filterSubscribablePlans = () => {
    const { subscribablePlans, items } = this.props;
    const subscribedToPlans = items.map(item => item.plan);

    // we need to find the "template plan" which tells how an eligible plan should look
    const templatePlan = this.getTemplatePlan();

    if(!templatePlan) {
      // if there is no template plan, all plans are eligible for this subscription
      return subscribablePlans;
    }

    const { interval:templateInterval, currency:templateCurrency } = templatePlan;

    return subscribablePlans.filter(plan => {
      // we can only subscribe to plans not already in items
      if(subscribedToPlans.includes(plan.stripeId)) return false;
      // we can only subscribe to plans that are similar to the templatePlan
      const { interval, currency } = plan;
      return interval === templateInterval && currency === templateCurrency;
    });
  }

  /**
   * @desc Finds the "template plan" which tells some requirements for this subscription
   * @return {Plan}
   */
  getTemplatePlan() {
    const { allPlansByStripeId, items } = this.props;
    if(!items || !items.length) {
      // no items in the subscription, no template for how it should look
      return null;
    }

    const templatePlanId = items[0].plan;
    return allPlansByStripeId[templatePlanId];
  }

  openChoosePlanModal = choosePlanModalOpen => {
    this.setState({choosePlanModalOpen});
  }

  openFixProblemModal = open => {
    const { subscriptionId } = this.props;
    this.props.accountSubscriptionToggleFixProblemModal(subscriptionId, open);
  }

  // request to preview subscription changes
  handlePreview = () => {
    const { subscriptionId, items } = this.props;
    return this.props.accountSubscriptionPreview(subscriptionId, {
      items: items,
    });
  }

  // request to create or update a subscription
  handleSave = () => {
    const { subscriptionId, items, isNew } = this.props;
    const body = {items: items};
    if(isNew) {
      return this.props.accountSubscriptionCreate(body);
    } else {
      return this.props.accountSubscriptionUpdate(subscriptionId, body);
    }
  };

  // request to delete/cancel a subscription
  handleDelete = () => {
    const { subscriptionId } = this.props;
    return this.props.accountSubscriptionDelete(subscriptionId);
  }

  // undos as changes to a subscription
  handleUndo = () => {
    const { subscriptionId } = this.props;
    return this.props.accountSubscriptionUndo(subscriptionId);
  }

  // adds a new plan to this subscription
  handleAddPlan = ({plan}) => {
    const { subscriptionId } = this.props;
    this.props.accountSubscriptionAddPlan(subscriptionId, plan);
    this.setState({choosePlanModalOpen: false});
  }

  isDisabledForUpdate() {
    const status = _.get(this.props, 'meta.status');
    return this.isDisabledForCancel() || status === 'trialing' || status === 'incomplete';
  }

  isDisabledForCancel() {
    const meta = this.props.meta || {};
    const { cancelAtPeriodEnd, status } = meta;
    return status === 'canceled' || cancelAtPeriodEnd || status === 'incomplete_expired';
  }

  renderSubscriptionItems(items = []) {
    if(!items.length) {
      return <tr><td colSpan="6">Nothing here yet</td></tr>
    }
    const isDisabled = this.isDisabledForUpdate();
    const { subscriptionId, allPlansByStripeId } = this.props;
    // we can delete if deleting this one does not make everything deleted
    const numDeleted = items.filter(item => item.deleted).length;

    return items.map(item => {
      const id = getPlanId(item.plan);
      return <AccountSubscriptionItemRow
          key={id}
          {...item}
          subscriptionId={subscriptionId}
          allPlansByStripeId={allPlansByStripeId}
          canDelete={numDeleted < items.length - 1}
          disabled={isDisabled}
        />
    });
  }

  getPanelClassName() {
    const { isNew, isChanged, meta = {} } = this.props;
    const { status } = meta;
    const classes = ['panel', 'panel-border', 'panel-contrast', 'panel-table', 'ln-panel-border-unmovable', 'ln-subscription-panel'];
    switch(true) {
      default: break;
      case isChanged || isNew: classes.push('panel-border-color', 'panel-border-color-success'); break;
      case status === 'past_due':
      case status === 'unpaid': classes.push('panel-border-color', 'panel-border-color-danger'); break;
      case status === 'canceled': classes.push('panel-border-color', 'panel-border-color-danger'); break;
    }
    return classNames(classes);
  }

  getSaveButtonConfirmText() {
    const { isNew, meta = {} } = this.props;
    const { hasManualPayment } = meta;
    switch(true) {
      default: return '';
      case isNew && hasManualPayment: return (
        <span>
          When you create this subscription you will receive an invoice from us for the total amount.
        </span>
      );
      case isNew && !hasManualPayment: return (
        <span>
          When you create this subscription your card will be automatically charged for the total amount now, and once at the end of every period.
        </span>
      );
      case !isNew && hasManualPayment: return (
        <span>
          When you update this subscription you will receive an invoice from us for the new total amount. Any credit that you have will be prorated.
        </span>
      );
      case !isNew && !hasManualPayment: return (
        <span>
          When you update this subscription your card will be automatically charged for the new total amount within 1-2 hours, and once at the end of every period. Any credit that you have will be prorated towards your next invoice/charge.
        </span>
      );
    }
  }

  handleClickFixButton = () => {
    const { subscriptionId } = this.props;
    this.props.accountSubscriptionReadPaymentIntent(subscriptionId).then(result => {
      if(result.type === ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_SUCCESS) {
        return this.props.accountSubscriptionToggleFixProblemModal(subscriptionId, true);
      }
      return result;
    });
  }

  handleVerificationComplete = () => {
    const { subscriptionId } = this.props;
    this.props.accountSubscriptionToggleFixProblemModal(subscriptionId, false);
    this.props.accountSubscriptionIndex();
  }

  renderSaveOrFixButton() {
    const { isReadingPaymentIntent } = this.props;
    const status = _.get(this.props, 'meta.status');

    if(status === 'incomplete') {
      return (
        <ButtonWaiting className="btn-warning btn-lg" isWaiting={isReadingPaymentIntent} onClick={this.handleClickFixButton}>
          <i className="icon icon-left mdi mdi-check"></i>{' '}
            Fix problem
        </ButtonWaiting>
      );
    }

    const confirmProps = {
      type: 'success',
      title: 'Confirm subscription update',
      text: this.getSaveButtonConfirmText(),
      yesText: 'Yes, I understand',
    };
    const { isChanged } = this.props;
    const saveBtnClasses = isChanged ? 'btn btn-success btn-lg' : 'btn btn-default btn-lg';
    const disabled = this.isDisabledForUpdate() || !isChanged;
    return (
      <ModalConfirmButton className={saveBtnClasses} callback={this.handleSave} contentProps={confirmProps} Content={ModalContentConfirm} disabled={disabled}>
        <i className="icon icon-left mdi mdi-check"></i>{' '}
        Save
      </ModalConfirmButton>
    );
  }

  renderUndoButton() {
    const { isChanged } = this.props;
    const disabled = this.isDisabledForUpdate() || !isChanged;
    return (
      <button className="btn btn-default btn-lg" type="button" onClick={this.handleUndo} disabled={disabled}>
        <i className="icon icon-left mdi mdi-undo"></i>{' '}
        Undo
      </button>
    );
  }

  renderHeader() {
    const { isNew, meta, items } = this.props;
    if(isNew) {
      const text = items.length > 0 ? 'You can now save the subscription or add another plan if possible' : 'Add the first plan to the subscription by pressing "+ Plan" to the right';
      return (
        <div className="col-sm-7">
          <strong>New Subscription</strong>
          <span className="panel-subtitle">
            {text}
          </span>
        </div>
      );
    }
    const templatePlan = this.getTemplatePlan();
    if(!templatePlan) return null;
    const { interval, currency } = templatePlan;
    return (
      <div className="col-sm-7">
        <StripeInterval interval={interval} />{' '}
        (<CurrencyName name={currency} />){' '}
        <UserSubscriptionStatusLabel className="ln-subscription-heading-status" {...meta} />
        <span className="panel-subtitle">
          {this.getHeaderText(meta)}
        </span>
      </div>
    );
  }

  getHeaderText(meta) {
    const { interval } = this.getTemplatePlan();
    const {
      created,
      status,
      currentPeriodEnd,
      cancelAtPeriodEnd,
      canceledAt,
      endedAt,
      billingCycleAnchor
    } = meta;

    if(status === 'incomplete') {
      return (
        <span>
          An automatic payment failed for this subscription and a user action is required.
        </span>
      );
    }

    if(status === 'incomplete_expired') {
      return (
        <span>
          An automatic payment failed for this subscription failed and the required user action was not performed.
        </span>
      );
    }

    const fmt = 'YYYY-MM-DD';
    if(endedAt) {
      return <span>This subscription was canceled on <StripeTime time={canceledAt} format={fmt} /> and ended on <StripeTime time={endedAt} format={fmt} /></span>
    }
    if(status === 'canceled' || cancelAtPeriodEnd) {
      return <span>This subscription was canceled on <StripeTime time={canceledAt} format={fmt} /> and will expire on <StripeTime time={currentPeriodEnd} format={fmt} /></span>
    }
    return (
      <span>
        This subscription was created on{' '}
        <StripeTime time={created} format={fmt} /> and will be billed on {' '}
        <StripeBillingInterval interval={interval} anchor={billingCycleAnchor} />
      </span>
    );
  }

  renderDeleteButton() {
    const isDisabled = this.isDisabledForCancel();
    const { isNew, meta = {} } = this.props;
    const { currentPeriodEnd } = meta;
    const confirmProps = {
      title: 'Confirm subscription cancellation',
      type: 'danger',
      text: <span>Are you sure you want to cancel this subscription? If you cancel, you will still have access to the subscribed plans until <StripeTime time={currentPeriodEnd} format={'YYYY-MM-DD'} /></span>,
      yesText: 'Cancel at period end',
      noText: 'Do nothing',
    };
    return (
      <ModalConfirmButton className="btn btn-default btn-lg" callback={this.handleDelete} contentProps={confirmProps} Content={ModalContentConfirm} disabled={isDisabled || isNew}>
        <i className="icon mdi mdi-delete"></i>{' ' }
      </ModalConfirmButton>
    );
  }

  renderPreviewButton() {
    return null;
    // const isDisabled = this.isDisabledForUpdate();
    // const comingSoonProps = {
    //   type: 'primary',
    //   title: 'Subscription Invoice Preview',
    //   text: 'This feature is coming soon!',
    //   yesText: 'I understand',
    // };
    // return (
    //   <ModalConfirmButton disabled={isDisabled} className="btn btn-default btn-lg" callback={() => {}} contentProps={comingSoonProps} Content={ModalContentConfirm}>
    //     <i className="icon mdi mdi-search icon-left"></i>{' '}
    //     Preview
    //   </ModalConfirmButton>
    // );
    // return false;
    // const isDisabled = this.isDisabledForUpdate();
    // const { items } = this.props;
    // return <button className="btn btn-default btn-lg" type="button" onClick={this.handlePreview} disabled={isDisabled || !items.length}>Preview</button>
  }

  renderAddPlanButton() {
    const isDisabled = this.isDisabledForUpdate();
    const availablePlans = this.filterSubscribablePlans();
    return (
      <button
        className="btn btn-default btn-lg"
        type="button"
        disabled={isDisabled || !availablePlans.length}
        onClick={() => this.openChoosePlanModal(true)}
      >
        <i className="icon mdi mdi-plus icon-left"></i>{' '}
        Plan
      </button>
    );
  }

  render() {
    const {
      items,
      error,
      subscriptionId,
      paymentIntent,
      isReadingPaymentIntent,
      fixProblemModalOpen,
    } = this.props;
    const { choosePlanModalOpen } = this.state;
    const availablePlans = this.filterSubscribablePlans();
    return (
      <div className={this.getPanelClassName()}>
        <div className="panel-heading panel-heading-contrast">
          <div className="row">
            {this.renderHeader()}
            <div className="col-sm-5">
              {this.renderSaveOrFixButton()}{' '}
              <div className="pull-right">
                <div className="btn-group btn-group-lg">
                  {this.renderUndoButton()}{' '}
                  {this.renderPreviewButton()}{' '}
                  {this.renderAddPlanButton()}{' '}
                </div>
                {' '}{this.renderDeleteButton()}
              </div>
            </div>
          </div>
          <BeRemoteErrorAlert className="ln-subscription-error" error={error} />
        </div>
        <div className="panel-body">
          <table className="table table-striped table-borderless">
            <thead>
              <tr>
                <th>Plan Name</th>
                <th>Plan Type</th>
                <th className="number">Price</th>
                <th className="number">Quantity</th>
                <th className="number">Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderSubscriptionItems(items)}
            </tbody>
          </table>
          <ModalSimple title="Choose from available plans" dialogClassName="colored-header colored-header-primary" isOpen={choosePlanModalOpen} onClose={() => this.openChoosePlanModal(false)}>
            <AccountSubscriptionChoosePlanForm
              availablePlans={availablePlans}
              onSelection={this.handleAddPlan}
              isFirstSelection={!items.length}
            />
          </ModalSimple>
          {subscriptionId && paymentIntent && (
            <ModalSimple
              bodyClassName="xs-pt-0 xs-pb-5"
              isOpen={fixProblemModalOpen} onClose={() => this.openFixProblemModal(false)}
            >
                <StripeContext.Consumer>
                  {stripe => (
                    <AccountSubscriptionFixProblem
                      subscriptionId={subscriptionId}
                      paymentIntent={paymentIntent}
                      isReadingPaymentIntent={isReadingPaymentIntent}
                      onVerificationComplete={this.handleVerificationComplete}
                      stripe={stripe}
                    />
                  )}
                </StripeContext.Consumer>
            </ModalSimple>
          )}
        </div>
      </div>
    );
  }

}

function getPlanId(plan) {
  if(!plan) return null;
  return _.isString(plan) ? plan : plan.id;
}

const actions = {
  ...subscriptionActions,
};

export default connect(null, actions)(AccountSubscriptionPanel);
