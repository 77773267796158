import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import BeLoading from 'client/components/Be/BeLoading';
import { Bool } from 'client/components/Helpers/Strings';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import ConnectorPanelModal from 'client/components/ConnectorPanel/components/ConnectorPanelModal';
import { TableCheckbox, TableThSort, TableCheckboxManager, TablePagination, TableShowCount } from 'client/components/Helpers/Table';

class ConnectorTable extends Component {

  static defaultProps = {
    connectors: []
  }

  static propTypes = {
    connectors: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onRefresh: propTypes.func,
    loading: propTypes.bool,
  }

  handleModalClosed = (data = {}) => {
    if(data.wasUpdated) return this.props.onRefresh();
  }

  renderConnectors() {
    const { connectors, handleSelectRow, isRowSelected } = this.props;
    if(!connectors || !connectors.length) return (
      <tr>
        <td className="empty" colSpan={6}>No connectors to show</td>
      </tr>
    );
    return connectors.map(connector => {
      const { id, type, title, shortTitle, isActive } = connector;
      return (
        <tr key={id}>
          <td>
            <TableCheckbox
              base="connectorTableSelect"
              id={id}
              onSelect={handleSelectRow}
              isSelected={isRowSelected(id)}
            />
          </td>
          <td>{id}</td>
          <td>{type}</td>
          <td className="cell-detail">
            <span>{title}</span>
            <span className="cell-detail-description">{shortTitle}</span>
          </td>
          <td><Bool map={['Inactive', 'Active']} value={isActive} /></td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: connector, title}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>
              <ModalButton className="btn btn-default" onClose={this.handleModalClosed} ModalComponent={ConnectorPanelModal} modalProps={{connector}}>
                <i className="icon mdi mdi-settings"></i>
              </ModalButton>
            </div>
          </td>
        </tr>
      )
    })
  }

  renderTable() {
    const { handleSelectAll, isAllSelected, orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                <th>
                  <TableCheckbox
                    id="connectorTableSelectAll"
                    onSelectAll={handleSelectAll}
                    isSelected={isAllSelected()}
                  />
                </th>
                <TableThSort column="id" {...sortProps}>ID</TableThSort>
                <TableThSort column="type" {...sortProps}>Type</TableThSort>
                <TableThSort column="title" {...sortProps}>Title/Short</TableThSort>
                <TableThSort column="isActive" {...sortProps}>Active</TableThSort>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderConnectors()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['connector', 'connectors']} />
          </div>
        </div>
        <div className="col-sm-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of Connectors</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

export default TableCheckboxManager(ConnectorTable, {
  allIds: function(props) {
    const connectors = props.connectors || [];
    return connectors.map(connector => connector.id);
  } 
});
