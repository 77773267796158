import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class EndpointCurlTemplate extends React.Component {

  static propTypes = {
    endpoint: propTypes.object.isRequired,
  }

  render() {
    const { endpoint: { type, settings, state } } = this.props;
    const template = getCurlTemplate(type, settings, state);
    if(!template) return null;
    return (
      <div>
        <textarea
          className="form-control xs-mb-5"
          readOnly
          value={template}
          style={{fontSize: '10px'}}
          rows="6"
        />
        <CopyToClipboard text={template}>
          <Button>Copy cURL call</Button>
        </CopyToClipboard>
      </div>
    );
  }

}

function getCurlTemplate(type, settings, state) {
  switch(type) {
    default: return '';

    case 'fast': return `export URL="${settings.apiUrl}"
export USER="${settings.username}"
export PASSWORD="${settings.password}"
export TOKEN=\`curl --silent -G -L -D - -o /dev/null  "$URL/login" --data-urlencode "user=$USER" --data-urlencode "password=$PASSWORD" | grep -i "access-token" | cut -d " " -f 2 | tr -d '\\r\\n'\`
curl -GLH "Access-Token: $TOKEN" --http1.1 "$URL/fi2partner/" --data-urlencode "filter=fi2part_fullname:'maria'"`;

    case 'topdesk': {
      const { appPassword } = settings;
      if(appPassword) return `export TOPDESK_API_URL="${settings.apiUrl}"
export TOPDESK_USER="${settings.username}"
export TOPDESK_APP_PASS="${settings.appPassword}"
curl --user "$TOPDESK_USER:$TOPDESK_APP_PASS" -Gv "$TOPDESK_API_URL/assetmgmt/assets" --data-urlencode "\\$filter=id eq '28a252eb-e70e-4db2-96fb-7f21e02f682a'"`;
      return `
export TOPDESK_API_URL="${settings.apiUrl}"
export TOPDESK_USER="${settings.username}"
export TOPDESK_PASS="${settings.password}"
export TOPDESK_TOKEN=\`curl -u $TOPDESK_USER:$TOPDESK_PASS $TOPDESK_API_URL/login/operator\`
curl -GvH "Authorization: TOKEN id=\\"$TOPDESK_TOKEN\\"" "$TOPDESK_API_URL/assetmgmt/assets" --data-urlencode "\\$filter=id eq '28a252eb-e70e-4db2-96fb-7f21e02f682a'"`;
    }

    case 'graph': return `export TOKEN="${_.get(state, 'token.accessToken')}"
curl -vGLH "Authorization: Bearer $TOKEN" 'https://graph.microsoft.com/v1.0/users' --data-urlencode "\\$filter=id eq '28a252eb-e70e-4db2-96fb-7f21e02f682a'"`;

    case 'opmanager': return `export OPMANAGER_API_URL="${settings.apiUrl}"
export OPMANAGER_KEY="${settings.apiKey}"
curl -Gv "$OPMANAGER_API_URL/alarm/listAlarms" --data-urlencode "apiKey=$OPMANAGER_KEY" --data-urlencode "fromTime=2018-04-17 10:25:00" --data-urlencode "toTime=2018-04-18 00:00:00"
`;
  }
}
