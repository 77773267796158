export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT = [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE];

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN = [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE];

export const LOGIN_PROPS_REQUEST = 'LOGIN_PROPS_REQUEST';
export const LOGIN_PROPS_SUCCESS = 'LOGIN_PROPS_SUCCESS';
export const LOGIN_PROPS_FAILURE = 'LOGIN_PROPS_FAILURE';
export const LOGIN_PROPS = [LOGIN_PROPS_REQUEST, LOGIN_PROPS_SUCCESS, LOGIN_PROPS_FAILURE];

export const AUTH_SWITCH_USER_REQUEST = 'AUTH_SWITCH_USER_REQUEST';
export const AUTH_SWITCH_USER_SUCCESS = 'AUTH_SWITCH_USER_SUCCESS';
export const AUTH_SWITCH_USER_FAILURE = 'AUTH_SWITCH_USER_FAILURE';
export const AUTH_SWITCH_USER = [AUTH_SWITCH_USER_REQUEST, AUTH_SWITCH_USER_SUCCESS, AUTH_SWITCH_USER_FAILURE];

export const AUTH_SWITCH_USER_RETURN_REQUEST = 'AUTH_SWITCH_USER_RETURN_REQUEST';
export const AUTH_SWITCH_USER_RETURN_SUCCESS = 'AUTH_SWITCH_USER_RETURN_SUCCESS';
export const AUTH_SWITCH_USER_RETURN_FAILURE = 'AUTH_SWITCH_USER_RETURN_FAILURE';
export const AUTH_SWITCH_USER_RETURN = [AUTH_SWITCH_USER_RETURN_REQUEST, AUTH_SWITCH_USER_RETURN_SUCCESS, AUTH_SWITCH_USER_RETURN_FAILURE];
