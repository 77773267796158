import { INSTANCE_LOG_FRAGMENT_INDEX, INSTANCE_LOG_FRAGMENT_INDEX_ORDERBY, INSTANCE_LOG_FRAGMENT_INDEX_SET_PAGE, INSTANCE_LOG_FRAGMENT_INDEX_FILTER } from 'client/redux/instance/logFragment/constants';
import { instanceLogFragment } from 'client/redux/instance/logFragment/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const instanceLogFragmentIndex = query => dispatch => {
  return dispatch(instanceLogFragment(INSTANCE_LOG_FRAGMENT_INDEX, query));
};

export const instanceLogFragmentIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(INSTANCE_LOG_FRAGMENT_INDEX_ORDERBY, orderBy, orderDirection));
};

export const instanceLogFragmentIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(INSTANCE_LOG_FRAGMENT_INDEX_SET_PAGE, page));
};

export const instanceLogFragmentIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(INSTANCE_LOG_FRAGMENT_INDEX_FILTER, filter));
};
