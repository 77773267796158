import { combineReducers } from 'redux';

import statistics from 'client/redux/grtd/statisticsReducer';
import test from 'client/redux/grtd/testReducer';
import meta from 'client/redux/grtd/metaReducer';

const grtdReducer = combineReducers({
  statistics,
  test,
  meta,
});

export default grtdReducer;
