import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { ModalButton, ModalFormattedObject, ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import Time from 'client/components/Helpers/Time';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import UIFragmentTableCreateForm from 'client/components/UIFragment/components/UIFragmentTableCreateForm';
import BeLoading from 'client/components/Be/BeLoading';

class UIFragmentTable extends Component {

  static defaultProps = {
    fragments: []
  }

  static propTypes = {
    fragments: propTypes.array,
    onRefresh: propTypes.func,
    onDeleteFragment: propTypes.func,
    loading: propTypes.bool,
  }

  renderFragmentsRows() {
    const { fragments, onRefresh } = this.props;
    if(!fragments || !fragments.length) return (
      <tr>
        <td className="empty" colSpan={4}>No UI fragments to show</td>
      </tr>
    );
    return fragments.map(fragment => {
      const { id, title, updatedAt } = fragment;
      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{title}</td>
          <td><Time time={updatedAt} format="YYYY-MM-DD HH:mm" /></td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" title="Show fragment" ModalComponent={ModalFormattedObject} modalProps={{object: fragment, title: id}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>{' '}
              <ModalButton className="btn btn-default" title="Edit fragment" ModalComponent={UIFragmentHelpModal} modalProps={{fragmentId: id, onFragmentWasUpdated: onRefresh}}>
                <span className="icon mdi mdi-settings"></span>
              </ModalButton>
              {this.renderDeleteButton(id)}
            </div>
          </td>
        </tr>
      );
    })
  }

  renderDeleteButton(id) {
    const { onDeleteFragment } = this.props;
    const confirmProps = {
      title: 'Confirm fragment deletion',
      text: 'Are you sure you want to delete this fragment?'
    };
    return (
      <ModalConfirmButton className="btn btn-default" callback={() => onDeleteFragment(id)} contentProps={confirmProps} Content={ModalContentConfirmWarning}>
        <i className="icon mdi mdi-delete"></i>
      </ModalConfirmButton>
    );
  }

  renderTable() {
    const { loading } = this.props;
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Updated</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderFragmentsRows()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  render() {
    const { onRefresh } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading panel-heading-divider">Table of UI Fragments</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <div className="row be-datatable-header xs-pb-10">
              <UIFragmentTableCreateForm onCreate={onRefresh}/>
            </div>
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }

};

export default UIFragmentTable;

