import * as actionCreators from 'client/redux/settings/actionCreators';
import { SETTINGS_PANEL_READ, SETTINGS_PANEL_UPDATE }  from 'client/redux/settings/constants';

export const settingsPanelRead = id => dispatch => {
  return dispatch(actionCreators.settingsRead(SETTINGS_PANEL_READ, id));
};

export const settingsPanelUpdate = (id, update) => dispatch => {
  return dispatch(actionCreators.settingsUpdate(SETTINGS_PANEL_UPDATE, id, update));
};
