import { combineReducers } from 'redux';

import meta from 'client/redux/pvtd/metaReducer';
import test from 'client/redux/pvtd/testReducer';
import statistics from 'client/redux/pvtd/statisticsReducer';

const pvtdReducer = combineReducers({
  meta,
  test,
  statistics,
});

export default pvtdReducer;
