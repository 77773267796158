import React, { Component } from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { levelSelect2 } from 'client/redux/instance/log/constants';
import { ConnectorIdSearcher, ConnectorInstanceIdSearcher } from 'client/components/Form/Select2Searchers';
import DateRangePickerWrap from 'client/components/Form/DateRangePickerWrap';
import 'react-bootstrap-daterangepicker/css/daterangepicker.css';

export default class InstanceLogTableFilterForm extends Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  handleChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render() {
    const { level, connectorId, connectorInstanceId, timeStart, timeEnd } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <DateRangePickerWrap
              startName="timeStart"
              startValue={timeStart}
              endName="timeEnd"
              endValue={timeEnd}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="instanceLogTableFilterLevel"
              name="level"
              className="form-control"
              data={levelSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any level'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={level || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <ConnectorIdSearcher
              id="instanceLogTableFilterConnectorId"
              className="form-control"
              name="connectorId"
              onChange={val => this.handleChange({target: {name: 'connectorId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any connector', minimumInputLength: 0}}
              defaultValue={connectorId}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <ConnectorInstanceIdSearcher
              id="instanceLogTableFilterConnectorInstanceId"
              className="form-control"
              name="connectorInstanceId"
              onChange={val => this.handleChange({target: {name: 'connectorInstanceId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any instance'}}
              defaultValue={connectorInstanceId}
            />
          </div>
        </div>
      </div>
    );
  }

}
