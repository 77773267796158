import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Sparklines, SparklinesBars, SparklinesLine } from 'react-sparklines';

/**
 * @description Wraps SparklinesBars with properties common to the app
 */
export class AppSparklinesBar extends Component {

  static defaultProps = {
    width: 85,
    height: 35,
    barWidth: 3,
    data: {}
  }

  static propTypes = {
    data: propTypes.object.isRequired,
    width: propTypes.number,
    height: propTypes.number,
    barWidth: propTypes.number
  }

  render() {
    const { width, height, barWidth } = this.props;
    const { data, limit } = this.props.data || {};
    if(!data || !limit) return null;
    return (
      <Sparklines data={data} limit={limit} width={width} height={height}>
        <SparklinesBars style={{fill: '#036abc'}} barWidth={barWidth} />
      </Sparklines>
    );
  }

}

export class AppSparklinesLine extends Component {

  static defaultProps = {
    width: 85,
    height: 35,
    data: {}
  }

  static propTypes = {
    data: propTypes.object.isRequired,
    width: propTypes.number,
    height: propTypes.number
  }

  render() {
    const { width, height } = this.props;
    const { data, limit } = this.props.data || {};
    if(!data || !limit) return null;
    return (
      <Sparklines data={data} limit={limit} width={width} height={height}>
        <SparklinesLine color="#036abc" style={{fill: "none"}} />
      </Sparklines>
    );
  }

}
