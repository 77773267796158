import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { BeWizardTitle } from 'client/components/Be/BeWizard';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { push } from 'connected-react-router';
import QRTDCodeCreatorAssetTable from 'client/components/QRTD/QRTDCodeCreatorAssetTable';
import QRTDCodeCreatorLocationTable from 'client/components/QRTD/QRTDCodeCreatorLocationTable';
import * as qrCodeCreatorActions from 'client/redux/qrtd/qrCodeCreatorActions';
import * as printPageActions from 'client/redux/qrtd/printPageActions';
import classNames from 'classnames';
import _ from 'lodash';

class ConnectedQRTDCodeCreator extends React.Component {

  static propTypes = {
    endpointsByType: propTypes.object.isRequired,
    isSetupMode: propTypes.bool,
    instance: propTypes.object.isRequired,
    disabled: propTypes.bool,
  }

  constructor(props) {
    super(props);
    this.getAssetDataDebounced = _.debounce(this.getAssetData, 500);
    this.getLocationDataDebounced = _.debounce(this.getLocationData, 500);
  }

  getTopdeskEndpointId() {
    return _.get(this.props, 'endpointsByType.topdesk.id');
  }

  componentDidMount () {
    this.getDataForActiveTab();
  }

  componentDidUpdate(prevProps) {
    const { tab, assetFilter, locationFilter } = this.props;
    if(!_.isEqual(assetFilter, prevProps.assetFilter)) {
      this.getAssetDataDebounced();
    }
    if(!_.isEqual(locationFilter, prevProps.locationFilter)) {
      this.getLocationDataDebounced();
    }
    if (tab && prevProps.tab !== tab) {
      this.getDataForActiveTab();
    }
  }

  getDataForActiveTab () {
    const { tab } = this.props;
    if (tab === 'locations') this.getLocationData();
    if (tab === 'assets') this.getAssetData();
  }

  getLocationData = (overrideWith = {}) => {
    const id = this.getTopdeskEndpointId();
    const { locationFilter } = this.props;
    return this.props.instanceQrtdQrCodeCreatorReadLocations(id, {
      ...locationFilter,
      ...overrideWith,
    });
  }

  getAssetData = (overrideWith = {}) => {
    const id = this.getTopdeskEndpointId();
    const { assetFilter } = this.props;
    return this.props.instanceQrtdQrCodeCreatorReadAssets(id, {
      ...assetFilter,
      ...overrideWith,
      archived: false,
    });
  }

  handleSetAssetPage = lastValueInSet => {
    this.props.instanceQrtdQrCodeCreatorAssetFilter({
      ...this.props.assetFilter,
      nameGt: lastValueInSet || undefined,
    });
  }

  handleAssetFilter = (assetFilter = {}) => {
    this.props.instanceQrtdQrCodeCreatorAssetFilter({
      ...this.props.assetFilter,
      ...assetFilter,
    });
  }

  handleLocationFilter = (locationFilter = {}) => {
    this.props.instanceQrtdQrCodeCreatorLocationFilter({
      ...this.props.locationFilter,
      ...locationFilter,
    });
  }

  handleSelectTab = newTab => {
    this.props.instanceQrtdQrCodeCreatorSetTab(newTab);
  }

  handleSetSelectedLocationsById = ids => {
    this.props.instanceQrtdQrCodeCreatorLocationSetSelectedIds(ids);
  }

  handleSetSelectedAssetsById = ids => {
    this.props.instanceQrtdQrCodeCreatorAssetSetSelectedIds(ids);
  }

  handleOpenQrPrintPage = ev => {
    const { instance, selectedAssetsById, selectedLocationsById } = this.props;
    const selectedAssetIds = this.getSelectedList(selectedAssetsById);
    const selectedLocationIds = this.getSelectedList(selectedLocationsById);
    const url = `/instance/qrtd/${instance.id}/printCodes`;
    this.props.instanceQrtdPrintPageSetChosenIds(selectedAssetIds, selectedLocationIds);
    this.props.push(url);
  }

  getSelectedList (selectedById) {
    return _.reduce(selectedById, (list, value, key) => {
      if (value) list.push(key);
      return list;
    }, []);
  }

  render() {
    const {
      instance,
      selectedAssetsById,
      selectedLocationsById,
      isReadingAssets,
      isReadingLocations,
      assetFilter,
      locationFilter,
      apiError,
      locations,
      assets,
      templates,
      tab,
    } = this.props;
    const selectedAssetIds = this.getSelectedList(selectedAssetsById);
    const selectedLocationIds = this.getSelectedList(selectedLocationsById);
    return (
      <div>
        <BeWizardTitle subTitle="You can select assets and locations from TOPdesk for QR-code creation and printing here.">
          Create QR codes
        </BeWizardTitle>
        <BeRemoteErrorAlert error={apiError} />
        <div className="panel panel-default panel-border">
          <div className="panel-body xs-p-10">
            <table>
              <tbody>
                <tr>
                  <td className="text-right">
                    Selected assets: <strong>{selectedAssetIds ? selectedAssetIds.length : 0}</strong><br /> Selected locations: <strong>{selectedLocationIds ? selectedLocationIds.length : 0}</strong>
                  </td>
                  <td className="xs-pl-10">
                    <button type="button" className="btn btn-lg btn-primary" onClick={this.handleOpenQrPrintPage}>
                      Open page to print QR-codes »
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="panel panel-default panel-border">
          <div className="tab-container">
            <div className="nav nav-tabs">
              <NavTab onSelectTab={this.handleSelectTab} tab="assets" currentTab={tab}>
                Assets
              </NavTab>
              <NavTab onSelectTab={this.handleSelectTab} tab="locations" currentTab={tab}>
                Locations
              </NavTab>
            </div>
            <div className="tab-content xs-p-0 xs-m-0">
              <div className={tab !== 'assets' ? 'hide' : ''} key="assets">
                <QRTDCodeCreatorAssetTable
                  endpointTopdeskId={this.getTopdeskEndpointId()}
                  instanceId={instance.id}
                  assets={assets}
                  templates={templates}
                  filter={assetFilter}
                  loading={isReadingAssets}
                  onFilter={this.handleAssetFilter}
                  onSetPage={this.handleSetAssetPage}
                  onSetSelectedById={this.handleSetSelectedAssetsById}
                  selectedById={selectedAssetsById}
                />
              </div>
              <div className={tab !== 'locations' ? 'hide' : ''} key="locations">
                <QRTDCodeCreatorLocationTable
                  endpointTopdeskId={this.getTopdeskEndpointId()}
                  instanceId={instance.id}
                  locations={locations}
                  filter={locationFilter}
                  loading={isReadingLocations}
                  onFilter={this.handleLocationFilter}
                  onSetSelectedById={this.handleSetSelectedLocationsById}
                  selectedById={selectedLocationsById}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function NavTab ({tab, currentTab, onSelectTab, children}) {
  const className = classNames(tab === currentTab ? 'active' : '');
  return (
    <li role="presentation" className={className}>
      <a href="#/" onClick={() => onSelectTab(tab)}>
        {children}
      </a>
    </li>
  );
}

const actions = {
  ...qrCodeCreatorActions,
  instanceQrtdPrintPageSetChosenIds: printPageActions.instanceQrtdPrintPageSetChosenIds,
  push,
};

const mapStateToProps = state => state.qrtd.qrCodeCreator;

export default connect(mapStateToProps, actions)(ConnectedQRTDCodeCreator);
