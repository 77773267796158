import React, { Component } from 'react';
import { connect } from 'react-redux';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Time from 'client/components/Helpers/Time';
import { StripeAmount } from 'client/components/Helpers/Strings';
import * as accountInvoiceIndexActions from 'client/redux/account/invoiceIndexActions';
import { UserInvoiceStatusLabel } from 'client/components/Helpers/StatusLabel';
import _ from 'lodash';

class AccountInvoiceIndex extends Component {

  static propTypes = {
    invoices: propTypes.array
  }

  static defaultProps = {
    invoices: []
  }

  componentDidMount() {
    this.props.accountInvoiceIndex();
  }

  getInvoiceDescription(invoice) {
    const lineData = _.get(invoice, 'stripeObject.lines.data', []);
    return lineData.map(({description, id}) => {
      return (
        <div key={id}>{description}</div>
      );
    });
  }

  getInvoiceData(invoice) {
    const date = _.get(invoice, 'stripeObject.created', 0) || _.get(invoice, 'stripeObject.date', 0);
    return {
      amount: _.get(invoice, 'stripeObject.total', 0),
      currency: _.get(invoice, 'stripeObject.currency'),
      date: new Date(date * 1000),
    };
  }

  renderRows() {
    const { invoices } = this.props;
    if(!invoices || !invoices.length) {
      return (
      <tr>
        <td className="empty" colSpan={6}>No invoices to show</td>
      </tr>
      );
    }
    return invoices.map(invoice => {
      const { id } = invoice;
      const { date, amount, currency } = this.getInvoiceData(invoice);
      return (
        <tr key={id}>
          <td><Time time={date} format="YYYY-MM-DD" /></td>
          <td>{this.getInvoiceDescription(invoice)}</td>
          <td className="number">
            <StripeAmount currency={currency} amount={amount} />
          </td>
          <td><UserInvoiceStatusLabel invoice={invoice} /></td>
          <td>
            <Link className="btn btn-default pull-right" to={`/account/invoice/${id}`}>
              <span className="icon mdi mdi-open-in-browser"></span>{' '}
              View invoice
            </Link>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { isReading, error } = this.props;
    return (
      <div>
        <BeRemoteErrorAlert className="xs-p-20" error={error} />
        <BeLoading loading={isReading}>
          <table className="table table-striped table-borderless">
            <thead>
              <tr>
                <th>Invoice date</th>
                <th>Description</th>
                <th className="number">Amount</th>
                <th>Status</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderRows()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

}

const actions = {
  ...accountInvoiceIndexActions
};

export default connect(state => state.account.invoiceIndex, actions)(AccountInvoiceIndex);
