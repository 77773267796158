import { apiAction } from 'client/redux/apiHelpers';

export const endpointFastEditCredentials = (type, isEditing) => {
  return {type, isEditing};
};

export const endpointFastUpdateCredentials = (types, id, credentials) => {
  return apiAction({
    endpoint: `/api/endpoint/fast/${id}/credentials`,
    method: 'PATCH',
    types,
    json: credentials
  });
};

export const endpointFastTestCredentials = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/fast/${id}/credentials/test`,
    method: 'POST',
    types,
    json: {}
  });
};
