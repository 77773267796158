import React from 'react';
import propTypes from 'prop-types';
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import { BeCheckbox } from 'client/components/Be';
import SwitchButton from 'client/components/Form/SwitchButton';
import { qrCodeFeatures } from 'client/redux/qrtd/constants';
import { getNumberOfRows } from 'client/helpers/misc';
import classNames from 'classnames';
import { codeSettingsShape } from 'client/components/QRTD/shapes'

export default class QRTDPrintCodeSettingsForm extends React.Component {

  static propTypes = {
    codeType: propTypes.string,
    idBase: propTypes.string.isRequired,
    onChangeValue: propTypes.func.isRequired,
    settings: codeSettingsShape.isRequired,
    infoTexts: propTypes.array.isRequired,
    incidentFieldMaps: propTypes.array.isRequired,
    showTexts: propTypes.bool.isRequired,
  }

  static defaultProps = {
    showTexts: false,
  }

  getId (...fragments) {
    const { idBase } = this.props;
    return `qrtdPrintPage_${idBase}_${fragments.join('-')}`;
  }

  getFeatures () {
    const { codeType } = this.props;
    switch (codeType) {
      default: return [...qrCodeFeatures];
      case 'empty': return qrCodeFeatures.filter(({value}) => ['info', 'reportIncident'].includes(value));
    }
  }

  handleChange = ev => {
    const { name, value } = ev.target;
    this.props.onChangeValue(name, value);
  }

  handleChangeNumber = ev => {
    const { name } = ev.target;
    const value = parseInt(ev.target.value, 10) || 1;
    this.props.onChangeValue(name, value);
  }

  handleChangeCheckbox = ev => {
    const { name, checked } = ev.target;
    this.props.onChangeValue(name, Boolean(checked));
  }

  handleChangeFeature = ev => {
    const { features } = this.props.settings;
    const { value, checked } = ev.target;
    const newFeatures = checked ? [...features, value] : features.filter(v => v !== value);
    this.props.onChangeValue('features', newFeatures);
  }

  render () {
    const { incidentFieldMaps, infoTexts, settings, className, showTexts } = this.props;
    const style = {display: 'flex', flexWrap: 'wrap'};
    return (
      <form className={className}>
        <FormGroup className="form-group-sm xs-mb-5">
          <label className="control-label">
            Enabled Features
          </label>
          <div style={style}>
            {this.getFeatures().map(({value, label}, index) => (
              <div key={value} className="xs-mr-10">
                <input
                  id={this.getId('feature', value)}
                  type="checkbox"
                  value={value}
                  checked={settings.features.includes(value)}
                  onChange={this.handleChangeFeature}
                />
                <label className="control-label xs-ml-5" htmlFor={this.getId('feature', value)}>
                  {label}
                </label>
              </div>
            ))}
          </div>
        </FormGroup>
        {showTexts && (
          <>
            <FormGroup className="form-group-sm">
              <label htmlFor={this.getId('topText')}>Text above QR-code</label>
              <InputGroup className="input-group-sm">
                <InputGroup.Addon>
                  <BeCheckbox
                    id={this.getId('topTextShow')}
                    name="topTextShow"
                    checked={Boolean(settings.topTextShow)}
                    onChange={this.handleChangeCheckbox}
                    title="Uncheck to disable top text"
                  />
                </InputGroup.Addon>
                <FormControl
                  id={this.getId('topText')}
                  name="topText"
                  onChange={this.handleChange}
                  value={settings.topText}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="form-group-sm">
              <label htmlFor={this.getId('bottomText')}>Text below QR-code</label>
              <InputGroup className="input-group-sm">
                <InputGroup.Addon>
                  <BeCheckbox
                    id={this.getId('bottomTextShow')}
                    name="bottomTextShow"
                    checked={Boolean(settings.bottomTextShow)}
                    onChange={this.handleChangeCheckbox}
                    title="Uncheck to disable bottom text"
                  />
                </InputGroup.Addon>
                <FormControl
                  id={this.getId('bottomText')}
                  name="bottomText"
                  componentClass="textarea"
                  style={{minHeight: '37px'}}
                  rows={getNumberOfRows(settings.bottomText)}
                  onChange={this.handleChange}
                  value={settings.bottomText}
                />
              </InputGroup>
            </FormGroup>
          </>
        )}
        <div className="row">
          <ColorPickerFormGroup
            className="col-md-6"
            id={this.getId('bgColor')}
            label="QR-code background color"
            name="qrCodeBgColor"
            onChange={this.handleChange}
            value={settings.qrCodeBgColor}
          />
          <ColorPickerFormGroup
            className="col-md-6"
            id={this.getId('fgColor')}
            label="QR-code foreground color"
            name="qrCodeFgColor"
            onChange={this.handleChange}
            value={settings.qrCodeFgColor}
          />
        </div>
        <FormGroup className="form-group-sm">
          <label htmlFor={this.getId('bgExtend')} className="control-label xs-mb-0">
            Extend QR-code background color to cover the text
          </label>{' '}
          <SwitchButton
            id={this.getId('bgExtend')}
            value={Boolean(settings.qrCodeBgExtend)}
            size="xs"
            name="qrCodeBgExtend"
            onChange={this.handleChangeCheckbox}
          />
        </FormGroup>

        <div className="row">
          <FormGroup className="form-group-sm col-md-6 xs-mb-5">
            <label htmlFor={this.getId('fieldMapOrdinal')}>
              QR-code report incident field map
            </label>
            <select
              id={this.getId('fieldMapOrdinal')}
              value={settings.fieldMapOrdinal}
              name="fieldMapOrdinal"
              className="form-control"
              onChange={this.handleChange}
            >
              <option value="">Default field map</option>
              {incidentFieldMaps.map(fieldMap => (
                <option key={fieldMap.ordinal} value={fieldMap.ordinal}>
                  {fieldMap.title}
                </option>
              ))}
            </select>
          </FormGroup>
          <FormGroup className="form-group-sm col-md-6 xs-mb-5">
            <label htmlFor={this.getId('infoTextOrdinal')}>
              QR-code info tab text
            </label>
            <select
              id={this.getId('infoTextOrdinal')}
              value={settings.infoTextOrdinal}
              name="infoTextOrdinal"
              className="form-control"
              onChange={this.handleChange}
            >
              <option value="">Default info text</option>
              {infoTexts.map(infoText => (
                <option key={infoText.ordinal} value={infoText.ordinal}>
                  {infoText.title}
                </option>
              ))}
            </select>
          </FormGroup>
        </div>
      </form>
    );
  }

}

function ColorPickerFormGroup (props) {
  const { id, name, label, value, onChange, className } = props;
  return (
    <FormGroup className={classNames(className, 'form-group-sm')}>
      <label htmlFor={id}>{label}</label>
      <FormControl
        id={id}
        name={name}
        componentClass="input"
        type="color"
        onChange={onChange}
        value={value}
      />
    </FormGroup>
  );
}
