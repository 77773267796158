import * as actionCreators from 'client/redux/instance/actionCreators';
import * as constants from 'client/redux/instance/constants';

export const instancePanelRead = id => dispatch => {
  return dispatch(actionCreators.instanceRead(constants.INSTANCE_PANEL_READ, id));
};

export const instancePanelCall = (id, instanceType, method) => dispatch => {
  return dispatch(actionCreators.instanceCall(constants.INSTANCE_PANEL_CALL, id, instanceType, method));
};

export const instancePanelUpdateStatus = (id, newStatus) => dispatch => {
  return dispatch(actionCreators.instanceUpdateStatus(constants.INSTANCE_PANEL_UPDATE_STATUS, id, newStatus));
};

export const instancePanelUpdateAllStatusToActive = (id) => dispatch => {
  return dispatch(actionCreators.instanceUpdateAllStatusToActive(constants.INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE, id));
};

export const instancePanelUpdateUserId = (id, newUserId) => dispatch => {
  return dispatch(actionCreators.instanceUpdateUserId(constants.INSTANCE_PANEL_UPDATE_USER_ID, id, newUserId));
};
