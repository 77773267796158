import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { accountFields, accountUpdate, accountFormTogglePart, accountFormHideAllParts } from 'client/redux/account/actions';
import AccountSettingsForm from 'client/components/Account/components/AccountSettingsForm';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeLoading from 'client/components/Be/BeLoading';

class AccountSettings extends Component {

  handleFormSubmit = fields => {
    return this.props.accountUpdate(fields).then(getResponseToFormErrorHandler({
      aliasFn: key => {
        switch(key) {
          default: return key;
          case 'email': return 'email.one';
          case 'password': return 'password.now';
        }
      }
    }));
  }

  handleToggleFormPart = (part, show) => {
    return this.props.accountFormTogglePart(part, show);
  }

  componentDidMount() {
    this.props.accountFields();
  }

  getInitialValues() {
    const { fields: { fullName } } = this.props;
    return {fullName};
  }

  render() {
    const { fields: { email } } = this.props;
    return (
      <MainContent>
        <Content>
          <Helmet>
            <title>Settings</title>
          </Helmet>
          <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
            <div className="panel-heading panel-heading-divider">
              Profile<span className="panel-subtitle">Edit your profile settings below</span>
            </div>
            <div className="panel-body">
              <BeLoading positionStatic={true} loading={this.props.isLoading} minHeight="75">
                {!this.props.isLoading && <AccountSettingsForm
                  initialValues={this.getInitialValues()}
                  initialEmail={email}
                  onSubmit={this.handleFormSubmit}
                  showPart={this.props.showPart}
                  onTogglePart={this.handleToggleFormPart}
                />}
              </BeLoading>
            </div>
          </div>
        </Content>
      </MainContent>
    );
  }
}

const mapDispatchToProps = {
  accountFields,
  accountUpdate,
  accountFormTogglePart,
  accountFormHideAllParts
};

export default connect(state => state.account.settings, mapDispatchToProps)(AccountSettings);
