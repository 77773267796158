import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/optd/testActions';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import OPTDTesterAddAlarm from 'client/components/OPTD/components/OPTDTesterAddAlarm';
import OPTDTesterAlarm from 'client/components/OPTD/components/OPTDTesterAlarm';

class OPTDTester extends Component {

  instanceId() {
    return _.get(this.props, 'instance.id');
  }

  handleAddAlarm = alarmId => {
    this.props.instanceOptdTestAlarmAdd(this.instanceId(), alarmId).then(() => {
      return this.props.instanceOptdSearchFieldChange('');
    });
  }

  handleSearchFieldChange = alarmId => {
    return this.props.instanceOptdSearchFieldChange(alarmId);
  }

  handleRemoveAlarm = alarmId => {
    return this.props.instanceOptdTestAlarmRemove(alarmId);
  }

  handleTestAlarm = alarmId => {
    return this.props.instanceOptdTestAlarm(this.instanceId(), alarmId);
  }

  handleSyncAlarm = alarmId => {
    return this.props.instanceOptdSyncAlarm(this.instanceId(), alarmId);
  }

  renderAlarms() {
    const alarms = this.props.alarms || {};
    return _.map(alarms, (alarm, id) => {
      return (
        <OPTDTesterAlarm
          key={id}
          id={id}
          alarm={alarm}
          onRemove={this.handleRemoveAlarm}
          onTest={this.handleTestAlarm}
          onSync={this.handleSyncAlarm}
        />
      );
    });
  }

  render() {
    const { searchFieldAlarmId, isAdding, endpointsByType: { opmanager } } = this.props;
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_optd_test_help" subTitle="Here you can test the options on real alarms and incidents">
          Test Synchronization
        </InstanceHelpHeader>

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> Clicking "Sync" on an added OpManager alarm below will create or update real data in TOPdesk.
          </div>
        </div>

        <div className="list-group">
          {this.renderAlarms()}
        </div>

        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <OPTDTesterAddAlarm
              endpointId={opmanager.id}
              alarmId={searchFieldAlarmId}
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddAlarm}
              isAdding={isAdding}
            />
          </div>
        </div>

      </div>
    );
  }
}

const actions = {
  ...testActions,
};

export default connect(state => state.optd.test, actions)(OPTDTester);
