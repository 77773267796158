import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { DropdownButton } from 'react-bootstrap';
import _ from 'lodash';
import {
  TableCheckbox,
  TableThSort,
  TableCheckboxManager,
  TablePagination,
  TableShowCount,
  TableUserCell,
  TablePanelColumnSelectorDropdown,
} from 'client/components/Helpers/Table';
import {
  ModalButton,
  ModalFormattedObject,
} from 'client/components/Helpers/Modal';
import Time from 'client/components/Helpers/Time';
import BeLoading from 'client/components/Be/BeLoading';
import { MailTo, UserRole, Bool, ShortIdStr } from 'client/components/Helpers/Strings';
import ButtonSoftDeleteConfirmModal from 'client/components/Button/ButtonSoftDeleteConfirmModal';
import ButtonRestore from 'client/components/Button/ButtonRestore';
import UserEditModal from 'client/components/User/components/UserEditModal';
import UserNewModal from 'client/components/User/components/UserNewModal';
import UserTableFilterForm from 'client/components/User/components/UserTableFilterForm';

class UserTable extends React.Component {

  static defaultProps = {
    users: []
  }

  static propTypes = {
    columns: propTypes.array,
    filter: propTypes.object,
    hasPreviousSelf: propTypes.bool,
    loading: propTypes.bool,
    onCreateUser: propTypes.func,
    onDeleteUser: propTypes.func,
    onOrderBy: propTypes.func,
    onRefresh: propTypes.func,
    onSetColumns: propTypes.func,
    onSetPage: propTypes.func,
    onSwitchUser: propTypes.func,
    onUpdateUser: propTypes.func,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    selfId: propTypes.string,
    users: propTypes.array,
  }

  handleClickRestore = id => {
    return this.props.onUpdateUser(id, {deletedAt: null}, {paranoid: 0});
  }

  handleClickDelete = (id, force) => {
    return this.props.onDeleteUser(id, force);
  }

  columnIsVisible = id => {
    const { columns } = this.props;
    return _.get(_.find(columns, {id}), 'visible', false);
  }

  shouldRenderSwitchButton(id) {
    const { selfId, hasPreviousSelf } = this.props;
    return id !== selfId && !hasPreviousSelf;
  }

  renderUsers() {
    const visible = this.columnIsVisible;
    const {
      users,
      handleSelectRow,
      isRowSelected,
      onRefresh,
      selfId,
      onSwitchUser,
      onFilter,
    } = this.props;

    if(!users || !users.length) return (
      <tr>
        <td className="empty" colSpan={100}>No users to show</td>
      </tr>
    );

    return users.map(user => {
      const {
        id,
        email,
        role,
        stripeId,
        isConfirmed,
        parentUserId,
        createdAt,
        updatedAt,
        deletedAt,
        displayName,
        lastLoginAt,
      } = user;

      return (
        <tr key={id}>
          {visible('select') && (<td>
            <TableCheckbox
              base="userTableSelect"
              id={id}
              onSelect={handleSelectRow}
              isSelected={isRowSelected(id)}
            />
          </td>)}
          {visible('id') && (<TableUserCell user={user} />)}
          {visible('createdAt') && (<td><Time time={createdAt} format="YYYY-MM-DD" /></td>)}
          {visible('updatedAt') && (<td><Time time={updatedAt} format="YYYY-MM-DD" /></td>)}
          {visible('lastLoginAt') && (<td>
            {lastLoginAt && <Time time={lastLoginAt} format="YYYY-MM-DD" />}
            {!lastLoginAt && <span>Never</span>}
          </td>)}
          {visible('email') && (<td><MailTo email={email} /></td>)}
          {visible('role') && (<td><UserRole role={role} /></td>)}
          {visible('stripeId') && (<td>{stripeId}</td>)}
          {visible('parentUserId') && (<td>
            {parentUserId && (
              <button type="button" className="btn btn-default btn-sm" onClick={() => onFilter({term: parentUserId})}>
                <ShortIdStr id={parentUserId} />
              </button>
            )}
          </td>)}
          {visible('isConfirmed') && (<td><Bool value={isConfirmed} /></td>)}
          <td className="text-right">
            <DropdownButton title={(<i className="icon mdi mdi-settings"></i>)} pullRight id={`table-row-actions-${user.id}`}>
              <li>
                <Link to={`/instance?userId=${user.id}`}>
                  <i className="icon mdi mdi-swap-alt"></i>
                  Show user instances
                </Link>
              </li>
              <li>
                <Link to={`/user/subscription?userId=${user.id}`}>
                  <i className="icon mdi mdi-group"></i>
                  Show user subscriptions
                </Link>
              </li>
              <li role="separator" className="divider" />
              {this.shouldRenderSwitchButton(id) && (
                <li>
                  {/* eslint-disable-next-line */}
                  <a href="#" onClick={ev => ev.preventDefault() || onSwitchUser(id)}>
                    <i className="icon mdi mdi-sign-in"></i>
                    Switch to this user
                  </a>
                </li>
              )}
              <li>
                <ModalButton anchor className="btn-link" ModalComponent={ModalFormattedObject} modalProps={{object: user, title: displayName || ''}}>
                  <span className="icon mdi mdi-search"></span>
                  Inspect
                </ModalButton>
              </li>
              <li>
                <ButtonRestore
                  anchor
                  text
                  id={id}
                  isSoftDeleted={Boolean(deletedAt)}
                  onRestore={this.handleClickRestore}
                />
              </li>
              <li>
                <ModalButton anchor className="btn-link" title="Edit user" ModalComponent={UserEditModal} modalProps={{user, onUserWasUpdated: onRefresh}}>
                  <span className="icon mdi mdi-settings"></span>
                  Settings
                </ModalButton>
              </li>
              {selfId !== id && (
                <li>
                  <ButtonSoftDeleteConfirmModal
                    className="btn-link"
                    anchor
                    text
                    id={id}
                    isSoftDeleted={Boolean(deletedAt)}
                    onDelete={this.handleClickDelete}
                  />
                </li>
              )}
            </DropdownButton>
          </td>
        </tr>
      );
    })
  }

  renderTable() {
    const { handleSelectAll, isAllSelected, orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    const visible = this.columnIsVisible;
    return (
      <div className="be-datatable-body no-overflow">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                {visible('select') && (<th>
                  <TableCheckbox
                    id="userTableSelectAll"
                    onSelectAll={handleSelectAll}
                    isSelected={isAllSelected()}
                  />
                </th>)}
                {visible('id') && (<TableThSort column="id" {...sortProps}>ID</TableThSort>)}
                {visible('createdAt') && (<TableThSort column="createdAt" {...sortProps}>Created</TableThSort>)}
                {visible('updatedAt') && (<TableThSort column="updatedAt" {...sortProps}>Updated</TableThSort>)}
                {visible('deletedAt') && (<TableThSort column="deletedAt" {...sortProps}>Deleted</TableThSort>)}
                {visible('lastLoginAt') && (<TableThSort column="lastLoginAt" {...sortProps}>Last Login</TableThSort>)}
                {visible('email') && (<TableThSort column="email" {...sortProps}>Email</TableThSort>)}
                {visible('role') && (<th>Role</th>)}
                {visible('stripeId') && (<th>Stripe ID</th>)}
                {visible('parentUserId') && (<th>Parent User ID</th>)}
                {visible('isConfirmed') && (<th>Confirmed</th>)}
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderUsers()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['user', 'users']} />
          </div>
        </div>
        <div className="col-md-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filter, onFilter, onRefresh, onSetColumns, columns } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading panel-heading-divider">
          Table of Users
          <div className="tools xs-ml-5">
            <ModalButton className="btn-link" title="Create new user" ModalComponent={UserNewModal} modalProps={{onUserWasCreated: onRefresh}}>
              <i className="icon mdi mdi-plus-circle-o"></i>
            </ModalButton>
          </div>{' '}
          <TablePanelColumnSelectorDropdown
            id="table-columns"
            onSetColumns={onSetColumns}
            columns={columns}
          />
        </div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <UserTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

export default TableCheckboxManager(UserTable, {
  allIds: function(props) {
    const users = props.users || [];
    return users.map(user => user.id);
  }
});
