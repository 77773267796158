import * as actionCreators from 'client/redux/grtd/actionCreators';
import {
  INSTANCE_GRTD_TEST_CLEAR,
  INSTANCE_GRTD_TEST_USER_ADD,
  INSTANCE_GRTD_TEST_USER_REMOVE,
  INSTANCE_GRTD_RELOAD_USER,
  INSTANCE_GRTD_SYNC_USER
} from 'client/redux/grtd/constants';

export const instanceGrtdTestUserAdd = (id, userId) => dispatch => {
  return dispatch(actionCreators.instanceGrtdTestUserAdd(INSTANCE_GRTD_TEST_USER_ADD, id, userId));
};

export const instanceGrtdReloadUser = (id, userId) => dispatch => {
  return dispatch(actionCreators.instanceGrtdReloadUser(INSTANCE_GRTD_RELOAD_USER, id, userId));
};

export const instanceGrtdTestUserRemove = userId => dispatch => {
  return dispatch(actionCreators.instanceGrtdTestUserRemove(INSTANCE_GRTD_TEST_USER_REMOVE, userId));
};

export const instanceGrtdSyncUser = (id, userId) => dispatch => {
  return dispatch(actionCreators.instanceGrtdSyncUser(INSTANCE_GRTD_SYNC_USER, id, userId));
};

export const instanceGrtdTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceGrtdTestClear(INSTANCE_GRTD_TEST_CLEAR));
};
