export const ENDPOINT_MANAGEDWORKPLACE_READ_REQUEST = 'ENDPOINT_MANAGEDWORKPLACE_READ_REQUEST';
export const ENDPOINT_MANAGEDWORKPLACE_READ_SUCCESS = 'ENDPOINT_MANAGEDWORKPLACE_READ_SUCCESS';
export const ENDPOINT_MANAGEDWORKPLACE_READ_FAILURE = 'ENDPOINT_MANAGEDWORKPLACE_READ_FAILURE';
export const ENDPOINT_MANAGEDWORKPLACE_READ = [ENDPOINT_MANAGEDWORKPLACE_READ_REQUEST, ENDPOINT_MANAGEDWORKPLACE_READ_SUCCESS, ENDPOINT_MANAGEDWORKPLACE_READ_FAILURE];

export const ENDPOINT_MANAGEDWORKPLACE_EDIT_CREDENTIALS = 'ENDPOINT_MANAGEDWORKPLACE_EDIT_CREDENTIALS';

export const ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_REQUEST = 'ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_REQUEST';
export const ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_SUCCESS = 'ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_SUCCESS';
export const ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_FAILURE = 'ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_FAILURE';
export const ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS = [ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_REQUEST, ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_SUCCESS, ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS_FAILURE];

export const ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_REQUEST = 'ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_REQUEST';
export const ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_SUCCESS = 'ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_SUCCESS';
export const ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_FAILURE = 'ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_FAILURE';
export const ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS = [ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_REQUEST, ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_SUCCESS, ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS_FAILURE];
