import React, { Component } from 'react';
import GRTDTesterAddUser from 'client/components/GRTD/components/GRTDTesterAddUser';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'client/redux/grtd/testActions';
import GRTDTesterUser from 'client/components/GRTD/components/GRTDTesterUser';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import Alert from 'client/components/Be/Alert';
import _ from 'lodash';

class GRTDTester extends Component {

  static propTypes = {
    instance: propTypes.object.isRequired,
    endpointsByType: propTypes.object.isRequired
  }

  state = {
    addUserId: null
  }

  handleAddUser = userId => {
    this.props.instanceGrtdTestUserAdd(this.props.instance.id, userId);
    this.setState({addUserId: null});
  }

  handleChangeAddUser = userId => {
    this.setState({addUserId: userId});
  }

  handleRemoveUser = userId => {
    return this.props.instanceGrtdTestUserRemove(userId);
  }

  handleReloadUser = userId => {
    return this.props.instanceGrtdReloadUser(this.props.instance.id, userId);
  }

  handleSyncUser = userId => {
    return this.props.instanceGrtdSyncUser(this.props.instance.id, userId);
  }

  renderUsers() {
    const users = this.props.users || {};
    return _.map(users, (user, id) => {
      return (
        <GRTDTesterUser
          key={id}
          id={id}
          user={user}
          onRemove={this.handleRemoveUser}
          onSync={this.handleSyncUser}
          onReload={this.handleReloadUser}
        />
      );
    });
  }

  render() {
    const { graph } = this.props.endpointsByType;
		return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_grtd_test_help" subTitle="Here you can simulate or test the options on real user accounts">
          Test Synchronization
        </InstanceHelpHeader>

        <Alert alertType="primary" icon={true}>
          <strong>Note:</strong> Clicking "Sync" on an added Graph user below will create or update real data in TOPdesk.
        </Alert>

        <div className="list-group">
          {this.renderUsers()}
        </div>

        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <GRTDTesterAddUser
              endpointId={graph.id}
              userId={this.state.addUserId}
              onChange={this.handleChangeAddUser}
              onAdd={this.handleAddUser}
              isAdding={this.props.isAdding}
            />
          </div>
        </div>

      </div>
		);
  }
}

export default connect(state => state.grtd.test, actions)(GRTDTester);
