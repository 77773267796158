import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { isFilled, isString/* , isUUID */ } from 'client/redux/validators';
import { normalizeBoolean } from 'client/redux/formHelpers';
import { FormReduxSelect2, FormReduxTextareaJSON, FormReduxInput } from 'client/components/Form/FormRedux';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import * as optionsActions from 'client/redux/fatd/optionsActions';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import EndpointTopdeskFieldArchivingReason from 'client/components/Endpoint/components/EndpointTopdeskFieldArchivingReason';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import Sticky from 'react-stickynode';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';
import Icon from 'client/components/Be/Icon';

const validators = {
  isFilledUUID: [isString(), isFilled()/* , isUUID() */],
  isUUID: [isString()/* , isUUID() */],
}

class ConnectedFATDOptionsForm extends Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false,
  }

  static propTypes = {
    disabled: propTypes.bool,
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    this.props.instanceFatdOptionsReadMeta(instanceId);
  }

  getInitialValues() {
    const { initialValues } = this.props;
    const translationTable = initialValues.fastSpatisystemTranslationTable || {};
    return {
      ...initialValues,
      fastSpatisystemTranslationTable: JSON.stringify(translationTable, null, 2),
    };
  }

  handleReset = ev => {
    this.props.reset();
  }

  renderTopdeskSettings() {
    const { endpointsByType, initialValues, disabled } = this.props;
    const { topdesk } = endpointsByType;
    const {
      topdeskArchivingReasonId,
      branchStrategy = '',
      personStrategy = '',
      topdeskPrefix,
      assetStrategy = '',
      assetsCanBeUpdated,
      assignAssetsToBranch,
      personExtensiveSearch,
      personExtensiveSearchVariant = '',
      branchCanBeUpdated,
      linkSpatisystemAssetToPropertyAsset = '',
      topdeskPropertyAssetPropertyIdFieldName = '',
      topdeskPropertyAssetTemplateId = '',
    } = initialValues;
    return (
      <div className="well">
        <InstanceHelpHeader helpFragmentId="instance_fatd_settings_topdesk_archiving_reason_help" subTitle="Set the reason given when TOPdesk Persons are archived">
          TOPdesk Archiving Reason
        </InstanceHelpHeader>
        <EndpointTopdeskFieldArchivingReason
          base="fatdOptionsTopdeskArchivingReason"
          endpointId={topdesk.id}
          topdeskArchivingReasonId={topdeskArchivingReasonId}
          disabled={disabled}
          name="topdeskArchivingReasonId"
          placeholderText="Use the first available archiving reason"
        />
        <hr />
        <InstanceHelpHeader helpFragmentId="instance_fatd_settings_topdesk_archiving_reason_help" subTitle="TOPdesk branch to use when no better match could be found">
          TOPdesk Default Branch
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsTopdeskBranchDefaultValue"
          component={FormReduxSelect2}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2ComponentProps={{forWhat: 'branch', endpointId: topdesk.id}}
          select2Options={{minimumInputLength: 0}}
          name="topdeskBranch.defaultValue"
          placeholder="Set a default branch"
          disabled={disabled}
          validate={validators.isFilledUUID}
        />
        <InstanceHelpHeader>
          TOPdesk Existing Persons
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsPersonExtensiveSearch"
          name="personExtensiveSearch"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'No extensive search'},
            {id: true, text: 'Extensive search'},
          ]}
          normalize={normalizeBoolean}
          defaultValue={personExtensiveSearch || ''}
          disabled={disabled}
        />
        <Field
          base="fatdOptionsPersonExtensiveSearchVariant"
          name="personExtensiveSearchVariant"
          component={FormReduxSelect2}
          helpText="No effect unless extensive search is active"
          data={[
            {id: '', text: 'Default extensive search'},
            {id: 'hembla', text: 'Hembla extensive search'},
          ]}
          defaultValue={personExtensiveSearchVariant || ''}
          disabled={disabled}
        />
        <InstanceHelpHeader>
          TOPdesk Person Strategy
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsPersonStrategy"
          name="personStrategy"
          component={FormReduxSelect2}
          data={[
            {id: '', text: 'Default'},
            {id: 'victoriapark', text: 'Type VP'},
            {id: 'rikshem', text: 'Type R'},
          ]}
          defaultValue={personStrategy || ''}
          disabled={disabled}
        />
        <InstanceHelpHeader>
          TOPdesk Branch Strategy
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsBranchStrategy"
          name="branchStrategy"
          component={FormReduxSelect2}
          data={[
            {id: 'erstadiakoni', text: 'Type E'},
            {id: 'victoriapark', text: 'Type VP'},
            {id: 'rikshem', text: 'Type R'},
          ]}
          defaultValue={branchStrategy || ''}
          disabled={disabled}
        />
        <InstanceHelpHeader>
          TOPdesk Branch updating
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsBranchCanBeUpdated"
          name="branchCanBeUpdated"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'Existing branches will not be updated'},
            {id: true, text: 'Branches can be updated'},
          ]}
          defaultValue={branchCanBeUpdated || ''}
          normalize={normalizeBoolean}
          disabled={disabled}
        />
        <InstanceHelpHeader>
          TOPdesk Asset Strategy
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsAssetStrategy"
          name="assetStrategy"
          component={FormReduxSelect2}
          data={[
            {id: '', text: 'Default'},
            {id: 'rikshem', text: 'Type R'},
          ]}
          defaultValue={assetStrategy || ''}
          disabled={disabled}
        />
        <InstanceHelpHeader>
          TOPdesk Assets
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsAssetsCanBeUpdated"
          name="assetsCanBeUpdated"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'Existing assets will not be updated'},
            {id: true, text: 'Assets can be updated'},
          ]}
          defaultValue={assetsCanBeUpdated || ''}
          normalize={normalizeBoolean}
          disabled={disabled}
        />
        <Field
          base="fatdOptionsAssignAssetsToBranch"
          name="assignAssetsToBranch"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'Assets will not be assigned to branches'},
            {id: true, text: 'Assets will be assigned to the same branch as the person'},
          ]}
          defaultValue={assignAssetsToBranch || ''}
          normalize={normalizeBoolean}
          disabled={disabled}
        />
        <InstanceHelpHeader subTitle="Prefix objects in TOPdesk for multiple FastAPI endpoints into the same TOPdesk">
          Object Prefix
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsPropertyParishFilter"
          component={FormReduxInput}
          label="TOPdesk Object Name Prefix"
          name="topdeskPrefix"
          value={topdeskPrefix || ''}
          disabled={disabled}
          helpText="Leave the field blank for no prefix"
        />
        <InstanceHelpHeader>
          TOPdesk Asset Parent Linking
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsLinkSpatisystemAssetToPropertyAsset"
          name="linkSpatisystemAssetToPropertyAsset"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'Assets will not be linked'},
            {id: true, text: 'Assets will be linked to a parent asset'},
          ]}
          normalize={normalizeBoolean}
          defaultValue={linkSpatisystemAssetToPropertyAsset || ''}
          disabled={disabled}
        />
        <Field
          label="TOPdesk Parent Asset Template"
          base="fatdOptionsTopdeskPropertyAssetTemplateId"
          component={FormReduxSelect2}
          select2Component={EndpointTopdeskRemoteSearcher}
          select2ComponentProps={{forWhat: 'assetTemplateId', endpointId: topdesk.id}}
          select2Options={{minimumInputLength: 0}}
          name="topdeskPropertyAssetTemplateId"
          disabled={disabled}
          defaultValue={topdeskPropertyAssetTemplateId}
          validate={validators.isUUID}
        />
        <Field
          base="fatdOptionsTopdeskPropertyAssetPropertyIdFieldName"
          component={FormReduxInput}
          label="TOPdesk Parent Asset Field ID"
          name="topdeskPropertyAssetPropertyIdFieldName"
          value={topdeskPropertyAssetPropertyIdFieldName || ''}
          disabled={disabled}
          helpText="Enter the field ID in the parent asset that contains the fi2property Fast ID"
        />
      </div>
    );
  }

  renderExternalSyncSettings() {
    const {
      instanceId,
      disabled,
      initialValues: { externalSyncAuthPassword = '' },
      errorMeta,
      meta,
      isReadingMeta,
    } = this.props;
    return (
      <div className="well xs-pb-10">
        <InstanceHelpHeader
          subTitle="Settings for a TOPdesk Action notifying a person has been updated"
        >
          External TOPdesk Person Callback
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorMeta} />
        <BeLoading loading={isReadingMeta} className="xs-mt-20">
          {meta && (
            <div>
              <div className="form-group">
                <label className="control-label" htmlFor="fatdOptionsExternalSyncUserName">
                  HTTP Basic Auth Username
                </label>
                <div className="input-group">
                  <input
                    id="fatdOptionsExternalSyncUserName"
                    className="form-control"
                    type="text"
                    readOnly
                    value={instanceId}
                  />
                  <QuickCopyAddon value={instanceId} />
                </div>
              </div>
              <Field
                base="fatdOptions"
                component={FormReduxInput}
                name="externalSyncAuthPassword"
                value={externalSyncAuthPassword || ''}
                disabled={disabled}
                label="HTTP Basic Auth Password"
                validate={validators.isNonEmptyString}
              />
              <div className="form-group">
                <label className="control-label" htmlFor="fatdOptionsExternalSyncUrl">
                  URL to POST to
                </label>
                <div className="input-group">
                  <input
                    id="fatdOptionsExternalSyncUrl"
                    className="form-control"
                    type="text"
                    readOnly
                    value={meta.externalSyncUrl}
                  />
                  <QuickCopyAddon value={meta.externalSyncUrl} />
                </div>
              </div>
            </div>
          )}
        </BeLoading>
      </div>
    );
  }

  renderFastSettings() {
    const { disabled, initialValues } = this.props;
    const {
      propertyParishFilter = '',
      emailFindPersonFilter = '',
      coreExternalApiUrl  = '',
      fastLeasecontractExtendedPrioritization = '',
      fastLeasecontractHandleFuture = '',
      personArchivingDelay = '',
    } = initialValues;
    return (
      <div className="well">
        <InstanceHelpHeader subTitle="Settings to filter objects coming from FastAPI">
          Filters
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsPropertyParishFilter"
          component={FormReduxInput}
          label="Property Parish Filter (fi2property.fi2prop_parish)"
          name="propertyParishFilter"
          value={propertyParishFilter || ''}
          disabled={disabled}
          helpText="Leave the field blank for no filter"
        />
        <Field
          base="fatdOptionsEmailFindPersonFilter"
          component={FormReduxInput}
          label="TOPdesk Matching Person Email Filter"
          name="emailFindPersonFilter"
          value={emailFindPersonFilter || ''}
          disabled={disabled}
          helpText="TOPdesk persons with a matching email will not be paired"
        />
        <hr />
        <InstanceHelpHeader subTitle="Settings to determine how lease contracts are handled">
          Fast Lease Contracts
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsLeasecontractExtendedPrioritization"
          name="fastLeasecontractExtendedPrioritization"
          label="Fast Lease Contract prioritization"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'No extended lease contract priotization'},
            {id: true, text: 'Extended lease contract prioritization'},
          ]}
          normalize={normalizeBoolean}
          defaultValue={fastLeasecontractExtendedPrioritization || ''}
          disabled={disabled}
        />
        <Field
          base="fatdOptionsLeasecontractHandleFuture"
          name="fastLeasecontractHandleFuture"
          label="Fast Lease Contracts not yet started"
          component={FormReduxSelect2}
          data={[
            {id: false, text: 'Future lease contracts are ignored'},
            {id: true, text: 'Future lease contracts will be handled'},
          ]}
          normalize={normalizeBoolean}
          defaultValue={fastLeasecontractHandleFuture || 'false'}
          disabled={disabled}
        />
        <Field
          base="fatdOptionsPersonArchivingDelay"
          name="personArchivingDelay"
          label="TOPdesk Person Archiving Delay"
          component={FormReduxInput}
          type="number"
          value={personArchivingDelay || '0'}
          min="0"
          step="1"
          disabled={disabled}
          helpText="Persons in TOPdesk without any active leases will be kept for this amount of days"
        />
        <hr />
        <InstanceHelpHeader helpFragmentId="instance_fatd_settings_fast_translation_table_help" subTitle="Define how class codes are translated into asset template names">
          Class Code to Asset Template Table
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsFastSpatisystemTranslationTable"
          component={FormReduxTextareaJSON}
          name="fastSpatisystemTranslationTable"
          placeholder="Define the translation table as valid JSON"
          showAllRows={true}
          helpText="See http://www.fi2.se/classlist/CB001_005_001.xml for available classes"
          disabled={disabled}
        />
        <hr />
        <InstanceHelpHeader subTitle="Settings related to Vitec CoreExternal">
          Vitec CoreExternal
        </InstanceHelpHeader>
        <Field
          base="fatdOptionsCoreExternalApiUrl"
          component={FormReduxInput}
          label="Vitec CoreExternal API URL"
          name="coreExternalApiUrl"
          value={coreExternalApiUrl || ''}
          disabled={disabled}
          helpText="Leave the field blank if person strategy is not 'Type R'"
        />
      </div>
    );
  }

  render() {
    const { dirty, disabled, invalid, isSetupMode, onSubmit, onNudgeStep, isUpdating } = this.props;
		return (
      <Form id="instanceFatdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceFatdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20 row">
          <div className="col-md-6">
            {this.renderFastSettings()}
            {this.renderExternalSyncSettings()}
          </div>
          <div className="col-md-6">
            {this.renderTopdeskSettings()}
          </div>
        </div>
      </Form>
		);
  }
}

function QuickCopyAddon(props) {
  const { value } = props;
  return (
    <CopyToClipboard text={value}>
      <div className="input-group-btn">
        <Button title="Copy to clipboard"><Icon icon="copy" /></Button>
      </div>
    </CopyToClipboard>
  );
}

function mapStateToProps(state) {
  return {
    ...state.fatd.options,
  };
}

const actions = {
  ...optionsActions,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'fatdOptions',
  enableReinitialize: true
})(ConnectedFATDOptionsForm));
