import {
  INSTANCE_FSTD_FILE_TEST,
  INSTANCE_FSTD_READ_TEST_FILE_SUCCESS,
  INSTANCE_FSTD_DELETE_TEST_FILE_SUCCESS,
  INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS,
} from 'client/redux/fstd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import _ from 'lodash';

// clears all notices when any of these actions are run
const clearActions = [
  INSTANCE_FSTD_READ_TEST_FILE_SUCCESS,
  INSTANCE_FSTD_DELETE_TEST_FILE_SUCCESS,
  INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS,
];

const defaultState = {
  error: null,
  isTesting: false,
  notices: [],
  method: null,
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_FSTD_FILE_TEST, state, action, {
    requestProp: 'isTesting',
    successProp: (state, action, value) => {
      const method = _.get(action, 'meta.method');
      return {..._.pick(value, 'notices'), method};
    }
  });

  if(clearActions.includes(action.type)) {
    state = {...state, ...defaultState, notices: []};
  }

  return state;
};
