import React, { Component } from 'react';
import { PlanTable} from 'client/components/Plans/Plans';
import propTypes from 'prop-types';
import { TurnTable, GhettoBlaster, Stereo, Radio } from 'client/components/Vector/StereoSets';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import _ from 'lodash';
import ConnectorSelectPlanTaxAlert from 'client/components/ConnectorSelectPlan/components/ConnectorSelectPlanTaxAlert';
import UIFragmentSingle from 'client/components/UIFragment/UIFragmentSingle';

export default class ConnectorSelectPlanTable extends Component {

  static propTypes = {
    onSelectPlan: propTypes.func.isRequired,
    plans: propTypes.array.isRequired,
    connector: propTypes.object.isRequired,
    userCanSelectWithoutAddingCard: propTypes.bool.isRequired,
    paymentContext: propTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      showInterval: _.chain(props.plans).orderBy(plan => ['month', 'year'].indexOf(plan.interval)).last().defaultTo({interval: 'year'}).get('interval').value()
    };
  }

  handleChangeInterval = ev => {
    const showInterval = ev.target.value;
    this.setState({showInterval});
  }

  getGroupedPlansByType() {
    const { plans } = this.props;
    const order = ['connector', 'category', 'everything'];
    return _.chain(plans)
      .groupBy('type')
      .map((plans, type) => ({
        plans: _.sortBy(plans, p => _.get(p, 'stripeObject.amount')),
        type
      }))
      .sortBy(group => order.indexOf(group.type))
      .value();
  }


  getPropsByType(type) {
    switch(type) {
      default: return {Image: Radio};
      case 'connector': return {
        Image: TurnTable, 
        colorName: 'danger', 
        text: 'Includes only this connector for your specific needs',
      };
      case 'category': return {
        Image: GhettoBlaster,
        colorName: 'warning',
        text: 'Get access to every connector in the same category now and in the future',
      };
      case 'everything': return {
        Image: Stereo,
        colorName: 'success',
        text: 'Includes access to every connector we offer now and in the future',
      };
    }
  }

  renderPlans() {
    const { showInterval } = this.state;
    const planGroups = this.getGroupedPlansByType();
    const { userCanSelectWithoutAddingCard, text } = this.props;
    const selectText = userCanSelectWithoutAddingCard ? 'Select' : 'Add card and select';
    return _.map(planGroups, ({plans, type}) => {
      return <PlanTable
        key={type}
        className="col-md-3"
        onSelect={this.props.onSelectPlan}
        plans={plans}
        showInterval={showInterval}
        selectText={selectText}
        text={text}
        {...this.getPropsByType(type)}
      />
    });
  }

  getIntervalData() {
    const { plans } = this.props;
    return _.chain(plans).map('interval').uniq().orderBy().reverse().map(id => {
      switch(id) {
        default: return false;
        case 'month': return {id, text: 'Pay per month'};
        case 'year': return {id, text: 'Pay per year'};
      }
    }).compact().value();
  }

  renderIntervalForm() {
    const { showInterval } = this.state;
    return (
      <div className="col-md-9">
        <div className="panel panel-border panel-default">
          <div className="panel-body xs-pt-20 xs-pb-20">
            <div className="row">
              <div className="col-md-6">
                <label className="control-label" htmlFor="connectorSelectPlanInterval">
                  Desired payment interval:
                </label>
              </div>
              <div className="col-md-6">
                <Select2Wrap
                  id="connectorSelectPlanInterval"
                  name="interval"
                  className="form-control"
                  data={this.getIntervalData()}
                  style={{width: '100%'}}
                  onChange={this.handleChangeInterval}
                  defaultValue={showInterval}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { hasVatNumber, taxPercentage } = _.get(this.props, 'paymentContext', {});
    return (
      <div>
        <div className="row">
          <div className="col-md-9 col-centered">
            <ConnectorSelectPlanTaxAlert taxPercentage={taxPercentage} hasVatNumber={hasVatNumber} />
          </div>
        </div>
        <h1 className="display-heading text-center">Select your pricing plan</h1>
        <UIFragmentSingle
          className="display-description text-center"
          fragmentId={`connector_select_plan_table_description`}
        />
        <div className="pricing-tables-form row">
          {this.renderIntervalForm()}
        </div>
        <div className="row pricing-tables">
          {this.renderPlans()}
        </div>
      </div>
    );
  }

}
