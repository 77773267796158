import { CONFIRM_USER } from 'client/redux/confirmUser/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isConfirming: false,
  result: null,
  error: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(CONFIRM_USER, state, action, {requestProp: 'isConfirming'});
  return state;
};
