import React, { Component } from 'react';
import propTypes from 'prop-types';

export default class LogLevelLabel extends Component {

  static propTypes = {
    level: propTypes.oneOf(['error', 'warning', 'info', 'success', 'job'])
  }

  render() {
    const { level } = this.props;
    switch(level) {
      default:
      case 'job': return <span className="label label-primary">Job</span>;
      case 'info': return <span className="label label-primary">Info</span>;
      case 'warning': return <span className="label label-warning">Warning</span>;
      case 'error': return <span className="label label-danger">Error</span>;
      case 'success': return <span className="label label-success">Success</span>;
    }
  }

}
