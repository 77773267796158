import { combineReducers } from 'redux';

import index from 'client/redux/plan/indexReducer';
import panel from 'client/redux/plan/panelReducer';

const planReducer = combineReducers({
  index,
  panel
});

export default planReducer;
