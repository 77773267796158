import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import EndpointSkatteverketForm from 'client/components/Endpoint/EndpointSkatteverketForm';
import { connect } from 'react-redux';
import * as skatteverketActions from 'client/redux/endpoint/skatteverket/actions';
import BeLoading from 'client/components/Be/BeLoading';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { ENDPOINT_SKATTEVERKET_DELETE_SUCCESS } from 'client/redux/endpoint/skatteverket/constants';
import { ModalConfirmButton, ModalContentConfirmWarning  } from 'client/components/Helpers/Modal';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { AlertWithMessage } from 'client/components/Be/Alert';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';

class EndpointSkatteverket extends React.Component {

  static defaultProps = {
    onUpdated: function() {},
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    instanceId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    onUpdated: propTypes.func,
  }

  componentDidMount() {
    if (this.props.preloadedEndpoint) {
      this.props.endpointSkatteverketPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointSkatteverketRead(this.props.endpointId);
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointSkatteverketRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  handleUpdate = (settings = {}) => {
    return this.props.endpointSkatteverketUpdateSettings(this.props.endpointId, settings)
      .then(getResponseToFormErrorHandler())
      .then(result => {
        this.props.endpointSkatteverketEditSettings(false);
      });
  }

  handleClickEdit = ev => {
    this.props.endpointSkatteverketEditSettings(true);
  }

  handleClickDelete = ev => {
    const { instanceId, endpointId } = this.props;
    return this.props.endpointSkatteverketDelete(instanceId, 'skatteverket').then(result => {
      if (result.type !== ENDPOINT_SKATTEVERKET_DELETE_SUCCESS) return result;
      return this.props.onUpdated(endpointId);
    });
  }

  handleTest = ev => {
    const { endpointId } = this.props;
    return this.props.endpointSkatteverketTest(endpointId);
  }


  render() {
    const {
      endpoint,
      error,
      isEditing,
      isReading,
      isUpdating,
      isTesting,
    } = this.props;

    const endpointHasError = endpoint?.status === 'error';
    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              Skatteverket Navet
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_skatteverket_settings_help'}}
              />
            </div>
            <span className="panel-subtitle">Paste your Skatteverket Navet credentials below</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading loading={isReading} className="xs-p-20">
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {endpointHasError && (
              <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
                <strong>Error:</strong> There is a problem with this endpoint.
              </AlertWithMessage>
            )}
            {endpoint && (
              <>
                {isEditing && (
                  <EndpointSkatteverketForm
                    isDisabled={false}
                    initialValues={endpoint.settings}
                    isEditing={isEditing}
                    endpointState={endpoint.state}
                    onEdit={this.handleClickEdit}
                    onSubmit={this.handleUpdate}
                    isUpdating={isUpdating}
                    remoteError={error}
                  />
                )}
                {!isEditing && (
                  <div className="row">
                    <div className="col-md-6">
                      <EndpointTestButton
                        isTesting={isTesting}
                        onTest={this.handleTest}
                        testOk={endpoint.state.testOk}
                        testTimestamp={endpoint.state.testTimestamp}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="btn-group pull-right clearfix">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={this.handleClickEdit}
                        >
                          <i className="icon icon-left mdi mdi-edit"></i>
                          {' '}Edit settings
                        </button>
                        <ModalConfirmButton
                          className="btn btn-default"
                          callback={this.handleClickDelete}
                          contentProps={{title: 'Confirm endpoint deletion', text: 'Are you sure you want to delete this endpoint?'}}
                          Content={ModalContentConfirmWarning}
                        >
                          <i className="icon mdi mdi-delete"></i>{' '}
                          Delete
                        </ModalConfirmButton>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.skatteverket, skatteverketActions)(EndpointSkatteverket);
