import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import ConnectedMWTDTester from 'client/components/MWTD/ConnectedMWTDTester';
import { Helmet } from 'react-helmet';
import ConnectedMWTDOptionsForm from 'client/components/MWTD/ConnectedMWTDOptionsForm';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

export default class MWTDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Barracuda Managed Workplace → TOPdesk Incidents</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={ConnectedMWTDOptionsForm}
            TesterComponent={ConnectedMWTDTester}
            endpointOrder={['managedworkplace', 'topdesk']}
          />
        </MainContent>
      </Content>
    );
  }

}
