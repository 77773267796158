import React, { Component } from 'react';
import * as userNewPanelActions from 'client/redux/user/newPanelActions';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import UserPanelForm from 'client/components/User/components/UserPanelForm';
import { USER_PANEL_CREATE_SUCCESS } from 'client/redux/user/constants';
import { gritterAlertPush } from 'client/redux/gritter/actions';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';

class UserNewPanel extends Component {

  static propTypes = {
    onCreated: propTypes.func
  }

  handleCreate = fields => {
    const { onCreated } = this.props;
    return this.props.userNewPanelCreate(fields).then(getResponseToFormErrorHandler()).then(result => {
      if(result.type !== USER_PANEL_CREATE_SUCCESS) return;
      this.props.gritterAlertPush('user_create_success', {
        className: 'color success',
        title: 'Creation complete',
        message: 'The user was created',
        closeAfter: 3000
      });
      return onCreated();
    });
  }

  render() {
    const { error } = this.props;
    return (
      <div>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        <UserPanelForm isNew={true} onSubmit={this.handleCreate} error={error} />
      </div>
    );
  }

}

export default connect(state => state.user.newPanel, {...userNewPanelActions, gritterAlertPush})(UserNewPanel);
