import { ACCOUNT_CARD_LIST, ACCOUNT_CARD_DELETE } from 'client/redux/account/constants';
import * as actionCreators from 'client/redux/account/actionCreators';

export const accountCardList = () => dispatch => {
  return dispatch(actionCreators.accountCardList(ACCOUNT_CARD_LIST));
};

export const accountCardDelete = id => dispatch => {
  return dispatch(actionCreators.accountCardDelete(ACCOUNT_CARD_DELETE, id));
};
