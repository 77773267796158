import React from 'react';
import { AlertWithMessage } from 'client/components/Be/Alert';

export default class InstancePauseAlert extends React.Component {
  render() {
    const alertProps = {icon: true, className: 'alert-icon xs-mb-0'};
    return (
      <AlertWithMessage {...alertProps} alertType="warning">
        <strong>Warning:</strong> The connector needs to be paused before any options can be changed.
      </AlertWithMessage>
    );
  }
}
