import * as constants from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isUpdating: false,
  isCalling: false,
  isUpdatingAllStatusToActive: false,
  isCloningInstance: false,
  error: null,
  result: null,
};

export default function panelReducer(state = defaultState, action) {
  state = reduceByTypes(constants.INSTANCE_PANEL_UPDATE_ALL_STATUS_TO_ACTIVE, state, action, {requestProp: 'isUpdatingAllStatusToActive'});
  state = reduceByTypes(constants.INSTANCE_PANEL_UPDATE_STATUS, state, action, {requestProp: 'isUpdating'});
  state = reduceByTypes(constants.INSTANCE_PANEL_UPDATE_USER_ID, state, action, {requestProp: 'isUpdating'});
  state = reduceByTypes(constants.INSTANCE_PANEL_CALL, state, action, {requestProp: 'isCalling'});
  state = reduceByTypes(constants.INSTANCE_PANEL_READ, state, action, {requestProp: 'isReading'});
  return state;
};
