export const ENDPOINT_GRAPH_PRELOADED = 'ENDPOINT_GRAPH_PRELOADED';

export const ENDPOINT_GRAPH_READ_REQUEST = 'ENDPOINT_GRAPH_READ_REQUEST';
export const ENDPOINT_GRAPH_READ_SUCCESS = 'ENDPOINT_GRAPH_READ_SUCCESS';
export const ENDPOINT_GRAPH_READ_FAILURE = 'ENDPOINT_GRAPH_READ_FAILURE';
export const ENDPOINT_GRAPH_READ = [ENDPOINT_GRAPH_READ_REQUEST, ENDPOINT_GRAPH_READ_SUCCESS, ENDPOINT_GRAPH_READ_FAILURE];

export const ENDPOINT_GRAPH_TEST_CONSENT_REQUEST = 'ENDPOINT_GRAPH_TEST_CONSENT_REQUEST';
export const ENDPOINT_GRAPH_TEST_CONSENT_SUCCESS = 'ENDPOINT_GRAPH_TEST_CONSENT_SUCCESS';
export const ENDPOINT_GRAPH_TEST_CONSENT_FAILURE = 'ENDPOINT_GRAPH_TEST_CONSENT_FAILURE';
export const ENDPOINT_GRAPH_TEST_CONSENT = [ENDPOINT_GRAPH_TEST_CONSENT_REQUEST, ENDPOINT_GRAPH_TEST_CONSENT_SUCCESS, ENDPOINT_GRAPH_TEST_CONSENT_FAILURE];
