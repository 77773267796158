import React, { Component } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { roleSelect2, isConfirmedSelect2, paranoidSelect2 } from 'client/redux/user/constants';

export default class UserTableFilterForm extends Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  constructor(props) {
    super(props);
    this.extendFilterDebounced = _.debounce(this.extendFilter, 200);
  }

  extendFilter(extendWith = {}) {
    const newFilter = {...this.props.filter, ...extendWith};
    this.props.onFilter(newFilter);
  }

  handleChangeToDebounced = ev => {
    this.extendFilterDebounced({[ev.target.name]: ev.target.value});
  }

  handleChange = ev => {
    this.extendFilter({[ev.target.name]: ev.target.value});
  }

  render() {
    const { role, isConfirmed, paranoid, term } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <input
              id="userTableFilterTerm"
              name="term"
              className="form-control"
              onChange={this.handleChangeToDebounced}
              defaultValue={term || ''}
              placeholder="No search term"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="userTableFilterRole"
              name="role"
              className="form-control"
              data={roleSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any role'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={role || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="userTableFilterIsConfirmed"
              name="isConfirmed"
              className="form-control"
              data={isConfirmedSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any confirmed'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={isConfirmed || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="userTableFilterParanoid"
              name="paranoid"
              className="form-control"
              data={paranoidSelect2}
              options={{minimumResultsForSearch: 20}}
              onChange={this.handleChange}
              defaultValue={paranoid || '1'}
            />
          </div>
        </div>
      </div>
    );
  }

}
