import { PLAN_PANEL_READ, PLAN_PANEL_UPDATE, PLAN_PANEL_CLEAR } from 'client/redux/plan/constants';
import * as actions from 'client/redux/plan/actionCreators';

export const planPanelClear = () => dispatch => {
  return dispatch({type: PLAN_PANEL_CLEAR});
};

export const planPanelRead = id => dispatch => {
  return dispatch(actions.planRead(PLAN_PANEL_READ, id));
};

export const planPanelUpdate = (id, update) => dispatch => {
  return dispatch(actions.planUpdate(PLAN_PANEL_UPDATE, id, update));
};
