import { CONNECTOR_INDEX, CONNECTOR_INDEX_ORDERBY, CONNECTOR_INDEX_SET_PAGE, CONNECTOR_INDEX_FILTER } from 'client/redux/connector/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  connectors: null,
  filter: {isActive: ''},
  page: 1,
  orderBy: 'type',
  orderDirection: 'ASC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(CONNECTOR_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceTableActions([CONNECTOR_INDEX_FILTER, CONNECTOR_INDEX_SET_PAGE, CONNECTOR_INDEX_ORDERBY], state, action);
  return state;
};
