import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm } from 'redux-form';
import { isNotEmpty, isString, isURL } from 'client/redux/validators';
import { FormErrors } from 'client/components/Form/FormErrors';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';

const validateByField = {
  apiUrl: [isString(), isNotEmpty(), isURL()],
  clientId: [isString(), isNotEmpty()],
  clientSecret: [isString(), isNotEmpty()],
};

class EndpointSnowForm extends React.Component {

  static defaultProps = {
    isEditing: false,
    onEdit: function() {}
  }

  static propTypes = {
    isEditing: propTypes.bool.isRequired,
    onEdit: propTypes.func,
    remoteError: propTypes.object,
    initialValues: propTypes.object.isRequired,
    endpointState: propTypes.object.isRequired,
    isTesting: propTypes.bool.isRequired,
    onTest: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    isUpdating: propTypes.bool.isRequired,
  }

  render() {
    const {
      remoteError,
      error,
      isUpdating,
      valid,
      onEdit,
      onSubmit,
      isEditing,
      initialValues,
      handleSubmit,
      isTesting,
    } = this.props;
    const { testOk, testTimestamp } = this.props.endpointState;

    const hasInitialValues = Object.keys(validateByField).every(field => {
      return Boolean(initialValues[field]);
    });
    const isDisabled = Boolean(hasInitialValues && !isEditing);

    const fieldProps = {
      base: 'endpointSnowForm',
      metaOptions: {defaultState: null, ignorePristine: true},
    };

    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <FormErrors className="xs-mt-0 xs-mb-10" error={error || remoteError} />
        <div>
          <Field
            {...fieldProps}
            name="apiUrl"
            component={FormReduxInput}
            type="text"
            label="API Base URL"
            disabled={isDisabled}
            validate={validateByField.apiUrl}
            helpText="Example: https://westeurope.snowsoftware.io"
            required
          />
          <div className="row">
            <div className="col-lg-6">
              <Field
                {...fieldProps}
                name="clientId"
                component={FormReduxInput}
                type="text"
                label="Client ID"
                disabled={isDisabled}
                validate={validateByField.clientId}
                required
              />
            </div>
            <div className="col-lg-6">
              <Field
                {...fieldProps}
                name="clientSecret"
                component={FormReduxInput}
                type="text"
                label="Client Secret"
                disabled={isDisabled}
                validate={validateByField.clientSecret}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group xs-mb-0">
          <EndpointTestButton
            isTesting={isTesting}
            isDisabled={!isDisabled}
            onTest={this.props.onTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
          />{' '}
          {isDisabled && (
            <button className="btn btn-default pull-right" type="button" onClick={onEdit}>
              Edit settings
            </button>
          )}
          {!isDisabled && (
            <ButtonWaiting
              type="submit"
              disabled={!valid}
              className="btn btn-default pull-right"
              isWaiting={isUpdating}
            >
              Save and test
            </ButtonWaiting>
          )}
          <div className="clearfix"></div>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'endpointSnow',
  enableReinitialize: true
})(EndpointSnowForm);
