import {
  INSTANCE_FATD_OPTIONS_READ_META,
} from 'client/redux/fatd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  errorMeta: null,
  isReadingMeta: false,
};

export default function optionsReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_FATD_OPTIONS_READ_META, state, action, {
    requestProp: 'isReadingMeta',
    errorProp: 'errorMeta',
    successProp: 'meta',
  });

  return state;
};
