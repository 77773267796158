import * as constants from 'client/redux/qrtd/constants';
import * as actions from 'client/redux/qrtd/actionCreators';

export const instanceQrtdImageUploadRead = (instanceId, imageType) => dispatch => dispatch(actions.instanceQrtdImageUploadRead(constants.INSTANCE_QRTD_IMAGE_UPLOAD_READ, instanceId, imageType));

export const instanceQrtdImageUploadSuccess = (imageType, response) => dispatch => dispatch(actions.instanceQrtdImageUploadSuccess(constants.INSTANCE_QRTD_IMAGE_UPLOAD_SUCCESS, imageType, response));

export const instanceQrtdImageUploadError = (imageType, error) => dispatch => dispatch(actions.instanceQrtdImageUploadError(constants.INSTANCE_QRTD_IMAGE_UPLOAD_ERROR, imageType, error));

export const instanceQrtdImageUploadDelete = (instanceId, imageType) => dispatch => dispatch(actions.instanceQrtdImageUploadDelete(constants.INSTANCE_QRTD_IMAGE_UPLOAD_DELETE, instanceId, imageType));
