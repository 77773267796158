import { combineReducers } from 'redux';

import supportForm from 'client/redux/navbar/supportFormReducer';
import color from 'client/redux/navbar/colorReducer';

const navbarReducer = combineReducers({
  supportForm,
  color,
});

export default navbarReducer;
