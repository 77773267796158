import React from 'react';
import Icon from 'client/components/Be/Icon';
import { AlertDismissButton } from 'client/components/Be/Alert';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { DASHBOARD_WELCOME_MESSAGE_HIDE_SUCCESS } from 'client/redux/dashboard/constants';
import { dashboardWelcomeMessageHide } from 'client/redux/dashboard/welcomeMessageActions';
import { uiPropsSelf } from 'client/redux/ui/actions';
import { connect } from 'react-redux';
import 'client/components/Dashboard/ConnectedDashboardWelcomeMessage.css';

class ConnectedDashboardWelcomeMessage extends React.Component {

  handleDismiss = ev => {
    const { id } = this.props;
    return this.props.dashboardWelcomeMessageHide(id).then(result => {
      if(result.type === DASHBOARD_WELCOME_MESSAGE_HIDE_SUCCESS) {
        return this.props.uiPropsSelf();
      }
    });
  }

  render() {
    const showWelcomeMessage = _.get(this.props, 'state.showWelcomeMessage', false);
    if(!showWelcomeMessage) return null;
    return (
      <div className="alert alert-success alert-icon welcome-message">
        <Icon icon="thumb-up" />
        <div className="message">
          <AlertDismissButton onClick={this.handleDismiss} />
          <h2>Thanks for signing up!</h2>
          <p>
            In the menu on the left you can find the <Link to="/marketplace">marketplace page</Link> where all our connectors are available.
            All connectors come with a 14-day free trial during which time you can use all of the features just like in the paid version. You do not need to add payment information in order to try the connectors out.
          </p>
          <p>
            When the trial time for a connector is about to run out, we will send you an email with instructions for how you can add your payment information on the <Link to="/account/billing">billing page</Link> and/or modify your subscriptions on the <Link to="/account/subscriptions">subscriptions page</Link> so that you can have uninterrupted service if you want to continue using a connector.
          </p>
          <p>
            Should you require additional features such as manual invoicing, more than one connector of a certain type or if you have any suggestions for features or how we can improve the product, please email <a href="mailto:hello@linknow.io">hello@linknow.io</a> and we will help you to the best of our ability.
          </p>
          <p>
            If you encounter any problems or errors when using this platform or any of the connectors, have a look at the <Link to="/support">support page</Link> or send an email to <a href="mailto:support@linknow.io">support@linknow.io</a> with any questions you may have and we will get back to you as soon as possible.
          </p>
          <p>
            <strong>We wish you the best of luck!</strong>
          </p>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => _.get(state, 'ui.self', {}) || {};

const actions = {
  dashboardWelcomeMessageHide,
  uiPropsSelf,
};

export default connect(mapStateToProps, actions)(ConnectedDashboardWelcomeMessage);
