import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/user/log/indexActions';
import UserLogTable from 'client/components/UserLog/components/UserLogTable';
import { getStateSearch } from 'client/redux/apiHelpers';
import _ from 'lodash';

class UserLogIndex extends Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    const filter = {...this.props.filter, ...this.props.search};
    return this.handleFilter(filter);
  }

  getData = (overrideWith = {}) => {
    const { orderBy, orderDirection, page, filter } = this.props;
    return this.props.userLogIndex({...filter, orderBy, orderDirection, page, ...overrideWith});
  }

  handleOrderBy = (orderBy, orderDirection) => {
    this.props.userLogIndexOrderBy(orderBy, orderDirection);
    return this.getData({orderBy, orderDirection});
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    this.props.userLogIndexSetPage(page);
    return this.getData({page});
  }

  handleFilter = filter => {
    this.props.userLogIndexFilter(filter);
    return this.getDataDebounced(filter);
  }

  renderTitle() {
    return (
      <Helmet>
        <title>User Log Overview</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const { isReading, logs, orderBy, orderDirection, page, pages, limit, offset, count, filter, error } = this.props;
    if(error) return this.renderError(error);
    return (
      <Content>
        <MainContent>
          {this.renderTitle()}
          <UserLogTable
            className="panel-default panel-border"
            loading={isReading}
            logs={logs}
            onOrderBy={this.handleOrderBy}
            onSetPage={this.handleSetPage}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onFilter={this.handleFilter}
            onRefresh={this.getData}
            filter={filter}
            count={count}
            limit={limit}
            offset={offset}
            page={page}
            pages={pages}
          />
        </MainContent>
      </Content>
    );
  }

}

const mapStateToProps = state => ({
  ...state.user.logIndex,
  search: getStateSearch(state),
});

export default connect(mapStateToProps, indexActions)(UserLogIndex);
