import { apiAction } from 'client/redux/apiHelpers';

export const instanceQrtdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceQrtdFieldMapCreate = (types, id, title) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/settings/fieldMaps`,
    method: 'POST',
    json: {title},
    types: types.map(type => ({type, meta: {id, title}})),
  });
};

export const instanceQrtdFieldMapUpdate = (types, id, ordinal, title) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/settings/fieldMaps/${ordinal}`,
    method: 'PATCH',
    json: {title},
    types: types.map(type => ({type, meta: {id, ordinal, title}})),
  });
};

export const instanceQrtdFieldMapDelete = (types, id, ordinal) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/settings/fieldMaps/${ordinal}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {id, ordinal}})),
  });
};

export const instanceQrtdInfoTextRead = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/infoTexts`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceQrtdInfoTextCreate = (types, id, create = {}) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/infoTexts`,
    method: 'POST',
    json: create,
    types: types.map(type => ({type, meta: {id, create}})),
  });
};

export const instanceQrtdInfoTextUpdate = (types, id, ordinal, update = {}) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/infoTexts/${ordinal}`,
    method: 'PATCH',
    json: update,
    types: types.map(type => ({type, meta: {id, ordinal, update}})),
  });
};

export const instanceQrtdInfoTextDelete = (types, id, ordinal) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${id}/infoTexts/${ordinal}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {id, ordinal}})),
  });
};

export const instanceQrtdImageUploadRead = (types, instanceId, imageType) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${instanceId}/settings/images/${imageType}`,
    method: 'GET',
    types: types.map(type => ({type, meta: {instanceId, imageType}})),
  });
};

export const instanceQrtdImageUploadDelete = (types, instanceId, imageType) => {
  return apiAction({
    endpoint: `/api/instance/qrtd/${instanceId}/settings/images/${imageType}`,
    method: 'DELETE',
    types: types.map(type => ({type, meta: {instanceId, imageType}})),
  });
};

export const instanceQrtdImageUploadSuccess = (type, imageType, response) => ({
  type,
  response,
  meta: {imageType},
});

export const instanceQrtdImageUploadError = (type, imageType, error) => ({
  type,
  error,
  meta: {imageType},
});
