import * as actionCreators from 'client/redux/mwtd/actionCreators';
import {
  INSTANCE_MWTD_TEST_CLEAR,
  INSTANCE_MWTD_TEST_DEVICE_ADD,
  INSTANCE_MWTD_TEST_DEVICE_REMOVE,
  INSTANCE_MWTD_TEST_DEVICE,
  INSTANCE_MWTD_SYNC_DEVICE,
} from 'client/redux/mwtd/constants';

export const instanceMwtdTestDeviceAdd = (id, alarmId) => dispatch => {
  return dispatch(actionCreators.instanceMwtdTestDeviceAdd(INSTANCE_MWTD_TEST_DEVICE_ADD, id, alarmId));
};

export const instanceMwtdTestDeviceRemove = alarmId => dispatch => {
  return dispatch(actionCreators.instanceMwtdTestDeviceRemove(INSTANCE_MWTD_TEST_DEVICE_REMOVE, alarmId));
};

export const instanceMwtdTestDevice = (id, alarmId) => dispatch => {
  return dispatch(actionCreators.instanceMwtdTestDevice(INSTANCE_MWTD_TEST_DEVICE, id, alarmId));
};

export const instanceMwtdSyncDevice = (id, alarmId) => dispatch => {
  return dispatch(actionCreators.instanceMwtdSyncDevice(INSTANCE_MWTD_SYNC_DEVICE, id, alarmId));
};

export const instanceMwtdTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceMwtdTestClear(INSTANCE_MWTD_TEST_CLEAR));
};
