import * as constants from 'client/redux/instance/logFragment/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  logFragments: null,
  filter: {connectorInstanceLogId: '', level: '', timeStart: '', timeEnd: '', searchData: '{}'},
  page: 1,
  orderBy: 'time',
  orderDirection: 'DESC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(constants.INSTANCE_LOG_FRAGMENT_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceTableActions([constants.INSTANCE_LOG_FRAGMENT_INDEX_FILTER, constants.INSTANCE_LOG_FRAGMENT_INDEX_SET_PAGE, constants.INSTANCE_LOG_FRAGMENT_INDEX_ORDERBY], state, action);
  return state;
};
