import * as actionCreators from 'client/redux/endpoint/bankid/actionCreators';
import * as constants from 'client/redux/endpoint/bankid/constants';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';
import { instanceOptionalEndpointDelete } from 'client/redux/instance/actionCreators';

export const endpointBankidPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(constants.ENDPOINT_BANKID_PRELOADED, endpoint));
};

export const endpointBankidRead = id => dispatch => {
  return dispatch(actionCreators.endpointBankidRead(constants.ENDPOINT_BANKID_READ, id));
};

export const endpointBankidDelete = (instanceId, endpointType) => dispatch => {
  return dispatch(instanceOptionalEndpointDelete(constants.ENDPOINT_BANKID_DELETE, instanceId, endpointType));
};

export const endpointBankidEditSettings = isEditing => dispatch => {
  return dispatch(actionCreators.endpointBankidEditSettings(constants.ENDPOINT_BANKID_EDIT_SETTINGS, isEditing));
};

export const endpointBankidUpdateSettings = (id, settings) => dispatch => {
  return dispatch(actionCreators.endpointBankidUpdateSettings(constants.ENDPOINT_BANKID_UPDATE_SETTINGS, id, settings));
};
