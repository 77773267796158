import React from 'react';
import propTypes from 'prop-types';

/**
 * @desc Restore button for an object table
 * @todo Should probably be consolidated in components/Button/TableButtons
 */

export default class ButtonRestore extends React.Component {

  static propTypes = {
    anchor: propTypes.bool.isRequired,
    text: propTypes.bool.isRequired,
    id: propTypes.string.isRequired,
    onRestore: propTypes.func.isRequired,
    isSoftDeleted: propTypes.bool.isRequired,
  }

  static defaultProps = {
    anchor: false,
    text: false,
  }

  handleClick = ev => {
    ev.preventDefault();
    const { onRestore, id } = this.props;
    return onRestore(id);
  }

  render() {
    const { isSoftDeleted, anchor, text } = this.props;
    if(!isSoftDeleted) return null;
    if(anchor) {
      return (
        // eslint-disable-next-line
        <a href="#" onClick={this.handleClick}>
          <i className="icon mdi mdi-time-restore-setting"></i>
          {text && 'Unarchive'}
        </a>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-default"
        title="Restore this object"
        onClick={this.handleClick}
      >
        <i className="icon mdi mdi-time-restore-setting"></i>
        {text && 'Restore'}
      </button>
    );
  }

}

