import { INSTANCE_SETUP_READ, INSTANCE_SETUP_UPDATE, INSTANCE_SETUP_UPDATE_STATUS } from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isUpdating: false,
  error: null,
  wizard: null,
  instance: null,
  connector: null,
  endpointsByType: null,
  optionalEndpoints: [],
};

export default function setupReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_SETUP_READ, state, action, {
    requestProp: 'isReading',
    successSpread: true,
    successPickProps: [
      'wizard',
      'instance',
      'endpointsByType',
      'connector',
      'subscription',
      'optionalEndpoints',
    ],
  });
  state = reduceByTypes(INSTANCE_SETUP_UPDATE, state, action, {
    requestProp: 'isUpdating',
    successSpread: true,
    successPickProps: ['wizard', 'instance', 'endpointsByType', 'connector']
  });
  state = reduceByTypes(INSTANCE_SETUP_UPDATE_STATUS, state, action, {
    requestProp: 'isUpdating',
    successSpread: true,
    successPickProps: ['instance']
  });
  return state;
};
