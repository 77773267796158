import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import BeLoading from 'client/components/Be/BeLoading';
import {
  TableThSort,
  TablePagination,
  TableShowCount,
  TableUserCell,
} from 'client/components/Helpers/Table';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import ButtonSoftDeleteConfirmModal from 'client/components/Button/ButtonSoftDeleteConfirmModal';
import Time from 'client/components/Helpers/Time';
import UserSubscriptionTableFilterForm from 'client/components/UserSubscription/components/UserSubscriptionTableFilterForm';
import { UserSubscriptionStatusLabel } from 'client/components/Helpers/StatusLabel';
import _ from 'lodash';

export default class UserSubscriptionTable extends Component {

  static defaultProps = {
    subscriptions: []
  }

  static propTypes = {
    subscriptions: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onRefresh: propTypes.func,
    onDeleteUserSubscription: propTypes.func,
    loading: propTypes.bool,
  }

  renderPlanColumn(plans = []) {
    if(!plans.length) return <span>0 plans</span>;
    if(plans.length === 1) return <span>{plans[0].name}</span>;
    return <span>{plans.length} plans</span>;
  }

  renderSubscriptions() {
    const { subscriptions, onDeleteUserSubscription  } = this.props;
    if(!subscriptions || !subscriptions.length) return (
      <tr>
        <td className="empty" colSpan={6}>No subscriptions to show</td>
      </tr>
    );
    return subscriptions.map(subscription => {
      const { id, stripeId, createdAt, status, deletedAt, User } = subscription;
      const plans = _.get(subscription, 'Plans', []);
      return (
        <tr key={id}>
          <td><Time time={createdAt} format="YYYY-MM-DD" /></td>
          <td>{stripeId}</td>
          <td><UserSubscriptionStatusLabel status={status} /></td>
          <TableUserCell user={User} />
          <td>{this.renderPlanColumn(plans)}</td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: subscription, title: 'User Subscription', inspector: 'object'}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>{' '}
              <ButtonSoftDeleteConfirmModal
                id={id}
                isSoftDeleted={Boolean(deletedAt)}
                onDelete={force => onDeleteUserSubscription(id, force)}
              />
            </div>
          </td>
        </tr>
      )
    })
  }

  renderTable() {
    const { orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                <TableThSort column="createdAt" {...sortProps}>Created</TableThSort>
                <TableThSort column="stripeId" {...sortProps}>Stripe ID</TableThSort>
                <TableThSort column="id" {...sortProps}>Status</TableThSort>
                <th>User</th>
                <th>Plans</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderSubscriptions()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['subscription', 'subscriptions']} />
          </div>
        </div>
        <div className="col-md-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filter, onFilter } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of User Subscriptions</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <UserSubscriptionTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};
