import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { ButtonWaiting, ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import {
  roleSelect2,
  isConfirmedSelect2,
  hideBillingSelect2,
  paymentMethodSelect2,
} from 'client/redux/user/constants';
import { FormReduxSelect2, FormReduxInput, FormReduxInputGroup } from 'client/components/Form/FormRedux';
import { UserIdSearcher } from 'client/components/Form/Select2Searchers';
import { isString, isEmail } from 'client/redux/validators';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';

const validateFilledEmail = [isString(), isEmail()];

class UserPanelForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
    onCall: propTypes.func,
    isNew: propTypes.bool
  }

  handleCallStripeSync = ev => {
    const { onCall } = this.props;
    if(!onCall) return;
    return onCall('customerStripeSync');
  }

  fieldProps = {base: 'userPanelForm', metaOptions: {defaultState: null, ignorePristine: false}}

  renderNewForm() {
    const { handleSubmit, onSubmit, submitting, invalid } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Field
            {...this.fieldProps}
            type="text"
            name="email"
            component={FormReduxInput}
            label="Email"
            validate={validateFilledEmail}
          />
        </div>
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Create</ButtonWaiting>
        </div>
      </Form>
    );
  }

  renderStripeCustomerSyncButton() {
    const { isNew } = this.props;
    if(isNew) return null;
    return (
      <div className="input-group-btn">
        <ButtonWaitingPromise onClick={this.handleCallStripeSync} type="button" className="btn btn-default">Sync with Stripe</ButtonWaitingPromise>
      </div>
    )
  }

  renderEditForm() {
    const { handleSubmit, onSubmit, submitting, invalid, error } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              type="text"
              name="fullName"
              component={FormReduxInput}
              label="Full name"
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              type="text"
              name="email"
              component={FormReduxInput}
              label="Email"
              validate={validateFilledEmail}
            />
          </div>
        </div>
        <div>
          <Field
            {...this.fieldProps}
            type="text"
            name="stripeId"
            component={FormReduxInputGroup}
            after={this.renderStripeCustomerSyncButton()}
            label="Stripe Customer ID"
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              name="role"
              component={FormReduxSelect2}
              label="Role"
              data={roleSelect2}
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              name="isConfirmed"
              component={FormReduxSelect2}
              label="Confirmed"
              data={isConfirmedSelect2}
              select2ComponentProps={{unwrapValue: true}}
            />
            <Field
              {...this.fieldProps}
              name="hideBilling"
              component={FormReduxSelect2}
              label="Hide billing"
              data={hideBillingSelect2}
              select2ComponentProps={{unwrapValue: true}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              type="number"
              name="taxPercentage"
              step="1"
              parse={val => Number(val)}
              component={FormReduxInput}
              label="Tax percentage"
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              name="paymentMethod"
              component={FormReduxSelect2}
              label="Payment method"
              data={paymentMethodSelect2}
              select2ComponentProps={{unwrapValue: true}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              type="text"
              name="password"
              component={FormReduxInput}
              label="New password"
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              name="parentUserId"
              component={FormReduxSelect2}
              select2ComponentProps={{}}
              select2Component={UserIdSearcher}
              select2Options={{allowClear: true, dropdownParent: '#userPanelFormParentUserIdsDropdownParent', placeholder: {id: '', text: 'No parent'}}}
              label="Parent User"
            />
            <div id="userPanelFormParentUserIdsDropdownParent"></div>
          </div>
        </div>
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

  render() {
    const { isNew } = this.props;
    if(isNew) return this.renderNewForm();
    else return this.renderEditForm();
  }

}

export default reduxForm({
  form: 'userPanel',
  enableReinitialize: true
})(UserPanelForm);
