import { INSTANCE_OPTD_TEST_ALARM_ADD, INSTANCE_OPTD_TEST_CLEAR, INSTANCE_OPTD_TEST_ALARM_REMOVE, INSTANCE_OPTD_TEST_ALARM, INSTANCE_OPTD_SYNC_ALARM, INSTANCE_OPTD_TEST_ALARM_ADD_SUCCESS, INSTANCE_OPTD_TEST_ALARM_SEARCH_FIELD_CHANGE, INSTANCE_OPTD_TEST_ALARM_SUCCESS, INSTANCE_OPTD_SYNC_ALARM_SUCCESS } from 'client/redux/optd/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isAdding: false,
  searchFieldAlarmId: '',
  alarms: {},
};

const defaultAlarm = {
  isSyncing: false,
  isTesting: false,
  error: null,
  topdeskPerson: null,
  opManagerAlarm: null,
  notices: null,
  wasLast: null,
};

const getAlarmId = action => _.get(action, 'meta.alarmId');

// reduce a single alarm
const reduceAlarm = (state = defaultAlarm, action) => {
  const successPickProps = ['notices', 'topdeskPerson', 'opManagerAlarm'];
  state = reduceByTypes(INSTANCE_OPTD_TEST_ALARM_ADD, state, action, {requestProp: 'isAdding', successPickProps});
  state = reduceByTypes(INSTANCE_OPTD_TEST_ALARM, state, action, {requestProp: 'isTesting', successPickProps});
  state = reduceByTypes(INSTANCE_OPTD_SYNC_ALARM, state, action, {requestProp: 'isSyncing', successPickProps});
  if(action.type === INSTANCE_OPTD_SYNC_ALARM_SUCCESS) state.wasLast = 'synced';
  if(action.type === INSTANCE_OPTD_TEST_ALARM_SUCCESS) state.wasLast = 'tested';
  return state;
};

// these actions should pass to reduceAlarm
const alarmActions = [
  ...INSTANCE_OPTD_TEST_ALARM,
  ...INSTANCE_OPTD_SYNC_ALARM,
  INSTANCE_OPTD_TEST_ALARM_ADD_SUCCESS,
];

// reduce an object of alarms keyed by id
const reduceAlarms = (alarms = {}, action) => {
  if(alarmActions.includes(action.type)) {
    const alarmId = getAlarmId(action);
    return {
      ...alarms,
      [alarmId]: reduceAlarm(alarms[alarmId], action),
    };
  }
  switch(action.type) {
    default: return alarms;
    case INSTANCE_OPTD_TEST_CLEAR: return {};
    case INSTANCE_OPTD_TEST_ALARM_REMOVE: return {
      ...alarms,
      [getAlarmId(action)]: undefined,
    };
  }
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_OPTD_TEST_ALARM_ADD, state, action, {requestProp: 'isAdding', successPickProps: []});
  switch(action.type) {
    default: return {
      ...state,
      alarms: reduceAlarms(state.alarms, action),
    };
    case INSTANCE_OPTD_TEST_ALARM_SEARCH_FIELD_CHANGE: return {
      ...state,
      searchFieldAlarmId: action.alarmId,
    }
  }
};
