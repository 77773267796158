import * as actionCreators from 'client/redux/fstd/actionCreators';
import { INSTANCE_FSTD_READ_TEST_FILE, INSTANCE_FSTD_DELETE_TEST_FILE, INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS, INSTANCE_FSTD_TEST_FILE_UPLOAD_ERROR }  from 'client/redux/fstd/constants';

export const instanceFstdGetTestFile = instanceId => dispatch => {
  return dispatch(actionCreators.instanceFstdGetTestFile(INSTANCE_FSTD_READ_TEST_FILE, instanceId));
};

export const instanceFstdDeleteTestFile = instanceId => dispatch => {
  return dispatch(actionCreators.instanceFstdDeleteTestFile(INSTANCE_FSTD_DELETE_TEST_FILE, instanceId));
};

export const instanceFstdTestFileUploadSuccess = testFile => dispatch => {
  return dispatch(actionCreators.instanceFstdTestFileUploadSuccess(INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS, testFile));
};

export const instanceFstdTestFileUploadError = testFile => dispatch => {
  return dispatch(actionCreators.instanceFstdTestFileUploadError(INSTANCE_FSTD_TEST_FILE_UPLOAD_ERROR, testFile));
};
