import { UI_PROPS, UI_PROPS_SELF } from 'client/redux/ui/constants';
import * as actionCreators from 'client/redux/ui/actionCreators';

export const uiProps = (query = {}) => dispatch => {
  return dispatch(actionCreators.uiProps(UI_PROPS, query));
};

export const uiPropsSelf = (query = {}) => dispatch => {
  return dispatch(actionCreators.uiPropsSelf(UI_PROPS_SELF, query));
};
