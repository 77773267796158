import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import EndpointFastForm from 'client/components/Endpoint/components/EndpointFastForm';
import { connect } from 'react-redux';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';
import * as fastActions from 'client/redux/endpoint/fast/actions';
import BeLoading from 'client/components/Be/BeLoading';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { AlertWithMessage } from 'client/components/Be/Alert';

class EndpointFast extends React.Component {

  static defaultProps = {
    onUpdated: function() {},
    isSetupMode: true
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    onUpdated: propTypes.func,
    isSetupMode: propTypes.bool
  }

  componentDidMount() {
    if(this.props.preloadedEndpoint) {
      this.props.endpointFastPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointFastRead(this.props.endpointId);
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props;
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointFastRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  handleUpdate = (credentials = {}) => {
    return this.props.endpointFastUpdateCredentials(this.props.endpointId, credentials)
      .then(getResponseToFormErrorHandler())
      .then(result => this.props.endpointFastEditCredentials(false));
  }

  handleTest = ev => {
    return this.props.endpointFastTestCredentials(this.props.endpointId);
  }

  handleClickEdit = ev => {
    this.props.endpointFastEditCredentials(true);
  }

  dataToFields(data = {}) {
    return {
      apiUrl: data.apiUrl || '',
      username: data.username || '',
      password: data.password || ''
    };
  }

  renderForm() {
    const { endpoint, isReading, isSetupMode, isEditing, isTesting, isUpdating, error } = this.props;
    if(isReading || !endpoint) return null;

    if(isSetupMode || isEditing) {
      return (
        <EndpointFastForm
          isDisabled={false}
          initialValues={this.dataToFields(endpoint.settings)}
          isEditing={isEditing}
          endpointState={endpoint.state}
          onEdit={this.handleClickEdit}
          onSubmit={this.handleUpdate}
          onTest={this.handleTest}
          isTesting={isTesting}
          isUpdating={isUpdating}
          remoteError={error}
        />
      );
    } else {
      const { testOk, testTimestamp } = endpoint.state;
      return (
        <div>
          {!isSetupMode && <EndpointTestButton
            isTesting={isTesting}
            onTest={this.handleTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
          />}{' '}
          <button type="button" className="btn btn-default pull-right" onClick={this.handleClickEdit}>
            <i className="icon icon-left mdi mdi-edit"></i>
            {' '}Edit credentials
          </button>
        </div>
      );
    }
  }

  renderStatusAlert() {
    const { endpoint } = this.props;
    if(endpoint && endpoint.status === 'error') {
      return (
        <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
          <strong>Error:</strong> There is a problem with this endpoint.
        </AlertWithMessage>
      )
    }
    return null;
  }

  render() {
    const { isReading, error } = this.props;
    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              Fast API
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_fast_credentials_help'}}
              />
            </div>
            <span className="panel-subtitle">Enter and test your Fast API credentials below</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading loading={isReading} className="xs-p-20">
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {this.renderStatusAlert()}
            {this.renderForm()}
          </BeLoading>
        </div>
      </div>
    );
  }

}

const actions = {
  ...fastActions
};

export default connect(state => state.endpoint.fast, actions)(EndpointFast);
