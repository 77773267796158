import React, { Component } from 'react';
import { EndpointGraphRemoteSearcher } from 'client/components/Form/Select2Searchers';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';

export default class GRTDTesterAddUser extends Component {

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    userId: propTypes.string,
    onAdd: propTypes.func,
    onChange: propTypes.func,
    isAdding: propTypes.bool
  }

  handleClick = ev => {
    this.props.onAdd(this.props.userId);
  }

  render() {
    const { endpointId, userId, isAdding, onChange } = this.props;
    return (
      <div className="input-group xs-mb-15">
        <EndpointGraphRemoteSearcher
          id="grtdTesterSelectUser" 
          name="userId"
          endpointId={endpointId}
          forWhat="user"
          onChange={onChange}
          options={{allowClear: true, placeholder: 'Search user name starting with'}}
          defaultValue={userId}
        />
        <span className="input-group-btn">
          <ButtonWaiting disabled={!userId} onClick={this.handleClick} type="button" className="btn btn-primary" isWaiting={isAdding}>Add Graph user</ButtonWaiting>
        </span>
      </div>
    );
  }

}
