import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceFatdCall = (types, id, method, data = {}) => {
  return apiAction({
    endpoint: `/api/instance/fatd/${id}/call/${method}`,
    method: 'POST',
    json: data,
    types
  });
};

export const instanceFatdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/fatd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceFatdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/fatd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceFatdTestObjectAdd = (types, id, objectType, objectId) => {
  const meta = {objectType, objectId};
  return apiAction({
    endpoint: `/api/instance/fatd/${id}/test/${objectType}/${objectId}`,
    method: 'GET',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceFatdTestObjectGraph = (types, id, objectType, objectId) => {
  const meta = {objectType, objectId};
  return apiAction({
    endpoint: `/api/instance/fatd/${id}/test/${objectType}/${objectId}/objectGraph`,
    method: 'GET',
    types: types.map(type => ({type, meta})),
    options: { timeout: 600000 },
  });
};

export const instanceFatdSyncObject = (types, id, objectType, objectId) => {
  const meta = {objectType, objectId, sync: true};
  return apiAction({
    endpoint: `/api/instance/fatd/${id}/sync/${objectType}/${objectId}`,
    method: 'POST',
    types: types.map(type => ({type, meta})),
    options: { timeout: 600000 },
  });
};

export const instanceFatdTestObjectRemove = (type, objectType, objectId) => {
  return {type, meta: {objectType, objectId}};
};

export const instanceFatdObjectTestClear = type => {
  return {type};
};

export const instanceFatdObjectSearchFieldChange = (type, objectType, objectId) => {
  return {type, meta: {objectType, objectId}};
};
