import { USER_INVOICE_INDEX, USER_INVOICE_INDEX_ORDERBY, USER_INVOICE_INDEX_SET_PAGE, USER_INVOICE_INDEX_FILTER, USER_INVOICE_DELETE } from 'client/redux/user/invoice/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isDeleting: false,
  invoices: null,
  filter: {userId: '', type: '', interval: '', paranoid: '1'},
  page: 1,
  orderBy: 'updatedAt',
  orderDirection: 'DESC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(USER_INVOICE_INDEX, state, action, {
    requestProp: 'isReading', 
    successPickProps: ['invoices', 'count', 'limit', 'offset', 'page', 'pages']
  });
  state = reduceByTypes(USER_INVOICE_DELETE, state, action, {requestProp: 'isDeleting'});
  state = reduceTableActions([USER_INVOICE_INDEX_FILTER, USER_INVOICE_INDEX_SET_PAGE, USER_INVOICE_INDEX_ORDERBY], state, action);
  return state;
};
