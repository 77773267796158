import * as actionCreators from 'client/redux/instance/actionCreators';
import * as constants  from 'client/redux/instance/constants';

export const instanceOptionalEndpointSetEndpointType = endpointType => dispatch => {
  return dispatch(actionCreators.instanceOptionalEndpointSetEndpointType(constants.INSTANCE_OPTIONAL_ENDPOINT_SET_ENDPOINT_TYPE, endpointType));
}

export const instanceOptionalEndpointCreate = (instanceId, endpointType) => dispatch => {
  return dispatch(actionCreators.instanceOptionalEndpointCreate(constants.INSTANCE_OPTIONAL_ENDPOINT_CREATE, instanceId, endpointType));
};

export const instanceOptionalEndpointDelete = (instanceId, endpointType) => dispatch => {
  return dispatch(actionCreators.instanceOptionalEndpointDelete(constants.INSTANCE_OPTIONAL_ENDPOINT_DELETE, instanceId, endpointType));
};
