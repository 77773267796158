import React, { Component } from 'react';
import propTypes from 'prop-types';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import SupportButtonDirection from 'client/components/Support/components/SupportButtonDirection';
import classNames from 'classnames';
import { Placeholder } from 'client/components/Helpers/Strings';

export default class SupportCategoryHeader extends Component {

  static propTypes = {
    category: propTypes.object.isRequired,
    onCreateQuestion: propTypes.func.isRequired,
    onUpdateCategory: propTypes.func.isRequired,
    onDeleteCategory: propTypes.func.isRequired,
    isLastCategory: propTypes.bool.isRequired,
    isFirstCategory: propTypes.bool.isRequired
  }

  state = {
    isEditing: false,
    title: ''
  }

  handleClickEdit = ev => {
    this.setState({isEditing: true}, () => {
      if(this.elInput) {
        this.elInput.focus();
        this.elInput.select();
      }
    });
  }

  handleUpdate = ev => {
    const { category, onUpdateCategory } = this.props;
    const update = {title: this.state.title};
    return onUpdateCategory(category.id, update).then(() => {
      setTimeout(() => {
        this.setState({isEditing: false});
      }, 1);
    });
  }

  handleChange = ev => {
    const updateState = {[ev.target.name]: ev.target.value};
    this.setState(updateState);
  }

  componentDidMount() {
    const { category } = this.props;
    this.setState({title: category.title});
  }

  renderEditButtons() {
    if(this.state.isEditing) {
      return (
        <ButtonWaitingPromise className="btn btn-success btn-icon" onClick={this.handleUpdate}>
          <i className="icon mdi mdi-check"></i>{' '}
          Save
        </ButtonWaitingPromise>
      )
    } 
    return (
      <button className="btn btn-default btn-icon" onClick={this.handleClickEdit}>
        <i className="icon mdi mdi-edit"></i>
      </button>
    );
  }

  renderTitle() {
    const { isEditing, title } = this.state;
    const { category } = this.props;

    if(isEditing) {
      return (
        <input
          className="form-control input-xs"
          type="text"
          name="title"
          ref={el => { this.elInput = el; }}
          value={title}
          placeholder="Enter title"
          onChange={this.handleChange}
        />
      );
    }

    return (
      <h3 className="xs-m-0" id={category.slug}>
        <Placeholder placeholder={<em>(No title)</em>}>
          {category.title}
        </Placeholder>
      </h3>
    );
  }

  render() {
    const { category, onCreateQuestion, onUpdateCategory, onDeleteCategory, className, isLastCategory, isFirstCategory } = this.props;
    const classes = classNames(className, 'row');
    return (
      <div className={classes}>
        <div className="col-lg-8 xs-mb-10">
          {this.renderTitle()}
        </div>
        <IsAdmin>
          <div className="col-lg-4 xs-mb-10 text-right">
            {this.renderEditButtons()}{' '}
            <ButtonWaitingPromise className="btn btn-default btn-icon" onClick={ev => onCreateQuestion(category.id)}>
              <i className="icon mdi mdi-plus"></i>{' '}
              Question
            </ButtonWaitingPromise>{' '}
            <SupportButtonDirection
              direction="up"
              disabled={isFirstCategory}
              onClick={ev => onUpdateCategory(category.id, {order: category.order - 1})}
            />{' '}
            <SupportButtonDirection
              direction="down"
              disabled={isLastCategory}
              onClick={ev => onUpdateCategory(category.id, {order: category.order + 1})}
            />{' '}
            <ButtonWaitingPromise className="btn btn-default btn-icon" onClick={ev => onDeleteCategory(category.id)}>
              <i className="icon mdi mdi-delete"></i>
            </ButtonWaitingPromise>
          </div>
        </IsAdmin>
      </div>
    );
  }

}
