import React from 'react';
import { StripeProvider } from 'react-stripe-elements';

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;

export const StripeContext = React.createContext(null);

export class StripeContextLoader extends React.Component {

  state = {
    stripe: null,
  }

  componentDidMount() {
    if(window.Stripe) {
      this.setState({stripe: window.Stripe(stripeApiKey)});
    } else {
      document.querySelector('#stripe-head-script').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(stripeApiKey)});
      });
    }
  }

  render() {
    const { children } = this.props;
    const { stripe } = this.state;
    return (
      <StripeContext.Provider value={stripe}>
        <StripeProvider stripe={stripe}>
          {children}
        </StripeProvider>
      </StripeContext.Provider>
    );
  }
}
