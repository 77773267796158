import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const connector = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/connector', query),
    method: 'GET',
    types
  });
};

export const connectorRead = (types, connectorId) => {
  return apiAction({
    endpoint: `/api/connector/${connectorId}`,
    method: 'GET',
    types
  });
};

export const connectorUpdate = (types, connectorId, json = {}) => {
  return apiAction({
    endpoint: `/api/connector/${connectorId}`,
    method: 'PATCH',
    json,
    types
  });
};

export const connectorInstantiate = (types, connectorId) => {
  return apiAction({
    endpoint: `/api/connector/${connectorId}/instantiate`,
    method: 'POST',
    json: {},
    types
  });
};

export const connectorPlan = (types, connectorId) => {
  return apiAction({
    endpoint: `/api/connector/${connectorId}/plan`,
    method: 'GET',
    types
  });
};
