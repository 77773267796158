import React  from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import * as testFileActions from 'client/redux/fstd/testFileActions';
import { connect } from 'react-redux';
import ArrayTable from 'client/components/Helpers/ArrayTable';
import FileUpload from 'client/components/Form/FileUpload';

class FSTDTestFile extends React.Component {

  static defaultProps = {
    disabled: false
  }

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    return this.props.instanceFstdGetTestFile(instanceId);
  }

  getUploadUrl() {
    const { instanceId } = this.props;
    return `/api/instance/fstd/${instanceId}/test/file`;
  }

  handleUploaded = (err, responseBody) => {
    if(err) return this.props.instanceFstdTestFileUploadError(err);
    const testFile = _.get(responseBody, 'data.testFile', null);
    return this.props.instanceFstdTestFileUploadSuccess(testFile);
  }

  handleDelete = () => {
    const { instanceId } = this.props;
    return this.props.instanceFstdDeleteTestFile(instanceId);
  }

  renderTestFile() {
    const { testFile } = this.props;
    if(!testFile) return null;
    const { data, statistics } = testFile;
    return (
      <div>
        {statistics && <p className="statistics">The uploaded file had <strong>{statistics.rows} rows </strong> and <strong>{statistics.cols} columns</strong>. If this is incorrect, please check your parse settings.</p>}
        <ArrayTable className="table-condensed table-bordered table-striped" data={data} />
      </div>
    );
  }

  render() {
    const { isReading, error, testFile, testFileError } = this.props;
    return (
      <div className="fstd-test-file">
        <BeLoading loading={isReading}>
          <BeRemoteErrorAlert error={error} />
          <InstanceHelpHeader
            subTitle="You can upload a test file to to help you pick fields and test parse options. Only the first three rows can be used."
            helpFragmentId="instance_fstd_settings_testfile_help"
          >
            Upload Test File
          </InstanceHelpHeader>
          <div>
            <FileUpload
              id="instanceFstdTestFileUpload"
              name="file"
              error={testFileError}
              currentFileName={testFile ? testFile.originalName : undefined}
              url={this.getUploadUrl()}
              onUploaded={this.handleUploaded}
              onDelete={this.handleDelete}
            />
          </div>
          {this.renderTestFile()}
        </BeLoading>
      </div>
    );
  }
}

const actions = {
  ...testFileActions,
};

export default connect(state => state.fstd.testFile, actions)(FSTDTestFile);
