import {
  INSTANCE_QRTD_OPTIONS_READ_META,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_READ,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE,
  INSTANCE_QRTD_OPTIONS_SET_SELECTED_FIELD_MAP_ORDINAL,
} from 'client/redux/qrtd/constants';
import {
  instanceQrtdReadMeta,
  instanceQrtdFieldMapCreate,
  instanceQrtdFieldMapDelete,
  instanceQrtdFieldMapUpdate,
  instanceQrtdInfoTextRead,
  instanceQrtdInfoTextCreate,
  instanceQrtdInfoTextDelete,
  instanceQrtdInfoTextUpdate,
} from 'client/redux/qrtd/actionCreators';

export const instanceQrtdOptionsReadMeta = instanceId => dispatch => dispatch(instanceQrtdReadMeta(INSTANCE_QRTD_OPTIONS_READ_META, instanceId));

export const instanceQrtdOptionsFieldMapCreate = (instanceId, title) => dispatch => dispatch(instanceQrtdFieldMapCreate(INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE, instanceId, title));

export const instanceQrtdOptionsFieldMapUpdate = (instanceId, ordinal, title) => dispatch => dispatch(instanceQrtdFieldMapUpdate(INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE, instanceId, ordinal, title));

export const instanceQrtdOptionsFieldMapDelete = (instanceId, ordinal) => dispatch => dispatch(instanceQrtdFieldMapDelete(INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE, instanceId, ordinal));

export const instanceQrtdOptionsSetSelectedFieldMapOrdinal = ordinal => dispatch => dispatch({
  type: INSTANCE_QRTD_OPTIONS_SET_SELECTED_FIELD_MAP_ORDINAL,
  ordinal,
});

export const instanceQrtdOptionsInfoTextRead = (instanceId) => dispatch => dispatch(instanceQrtdInfoTextRead(INSTANCE_QRTD_OPTIONS_INFOTEXT_READ, instanceId));

export const instanceQrtdOptionsInfoTextCreate = (instanceId, create) => dispatch => dispatch(instanceQrtdInfoTextCreate(INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE, instanceId, create));

export const instanceQrtdOptionsInfoTextUpdate = (instanceId, ordinal, update) => dispatch => dispatch(instanceQrtdInfoTextUpdate(INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE, instanceId, ordinal, update));

export const instanceQrtdOptionsInfoTextDelete = (instanceId, ordinal) => dispatch => dispatch(instanceQrtdInfoTextDelete(INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE, instanceId, ordinal));
