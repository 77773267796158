export const NAVBAR_USER_NOTIFICATIONS_SHOW = 'NAVBAR_USER_NOTIFICATIONS_SHOW';

export const NAVBAR_USER_SUPPORT_FORM_SHOW = 'NAVBAR_USER_SUPPORT_FORM_SHOW';

export const NAVBAR_USER_SUPPORT_FORM_SUBMIT_REQUEST = 'NAVBAR_USER_SUPPORT_FORM_SUBMIT_REQUEST';
export const NAVBAR_USER_SUPPORT_FORM_SUBMIT_SUCCESS = 'NAVBAR_USER_SUPPORT_FORM_SUBMIT_SUCCESS';
export const NAVBAR_USER_SUPPORT_FORM_SUBMIT_FAILURE = 'NAVBAR_USER_SUPPORT_FORM_SUBMIT_FAILURE';
export const NAVBAR_USER_SUPPORT_FORM_SUBMIT = [NAVBAR_USER_SUPPORT_FORM_SUBMIT_REQUEST, NAVBAR_USER_SUPPORT_FORM_SUBMIT_SUCCESS, NAVBAR_USER_SUPPORT_FORM_SUBMIT_FAILURE];

export const NAVBAR_COLOR_SET = 'NAVBAR_COLOR_SET';

export const NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_REQUEST = 'NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_REQUEST';
export const NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_SUCCESS = 'NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_SUCCESS';
export const NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_FAILURE = 'NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_FAILURE';
export const NAVBAR_USER_NOTIFICATIONS_HAS_SEEN = [NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_REQUEST, NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_SUCCESS, NAVBAR_USER_NOTIFICATIONS_HAS_SEEN_FAILURE];

