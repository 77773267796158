import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import FATDIOptionsForm from 'client/components/FATDI/components/FATDIOptionsForm';
import FATDITester from 'client/components/FATDI/components/FATDITester';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import { connect } from 'react-redux';
import { instanceFatdiObjectTestClear } from 'client/redux/fatdi/testActions';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class FATDISetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  componentWillUnmount() {
    // clear everything from the test when we unmount
    this.props.instanceFatdiObjectTestClear();
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Fast API WP 1 → TOPdesk Incidents</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={FATDIOptionsForm}
            endpointOrder={['fast', 'topdesk']}
            TesterComponent={FATDITester}
          />
        </MainContent>
      </Content>
    );
  }

}

export default connect(null, { instanceFatdiObjectTestClear })(FATDISetup);
