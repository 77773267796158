import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { instanceGrtdReadMeta } from 'client/redux/grtd/metaActions';
import UIFragmentHelpButton from 'client/components/UIFragment/UIFragmentHelpButton';
import BeLoading from 'client/components/Be/BeLoading';
import { topdeskFieldNameToNatural } from 'client/redux/field/constants';
import { escapeDottedName } from 'client/redux/formHelpers';
import { Field } from 'redux-form';
import FieldMapFormComponent from 'client/components/FieldMap/FieldMapFormComponent';

class GRTDOptionsFormFieldMap extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    endpointIds: propTypes.object.isRequired,
    disabled: propTypes.bool,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    this.props.instanceGrtdReadMeta(instanceId);
  }

  getAvailableSources() {
    const { graphFields: { user:graphFields }, endpointIds: { graphId } } = this.props;
    return [
      {type: 'FieldSourceGraphUser', graphFields},
      {type: 'FieldSourceGraphGroupMember', graphId},
      {type: 'FieldSourceToken'},
    ];
  }

  renderFieldMapFor(settingName, fieldMap) {
    const { disabled, endpointIds } = this.props;
    if(!fieldMap) return null;

    const { targets = [], defaultTargetToSourceMap } = fieldMap;

    return targets.map(target => {
      const { name } = target;
      const defaultSource = defaultTargetToSourceMap[name];
      return (
        <Field
          key={name}
          name={`${settingName}.${escapeDottedName(name)}`}
          base={`grtdOptionsFieldMap${settingName}`}
          component={FieldMapFormComponent}
          label={topdeskFieldNameToNatural(name)}
          disabled={disabled}
          target={target}
          defaultSource={defaultSource}
          endpointIds={endpointIds}
          availableSources={this.getAvailableSources()}
        />
      );
    });
  }

  render() {
    const { isReading } = this.props;
    const fieldMap = this.props.fieldMap || {};
    return (
      <div>
        <div className="col-md-6">
          <h3 className="wizard-title xs-mb-5 md-mb-20">
            TOPdesk Person Field Map
            <UIFragmentHelpButton
              className="btn btn-rounded btn-default btn-xs pull-right"
              fragmentId="instance_grtd_settings_topdesk_fieldmap_help"
            />
            <span className="panel-subtitle">Determine how fields are set for Persons</span>
          </h3>
          <div className="form-horizontal">
            <BeLoading loading={isReading}>
              {this.renderFieldMapFor('topdeskPersonFieldSourceMap', fieldMap.person)}
            </BeLoading>
          </div>
        </div>
        <div className="col-md-6">
          <h3 className="wizard-title md-mb-20 xs-mb-5 xs-mt-20 md-mt-5">
            TOPdesk Operator Field Map
            <UIFragmentHelpButton
              className="btn btn-rounded btn-default btn-xs pull-right"
              fragmentId="instance_grtd_settings_topdesk_fieldmap_help"
            />
            <span className="panel-subtitle">Determine how fields are set for Operators</span>
          </h3>
          <div className="form-horizontal">
            <BeLoading loading={isReading}>
              {this.renderFieldMapFor('topdeskOperatorFieldSourceMap', fieldMap.operator)}
            </BeLoading>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => state.grtd.meta;
const actions = {instanceGrtdReadMeta};

export default connect(mapStateToProps, actions)(GRTDOptionsFormFieldMap);
