import { INTERNAL_ERROR, AUTH_ERROR, FORBIDDEN_ERROR } from 'client/redux/appError/constants';

const defaultState = {
  error: null,
  message: ''
};

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case FORBIDDEN_ERROR: return {
      ...state,
      error: action.error,
      message: 'Forbidden from accessing this resource'
    };
    case AUTH_ERROR: return {
      ...state,
      error: action.error,
      message: 'Authentication credentials not provided'
    };
    case INTERNAL_ERROR: return {
      ...state,
      error: action.error,
      message: 'Internal Error'
    };
    default:
      return state
  }
};
