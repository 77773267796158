import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames'

export default class Alert extends Component {

  static propTypes = {
    alertType: propTypes.oneOf(['success', 'primary', 'warning', 'danger']),
    icon: propTypes.bool,
    dismissible: propTypes.bool,
  }

  static defaultProps = {
    alertType: 'primary',
    icon: false,
    dismissible: false
  }

  state = {
    isDismissed: false
  }

  handleClickDismiss = ev => {
    this.setState({isDismissed: true});
  }

  render() {
    const {
      children,
      alertType,
      className,
      icon,
      dismissible,
    } = this.props;
    const classes = classNames('alert', 'alert-' + alertType, className, icon ? 'alert-icon' : null);
    if(this.state.isDismissed) return null;
    return (
      <div role="alert" className={classes}>
        {icon && <AlertIcon alertType={alertType} />}
        <div className="message">
          {dismissible && <AlertDismissButton onClick={this.handleClickDismiss} />}
          {children}
        </div>
      </div>
    );
  }
};

export const AlertIcon = ({alertType}) => {
  switch(alertType) {
    default: return null;
    case 'primary': return <span className="icon mdi mdi-info-outline"></span>;
    case 'success': return <span className="icon mdi mdi-check"></span>;
    case 'warning': return <span className="icon mdi mdi-alert-triangle"></span>;
    case 'danger': return <span className="icon mdi mdi-alert-octagon"></span>;
  }
};

export const AlertDismissButton = props => {
  const { onClick } = props;
  return (
    <button type="button" data-dismiss="alert" aria-label="Close" className="close" onClick={onClick}>
      <span aria-hidden="true" className="mdi mdi-close"></span>
    </button>
  );
};

export const AlertWithMessage = Alert;
