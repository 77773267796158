import _ from 'lodash';
import { USER_PANEL_READ, USER_PANEL_UPDATE, USER_PANEL_CALL } from 'client/redux/user/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isCalling: false,
  isReading: false,
  isUpdating: false,
  error: null,
  user: null,
};

export default function editPanelReducer(state = defaultState, action) {
  state = reduceByTypes(USER_PANEL_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(USER_PANEL_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(USER_PANEL_CALL, state, action, {
    requestProp: 'isCalling',
    successProp: (state, action, payload) => {
      switch(_.get(action, 'meta.method')) {
        default: return {...state, user: action.user};
        case 'customerStripeSync': {
          const stripeId = _.get(payload, 'user.stripeId');
          return {...state, user: {...state.user, stripeId}};
        }

      }
    },
  });
  return state;
};
