import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import ConnectedQRTDOptionsForm from 'client/components/QRTD/ConnectedQRTDOptionsForm';
import ConnectedQRTDCodeCreator from 'client/components/QRTD/ConnectedQRTDCodeCreator';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

export default class QRTDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>QR Codes → TOPdesk Incidents</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            stepComponents={{
              qrCodeCreator: props => <ConnectedQRTDCodeCreator {...props} />
            }}
            OptionsFormComponent={ConnectedQRTDOptionsForm}
            endpointOrder={['topdesk']}
          />
        </MainContent>
      </Content>
    );
  }

}
