import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

export default class GritterAlert extends Component {

  static propTypes = {
    closeAfter: propTypes.number.isRequired,
    className: propTypes.string,
    onDismiss: propTypes.func
  }

  static defaultProps = {
    closeAfter: 0,
    className: 'default',
    title: '',
    message: ''
  }

  handleClickDismiss = ev => {
    this.props.onDismiss();
  }

  componentDidMount() {
    if(this.props.closeAfter > 0) {
      setTimeout(this.props.onDismiss, this.props.closeAfter);
    }
  }

  render() {
    const classes = classNames(this.props.className, 'gritter-item-wrapper');
    const { title, message } = this.props;
    return (
      <div className={classes} role="alert">
        <div className="gritter-item">
          <div className="gritter-content gritter-without-image">
            <button onClick={this.handleClickDismiss} className="btn-link gritter-close">Close Notification</button>
            <span className="gritter-title">{title}</span>
            <p>{message}</p>
          </div>
        </div>
      </div>
    );
  }

}

