import * as actionCreators from 'client/redux/aitd/actionCreators';
import {
  INSTANCE_AITD_TEST_CLEAR,
  INSTANCE_AITD_TEST_INCIDENT_ADD,
  INSTANCE_AITD_TEST_INCIDENT_REMOVE,
  INSTANCE_AITD_SYNC_INCIDENT,
  INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH,
} from 'client/redux/aitd/constants';

export const instanceAitdTestIncidentAdd = (id, topdeskIncidentId) => dispatch => {
  return dispatch(actionCreators.instanceAitdTestIncidentAdd(INSTANCE_AITD_TEST_INCIDENT_ADD, id, topdeskIncidentId));
};

export const instanceAitdTestIncidentRemove = topdeskIncidentId => dispatch => {
  return dispatch(actionCreators.instanceAitdTestIncidentRemove(INSTANCE_AITD_TEST_INCIDENT_REMOVE, topdeskIncidentId));
};

export const instanceAitdTestObjectGraph = (id, topdeskIncidentId) => dispatch => {
  return dispatch(actionCreators.instanceAitdTestObjectGraph(INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH, id, topdeskIncidentId));
};

export const instanceAitdSyncIncident = (id, topdeskIncidentId) => dispatch => {
  return dispatch(actionCreators.instanceAitdSyncIncident(INSTANCE_AITD_SYNC_INCIDENT, id, topdeskIncidentId));
};

export const instanceAitdTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceAitdTestClear(INSTANCE_AITD_TEST_CLEAR));
};
