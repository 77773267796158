import React, { Component } from 'react';
import BeLoading from 'client/components/Be/BeLoading';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { instanceViewRead } from 'client/redux/instance/viewActions';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { connect } from 'react-redux';
import _ from 'lodash';
import AITDSetup from 'client/components/AITD/AITDSetup';
import ConnectedAITDModule from 'client/components/AITD/ConnectedAITDModule';
import FATDSetup from 'client/components/FATD/FATDSetup';
import FATDModule from 'client/components/FATD/FATDModule';
import FATDISetup from 'client/components/FATDI/FATDISetup';
import FATDIModule from 'client/components/FATDI/FATDIModule';
import GRTDSetup from 'client/components/GRTD/GRTDSetup';
import GRTDModule from 'client/components/GRTD/GRTDModule';
import FSTDSetup from 'client/components/FSTD/FSTDSetup';
import FSTDModule from 'client/components/FSTD/FSTDModule';
import OPTDSetup from 'client/components/OPTD/OPTDSetup';
import OPTDModule from 'client/components/OPTD/OPTDModule';
import SWTDSetup from 'client/components/SWTD/SWTDSetup';
import ConnectedSWTDModule from 'client/components/SWTD/ConnectedSWTDModule';
import QRTDSetup from 'client/components/QRTD/QRTDSetup';
import ConnectedQRTDModule from 'client/components/QRTD/ConnectedQRTDModule';
import MWTDSetup from 'client/components/MWTD/MWTDSetup';
import ConnectedMWTDModule from 'client/components/MWTD/ConnectedMWTDModule';
import PVTDSetup from 'client/components/PVTD/PVTDSetup';
import ConnectedPVTDModule from 'client/components/PVTD/ConnectedPVTDModule';

class InstanceView extends Component {

  getInstanceId(props) {
    return _.get(props || this.props, 'match.params.instanceId');
  }

  getTab(props) {
    return _.get(props || this.props, 'match.params.tab', 'auth');
  }

  getData(id) {
    return this.props.instanceViewRead(id);
  }

  componentDidMount() {
    this.getData(this.getInstanceId(this.props));
  }

  componentDidUpdate (prevProps) {
    const newInstanceId = this.getInstanceId();
    const oldInstanceId = this.getInstanceId(prevProps);
    if (newInstanceId !== oldInstanceId) {
      this.getData(newInstanceId);
    }
  }

  renderPlaceholder(loading, error) {
    return (
      <Content>
        <Helmet>
          <title>Instance View</title>
        </Helmet>
        <MainContent>
          <BeRemoteErrorAlert error={error} />
          <BeLoading positionStatic={true} loading={loading}></BeLoading>
        </MainContent>
      </Content>
    );
  }

  renderInstance(instance) {
    const { status, type, id } = instance;
    const tab = this.getTab();
    switch(true) {
      default: return null;
      case type === 'aitd' && status === 'setup': return <AITDSetup instanceId={id} />
      case type === 'fatd' && status === 'setup': return <FATDSetup instanceId={id} />
      case type === 'fatdi' && status === 'setup': return <FATDISetup instanceId={id} />
      case type === 'grtd' && status === 'setup': return <GRTDSetup instanceId={id} />
      case type === 'fstd' && status === 'setup': return <FSTDSetup instanceId={id} />
      case type === 'optd' && status === 'setup': return <OPTDSetup instanceId={id} />
      case type === 'swtd' && status === 'setup': return <SWTDSetup instanceId={id} />
      case type === 'qrtd' && status === 'setup': return <QRTDSetup instanceId={id} />
      case type === 'mwtd' && status === 'setup': return <MWTDSetup instanceId={id} />
      case type === 'pvtd' && status === 'setup': return <PVTDSetup instanceId={id} />
      case type === 'fatd': return <FATDModule instanceId={id} tab={tab} />
      case type === 'fatdi': return <FATDIModule instanceId={id} tab={tab} />
      case type === 'grtd': return <GRTDModule instanceId={id} tab={tab} />
      case type === 'fstd': return <FSTDModule instanceId={id} tab={tab} />
      case type === 'optd': return <OPTDModule instanceId={id} tab={tab} />
      case type === 'qrtd': return <ConnectedQRTDModule instanceId={id} tab={tab} />
      case type === 'mwtd': return <ConnectedMWTDModule instanceId={id} tab={tab} />
      case type === 'swtd': return <ConnectedSWTDModule instanceId={id} tab={tab} />
      case type === 'aitd': return <ConnectedAITDModule instanceId={id} tab={tab} />
      case type === 'pvtd': return <ConnectedPVTDModule instanceId={id} tab={tab} />
    }
  }

  render() {
    const { error, isReading, instance } = this.props;
    if(error || isReading || !instance || this.getInstanceId() !== instance.id) return this.renderPlaceholder(isReading, error);
    return this.renderInstance(instance);
  }

}

const actions = {
  instanceViewRead,
};

const mapStateToProps = state => ({
  ...state.instance.view,
});

export default connect(mapStateToProps, actions)(InstanceView);
