import { USER_SUBSCRIPTION_INDEX, USER_SUBSCRIPTION_INDEX_ORDERBY, USER_SUBSCRIPTION_INDEX_SET_PAGE, USER_SUBSCRIPTION_INDEX_FILTER, USER_SUBSCRIPTION_DELETE } from 'client/redux/user/subscription/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isDeleting: false,
  subscriptions: null,
  filter: {userId: '', type: '', interval: '', paranoid: '1'},
  page: 1,
  orderBy: 'updatedAt',
  orderDirection: 'DESC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(USER_SUBSCRIPTION_INDEX, state, action, {
    requestProp: 'isReading', 
    successPickProps: ['subscriptions', 'count', 'limit', 'offset', 'page', 'pages']
  });
  state = reduceByTypes(USER_SUBSCRIPTION_DELETE, state, action, {requestProp: 'isDeleting'});
  state = reduceTableActions([USER_SUBSCRIPTION_INDEX_FILTER, USER_SUBSCRIPTION_INDEX_SET_PAGE, USER_SUBSCRIPTION_INDEX_ORDERBY], state, action);
  return state;
};
