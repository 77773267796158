import React from 'react';
import { Field } from 'redux-form';
import { isFilled, isString } from 'client/redux/validators';
import { FormReduxInput, FormReduxSelect2 } from 'client/components/Form/FormRedux';
import CountryList from 'country-list';

const validateByField = {
  name: [isString(), isFilled()],
  line1: [isString(), isFilled()],
  line2: [isString()],
  city: [isString(), isFilled()],
  state: [isString()],
  zip: [isString(), isFilled()],
  country: [isString(), isFilled()]
}

export default class AccountAddressFormSection extends React.Component {

  constructor() {
    super();
    this.countryData = this.getCountryData();
  }

  getCountryData() {
    return CountryList().getData().map(({code, name}) => {
      return {id: code, text: name};
    });
  }

  fieldProps = {base: 'accountAddress', metaOptions: {ignorePristine: true, showSuccess: true}};

  render() {
    return (
      <div>
        <Field
          {...this.fieldProps}
          className="required"
          name="name"
          component={FormReduxInput}
          type="text"
          label="Name"
          placeholder="Your Name"
          validate={validateByField.name}
        />
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              className="required"
              name="line1"
              component={FormReduxInput}
              type="text"
              label="Address 1"
              placeholder="Your address"
              validate={validateByField.line1}
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              name="line2"
              component={FormReduxInput}
              type="text"
              label="Address 2"
              placeholder="Your address"
              validate={validateByField.line2}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Field
              {...this.fieldProps}
              className="required"
              name="city"
              component={FormReduxInput}
              type="text"
              label="City"
              placeholder="Your city"
              validate={validateByField.city}
            />
          </div>
          <div className="col-md-4">
            <Field
              {...this.fieldProps}
              name="state"
              component={FormReduxInput}
              type="text"
              label="State/Region"
              placeholder="Your state"
              validate={validateByField.state}
            />
          </div>
          <div className="col-md-4">
            <Field
              {...this.fieldProps}
              className="required"
              name="zip"
              component={FormReduxInput}
              type="text"
              label="Zip code"
              placeholder="Your zip"
              validate={validateByField.zip}
            />
          </div>
        </div>
        <Field
          {...this.fieldProps}
          className="required"
          name="country"
          component={FormReduxSelect2}
          data={this.countryData}
          label="Country"
          select2Options={{placeholder: 'Select your country', minimumResultsForSearch: 0, dropdownParent: '#accountAddCardFormCountryDropdownParent'}}
          validate={validateByField.country}
        />
      </div>
    );
  }

}
