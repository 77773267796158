import React from 'react';
import propTypes from 'prop-types';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';

export default class ObjectRow extends React.Component {

  static propTypes = {
    object: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
    subject: propTypes.string.isRequired,
  }

  render() {
    const {
      subject,
      object,
    } = this.props;
    return (
      <tr>
        <td><span className="label label-primary">FOUND</span></td>
        <td>{subject}</td>
        <td className="text-right">
          <ModalButton
            className="btn btn-default btn-xs"
            ModalComponent={ModalFormattedObject}
            modalProps={{object, title: subject, inspector: 'object'}}
          >
            <i className="icon mdi mdi-search" style={{marginRight: 0}}></i>
            {' '}Details
          </ModalButton>
        </td>
      </tr>
    );
  }

}
