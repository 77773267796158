import {
  INSTANCE_FSTD_FIELDMAPPER_NEW,
  INSTANCE_FSTD_FIELDMAPPER_NEW_DELETE,
  INSTANCE_FSTD_FIELDMAPPER_EDIT,
  INSTANCE_FSTD_FIELDMAPPER_SAVE,
  INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS,
  INSTANCE_FSTD_FIELDMAPPER_DELETE,
  INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS,
  INSTANCE_FSTD_FIELDMAPPER_READ,
  INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS,
} from 'client/redux/fstd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import _ from 'lodash';

// when action is one of these types, reduceFieldMaps should be the main
// reducer and all of the actions should have meta property fileName and assetTemplateId
const singleFieldMapTypes = [
  INSTANCE_FSTD_FIELDMAPPER_EDIT,
  ...INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS,
  ...INSTANCE_FSTD_FIELDMAPPER_SAVE,
  ...INSTANCE_FSTD_FIELDMAPPER_DELETE,
];

const defaultState = {
  isReading: false,
  fileMap: [],
  error: null,
};

const defaultFieldMapState = {
  isNew: false,
  isEditing: false,
  isDeleting: false,
  isSaving: false,
  apiError: null,
  fileName: null,
  assetTemplateId: null,
  map: null,
  links: null,
  assignments: null,
  isReadingFieldTargets: false,
  fieldTargets: null,
};

// reduce a single field map
export function reduceFieldMap(state = defaultFieldMapState, action) {

  state = reduceByTypes(INSTANCE_FSTD_FIELDMAPPER_SAVE, state, action, {
    requestProp: 'isSaving',
    successPickProps: ['fileName', 'assetTemplateId', 'map', 'links', 'assignments', 'settings'],
    errorProp: 'apiError',
  });

  state = reduceByTypes(INSTANCE_FSTD_FIELDMAPPER_DELETE, state, action, {
    requestProp: 'isDeleting',
    successPickProps: ['map', 'links', 'assignments', 'settings'],
    errorProp: 'apiError',
  });

  state = reduceByTypes(INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS, state, action, {
    requestProp: 'isReadingFieldTargets',
    successPickProps: ['fieldTargets'],
    errorProp: 'apiError'
  });

  if(action.type === INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS) {
    state = {...state, isNew: false};
  }

  if(action.type === INSTANCE_FSTD_FIELDMAPPER_EDIT) {
    state = {...state, isEditing: Boolean(action.isEditing)};
  }

  return state;
};

// reduce a list of field maps
export function reduceFieldMaps(state = [], action) {
  const { meta: { fileName, assetTemplateId } } = action;
  let index = _.findIndex(state, _.matches({fileName, assetTemplateId}));
  if(index === -1) index = state.length - 1;

  if(action.type === INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS) {
    const newState = state.slice(0);
    newState.splice(index, 1);
    return newState;
  }

  const object = reduceFieldMap(state[index], action);
  if(state[index] !== object) {
    state[index] = object;
    return [...state];
  }

  return state;
}

export default function fieldMapperReducer(state = defaultState, action) {
  if(singleFieldMapTypes.includes(action.type)) {
    const fileMap = reduceFieldMaps([...state.fileMap], action);
    return {
      ...state,
      fileMap,
    };
  }

  state = reduceByTypes(INSTANCE_FSTD_FIELDMAPPER_READ, state, action, {
    requestProp: 'isReading',
    successProp: (state, action, response) => ({
      ...state,
      isReading: false,
      fileMap: (response.fileMap || []).map((fileMap, index) => {
        // we do this contorted merge because we would like to preserve isEditing
        // between navigations i.e. component mounts/unmounts 
        const previousMatching = _.get(state, 'fileMap', []).find(({assetTemplateId, fileName}) => {
          return assetTemplateId === fileMap.assetTemplateId && fileName === fileMap.fileName;
        }) || {};
        return {
          ...previousMatching,
          ...fileMap,
          isEditing: previousMatching.isEditing || false,
        };
      }),
    }),
  });

  switch(action.type) {
    default: return state;
    case INSTANCE_FSTD_FIELDMAPPER_NEW: return {
      ...state,
      fileMap: [
        ...state.fileMap,
        {
          ...defaultFieldMapState,
          isEditing: true,
          isNew: true,
          fileName: _.isString(action.currentTestFileName) ? stripFileEnding(action.currentTestFileName) : '',
        },
      ],
    };
    case INSTANCE_FSTD_FIELDMAPPER_NEW_DELETE: return {
      ...state,
      fileMap: state.fileMap.filter(({isNew}) => !isNew),
    };
  }
};

function stripFileEnding(fileName) {
  const extname = _.last(fileName.split('.'));
  return fileName.slice(0, -1 * (extname.length + 1));
}
