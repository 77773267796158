import * as actionCreators from 'client/redux/uiFragment/actionCreators';
import { UI_FRAGMENTS_INDEX, UI_FRAGMENTS_INDEX_DELETE } from 'client/redux/uiFragment/constants';

export const uiFragmentIndex = () => dispatch => {
  return dispatch(actionCreators.uiFragmentIndex(UI_FRAGMENTS_INDEX));
};

export const uiFragmentIndexDelete = id => dispatch => {
  return dispatch(actionCreators.uiFragmentDelete(UI_FRAGMENTS_INDEX_DELETE, id));
};
