import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const uiProps = (types, query = {}) => {
  return apiAction({
    endpoint: appendQuery('/api/ui/props', query),
    method: 'GET',
    types
  });
};

export const uiPropsSelf = (types, query = {}) => {
  return apiAction({
    endpoint: appendQuery('/api/ui/props/self', query),
    method: 'GET',
    types
  });
};

export const uiPropsLogin = (types) => {
  return apiAction({
    endpoint: '/api/ui/props/login',
    method: 'GET',
    types
  });
};
