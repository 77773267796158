import { reduceByTypes } from 'client/redux/apiHelpers';
import { DASHBOARD_NEWS_LATEST, DASHBOARD_NEWS_DELETE } from 'client/redux/dashboard/constants';

const defaultState = {
  isReading: false,
  isDeleting: false,
  newsItems: null,
  error: null
};

export default function newsReducer(state = defaultState, action) {
  state = reduceByTypes(DASHBOARD_NEWS_LATEST, state, action, {requestProp: 'isReading', successSpread: true, successPickProps: ['newsItems']});
  state = reduceByTypes(DASHBOARD_NEWS_DELETE, state, action, {requestProp: 'isDeleting', successProp: 'deleteResult'});
  return state;
};
