import $ from 'jquery';

export function setBodyClass(...classes) {
  const $body = $('body');
  for(const className of classes) {
    if(!$body.hasClass(className)) {
      $body.addClass(className);
    }
  }
}

export function removeBodyClass(...classes) {
  const $body = $('body');
  for(const className of classes) {
    if($body.hasClass(className)) {
      $body.removeClass(className);
    }
  }
}

export function clearBodyClass() {
  const $body = $('body');
  $body.attr('class', '');
}
