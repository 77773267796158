import React from 'react';
import propTypes from 'prop-types';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import {
  noticeObjectToNatural,
  noticeActionToNatural,
  pairingDescription,
} from 'client/redux/instance/log/constants';

export default class NoticeRow extends React.Component {

  static propTypes = {
    notice: propTypes.object.isRequired,
    subject: propTypes.string.isRequired,
    futureTense: propTypes.bool,
  }

  static defaultProps = {
    futureTense: false,
  }

  maybeRenderMetaModal(action) {
    const modalProps = {object: this.props.notice, title: action, inspector: 'object'};
    return (
      <ModalButton className="btn btn-default btn-xs" ModalComponent={ModalFormattedObject} modalProps={modalProps}>
        <i className="icon mdi mdi-search" style={{marginRight: 0}}></i>
        {' '}Details
      </ModalButton>
    );
  }

  render() {
    const {
      subject,
      futureTense,
      notice: { action, object, meta }
    } = this.props;

    const naturalObject = noticeObjectToNatural(object);
    const naturalAction = noticeActionToNatural(action);
    const tense = futureTense ? 'would be' : 'was';

    switch(action) {
      default: return (
        <tr>
          <td><span className="label label-primary">{naturalAction} {naturalObject}</span></td>
          <td>
            -
          </td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'REQUEST': {
        const { duration, method, status, url } = meta;
        return (
          <tr>
            <td>
              <span className="label label-primary">REQUEST</span>
              {' → '} 
              <span className="label label-primary">{object}</span>
            </td>
            <td>
              <code>
                {method} {urlToPath(url)}
              </code>
              <code style={{fontWeight: 'bold'}} className={statusCodeClassName(status)}>
                {status}
              </code>
              <code>{duration}ms</code>
            </td>
            <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
          </tr>
        );
      };

      case 'FOUND': return (
        <tr>
          <td><span className="label label-primary">{naturalAction} {naturalObject}</span></td>
          <td>An existing {naturalObject} was found</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'NOOP': {
        const { reason } = meta;
        return (
          <tr>
            <td><span className="label label-primary">{naturalAction} {naturalObject}</span></td>
            <td>A {naturalObject} {tense} ignored {maybeAppend(reason)}</td>
            <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
          </tr>
        );
      }

      case 'SKIPPED': {
        const { reason } = meta;
        return (
          <tr>
            <td><span className="label label-primary">{naturalAction} {naturalObject}</span></td>
            <td>A {naturalObject} {tense} skipped {maybeAppend(reason)}</td>
            <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
          </tr>
        );
      }

      case 'UNARCHIVED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} unarchived</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'ARCHIVED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} archived</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'ASSIGNED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} assigned</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'UNASSIGNED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} unassigned</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'LINKED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} linked</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'UNLINKED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} unlinked</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'CREATED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} created {subject ? ` based on ${subject}` : ''}</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'UPDATED': return (
        <tr>
          <td><span className="label label-success">{naturalAction} {naturalObject}</span></td>
          <td>A {naturalObject} {tense} updated {subject ? ` based on ${subject}` : ''}</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

      case 'PAIRED_WITH_PERSON':
      case 'PAIRED_WITH_OPERATOR': return (
        <tr>
          <td><span className="label label-warning">Paired {naturalObject}</span></td>
          <td>The Graph user {subject} {tense} paired with a previously created {pairingDescription(meta)}</td>
          <td className="text-right">{this.maybeRenderMetaModal(action)}</td>
        </tr>
      );

    }
  }

}

function maybeAppend(str) {
  if(!str) return '';
  return ` (${str})`;
}

function urlToPath (str) {
  const url = new URL(str);
  const tokens = [url.pathname, url.search].filter(v => v);
  return decodeURIComponent(tokens.join(''));
}

function statusCodeClassName (status) {
  if (!status || typeof status !== 'number') return 'text-primary';
  if (status < 300) return 'text-success';
  if (status < 400) return 'text-warning';
  return 'text-danger';
}
