import React from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/mwtd/testActions';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import MWTDTesterDevice from 'client/components/MWTD/MWTDTesterDevice';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';

class MWTDTester extends React.Component {

  state = {
    deviceId: '',
  }

  instanceId() {
    return _.get(this.props, 'instance.id');
  }

  handleAddDevice = () => {
    const { deviceId } = this.state;
    if(!deviceId) return;
    this.props.instanceMwtdTestDeviceAdd(this.instanceId(), deviceId).then(() => {
      this.setState({deviceId: ''});
    });
  }

  handleRemoveDevice = deviceId => {
    return this.props.instanceMwtdTestDeviceRemove(deviceId);
  }

  handleSyncDevice = deviceId => {
    return this.props.instanceMwtdSyncDevice(this.instanceId(), deviceId);
  }

  handleDeviceIdChange = ev => {
    this.setState({deviceId: ev.target.value});
  }

  render() {
    const {
      isAdding,
      devices,
      error,
    } = this.props;
    const { deviceId } = this.state;
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_mwtd_test_help" subTitle="Here you can simulate or test the options on real devices and incidents">
          Test Synchronization
        </InstanceHelpHeader>

        <BeRemoteErrorAlert error={error} />

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> Clicking "Sync" on an added Managed Workplace device below will create or update real data in TOPdesk.
          </div>
        </div>

        <div className="list-group">
          {devices.map(device => (
            <MWTDTesterDevice
              key={device.deviceId}
              id={device.deviceId}
              device={device}
              onRemove={this.handleRemoveDevice}
              onSync={this.handleSyncDevice}
            />
          ))}
        </div>

        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <div className="form-group">
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  name="deviceId"
                  value={deviceId}
                  onChange={this.handleDeviceIdChange}
                  placeholder="Enter a device ID in Managed Workplace"
                />
                <span className="input-group-btn">
                  <ButtonWaiting
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleAddDevice}
                    isWaiting={isAdding}
                  >
                    Add device
                  </ButtonWaiting>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const actions = {
  ...testActions,
};

export default connect(state => state.mwtd.test, actions)(MWTDTester);
