import * as actionCreators from 'client/redux/optd/actionCreators';
import {
  INSTANCE_OPTD_TEST_CLEAR,
  INSTANCE_OPTD_TEST_ALARM_ADD,
  INSTANCE_OPTD_TEST_ALARM_REMOVE,
  INSTANCE_OPTD_TEST_ALARM,
  INSTANCE_OPTD_SYNC_ALARM,
  INSTANCE_OPTD_TEST_ALARM_SEARCH_FIELD_CHANGE,
} from 'client/redux/optd/constants';

export const instanceOptdTestAlarmAdd = (id, alarmId) => dispatch => {
  return dispatch(actionCreators.instanceOptdTestAlarmAdd(INSTANCE_OPTD_TEST_ALARM_ADD, id, alarmId));
};

export const instanceOptdTestAlarmRemove = alarmId => dispatch => {
  return dispatch(actionCreators.instanceOptdTestAlarmRemove(INSTANCE_OPTD_TEST_ALARM_REMOVE, alarmId));
};

export const instanceOptdTestAlarm = (id, alarmId) => dispatch => {
  return dispatch(actionCreators.instanceOptdTestAlarm(INSTANCE_OPTD_TEST_ALARM, id, alarmId));
};

export const instanceOptdSyncAlarm = (id, alarmId) => dispatch => {
  return dispatch(actionCreators.instanceOptdSyncAlarm(INSTANCE_OPTD_SYNC_ALARM, id, alarmId));
};

export const instanceOptdTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceOptdTestClear(INSTANCE_OPTD_TEST_CLEAR));
};

export const instanceOptdSearchFieldChange = alarmId => dispatch => {
  return dispatch(actionCreators.instanceOptdSearchFieldChange(INSTANCE_OPTD_TEST_ALARM_SEARCH_FIELD_CHANGE, alarmId));
};
