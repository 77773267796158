import { combineReducers } from 'redux';

import index from 'client/redux/settings/indexReducer';
import panel from 'client/redux/settings/panelReducer';

const settingsReducer = combineReducers({
  index,
  panel
});

export default settingsReducer;
