import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { connect } from 'react-redux';
import { onFragmentDelete } from 'client/redux/uiFragment/eventHandlerActions';
import * as indexActions from 'client/redux/uiFragment/indexActions';
import UIFragmentTable from 'client/components/UIFragment/components/UIFragmentTable';

class UIFragmentIndex extends Component {

  componentDidMount() {
    return this.getData();
  }

  getData = () => {
    return this.props.uiFragmentIndex();
  }

  onDeleteFragment = id => {
    return this.props.uiFragmentIndexDelete(id).then(this.props.onFragmentDelete).then(this.getData);
  }

  renderTitle() {
    return (
      <Helmet>
        <title>UI Fragment Overview</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const { isReading, error, fragments } = this.props;
    if(error) return this.renderError(error);
    return (
      <Content>
        <MainContent>
          {this.renderTitle()}
          <UIFragmentTable
            loading={isReading}
            fragments={fragments}
            onRefresh={this.getData}
            onDeleteFragment={this.onDeleteFragment}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  ...indexActions,
  onFragmentDelete,
};

export default connect(state => state.uiFragment.index, actions)(UIFragmentIndex);
