import React  from 'react';
import _ from 'lodash';
import FileUpload from 'client/components/Form/FileUpload';
import propTypes from 'prop-types';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import * as imageUploadActions from 'client/redux/qrtd/imageUploadActions';
import { connect } from 'react-redux';
import 'client/components/QRTD/ConnectedQRTDImageUploadField.css';

class ConnectedQRTDImageUploadField extends React.Component {

  static defaultProps = {
    disabled: false,
  }

  static propTypes = {
    disabled: propTypes.bool.isRequired,
    instanceId: propTypes.string.isRequired,
    imageType: propTypes.string.isRequired,
    formId: propTypes.string.isRequired,
    formName: propTypes.string.isRequired,
  }

  componentDidMount() {
    const { instanceId, imageType } = this.props;
    return this.props.instanceQrtdImageUploadRead(instanceId, imageType);
  }

  handleUploaded = (err, body) => {
    const { imageType } = this.props;
    if(err) return this.props.instanceQrtdImageUploadError(imageType, err);
    return this.props.instanceQrtdImageUploadSuccess(imageType, body);
  }

  handleDelete = () => {
    const { instanceId, imageType } = this.props;
    return this.props.instanceQrtdImageUploadDelete(instanceId, imageType);
  }

  getUploadUrl() {
    const { instanceId, imageType } = this.props;
    return `/api/instance/qrtd/${instanceId}/settings/images/${imageType}`;
  }

  getFileName() {
    const { imageUrl } = this.props;
    if(!imageUrl) return undefined;
    return _.last(imageUrl.split('/'));
  }

  render() {
    const {
      label,
      error,
      imageUrl,
      isReading,
      uploadError,
      formId,
      helpText,
      formName,
      disabled,
    } = this.props;
    return (
      <div className="qrtd-image-container">
        <BeLoading loading={isReading}>
          <BeRemoteErrorAlert error={error} />
          <h4>
            {label}
          </h4>
          {helpText && (
            <h5>
              {helpText}
            </h5>
          )}
          {imageUrl && (
            <div className="qrtd-image-preview">
              <img src={imageUrl} alt={formName} style={{maxHeight: '150px'}} />
            </div>
          )}
          <div>
            <FileUpload
              error={uploadError}
              currentFileName={this.getFileName()}
              url={this.getUploadUrl()}
              onUploaded={this.handleUploaded}
              onDelete={this.handleDelete}
              disabled={disabled}
              id={formId}
              name={formName}
            />
          </div>
        </BeLoading>
      </div>
    );
  }

}

const mapStateToProps = (state, props) => _.get(state, ['qrtd', 'imageUpload', 'imagesByType', props.imageType], {});

const actions = {
  ...imageUploadActions,
};

export default connect(mapStateToProps, actions)(ConnectedQRTDImageUploadField)
