import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceAitdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/aitd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceAitdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/aitd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceAitdTestIncidentAdd = (types, id, topdeskIncidentId) => {
  const meta = {topdeskIncidentId};
  return apiAction({
    endpoint: `/api/instance/aitd/${id}/test/topdeskIncident/${topdeskIncidentId}`,
    method: 'GET',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceAitdTestObjectGraph = (types, id, topdeskIncidentId) => {
  const meta = {topdeskIncidentId};
  return apiAction({
    endpoint: `/api/instance/aitd/${id}/test/topdeskIncident/${topdeskIncidentId}/objectGraph`,
    method: 'GET',
    types: types.map(type => ({type, meta})),
    options: { timeout: 600000 },
  });
};

export const instanceAitdSyncIncident = (types, id, topdeskIncidentId) => {
  const meta = {topdeskIncidentId, sync: true};
  return apiAction({
    endpoint: `/api/instance/aitd/${id}/sync/topdeskIncident/${topdeskIncidentId}`,
    method: 'POST',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceAitdTestIncidentRemove = (type, topdeskIncidentId) => {
  return {type, meta: {topdeskIncidentId}};
};

export const instanceAitdTestClear = type => {
  return {type};
};
