import React from 'react';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';
import { showError } from 'client/redux/formHelpers';

export default function MaybeBeFormFieldError(props = {}) {
  if(!showError(props, props.metaOptions)) return null;
  return (
    <BeFormFieldErrors errors={[props.error]} />
  );
};
