import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceGrtdCall = (types, id, method, data = {}) => {
  return apiAction({
    endpoint: `/api/instance/grtd/${id}/call/${method}`,
    method: 'POST',
    json: data,
    types
  });
};

export const instanceGrtdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/grtd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceGrtdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/grtd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceGrtdTestUserAdd = (types, id, userId) => {
  return apiAction({
    endpoint: `/api/instance/grtd/${id}/test/graphUser/${userId}`,
    method: 'GET',
    types
  });
};

export const instanceGrtdTestUserRemove = (type, userId) => {
  return {type, userId};
};

export const instanceGrtdTestClear = type => {
  return {type};
};

export const instanceGrtdReloadUser = (types, id, userId) => {
  const meta = {userId, sync: false};
  return apiAction({
    endpoint: `/api/instance/grtd/${id}/test/graphUser/${userId}`,
    method: 'GET',
    types: types.map(type => { return {type, meta}; })
  });
};

export const instanceGrtdSyncUser = (types, id, userId) => {
  const meta = {userId, sync: true};
  return apiAction({
    endpoint: `/api/instance/grtd/${id}/sync/graphUser/${userId}`,
    method: 'POST',
    types: types.map(type => { return {type, meta}; })
  });
};
