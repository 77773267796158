import React from 'react';
import propTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import SwitchButton from 'client/components/Form/SwitchButton';
import Icon from 'client/components/Be/Icon';
import QRTDPrintCodeSettingsForm from 'client/components/QRTD/QRTDPrintCodeSettingsForm';

export default class QRTDPrintPageToolbox extends React.Component {

  static propTypes = {
    defaultCodeSettings: propTypes.object.isRequired,
    incidentFieldMaps: propTypes.array,
    infoTexts: propTypes.array,
    onChangeValue: propTypes.func.isRequired,
    onCreateCode: propTypes.func.isRequired,
    onDownloadCsv: propTypes.func.isRequired,
    renderQrCodes: propTypes.bool.isRequired,
    size: propTypes.number.isRequired,
  }

  handleChangeDefaultCodeSettingsValue = (key, value) => {
    const { defaultCodeSettings } = this.props;
    this.props.onChangeValue('defaultCodeSettings', {
      ...defaultCodeSettings,
      [key]: value,
    });
  }

  handleChangeSize = ev => {
    const size = parseInt(ev.target.value, 10);
    this.props.onChangeValue('size', size);
  }

  handleChangeRender = ev => {
    const { checked } = ev.target;
    this.props.onChangeValue('renderQrCodes', Boolean(checked));
  }

  render() {
    const {
      defaultCodeSettings,
      incidentFieldMaps,
      infoTexts = [],
      onCreateCode,
      onDownloadCsv,
      renderQrCodes,
      size,
    } = this.props;
    return (
      <div className="qrtd-print-page-container">
        <div className="panel panel-contrast panel-border xs-mt-20">
          <div className="panel-heading panel-heading-contrast xs-p-15">
            QR-Code Settings
            <span className="panel-subtitle xs-mb-0">
              Changing these settings will apply to all QR-codes below <em>except</em> the ones that have been edited
            </span>
          </div>
          <QRTDPrintCodeSettingsForm
            showTexts
            idBase="toolbox"
            className="xs-p-15"
            onChangeValue={this.handleChangeDefaultCodeSettingsValue}
            settings={defaultCodeSettings}
            incidentFieldMaps={incidentFieldMaps}
            infoTexts={infoTexts}
          />
        </div>

        <div className="panel panel-default panel-border xs-mt-10 xs-p-15">
          <div>
            <button className="btn btn-default" type="button" onClick={onCreateCode}>
              <Icon icon="plus" />{' '}
              Add QR-code without asset or location
            </button>{' '}
            <button className="btn btn-default" type="button" onClick={onDownloadCsv}>
              Download all codes as a CSV{' '}
              <Icon icon="download" />
            </button>
          </div>
          <div className="xs-mt-10 form-inline">
            <FormGroup className="form-group-sm xs-mr-15">
              <label className="control-label xs-mr-10" htmlFor="qrPrintPageSize">
                QR-code size
              </label>
              <select
                id="qrPrintPageSize"
                value={size}
                name="size"
                className="form-control"
                onChange={this.handleChangeSize}
              >
              <option value="64">64 QR-codes per page</option>
              <option value="49">49 QR-codes per page</option>
              <option value="36">36 QR-codes per page</option>
              <option value="25">25 QR-codes per page</option>
              <option value="16">16 QR-codes per page</option>
              <option value="9">9 QR-codes per page</option>
              <option value="4">4 QR-codes per page</option>
              <option value="2">2 QR-code per page</option>
              <option value="1">1 QR-code per page</option>


              </select>
            </FormGroup>
            <FormGroup className="form-group-sm">
              <label className="control-label xs-mr-10" htmlFor="qrtdPrintPageRenderQrCodesSwitch">
                Render QR-codes so they can be printed (slow)
              </label>{' '}
              <SwitchButton
                id="qrtdPrintPageRenderQrCodesSwitch"
                value={renderQrCodes}
                size="xs"
                name="renderQrCodes"
                onChange={this.handleChangeRender}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}
