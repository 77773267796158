import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import * as uiFragmentSingleActions from 'client/redux/uiFragment/singleActions';
import { get } from 'lodash';

class UIFragmentSingle extends Component {

  static propTypes = {
    fragment: propTypes.object,
    fragmentId: propTypes.string.isRequired,
  }

  getData(fragmentId) {
    return this.props.uiFragmentRead(fragmentId);
  }

  componentDidMount() {
    if(!this.props.fragment) {
      this.getData(this.props.fragmentId);
    }
  }

  componentDidUpdate (oldProps) {
    if(oldProps.fragmentId !== this.props.fragmentId) {
      this.getData(this.props.fragmentId);
    }
  }

  renderText(text) {
    const { className } = this.props;
    return <div className={className}>{text}</div>
  }

  render() {
    const { error, fragment, isReading, fragmentId, className } = this.props;
    if(error) return this.renderText('The text here could not be loaded');
    if(fragment && fragment.isNew) return this.renderText(`(The text "${fragmentId}" does not yet exist)`);
    if(isReading || !fragment) return null;
    return <div className={className} dangerouslySetInnerHTML={{__html: fragment.html}}></div>;
  }

}

const mapStateToProps = (state, props) => {
  const fragmentId = props.fragmentId;
  const fragment = get(state, ['uiFragment', 'single', 'fragments', fragmentId], {});
  return {...fragment};
}

const actions = {
  ...uiFragmentSingleActions,
};

export default connect(mapStateToProps, actions)(UIFragmentSingle);
