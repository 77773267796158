import React, { Component } from 'react';
import * as planPanelActions from 'client/redux/plan/panelActions';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { PLAN_PANEL_UPDATE_SUCCESS } from 'client/redux/plan/constants';
import PlanPanelForm from 'client/components/Plan/components/PlanPanelForm';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { gritterAlertPush } from 'client/redux/gritter/actions';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';

class PlanPanel extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    onUpdated: propTypes.func
  }

  componentDidMount() {
    this.props.planPanelRead(this.props.id);
  }

  componentWillUnmount() {
    this.props.planPanelClear();
  }

  handleUpdate = fields => {
    const { id, onUpdated } = this.props;
    return this.props.planPanelUpdate(id, fields).then(getResponseToFormErrorHandler()).then(result => {
      if(result.type !== PLAN_PANEL_UPDATE_SUCCESS) return;
      setTimeout(onUpdated, 1);
      return this.props.gritterAlertPush('planUpdateSuccess', {
        className: 'color success',
        title: 'Update complete',
        message: 'The plan was updated',
        closeAfter: 3000
      });
    });
  }

  getInitialFormValues() {
    const { plan: { isSubscribable, Connectors, type } } = this.props;
    const connectorIds = Connectors.map(c => c.id);
    return {isSubscribable, connectorIds, type};
  }

  render() {
    const { plan, isReading, error } = this.props;
    return (
      <BeLoading loading={isReading} positionStatic={true}>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        {plan && 
          <PlanPanelForm
            error={error}
            onSubmit={this.handleUpdate}
            initialValues={this.getInitialFormValues()}
          />
        }
      </BeLoading>
    );
  }

}

const actions = {
  ...planPanelActions,
  gritterAlertPush
};

export default connect(state => state.plan.panel, actions)(PlanPanel);
