import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ShortId } from 'client/components/Helpers/Strings';
import classNames from 'classnames';
import {
  TableThSort,
  TablePagination,
  TableShowCount,
  TableUserCell,
} from 'client/components/Helpers/Table';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import Time from 'client/components/Helpers/Time';
import BeLoading from 'client/components/Be/BeLoading';
import UserLogTableFilterForm from 'client/components/UserLog/components/UserLogTableFilterForm';
import LogLevelLabel from 'client/components/Helpers/LogLevelLabel';

class UserLogTable extends Component {

  static defaultProps = {
    logs: []
  }

  static propTypes = {
    logs: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onRefresh: propTypes.func,
    loading: propTypes.bool,
  }

  renderLogs() {
    const { logs } = this.props;
    if(!logs || !logs.length) return (
      <tr>
        <td className="empty" colSpan={7}>No logs to show</td>
      </tr>
    );
    return logs.map(log => {
      const { id, time, level, type, ip, User } = log;
      return (
        <tr key={id}>
          <td><Time time={time} format="YYYY-MM-DD HH:mm:ss" /></td>
          <td><ShortId id={id} /></td>
          <td><LogLevelLabel level={level} /></td>
          <td>{type}</td>
          <TableUserCell user={User} />
          <td>{ip}</td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: log, title: type}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>
            </div>
          </td>
        </tr>
      )
    })
  }

  renderTable() {
    const { orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                <TableThSort column="time" {...sortProps}>Time</TableThSort>
                <TableThSort column="id" {...sortProps}>ID</TableThSort>
                <TableThSort column="level" {...sortProps}>Level</TableThSort>
                <th>Type</th>
                <th>User</th>
                <th>IP</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderLogs()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['log', 'logs']} />
          </div>
        </div>
        <div className="col-md-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filter, onFilter } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of User Logs</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <UserLogTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

export default UserLogTable;
