import React, { Component } from 'react';
import { App, Content, MainContent } from 'client/components/App';
import { SIGNUP_SUCCESS } from 'client/redux/signup/constants';
import { SplashFooter, SplashHeader } from 'client/components/Branding';
import { setBodyClass } from 'client/redux/bodyClass';
import { Helmet } from 'react-helmet';
import { signup } from 'client/redux/signup/actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SignupForm from 'client/components/Signup/components/SignupForm';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';

class Signup extends Component {

  handleFormSubmit = fields => {
    return this.props.signup(fields).then(getResponseToFormErrorHandler()).then(action => {
      if(action.type === SIGNUP_SUCCESS) {
        return this.props.push('/');
      }
    });
  }

  render() {
    setBodyClass('be-splash-screen');
    return (
      <App className="be-login be-signup">
        <Helmet>
          <title>Sign up</title>
        </Helmet>
        <Content>
          <MainContent className="container-fluid">
            <div className="splash-container sign-up">
              <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
                <SplashHeader className="panel-heading">Please enter your user information to create a new account.</SplashHeader>
                <div className="panel-body">
                  <SignupForm onSubmit={this.handleFormSubmit} />
                </div>
              </div>
              <SplashFooter />
            </div>
          </MainContent>
        </Content>
      </App>
    );
  }
}

export default connect(state => state.signup, { signup, push })(Signup);
