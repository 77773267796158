import { apiAction } from 'client/redux/apiHelpers';

export const endpointAzureOpenAiRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/azureOpenAi/${id}`,
    method: 'GET',
    types
  });
};

export const endpointAzureOpenAiEditSettings = (type, isEditing) => {
  return {
    type,
    isEditing
  };
};

export const endpointAzureOpenAiUpdateSettings = (types, id, settings) => {
  return apiAction({
    endpoint: `/api/endpoint/azureOpenAi/${id}/credentials`,
    method: 'PATCH',
    types,
    json: settings
  });
};
