import * as actionCreators from 'client/redux/swtd/actionCreators';
import {
  INSTANCE_SWTD_TEST_CLEAR,
  INSTANCE_SWTD_TEST_COMPUTER_ADD,
  INSTANCE_SWTD_TEST_COMPUTER_REMOVE,
  INSTANCE_SWTD_SYNC_COMPUTER,
  INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH,
} from 'client/redux/swtd/constants';

export const instanceSwtdTestComputerAdd = (id, computerId) => dispatch => {
  return dispatch(actionCreators.instanceSwtdTestComputerAdd(INSTANCE_SWTD_TEST_COMPUTER_ADD, id, computerId));
};

export const instanceSwtdTestComputerRemove = computerId => dispatch => {
  return dispatch(actionCreators.instanceSwtdTestComputerRemove(INSTANCE_SWTD_TEST_COMPUTER_REMOVE, computerId));
};

export const instanceSwtdTestObjectGraph = (id, snowComputerId) => dispatch => {
  return dispatch(actionCreators.instanceSwtdTestObjectGraph(INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH, id, snowComputerId));
};

export const instanceSwtdSyncComputer = (id, computerId) => dispatch => {
  return dispatch(actionCreators.instanceSwtdSyncComputer(INSTANCE_SWTD_SYNC_COMPUTER, id, computerId));
};

export const instanceSwtdTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceSwtdTestClear(INSTANCE_SWTD_TEST_CLEAR));
};
