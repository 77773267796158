import React from 'react';
import { push } from 'connected-react-router';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, switchUserReturn } from 'client/redux/auth/actions';
import moment from 'moment';
import { Gravatar } from 'client/components/Helpers/Images'; 
import _ from 'lodash';
import 'client/components/App/Navbar/NavbarUser.css';

const {
  REACT_APP_VERSION_COMMIT:versionCommit = '',
  REACT_APP_VERSION_BRANCH:versionBranch = '',
  REACT_APP_VERSION_TIMESTAMP:versionTimestamp = 0,
} = process.env;

class NavbarUser extends React.Component {

  onClickLogout = ev => {
    return this.props.logout();
  }

  onClickUserReturn = ev => {
    return this.props.switchUserReturn().then(() => {
      return this.props.push('/user');
    });
  }

  maybeRenderReturnButton() {
    const { previousSelf } = this.props;
    if(!previousSelf) return null;
    return (
      <MenuItem onClick={this.onClickUserReturn}>
        <span className="icon mdi mdi-long-arrow-return"></span>
        Return to previous user
      </MenuItem>
    );
  }

  maybeRenderVersion() {
    const role = _.get(this.props, 'self.role', '');
    if(role !== 'admins') return null;
    const date = moment(versionTimestamp, 'X');
    const tokens = [
      date.isValid() && date.unix() > 0 ? date.format('Do MMM YYYY HH:mm') : null,
      versionBranch,
      versionCommit,
    ];
    return [
      <li key="divider"><hr className="final-divider" /></li>,
      <li key="version-info" role="presentation" className="xs-pl-20 xs-pr-20">
        <small>
          {_.compact(tokens).join(' / ')}
        </small>
      </li>,
    ];
  }

  render() {
    const { self = {}, parentUser } = this.props;
    const { role = '', displayName = '<empty>', hideBilling = false } = self || {};
    return (
      <ul className="nav navbar-nav navbar-right linknow-user-nav be-user-nav">
        <Dropdown id="nav-user-dropdown" componentClass="li">
          <Dropdown.Toggle useAnchor={true} noCaret={true}>
            <Gravatar user={self} />
            <span className="user-name">{displayName}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <li>
              <div className="user-info">
                <div className="user-name">
                  <div>
                    {role === 'admins' && <span className="icon mdi mdi-flower-alt"></span>}
                    {' '}{displayName}
                  </div>
                  {parentUser && (
                    <small className="xs-mt-5" style={{display: 'block', lineHeight: '1.25em'}}>
                      <strong>Parent user:</strong>
                      {parentUser.displayName && (<div>{parentUser.displayName}</div>)}
                      {parentUser.email && (
                        <div>{parentUser.email}</div>
                      )}
                    </small>
                  )}
                </div>
              </div>
            </li>
            <li role="presentation">
              <NavLink to="/account/settings">
                <span className="icon mdi mdi-settings"></span>
                Settings
              </NavLink>
            </li>
            {!parentUser && (
            <li role="presentation">
              <NavLink to="/account/children">
                <span className="icon mdi mdi-accounts-list"></span>
                Sub-Accounts
              </NavLink>
            </li>
            )}
            {!hideBilling && (
              <>
                <li role="presentation">
                  <NavLink to="/account/billing">
                    <span className="icon mdi mdi-money-box"></span>
                    Billing
                  </NavLink>
                </li>
                <li role="presentation">
                  <NavLink to="/account/subscriptions">
                    <span className="icon mdi mdi-group"></span>
                    Subscriptions
                  </NavLink>
                </li>
              </>
            )}
            {this.maybeRenderVersion()}
            <li><hr className="final-divider" /></li>
            {this.maybeRenderReturnButton()}
            <MenuItem onClick={this.onClickLogout}><span className="icon mdi mdi-power"></span> Logout</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </ul>
    );
  }

}

export default connect(state => state.ui, { logout, switchUserReturn, push })(NavbarUser);
