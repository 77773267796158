import * as constants from 'client/redux/endpoint/snow/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isEditing: false,
  isReading: false,
  isUpdating: false,
  isDeleting: false,
  isTesting: false,
  error: null,
  endpoint: null,
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(constants.ENDPOINT_SNOW_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(constants.ENDPOINT_SNOW_UPDATE_SETTINGS, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(constants.ENDPOINT_SNOW_TEST, state, action, {requestProp: 'isTesting', successSpread: true});
  switch (action.type) {
    default: return state
    case constants.ENDPOINT_SNOW_EDIT_SETTINGS: return {
      ...state,
      isEditing: action.isEditing,
    };
    case constants.ENDPOINT_SNOW_PRELOADED: return {
      ...state,
      isReading: false,
      endpoint: action.endpoint,
      error: null,
    };
  }
};
