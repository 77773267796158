import React from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { statusSelect2 } from 'client/redux/instance/constants';
import { ConnectorInstanceIdSearcher, UserIdSearcher, ConnectorIdSearcher } from 'client/components/Form/Select2Searchers';

export default class InstanceTableFilterForm extends React.Component {

  static propTypes = {
    filter: propTypes.object,
    onFilter: propTypes.func,
  }

  handleChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render() {
    const { id, status, userId, connectorId } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <ConnectorInstanceIdSearcher
              id="instanceTableFilterId"
              className="form-control"
              name="id"
              onChange={value => this.handleChange({target: {name: 'id', value}})}
              options={{minimumResultsForSearch: 0, allowClear: true, placeholder: 'Any instance', minimumInputLength: 0}}
              defaultValue={id || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="instanceTableFilterStatus"
              name="status"
              className="form-control"
              data={statusSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any status'}}
              onChange={this.handleChange}
              defaultValue={status || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <UserIdSearcher
              id="instanceTableFilterUserId"
              className="form-control"
              name="userId"
              onChange={val => this.handleChange({target: {name: 'userId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any user'}}
              defaultValue={userId || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <ConnectorIdSearcher
              id="instanceTableFilterConnectorId"
              className="form-control"
              name="connectorId"
              onChange={val => this.handleChange({target: {name: 'connectorId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any connector', minimumInputLength: 0}}
              defaultValue={connectorId || ''}
            />
          </div>
        </div>
      </div>
    );
  }

}
