import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import EndpointOpmanagerForm from 'client/components/Endpoint/components/EndpointOpmanagerForm';
import { connect } from 'react-redux';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';
import * as opmanagerActions from 'client/redux/endpoint/opmanager/actions';
import BeLoading from 'client/components/Be/BeLoading';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { AlertWithMessage } from 'client/components/Be/Alert';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';

class EndpointOpmanager extends Component {

  static defaultProps = {
    onUpdated: function() {},
    isSetupMode: true
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    onUpdated: propTypes.func,
    isSetupMode: propTypes.bool
  }

  componentDidMount() {
    if(this.props.preloadedEndpoint) {
      this.props.endpointOpmanagerPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointOpmanagerRead(this.props.endpointId);
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props;
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointOpmanagerRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  handleUpdate = (credentials = {}) => {
    return this.props.endpointOpmanagerUpdateCredentials(this.props.endpointId, credentials)
      .then(getResponseToFormErrorHandler())
      .then(result => {
        this.props.endpointOpmanagerEditCredentials(false);
      });
  }

  handleTest = ev => {
    return this.props.endpointOpmanagerTestCredentials(this.props.endpointId);
  }

  handleClickEdit = ev => {
    this.props.endpointOpmanagerEditCredentials(true);
  }

  dataToFields(data = {}) {
    return {
      apiUrl: data.apiUrl || '',
      apiKey: data.apiKey || '',
    };
  }

  renderForm() {
    const { endpoint, isReading, isSetupMode, isEditing, isTesting, isUpdating, error } = this.props;
    if(isReading || !endpoint) return null;

    if(isSetupMode || isEditing) {
      return (
          <EndpointOpmanagerForm
            isDisabled={false}
            initialValues={this.dataToFields(endpoint.settings)}
            isEditing={isEditing}
            endpointState={endpoint.state}
            onEdit={this.handleClickEdit}
            onSubmit={this.handleUpdate}
            onTest={this.handleTest}
            isTesting={isTesting}
            isUpdating={isUpdating}
            remoteError={error}
          />
      );
    } else {
      const { testOk, testTimestamp } = endpoint.state;
      return (
        <div>
          {!isSetupMode && <EndpointTestButton
            isTesting={isTesting}
            onTest={this.handleTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
          />}
          <button type="button" className="btn btn-default pull-right" onClick={this.handleClickEdit}>
            <i className="icon icon-left mdi mdi-edit"></i>
            {' '}Edit credentials
          </button>
        </div>
      );
    }
  }

  renderStatusAlert() {
    const { endpoint } = this.props;
    if(endpoint && endpoint.status === 'error') {
      return (
        <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
          <strong>Error:</strong> There is a problem with this endpoint.
        </AlertWithMessage>
      )
    }
    return null;
  }

  render() {
    const { isReading, error } = this.props;
    return (
      <div>
        <div className="form-group">
          <InstanceHelpHeader
            subTitle="Enter and test your OpManager credentials below"
            helpFragmentId="endpoint_opmanager_credentials_help"
          >
            OpManager
          </InstanceHelpHeader>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading loading={isReading} className="xs-p-20">
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {this.renderStatusAlert()}
            {this.renderForm()}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.opmanager, opmanagerActions)(EndpointOpmanager);
