import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import BeLoading from 'client/components/Be/BeLoading';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { UserInvoiceStatusLabel } from 'client/components/Helpers/StatusLabel';
import {
  TableThSort,
  TablePagination,
  TableShowCount,
  TableUserCell,
} from 'client/components/Helpers/Table';
import {
  ModalButton,
  ModalFormattedObject,
  ModalConfirmButton,
  ModalContentConfirmWarning
} from 'client/components/Helpers/Modal';
import Time from 'client/components/Helpers/Time';
import UserInvoiceTableFilterForm from 'client/components/UserInvoice/components/UserInvoiceTableFilterForm';

const fragments = {
  deleteSoftText: 'Are you sure you want to permanently delete this invoice? This action is NOT recommended!',
  deleteHardText: 'Are you sure you want to soft delete this invoice?',
  deleteSoftTitle: 'Confirm invoice permanent deletion',
  deleteHardTitle: 'Confirm invoice soft deletion',
};

export default class UserInvoiceTable extends Component {

  static defaultProps = {
    invoices: []
  }

  static propTypes = {
    invoices: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onRefresh: propTypes.func,
    onDeleteUserInvoice: propTypes.func,
    loading: propTypes.bool,
  }

  renderDeleteButton(invoice) {
    const { id, deletedAt } = invoice;
    const isSoftDeleted = Boolean(deletedAt);
    const { onDeleteUserInvoice } = this.props;
    const text = isSoftDeleted ? fragments.deleteSoftText : fragments.deleteHardText;
    const title = isSoftDeleted ? fragments.deleteSoftTitle : fragments.deleteHardTitle;
    const confirmProps = {title, text};
    if(isSoftDeleted) confirmProps.classNameSuffix = 'danger';
    return (
      <ModalConfirmButton className="btn btn-default" callback={() => onDeleteUserInvoice(id, Boolean(deletedAt))} contentProps={confirmProps} Content={ModalContentConfirmWarning}>
        <i className="icon mdi mdi-delete"></i>
      </ModalConfirmButton>
    );
  }

  renderInvoices() {
    const { invoices } = this.props;
    if(!invoices || !invoices.length) return (
      <tr>
        <td className="empty" colSpan={6}>No invoices to show</td>
      </tr>
    );
    return invoices.map(invoice => {
      const { id, stripeId, createdAt, updatedAt, User } = invoice;
      const invoiceNumber = _.get(invoice, 'stripeObject.number');
      return (
        <tr key={id}>
          <td className="cell-detail">
            <span>{invoiceNumber}</span>
            <span className="cell-detail-description">{stripeId}</span>
          </td>
          <td><Time time={createdAt} format="YYYY-MM-DD" /></td>
          <td><Time time={updatedAt} format="YYYY-MM-DD" /></td>
          <td><UserInvoiceStatusLabel invoice={invoice} /></td>
          <TableUserCell user={User} />
          <td className="text-right">
            <Link className="btn btn-default btn-hspace" to={`/user/invoice/${id}`}>
              <span className="icon mdi mdi-open-in-browser"></span>{' '}
              View
            </Link>
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: invoice, title: 'User Invoice'}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>{' '}
              {this.renderDeleteButton(invoice)}
            </div>
          </td>
        </tr>
      )
    })
  }

  renderTable() {
    const { orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                <TableThSort column="stripeId" {...sortProps}>Invoice #</TableThSort>
                <TableThSort column="createdAt" {...sortProps}>Created</TableThSort>
                <TableThSort column="updatedAt" {...sortProps}>Updated</TableThSort>
                <th>Status</th>
                <th>User</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderInvoices()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['invoice', 'invoices']} />
          </div>
        </div>
        <div className="col-md-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filter, onFilter } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of User Invoices</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <UserInvoiceTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

