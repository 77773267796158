import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';

export default class NewsNewModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    newsItem: propTypes.object.isRequired
  }

  renderBody() {
    const { html } = this.props.newsItem;
    return <div dangerouslySetInnerHTML={{__html: html}}></div>;
  }

  render() {
    const { onClose, open, newsItem } = this.props;
    const title = newsItem && newsItem.title ? newsItem.title : 'No title';
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: '40px', paddingBottom: '25px'}}>
          {this.renderBody()}
        </Modal.Body>
      </Modal>
    );
  }

}
