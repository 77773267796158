import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import ConnectedAITDTester from 'client/components/AITD/ConnectedAITDTester';
import { Helmet } from 'react-helmet';
import ConnectedAITDOptionsForm from 'client/components/AITD/ConnectedAITDOptionsForm';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

export default class AITDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Microsoft Azure AI → TOPdesk</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={ConnectedAITDOptionsForm}
            TesterComponent={ConnectedAITDTester}
            endpointOrder={['topdesk', 'azureOpenAi']}
          />
        </MainContent>
      </Content>
    );
  }

}

