import React from 'react';
import propTypes from 'prop-types';
import EndpointAzureOpenAi from 'client/components/Endpoint/EndpointAzureOpenAi';
import EndpointTopdesk from 'client/components/Endpoint/EndpointTopdesk';
import EndpointFiles from 'client/components/Endpoint/EndpointFiles';
import EndpointManagedworkplace from 'client/components/Endpoint/EndpointManagedworkplace';
import EndpointGraph from 'client/components/Endpoint/EndpointGraph';
import EndpointFast from 'client/components/Endpoint/EndpointFast';
import EndpointOpmanager from 'client/components/Endpoint/EndpointOpmanager';
import EndpointBankid from 'client/components/Endpoint/EndpointBankid';
import EndpointSkatteverket from 'client/components/Endpoint/EndpointSkatteverket';
import EndpointSnow from 'client/components/Endpoint/EndpointSnow';
import ConnectedInstanceOptionalEndpointList from 'client/components/Instance/ConnectedInstanceOptionalEndpointList';
import Alert from 'client/components/Be/Alert';

export default class InstanceEndpoints extends React.Component {

  static propTypes = {
    instance: propTypes.object.isRequired,
    endpointsByType: propTypes.object.isRequired,
    endpointOrder: propTypes.array.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    onEndpointUpdated: propTypes.func.isRequired,
  }

  static defaultProps = {
    onEndpointUpdated: function() {},
    isSetupMode: false
  }

  renderNoEndpoint(type) {
    return (
      <Alert icon alertType="primary">
        <strong>Info:</strong> The endpoint "{type}" does not have an authentication step
      </Alert>
    );
  }

  renderEndpoint (type, preloadedEndpoint) {
    const { isSetupMode, instance, onEndpointUpdated } = this.props;
    const endpointProps = {
      onUpdated: onEndpointUpdated,
      endpointId: preloadedEndpoint.id,
      instanceId: instance.id,
      preloadedEndpoint,
      isSetupMode,
    };
    switch(type) {
      default: return this.renderNoEndpoint(type);
      case 'bankid': return <EndpointBankid {...endpointProps} />
      case 'fast': return <EndpointFast {...endpointProps} />
      case 'topdesk': return <EndpointTopdesk {...endpointProps} />
      case 'graph': return <EndpointGraph {...endpointProps} />
      case 'files': return <EndpointFiles {...endpointProps} />
      case 'opmanager': return <EndpointOpmanager {...endpointProps} />
      case 'managedworkplace': return <EndpointManagedworkplace {...endpointProps} />
      case 'skatteverket': return <EndpointSkatteverket {...endpointProps} />
      case 'snow': return <EndpointSnow {...endpointProps} />
      case 'azureOpenAi': return <EndpointAzureOpenAi {...endpointProps} />
    }
  }

  renderEndpoints(order = []) {
    const { endpointsByType } = this.props;
    return order.map(type => {
      const endpoint = endpointsByType && endpointsByType[type] ? endpointsByType[type] : null;
      if(!endpoint) return null;
      return (
        <div key={type} className="col-md-6">
          {this.renderEndpoint(type, endpoint)}
        </div>
      );
    });
  }

  render () {
    const {
      endpointOrder,
      endpointsByType,
      instance,
      onEndpointUpdated,
      optionalEndpoints,
    } = this.props;
    return (
      <div>
        {(optionalEndpoints?.length > 0) && (
          <ConnectedInstanceOptionalEndpointList
            instanceId={instance.id}
            optionalEndpoints={optionalEndpoints}
            endpointsByType={endpointsByType}
            onEndpointCreated={onEndpointUpdated}
          />
        )}
        <div className="row">
          {this.renderEndpoints(endpointOrder)}
        </div>
      </div>
    );
  }
}
