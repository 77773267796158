import { LOGIN, LOGIN_PROPS } from 'client/redux/auth/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: null,
  isLoggedIn: null,
  isLoggingIn: false,
  error: null
};

export default function loginReducer(state = defaultState, action) {
  state = reduceByTypes(LOGIN_PROPS, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(LOGIN, state, action, {requestProp: 'isLoggingIn', successSpread: true});
  return state;
};
