export const SUPPORT_QUESTIONS_REQUEST = 'SUPPORT_QUESTIONS_REQUEST';
export const SUPPORT_QUESTIONS_FAILURE = 'SUPPORT_QUESTIONS_FAILURE';
export const SUPPORT_QUESTIONS_SUCCESS = 'SUPPORT_QUESTIONS_SUCCESS';
export const SUPPORT_QUESTIONS = [SUPPORT_QUESTIONS_REQUEST, SUPPORT_QUESTIONS_SUCCESS, SUPPORT_QUESTIONS_FAILURE];

export const SUPPORT_QUESTIONS_FILTER = 'SUPPORT_QUESTIONS_FILTER';

export const SUPPORT_CATEGORY_CREATE_REQUEST = 'SUPPORT_CATEGORY_CREATE_REQUEST';
export const SUPPORT_CATEGORY_CREATE_FAILURE = 'SUPPORT_CATEGORY_CREATE_FAILURE';
export const SUPPORT_CATEGORY_CREATE_SUCCESS = 'SUPPORT_CATEGORY_CREATE_SUCCESS';
export const SUPPORT_CATEGORY_CREATE = [SUPPORT_CATEGORY_CREATE_REQUEST, SUPPORT_CATEGORY_CREATE_SUCCESS, SUPPORT_CATEGORY_CREATE_FAILURE];

export const SUPPORT_CATEGORY_UPDATE_REQUEST = 'SUPPORT_CATEGORY_UPDATE_REQUEST';
export const SUPPORT_CATEGORY_UPDATE_FAILURE = 'SUPPORT_CATEGORY_UPDATE_FAILURE';
export const SUPPORT_CATEGORY_UPDATE_SUCCESS = 'SUPPORT_CATEGORY_UPDATE_SUCCESS';
export const SUPPORT_CATEGORY_UPDATE = [SUPPORT_CATEGORY_UPDATE_REQUEST, SUPPORT_CATEGORY_UPDATE_SUCCESS, SUPPORT_CATEGORY_UPDATE_FAILURE];

export const SUPPORT_CATEGORY_DELETE_REQUEST = 'SUPPORT_CATEGORY_DELETE_REQUEST';
export const SUPPORT_CATEGORY_DELETE_FAILURE = 'SUPPORT_CATEGORY_DELETE_FAILURE';
export const SUPPORT_CATEGORY_DELETE_SUCCESS = 'SUPPORT_CATEGORY_DELETE_SUCCESS';
export const SUPPORT_CATEGORY_DELETE = [SUPPORT_CATEGORY_DELETE_REQUEST, SUPPORT_CATEGORY_DELETE_SUCCESS, SUPPORT_CATEGORY_DELETE_FAILURE];

export const SUPPORT_QUESTION_CREATE_REQUEST = 'SUPPORT_QUESTION_CREATE_REQUEST';
export const SUPPORT_QUESTION_CREATE_FAILURE = 'SUPPORT_QUESTION_CREATE_FAILURE';
export const SUPPORT_QUESTION_CREATE_SUCCESS = 'SUPPORT_QUESTION_CREATE_SUCCESS';
export const SUPPORT_QUESTION_CREATE = [SUPPORT_QUESTION_CREATE_REQUEST, SUPPORT_QUESTION_CREATE_SUCCESS, SUPPORT_QUESTION_CREATE_FAILURE];

export const SUPPORT_QUESTION_UPDATE_REQUEST = 'SUPPORT_QUESTION_UPDATE_REQUEST';
export const SUPPORT_QUESTION_UPDATE_FAILURE = 'SUPPORT_QUESTION_UPDATE_FAILURE';
export const SUPPORT_QUESTION_UPDATE_SUCCESS = 'SUPPORT_QUESTION_UPDATE_SUCCESS';
export const SUPPORT_QUESTION_UPDATE = [SUPPORT_QUESTION_UPDATE_REQUEST, SUPPORT_QUESTION_UPDATE_SUCCESS, SUPPORT_QUESTION_UPDATE_FAILURE];

export const SUPPORT_QUESTION_DELETE_REQUEST = 'SUPPORT_QUESTION_DELETE_REQUEST';
export const SUPPORT_QUESTION_DELETE_FAILURE = 'SUPPORT_QUESTION_DELETE_FAILURE';
export const SUPPORT_QUESTION_DELETE_SUCCESS = 'SUPPORT_QUESTION_DELETE_SUCCESS';
export const SUPPORT_QUESTION_DELETE = [SUPPORT_QUESTION_DELETE_REQUEST, SUPPORT_QUESTION_DELETE_SUCCESS, SUPPORT_QUESTION_DELETE_FAILURE];
