import React from 'react';
import classNames from 'classnames'

export default class BeCheckbox extends React.Component {

  static defaultProps = {
    className: '',
  }

  render() {
    const { className, id, children, ...props } = this.props;
    const classes = classNames('be-checkbox', className);
    return (
      <div className={classes}>
        <input type="checkbox" {...props} id={id} />
        <label htmlFor={id}></label>
        {children}
      </div>
    );
  }

};
