import {
  INSTANCE_SWTD_TEST_COMPUTER_ADD,
  INSTANCE_SWTD_TEST_CLEAR,
  INSTANCE_SWTD_TEST_COMPUTER_REMOVE,
  INSTANCE_SWTD_SYNC_COMPUTER,
  INSTANCE_SWTD_TEST_COMPUTER_ADD_SUCCESS,
  INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH,
} from 'client/redux/swtd/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isAdding: false,
  searchFieldComputerId: '',
  computers: [],
};

const defaultComputer = {
  isSyncing: false,
  isGettingObjectGraph: false,
  error: null,
  objectGraph: null,
  snowComputerId: null,
  topdeskAssetObject: null,
  snowComputerObject: null,
  notices: null,
};

const getComputerId = action => _.get(action, 'meta.computerId');
const getComputerIndex = (computerId, computers = []) => computers.findIndex(computer => computer.snowComputerId === computerId);

// reduce a single computer
const reduceComputer = (state = defaultComputer, action) => {
  const successPickProps = ['notices', 'topdeskAssetObject', 'snowComputerObject', 'snowComputerId'];
  state = reduceByTypes(INSTANCE_SWTD_TEST_COMPUTER_ADD, state, action, {requestProp: 'isAdding', successPickProps});
  state = reduceByTypes(INSTANCE_SWTD_SYNC_COMPUTER, state, action, {
    requestProp: (state, action, isSyncing) => ({
      isSyncing,
      notices: null,
    }),
    successPickProps,
  });
  state = reduceByTypes(INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH, state, action, {
    requestProp: 'isGettingObjectGraph',
    successProp: 'objectGraph',
  });
  return state;
};

// these actions should pass to reduceComputer
const computerActions = [
  ...INSTANCE_SWTD_SYNC_COMPUTER,
  ...INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH,
  INSTANCE_SWTD_TEST_COMPUTER_ADD_SUCCESS,
];

const mutateAt = (list, index, mutator, ...mutatorArgs) => {
  const newList = [...list];
  if(newList[index]) newList[index] = mutator(...mutatorArgs);
  return newList;
};

// reduce an array of computers
const reduceComputers = (computers = [], action) => {
  if(computerActions.includes(action.type)) {
    const computerIndex = getComputerIndex(getComputerId(action), computers);
    if(computerIndex === -1) return [...computers, reduceComputer({...defaultComputer}, action)];
    return mutateAt(computers, computerIndex, reduceComputer, computers[computerIndex], action);
  }
  switch(action.type) {
    default: return computers;
    case INSTANCE_SWTD_TEST_CLEAR: return [];
    case INSTANCE_SWTD_TEST_COMPUTER_REMOVE: {
      const computerIndex = getComputerIndex(getComputerId(action), computers);
      const newComputers = [...computers];
      newComputers.splice(computerIndex, 1);
      return newComputers;
    }
  }
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_SWTD_TEST_COMPUTER_ADD, state, action, {requestProp: 'isAdding', successPickProps: []});
  return {
    ...state,
    computers: reduceComputers(state.computers, action),
  };
};
