import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { codeSettingsShape } from 'client/components/QRTD/shapes'
import { Modal } from 'react-bootstrap';
import QRTDPrintCodeSettingsForm from 'client/components/QRTD/QRTDPrintCodeSettingsForm';
import { ModalClose } from 'client/components/Helpers/Modal';

export default class QRTDEditModal extends React.Component {

  static propTypes = {
    codeId: propTypes.string.isRequired,
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    settings: codeSettingsShape.isRequired,
    onUpdate: propTypes.func.isRequired,
    incidentFieldMaps: propTypes.array.isRequired,
    infoTexts: propTypes.array.isRequired,
    codeType: propTypes.string.isRequired,
  }

  state = {
    error: null,
    changed: [],
  }

  componentDidUpdate (prevProps) {
    const { open, settings } = this.props
    if (prevProps.open && !open) {
      this.setState({settings: null, error: null, changed: []});
    } else if (!prevProps.open && open) {
      this.setState({settings, error: null, changed: []});
    }
  }

  handleChangeSettingsValue = (name, value) => {
    const { settings, changed } = this.state;
    this.setState({
      settings: {...settings, [name]: value},
      changed: !changed.includes(name) ? [...changed, name] : changed,
    });
  }

  handleSaveAndClose = () => {
    const save = _.pick(this.state.settings, this.state.changed);
    this.props.onUpdate(save);
    this.props.onClose();
  }

  handleReset = () => {
    this.props.onUpdate(null);
    this.props.onClose();
  }

  render() {
    const { codeId, codeType, open, onClose, incidentFieldMaps, infoTexts } = this.props;
    const { error, settings } = this.state;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>Editing QR-code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="xs-pt-10">
          {settings && (
            <QRTDPrintCodeSettingsForm
              codeType={codeType}
              idBase={codeId}
              onChangeValue={this.handleChangeSettingsValue}
              settings={settings}
              incidentFieldMaps={incidentFieldMaps}
              infoTexts={infoTexts}
            />
          )}
          <hr />
          <div className="text-right">
            <button
              disabled={Boolean(error)}
              type="button"
              className="btn btn-default"
              onClick={this.handleReset}
            >
              Reset to default and close
            </button>{' '}
            <button
              disabled={Boolean(error)}
              type="button"
              className="btn btn-primary"
              onClick={this.handleSaveAndClose}
            >
              Save and close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}
