import { CONNECTOR_SELECT_PLAN, CONNECTOR_SELECT_PLAN_CREATE } from 'client/redux/connector/constants';
import * as actionCreators from 'client/redux/connector/actionCreators';
import { accountSubscriptionCreate } from 'client/redux/account/actionCreators';

export const connectorSelectPlan = connectorId => dispatch => {
  return dispatch(actionCreators.connectorPlan(CONNECTOR_SELECT_PLAN, connectorId));
};

export const connectorSelectPlanCreate = planId => dispatch => {
  return dispatch(accountSubscriptionCreate(CONNECTOR_SELECT_PLAN_CREATE, planId));
};
