import * as actionCreators from 'client/redux/endpoint/opmanager/actionCreators';
import {
  ENDPOINT_OPMANAGER_PRELOADED,
  ENDPOINT_OPMANAGER_READ,
  ENDPOINT_OPMANAGER_EDIT_CREDENTIALS,
  ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS,
  ENDPOINT_OPMANAGER_TEST_CREDENTIALS,
} from 'client/redux/endpoint/opmanager/constants';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointOpmanagerPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(ENDPOINT_OPMANAGER_PRELOADED, endpoint));
};

export const endpointOpmanagerRead = id => dispatch => {
  return dispatch(actionCreators.endpointOpmanagerRead(ENDPOINT_OPMANAGER_READ, id));
};

export const endpointOpmanagerEditCredentials = isEditing => dispatch => {
  return dispatch(actionCreators.endpointOpmanagerEditCredentials(ENDPOINT_OPMANAGER_EDIT_CREDENTIALS, isEditing));
};

export const endpointOpmanagerUpdateCredentials = (id, credentials) => dispatch => {
  return dispatch(actionCreators.endpointOpmanagerUpdateCredentials(ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS, id, credentials));
};

export const endpointOpmanagerTestCredentials = (id) => dispatch => {
  return dispatch(actionCreators.endpointOpmanagerTestCredentials(ENDPOINT_OPMANAGER_TEST_CREDENTIALS, id));
};
