import { ENDPOINT_PANEL_READ, ENDPOINT_PANEL_UPDATE, ENDPOINT_PANEL_UPDATE_SETTINGS } from 'client/redux/endpoint/constants';
import { endpointRead, endpointUpdate, endpointUpdateSettings } from 'client/redux/endpoint/actionCreators';

export const endpointEditPanelRead = id => dispatch => {
  return dispatch(endpointRead(ENDPOINT_PANEL_READ, id));
};

export const endpointEditPanelUpdate = (id, update) => dispatch => {
  return dispatch(endpointUpdate(ENDPOINT_PANEL_UPDATE, id, update));
};

export const endpointEditPanelUpdateSettings = (id, endpointType, update) => dispatch => {
  return dispatch(endpointUpdateSettings(ENDPOINT_PANEL_UPDATE_SETTINGS, id, endpointType, update));
};
