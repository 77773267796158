import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { push } from 'connected-react-router';
import { uiProps } from 'client/redux/ui/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import { CONNECTOR_PANEL_UPDATE_SUCCESS, CONNECTOR_INSTANTIATE_SUCCESS } from 'client/redux/connector/constants';
import { UI_PROPS } from 'client/redux/ui/constants';
import _ from 'lodash';

export const onConnectorUpdate = updateActionResult => dispatch => {
  if(updateActionResult) {
    const { type } = updateActionResult;
    if(type === CONNECTOR_PANEL_UPDATE_SUCCESS) {
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'connector_update_success', {
        className: 'color success',
        title: 'Update succeded',
        message: 'The connector was updated',
        closeAfter: 3000
      }));
    }
  }
};

export const onConnectorInstantiate = instantiateResult => dispatch => {
  if(instantiateResult && instantiateResult.type === CONNECTOR_INSTANTIATE_SUCCESS) {
    return dispatch(uiProps(UI_PROPS, {refresh: true})).then(() => {
      const instanceId = _.get(instantiateResult, 'payload.instance.id');
      const instanceType = _.get(instantiateResult, 'payload.instance.type');
      const url = `/instance/${instanceType}/${instanceId}/setup`;
      dispatch(push(url));
      return instantiateResult;
    });
  }
  return instantiateResult;
};
