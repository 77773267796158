import * as constants from 'client/redux/qrtd/constants';
import * as actions from 'client/redux/qrtd/actionCreators';
import {
  endpointTopdeskSearchRemoteAssets,
  endpointTopdeskSearchRemoteLocations,
} from 'client/redux/endpoint/topdesk/actionCreators';

export const instanceQrtdPrintPageReadInstanceMeta = instanceId => dispatch => dispatch(actions.instanceQrtdReadMeta(constants.INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META, instanceId));

export const instanceQrtdPrintPageReadAssets = (topdeskEndpointId, query) => dispatch => dispatch(endpointTopdeskSearchRemoteAssets(constants.INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS, topdeskEndpointId, query));

export const instanceQrtdPrintPageReadLocations = (topdeskEndpointId, query) => dispatch => dispatch(endpointTopdeskSearchRemoteLocations(constants.INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS, topdeskEndpointId, query));

export const instanceQrtdPrintPageUpdateSetting = (key, value) => dispatch => dispatch({
  type: constants.INSTANCE_QRTD_PRINT_PAGE_UPDATE_SETTING,
  key,
  value,
});

export const instanceQrtdPrintPageUpdateCodeSetting = (codeId, settings) => dispatch => dispatch({
  type: constants.INSTANCE_QRTD_PRINT_PAGE_UPDATE_CODE_SETTING,
  codeId,
  settings,
});

export const instanceQrtdPrintPageCreateCode = () => dispatch => dispatch({
  type: constants.INSTANCE_QRTD_PRINT_PAGE_CREATE_CODE,
});

export const instanceQrtdPrintPageSetChosenIds = (assetIds = [], locationIds = []) => dispatch => dispatch({
  type: constants.INSTANCE_QRTD_PRINT_PAGE_SET_CHOSEN_IDS,
  assetIds,
  locationIds,
});
