export const NEWS_PANEL_READ_REQUEST = 'NEWS_PANEL_READ_REQUEST';
export const NEWS_PANEL_READ_SUCCESS = 'NEWS_PANEL_READ_SUCCESS';
export const NEWS_PANEL_READ_FAILURE = 'NEWS_PANEL_READ_FAILURE';
export const NEWS_PANEL_READ = [NEWS_PANEL_READ_REQUEST, NEWS_PANEL_READ_SUCCESS, NEWS_PANEL_READ_FAILURE];

export const NEWS_PANEL_UPDATE_REQUEST = 'NEWS_PANEL_UPDATE_REQUEST';
export const NEWS_PANEL_UPDATE_SUCCESS = 'NEWS_PANEL_UPDATE_SUCCESS';
export const NEWS_PANEL_UPDATE_FAILURE = 'NEWS_PANEL_UPDATE_FAILURE';
export const NEWS_PANEL_UPDATE = [NEWS_PANEL_UPDATE_REQUEST, NEWS_PANEL_UPDATE_SUCCESS, NEWS_PANEL_UPDATE_FAILURE];

export const NEWS_PANEL_CREATE_REQUEST = 'NEWS_PANEL_CREATE_REQUEST';
export const NEWS_PANEL_CREATE_SUCCESS = 'NEWS_PANEL_CREATE_SUCCESS';
export const NEWS_PANEL_CREATE_FAILURE = 'NEWS_PANEL_CREATE_FAILURE';
export const NEWS_PANEL_CREATE = [NEWS_PANEL_CREATE_REQUEST, NEWS_PANEL_CREATE_SUCCESS, NEWS_PANEL_CREATE_FAILURE];

export const isPublishedSelect2 = [
  {id: false, text: 'Unpublished'},
  {id: true, text: 'Published'}
];
