import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/user/subscription/indexActions';
import { onUserSubscriptionDelete } from 'client/redux/user/subscription/eventHandlerActions';
import UserSubscriptionTable from 'client/components/UserSubscription/components/UserSubscriptionTable';
import _ from 'lodash';
import { getStateSearch } from 'client/redux/apiHelpers';

class UserSubscriptionIndex extends Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    const filter = {...this.props.filter, ...this.props.search};
    return this.handleFilter(filter);
  }

  getData = (overrideWith = {}) => {
    const { orderBy, orderDirection, page, filter } = this.props;
    return this.props.userSubscriptionIndex({...filter, orderBy, orderDirection, page, ...overrideWith});
  }

  handleOrderBy = (orderBy, orderDirection) => {
    this.props.userSubscriptionIndexOrderBy(orderBy, orderDirection);
    return this.getData({orderBy, orderDirection});
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    this.props.userSubscriptionIndexSetPage(page);
    return this.getData({page});
  }

  handleFilter = filter => {
    this.props.userSubscriptionIndexFilter(filter)
    this.getDataDebounced();
  }

  handleDeleteUserSubscription = (id, force = false) => {
    const query = force ? {force: '1', paranoid: '0'} : {};
    return this.props.userSubscriptionDelete(id, query).then(this.props.onUserSubscriptionDelete).then(this.getData);
  }

  renderTitle() {
    return (
      <Helmet>
        <title>User Subscription Overview</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const { isReading, subscriptions, orderBy, orderDirection, page, pages, limit, offset, count, filter, error } = this.props;
    if(error) return this.renderError(error);
    return (
      <Content>
        <MainContent>
          {this.renderTitle()}
          <UserSubscriptionTable
            className="panel-default panel-border"
            loading={isReading}
            subscriptions={subscriptions}
            onOrderBy={this.handleOrderBy}
            onSetPage={this.handleSetPage}
            onDeleteUserSubscription={this.handleDeleteUserSubscription}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onFilter={this.handleFilter}
            onRefresh={this.getData}
            filter={filter}
            count={count}
            limit={limit}
            offset={offset}
            page={page}
            pages={pages}
          />
        </MainContent>
      </Content>
    );
  }

}

const mapStateToProps = state => ({
  ...state.user.subscriptionIndex,
  search: getStateSearch(state),
});

const actions = {
  ...indexActions,
  onUserSubscriptionDelete,
};

export default connect(mapStateToProps, actions)(UserSubscriptionIndex);
