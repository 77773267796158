import React from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { UserIdSearcher } from 'client/components/Form/Select2Searchers';
import MaybeBeFormFieldError from 'client/components/Be/MaybeBeFormFieldError';

export default class InstanceFieldUserId extends React.Component {

  static propTypes = {
    base: propTypes.string.isRequired,
    dropdownParent: propTypes.string,
  }

  render() {
    const { defaultValue, input, meta, base, dropdownParent } = this.props;
    const { dirty, submitting } = meta;
    const id = `${base}-status`;
    return (
      <FormGroup controlId={id}>
        <ControlLabel bsClass="control-label">User ID</ControlLabel>
        <div className="input-group">
          <UserIdSearcher
            id={id}
            className="form-control"
            name="userId"
            options={{minimumResultsForSearch: 20, dropdownParent}}
            defaultValue={defaultValue}
            {...input}
          />
          <span className="input-group-btn">
            <ButtonWaiting type="submit" disabled={!dirty} className="btn btn-primary" isWaiting={submitting}>Change User ID</ButtonWaiting>
          </span>
        </div>
        <FormControl.Feedback />
        <MaybeBeFormFieldError {...meta} />
      </FormGroup>
    );
  }

}

