import { INTERNAL_ERROR, AUTH_ERROR, FORBIDDEN_ERROR } from 'client/redux/appError/constants';
import * as actionCreators from 'client/redux/appError/actionCreators';

export const internalError = error => dispatch => {
  return dispatch(actionCreators.generalError(INTERNAL_ERROR, error));
};

export const authError = error => dispatch => {
  return dispatch(actionCreators.generalError(AUTH_ERROR, error));
};

export const forbiddenError = error => dispatch => {
  return dispatch(actionCreators.generalError(FORBIDDEN_ERROR, error));
};
