export const CONNECTOR_INDEX_REQUEST = 'CONNECTOR_INDEX_REQUEST';
export const CONNECTOR_INDEX_SUCCESS = 'CONNECTOR_INDEX_SUCCESS';
export const CONNECTOR_INDEX_FAILURE = 'CONNECTOR_INDEX_FAILURE';
export const CONNECTOR_INDEX = [CONNECTOR_INDEX_REQUEST, CONNECTOR_INDEX_SUCCESS, CONNECTOR_INDEX_FAILURE];

export const CONNECTOR_INDEX_ORDERBY = 'CONNECTOR_INDEX_ORDERBY';

export const CONNECTOR_INDEX_SET_PAGE = 'CONNECTOR_INDEX_SET_PAGE';

export const CONNECTOR_INDEX_FILTER = 'CONNECTOR_INDEX_FILTER';

export const CONNECTOR_INSTANTIATE_REQUEST = 'CONNECTOR_INSTANTIATE_REQUEST';
export const CONNECTOR_INSTANTIATE_SUCCESS = 'CONNECTOR_INSTANTIATE_SUCCESS';
export const CONNECTOR_INSTANTIATE_FAILURE = 'CONNECTOR_INSTANTIATE_FAILURE';
export const CONNECTOR_INSTANTIATE = [CONNECTOR_INSTANTIATE_REQUEST, CONNECTOR_INSTANTIATE_SUCCESS, CONNECTOR_INSTANTIATE_FAILURE];

export const CONNECTOR_SELECT_PLAN_REQUEST = 'CONNECTOR_SELECT_PLAN_REQUEST';
export const CONNECTOR_SELECT_PLAN_SUCCESS = 'CONNECTOR_SELECT_PLAN_SUCCESS';
export const CONNECTOR_SELECT_PLAN_FAILURE = 'CONNECTOR_SELECT_PLAN_FAILURE';
export const CONNECTOR_SELECT_PLAN = [CONNECTOR_SELECT_PLAN_REQUEST, CONNECTOR_SELECT_PLAN_SUCCESS, CONNECTOR_SELECT_PLAN_FAILURE];

export const CONNECTOR_SELECT_PLAN_CREATE_REQUEST = 'CONNECTOR_SELECT_PLAN_CREATE_REQUEST';
export const CONNECTOR_SELECT_PLAN_CREATE_SUCCESS = 'CONNECTOR_SELECT_PLAN_CREATE_SUCCESS';
export const CONNECTOR_SELECT_PLAN_CREATE_FAILURE = 'CONNECTOR_SELECT_PLAN_CREATE_FAILURE';
export const CONNECTOR_SELECT_PLAN_CREATE = [CONNECTOR_SELECT_PLAN_CREATE_REQUEST, CONNECTOR_SELECT_PLAN_CREATE_SUCCESS, CONNECTOR_SELECT_PLAN_CREATE_FAILURE];

export const CONNECTOR_PANEL_READ_REQUEST = 'CONNECTOR_PANEL_READ_REQUEST';
export const CONNECTOR_PANEL_READ_SUCCESS = 'CONNECTOR_PANEL_READ_SUCCESS';
export const CONNECTOR_PANEL_READ_FAILURE = 'CONNECTOR_PANEL_READ_FAILURE';
export const CONNECTOR_PANEL_READ = [CONNECTOR_PANEL_READ_REQUEST, CONNECTOR_PANEL_READ_SUCCESS, CONNECTOR_PANEL_READ_FAILURE];

export const CONNECTOR_PANEL_UPDATE_REQUEST = 'CONNECTOR_PANEL_UPDATE_REQUEST';
export const CONNECTOR_PANEL_UPDATE_SUCCESS = 'CONNECTOR_PANEL_UPDATE_SUCCESS';
export const CONNECTOR_PANEL_UPDATE_FAILURE = 'CONNECTOR_PANEL_UPDATE_FAILURE';
export const CONNECTOR_PANEL_UPDATE = [CONNECTOR_PANEL_UPDATE_REQUEST, CONNECTOR_PANEL_UPDATE_SUCCESS, CONNECTOR_PANEL_UPDATE_FAILURE];

export const isActiveSelect2 = [
  {id: false, text: 'Inactive'},
  {id: true, text: 'Active'}
];
