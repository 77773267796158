import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';
import InstanceTitleForm from 'client/components/Instance/components/InstanceTitleForm';
import * as titleModalActions from 'client/redux/instance/titleModalActions';
import { connect } from 'react-redux';

class InstanceTitleModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    instance: propTypes.object,
    defaultTitle: propTypes.string,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired
  }

  state = {
    wasUpdated: false
  }

  handleClose = () => {
    const { wasUpdated } = this.state;
    return this.props.onClose({wasUpdated});
  }

  handleUpdated = () => {
    this.setState({wasUpdated: true});
    this.props.onClose({wasUpdated: true});
  }

  handleUpdate = (fields = {}) => {
    const { id } = this.props.instance;
    const newTitle = fields.title || null;
    return this.props.instanceTitleModalUpdateTitle(id, newTitle).then(this.handleUpdated);
  }

  render() {
    const { open, instance: { title }, defaultTitle } = this.props;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={this.handleClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={this.handleClose} />
          <Modal.Title>Rename instance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstanceTitleForm
            initialValues={{title: title || defaultTitle}}
            onSubmit={this.handleUpdate}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const actions = {
  ...titleModalActions
};

const mapStateToProps = state => state.instance.titleModal;

export default connect(mapStateToProps, actions)(InstanceTitleModal);
