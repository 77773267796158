import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/fatd/testActions';
import _ from 'lodash';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import FATDTesterAddObject from 'client/components/FATD/components/FATDTesterAddObject';
import FATDTesterObject from 'client/components/FATD/components/FATDTesterObject';

class FATDTester extends Component {

  instanceId() {
    return _.get(this.props, 'instance.id');
  }

  handleAddObject = (objectType, objectId) => {
    this.props.instanceFatdTestObjectAdd(this.instanceId(), objectType, objectId).then(() => {
      return this.props.instanceFatdObjectSearchFieldChange(objectType, '');
    });
  }

  handleSearchFieldChange = (objectType, objectId) => {
    return this.props.instanceFatdObjectSearchFieldChange(objectType, objectId);
  }

  handleRemoveObject = (objectType, objectId) => {
    return this.props.instanceFatdTestObjectRemove(objectType, objectId);
  }

  handleTestObjectGraph = (objectType, objectId) => {
    return this.props.instanceFatdTestObjectGraph(this.instanceId(), objectType, objectId);
  }

  handleSyncObject = (objectType, objectId) => {
    return this.props.instanceFatdSyncObject(this.instanceId(), objectType, objectId);
  }

  render() {
    const {
      endpointsByType: { fast, topdesk },
      searchFields,
      isAddingFastPartner,
      isAddingTopdeskPerson,
      fastPartners,
      topdeskPersons,
    } = this.props;
    console.log(topdeskPersons);
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_fatd_test_help" subTitle="Here you can test the options on real objects">
          Test Synchronization
        </InstanceHelpHeader>

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> Clicking "Sync" on an added object below will create or update real data.
          </div>
        </div>

        <h4>TOPdesk Persons</h4>
        <div className="list-group">
          {Object.keys(topdeskPersons).length > 0 && _.map(topdeskPersons, (objectContainer, id) => (
            <FATDTesterObject
              key={id}
              id={id}
              objectContainer={objectContainer}
              objectType="topdeskPerson"
              onRemove={this.handleRemoveObject}
              onSync={this.handleSyncObject}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
          {Object.keys(topdeskPersons).length < 1 && (
            <div className="list-group-item">
              No TOPdesk Persons added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <FATDTesterAddObject
              endpointId={topdesk.id}
              searchForWhat="person"
              objectId={searchFields.topdeskPerson}
              objectType="topdeskPerson"
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddObject}
              isAdding={isAddingTopdeskPerson}
            />
          </div>
        </div>

        <h4>Fast Partners</h4>
        <div className="list-group">
          {Object.keys(fastPartners).length > 0 && _.map(fastPartners, (objectContainer, id) => (
            <FATDTesterObject
              key={id}
              id={id}
              objectContainer={objectContainer}
              objectType="fastPartner"
              onRemove={this.handleRemoveObject}
              onSync={this.handleSyncObject}
              onTestObjectGraph={this.handleTestObjectGraph}
            />
          ))}
          {Object.keys(fastPartners).length < 1 && (
            <div className="list-group-item">
              No Fast Partners added
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-6">
            <FATDTesterAddObject
              endpointId={fast.id}
              searchForWhat="partner"
              objectId={searchFields.fastPartner}
              objectType="fastPartner"
              onChange={this.handleSearchFieldChange}
              onAdd={this.handleAddObject}
              isAdding={isAddingFastPartner}
            />
          </div>
        </div>

      </div>
    );
  }
}

const actions = {
  ...testActions,
};

export default connect(state => state.fatd.test, actions)(FATDTester);
