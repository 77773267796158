import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ModalButton } from 'client/components/Helpers/Modal';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';

export default class UIFragmentTableCreateForm extends Component {

  static propTypes = {
    onCreate: propTypes.func
  }

  state = {
    id: ''
  }

  handleChange = ev => {
    const { name, value } = ev.target;
    this.setState({[name]: value});
  }

  render() {
    const { onCreate } = this.props;
    const { id } = this.state;
    return (
      <div className="col-md-4">
        <div className="dataTables_length form-group">
          <div className="input-group input-group-sm">
            <input
              id="uiFragmentTableCreateId"
              type="text"
              className="form-control"
              name="id"
              onChange={this.handleChange}
              placeholder="Fragment ID"
              value={id}
            />
            <span className="input-group-btn">
              <ModalButton className="btn btn-default" title="Edit fragment" ModalComponent={UIFragmentHelpModal} modalProps={{fragmentId: id, onFragmentWasUpdated: onCreate}}>
                Create
              </ModalButton>
            </span>
          </div>
        </div>
      </div>
    );
  }

}
