import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { AppSparklinesBar } from 'client/components/Helpers/Sparklines';
import FSTDOptionsForm from 'client/components/FSTD/components/FSTDOptionsForm';
import FSTDTester from 'client/components/FSTD/components/FSTDTester';
import FSTDWidgetStatistic from 'client/components/FSTD/components/FSTDWidgetStatistic';
import InstanceWidgetPlan from 'client/components/Instance/components/InstanceWidgetPlan';
import InstanceWidgetControls from 'client/components/Instance/components/InstanceWidgetControls';
import InstanceDashboard from 'client/components/Instance/InstanceDashboard';
import FSTDFieldMapper from 'client/components/FSTD/FSTDFieldMapper';
import { instanceSettingsDisabled } from 'client/redux/instance/instanceHelpers';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class FSTDModule extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    tab: propTypes.string.isRequired,
  }

  handleSelectTab = newTab => {
    if(newTab === this.props.tab) return;
    const instanceId = this.props.instanceId;
    this.props.push(`/instance/fstd/${instanceId}/${newTab}`);
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>CSV → TOPdesk Assets</title>
        </Helmet>
        <MainContent>
          <InstanceParentAlert />
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <FSTDWidgetStatistic
                title="Actions per month"
                id={instanceId}
                statistic="logSummer"
                query={{duration: 'month', limit: 7}}
                Component={AppSparklinesBar}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <FSTDWidgetStatistic
                title="Number of tasks in queue"
                id={instanceId}
                statistic="messagesInOwnQueue"
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetPlan
                id={instanceId}
                shouldReadInstance={false}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetControls
                id={instanceId}
                shouldReadInstance={false}
                hasDeleteQueueContentsButton
              />
            </div>
          </div>
          <InstanceDashboard
            id={this.props.instanceId}
            tab={this.props.tab}
            OptionsFormComponent={FSTDOptionsForm}
            TesterComponent={FSTDTester}
            onSelectTab={this.handleSelectTab}
            endpointOrder={['files', 'topdesk']}
            tabOrder={[
              {name: 'auth'},
              {name: 'options'},
              {name: 'fieldMapper', displayName: 'Field Mapper', icon: 'mdi-group'},
              {name: 'test'},
              {name: 'events'},
            ]}
            tabComponents={{
              fieldMapper: props => <FSTDFieldMapper
                {...props} 
                disabled={instanceSettingsDisabled(_.get(props, 'instance.status'))}
              />
            }}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  push,
};

export default connect(null, actions)(FSTDModule);
