import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import FATDOptionsForm from 'client/components/FATD/components/FATDOptionsForm';
import FATDTester from 'client/components/FATD/components/FATDTester';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import { connect } from 'react-redux';
import { instanceFatdObjectTestClear } from 'client/redux/fatd/testActions';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class FATDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  componentWillUnmount() {
    // clear everything from the test when we unmount
    this.props.instanceFatdObjectTestClear();
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Fast API → TOPdesk</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={FATDOptionsForm}
            endpointOrder={['fast', 'topdesk']}
            TesterComponent={FATDTester}
          />
        </MainContent>
      </Content>
    );
  }

}

export default connect(null, { instanceFatdObjectTestClear })(FATDSetup);
