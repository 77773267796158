import {
  ENDPOINT_MANAGEDWORKPLACE_READ,
  ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS,
  ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS,
  ENDPOINT_MANAGEDWORKPLACE_EDIT_CREDENTIALS,
} from 'client/redux/endpoint/managedworkplace/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isEditing: false,
  isReading: false,
  isUpdating: false,
  isTesting: false,
  error: null,
  endpoint: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(ENDPOINT_MANAGEDWORKPLACE_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS, state, action, {requestProp: 'isTesting', successSpread: true});
  switch(action.type) {
    case ENDPOINT_MANAGEDWORKPLACE_EDIT_CREDENTIALS: return {
      ...state,
      isEditing: action.isEditing
    };
    default:
      return state
  }
};
