import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import InstanceWidgetStatistic from 'client/components/Instance/components/InstanceWidgetStatistic';
import { instancePvtdReadStatistics } from 'client/redux/pvtd/statisticsActions';
import _ from 'lodash';

class ConnectedPVTDgetStatistic extends React.Component {

  static defaultProps = {
    query: {},
    componentProps: {}
  }

  static propTypes = {
    id: propTypes.string.isRequired,
    statistic: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    data: propTypes.object,
    Component: propTypes.elementType.isRequired,
    componentProps: propTypes.object,
    query: propTypes.object
  }

  componentDidMount() {
    const { id, statistic, query } = this.props;
    this.props.instancePvtdReadStatistics(id, statistic, query);
  }

  render() {
    const { title, Component, data, componentProps, isReading } = this.props;
    return (
      <InstanceWidgetStatistic
        isReading={isReading}
        title={title}
        Component={Component}
        componentProps={componentProps}
        data={data}
      />
    );
  }

}

const mapStateToProps =  (state, props) => {
  const id = props.statistic;
  return {
    ...(state.pvtd.statistics[id] || {}),
    data: _.get(state, ['pvtd', 'statistics', id, 'data', id], null)
  };
}

export default connect(mapStateToProps, { instancePvtdReadStatistics })(ConnectedPVTDgetStatistic);
