import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class SupportButtonDirection extends Component {

  static propTypes = {
    direction: propTypes.oneOf(['up', 'down']).isRequired,
    onClick: propTypes.func.isRequired,
    disabled: propTypes.bool.isRequired
  }

  render() {
    const { direction, onClick, disabled } = this.props;
    switch(direction) {
      default: return null;
      case 'up': return (
        <ButtonWaitingPromise className="btn btn-default btn-icon" onClick={onClick} disabled={disabled}>
          <i className="icon mdi mdi-long-arrow-up"></i>
        </ButtonWaitingPromise>
      );
      case 'down': return (
        <ButtonWaitingPromise className="btn btn-default btn-icon" onClick={onClick} disabled={disabled}>
          <i className="icon mdi mdi-long-arrow-down"></i>
        </ButtonWaitingPromise>
      );
    }
  }

}
