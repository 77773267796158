import * as constants from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  endpointType: '',
  isAdding: false,
  error: null,
};

export default function optionalEndpointReducer (state = defaultState, action) {
  state = reduceByTypes(constants.INSTANCE_OPTIONAL_ENDPOINT_CREATE, state, action, {
    requestProp: 'isAdding',
    successPickProps: []
  });

  switch (action.type) {
    default: return state;
    case constants.INSTANCE_OPTIONAL_ENDPOINT_SET_ENDPOINT_TYPE: return {
      ...state,
      endpointType: action.endpointType,
    };
  }
};
