import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { CardElement } from 'react-stripe-elements';
import propTypes from 'prop-types';
import { FormErrors } from 'client/components/Form/FormErrors';

export default class AccountAddCardField extends Component {

  static propTypes = {
    input: propTypes.object.isRequired,
    meta: propTypes.object.isRequired
  }

  state = {
    complete: false,
    pristine: true,
    error: null
  }

  handleChange = ev => {
    const { input } = this.props;
    const { error, complete } = ev;
    let completeChanged = this.state.complete !== complete;
    this.setState({pristine: false, complete, error}, () => {
      if(completeChanged) {
        input.onChange(complete);
      }
    });
  }

  getError() {
    return this.state.error ? this.state.error.message : null;
  }

  getValidationState() {
    const { error, complete, pristine } = this.state;
    if(pristine) return null;
    if(error) return 'error';
    if(complete) return 'success';
    return null;
  }

  render() {
    const { pristine } = this.state;
    return (
      <FormGroup validationState={this.getValidationState()} className="required">
        <ControlLabel>Card details</ControlLabel>
        <CardElement
          style={{base: {lineHeight: '38px'}}}
          hidePostalCode={true}
          onChange={this.handleChange}
        />
        <FormErrors pristine={pristine} ignorePristine={true} error={this.getError()} />
      </FormGroup>
    );
  }

}

