import React, { Component } from 'react';
import propTypes from 'prop-types';

export default class RouteRedirectTransform extends Component {

  static propTypes = {
    host: propTypes.string
  }

  componentDidMount() {
    const { host } = this.props;
    if(host) window.location.host = host;
  }

  render() {
    return <span>We are not supposed to be here</span>;
  }

}
