import { ACCOUNT_BILLING_UPDATE, ACCOUNT_BILLING, ACCOUNT_ADDRESS_UPDATE } from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: null,
  isUpdatingBilling: false,
  isUpdatingAddress: false,
  billing: null,
  address: null,
};

export default function billingReducer(state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_BILLING, state, action, {requestProp: 'isReading', successPickProps: ['billing', 'address']});
  state = reduceByTypes(ACCOUNT_BILLING_UPDATE, state, action, {requestProp: 'isUpdatingBilling'});
  state = reduceByTypes(ACCOUNT_ADDRESS_UPDATE, state, action, {requestProp: 'isUpdatingAddress'});
  return state;
};
