import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { BeFormFieldErrors } from 'client/components/Be';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { isFilled, isString, isEmail, isEqualTo } from 'client/redux/validators';
import { metaToValidationState } from 'client/redux/formHelpers';
import { ModalButton } from 'client/components/Helpers/Modal';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import MaybeBeFormFieldError from 'client/components/Be/MaybeBeFormFieldError';
import { FormReduxInput } from 'client/components/Form/FormRedux';

const validateByField = {
  email: [isString(), isFilled(), isEmail()],
  password: [isString(), isFilled()],
  hasAgreedToTOC: [isEqualTo(true, 'You must agree to the Terms and Conditions')]
};

class SignupForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired
  }

  renderCheckboxComponent = data => {
    return (
      <FormGroup validationState={metaToValidationState(data.meta)}>
        <div className="be-checkbox">
          <input id="signupFormHasAgreedToTOC" {...data.input} type={data.type} />
          <label htmlFor="signupFormHasAgreedToTOC">
            <span className="text">
              By creating an account, you agree to the{' '}
              <ModalButton anchor className="btn-link" ModalComponent={UIFragmentHelpModal} modalProps={{fragmentId: 'signup_terms'}}>
                terms and conditions and data processing agreement
              </ModalButton>.
            </span>
          </label>
        </div>
        <MaybeBeFormFieldError {...data.meta} />
      </FormGroup>
    );
  }

  render() {
    const metaOptions = {ignorePristine: true, showSuccess: true};
    return (
      <Form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <span className="splash-title xs-pb-20">New account</span>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={this.props.error} />
        <Field
          base="signupForm"
          name="email"
          component={FormReduxInput}
          type="email"
          placeholder="Enter E-mail"
          metaOptions={metaOptions}
          validate={validateByField.email}
        />
        <div className="row">
          <div className="col-xs-6">
            <Field
              base="signupForm"
              name="passwordOne"
              component={FormReduxInput}
              type="password"
              placeholder="Enter password"
              metaOptions={metaOptions}
              validate={validateByField.password}
            />
          </div>
          <div className="col-xs-6">
            <Field
              base="signupForm"
              name="passwordTwo"
              component={FormReduxInput}
              type="password"
              placeholder="Confirm"
              metaOptions={metaOptions}
              validate={validateByField.password}
            />
          </div>
        </div>
        <div className="form-group xs-pt-10">
          <ButtonWaiting type="submit" disabled={!this.props.valid} className="btn-block btn-primary btn-xl" isWaiting={this.props.submitting}>Sign up</ButtonWaiting>
        </div>
        <div className="form-group xs-pt-10">
          <Field
            name="hasAgreedToTOC"
            component={this.renderCheckboxComponent}
            type="checkbox"
            validate={validateByField.hasAgreedToTOC}
          />
        </div>
      </Form>
    );
  }

};

const validateForm = values => {
  const errors = {};
  if(values.passwordOne && values.passwordTwo) {
    if(values.passwordOne !== values.passwordTwo) {
      errors.passwordTwo = 'Does not match';
    }
  }
  return errors;
}

export default reduxForm({
  form: 'signup',
  validate: validateForm
})(SignupForm);
