import React from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { statusSelect2 } from 'client/redux/endpoint/constants';
import { EndpointIdSearcher, EndpointTypeSearcher, UserIdSearcher, ConnectorInstanceIdSearcher } from 'client/components/Form/Select2Searchers';

export default class EndpointTableFilterForm extends React.Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  handleChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render() {
    const { id, type, status, userId, connectorInstanceId } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <EndpointIdSearcher
              id="endpointTableFilterEndpointId"
              className="form-control"
              name="endpointId"
              onChange={value => this.handleChange({target: {name: 'id', value}})}
              options={{minimumResultsForSearch: 0, allowClear: true, placeholder: 'Any endpoint'}}
              defaultValue={id || ''}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <EndpointTypeSearcher
              id="endpointTableFilterType"
              className="form-control"
              name="type"
              onChange={val => this.handleChange({target: {name: 'type', value: val}})}
              options={{minimumResultsForSearch: 0, allowClear: true, placeholder: 'Any type', minimumInputLength: 0}}
              defaultValue={type || ''}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="endpointTableFilterStatus"
              name="status"
              className="form-control"
              data={statusSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any status'}}
              onChange={this.handleChange}
              defaultValue={status || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <UserIdSearcher
              id="endpointTableFilterUserId"
              className="form-control"
              name="userId"
              onChange={val => this.handleChange({target: {name: 'userId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any user'}}
              defaultValue={userId || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <ConnectorInstanceIdSearcher
              id="endpointTableFilterConnectorInstanceId"
              className="form-control"
              name="connectorInstanceId"
              onChange={val => this.handleChange({target: {name: 'connectorInstanceId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any instance'}}
              defaultValue={connectorInstanceId || ''}
            />
          </div>
        </div>
      </div>
    );
  }

}
