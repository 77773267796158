import React, { Component } from 'react';
import * as endpointEditPanelActions from 'client/redux/endpoint/editPanelActions';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import { connect } from 'react-redux';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { statusSelect2 } from 'client/redux/endpoint/constants';
import { Field } from 'redux-form';
import FormContainer from 'client/components/Form/FormContainer';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import EndpointGraphSettingsForm from 'client/components/Endpoint/components/EndpointGraphSettingsForm';
import _ from 'lodash';

class EndpointEditPanel extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    onUpdated: propTypes.func
  }

  handleUpdate = fields => {
    const { id, onUpdated } = this.props;
    return this.props.endpointEditPanelUpdate(id, fields).then(getResponseToFormErrorHandler()).then(onUpdated);
  }

  handleUpdateSettings = fields => {
    const { id, onUpdated } = this.props;
    const type = _.get(this.props, 'endpoint.type');
    return this.props.endpointEditPanelUpdateSettings(id, type, fields).then(getResponseToFormErrorHandler()).then(onUpdated);
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.endpointEditPanelRead(id);
  }

  getInitialFormValues() {
    const { status, settings = {} } = this.props.endpoint;
    return { status, settings };
  }

  renderForms() {
    const { endpoint } = this.props;
    if(!endpoint) return null;
    const { settings } = endpoint;
    const initialValues = _.pick(endpoint, 'settings', 'status');
    return (
      <div>
        <FormContainer
          onSubmit={this.handleUpdate}
          form="endpointFormStatus"
          initialValues={initialValues}
        >
          {formProps => (
            <>
              <Field
                label="Status"
                base="endpointPanelForm"
                name="status"
                component={FormReduxSelect2}
                data={statusSelect2}
              />
              <Field
                base="endpointPanelForm"
                name="settings.logLevel"
                component={FormReduxSelect2}
                label="Log Level"
                data={[
									'error',
									'warn',
									'info',
									'verbose',
									'debug',
									'silly',
                ]}
                select2Options={{
                  minimumInputLength: 0,
                  allowClear: true, 
                  placeholder: 'Default log level',
                }}
              />
              <ButtonWaiting
                type="submit"
                isWaiting={formProps.submitting}
                className="btn btn-primary btn-lg"
                disabled={formProps.submitting || !formProps.dirty}
              >
                Update
              </ButtonWaiting>
            </>
          )}
        </FormContainer>
        <hr />
        {endpoint.type === 'graph' && (
          <EndpointGraphSettingsForm
            initialValues={settings}
            onSubmit={this.handleUpdateSettings}
          />
        )}
      </div>
    );
  }

  render() {
    const { isReading, error } = this.props;
    return (
      <BeLoading loading={isReading} positionStatic={true}>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        {this.renderForms()}
      </BeLoading>
    );
  }

}

const actions = {
  ...endpointEditPanelActions,
};

export default connect(state => state.endpoint.editPanel, actions)(EndpointEditPanel);
