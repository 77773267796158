import { INSTANCE_SETTINGS_UPDATE, INSTANCE_SETTINGS_UPDATE_IN_PLACE } from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import _ from 'lodash';

const defaultState = {
  isUpdating: false,
  updatedSettingsInstanceId: null,
  updatedSettings: null,
  error: null,
};

export default function settingsReducer(state = defaultState, action) {

  state = reduceByTypes(INSTANCE_SETTINGS_UPDATE, state, action, {
    requestProp: 'isUpdating',
    successProp: (state, action, value) => ({
      updatedSettings: _.get(value, 'instance.settings', {}),
      updatedSettingsInstanceId: _.get(value, 'instance.id'),
    }),
  });

  switch(action.type) {
    default: return state;
    case INSTANCE_SETTINGS_UPDATE_IN_PLACE: return {
      ...state,
      updatedSettingsInstanceId: action.instanceId,
      updatedSettings: action.settings,
    };
  }
};
