import { ENDPOINT_INDEX, ENDPOINT_INDEX_ORDERBY, ENDPOINT_INDEX_SET_PAGE, ENDPOINT_INDEX_FILTER, ENDPOINT_INDEX_DELETE } from 'client/redux/endpoint/constants';
import * as actionCreators from 'client/redux/endpoint/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const endpointIndex = query => dispatch => {
  return dispatch(actionCreators.endpointIndex(ENDPOINT_INDEX, query));
};

export const endpointIndexDelete = query => dispatch => {
  return dispatch(actionCreators.endpointDelete(ENDPOINT_INDEX_DELETE, query));
};

export const endpointIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(ENDPOINT_INDEX_ORDERBY, orderBy, orderDirection));
};

export const endpointIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(ENDPOINT_INDEX_SET_PAGE, page));
};

export const endpointIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(ENDPOINT_INDEX_FILTER, filter));
};
