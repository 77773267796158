import * as actionCreators from 'client/redux/instance/actionCreators';
import { tableSetColumns } from 'client/redux/table/actionCreators';
import * as constants  from 'client/redux/instance/constants';

export const instanceIndex = query => dispatch => {
  return dispatch(actionCreators.instanceIndex(constants.INSTANCE_INDEX, query));
};

export const instanceDelete = id => dispatch => {
  return dispatch(actionCreators.instanceDelete(constants.INSTANCE_DELETE, id));
};

export const instanceClone = id => dispatch => {
  return dispatch(actionCreators.instanceClone(constants.INSTANCE_CLONE, id));
};

export const instanceIndexSetColumns = columns => dispatch => dispatch(tableSetColumns(constants.INSTANCE_INDEX_SET_COLUMNS, columns));
