import { USER_INDEX, USER_UPDATE, USER_DELETE, USER_INDEX_SET_COLUMNS } from 'client/redux/user/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import { reduceSetColumns } from 'client/redux/table/reducerHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isDeleting: false,
  users: null,
  columns: [
    {id: 'select', name: 'Select', visible: false},
    {id: 'id', name: 'Type/ID', visible: true},
    {id: 'createdAt', name: 'Created', visible: false},
    {id: 'updatedAt', name: 'Updated', visible: true},
    {id: 'deletedAt', name: 'Deleted', visible: false},
    {id: 'lastLoginAt', name: 'Last login', visible: true},
    {id: 'email', name: 'Email', visible: true},
    {id: 'role', name: 'Role', visible: true},
    {id: 'parentUserId', name: 'Parent User', visible: false},
    {id: 'stripeId', name: 'Stripe ID', visible: false},
    {id: 'isConfirmed', name: 'Confirmed', visible: false},
  ],
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(USER_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(USER_DELETE, state, action, {requestProp: 'isDeleting'});
  state = reduceByTypes(USER_UPDATE, state, action, {requestProp: 'isUpdating'});
  state = reduceSetColumns(USER_INDEX_SET_COLUMNS, state, action);
  return state;
};
