export const ENDPOINT_AZURE_OPEN_AI_PRELOADED = 'ENDPOINT_AZURE_OPEN_AI_PRELOADED';

export const ENDPOINT_AZURE_OPEN_AI_READ_REQUEST = 'ENDPOINT_AZURE_OPEN_AI_READ_REQUEST';
export const ENDPOINT_AZURE_OPEN_AI_READ_SUCCESS = 'ENDPOINT_AZURE_OPEN_AI_READ_SUCCESS';
export const ENDPOINT_AZURE_OPEN_AI_READ_FAILURE = 'ENDPOINT_AZURE_OPEN_AI_READ_FAILURE';
export const ENDPOINT_AZURE_OPEN_AI_READ = [ENDPOINT_AZURE_OPEN_AI_READ_REQUEST, ENDPOINT_AZURE_OPEN_AI_READ_SUCCESS, ENDPOINT_AZURE_OPEN_AI_READ_FAILURE];

export const ENDPOINT_AZURE_OPEN_AI_EDIT_SETTINGS = 'ENDPOINT_AZURE_OPEN_AI_EDIT_SETTINGS';

export const ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_REQUEST = 'ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_REQUEST';
export const ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_SUCCESS = 'ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_SUCCESS';
export const ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_FAILURE = 'ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_FAILURE';
export const ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS = [ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_REQUEST, ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_SUCCESS, ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS_FAILURE];
