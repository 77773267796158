import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import classNames from 'classnames';
import moment from 'moment';

class EndpointTestButton extends Component {

  static defaultProps = {
    labelOk: 'Login OK',
    labelTest: 'Test login'
  }

  static propTypes = {
    labelOk: propTypes.string,
    labelTest: propTypes.string,
    testTimestamp: propTypes.number,
    testOk: propTypes.bool,
    isTesting: propTypes.bool.isRequired,
    onTest: propTypes.func.isRequired
  }

  formatTestTimestamp() {
    const timestamp = this.props.testTimestamp;
    return moment(timestamp).format('MMM DD HH:mm');
  }

  renderButtonChildren() {
    const { testOk, labelOk, labelTest } = this.props;
    switch(true) {
        case testOk === true: return (
          <span>
            <i className="icon icon-left mdi mdi-check"></i>{' '}
            {labelOk} at {this.formatTestTimestamp()}
          </span>
        );
        case testOk === false: return (
          <span>
            <i className="icon icon-left mdi mdi-block"></i>{' '}
            Retry test?
          </span>
        );
        default: return (
          <span>
            {labelTest}
          </span>
        );
    }
  }

  getButtonClass() {
    const { testOk } = this.props;
    switch(true) {
      case testOk === true: return 'btn-success';
      case testOk === false: return 'btn-danger';
      default: return 'btn-default';
    }
  }

  render() {
    const { isTesting, onTest, className, isDisabled } = this.props;
    const classes = classNames('btn btn-rounded', className, this.getButtonClass());
    return (
      <ButtonWaiting type="button" className={classes} disabled={isDisabled} isWaiting={isTesting} onClick={onTest}>
        {this.renderButtonChildren()}
      </ButtonWaiting>
    );
  }

}

export default EndpointTestButton;
