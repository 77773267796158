export const INSTANCE_FATDI_TEST_OBJECT_ADD_REQUEST = 'INSTANCE_FATDI_TEST_OBJECT_ADD_REQUEST';
export const INSTANCE_FATDI_TEST_OBJECT_ADD_SUCCESS = 'INSTANCE_FATDI_TEST_OBJECT_ADD_SUCCESS';
export const INSTANCE_FATDI_TEST_OBJECT_ADD_FAILURE = 'INSTANCE_FATDI_TEST_OBJECT_ADD_FAILURE';
export const INSTANCE_FATDI_TEST_OBJECT_ADD = [INSTANCE_FATDI_TEST_OBJECT_ADD_REQUEST, INSTANCE_FATDI_TEST_OBJECT_ADD_SUCCESS, INSTANCE_FATDI_TEST_OBJECT_ADD_FAILURE];

export const INSTANCE_FATDI_TEST_OBJECT_REMOVE = 'INSTANCE_FATDI_TEST_OBJECT_REMOVE';
export const INSTANCE_FATDI_TEST_CLEAR = 'INSTANCE_FATDI_TEST_CLEAR';
export const INSTANCE_FATDI_TEST_OBJECT_SEARCH_FIELD_CHANGE = 'INSTANCE_FATDI_TEST_OBJECT_SEARCH_FIELD_CHANGE';

export const INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_REQUEST = 'INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_REQUEST';
export const INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_SUCCESS = 'INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_SUCCESS';
export const INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_FAILURE = 'INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_FAILURE';
export const INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH = [INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_REQUEST, INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_SUCCESS, INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH_FAILURE];

export const INSTANCE_FATDI_SYNC_OBJECT_REQUEST = 'INSTANCE_FATDI_SYNC_OBJECT_REQUEST';
export const INSTANCE_FATDI_SYNC_OBJECT_SUCCESS = 'INSTANCE_FATDI_SYNC_OBJECT_SUCCESS';
export const INSTANCE_FATDI_SYNC_OBJECT_FAILURE = 'INSTANCE_FATDI_SYNC_OBJECT_FAILURE';
export const INSTANCE_FATDI_SYNC_OBJECT = [INSTANCE_FATDI_SYNC_OBJECT_REQUEST, INSTANCE_FATDI_SYNC_OBJECT_SUCCESS, INSTANCE_FATDI_SYNC_OBJECT_FAILURE];

export const INSTANCE_FATDI_READ_STATISTICS_REQUEST = 'INSTANCE_FATDI_READ_STATISTICS_REQUEST';
export const INSTANCE_FATDI_READ_STATISTICS_SUCCESS = 'INSTANCE_FATDI_READ_STATISTICS_SUCCESS';
export const INSTANCE_FATDI_READ_STATISTICS_FAILURE = 'INSTANCE_FATDI_READ_STATISTICS_FAILURE';
export const INSTANCE_FATDI_READ_STATISTICS = [INSTANCE_FATDI_READ_STATISTICS_REQUEST, INSTANCE_FATDI_READ_STATISTICS_SUCCESS, INSTANCE_FATDI_READ_STATISTICS_FAILURE];

export const INSTANCE_FATDI_OPTIONS_READ_META_REQUEST = 'INSTANCE_FATDI_OPTIONS_READ_META_REQUEST';
export const INSTANCE_FATDI_OPTIONS_READ_META_SUCCESS = 'INSTANCE_FATDI_OPTIONS_READ_META_SUCCESS';
export const INSTANCE_FATDI_OPTIONS_READ_META_FAILURE = 'INSTANCE_FATDI_OPTIONS_READ_META_FAILURE';
export const INSTANCE_FATDI_OPTIONS_READ_META = [INSTANCE_FATDI_OPTIONS_READ_META_REQUEST, INSTANCE_FATDI_OPTIONS_READ_META_SUCCESS, INSTANCE_FATDI_OPTIONS_READ_META_FAILURE];
