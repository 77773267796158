import { uiProps } from 'client/redux/ui/actionCreators';
import { UI_PROPS } from 'client/redux/ui/constants';
import {
  INSTANCE_DASHBOARD_READ,
  INSTANCE_DASHBOARD_UPDATE_STATUS,
  INSTANCE_DASHBOARD_UPDATE_STATUS_SUCCESS,
  INSTANCE_DASHBOARD_DELETE,
  INSTANCE_DASHBOARD_DELETE_SUCCESS,
  INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS,
  INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_SUCCESS,
} from 'client/redux/instance/constants';
import * as actionCreators from 'client/redux/instance/actionCreators';

export const instanceDashboardRead = id => dispatch => {
  return dispatch(actionCreators.instanceRead(INSTANCE_DASHBOARD_READ, id));
};

export const instanceDashboardDelete = id => dispatch => {
  return dispatch(actionCreators.instanceDelete(INSTANCE_DASHBOARD_DELETE, id)).then(result => {
    if(result && result.type === INSTANCE_DASHBOARD_DELETE_SUCCESS) {
      return dispatch(uiProps(UI_PROPS, {refresh: true})).then(() => result);
    }
    return result;
  });
};

export const instanceDashboardUpdateStatus = (id, newStatus) => dispatch => {
  return dispatch(actionCreators.instanceUpdateStatus(INSTANCE_DASHBOARD_UPDATE_STATUS, id, newStatus)).then(result => {
    if(result && result.type === INSTANCE_DASHBOARD_UPDATE_STATUS_SUCCESS) {
      return dispatch(uiProps(UI_PROPS, {refresh: true})).then(res => true);
    }
    return false;
  });
};

export const instanceDashboardDeleteQueueContents = id => dispatch => {
  return dispatch(actionCreators.instanceDeleteQueueContents(INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS, id)).then(result => {
    if(result && result.type === INSTANCE_DASHBOARD_DELETE_QUEUE_CONTENTS_SUCCESS) {
      return dispatch(uiProps(UI_PROPS, {refresh: true})).then(() => result);
    }
    return result;
  });
};
