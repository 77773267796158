import React, { Component } from 'react';
import moment from 'moment';

export default class SplashFooter extends Component {

  year() {
    return moment().format('YYYY');
  }

  render() {
    return (
      <div className="splash-footer">
        © {this.year()}{' '}
        <a href="https://www.linknow.io">LINKNOW AB</a>
      </div>
    )
  }
}
