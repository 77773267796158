import { ENDPOINT_FILES_READ, ENDPOINT_FILES_PRELOADED, ENDPOINT_FILES_READ_DAV_INFO } from 'client/redux/endpoint/files/constants';
import * as actionCreators from 'client/redux/endpoint/files/actionCreators';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointFilesPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(ENDPOINT_FILES_PRELOADED, endpoint));
};

export const endpointFilesRead = endpointId => dispatch => {
  return dispatch(actionCreators.endpointFilesRead(ENDPOINT_FILES_READ, endpointId));
};

export const endpointFilesReadDavInfo = endpointId => dispatch => {
  return dispatch(actionCreators.endpointFilesReadDavInfo(ENDPOINT_FILES_READ_DAV_INFO, endpointId));
};
