import React  from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import Icon from 'client/components/Be/Icon';
import { connect } from 'react-redux';
import * as validators from 'client/redux/validators';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import { FormReduxInput, FormReduxSelect2, FormReduxTextarea } from 'client/components/Form/FormRedux';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as optionsActions from 'client/redux/aitd/optionsActions';
import Sticky from 'react-stickynode';

class ConnectedAITDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    return Promise.all([
      this.props.instanceAitdOptionsReadMeta(instanceId),
    ]);
  }

  handleReset = ev => {
    this.props.reset();
  }

  render() {
    const {
      dirty,
      disabled,
      invalid,
      isSetupMode,
      onSubmit,
      onNudgeStep,
      isUpdating,
    } = this.props;
    return (
      <Form id="instanceAitdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceAitdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20">
          <div className="row">
            <div className="col-md-6">
              {this.renderExternalSyncSettings()}
            </div>
            <div className="col-md-6">
              {this.renderAiSettings()}
            </div>
          </div>
        </div>
      </Form>
    );
  }

  renderExternalSyncSettings() {
    const {
      instanceId,
      disabled,
      initialValues: { basicAuthPassword = '' },
      errorMeta,
      meta,
      isReadingMeta,
    } = this.props;
    return (
      <div className="well xs-pb-10">
        <InstanceHelpHeader subTitle="Settings for a TOPdesk Action notifying an incident has been created">
          External TOPdesk Incident Callback
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorMeta} />
        <BeLoading loading={isReadingMeta} className="xs-mt-20">
          {meta && (
            <div>
              <div className="form-group">
                <label className="control-label" htmlFor="aitdOptionsBasicAuthUsername">
                  HTTP Basic Auth Username
                </label>
                <div className="input-group">
                  <input
                    id="aitdOptionsBasicAuthUsername"
                    className="form-control"
                    type="text"
                    readOnly
                    value={instanceId}
                  />
                  <QuickCopyAddon value={instanceId} />
                </div>
              </div>
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="basicAuthPassword"
                value={basicAuthPassword || ''}
                disabled={disabled}
                label="HTTP Basic Auth Password"
              />
              <div className="form-group">
                <label className="control-label" htmlFor="aitdOptionsTopdeskIncidentCallbackUrl">
                  URL to GET to
                </label>
                <div className="input-group">
                  <input
                    id="aitdOptionsTopdeskIncidentCallbackUrl"
                    className="form-control"
                    type="text"
                    readOnly
                    value={meta.topdeskIncidentCallbackUrl}
                  />
                  <QuickCopyAddon value={meta.topdeskIncidentCallbackUrl} />
                </div>
              </div>
            </div>
          )}
        </BeLoading>
      </div>
    );
  }

  renderAiSettings () {
    const {
      disabled,
      initialValues: {
        systemMessage = '', 
        indexName = '',
        aiSearchApiKey = '',
        top_p = 0.9,
        temperature = 0.8,
        max_tokens = 5000,
        strictness = 3,
        top_n_documents = 5,
        keywordsExcluded,
        keywordsIncluded,
        language,
      },
      errorMeta,
      meta,
      isReadingMeta,
    } = this.props;
    return (
      <div className="well xs-pb-10">
        <InstanceHelpHeader subTitle="Settings controlling the AI">
          AI Settings
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorMeta} />
        <BeLoading loading={isReadingMeta} className="xs-mt-20">
          {meta && (
            <div>
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="aiSearchApiKey"
                value={aiSearchApiKey || ''}
                disabled={disabled}
                label="Azure AI Search API key"
                required
                validate={validators.isNonEmptyString}
              />
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="indexName"
                value={indexName || ''}
                disabled={disabled}
                label="Azure AI Search Index Name"
                required
                validate={validators.isNonEmptyString}
              />
              <Field
                base="aitdOptions"
                component={FormReduxTextarea}
                name="systemMessage"
                value={systemMessage || ''}
                disabled={disabled}
                label="System message"
              />
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="top_p"
                value={top_p || 0.9}
                disabled={disabled}
                label="Top P"
                min={0}
                max={1}
                step={0.01}
              />
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="temperature"
                value={temperature || 0.8}
                disabled={disabled}
                label="Temperature"
                min={0}
                max={1}
                step={0.01}
              />
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="max_tokens"
                value={max_tokens || 10000}
                disabled={disabled}
                label="Max Tokens"
                type="number"
                min={1}
                max={1000}
              />
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="strictness"
                value={strictness || 3}
                disabled={disabled}
                label="Strictness"
                min={1}
                max={5}
                step={0.1}
              />
              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="top_n_documents"
                value={top_n_documents || 5}
                disabled={disabled}
                label="Top N Documents"
                min={1}
                max={5}
                step={1}
              />

            <Field
              base="aitdOptions"
              label="Language"
              name="language"
              component={FormReduxSelect2}
              data={[
                {id: 'sv', text: 'Swedish'},
                {id: 'en', text: 'English'},
              ]}
              defaultValue={language || ''}
              disabled={disabled}
            />

              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="keywordsIncluded"
                value={keywordsIncluded || 5}
                disabled={disabled}
                label="Included keywords"
                helpText="Comma-separated list"
              />

              <Field
                base="aitdOptions"
                component={FormReduxInput}
                name="keywordsExcluded"
                value={keywordsExcluded || 5}
                disabled={disabled}
                label="Excluded keywords"
                helpText="Comma-separated list"
              />
            </div>
          )}
        </BeLoading>
      </div>
    );
  }

  getDefaultSource(fieldName) {
    switch(fieldName) {
      default: return {type: 'FieldSourceIgnore'};
    }
  }

}

function mapStateToProps(state) {
  // const selector = formValueSelector('aitdOptions');
  // the value of these fields need to be available when rendering
  return {
    ...state.aitd.options,
  };
}

const actions = {
  ...optionsActions,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'aitdOptions',
  enableReinitialize: true,
})(ConnectedAITDOptionsForm));

function QuickCopyAddon(props) {
  const { value } = props;
  return (
    <CopyToClipboard text={value}>
      <div className="input-group-btn">
        <Button title="Copy to clipboard"><Icon icon="copy" /></Button>
      </div>
    </CopyToClipboard>
  );
}
