import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import propTypes from 'prop-types';

export default class Select2Wrap extends Component {

  static defaultProps = {
    onChange: function() {},
    options: {minimumResultsForSearch: Infinity}
  }

  static propTypes = {
    onChange: propTypes.func,
    unwrapValue: propTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {value: props.defaultValue};
  }

  componentDidUpdate (oldProps) {
    if (this.props.defaultValue !== oldProps.defaultValue) {
      this.setState({value: this.props.defaultValue});
    }
  }

  unwrapValue(value) {
    const data = this.props.data || [];
    const found = data.find(obj => String(obj.id) === value);
    return found ? found.id : value;
  }

  handleChange = ev => {
    const { unwrapValue } = this.props;
    setTimeout(() => {
      const value = unwrapValue ? this.unwrapValue(ev.target.value) : ev.target.value;
      this.setState({value}, () => {
        this.props.onChange(unwrapValue ? value : ev);
      });
    }, 1);
  }

  render() {
    const { value } = this.state;
    const props = {...this.props};
    delete props.defaultValue;
    delete props.unwrapValue;
    const style = {width: '100%', ...(this.props.style || {})};
    return (
      <Select2 {...props} style={style} onChange={this.handleChange} value={String(value)} />
    );
  }

}
