import _ from 'lodash';
import { ucfirst } from 'client/helpers/misc';

export const fieldSourceTypeToNatural = (type) => {
  switch(type) {
    default:
    case 'FieldSource': return type;
    case 'FieldSourceConstantPromise':
    case 'FieldSourceOther': return '(Other Source)';
    case 'FieldSourceUserInput': return 'Custom Input';
    case 'FieldSourceUserSelect': return 'Custom Select';
    case 'FieldSourceUserSelectFromResolver': return 'Custom Select Resolver';
    case 'FieldSourceResolve': return 'Resolve To';
    case 'FieldSourceConstant': return 'Constant';
    case 'FieldSourceToken': return 'Token String';
    case 'FieldSourceGraphGroupMember': return 'Graph Group Member';
    case 'FieldSourceGraphUser': return 'Graph User';
    case 'FieldSourceIgnore': return 'Ignore';
    case 'FieldSourceFSTDObject': return 'Column';
    case 'FieldSourceObject': return 'Field';
  }
};

export const fieldTargetTypeToNatural = type => {
  switch(type) {
    default: return type || '-';
    case 'FieldTargetPromise':
    case 'FieldTarget': return 'Any';
    case 'FieldTargetBool': return 'Boolean';
    case 'FieldTargetEnum': return 'Enum';
    case 'FieldTargetString': return 'String';
    case 'FieldTargetTopdeskId': return 'TOPdesk ID';
  }
};

export const fieldTargetPropToNatural = key => {
  switch(key) {
    default: return key;
    case 'displayName': return 'Natural name';
    case 'unwrap': return 'Unwrap';
    case 'min': return 'Minimum length';
    case 'max': return 'Maximum length';
    case 'list': return 'Available values';
    case 'locked': return 'Locked';
    case 'strict': return 'Strict';
    case 'required': return 'Required';
    case 'format': return 'Format';
    case 'stringNumberToIndex': return 'String numbers for list index?';
  }
};

export const fieldTargetPropToValue = (key, value) => {
  switch(key) {
    default: return value;
    case 'min': return value;
    case 'max': return value;
    case 'list': return value.join(', ');
    case 'required': return value ? 'Required' : 'Not required';
    case 'strict': return value ? 'Strict' : 'Not strict';
    case 'unwrap': return value ? 'Unwrapped' : 'Not unwrapped';
    case 'stringNumberToIndex': return value ? 'Yes' : 'No';
  }
};

export const fieldSourceToNatural = (source = {}, required, isDefaultSource = false) => {
  const { type, props = {} } = source;
  const typeNatural = fieldSourceTypeToNatural(type);
  const result = (function() {
    switch(type) {
      default:
      case 'FieldSource': return typeNatural;
      case 'FieldSourceFSTDObject': return `${typeNatural} (${props.fieldName})`;
      case 'FieldSourceObject': return `${typeNatural} (${props.contextKey || 'object'}.${props.fieldName})`;
      case 'FieldSourceToken':
      case 'FieldSourceConstantPromise':
      case 'FieldSourceConstant': return `${typeNatural} (${props.value})`;
      case 'FieldSourceGraphGroupMember': return `${typeNatural} (${props.groupId})`;
      case 'FieldSourceGraphUser': return `${typeNatural} (${graphUserFieldNameToNatural(props.fieldName)})`;
      case 'FieldSourceResolve': {
        const defaultValue = _.get(props, 'targetResolveOptions.defaultValue') || '';
        return `${typeNatural} (${defaultValue})`;
      }
    }
  })();
  // if there is no default source defined, assume the field is ignored
  if (typeof result === 'undefined') return isDefaultSource ? 'Default: Ignore' : '';
  const val = isDefaultSource ? 'Default: ' + result : result;
  return val;
};

export const targetToLabel = (target = {}) => {
  const { name } = target;
  return _.get(target, 'props.displayName', name);
};

export const topdeskSearchRemoteNameToNatural = searchRemoteName => {
  switch(searchRemoteName) {
    default: return searchRemoteName;
    case 'archivingReason': return 'Search Archiving Reasons by name';
    case 'asset': return 'Search Assets by name';
    case 'assetTemplate': return 'Search Asset Templates by name';
    case 'branch': return 'Search Branches by name';
    case 'callTypes': return 'Search Call Types by name';
    case 'categories': return 'Search Categories by name';
    case 'department': return 'Search Departments by name';
    case 'entryTypes': return 'Search Entry Types by name';
    case 'impacts': return 'Search Impacts by name';
    case 'languages': return 'Search Languages by name';
    case 'operator': return 'Search Operators by last name';
    case 'operatorGroups': return 'Search Operator Groups by name';
    case 'person': return 'Search Persons by last name';
    case 'processingStatus': return 'Search Processing Statuses by name';
    case 'subcategories': return 'Search Subcategories by name';
    case 'urgencies': return 'Search Urgencies by name';
    case 'suppliers': return 'Search Suppliers by name';
  }
}

export const topdeskFieldNameToNatural = fieldName => {

  if (fieldName && fieldName.startsWith('optionalFields')) {
    const [group, type] = fieldName.split('.');
    const groupNum = (group || '').slice(-1);
    const typeNum = (type || '').slice(-1);
    if (group && type && groupNum && typeNum) {
      const typeName = ucfirst(type.slice(0, -1));
      return `Opt. Fields ${groupNum}→${typeName} ${typeNum}`;
    }
  }

  switch(fieldName) {
    default: return ucfirst(fieldName);
    case 'accountManager': return 'Account Manager';
    case 'action': return 'Action';
    case 'address.addressMemo': return 'Address→AddressMemo';
    case 'address.city': return 'Address→City';
    case 'address.country': return 'Address→Country';
    case 'address.county': return 'Address→County';
    case 'address.number': return 'Address→Number';
    case 'address.postcode': return 'Address→Postcode';
    case 'address.street': return 'Address→Street';
    case 'authorizeAll': return 'Authorize from all';
    case 'authorizeBranch': return 'Authorize from branch';
    case 'authorizeBudgetHolder': return 'Authorize from budgetholder';
    case 'authorizeDepartment': return 'Authorize from department';
    case 'authorizeSubsidiaryBranches': return 'Authorize from subsidiary branches';
    case 'branch': return 'Branch/Site';
    case 'briefDescription': return 'Brief Description';
    case 'budgetHolder': return 'Budget Holder';
    case 'callType': return 'Call Type';
    case 'caller.branch': return 'Caller→Branch';
    case 'caller.department': return 'Caller→Department';
    case 'caller.dynamicName': return 'Caller→Dynamic Name';
    case 'caller.email': return 'Caller→Email';
    case 'caller.id': return 'Caller→ID';
    case 'caller.location': return 'Caller→Location';
    case 'caller.mobileNumber': return 'Caller→Mobile Number';
    case 'caller.phoneNumber': return 'Caller→Phone Number';
    case 'callerLookup.email': return 'Caller Lookup→Email';
    case 'callerLookup.employeeNumber': return 'Caller Lookup→Employee Number';
    case 'callerLookup.id': return 'Caller Lookup→ID';
    case 'callerLookup.loginName': return 'Caller Lookup→Login Name';
    case 'callerLookup.networkLoginName': return 'Caller Lookup→Network Login Name';
    case 'category': return 'Category';
    case 'changeActivitiesOperator': return 'Change Activities Operator';
    case 'changeCoordinator': return 'Change Coordinator';
    case 'contractManager': return 'Contract Manager';
    case 'department': return 'Department';
    case 'departmentFree': return 'Department (Free)';
    case 'email': return 'Email';
    case 'employeeNumber': return 'Employee Number';
    case 'entryType': return 'Entry Type';
    case 'extensiveChangeOperator': return 'Extensive Change Operator';
    case 'externalHelpDeskParty': return 'External Helpdesk Party';
    case 'externalLink.id': return 'External Link→ID';
    case 'externalLink.type': return 'External Link→Type';
    case 'externalNumber': return 'External Number';
    case 'fax': return 'Fax';
    case 'firstInitials': return 'First Initials';
    case 'firstLineCallOperator': return 'First Line Call Operator';
    case 'firstName': return 'First Name';
    case 'gender': return 'Gender';
    case 'impact': return 'Impact';
    case 'installer': return 'Installer';
    case 'isManager': return 'Is Manager';
    case 'jobTitle': return 'Job Title';
    case 'knowledgeBaseManager': return 'Knowledgebase Manager';
    case 'language': return 'Language';
    case 'location': return 'Location/Room';
    case 'location.id': return 'Location→ID';
    case 'loginName': return 'Login Name';
    case 'loginPermission': return 'Login Permission';
    case 'mainframeLoginName': return 'Mainframe Login Name';
    case 'mobileNumber': return 'Mobile Number';
    case 'networkLoginName': return 'Network Login Name';
    case 'object.name': return 'Object→Name';
    case 'operationsManager': return 'Operations Manager';
    case 'operationsOperator': return 'Operations Operator';
    case 'operator': return 'Operator';
    case 'operatorGroup': return 'Operator Group';
    case 'password': return 'Password';
    case 'phoneNumber': return 'Phone Number';
    case 'planningActivityManager': return 'Planning Activity Manager';
    case 'postalAddress.addressMemo': return 'Postal Address→AddressMemo';
    case 'postalAddress.city': return 'Postal Address→City';
    case 'postalAddress.country': return 'Postal Address→Country';
    case 'postalAddress.county': return 'Postal Address→County';
    case 'postalAddress.number': return 'Postal Address→Number';
    case 'postalAddress.postcode': return 'Postal Address→Postcode';
    case 'postalAddress.street': return 'Postal Address→Street';
    case 'prefixes': return 'Prefixes';
    case 'problemManager': return 'Problem Manager';
    case 'problemOperator': return 'Problem Operator';
    case 'projectActiviesOperator': return 'Project Activities Operator';
    case 'projectCoordinator': return 'Project Coordinator';
    case 'request': return 'Request';
    case 'requestForChangeOperator': return 'Request For Change Operator';
    case 'reservationsOperator': return 'Reservations Operator';
    case 'scenarioManager': return 'Scenario Manager';
    case 'secondLineCallOperator': return 'Second Line Call Operator';
    case 'serviceOperator': return 'Service Operator';
    case 'showBranch': return 'Show branch';
    case 'showBudgetholder': return 'Show budgetholder';
    case 'showDepartment': return 'Show department';
    case 'showSubsidiaries': return 'Show subsidiaries';
    case 'simpleChangeOperator': return 'Simple Change Operator';
    case 'status': return 'Status';
    case 'stockManager': return 'Stock Manager';
    case 'subcategory': return 'Sub Category';
    case 'supplier': return 'Suppliers';
    case 'surName': return 'Surname';
    case 'tasLoginName': return 'Tas Login Name';
    case 'telephone': return 'Telephone';
    case 'title': return 'Title';
    case 'urgency': return 'Urgency';
  }
};

export const graphUserFieldNameToNatural = fieldName => {
  switch(fieldName) {
    default: return fieldName;
    case 'aboutMe': return 'About Me';
    case 'accountEnabled': return 'Account Enabled';
    case 'assignedLicenses': return 'Assigned Licenses';
    case 'assignedPlans': return 'Assigned Plans';
    case 'birthday': return 'Birthday';
    case 'businessPhones': return 'Business Phones';
    case 'city': return 'City';
    case 'companyName': return 'Company Name';
    case 'country': return 'Country';
    case 'department': return 'Department';
    case 'departmentFree': return 'Department (text)';
    case 'displayName': return 'Display Name';
    case 'givenName': return 'Given Name';
    case 'hireDate': return 'Hire Date';
    case 'id': return 'ID';
    case 'imAddresses': return 'IM Addresses';
    case 'interests': return 'Interests';
    case 'jobTitle': return 'Job Title';
    case 'mail': return 'Mail';
    case 'mailboxSettings': return 'Mailbox Settings';
    case 'mailNickname': return 'Mail Nickname';
    case 'mobilePhone': return 'Mobile Phone';
    case 'mySite': return 'My Site';
    case 'officeLocation': return 'Office Location';
    case 'onPremisesImmutableId': return 'On Premises Immutable ID';
    case 'onPremisesLastSyncDateTime': return 'On Premises Last Sync Date/Time';
    case 'onPremisesSecurityIdentifier': return 'On Premises Security ID';
    case 'onPremisesSyncEnabled': return 'On Premises Sync Enabled';
    case 'passwordPolicies': return 'Password Policies';
    case 'passwordProfile': return 'Password Profile';
    case 'pastProjects': return 'Past Projects';
    case 'postalCode': return 'Postal Code';
    case 'preferredLanguage': return 'Preferred Language';
    case 'preferredName': return 'Preferred Name';
    case 'provisionedPlans': return 'Provisioned Plans';
    case 'proxyAddresses': return 'Proxy Addresses';
    case 'responsibilities': return 'Responsibilities';
    case 'schools': return 'Schools';
    case 'skills': return 'Skills';
    case 'state': return 'State';
    case 'streetAddress': return 'Street Address';
    case 'surname': return 'Surname';
    case 'usageLocation': return 'Usage Location';
    case 'userPrincipalName': return 'Principal Name';
    case 'userType': return 'Type';
    case 'hasManager': return 'Has Manager';
    case 'hasDirectReports': return 'Is Manager';
  }
};
