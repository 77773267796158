import * as constants from 'client/redux/endpoint/bankid/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isEditing: false,
  isReading: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
  endpoint: null,
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(constants.ENDPOINT_BANKID_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(constants.ENDPOINT_BANKID_DELETE, state, action, {requestProp: 'isDeleting', successSpread: true});
  state = reduceByTypes(constants.ENDPOINT_BANKID_UPDATE_SETTINGS, state, action, {requestProp: 'isUpdating', successSpread: true});
  switch (action.type) {
    default: return state
    case constants.ENDPOINT_BANKID_EDIT_SETTINGS: return {
      ...state,
      isEditing: action.isEditing,
    };
    case constants.ENDPOINT_BANKID_PRELOADED: return {
      ...state,
      isReading: false,
      endpoint: action.endpoint,
      error: null,
    };
  }
};
