import * as actionCreators from 'client/redux/endpoint/snow/actionCreators';
import * as constants from 'client/redux/endpoint/snow/constants';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointSnowPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(constants.ENDPOINT_SNOW_PRELOADED, endpoint));
};

export const endpointSnowRead = id => dispatch => {
  return dispatch(actionCreators.endpointSnowRead(constants.ENDPOINT_SNOW_READ, id));
};

export const endpointSnowEditSettings = isEditing => dispatch => {
  return dispatch(actionCreators.endpointSnowEditSettings(constants.ENDPOINT_SNOW_EDIT_SETTINGS, isEditing));
};

export const endpointSnowUpdateSettings = (id, settings) => dispatch => {
  return dispatch(actionCreators.endpointSnowUpdateSettings(constants.ENDPOINT_SNOW_UPDATE_SETTINGS, id, settings));
};

export const endpointSnowTest = (id) => dispatch => {
  return dispatch(actionCreators.endpointSnowTest(constants.ENDPOINT_SNOW_TEST, id));
};
