import * as constants from 'client/redux/pvtd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  errorMeta: null,
  isReadingMeta: false,
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(constants.INSTANCE_PVTD_READ_META, state, action, {
    requestProp: 'isReadingMeta',
    errorProp: 'errorMeta',
    successProp: 'meta',
  });
  return state;
};
