export const ENDPOINT_SKATTEVERKET_PRELOADED = 'ENDPOINT_SKATTEVERKET_PRELOADED';

export const ENDPOINT_SKATTEVERKET_READ_REQUEST = 'ENDPOINT_SKATTEVERKET_READ_REQUEST';
export const ENDPOINT_SKATTEVERKET_READ_SUCCESS = 'ENDPOINT_SKATTEVERKET_READ_SUCCESS';
export const ENDPOINT_SKATTEVERKET_READ_FAILURE = 'ENDPOINT_SKATTEVERKET_READ_FAILURE';
export const ENDPOINT_SKATTEVERKET_READ = [ENDPOINT_SKATTEVERKET_READ_REQUEST, ENDPOINT_SKATTEVERKET_READ_SUCCESS, ENDPOINT_SKATTEVERKET_READ_FAILURE];

export const ENDPOINT_SKATTEVERKET_DELETE_REQUEST = 'ENDPOINT_SKATTEVERKET_DELETE_REQUEST';
export const ENDPOINT_SKATTEVERKET_DELETE_SUCCESS = 'ENDPOINT_SKATTEVERKET_DELETE_SUCCESS';
export const ENDPOINT_SKATTEVERKET_DELETE_FAILURE = 'ENDPOINT_SKATTEVERKET_DELETE_FAILURE';
export const ENDPOINT_SKATTEVERKET_DELETE = [ENDPOINT_SKATTEVERKET_DELETE_REQUEST, ENDPOINT_SKATTEVERKET_DELETE_SUCCESS, ENDPOINT_SKATTEVERKET_DELETE_FAILURE];

export const ENDPOINT_SKATTEVERKET_EDIT_SETTINGS = 'ENDPOINT_SKATTEVERKET_EDIT_SETTINGS';

export const ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_REQUEST = 'ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_REQUEST';
export const ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_SUCCESS = 'ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_SUCCESS';
export const ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_FAILURE = 'ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_FAILURE';
export const ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS = [ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_REQUEST, ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_SUCCESS, ENDPOINT_SKATTEVERKET_UPDATE_SETTINGS_FAILURE];

export const ENDPOINT_SKATTEVERKET_TEST_REQUEST = 'ENDPOINT_SKATTEVERKET_TEST_REQUEST';
export const ENDPOINT_SKATTEVERKET_TEST_SUCCESS = 'ENDPOINT_SKATTEVERKET_TEST_SUCCESS';
export const ENDPOINT_SKATTEVERKET_TEST_FAILURE = 'ENDPOINT_SKATTEVERKET_TEST_FAILURE';
export const ENDPOINT_SKATTEVERKET_TEST = [ENDPOINT_SKATTEVERKET_TEST_REQUEST, ENDPOINT_SKATTEVERKET_TEST_SUCCESS, ENDPOINT_SKATTEVERKET_TEST_FAILURE];
