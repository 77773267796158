import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import { BeWizardTitle } from 'client/components/Be/BeWizard';

export default class InstanceSetupDone extends Component {

  static propTypes = {
    onActivate: propTypes.func.isRequired
  }

  render() {
    const { onActivate } = this.props;
		return (
      <div>
        <BeWizardTitle subTitle="You can now activate the connector">Done!</BeWizardTitle>
        <div className="alert alert-icon alert-success xs-mt-20">
          <div className="icon"><span className="mdi mdi-check"></span></div>
          <div className="message">
            <strong>Congratulations!</strong> You have now completed the setup wizard and the connector can be activated. You can always change the options later.
          </div>
        </div>
        <div className="well text-center xs-mb-10">
          <ButtonWaitingPromise type="button" className="btn btn-success btn-lg" onClick={onActivate}>Activate the connector</ButtonWaitingPromise>
        </div>
      </div>
		);
  }
}
