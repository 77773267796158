import { reduceByTypes } from 'client/redux/apiHelpers';
import { DASHBOARD_INSTANCE_LIST } from 'client/redux/dashboard/constants';

const defaultState = {
  isReading: false,
  instances: null,
  error: null,
};

export default function instanceReducer(state = defaultState, action) {
  state = reduceByTypes(DASHBOARD_INSTANCE_LIST, state, action, {requestProp: 'isReading', successSpread: true, successPickProps: ['instances']});
  return state;
};
