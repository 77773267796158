import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import classNames from 'classnames';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import Icon from 'client/components/Be/Icon';
import { FormErrors } from 'client/components/Form/FormErrors';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { moveValueInList } from 'client/helpers/misc';
import { metaToValidationState, getReduxFieldId as getId } from 'client/redux/formHelpers';
import SwitchButton from 'client/components/Form/SwitchButton';
import UIFragmentHelpButton from 'client/components/UIFragment/UIFragmentHelpButton';
import 'client/components/Form/FormRedux.css';

export class FormReduxSelect2 extends React.Component {

  render() {
    const {
      helpFragmentId,
      input,
      disabled,
      label,
      helpText,
      meta,
      select2Component,
      select2ComponentProps,
      name,
      data,
      style,
      className,
    } = this.props;

    const id = getId(this.props);
    const Select2Wrapper = select2Component ? select2Component : Select2Wrap;
    const select2WrapperProps = select2ComponentProps ? select2ComponentProps : {};
    const select2Options = {minimumResultsForSearch: 20, ...this.props.select2Options};

    const metaOptions = getMetaOptions(this.props);

    return (
      <FormGroup className={className} controlId={id} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel label={label} helpFragmentId={helpFragmentId} />
        <Select2Wrapper
          id={id}
          name={name}
          className="form-control"
          options={select2Options}
          style={{width: '100%', ...style}}
          {...input}
          {...select2WrapperProps}
          defaultValue={input.value}
          data={data}
          disabled={disabled}
        />
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  
  }

}

export class FormReduxSelect2InputGroup extends React.Component {

  static propTypes = {
    before: propTypes.node,
    after: propTypes.node,
  }

  render() {
    const {
      input,
      disabled,
      label,
      helpText,
      meta,
      select2Component,
      select2ComponentProps,
      name,
      data,
      style,
      className,
      before,
      after,
    } = this.props;

    const id = getId(this.props);
    const Select2Wrapper = select2Component ? select2Component : Select2Wrap;
    const select2WrapperProps = select2ComponentProps ? select2ComponentProps : {};
    const select2Options = {minimumResultsForSearch: 20, ...this.props.select2Options};

    const metaOptions = getMetaOptions(this.props);

    return (
      <FormGroup className={className} controlId={id} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel label={label} />
        <div className="input-group">
          {before}
          <Select2Wrapper
            id={id}
            name={name}
            className="form-control"
            options={select2Options}
            style={{width: '100%', ...style}}
            {...input}
            {...select2WrapperProps}
            defaultValue={input.value}
            data={data}
            disabled={disabled}
          />
          {after}
        </div>
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  
  }

}

export class FormReduxInput extends React.Component {

  render() {
    const { input, disabled, label, meta, type, placeholder, defaultValue, helpText, className } = this.props;
    const id = getId(this.props);
    const metaOptions = getMetaOptions(this.props);

    return (
      <FormGroup className={className} controlId={id} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel label={label} />
        <input
          id={id}
          className="form-control"
          placeholder={placeholder}
          {...input}
          type={type}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }
}

export class FormReduxInputGroup extends React.Component {

  static propTypes = {
    before: propTypes.node,
    after: propTypes.node,
  }

  render() {
    const { input, disabled, label, meta, type, placeholder, defaultValue, helpText, className, before, after } = this.props;
    const id = getId(this.props);
    const metaOptions = getMetaOptions(this.props);

    return (
      <FormGroup className={className} controlId={id} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel label={label} />
        <div className="input-group">
          {before}
          <input
            id={id}
            className="form-control"
            placeholder={placeholder}
            {...input}
            type={type}
            defaultValue={defaultValue}
            disabled={disabled}
          />
          {after}
        </div>
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }
}

export class FormReduxTextarea extends React.Component {

  static defaultProps = {
    rows: 5
  }

  render() {
    const {
      className,
      defaultValue,
      disabled,
      helpText,
      after,
      input,
      label,
      meta,
      placeholder,
      rows,
      type,
    } = this.props;
    const id = getId(this.props);
    const metaOptions = getMetaOptions(this.props);

    return (
      <FormGroup
        className={className}
        controlId={id}
        validationState={metaToValidationState(meta, metaOptions)}
      >
        <FormReduxControlLabel label={label} />
        <textarea
          id={id}
          className="form-control"
          placeholder={placeholder}
          {...input}
          type={type}
          rows={rows}
          defaultValue={defaultValue}
          disabled={disabled}
        ></textarea>
        {after}
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }
}

export function FormReduxTextareaJSON (props) {
  const {
    input,
    disabled,
    label,
    meta,
    type,
    placeholder,
    getDefaultValue = () => ({}),
    helpText,
    className,
    showAllRows,
    rows,
  } = props;

  const defaultStrValue = JSON.stringify(input.value || getDefaultValue(), null, 2);
  const [strValue, setStrValue] = React.useState(defaultStrValue);

  let error = false;
  try {
    JSON.parse(strValue);
  } catch (err) {
    error = true;
  }

  const rowsCount = React.useMemo(() => {
    if(showAllRows) {
      return (strValue || '').split('\n').length;
    }
    return rows;
  }, [strValue, rows, showAllRows]);

  const id = getId(props);
  const metaOptions = getMetaOptions(props);

  const onChange = ev => {
    setStrValue(ev.target.value);
  };

  const onBlur = ev => {
    try {
      const parsed = JSON.parse(strValue);
      input.onBlur(parsed);
      setStrValue(JSON.stringify(parsed, null, 2));
    } catch (err) {
    }
  };

  return (
    <FormGroup
      className={className}
      controlId={id}
      validationState={metaToValidationState({...meta, error}, metaOptions)}
    >
      <FormReduxControlLabel label={label} />
      <textarea
        id={id}
        className="form-control"
        placeholder={placeholder}
        {...input}
        type={type}
        rows={rowsCount}
        value={strValue}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        style={{fontFamily: 'monospace', fontSize: '90%'}}
      ></textarea>
      <FormControl.Feedback />
      <FormErrors {...meta} {...metaOptions} />
      <FormReduxHelpText helpText={helpText} />
    </FormGroup>
  );
}

export class FormReduxCheckbox extends React.Component {

  static propTypes = {
    renderCheckboxes: propTypes.func,
    checkboxes: propTypes.array,
    type: propTypes.string,
    checkboxContainerClassName: propTypes.string,
  }

  static defaultProps = {
    type: 'radio'
  }

  renderCheckboxes() {
    const {
      checkboxes,
      input,
      disabled,
      type,
      checkboxContainerClassName,
    } = this.props;
    return checkboxes.map((checkbox, index) => {
      const id = getId(this.props, checkbox.value);
      return (
        <div key={String(index)} className={classNames('inline', checkboxContainerClassName, `be-${type}`)}>
          <input
            {...input}
            id={id}
            disabled={disabled}
            type={type}
            value={checkbox.value}
            checked={input.checked}
          />
          <label htmlFor={id}>{checkbox.label}</label>
        </div>
      );
    });
  }

  render() {
    const { label, meta, helpText, className } = this.props;
    const metaOptions = getMetaOptions(this.props);
    return (
      <FormGroup className={className} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel label={label} />
        <div>
          {this.renderCheckboxes()}
        </div>
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }

}

/**
 * @desc Special checkbox for input addons (no form-group)
 */
export class FormReduxCheckboxAddon extends FormReduxCheckbox {

  render() {
    return (
      <div>
        {this.renderCheckboxes()}
      </div>
    );
  }

}

export class FormReduxCheckboxCustomRenderer extends FormReduxCheckbox {

  renderCheckboxes() {
    const { checkboxes, input, disabled, type, CheckboxListComponent } = this.props;
    return <CheckboxListComponent
      checkboxes={checkboxes}
      input={input}
      disabled={disabled}
      type={type}
    />
  }

}

export class FormOrderTable extends React.Component {

  static propTypes = {
    list: propTypes.arrayOf(propTypes.object),
  }

  getList () {
    const { list } = this.props;
    const value = this.getValue();

    const listValues = list.map(o => o.value);
    const validValues = value.filter(value => listValues.includes(value));

    const union = _.union(validValues, listValues);
    return union.map(value => list.find(o => o.value === value));
  }

  getValue () {
    const { list, input: { value } } = this.props;
    if (value && Array.isArray(value)) {
      return value;
    }
    return list.map(o => o.value);
  }

  handleMoveUp = ev => {
    const { input } = this.props;
    const list = this.getList().map(o => o.value);
    const which = ev.currentTarget.value;
    const newValue = moveValueInList(list, which, -1);
    input.onChange(newValue);
  }

  handleMoveDown = ev => {
    const { input } = this.props;
    const list = this.getList().map(o => o.value);
    const which = ev.currentTarget.value;
    const newValue = moveValueInList(list, which, 1);
    input.onChange(newValue);
  }

  render() {
    const { label, meta, helpText, className, disabled } = this.props;
    const metaOptions = getMetaOptions(this.props);
    const list = this.getList();
    return (
      <FormGroup className={className} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel className="xs-mb-5" label={label} />
        <div className="row xs-pl-15 xs-pr-15">
          <table className="table table-condensed xs-mb-0">
            <tbody>
              {list.map((obj, index) => (
                <tr key={obj.value}>
                  <td>{obj.label}</td>
                  <td className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-default btn-icon"
                      disabled={disabled || index === 0}
                      value={obj.value}
                      onClick={this.handleMoveUp}
                    >
                      <Icon icon="long-arrow-up" />
                    </button>{' '}
                    <button
                      type="button"
                      className="btn btn-sm btn-default btn-icon"
                      disabled={disabled || index === list.length - 1}
                      value={obj.value}
                      onClick={this.handleMoveDown}
                    >
                      <Icon icon="long-arrow-down" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }

}

export class FormReduxCheckboxList extends React.Component {

  static propTypes = {
    type: propTypes.string,
    checkboxContainerClassName: propTypes.string,
    checkboxes: propTypes.arrayOf(propTypes.shape({
      value: propTypes.string.isRequired,
      label: propTypes.string.isRequired,
    })),
  }

  handleChange = ev => {
    const { target } = ev;
    const { input } = this.props;

    const value = _.isArray(input.value) ? input.value : [];
    if(value.includes(target.value) && !target.checked) {
      input.onChange(_.without(value, target.value));
    } else if(!value.includes(target.value) && target.checked) {
      input.onChange([...value, target.value]);
    }
  }

  renderCheckboxes() {
    const {
      checkboxes,
      input,
      disabled,
      type,
      checkboxContainerClassName,
    } = this.props;
    const value = _.isArray(input.value) ? input.value : [];
    return checkboxes.map((checkbox, index) => {
      const id = getId(this.props, checkbox.value);
      const classes = classNames(checkboxContainerClassName, `be-${type}`);
      return (
        <div key={String(index)} className={classes}>
          <input
            {...input}
            id={id}
            disabled={disabled}
            type={type}
            value={checkbox.value}
            checked={value.includes(checkbox.value)}
            onChange={this.handleChange}
          />
          <label htmlFor={id}>{checkbox.label}</label>
        </div>
      );
    });
  }

  render() {
    const { label, meta, helpText, className } = this.props;
    const metaOptions = getMetaOptions(this.props);
    return (
      <FormGroup className={className} validationState={metaToValidationState(meta, metaOptions)}>
        <FormReduxControlLabel className="xs-mb-5" label={label} />
        <div className="row xs-pl-15 xs-pr-15">
          {this.renderCheckboxes()}
        </div>
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }

}

FormReduxCheckboxCustomRenderer.propTypes = {
  ...FormReduxCheckbox.propTypes,
  CheckboxListComponent: propTypes.elementType.isRequired,
};

export class FormReduxHelpText extends React.Component {

  static propTypes = {
    helpText: propTypes.node,
  }

  render() {
    const { helpText } = this.props;
    if(!helpText) return null;
    return <p className="help-block text-overflow-ellipsis">{helpText}</p>;
  }

}

export class FormReduxControlLabel extends React.Component {

  static propTypes = {
    label: propTypes.string,
    helpFragmentId: propTypes.string,
  }

  renderHelpFragmentId() {
    const { helpFragmentId } = this.props;
    if(!helpFragmentId) return null;
    return (
      <UIFragmentHelpButton
        className="btn btn-rounded btn-default btn-xs pull-right xs-ml-5"
        style={{marginBottom: '-2px'}}
        fragmentId={helpFragmentId}
      />
    );
  }

  render() {
    const { label, className} = this.props;
    if(!label) return null;
    return (
      <ControlLabel className={className} bsClass="control-label">
        {label}
        {this.renderHelpFragmentId()}
      </ControlLabel>
    );
  }

}

export class FormReduxSwitchButton extends React.Component {

  render() {
    const {
      className,
      switchButtonClassName,
      disabled,
      helpText,
      input,
      label,
      meta,
    } = this.props;
    const id = getId(this.props);
    const metaOptions = getMetaOptions(this.props);
    return (
      <FormGroup className={className} controlId={id} validationState={metaToValidationState(meta, metaOptions)}>
        <SwitchButton
          id={id}
          className={switchButtonClassName}
          {...input}
          disabled={disabled}
        />
        <FormReduxControlLabel label={label} className="xs-ml-10" />
        <FormControl.Feedback />
        <FormErrors {...meta} {...metaOptions} />
        <FormReduxHelpText helpText={helpText} />
      </FormGroup>
    );
  }

}

// return default meta options
function getMetaOptions(props = {}) {
  const metaOptions = props.metaOptions || {};
  return {
    ignorePristine: false,
    defaultState: null,
    ...metaOptions
  };
}
