import { UI_FRAGMENTS_READ, UI_FRAGMENTS_UPDATE, UI_FRAGMENTS_CREATE } from 'client/redux/uiFragment/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  fragments: {}
};

// reduces a single fragment
export const singleFragmentReducer = (fragment = {}, action) => {
  fragment = reduceByTypes(UI_FRAGMENTS_CREATE, fragment, action, {
    requestProp: 'isCreating',
    successProp: (state, action, payload) => ({fragment: payload.fragment})
  });
  fragment = reduceByTypes(UI_FRAGMENTS_READ, fragment, action, {
    requestProp: 'isReading',
    successProp: (state, action, payload) => ({fragment: payload.fragment}),
    errorProp: (state, action, error) => {
      if(error && error.status === 404) return ({fragment: {isNew: true}});
      return ({error});
    }
  });
  fragment = reduceByTypes(UI_FRAGMENTS_UPDATE, fragment, action, {
    requestProp: 'isUpdating',
    successProp: (state, action, payload) => ({fragment: payload.fragment})
  });
  return fragment;
}

// reduces an object of many fragments
export const fragmentsReducer = (state, action) => {
  if(![...UI_FRAGMENTS_READ, ...UI_FRAGMENTS_UPDATE, ...UI_FRAGMENTS_CREATE].includes(action.type)) {
    // this action does not concern us...
    return state;
  }

  const fragmentId = action.meta.id;
  return {
    ...state,
    fragments: {
      ...state.fragments,
      [fragmentId]: {...singleFragmentReducer(state.fragments[fragmentId], action)}
    }
  };
}

export default function singleReducer(state = defaultState, action) {
  state = fragmentsReducer(state, action);
  return state;
};
