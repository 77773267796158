export const ENDPOINT_FILES_PRELOADED = 'ENDPOINT_FILES_PRELOADED';

export const ENDPOINT_FILES_READ_REQUEST = 'ENDPOINT_FILES_READ_REQUEST';
export const ENDPOINT_FILES_READ_SUCCESS = 'ENDPOINT_FILES_READ_SUCCESS';
export const ENDPOINT_FILES_READ_FAILURE = 'ENDPOINT_FILES_READ_FAILURE';
export const ENDPOINT_FILES_READ = [ENDPOINT_FILES_READ_REQUEST, ENDPOINT_FILES_READ_SUCCESS, ENDPOINT_FILES_READ_FAILURE];

export const ENDPOINT_FILES_READ_DAV_INFO_REQUEST = 'ENDPOINT_FILES_READ_DAV_INFO_REQUEST';
export const ENDPOINT_FILES_READ_DAV_INFO_SUCCESS = 'ENDPOINT_FILES_READ_DAV_INFO_SUCCESS';
export const ENDPOINT_FILES_READ_DAV_INFO_FAILURE = 'ENDPOINT_FILES_READ_DAV_INFO_FAILURE';
export const ENDPOINT_FILES_READ_DAV_INFO = [ENDPOINT_FILES_READ_DAV_INFO_REQUEST, ENDPOINT_FILES_READ_DAV_INFO_SUCCESS, ENDPOINT_FILES_READ_DAV_INFO_FAILURE];
