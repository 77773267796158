import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DASHBOARD_NEWS_DELETE_SUCCESS } from 'client/redux/dashboard/constants';
import BeLoading from 'client/components/Be/BeLoading';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { TimeRelative } from 'client/components/Helpers/Time';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import { ModalButton, ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import NewsNewModal from 'client/components/News/components/NewsNewModal';
import NewsReadModal from 'client/components/News/components/NewsReadModal';
import NewsEditModal from 'client/components/News/components/NewsEditModal';
import * as newsActions from 'client/redux/dashboard/newsActions';

class DashboardNews extends Component {

  getData = () => {
    return this.props.dashboardNewsLatest();
  }

  componentDidMount() {
    return this.getData();
  }

  renderPublishedAt(publishedAt) {
    if(!publishedAt) return 'Unpublished';
    return <TimeRelative time={publishedAt} />;
  }

  confirmDeleteProps = {
    title: 'Confirm news deletion',
    text: 'Are you sure you want to delete this news item?'
  }

  onDeleteNews = id => {
    return this.props.dashboardNewsDelete(id).then(result => {
      if(result && result.type === DASHBOARD_NEWS_DELETE_SUCCESS) {
        return this.getData();
      }
    });
  }

  renderNewsItems(items) {
    if(!items) return null;
    if(!items.length) return (
      <tr>
        <td className="empty" colSpan="2">No news to show</td>
      </tr>
    );
    return items.map(item => {
      const { publishedAt, title, id } = item;
      return (
        <tr key={id}>
          <td>{this.renderPublishedAt(publishedAt)}</td>
          <td>
            <ModalButton className="btn-link" title="Read news" ModalComponent={NewsReadModal} modalProps={{newsItem: item}}>
              {title}
            </ModalButton>
            <IsAdmin>
              <div className="btn-group pull-right">
                <ModalButton className="btn btn-default btn-xs" title="Edit news" ModalComponent={NewsEditModal} modalProps={{newsItem: item, onNewsWasUpdated: this.getData}}>
                  <span className="icon mdi mdi-edit"></span>
                </ModalButton>
                <ModalConfirmButton className="btn btn-default btn-xs" callback={() => this.onDeleteNews(id)} contentProps={this.confirmDeleteProps} Content={ModalContentConfirmWarning}>
                  <i className="icon mdi mdi-delete"></i>
                </ModalConfirmButton>
              </div>
            </IsAdmin>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { error, newsItems, isReading } = this.props;
    return (
      <div className="panel panel-default panel-table panel-border">
        <BeLoading loading={isReading}>
          <div className="panel-heading panel-heading-divider">
            <span className="title">Latest news</span>
            <IsAdmin>
              <div className="tools">
                <ModalButton className="btn-link" title="Create news item" ModalComponent={NewsNewModal} modalProps={{onNewsWasCreated: this.getData}}>
                  <i className="icon mdi mdi-plus-circle-o"></i>
                </ModalButton>
              </div>
            </IsAdmin>
          </div>
          <div className="panel-body table-responsive">
            <BeRemoteErrorAlert error={error} className="xs-ml-20 xs-mt-10 xs-mr-20" />
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {this.renderNewsItems(newsItems)}
              </tbody>
            </table>
          </div>
          {/* <div className="panel-footer xs-pt-10 xs-pb-10"> */}
            {/*   <form action="#"> */}
              {/*     <div className="form-group xs-mb-0"> */}
                {/*       <div className="be-checkbox inline"> */}
                  {/*         <input id="check6" type="checkbox" checked="" /> */}
                  {/*         <label htmlFor="check6">Yes, subscribe me to the newsletter</label> */}
                  {/*       </div> */}
                {/*     </div> */}
              {/*   </form> */}
            {/*   <div className="clearfix"></div> */}
            {/* </div> */}
        </BeLoading>
      </div>
    );
  }
}

const actions = {
  ...newsActions
};

export default connect(state => state.dashboard.news, actions)(DashboardNews);
