import rootReducer from 'client/redux/rootReducer';
import createHistory from 'history/createBrowserHistory';
import { compose, createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { apiMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, autoRehydrate } from 'redux-persist';
import apiErrorInterceptorMiddleware from 'client/redux/middleware/apiErrorInterceptor';
import errorRouter from 'client/redux/middleware/errorRouter';
import authRouter from 'client/redux/middleware/authRouter';

export const history = createHistory();

const middleware = [
  thunkMiddleware,
  apiMiddleware,
  apiErrorInterceptorMiddleware,
  errorRouter,
  authRouter,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger({
    predicate: (getState, action) => !/^@@redux-form/.test(action.type)
  }));
}

export default function configureStore (initialState = {}) {
  const middlewares = [
    autoRehydrate(),
    applyMiddleware(...middleware),
  ];
  if(window.__REDUX_DEVTOOLS_EXTENSION__) {
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const enhancer = compose(...middlewares);
  const store = createStore(rootReducer(history), {}, enhancer);

  persistStore(store, {
    whitelist: ['auth'],
  });
  return store;
};
