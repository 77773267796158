import { GRITTER_ALERT_PUSH, GRITTER_ALERT_DISMISS } from 'client/redux/gritter/constants';

const defaultState = {
  alerts: [],
};

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case GRITTER_ALERT_PUSH: return {
      ...state,
      alerts: [...state.alerts, {...action.props, id: action.id}]
    };
    case GRITTER_ALERT_DISMISS : return {
      ...state,
      alerts: state.alerts.filter(alert => alert.id !== action.id)
    };
    default:
      return state
  }
};
