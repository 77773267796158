import { combineReducers } from 'redux';

import index from 'client/redux/user/indexReducer';
import logIndex from 'client/redux/user/log/indexReducer';
import invoiceIndex from 'client/redux/user/invoice/indexReducer';
import invoiceRead from 'client/redux/user/invoice/readReducer';
import subscriptionIndex from 'client/redux/user/subscription/indexReducer';
import editPanel from 'client/redux/user/editPanelReducer';
import newPanel from 'client/redux/user/newPanelReducer';

const userReducer = combineReducers({
  index,
  logIndex,
  subscriptionIndex,
  editPanel,
  newPanel,
  invoiceIndex,
  invoiceRead,
});

export default userReducer;
