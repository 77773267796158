import React from 'react';
import AppHasLoaded from 'client/components/App/AppHasLoaded';
import { connect } from 'react-redux';
import * as printPageActions from 'client/redux/qrtd/printPageActions';
import PrintPage from 'client/components/App/PrintPage';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import QRTDPrintPageToolbox from 'client/components/QRTD/QRTDPrintPageToolbox';
import {
  QRTDPrintCode,
  QRTDURLGenerator,
  interpolateText,
  getCodeType,
} from 'client/components/QRTD/QRTDPrintPageHelpers';
import * as qrtdHelpers from 'client/helpers/qrtdHelpers';
import { Prompt } from 'react-router';
import { createCsv, downloadAsCsv } from 'client/helpers/misc';
import _ from 'lodash';
import 'client/components/QRTD/QRTDPrintPage.css';

class ConnectedQRTDPrintPage extends React.Component {

  componentDidMount() {
    const { chosenAssetIds, chosenLocationIds } = this.props;
    const instanceId = _.get(this.props, 'match.params.instanceId');
    this.props.instanceQrtdPrintPageReadInstanceMeta(instanceId).then(result => {
      const topdeskEndpointId = _.get(result, 'payload.endpointsByType.topdesk.id');
      const p = [];
      if (topdeskEndpointId) {
        splitIntoPages(chosenAssetIds, 50, id => {
          const query = {id};
          p.push(this.props.instanceQrtdPrintPageReadAssets(topdeskEndpointId, query));
        });
        splitIntoPages(chosenLocationIds, 50, id => {
          const query = {id};
          p.push(this.props.instanceQrtdPrintPageReadLocations(topdeskEndpointId, query));
        });
      }
      return Promise.all(p);
    });
  }

  handleDownloadCsv = () => {
    const { codes, instance } = this.props;
    if(!codes) return;
    const header = ['URL', 'Top', 'Bottom'];

    let rows;
    if(instance.settings.signedUrlsIsRequired) {
      rows = [['Unfortunately CSV export is not available when signed URLs are required', '', '']];
    } else {
      rows = codes.map(code => {
        const codeSettings = this.getCodeSettingsByCodeId(code.id);
        const props = {
          assetId: _.get(code, 'asset.unid'),
          locationId: _.get(code, 'location.id'),
          instanceId: instance.id,
          signingKey: instance.settings.signingKey,
          signedUrlsIsRequired: instance.settings.signedUrlsIsRequired || false,
          settings: _.pick(codeSettings, 'features', 'fieldMapOrdinal', 'infoTextOrdinal'),
        };
        const url = qrtdHelpers.getUrl(process.env.REACT_APP_QR_URL_BASE, 2, props);
        return [
          url,
          codeSettings.topText,
          codeSettings.bottomText,
        ];
      });
    }

    const csv = createCsv(header, ...rows);
    downloadAsCsv('qrcodes.csv', csv);
  }

  handleCreateCode = () => {
    return this.props.instanceQrtdPrintPageCreateCode();
  }

  handleSettingsChange = (key, value) => {
    return this.props.instanceQrtdPrintPageUpdateSetting(key, value);
  }

  handleQrCodeSettingsUpdate = (codeId, settings) => {
    return this.props.instanceQrtdPrintPageUpdateCodeSetting(codeId, settings);
  }

  getCodeSettingsByCodeId(codeId) {
    const { codes, codeSettingsByCodeId } = this.props;
    const customCodeSettings = codeSettingsByCodeId[codeId] || {};
    const defaultCodeSettings = _.get(this.props, 'settings.defaultCodeSettings') || {};
    const code = codes.find(({id}) => id === codeId);
    const codeType = getCodeType(code);
    const object = code[codeType];

    const codeSettings = {
      ...defaultCodeSettings,
      ...customCodeSettings,
      topText: customCodeSettings.topText || interpolateText(object, defaultCodeSettings.topText),
      bottomText: customCodeSettings.bottomText || interpolateText(object, defaultCodeSettings.bottomText),
    };

    // enforce some logic on which codes support which features
    if (codeType === 'empty') {
      codeSettings.features = codeSettings.features ? codeSettings.features.filter(f => ['info', 'reportIncident'].includes(f)) : [];
    }

    return codeSettings;
  }

  render() {
    const {
      instance,
      infoTexts,
      incidentFieldMaps,
      isReadingAssets,
      isReadingLocations,
      codes,
      settings,
      error
    } = this.props;
    const { size, renderQrCodes } = settings;
    return (
      <>
        {error && (
          <div className="qrtd-print-page">
            <div className="qrtd-print-page-container xs-pt-20">
              <BeRemoteErrorAlert error={error} />
            </div>
          </div>
        )}
        <AppHasLoaded when={Boolean(instance)}>
          <div className="qrtd-print-page">
            <Prompt when={true} message="If you leave the page any settings or edits made will be lost" />
            <QRTDPrintPageToolbox
              onChangeValue={this.handleSettingsChange}
              onDownloadCsv={this.handleDownloadCsv}
              onCreateCode={this.handleCreateCode}
              infoTexts={infoTexts}
              incidentFieldMaps={incidentFieldMaps}
              {...settings}
            />
            <PrintPage size="a4" padding="10mm">
              <BeLoading loading={isReadingAssets || isReadingLocations}>
                <ul className="qrtd-print-page-code-list">
                  {codes && codes.map(code => (
                    <li key={code.id}>
                      <QRTDURLGenerator
                        assetId={_.get(code, 'asset.unid')}
                        locationId={_.get(code, 'location.id')}
                        instanceId={instance.id}
                        signingKey={instance.settings.signingKey}
                        signedUrlsIsRequired={instance.settings.signedUrlsIsRequired || false}
                        codeSettings={this.getCodeSettingsByCodeId(code.id)}
                      >
                        {url => (
                          <QRTDPrintCode
                            code={code}
                            codeSettings={this.getCodeSettingsByCodeId(code.id)}
                            infoTexts={infoTexts}
                            incidentFieldMaps={incidentFieldMaps}
                            onUpdateCodeSettings={this.handleQrCodeSettingsUpdate}
                            renderQrCode={renderQrCodes}
                            size={size}
                            url={url}
                          />
                        )}
                      </QRTDURLGenerator>
                    </li>
                  ))}
                </ul>
              </BeLoading>
            </PrintPage>
          </div>
        </AppHasLoaded>
      </>
    );
  }
}

export default connect(state => state.qrtd.printPage, printPageActions)(ConnectedQRTDPrintPage);

function splitIntoPages (arr, pageLength, onPage) {
  if (!Array.isArray(arr) || arr.length < 1) return;
  for (let i = 0; i < arr.length; i += pageLength) {
    const page = arr.slice(i, i + pageLength);
    onPage(page);
  }
}
