export const USER_SUBSCRIPTION_INDEX_REQUEST = 'USER_SUBSCRIPTION_INDEX_REQUEST';
export const USER_SUBSCRIPTION_INDEX_SUCCESS = 'USER_SUBSCRIPTION_INDEX_SUCCESS';
export const USER_SUBSCRIPTION_INDEX_FAILURE = 'USER_SUBSCRIPTION_INDEX_FAILURE';
export const USER_SUBSCRIPTION_INDEX = [USER_SUBSCRIPTION_INDEX_REQUEST, USER_SUBSCRIPTION_INDEX_SUCCESS, USER_SUBSCRIPTION_INDEX_FAILURE];

export const USER_SUBSCRIPTION_READ_REQUEST = 'USER_SUBSCRIPTION_READ_REQUEST';
export const USER_SUBSCRIPTION_READ_SUCCESS = 'USER_SUBSCRIPTION_READ_SUCCESS';
export const USER_SUBSCRIPTION_READ_FAILURE = 'USER_SUBSCRIPTION_READ_FAILURE';
export const USER_SUBSCRIPTION_READ = [USER_SUBSCRIPTION_READ_REQUEST, USER_SUBSCRIPTION_READ_SUCCESS, USER_SUBSCRIPTION_READ_FAILURE];

export const USER_SUBSCRIPTION_DELETE_REQUEST = 'USER_SUBSCRIPTION_DELETE_REQUEST';
export const USER_SUBSCRIPTION_DELETE_SUCCESS = 'USER_SUBSCRIPTION_DELETE_SUCCESS';
export const USER_SUBSCRIPTION_DELETE_FAILURE = 'USER_SUBSCRIPTION_DELETE_FAILURE';
export const USER_SUBSCRIPTION_DELETE = [USER_SUBSCRIPTION_DELETE_REQUEST, USER_SUBSCRIPTION_DELETE_SUCCESS, USER_SUBSCRIPTION_DELETE_FAILURE];

export const USER_SUBSCRIPTION_INDEX_ORDERBY = 'USER_SUBSCRIPTION_INDEX_ORDERBY';

export const USER_SUBSCRIPTION_INDEX_SET_PAGE = 'USER_SUBSCRIPTION_INDEX_SET_PAGE';

export const USER_SUBSCRIPTION_INDEX_FILTER = 'USER_SUBSCRIPTION_INDEX_FILTER';

export const statusSelect2 = [
  {id: 'trialing', text: 'Trialing'},
  {id: 'active', text: 'Active'},
  {id: 'past_due', text: 'Past Due'},
  {id: 'canceled', text: 'Canceled'},
  {id: 'unpaid', text: 'Unpaid'}
];

export const paranoidSelect2 = [
  {id: '0', text: 'Include archived'},
  {id: '1', text: 'Hide archived'},
];
