import { ACCOUNT_CARD_LIST, ACCOUNT_CARD_DELETE } from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: null,
  isDeleting: null,
  cards: null
};

export default function cardReducer(state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_CARD_LIST, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(ACCOUNT_CARD_DELETE, state, action, {requestProp: 'isDeleting', successSpread: true});
  return state;
};

