import React, { Component } from 'react';
import { setBodyClass } from 'client/redux/bodyClass';
import { App, MainContent } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { SplashFooter } from 'client/components/Branding';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

class ErrorSplash extends Component {

  static propTypes = {
    code: propTypes.string,
    shortTitle: propTypes.string,
    longTitle: propTypes.string,
    showGoHome: propTypes.bool
  }

  defaultPropTypes = {
    showGoHome: true
  }

  renderGoHome() {
    const { showGoHome } = this.props;
    if(!showGoHome) return null;
    return (
      <div>
        <div className="error-goback-text">Would you like to go home?</div>
        <div className="error-goback-button">
          <Link to="/" className="btn btn-xl btn-primary">Let's go home</Link>
        </div>
      </div>
    );
  }

  render() {
    const { code, shortTitle, longTitle } = this.props;
    setBodyClass('be-splash-screen');
    return (
      <App className="be-error be-error-404">
        <Helmet>
          <title>{code} - {shortTitle}</title>
        </Helmet>
        <MainContent className="container-fluid">
          <div className="main-content container-fluid">
            <div className="error-container">
              <div className="error-number">{code}</div>
              <div className="error-description">{longTitle}</div>
              {this.renderGoHome()}
              <SplashFooter className="footer" />
            </div>
          </div>
        </MainContent>
      </App>
    );
  }
}

export default ErrorSplash;

export const ErrorNotFound = props => {
  return (
    <ErrorSplash
      code="404"
      shortTitle="Not Found"
      longTitle="The page you are looking for might have been removed."
      showGoHome={true}
    />
  );
};

export const ErrorForbidden = props => {
  return (
    <ErrorSplash
      code="403"
      shortTitle="Forbidden"
      longTitle="You are not authorized to view this page."
      showGoHome={true}
    />
  );
};
