import React, { Component } from 'react';
import { connect } from 'react-redux';
import BeLoading from 'client/components/Be/BeLoading';
import { IsRole } from 'client/components/Helpers/IsRole';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import UIFragmentSingle from 'client/components/UIFragment/UIFragmentSingle';
import { Link } from 'react-router-dom';
import * as instanceActions from 'client/redux/dashboard/instanceActions';
import { ModalButton } from 'client/components/Helpers/Modal';
import { instanceStatusToClass, instanceStatusToName } from 'client/redux/instance/instanceHelpers';
import { instanceTitle } from 'client/redux/instance/instanceHelpers';
import InstanceTitleModal from 'client/components/Instance/InstanceTitleModal';
import classNames from 'classnames';

class DashboardInstances extends Component {

  getData = () => {
    return this.props.dashboardInstanceList();
  }

  componentDidMount() {
    return this.getData();
  }

  handleTitleModalClose = ({wasUpdated = false} = {}) => {
    if(wasUpdated) return this.getData();
  }

  renderInstances(instances = []) {
    if(!instances || !instances.length) {
      return <li className="list-group-item">No connector instances yet</li>
    }
    return instances.map(instance => {
      const { status, id, type } = instance;
      const connector = instance.Connector;
      return (
        <li key={id} className="list-group-item">
          <div className="btn-group btn-group-xs pull-right">
            <IsRole requiredRole={['admins', 'partners']}>
              <ModalButton
                className="btn btn-default"
                title="Rename connector instance"
                ModalComponent={InstanceTitleModal}
                onClose={this.handleTitleModalClose}
                modalProps={{instance, defaultTitle: instanceTitle(instance, connector, false)}}
              >
                Rename
              </ModalButton>
            </IsRole>
            <button type="button" className={classNames(`btn-${instanceStatusToClass(status)}`, 'btn btn-static')}>
              {instanceStatusToName(status)}
            </button>
          </div>
          <h4 className="list-group-item-heading">
            <Link to={`/instance/${type}/${id}`}>
              {instanceTitle(instance, connector, false)}{' '}
            </Link>
          </h4>
          <UIFragmentSingle className="list-group-item-text" fragmentId={`instance_${instance.type}_description_short`} />
        </li>
      );
    });
  }

  render() {
    const { error, instances, isReading } = this.props;
    return (
      <div className="panel panel-border">
        <BeLoading loading={isReading}>
          <div className="panel-heading panel-heading-divider xs-pb-20">Active Connectors</div>
          <div className="panel-body">
            <BeRemoteErrorAlert error={error} />
            <ul className="list-group xs-mb-0">
              {this.renderInstances(instances)}
            </ul>
          </div>
          <div className="panel-footer xs-pt-10">
            <Link className="btn btn-default pull-right" to="/marketplace">Go to Marketplace</Link>
            <div className="clearfix"></div>
          </div>
        </BeLoading>
      </div>
    );
  }
}

const actions = {
  ...instanceActions,
};

export default connect(state => state.dashboard.instance, actions)(DashboardInstances);
