import {
  INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ,
  INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
  INSTANCE_OPTD_OPTIONS_READ_META,
} from 'client/redux/optd/constants';
import { endpointTopdeskAssetTemplateFieldTargets } from 'client/redux/endpoint/topdesk/actionCreators';
import { instanceOptdReadMeta } from 'client/redux/optd/actionCreators';

export const instanceOptdOptionsAssetFieldTargetsRead = (topdeskEndpointId, assetTemplateId) => dispatch => dispatch(endpointTopdeskAssetTemplateFieldTargets(INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_READ.map(type => ({type, meta: {assetTemplateId}})), topdeskEndpointId, assetTemplateId));

export const instanceOptdOptionsAssetFieldTargetsReset = () => dispatch => dispatch({
  type: INSTANCE_OPTD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
});

export const instanceOptdOptionsReadMeta = instanceId => dispatch => dispatch(instanceOptdReadMeta(INSTANCE_OPTD_OPTIONS_READ_META, instanceId));
