import React, { Component } from 'react';
import * as actions from 'client/redux/gritter/actions';
import { connect } from 'react-redux';
import GritterAlert from 'client/components/Gritter/components/GritterAlert';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import 'client/components/Gritter/Gritter.css';

class GritterContainer extends Component {

  static defaultProps = {
    alerts: []
  }

  handleDismiss = id => ev => {
    return this.props.gritterAlertDismiss(id);
  }

  renderItems() {
    const { alerts } = this.props;
    return alerts.map((alert, index) => {
      return (
        <CSSTransition key={index} classNames="alert" timeout={{enter: 500, exit: 300}}>
          <GritterAlert id={alert.id} {...alert} onDismiss={this.handleDismiss(alert.id)} />
        </CSSTransition>
      );
    });
  }

  render() {
    return (
      <div id="gritter-notice-wrapper" className="gritter-main-wrapper">
        <TransitionGroup>
          {this.renderItems()}
        </TransitionGroup>
      </div>
    );
  }

}

export default connect(state => state.gritter, actions)(GritterContainer);
