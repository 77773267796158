import React, { Component } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import Alert from 'client/components/Be/Alert';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import { isApiValidationResultError, responseToErrorMessage } from 'client/redux/apiHelpers';

class BeRemoteErrorAlert extends Component {

  static propTypes = {
    error: propTypes.object,
    ignoreValidationResult: propTypes.bool,
    icon: propTypes.bool,
    canShowDetails: propTypes.bool,
  }

  static defaultProps = {
    className: '',
    icon: true,
  }

  getCode(error) {
    return _.get(error, 'response.code', null) || error.status || '';
  }

  render() {
    const { error, canShowDetails, ignoreValidationResult, className, icon } = this.props;
    if(!error) return null;
    if(isApiValidationResultError(error) && ignoreValidationResult) return null;
    let message = responseToErrorMessage(_.get(error, 'response', {}), false);
    if(!message) message = responseToErrorMessage(error, true);
    const classes = classNames('alert-contrast', className);
    return (
      <Alert alertType="danger" className={classes} icon={icon}>
        <strong>Error {this.getCode(error)}: </strong> {message}
        {canShowDetails && (
          <div className="xs-mt-10">
            <ModalButton
              className="btn btn-default btn-xs"
              ModalComponent={ModalFormattedObject}
              modalProps={{object: error, title: 'Error Details', inspector: 'object'}}
            >
              <i className="icon mdi mdi-search xs-mr-0"></i>
              {' '}Details
            </ModalButton>
          </div>
        )}
      </Alert>
    );
  }

}

export default BeRemoteErrorAlert;
