import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';
import { graphUseBetaVersionSelect2 } from 'client/redux/endpoint/constants';

class EndpointGraphSettingsForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
  }

  fieldProps = {base: 'endpointGraphSettingsForm', metaOptions: {defaultState: null, ignorePristine: false}}

  render() {
    const { handleSubmit, onSubmit, submitting, invalid, error } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <Field
          {...this.fieldProps}
          component={FormReduxSelect2}
          label="Use API Beta Version"
          data={graphUseBetaVersionSelect2}
          name="useBetaVersion"
          select2Options={{allowClear: true, placeholder: 'Use default value'}}
          select2ComponentProps={{unwrapValue: true}}
        />
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'endpointGraphSettingsForm',
  enableReinitialize: true
})(EndpointGraphSettingsForm);
