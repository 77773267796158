import * as c from 'client/redux/account/constants';
import * as actionCreators from 'client/redux/account/actionCreators';

export const accountSubscriptionIndex = () => dispatch => {
  return dispatch(actionCreators.accountSubscriptionIndex(c.ACCOUNT_SUBSCRIPTION_INDEX));
};

export const accountSubscriptionCreate = body => dispatch => {
  return dispatch(actionCreators.accountSubscriptionCreate(c.ACCOUNT_SUBSCRIPTION_CREATE, body));
};

export const accountSubscriptionUpdate = (subscriptionId, body) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionUpdate(c.ACCOUNT_SUBSCRIPTION_UPDATE, subscriptionId, body));
};

export const accountSubscriptionDelete = (subscriptionId) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionDelete(c.ACCOUNT_SUBSCRIPTION_DELETE, subscriptionId));
};

export const accountSubscriptionReadPaymentIntent = (subscriptionId) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionReadPaymentIntent(c.ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT, subscriptionId));
};

export const accountSubscriptionPreview = (subscriptionId, body) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionPreview(c.ACCOUNT_SUBSCRIPTION_PREVIEW, subscriptionId, body));
};

export const accountSubscriptionNew = () => dispatch => {
  return dispatch(actionCreators.accountSubscriptionNew(c.ACCOUNT_SUBSCRIPTION_NEW));
};

export const accountSubscriptionChangePlanQuantity = (subscriptionId, planId, quantity) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionChangePlanQuantity(c.ACCOUNT_SUBSCRIPTION_CHANGE_PLAN_QUANTITY, subscriptionId, planId, quantity));
};
  
export const accountSubscriptionRemovePlan = (subscriptionId, planId, deleted = true) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionRemovePlan(c.ACCOUNT_SUBSCRIPTION_REMOVE_PLAN, subscriptionId, planId, deleted));
};

export const accountSubscriptionAddPlan = (subscriptionId, planId) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionAddPlan(c.ACCOUNT_SUBSCRIPTION_ADD_PLAN, subscriptionId, planId));
};

export const accountSubscriptionUndo = (subscriptionId) => dispatch => {
  return dispatch(actionCreators.accountSubscriptionUndo(c.ACCOUNT_SUBSCRIPTION_UNDO, subscriptionId));
};

export const accountSubscriptionToggleFixProblemModal = (subscriptionId, open) => dispatch => dispatch({type: c.ACCOUNT_SUBSCRIPTION_TOGGLE_FIX_PROBLEM_MODAL, open, meta: {subscriptionId}});
