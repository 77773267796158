import { internalError, authError } from 'client/redux/appError/actions';

const apiErrorInterceptor = store => dispatch => action => {
  if(action.error && action.payload instanceof Error) {
    const payload = action.payload;
    switch(payload.name) {
      default: return dispatch(action);
      case 'RequestError':
      case 'InvalidRSAA':
        return internalError(payload)(dispatch);
      case 'ApiError':
        switch(payload.status) {
            default: return dispatch(action);
            case 401: return authError(payload)(dispatch);
        }
    }
  }
  return dispatch(action);
};

export default apiErrorInterceptor;
