export const ENDPOINT_LOG_INDEX_REQUEST = 'ENDPOINT_LOG_INDEX_REQUEST';
export const ENDPOINT_LOG_INDEX_SUCCESS = 'ENDPOINT_LOG_INDEX_SUCCESS';
export const ENDPOINT_LOG_INDEX_FAILURE = 'ENDPOINT_LOG_INDEX_FAILURE';
export const ENDPOINT_LOG_INDEX = [ENDPOINT_LOG_INDEX_REQUEST, ENDPOINT_LOG_INDEX_SUCCESS, ENDPOINT_LOG_INDEX_FAILURE];

export const ENDPOINT_LOG_INDEX_ORDERBY = 'ENDPOINT_LOG_INDEX_ORDERBY';

export const ENDPOINT_LOG_INDEX_SET_PAGE = 'ENDPOINT_LOG_INDEX_SET_PAGE';

export const ENDPOINT_LOG_INDEX_FILTER = 'ENDPOINT_LOG_INDEX_FILTER';

export const levelSelect2 = [
  {id: 'error', text: 'Error'},
  {id: 'warning', text: 'Warning'},
  {id: 'info', text: 'Info'},
  {id: 'success', text: 'Success'}
];
