import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import _ from 'lodash';

export default class MarketplaceItem extends Component {

  static propTypes = {
    connector: propTypes.object.isRequired,
    instances: propTypes.array,
    onInstantiate: propTypes.func,
    onSelectPlan: propTypes.func,
    html: propTypes.node,
    hasSubscription: propTypes.bool,
    isInstantiating: propTypes.bool,
    maxInstances: propTypes.number,
  }

  handleClickInstantiate = ev => {
    const { connector } = this.props;
    return this.props.onInstantiate(connector.id);
  }

  // just get the first instance
  getInstance() {
    const { instances = [] } = this.props;
    return _.first(instances) || {};
  }

  hasMaxInstances() {
    const { maxInstances, instances } = this.props;
    const numInstances = instances && instances.length ? instances.length : 0;
    return numInstances >= maxInstances;
  }

  renderInstantiateButton() {
    const { instances } = this.props;
    const { connector, isInstantiating, hasSubscription } = this.props;
    const instance = this.getInstance();
    const instanceStatus = instance && instance.status ? instance.status : '';
    const hasMaxInstances = this.hasMaxInstances();
    switch(true) {
      case hasSubscription && !hasMaxInstances: return (
        <ButtonWaiting type="button" className="btn btn-default" isWaiting={isInstantiating} onClick={this.handleClickInstantiate}>
          <i className="icon icon-left mdi mdi-flash"></i>{' '}
          Activate #{instances.length+1}
        </ButtonWaiting>
      );
      case instanceStatus === 'setup': return (
        <Link className="btn btn-warning" to={`/instance/${connector.id}/${instance.id}/setup`} title="Go to dashboard">
          <i className="icon icon-left mdi mdi-alert-circle-o"></i>{' '}
          Not yet setup
        </Link>
      );
      case instanceStatus === 'paused': return (
        <Link className="btn btn-warning" to={`/instance/${connector.id}/${instance.id}`} title="Go to dashboard">
          <i className="icon icon-left mdi mdi-pause-circle-outline"></i>{' '}
          Paused
        </Link>
      );
      case instanceStatus === 'active': return (
        <Link className="btn btn-success" to={`/instance/${connector.id}/${instance.id}`} title="Go to dashboard">
          <i className="icon icon-left mdi mdi-check"></i>{' '}
          Activated
        </Link>
      );
      case instanceStatus === 'inactive': return (
        <Link className="btn btn-danger" to={`/instance/${connector.id}/${instance.id}`} title="Go to dashboard">
          <i className="icon icon-left mdi mdi-alert-octagon"></i>{' '}
          Expired
        </Link>
      );
      case !hasSubscription: return (
        <ButtonWaiting type="button" className="btn btn-default" isWaiting={isInstantiating} onClick={this.handleClickInstantiate}>
          <i className="icon icon-left mdi mdi-flash"></i>{' '}
          Start new trial
        </ButtonWaiting>
      );
      case hasSubscription:
      default: return (
        <ButtonWaiting type="button" className="btn btn-default" isWaiting={isInstantiating} onClick={this.handleClickInstantiate}>
          <i className="icon icon-left mdi mdi-flash"></i>{' '}
          Activate
        </ButtonWaiting>
      );
    }
  }

  render() {
    const { connector, html, className } = this.props;
    const { title } = connector;
    return (
      <div className={classNames(className, 'panel panel-border')}>
        <div>
          <div className="panel-heading panel-heading-divider">
            {title}
          </div>
          <div className="panel-body" dangerouslySetInnerHTML={{__html: html}}></div>
        </div>
        <div className="panel-footer xs-p-10 text-right">
          {this.renderInstantiateButton()}
        </div>
      </div>
    );
  }

}
