import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

class BeFormFieldErrors extends Component {

  static propTypes = {
    errors: propTypes.oneOfType([propTypes.array, propTypes.string])
  }

  renderErrors(errors) {
    if(!Array.isArray(errors)) errors = [errors];
    return errors.map((error, index) => {
      return <li key={index}>{error}</li>;
    });
  }

  render() {
    if(!this.props.errors || !this.props.errors.length) return null;
    const classes = classNames('parsley-errors-list', 'filled', this.props.className || '');
    return (
      <ul className={classes}>
        {this.renderErrors(this.props.errors)}
      </ul>
    )
  }

};

export default BeFormFieldErrors;
