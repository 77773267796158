import { combineReducers } from 'redux';

import statistics from 'client/redux/fstd/statisticsReducer';
import test from 'client/redux/fstd/testReducer';
import testFile from 'client/redux/fstd/testFileReducer';
import fieldMapper from 'client/redux/fstd/fieldMapperReducer';

const fstdReducer = combineReducers({
  statistics,
  test,
  testFile,
  fieldMapper,
});

export default fstdReducer;
