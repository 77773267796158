import React, { Component } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

export default class SwitchButton extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.bool]),
    size: propTypes.string,
  }

  static defaultProps = {
    className: '',
    id: '',
  }

  render() {
    const { className, id, value, size, ...props } = this.props;
    const classes = classNames(className, 'switch-button', size ? 'switch-button-' + size : '');
    return (
      <div className={classes}>
        <input type="checkbox" id={id} checked={Boolean(value)} {...props} />
        <span>
          <label htmlFor={id}></label>
        </span>
      </div>
    );
  }

}
