import { ENDPOINT_PANEL_READ, ENDPOINT_PANEL_UPDATE } from 'client/redux/endpoint/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isUpdating: false,
  error: null,
  endpoint: null,
};

export default function editPanelReducer(state = defaultState, action) {
  state = reduceByTypes(ENDPOINT_PANEL_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(ENDPOINT_PANEL_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true});
  return state;
};
