import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';
import ObjectRow from 'client/components/Helpers/ObjectRow';
import _ from 'lodash';

const ObjectGraph = ({topdeskIncident}) => {
  return (
    <>
      <ObjectRow subject="TOPdesk incident and related objects" object={topdeskIncident} />
    </>
  );
};

export default class AITDTesterIncident extends React.Component {

  static propTypes = {
    incident: propTypes.object,
    onRemove: propTypes.func,
    onSync: propTypes.func
  }

  handleClickSync = ev => {
    if (!this.props.incident?.topdeskIncident?.id) return;
    this.props.onSync(this.props.incident.topdeskIncident.id);
  }

  handleClickRemove = ev => {
    if (!this.props.incident?.topdeskIncident?.id) return;
    this.props.onRemove(this.props.incident.topdeskIncident.id);
  }

  renderNoticeTableRows(notices) {
    if(!notices || !notices.length) {
      return (
        <tr>
          <td colSpan="3">
            <em>No action taken with this incident</em>
          </td>
        </tr>
      );
    }

    return notices.map((notice, index) => {
      return (
        <NoticeRow
          key={index}
          notice={notice}
        />
      );
    });
  }

  renderNoticeTable() {
    const { incident } = this.props;
    const { notices, topdeskIncident } = incident;
    if(!notices && !topdeskIncident) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {topdeskIncident && <ObjectGraph topdeskIncident={topdeskIncident} />}
              {this.renderNoticeTableRows(notices)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { incident } = this.props;
    if(!incident) return null;
    const { error } = incident;
    const topdeskIncidentNumber = _.get(incident, 'topdeskIncident.number');
    const topdeskIncidentStatus = _.get(incident, 'topdeskIncident.status');
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-assignment xs-mr-5"></i>
            {topdeskIncidentNumber} (status: {topdeskIncidentStatus})
          </div>
          <span className="btn-group pull-right">
            <ButtonWaiting type="button" onClick={this.handleClickSync} isWaiting={incident.isSyncing} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>
              Sync
            </ButtonWaiting>
            <button onClick={this.handleClickRemove} type="button" className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-delete"></i>
              Remove from test
            </button>
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} />
        {this.renderNoticeTable()}
      </div>
    );
  }

};
