import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';
import ConnectorPanel from 'client/components/ConnectorPanel/ConnectorPanel';

export default class ConnectorPanelModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    connector: propTypes.object,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired
  }

  state = {
    wasUpdated: false
  }

  handleClose = () => {
    const { wasUpdated } = this.state;
    return this.props.onClose({wasUpdated});
  }

  handleUpdated = () => {
    this.setState({wasUpdated: true});
  }

  render() {
    const { open, connector } = this.props;
    const { title } = connector;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={this.handleClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={this.handleClose} />
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConnectorPanel id={connector.id} onUpdated={this.handleUpdated} />
        </Modal.Body>
      </Modal>
    );
  }

}
