import { combineReducers } from 'redux';

import billing from 'client/redux/account/billingReducer';
import cardAdd from 'client/redux/account/cardAddReducer';
import card from 'client/redux/account/cardReducer';
import settings from  'client/redux/account/settingsReducer';
import subscription from  'client/redux/account/subscriptionReducer';
import invoiceIndex from 'client/redux/account/invoiceIndexReducer';
import invoiceRead from 'client/redux/account/invoiceReadReducer';
import children from 'client/redux/account/childrenReducer';

const accountReducer = combineReducers({
  billing,
  card,
  cardAdd,
  children,
  invoiceIndex,
  invoiceRead,
  settings,
  subscription,
});

export default accountReducer;
