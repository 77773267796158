import { USER_SUBSCRIPTION_INDEX, USER_SUBSCRIPTION_INDEX_ORDERBY, USER_SUBSCRIPTION_INDEX_SET_PAGE, USER_SUBSCRIPTION_INDEX_FILTER, USER_SUBSCRIPTION_DELETE } from 'client/redux/user/subscription/constants';
import * as actions from 'client/redux/user/subscription/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const userSubscriptionIndex = query => dispatch => {
  return dispatch(actions.userSubscriptionIndex(USER_SUBSCRIPTION_INDEX, query));
};

export const userSubscriptionDelete = (id, query) => dispatch => {
  return dispatch(actions.userSubscriptionDelete(USER_SUBSCRIPTION_DELETE, id, query));
};

export const userSubscriptionIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(USER_SUBSCRIPTION_INDEX_ORDERBY, orderBy, orderDirection));
};

export const userSubscriptionIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(USER_SUBSCRIPTION_INDEX_SET_PAGE, page));
};

export const userSubscriptionIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(USER_SUBSCRIPTION_INDEX_FILTER, filter));
};
