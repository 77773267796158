import React from 'react';
import propTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import _ from 'lodash';

export default class FSTDFieldMapperItemAssignmentsManager extends React.Component {

  static propTypes = {
    idSuffix: propTypes.string.isRequired,
    disabled: propTypes.bool,
    columns: propTypes.array,
  }

  static defaultProps = {
    disabled: false,
    columns: [],
  }

  state = {
    addFormOpen: false,
  }

  setAddFormClosed = () => {
    this.setState({addOpenForm: false});
  }

  setAddFormOpen = () => {
    this.setState({addOpenForm: true});
  }

  addAssignment = (columnName, obj) => {
    const { input: { onChange } } = this.props;
    onChange({...this.getValue(), [columnName]: obj});
  }

  removeAssignment = columnName => {
    const { input: { onChange } } = this.props;
    onChange(_.omit(this.getValue(), columnName));
  }

  renderAssignments() {
    const { disabled } = this.props;
    const value = this.getValue();
    if(!_.keys(value).length) return (
      <tr className="empty">
        <td colSpan={5}>No columns assigned yet</td>
      </tr>
    );
    return _.map(value, (obj, columnName) => (
      <tr key={columnName}>
        <td>{columnName}</td>
        <td>{obj.branchField}</td>
        <td>{obj.type}</td>
        <td>{obj.field}</td>
        <td>
          <button disabled={disabled} type="button" onClick={() => this.removeAssignment(columnName)} className="btn btn-default btn-sm center-block">
            <i className="btn-icon icon mdi mdi-close"></i>
          </button>
        </td>
      </tr>
    ));
  }

  getValue() {
    const { input: { value } } = this.props;
    return _.isPlainObject(value) ? value : {};
  }

  render() {
    const { addOpenForm } = this.state;
    const { columns, disabled, idSuffix } = this.props;
    const value = this.getValue();
    return (
      <div>
        <table className="table table-condensed table-bordered">
          <tbody>
            <tr>
              <th className="xs-pl-10">Column</th>
              <th>Branch Column</th>
              <th>Assigned Object</th>
              <th>Search Field</th>
              <th></th>
            </tr>
            {this.renderAssignments()}
          </tbody>
        </table>
        <div className="text-center xs-mt-15">
          <button type="button" disabled={disabled} onClick={this.setAddFormOpen} className="btn btn-default btn-rounded">New column assignment</button>
          <AddAssignmentModal
            open={addOpenForm || false}
            idSuffix={idSuffix}
            columns={columns}
            assignments={value}
            onClose={this.setAddFormClosed}
            onAdd={this.addAssignment}
          />
        </div>
      </div>
    );
  }

}

class AddAssignmentModal extends React.Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    assignments: propTypes.object.isRequired,
    columns: propTypes.array,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    onAdd: propTypes.func.isRequired,
    idSuffix: propTypes.string.isRequired,
  }

  handleSubmit = fields => {
    const { columnName, type, field, branchField } = fields;
    const obj = {type, field};
    if(type === 'location') obj.branchField = branchField;
    this.props.onAdd(columnName, obj);
    this.props.onClose();
  }

  renderBody() {
    const { open, columns, assignments, idSuffix } = this.props;
    if(!open) return null;
    return (
      <AddAssignmentFormConnected
        form="fstdFieldMapperAddAssignmentForm"
        idSuffix={idSuffix}
        onSubmit={this.handleSubmit}
        assignments={assignments}
        columns={columns}
      />
    );
  }

  render() {
    const { onClose, open } = this.props;
    return (
      <Modal id="fstdFieldMapperAddAssignmentModal" dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>Add Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
      </Modal>
    );
  }

}

class AddAssignmentForm extends React.Component {

  static propTypes = {
    assignments: propTypes.object.isRequired,
    columns: propTypes.array,
    onSubmit: propTypes.func.isRequired,
    idSuffix: propTypes.string.isRequired,
    currentType: propTypes.string,
  }

  constructor(props) {
    super(props);
    this.fieldProps = {
      base: 'fstdFieldMapperAddAssignmentForm' + (props.idSuffix || ''),
      metaOptions: {defaultState: null, ignorePristine: false},
    };
  }

  getColumnData(getEverything = false) {
    const mappedColumns = _.keys(this.props.assignments) || [];
    const columns = this.props.columns || [];
    return columns.filter(name => getEverything || !mappedColumns.includes(name)).map(text => ({id: text, text}));
  }

  getTypeData() {
    return [
      {id: 'person', text: 'Person'},
      {id: 'personGroup', text: 'Person Group'},
      {id: 'branch', text: 'Branch/Site'},
      {id: 'location', text: 'Location/Room'},
    ];
  }

  getFieldData() {
    const { currentType } = this.props;
    switch(currentType) {
      default: return [
        {id: 'name', text: 'Name'},
      ];
      case 'person': return [
        {id: 'email', text: 'Email'},
        {id: 'network_login_name', text: 'Network Login Name'},
        {id: 'ssp_login_name', text: 'Tas Login Name'},
      ];
    }
  }

  maybeRenderBranchField() {
    const { currentType } = this.props;
    if(currentType !== 'location') return null;
    return <Field
      {...this.fieldProps}
      component={FormReduxSelect2}
      select2Options={{tags: true, minimumInputLength: 0, minimumResultsForSearch: 0, dropdownParent: '#fstdFieldMapperAddAssignmentModal'}}
      name="branchField"
      label="Branch Column"
      helpText="Column holding the name of the branch the location belongs to"
      required={true}
      data={this.getColumnData(true)}
    />
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      error,
      invalid,
    } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <div className="row">
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              component={FormReduxSelect2}
              select2Options={{tags: true, minimumInputLength: 0, minimumResultsForSearch: 0, dropdownParent: '#fstdFieldMapperAddAssignmentModal'}}
              name="columnName"
              label="Column Name"
              required={true}
              data={this.getColumnData()}
            />
          </div>
          <div className="col-md-6">
            <Field
              {...this.fieldProps}
              component={FormReduxSelect2}
              select2Options={{minimumInputLength: 0, minimumResultsForSearch: 5, dropdownParent: '#fstdFieldMapperAddAssignmentModal'}}
              name="type"
              label="Object Type"
              required={true}
              data={this.getTypeData()}
            />
          </div>
        </div>
        {this.maybeRenderBranchField()}
        <div>
          <Field
            {...this.fieldProps}
            component={FormReduxSelect2}
            select2Options={{minimumInputLength: 0, minimumResultsForSearch: 5, dropdownParent: '#fstdFieldMapperAddAssignmentModal'}}
            name="field"
            label="Search Field"
            helpText="TOPdesk will be queried for this field when finding a matching object"
            required={true}
            data={this.getFieldData()}
          />
        </div>
        <div className="text-right">
          <button type="button" onClick={handleSubmit(onSubmit)} disabled={invalid} className="btn btn-primary btn-lg">
            <i className="icon icon-left mdi mdi-icon mdi-check"></i>{' '}
            Create
          </button>
        </div>
      </Form>
    );
  }

}

// validate so that we don't create a assignments with the same key/column name
const validateAddForm = (values, props) => {
  const errors = {};
  const { columnName, type, field, branchField } = values;
  const { assignments } = props;
  if(assignments[columnName]) errors.columnName = 'This column has already been mapped';
  if(type === 'location' && !branchField) errors.branchField = 'This field is required';
  if(!columnName) errors.columnName = 'This field is required';
  if(!field) errors.field = 'This field is required';
  if(!type) errors.type = 'This field is required';
  return errors;
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);
  // the value of these fields need to be available when rendering
  return {
    currentType: selector(state, 'type'),
  };
};

const AddAssignmentFormConnected = connect(mapStateToProps)(reduxForm({
  enableReinitialize: false,
  validate: validateAddForm,
})(AddAssignmentForm));
