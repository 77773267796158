import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { INSTANCE_OPTIONAL_ENDPOINT_CREATE_SUCCESS } from 'client/redux/instance/constants';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import * as optionalEndpointActions from 'client/redux/instance/optionalEndpointActions';

class InstanceOptionalEndpointList extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    endpointsByType: propTypes.object.isRequired,
    onEndpointCreated: propTypes.func.isRequired,
  }

  handleChange = value => {
    this.props.instanceOptionalEndpointSetEndpointType(value);
  }

  handleClickAdd = ev => {
    const { instanceId, endpointType, onEndpointCreated } = this.props;
    this.props.instanceOptionalEndpointCreate(instanceId, endpointType).then(result => {
      if (result.type === INSTANCE_OPTIONAL_ENDPOINT_CREATE_SUCCESS) {
        onEndpointCreated(instanceId);
        this.props.instanceOptionalEndpointSetEndpointType('');
      }
      return result;
    });
  }

  render () {
    const {
      error,
      endpointType,
      isAdding,
      optionalEndpoints,
      endpointsByType,
    } = this.props;

    const data = optionalEndpoints.filter(o => !Boolean(endpointsByType[o.type])).map(o => ({id: o.type, text: o.description || o.type}));

    return (
      <div className="xs-mb-20">
        <h3 className="wizard-title">
          <div className="clearfix">
            Optional Endpoints
          </div>
          <span className="panel-subtitle">
            This connector has optional endpoints that you can add to unlock extra functionality
          </span>
        </h3>
        <div className="input-group">
          <Select2Wrap
            id="instanceEndpointsSelectOptionalEndpoint"
            name="optionalEndpointType"
            className="form-control"
            data={data}
            disabled={!data.length}
            style={{width: '100%'}}
            options={{placeholder: 'Choose an optional endpoint', minimumResultsForSearch: Infinity}}
            defaultValue={endpointType}
            unwrapValue
            onChange={this.handleChange}
          />

          <span className="input-group-btn">
            <ButtonWaiting
              onClick={this.handleClickAdd}
              type="button"
              className="btn btn-primary"
              disabled={!data.length || isAdding}
              isWaiting={isAdding}
            >
              Add Endpoint
            </ButtonWaiting>
          </span>
        </div>
        <BeRemoteErrorAlert className="xs-mt-20" error={error} />
        <hr />
      </div>
    );
  }
};

const actions = {
  ...optionalEndpointActions,
};

const mapStateToProps = state => ({
  ...state.instance.optionalEndpoint,
});

export default connect(mapStateToProps, actions)(InstanceOptionalEndpointList);
