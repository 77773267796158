import React from 'react';
import AccountAddressFormSection from 'client/components/Account/components/AccountAddressFormSection';
import { Form, FormSection, reduxForm } from 'redux-form';
import { ButtonWaiting }  from 'client/components/Button/ButtonWaiting';
import propTypes from 'prop-types';

class AccountBillingAddressForm extends React.Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired
  }

  render() {
    const { onSubmit, submitting, invalid } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={this.props.handleSubmit(onSubmit)}>
        <FormSection name="address">
          <div id="accountAddCardFormCountryDropdownParent"></div>
          <AccountAddressFormSection />
        </FormSection>
        <div className="clearfix">
          <ButtonWaiting type="submit" className="btn btn-primary btn-lg pull-right" isWaiting={submitting} disabled={invalid}>
            Save address
          </ButtonWaiting>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'accountBillingAddress',
  enableReinitialize: true
})(AccountBillingAddressForm);
