import React  from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { normalizeBoolean } from 'client/redux/formHelpers';
import { getNumberOfRows } from 'client/helpers/misc';
import { AlertWithMessage } from 'client/components/Be/Alert';
import { qrCodeFeatures, qrCodePrintSizesSelect2 } from 'client/redux/qrtd/constants';
import ConnectedQRTDOptionsFormInfoTextEditor from 'client/components/QRTD/ConnectedQRTDOptionsFormInfoTextEditor';
import { connect } from 'react-redux';
import QRTDOptionsIncidentFieldMaps, { getFieldMapSelect2List } from 'client/components/QRTD/QRTDOptionsIncidentFieldMaps';
import { instanceSettingsUpdateInPlace } from 'client/redux/instance/settingsActions';
import * as optionsActions from 'client/redux/qrtd/optionsActions';
import {
  INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS,
  qrPageTabs,
} from 'client/redux/qrtd/constants';
import {
  FormReduxSelect2,
  FormReduxInput,
  FormReduxTextarea,
  FormReduxSwitchButton,
  FormReduxCheckboxList,
  FormOrderTable,
} from 'client/components/Form/FormRedux';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import Sticky from 'react-stickynode';
import ConnectedQRTDImageUploadField from 'client/components/QRTD/ConnectedQRTDImageUploadField';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';

const showWipFeaturesInstanceIds = (process.env.REACT_APP_QRTD_SHOW_WIP_FEATURES_INSTANCE_IDS || '').split(',').filter(v => v);

class ConnectedQRTDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    this.props.instanceQrtdOptionsReadMeta(instanceId);
  }

  handleReset = ev => {
    this.props.reset();
  }

  updateSettingsInPlaceWhen(actionType, fieldMapResult = {}) {
    if(fieldMapResult.type !== actionType) return fieldMapResult;
    const { instanceId } = this.props;
    const newSettings = _.get(fieldMapResult, 'payload.instance.settings');
    // TODO consider to only update in place with payload.instance.settings.fieldMaps so we can
    // prevent overriding unsaved updates in other parts of the options form when this
    // call is done. fieldMaps is the only thing that could have changed anyway.
    return this.props.instanceSettingsUpdateInPlace(instanceId, newSettings);
  }

  handleCreateFieldMap = (form = {}) => {
    const { instanceId } = this.props;
    const { title } = form;
    return this.props.instanceQrtdOptionsFieldMapCreate(instanceId, title).then(result => {
      return this.updateSettingsInPlaceWhen(INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS, result);
    });
  }

  handleDeleteFieldMap = ordinal => {
    const { instanceId } = this.props;
    return this.props.instanceQrtdOptionsFieldMapDelete(instanceId, ordinal).then(result => {
      return this.updateSettingsInPlaceWhen(INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS, result);
    });
  }

  handleUpdateFieldMap = (form = {}) => {
    const { instanceId, selectedFieldMapOrdinal } = this.props;
    const { title } = form;
    return this.props.instanceQrtdOptionsFieldMapUpdate(instanceId, selectedFieldMapOrdinal, title).then(result => {
      return this.updateSettingsInPlaceWhen(INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_SUCCESS, result);
    });
  }

  handleChangeSelectedFieldMapOrdinal = ordinal => {
    return this.props.instanceQrtdOptionsSetSelectedFieldMapOrdinal(ordinal);
  }

  render() {
    const {
      dirty,
      disabled,
      endpointsByType,
      errorFieldMap,
      errorMeta,
      incidentDefaultFieldMap,
      incidentFieldMaps,
      infoTexts,
      initialValues,
      instanceId,
      invalid,
      isCreatingFieldMap,
      isDeletingFieldMap,
      isReadingMeta,
      isSetupMode,
      isUpdating,
      isUpdatingFieldMap,
      onNudgeStep,
      onSubmit,
      selectedFieldMapOrdinal,
      defaultBottomTextRows,
      defaultInfoTextRows,
    } = this.props;
		return (
      <Form id="instanceQrtdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceQrtdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20 row">
          <div className="col-md-6">
            <PrintPageDefaultOptionsSubForm
              initialValues={initialValues}
              disabled={disabled}
              incidentFieldMaps={incidentFieldMaps}
              infoTexts={infoTexts}
              defaultBottomTextRows={defaultBottomTextRows}
              instanceId={instanceId}
            />
            <UserPageCustomizationSubForm
              initialValues={initialValues}
              disabled={disabled}
              instanceId={instanceId}
            />
            <ConnectedQRTDOptionsFormInfoTextEditor
              initialValues={initialValues}
              infoTextRows={defaultInfoTextRows}
              disabled={disabled}
              infoTexts={infoTexts}
              instanceId={instanceId}
            />
          </div>
          <div className="col-md-6">
            <QRTDOptionsIncidentFieldMaps
              settingsFormIsDirty={dirty}
              initialValues={initialValues}
              disabled={disabled}
              endpointsByType={endpointsByType}
              isReadingMeta={isReadingMeta}
              isCreatingFieldMap={isCreatingFieldMap}
              isDeletingFieldMap={isDeletingFieldMap}
              isUpdatingFieldMap={isUpdatingFieldMap}
              onCreateFieldMap={this.handleCreateFieldMap}
              onDeleteFieldMap={this.handleDeleteFieldMap}
              onUpdateFieldMap={this.handleUpdateFieldMap}
              incidentDefaultFieldMap={incidentDefaultFieldMap}
              incidentFieldMaps={incidentFieldMaps}
              onChangeSelectedFieldMapOrdinal={this.handleChangeSelectedFieldMapOrdinal}
              selectedFieldMapOrdinal={selectedFieldMapOrdinal}
              errorFieldMap={errorFieldMap}
              errorMeta={errorMeta}
            />
            {/* <SigningKeyOptionsSubForm */}
            {/*   initialValues={initialValues} */}
            {/*   disabled={disabled} */}
            {/* /> */}
          </div>
        </div>
      </Form>
		);
  }
}

function FeaturesField(props) {
  const { disabled, name, label, instanceId } = props;
  return (
    <Field
      checkboxContainerClassName="be-checkbox-color col-lg-6"
      base="qrtdOptionsDefault"
      name={name}
      type="checkbox"
      component={FormReduxCheckboxList}
      checkboxes={getQrCodeFeaturesCheckboxes(instanceId)}
      disabled={disabled}
      label={label}
    />
  );
}

function ColorPickerField(props) {
  return (
    <Field
      base="qrtdOptions"
      component={FormReduxInput}
      type="color"
      {...props}
    />
  );
}

function UserPageCustomizationSubForm(props) {
  const { disabled, initialValues, instanceId } = props;
  const {
    colorBase,
    locale,
    disableQrPageWhenPaused,
    incidentUploadPhoto,
    qrPageShowAssetTemplateName,
    qrPageShowLocationBranchName,
    qrPageHideLinknow,
    qrPageUrlToAsset,
    qrPageUrlToLocation,
    tabOrder,
  } = initialValues;
  return (
    <div className="well xs-pb-10">
      <InstanceHelpHeader
        subTitle="Customization options for the user facing page"
        helpFragmentId="instance_qrtd_settings_user_page_customization_help"
      >
          User Page Customization
      </InstanceHelpHeader>
      <ColorPickerField
        name="colorBase"
        value={colorBase}
        label="Base color"
        disabled={disabled}
      />
      <ConnectedQRTDImageUploadField
        label="Header"
        imageType="header"
        instanceId={instanceId}
        helpText="Must be of type jpg, jpeg or png. Best results with a transparent background."
        formId="instanceQrtdImageUploadHeader"
        formName="imageUploadHeader"
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsLocale"
        name="locale"
        component={FormReduxSelect2}
        label="Language"
        data={[
          {id: 'en', text: 'English'},
          {id: 'dk', text: 'Danish'},
          {id: 'nl', text: 'Dutch'},
          {id: 'sv', text: 'Swedish'},
        ]}
        defaultValue={locale || ''}
        disabled={disabled}
      />
      <h4>Asset QR-pages</h4>
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Show asset template name in header"
        name="qrPageShowAssetTemplateName"
        value={qrPageShowAssetTemplateName}
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Asset name links to the asset card in TOPdesk"
        name="qrPageUrlToAsset"
        value={qrPageUrlToAsset}
        disabled={disabled}
      />
      <h4>Location QR-pages</h4>
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Show branch name next to location name"
        name="qrPageShowLocationBranchName"
        value={qrPageShowLocationBranchName}
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Location name links to the location in TOPdesk"
        name="qrPageUrlToLocation"
        value={qrPageUrlToLocation}
        disabled={disabled}
      />
      <h4>All QR-pages</h4>
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Disable user facing page when connector is paused"
        name="disableQrPageWhenPaused"
        value={disableQrPageWhenPaused}
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Allow the user to upload a photo when creating a new incident"
        name="incidentUploadPhoto"
        value={incidentUploadPhoto}
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Hides the Linknow logo in the page footer"
        name="qrPageHideLinknow"
        value={qrPageHideLinknow}
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsQrPage"
        className="xs-mb-10"
        component={FormOrderTable}
        list={getQrPageTabsList(instanceId)}
        label="Tab order"
        helpText="The topmost tab is displayed first when opening a QR-code"
        name="tabOrder"
        value={tabOrder || []}
        disabled={disabled}
      />
    </div>
  );
}

function PrintPageDefaultOptionsSubForm(props) {
  const {
    instanceId,
    disabled,
    initialValues,
    incidentFieldMaps,
    infoTexts,
    defaultBottomTextRows,
  } = props;
  const {
    defaultLinkTemplate = {},
    defaultTopText,
    defaultBottomText,
    defaultQrCodeFgColor,
    defaultQrCodeBgColor,
    defaultQrCodeBgExtend,
    defaultQrPrintPageSize,
  } = initialValues;
  const { fieldMapOrdinal, infoTextOrdinal } = defaultLinkTemplate;
  return (
    <div className="well xs-pb-10">
      <InstanceHelpHeader
        subTitle="Default settings that can be overridden on the QR print page"
        helpFragmentId="instance_qrtd_settings_default_link_template_help"
      >
          QR-Code Print Page Default Settings
      </InstanceHelpHeader>
      <FeaturesField
        instanceId={instanceId}
        name="defaultLinkTemplate.features"
        label="Enabled features"
        disabled={disabled}
      />
      <Field
        base="qrtdOptions"
        component={FormReduxInput}
        label="Text above QR-codes"
        name="defaultTopText"
        value={defaultTopText || ''}
        disabled={disabled}
        maxlen="4096"
      />
      <Field
        base="qrtdOptions"
        component={FormReduxTextarea}
        label="Text below QR-codes"
        name="defaultBottomText"
        rows={defaultBottomTextRows}
        value={defaultBottomText || ''}
        disabled={disabled}
        maxlen="4096"
      />
      <div className="row">
        <ColorPickerField
          className="col-md-6"
          name="defaultQrCodeBgColor"
          value={defaultQrCodeBgColor}
          label="QR-code background color"
          disabled={disabled}
        />
        <ColorPickerField
          className="col-md-6"
          name="defaultQrCodeFgColor"
          value={defaultQrCodeFgColor}
          label="QR-code foreground color"
          disabled={disabled}
        />
      </div>
      <Field
        base="qrtdOptions"
        component={FormReduxSwitchButton}
        switchButtonClassName="switch-button-yesno"
        label="Extend QR-code background color to cover the text"
        name="defaultQrCodeBgExtend"
        value={defaultQrCodeBgExtend || false}
        disabled={disabled}
      />
      <Field
        label="QR-code size"
        base="qrtdOptions"
        name="defaultQrPrintPageSize"
        component={FormReduxSelect2}
        data={qrCodePrintSizesSelect2}
        defaultValue={defaultQrPrintPageSize || '9'}
        disabled={disabled}
      />
      <div className="row">
        <div className="col-md-6">
          <Field
            label="QR-code report incident field map"
            base="qrtdOptions"
            name="defaultLinkTemplate.fieldMapOrdinal"
            component={FormReduxSelect2}
            data={getFieldMapSelect2List(incidentFieldMaps)}
            select2Options={{allowClear: true, placeholder: 'Default QR-code report incident field map'}}
            defaultValue={fieldMapOrdinal || ''}
            disabled={disabled}
          />
        </div>
        <div className="col-md-6">
          <Field
            label="QR-code info tab text"
            className="xs-mb-10"
            base="qrtdOptions"
            name="defaultLinkTemplate.infoTextOrdinal"
            component={FormReduxSelect2}
            data={getInfoTextSelect2List(infoTexts)}
            select2Options={{allowClear: true, placeholder: 'Default info tab text'}}
            defaultValue={infoTextOrdinal || ''}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line
function SigningKeyOptionsSubForm(props) {
  const {
    initialValues: { signedUrlsIsRequired, signingKey },
    disabled,
  } = props;
  return (
    <div className="well xs-pb-10">
      <InstanceHelpHeader
        subTitle="Links contained in QR-codes can be signed to prevent tampering by end users"
        helpFragmentId="instance_qrtd_settings_signing_key_help"
      >
        Signing Key (advanced)
      </InstanceHelpHeader>
      <Field
        base="qrtdOptionsSignedUrlsIsRequired"
        name="signedUrlsIsRequired"
        component={FormReduxSelect2}
        data={[
          {id: false, text: 'QR-code links not signed'},
          {id: true, text: 'QR-code links must be signed'},
        ]}
        normalize={normalizeBoolean}
        defaultValue={signedUrlsIsRequired || ''}
        disabled={disabled}
      />
      <Field
        base="qrtdOptionsSigningKey"
        component={FormReduxInput}
        label="Signing key"
        name="signingKey"
        value={signingKey || ''}
        disabled={disabled}
        helpText="Secret key automatically used when signing links"
      />
      <AlertWithMessage alertType="warning" icon className="alert-icon">
        Changing the signing key invalidates <strong>every</strong> previously created QR-code!
      </AlertWithMessage>
    </div>
  );
}

function getInfoTextSelect2List (infoTexts) {
  const list = [
    {id: 0, text: 'Default'},
    ..._.map(infoTexts, ({ordinal, title}) => {
      return {id: ordinal, text: title};
    }),
  ];
  return list;
}

function getQrCodeFeaturesCheckboxes (instanceId) {
  return qrCodeFeatures.filter(v => {
    if (!v.wip) return true;
    return !showWipFeaturesInstanceIds.length || showWipFeaturesInstanceIds.includes(instanceId);
  });
}

function getQrPageTabsList (instanceId) {
  return qrPageTabs.filter(v => {
    if (!v.wip) return true;
    return !showWipFeaturesInstanceIds.length || showWipFeaturesInstanceIds.includes(instanceId);
  });
}

function mapStateToProps(state) {
  const selector = formValueSelector('qrtdOptions');
  // the value of these fields need to be available when rendering
  return {
    ...state.qrtd.options,
    defaultBottomTextRows: getNumberOfRows(selector(state, 'defaultBottomText')),
    defaultInfoTextRows: Math.max(4, getNumberOfRows(selector(state, 'infoText'))),
  };
}

const actions = {
  ...optionsActions,
  instanceSettingsUpdateInPlace,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'qrtdOptions',
  enableReinitialize: true,
})(ConnectedQRTDOptionsForm));
