import * as actionCreators from 'client/redux/fatd/actionCreators';
import * as constants from 'client/redux/fatd/constants';

export const instanceFatdTestObjectAdd = (id, objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdTestObjectAdd(constants.INSTANCE_FATD_TEST_OBJECT_ADD, id, objectType, objectId));
};

export const instanceFatdTestObjectRemove = (objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdTestObjectRemove(constants.INSTANCE_FATD_TEST_OBJECT_REMOVE, objectType, objectId));
};

export const instanceFatdTestObjectGraph = (id, objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdTestObjectGraph(constants.INSTANCE_FATD_TEST_OBJECT_FASTOBJECTGRAPH, id, objectType, objectId));
};

export const instanceFatdSyncObject = (id, objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdSyncObject(constants.INSTANCE_FATD_SYNC_OBJECT, id, objectType, objectId));
};

export const instanceFatdObjectTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceFatdObjectTestClear(constants.INSTANCE_FATD_TEST_CLEAR));
};

export const instanceFatdObjectSearchFieldChange = (objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdObjectSearchFieldChange(constants.INSTANCE_FATD_TEST_OBJECT_SEARCH_FIELD_CHANGE, objectType, objectId));
};
