import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import BeLoading from 'client/components/Be/BeLoading';
import SettingsPanelModal from 'client/components/Settings/components/SettingsPanelModal';

class SettingsTable extends Component {

  static defaultProps = {
    settings: []
  }

  static propTypes = {
    settings: propTypes.array,
    onRefresh: propTypes.func,
    loading: propTypes.bool,
  }

  renderSettingsRows() {
    const { settings, onRefresh } = this.props;
    if(!settings || !settings.length) return (
      <tr>
        <td className="empty" colSpan={4}>No settings to show</td>
      </tr>
    );
    return settings.map(setting => {
      return (
        <tr key={setting.id}>
          <td>{setting.id}</td>
          <td>{setting.description}</td>
          <td>{setting.validator}</td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: setting, title: setting.id}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>{' '}
              <ModalButton className="btn btn-default" onClose={onRefresh} ModalComponent={SettingsPanelModal} modalProps={{setting}}>
                <i className="icon mdi mdi-settings"></i>
              </ModalButton>
            </div>
          </td>
        </tr>
      );
    })
  }

  renderTable() {
    const { loading } = this.props;
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Validator</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderSettingsRows()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  render() {
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of Settings</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }

};

export default SettingsTable;
