import { MARKETPLACE_FILTER, MARKETPLACE_INDEX } from 'client/redux/marketplace/constants';
import { CONNECTOR_INSTANTIATE_REQUEST, CONNECTOR_INSTANTIATE_SUCCESS, CONNECTOR_INSTANTIATE_FAILURE } from 'client/redux/connector/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  categories: [],
  connectors: [],
  instancesByConnector: {},
  instantiatingConnectorsById: {},
  isWaiting: false,
  error: null,
  filter: {category: null, search: ''}
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(MARKETPLACE_INDEX, state, action, {requestProp: 'isWaiting', successSpread: true});
  switch(action.type) {
    case CONNECTOR_INSTANTIATE_REQUEST: return {
      ...state,
      instantiatingConnectorsById: {
        ...state.instantiatingConnectorsById,
        [action.meta.connectorId]: true
      }
    };
    case CONNECTOR_INSTANTIATE_SUCCESS: return {
      ...state,
      error: null,
      instantiatingConnectorsById: {
        ...state.instantiatingConnectorsById,
        [action.meta.connectorId]: false
      }
    };
    case CONNECTOR_INSTANTIATE_FAILURE: return {
      ...state,
      error: action.payload,
      instantiatingConnectorsById: {
        ...state.instantiatingConnectorsById,
        [action.meta.connectorId]: false
      }
    };
    case MARKETPLACE_FILTER: return {
      ...state,
      filter: {...state.filter, ...action.filter}
    };
    default:
      return state
  }
};
