import React, { Component } from 'react';
import propTypes from 'prop-types';
import Logo from 'client/components/Branding/Logo';
import { StripeAmount, StripePercent } from 'client/components/Helpers/Strings';
import CountryList from 'country-list';
import Time from 'client/components/Helpers/Time';
import { UserInvoiceStatusLabel } from 'client/components/Helpers/StatusLabel';
import { instanceTitle } from 'client/redux/instance/instanceHelpers';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { round } from 'client/helpers/numbers';
import 'client/components/UserInvoice/components/UserInvoice.css';
import _ from 'lodash';

export const LineValue = ({currency, value, name, summaryClassName, amountClassName}) => {
  return (
    <tr>
      <td></td>
      <td className={classNames('summary', summaryClassName)}>{name}</td>
      <td className={classNames('amount', amountClassName)}>
        <StripeAmount currency={currency} amount={value} />
      </td>
    </tr>
  );
};

export const VatConversion = ({vatConversion, tax, taxPercent}) => {
  const sekRate = _.get(vatConversion, 'rates.SEK');
  if(!vatConversion || tax === null || taxPercent === null || !sekRate) return null;
  const sekAmount = sekRate * tax;
  return (
    <li className="right">
      <span><strong>VAT</strong>: <StripeAmount currency="sek" amount={sekAmount} roundTo="2" /></span>
      <span><strong>Rate</strong>: {round(sekRate, 2)}</span>
    </li>
  );
};

const BankDetail = ({bankDetail}) => {
  const [bold, normal] = bankDetail.split(':');
  return (
    <span>
      <strong>{bold}</strong>
      {normal && (<span>: {normal}</span>)}
    </span>
  );
};

export default class UserInvoice extends Component {

  constructor(props) {
    super(props);
    this.countries = CountryList();
  }

  static propTypes = {
    showPaymentStatus: propTypes.bool,
    reverseCharge: propTypes.bool,
    invoice: propTypes.object.isRequired,
    vatConversion: propTypes.object,
    sender: propTypes.object.isRequired,
    recipient: propTypes.object.isRequired,
    instances: propTypes.array,
    instancesByStripePlanId: propTypes.object,
  }

  static defaultProps = {
    showPaymentStatus: true,
  }

  getInvoiceData(invoice) {
    const {
      created,
      due_date: dueDate,
      period_start: periodStart,
      period_end: periodEnd,
      customer,
      charge,
      number: invoiceNumber,
    } = invoice;
    return {
      created: new Date(created * 1000),
      dueDate: dueDate ? new Date(dueDate * 1000) : null,
      periodStart: new Date(periodStart * 1000),
      periodEnd: new Date(periodEnd * 1000),
      lines: _.get(invoice, 'lines.data', []),
      customer: customer || {},
      charge: charge || {},
      invoiceNumber,
    };
  }

  getInvoiceLineData(line) {
    const planId = _.get(line, 'plan.id', '');

    // if any instances belong to this line, try to find them
    let lineInstances;
    if(planId) {
      const { instances = [], instancesByStripePlanId = {} } = this.props;
      const instanceIds = _.get(instancesByStripePlanId, planId, []);
      lineInstances = _.chain(instanceIds)
        .map(theId => instances.find(({id}) => id === theId))
        .compact()
        .map(instance => ({
          id: instance.id,
          type: instance.type,
          title: instanceTitle(instance, instance.Connector)
        }))
        .value();
    }

    return {
      id: _.get(line, 'id'),
      planId,
      description: _.get(line, 'description', ''),
      periodStart: new Date(_.get(line, 'period.start', 0) * 1000),
      periodEnd: new Date(_.get(line, 'period.end', 0) * 1000),
      quantity: _.get(line, 'quantity', 1) || 1,
      unitAmount: _.get(line, 'unit_amount'),
      amount: _.get(line, 'amount'),
      currency: _.get(line, 'currency'),
      lineInstances,
    };
  }

  renderPaymentStatus(invoice) {
    const { showPaymentStatus } = this.props;
    if(!showPaymentStatus) return null;
    return (
      <div className="col-md-12 invoice-payment-method">
        <span className="title">Payment Status: <UserInvoiceStatusLabel invoice={{stripeObject: invoice}} /></span>
      </div>
    );
  }

  renderCompanyInfo(sender) {
    const {
      slogan,
      supportEmail,
      website,
      websiteShort,
      name,
      bankDetails,
      registeredOffice,
      companyIdNumber,
    } = sender;
    return (
      <div className="row invoice-company-info">
        <div className="col-sm-6 col-md-2 logo"><Logo /></div>
        <div className="col-sm-6 col-md-3 summary"><span className="title">{name}</span>
          <p>{slogan}<br />Registered office: {registeredOffice}</p>
        </div>
        <div className="col-sm-6 col-md-3 email">
          <ul className="list-unstyled">
            <li><a href={website}>{websiteShort}</a></li>
            <li><a href={`mailto:${supportEmail}`}>{supportEmail}</a></li>
            <li>Company ID no: {companyIdNumber}</li>
          </ul>
        </div>
        {Array.isArray(bankDetails) && bankDetails.length > 0 && (
          <div className="col-sm-6 col-md-4 bank-details">
            <ul className="list-unstyled">
              {bankDetails.map((bankDetail, index) => (
                <li key={index}><BankDetail bankDetail={bankDetail} /></li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  renderInvoiceLineDescription(description, lineInstances) {
    if(!lineInstances || !lineInstances.length) return description;
    const lis = lineInstances.map(({id, type, title}) => (
      <li key={id}>
        <Link title="Go to connector instance" to={`/instance/${type}/${id}`}>
          {title}
        </Link>
      </li>
    ));
    return (
      <div>
        <span className="description">{description}</span>
        <ul className="instances description-tiny">{lis}</ul>
      </div>
    )
  }

  renderInvoiceLines(lines = []) {
    if(!lines.length) {
      return (
        <tr>
          <td className="description" colSpan="3">There is nothing here</td>
        </tr>
      );
    }
    return lines.map(line => {
      const {
        id,
        planId,
        description,
        periodStart,
        periodEnd,
        amount,
        currency,
        lineInstances
      } = this.getInvoiceLineData(line);
      return (
        <tr key={id + planId}>
          <td className="description">
            {this.renderInvoiceLineDescription(description, lineInstances)}
          </td>
          <td className="date">
            <Time time={periodStart} format="YYYY-MM-DD" />{' – '}
            <Time time={periodEnd} format="YYYY-MM-DD" />
          </td>
          <td className="amount">
            <StripeAmount currency={currency} amount={amount} />
          </td>
        </tr>
      );
    });
  }

  renderInvoiceSum(invoice) {
    const {
      subtotal:subTotal = 0,
      tax = 0,
      tax_percent:taxPercent = 0,
      total = 0,
      currency,
      amount_due: amountDue = 0,
      starting_balance:startingBalance = 0,
      discount,
    } = invoice;
    const hasDiscount = Boolean(discount);

    const props = {currency};

    const lines = [];
    lines.push(<LineValue key="subTotal" {...props} value={subTotal} name="Subtotal" />);

    if(hasDiscount) {
      const discount = total - tax - subTotal;
      lines.push(<LineValue key="discount" {...props} value={discount} name="Discount" />);
    }

    if(tax !== 0) {
      const percent = (<span>VAT <StripePercent percent={taxPercent} /></span>);
      lines.push(<LineValue key="tax" {...props} value={tax} name={percent} />);
    }

    lines.push(<LineValue key="total" summaryClassName="total" amountClassName="total" {...props} value={total} name="Total" />);

    if(startingBalance !== 0) {
      lines.push(<LineValue key="startingBalance" {...props} value={startingBalance} name="Customer balance" />);
    }

    const due = amountDue < 0 ? 'Paid back' : 'Amount due';
    lines.push(<LineValue key="amountDue" amountClassName="total-value" summaryClassName="total" {...props} value={amountDue} name={due} />);

    return lines;
  }

  renderInvoiceRecipient(invoice, recipient) {
    const { name, vatNumber, email, address1, address2, zipCode, city, country } = recipient;
    return (
      <div className="col-xs-5 invoice-person">
        <span><strong>Recipient</strong></span>
        <span className="name">{name}</span>
        {vatNumber && <span>VAT: {vatNumber}</span>}
        <span>{email}</span>
        {address1 && <span>{address1}</span>}
        {address2 && <span>{address2}</span>}
        <span>{zipCode} {city}</span>
        <span>{this.countries.getName(country)}</span>
      </div>
    );
  }

  renderInvoiceSender(sender) {
    const { name, vatNumber, address1, address2, zipCode, city, country, supportEmail } = sender;
    return (
      <div className="col-xs-5 invoice-person">
        <span><strong>Sender</strong></span>
        <span className="name">{name}</span>
        <span>VAT: {vatNumber}</span>
        <span>{supportEmail}</span>
        {address1 && <span>{address1}</span>}
        {address2 && <span>{address1}</span>}
        <span>{zipCode} {city}</span>
        <span>{country}</span>
      </div>
    );
  }

  renderInstances() {
    const { instances } = this.props;
    if(!instances || !instances.length) return null;
    const list = instances.map(instance => (
      <li key={instance.id}>{instanceTitle(instance, instance.Connector)}</li>
    ));
    return (
      <div>
        <hr />
        <p className="title">Connector instances</p>
        <p>These are all the instances (enabled connectors) that the subscription has:</p>
        <ul>{list}</ul>
      </div>
    );
  }

  render() {
    const { invoice, sender, recipient, reverseCharge, vatConversion } = this.props;
    const { tax = 0, tax_percent:taxPercent } = invoice;
    const { invoiceNumber, created, periodStart, periodEnd, lines, dueDate } = this.getInvoiceData(invoice);
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="invoice">
            <div className="row invoice-header">
              <div className="col-xs-7">
                <div className="invoice-logo"><Logo /></div>
              </div>
              <div className="col-xs-5 invoice-order">
                <span className="invoice-id">Invoice #{invoiceNumber}</span>
                <span className="incoice-date">
                  Period:{' '}
                  <Time time={periodStart} format="YYYY-MM-DD" />{' to '}
                  <Time time={periodEnd} format="YYYY-MM-DD" />
                </span>
                <span className="incoice-date">
                  Invoice date:{' '}
                  <Time time={created} format="LL" />
                </span>
                {dueDate && <span className="incoice-date">
                  Date Due:{' '}
                  <Time time={dueDate} format="LL" />
                </span>}
              </div>
            </div>
            <div className="row invoice-data">
              {this.renderInvoiceSender(sender)}
              <div className="col-xs-2 invoice-payment-direction">
                <i className="icon mdi mdi-chevron-right"></i>
              </div>
              {this.renderInvoiceRecipient(invoice, recipient)}
            </div>
            <div className="row">
              <div className="col-md-12">
                <table className="invoice-details">
                  <tbody>
                    <tr>
                      <th>Description</th>
                      <th>Period</th>
                      <th className="amount">Price</th>
                    </tr>
                    {this.renderInvoiceLines(lines)}
                    {this.renderInvoiceSum(invoice)}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              {this.renderPaymentStatus(invoice)}
            </div>
            <hr />
            <ol className="row vat-rates">
              <li className="left">
                <span><strong>VAT 25%</strong> <StripeAmount currency="eur" amount={tax} roundTo="2" /></span>
                <span><strong>VAT 12%</strong> 0.0</span>
                <span><strong>VAT 6%</strong> 0.0</span>
              </li>
              <VatConversion
                vatConversion={vatConversion}
                tax={tax}
                taxPercent={taxPercent}
              />
            </ol>
            <div className="row">
              <div className="col-md-12 invoice-message">
                {reverseCharge && (
                  <div>
                    <p className="xs-mt-15">The VAT for this invoice is "Reverse charge" according to Article 44 VAT directive.</p>
                  </div>
                )}
                {this.renderInstances()}
              </div>
            </div>
            {this.renderCompanyInfo(sender)}
            <div className="row invoice-footer">
              <div className="col-md-12 invoice-message">
                <span className="title">
                  Thank you for doing business with us!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
