import {
  INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ,
  INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
  INSTANCE_AITD_OPTIONS_READ_META,
} from 'client/redux/aitd/constants';
import { endpointTopdeskAssetTemplateFieldTargets } from 'client/redux/endpoint/topdesk/actionCreators';
import { instanceAitdReadMeta } from 'client/redux/aitd/actionCreators';

export const instanceAitdOptionsAssetFieldTargetsRead = (topdeskEndpointId, assetTemplateId) => dispatch => dispatch(endpointTopdeskAssetTemplateFieldTargets(INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ.map(type => ({type, meta: {assetTemplateId}})), topdeskEndpointId, assetTemplateId));

export const instanceAitdOptionsAssetFieldTargetsReset = () => dispatch => dispatch({
  type: INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
});

export const instanceAitdOptionsReadMeta = instanceId => dispatch => dispatch(instanceAitdReadMeta(INSTANCE_AITD_OPTIONS_READ_META, instanceId));
