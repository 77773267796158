import React from 'react';
import propTypes from 'prop-types';
import { AlertWithMessage } from 'client/components/Be/Alert';
import { Form, Field, reduxForm } from 'redux-form';
import { FormReduxCheckboxCustomRenderer } from 'client/components/Form/FormRedux';
import { StripeAmount } from 'client/components/Helpers/Strings';
import { getReduxFieldId } from 'client/redux/formHelpers';
import _ from 'lodash';

const isRequired = value => value ? undefined : 'Required';

class AccountSubscriptionChoosePlanForm extends React.Component {

  static propTypes = {
    availablePlans: propTypes.array.isRequired,
    onSelection: propTypes.func.isRequired,
    isFirstSelection: propTypes.bool.isRequired,
  }

  getCheckboxes() {
    const { availablePlans } = this.props;
    return availablePlans.map(({name, interval, currency, amount, stripeId}) => ({
      value: stripeId,
      name,
      interval,
      currency,
      amount,
    }));
  }

  renderFirstSelectionMessage() {
    const { isFirstSelection } = this.props
    if(!isFirstSelection) return null;
    return (
      <div>
        <AlertWithMessage icon={true} className="alert-icon" alertType="primary">
          <strong>Note:</strong> Choosing a plan paid for every year automatically gives you one extra month for free!
        </AlertWithMessage>
        <hr />
        <p>
          After adding the first plan to a subscription you can only add plans with the same billing interval and currency unless you create a new subscription!
        </p>
        <hr />
      </div>
    );
  }

  render() {
    const { handleSubmit, onSelection, invalid } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSelection)}>
        {this.renderFirstSelectionMessage()}
        <Field
          base="accountSubscriptionPlan"
          name="plan"
          component={FormReduxCheckboxCustomRenderer}
          CheckboxListComponent={CheckboxGroup}
          checkboxes={this.getCheckboxes()}
          validate={[isRequired]}
        />
        <hr />
        <div className="text-center">
          <button type="submit" disabled={invalid} className="btn btn-primary btn-lg btn">Choose</button>
        </div>
      </Form>
    );
  }

}

class CheckboxGroup extends React.Component {

  renderGroup(checkboxes = []) {
    const { input, disabled, type } = this.props;
    return checkboxes.map((checkbox, index) => {
      const { name, currency, amount } = checkbox;
      const id = getReduxFieldId(this.props, checkbox.value);
      return (
        <div key={String(index)} className={`be-${type}`}>
          <input
            {...input}
            id={id}
            disabled={disabled}
            type={type}
            value={checkbox.value}
            checked={input.value === checkbox.value}
          />
          <label htmlFor={id}>
            {name} (<StripeAmount amount={amount} currency={currency} />)
        </label>
        </div>
      );
    });
  }

  getCheckboxHeaderText(interval, currency) {
    return `Plans paid for every ${interval} in ${currency.toUpperCase()}`;
  }

  render() {
    const { checkboxes } = this.props;
    const groups = _.groupBy(checkboxes, ({interval}) => interval);

    const children = ['year', 'month'].map(interval => {
      const checkboxes = groups[interval];
      if(!checkboxes) return null;
      const { currency } = _.first(checkboxes);
      return (
        <div key={interval}>
          <h3>{this.getCheckboxHeaderText(interval, currency)}</h3>
          {this.renderGroup(checkboxes)}
        </div>
      );
    });
    return (
      <div>
        {children}
      </div>
    );
  }
}

export default reduxForm({
  form: 'accountSubscriptionChoosePlanForm',
  enableReinitialize: true,
})(AccountSubscriptionChoosePlanForm);
