import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { BeWizardTitle } from 'client/components/Be/BeWizard';

export default class InstanceHelpHeader extends React.PureComponent {

  static propTypes = {
    className: propTypes.string,
    subTitle: propTypes.node,
    helpFragmentId: propTypes.string,
  }

  render() {
    const { children, subTitle, helpFragmentId, className } = this.props;
    return (
      <BeWizardTitle className={classNames('xs-mt-5', className)} subTitle={subTitle}>
        {children}
        {helpFragmentId && <HelpButton
          className="btn btn-rounded btn-default btn-xs pull-right"
          HelpModal={UIFragmentHelpModal}
          helpModalProps={{fragmentId: helpFragmentId}}
        />}
      </BeWizardTitle>
    );
  }

}
