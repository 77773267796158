import React, { Component } from 'react';
import * as userEditPanelActions from 'client/redux/user/editPanelActions';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import { connect } from 'react-redux';
import UserPanelForm from 'client/components/User/components/UserPanelForm';
import { USER_PANEL_UPDATE_SUCCESS } from 'client/redux/user/constants';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { gritterAlertPush } from 'client/redux/gritter/actions';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';

class UserEditPanel extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    onUpdated: propTypes.func
  }

  handleUpdate = fields => {
    const { id, onUpdated } = this.props;
    return this.props.userEditPanelUpdate(id, fields).then(getResponseToFormErrorHandler()).then(result => {
      if(result.type !== USER_PANEL_UPDATE_SUCCESS) return;
      this.props.gritterAlertPush('userUpdateSuccess', {
        className: 'color success',
        title: 'Update complete',
        message: 'The user was updated',
        closeAfter: 3000
      });
      return onUpdated();
    });
  }

  handleCall = method => {
    const { id } = this.props;
    return this.props.userEditPanelCall(id, method);
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.userEditPanelRead(id);
  }

  getInitialFormValues() {
    const {
      fields: {
        fullName,
        paymentMethod,
        taxPercentage,
        hideBilling,
      },
      parentUserId,
      isConfirmed,
      role,
      email,
      stripeId,
    } = this.props.user;
    return {
      email,
      fullName,
      hideBilling,
      isConfirmed,
      parentUserId,
      paymentMethod,
      role,
      stripeId,
      taxPercentage,
    };
  }

  render() {
    const { user, isReading, error } = this.props;
    return (
      <BeLoading loading={isReading} positionStatic={true}>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        {user &&
          <UserPanelForm
            error={error}
            onSubmit={this.handleUpdate}
            onCall={this.handleCall}
            initialValues={this.getInitialFormValues()}
          />
        }
      </BeLoading>
    );
  }

}

const actions = {
  ...userEditPanelActions,
  gritterAlertPush,
};

export default connect(state => state.user.editPanel, actions)(UserEditPanel);
