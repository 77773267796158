import { USER_PANEL_READ, USER_PANEL_UPDATE, USER_PANEL_CALL } from 'client/redux/user/constants';
import { userRead, userUpdate, userCall } from 'client/redux/user/actionCreators';

export const userEditPanelRead = id => dispatch => {
  return dispatch(userRead(USER_PANEL_READ, id));
};

export const userEditPanelUpdate = (id, update) => dispatch => {
  return dispatch(userUpdate(USER_PANEL_UPDATE, id, update));
};

export const userEditPanelCall = (id, method, data = {}) => dispatch => {
  return dispatch(userCall(USER_PANEL_CALL, id, method, data));
};
