import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter, matchPath } from 'react-router';

class LeftSidebarItem extends Component {

  static propTypes = {
    activeOnExact: propTypes.bool,
    location: propTypes.object.isRequired,
    icon: propTypes.string
  }

  render() {
    const { index, activeOnExact, to, children, icon, location, ...props } = this.props;
    const isActive = Boolean(matchPath(location.pathname, {path: to, exact: activeOnExact}));
    const LinkComponent = index ? Link : NavLink;
    const iconClassNames = classNames('icon', 'mdi', icon);

    delete props.staticContext;
    delete props.activeOnExact;
    return (
      <li className={isActive ? 'active' : ''}>
        <LinkComponent to={to} {...props}>
          <i className={iconClassNames}></i>{' '}
          <i className="mdi-motorbike-off"></i>{' '}
          <span>{children}</span>
        </LinkComponent>
      </li>
    )
  }

}

export default withRouter(LeftSidebarItem);
