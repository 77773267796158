import { ENDPOINT_FILES_READ, ENDPOINT_FILES_PRELOADED, ENDPOINT_FILES_READ_DAV_INFO } from 'client/redux/endpoint/files/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isReadingDavInfo: false,
  error: null,
  endpoint: null,
  davInfo: null,
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(ENDPOINT_FILES_READ, state, action, {requestProp: 'isReading', successPickProps: ['endpoint']});
  state = reduceByTypes(ENDPOINT_FILES_READ_DAV_INFO, state, action, {requestProp: 'isReadingDavInfo', successPickProps: ['davInfo']});
  switch(action.type) {
    case ENDPOINT_FILES_PRELOADED: return {
      ...state,
      isReading: false,
      endpoint: action.endpoint,
      error: null,
    };
    default:
      return state
  }
};
