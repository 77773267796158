import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import { USER_SUBSCRIPTION_DELETE_SUCCESS } from 'client/redux/user/subscription/constants';
import _ from 'lodash';

export const onUserSubscriptionDelete = deleteActionResult => dispatch => {
  if(deleteActionResult) {
    const { type, meta } = deleteActionResult;
    if(type === USER_SUBSCRIPTION_DELETE_SUCCESS) {
      const wasForced = _.get(meta, 'query.forced') === '1';
      const message = wasForced ? 'The subscription was permanentely deleted' : 'The subscription was deleted';
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'user_subscription_delete_success', {
        className: 'color warning',
        title: 'Deletion succeded',
        message,
        closeAfter: 3000
      }));
    }
  }
};
