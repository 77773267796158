import _ from 'lodash';

export const getStepByOrd = (wizard, ord) => {
  return _.get(wizard, 'orderedSteps', []).find(step => step.ord === ord);
};

export const getNudgeStep = (wizard, direction = 1) => {
  const activeStep = _.get(wizard, ['steps', _.get(wizard, 'activeStepKey')]);
  const nextStepOrd = activeStep ? activeStep.ord + direction : 0;
  return getStepByOrd(wizard, nextStepOrd);
};
