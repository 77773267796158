import * as constants from 'client/redux/pvtd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  error: null,
  branchFieldMap: null,
  personFieldMap: null,
  incidentFieldMap: null,
};

export default function metaReducer(state = defaultState, action) {
  state = reduceByTypes(constants.INSTANCE_PVTD_READ_META, state, action, {requestProp: 'isReading', successPickProps: [
    'incidentFieldMap',
    'personFieldMap',
    'branchFieldMap',
  ]});
  return state;
};
