import { MARKETPLACE_FILTER, MARKETPLACE_INDEX } from 'client/redux/marketplace/constants';
import * as actionCreators from 'client/redux/marketplace/actionCreators';

export const marketplaceFilter = filter => dispatch => {
  return dispatch(actionCreators.marketplaceFilter(MARKETPLACE_FILTER, filter));
};

export const marketplaceIndex = () => dispatch => {
  return dispatch(actionCreators.marketplaceIndex(MARKETPLACE_INDEX));
};
