import React from 'react';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { NAVBAR_USER_SUPPORT_FORM_SUBMIT_SUCCESS } from 'client/redux/navbar/constants';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import AlertControlled from 'client/components/Be/AlertControlled';
import { Form, FormControl, Dropdown, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from 'client/redux/navbar/supportFormActions';

class NavbarSupportForm extends React.Component {

  state = {
    message: '',
    validationState: null,
    wasSubmitted: false,
  }

  handleToggle = (willBeOpen, ev, eventDetails) => { 
    if(eventDetails && eventDetails.source === 'select') {
      // ignored so that we may select and input into the form
      return;
    }
    return this.props.navbarUserSupportFormShow(willBeOpen);
  }

  handleChange = ev => {
    this.setState({[ev.target.name]: ev.target.value}, () => this.updateValid());
  }

  handleSubmit = ev => {
    ev.preventDefault();
    const props = {message: this.state.message};
    return this.props.navbarUserSupportFormSubmit(props).then(action => {
      if(action && action.type === NAVBAR_USER_SUPPORT_FORM_SUBMIT_SUCCESS) {
        this.setState({message: '', validationState: null, wasSubmitted: true});
      }
      return action;
    });
  }

  handleDismissAlert = ev => {
    this.setState({wasSubmitted: false});
  }

  updateValid = () => {
    const { message } = this.state;
    const valid = message && message.length > 0 && message.length < 4000;
    this.setState({validationState: valid ? 'success' : 'error'});
  }

  preventDefault = ev => {
    ev.preventDefault();
    ev.stopPropagation();
  }

  render() {
    const { message, validationState, wasSubmitted } = this.state;
    const { isOpen, isSubmitting, error } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <ul className="nav navbar-nav navbar-right be-icons-nav">
          <Dropdown id="nav-supportForm-dropdown" open={isOpen} componentClass="li" onToggle={this.handleToggle}>
            <Dropdown.Toggle useAnchor={true} noCaret={true}>
              <span className="icon mdi mdi-help-outline"></span>{' '}
              Support Form
            </Dropdown.Toggle>
            <Dropdown.Menu className="be-notifications linknow-notifications">
              <li>
                <div className="title">Send a quick message to support</div>
                <div className="list">
                  <div className="content xs-p-15">
                    <FormGroup controlId="navbar-supportForm-message" className="xs-mb-0" validationState={validationState}>
                      {wasSubmitted && (
                        <AlertControlled className="xs-mb-0" alertType="success" icon dismissed={!wasSubmitted} onDismiss={this.handleDismissAlert}>
                          Thank you! We will get back to you by your account email as soon as possible.
                        </AlertControlled>
                      )}
                      <BeRemoteErrorAlert error={error} />
                      {!wasSubmitted && <FormControl
                        componentClass="textarea"
                        name="message"
                        placeholder="Type in your issue, question or other feedback here and we will get back to you as soon as possible."
                        rows="7"
                        min="1"
                        onClick={this.preventDefault}
                        value={message}
                        onChange={this.handleChange}
                        />}
                    </FormGroup>
                  </div>
                </div>
                <div className="footer">
                  <div className="inner">
                    <ButtonWaiting
                      disabled={validationState !== 'success'}
                      type="submit"
                      className="btn btn-default"
                      isWaiting={isSubmitting}
                    >
                      Submit
                    </ButtonWaiting>
                  </div>
                </div>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </Form>
    );
  }

}

const stateToProps = state => ({
  ...state.navbar.supportForm,
});

export default connect(stateToProps, actions)(NavbarSupportForm);
