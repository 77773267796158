import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/user/indexActions';
import * as urlHelpers from 'client/helpers/urlHelpers';
import { switchUser } from 'client/redux/auth/actions';
import { USER_DELETE_SUCCESS, USER_UPDATE_SUCCESS } from 'client/redux/user/constants';
import { AUTH_SWITCH_USER_SUCCESS } from 'client/redux/auth/constants';
import UserTable from 'client/components/User/components/UserTable';
import { getStateSearch } from 'client/redux/apiHelpers';
import { gritterAlertPush } from 'client/redux/gritter/actions';
import _ from 'lodash';

class ConnectedUserIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const currQuery = this.getQuery(this.props);
    const prevQuery = this.getQuery(prevProps);
    if(!_.isEqual(currQuery, prevQuery)) {
      this.getData();
    }
  }

  getQuery = props => urlHelpers.parseQuerystring(_.get(props, 'location.search'));

  getData = () => {
    const query = this.getQuery(this.props);
    return this.props.userIndex(query);
  }

  handleSwitchUser = userId => {
    return this.props.switchUser(userId).then(result => {
      if(result.type !== AUTH_SWITCH_USER_SUCCESS) return;
      return this.props.push('/');
    });
  }

  handleUpdateUser = (id, update, query = {}) => {
    return this.props.userUpdate(id, update, query).then(result => {
      if(result.type !== USER_UPDATE_SUCCESS) return;
      return this.getData();
    });
  };

  handleDeleteUser = (id, force = false) => {
    const query = force ? {force: '1', paranoid: '0'} : {};
    return this.props.userDelete(id, query).then(result => {
      if(result.type !== USER_DELETE_SUCCESS) return;
      this.props.gritterAlertPush(`user_delete_${id}_success`, {
        className: `color ${force ? 'danger' : 'warning'}`,
        title: force ? 'Deletion succeded' : 'Archival succeded',
        message: force ? 'The user was deleted' : 'The user was archived',
        closeAfter: 3000
      });
      return this.getData();
    });
  }

  handleOrderBy = (orderBy, orderDirection) => {
    const url = this.getUrlWithModifiedQuery({orderBy, orderDirection});
    return this.props.push(url);
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    const url = this.getUrlWithModifiedQuery({page});
    return this.props.push(url);
  }

  handleFilter = (filter = {}) => {
    const url = this.getUrlWithModifiedQuery(filter);
    return this.props.push(url);
  }

  handleSetColumns = columns => {
    return this.props.userIndexSetColumns(columns);
  }

  getUrlWithModifiedQuery = (query = {}) => {
    const { location } = this.props;
    return urlHelpers.formatLocationWithQuery(location, {
      ...this.getQuery(this.props),
      ...query,
    });
  }

  renderTitle() {
    return (
      <Helmet>
        <title>User Overview</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const {
      isReading,
      error,
      users,
      pages,
      limit,
      offset,
      count,
      self,
      previousSelf,
      columns,
    } = this.props;
    if(error) return this.renderError(error);
    const query = this.getQuery(this.props);
    return (
      <Content>
        <MainContent>
          {this.renderTitle()}
          <UserTable
            className="panel-default panel-border"
            columns={columns}
            count={count}
            filter={query}
            hasPreviousSelf={Boolean(previousSelf)}
            limit={limit}
            loading={isReading}
            offset={offset}
            onCreateUser={this.handleCreateUser}
            onDeleteUser={this.handleDeleteUser}
            onFilter={this.handleFilter}
            onOrderBy={this.handleOrderBy}
            onRefresh={this.getData}
            onSetPage={this.handleSetPage}
            onSwitchUser={this.handleSwitchUser}
            onSetColumns={this.handleSetColumns}
            onUpdateUser={this.handleUpdateUser}
            orderBy={query.orderBy}
            orderDirection={query.orderDirection}
            page={parseInt(query.page, 10) || 1}
            pages={pages}
            selfId={self ? self.id : null}
            users={users}
          />
        </MainContent>
      </Content>
    );
  }

}

const mapStateToProps = state => ({
  ...state.user.index,
  search: getStateSearch(state),
  self: _.get(state, 'ui.self'),
  previousSelf: _.get(state, 'ui.previousSelf')
});

const actions = {
  ...indexActions,
  switchUser,
  gritterAlertPush,
  push,
};

export default connect(mapStateToProps, actions)(withRouter(ConnectedUserIndex));
