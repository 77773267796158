import React from 'react';
import propTypes from 'prop-types';
import { AlertWithMessage } from 'client/components/Be/Alert';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

const Message = props => <span style={{}}>{props.children}</span>;

export default class InstanceTrialAlert extends React.Component {

  static propTypes = {
    subscription: propTypes.object,
    instanceStatus: propTypes.string,
  }

  alertProps = {icon: true, className: 'alert-icon'}

  getDaysLeft() {
    const { trial_start:trialStart, trial_end:trialEnd } = _.get(this.props, 'subscription.stripeObject', {});
    if(!trialStart || !trialEnd) return 'a few more days';
    const mEnd = moment(trialEnd, 'X');
    const mNow = moment();
    if(mNow.isAfter(mEnd)) return 'one more day';
    return String(Math.ceil(moment.duration(mEnd.diff(mNow)).asDays())) + ' more days';
  }

  renderTrialMessage() {
    return (
      <AlertWithMessage {...this.alertProps} alertType="warning">
        <Message>
          This instance is in trial mode. You can use all of the features for {this.getDaysLeft()}{' '}
          before it is inactivated and you need to <Link to="/account/subscriptions">buy a subscription</Link>.
        </Message>
      </AlertWithMessage>
    );
  }

  renderPayReminder() {
    const { instanceStatus } = this.props;
    const message = instanceStatus === 'inactive' ? 'This instance has been inactivated.' : 'This instance is going to be inactivated soon.';
    return (
      <AlertWithMessage {...this.alertProps} alertType="danger">
        <Message>
          {message}{' '}
          Please <Link to="/account/subscriptions">buy a subscription</Link> or <Link to="/account/billing">fix your payment issues</Link> to automatically activate it again.
        </Message>
      </AlertWithMessage>
    );
  }

  render() {
    const { subscription } = this.props;
    if(!subscription) return null;
    const { status } = subscription;
    return (
      <div>
        {status === 'trialing' && this.renderTrialMessage()}
        {['unpaid', 'canceled', 'past_due'].includes(status) && this.renderPayReminder()}
      </div>
    );
  }

}
