import React from 'react';
import { App, Content, MainContent } from 'client/components/App';
import { LOGIN_SUCCESS } from 'client/redux/auth/constants';
import { setBodyClass } from 'client/redux/bodyClass';
import { SplashFooter, SplashHeader } from 'client/components/Branding';
import LoginForm from 'client/components/Login/components/LoginForm';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import FlashAlert from 'client/components/App/FlashAlert';
import { login } from 'client/redux/auth/actions';
import BeLoading from 'client/components/Be/BeLoading';
import { loginUiProps } from 'client/redux/auth/loginActions';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { push } from 'connected-react-router';
import _ from 'lodash';

class Login extends React.Component {

  componentDidMount() {
    this.props.loginUiProps();
  }

  handleFormSubmit = fields => {
    return this.props.login(fields).then(getResponseToFormErrorHandler()).then(action => {
      if(action.type === LOGIN_SUCCESS) {
        return this.props.push('/');
      }
    });
  }

  canSignup() {
    return _.get(this.props, 'settings.user_signup_active.value', true);
  }

  render() {
    const { isLoggingIn, isReading } = this.props;
    setBodyClass('be-splash-screen');
    return (
      <App className="be-login">
        <Helmet>
          <title>Login to your account</title>
        </Helmet>
        <Content>
          <MainContent className="container-fluid">
            <BeLoading loading={isReading} positionStatic>
              {!isReading && (
                <div className="splash-container">
                  <FlashAlert />
                  <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
                    <SplashHeader className="panel-heading">Please enter your user information to access your account.</SplashHeader>
                    <div className="panel-body">
                      <LoginForm isLoggingIn={isLoggingIn} onSubmit={this.handleFormSubmit} canSignup={this.canSignup()} />
                    </div>
                  </div>
                  <SplashFooter />
                </div>
              )}
            </BeLoading>
          </MainContent>
        </Content>
      </App>
    );
  }
}

const mapStateToProps = state => ({
  ...state.login,
  isLoggedIn: state.login.isLoggedIn || state.auth.isLoggedIn,
});

export default connect(mapStateToProps, { login, loginUiProps, push })(Login);
