import _ from 'lodash';

export function metaToValidationState({valid, pristine, warning, error}, {ignorePristine = true, defaultState = 'success', showSuccess = false} = {}) {
  if(ignorePristine && pristine) return null;
  if(warning) return 'warning';
  if(error) return 'error';
  if(valid && showSuccess) return 'success';
  return defaultState;
};

export function showError({pristine, error}, {ignorePristine = true} = {}) {
  if(ignorePristine && pristine) return false;
  if(error) return true;
  return false;
};

export function normalizeBoolean(value) {
  if(value === 'true') return true;
  if(value === 'false') return false;
  return value;
};

export function getReduxFieldId(props = {}, value) {
  const base = _.get(props, 'base', 'form');
  const name = _.get(props, 'input.name', 'field');
  let id = `${base}-${name}`;
  if(_.isString(value) || _.isBoolean(value)) id += '-' + value;
  return id;
};

export function escapeDottedName(name) {
  return name.replace(/\./g, () => '*');
};

export function unescapeDottedName(name) {
  return name.replace(/\*/g, () => '.');
};

export function unescapeDottedObject(obj) {
  return _.reduce(obj, (output, value, key) => {
    const unescapedKey = unescapeDottedName(key);
    if(_.isPlainObject(value)) {
      output[unescapedKey] = unescapeDottedObject(value);
    } else {
      output[unescapedKey] = value;
    }
    return output;
  }, {});
};

export function escapeDottedObject(obj) {
  return _.reduce(obj, (output, value, key) => {
    const escapedKey = escapeDottedName(key);
    if(_.isPlainObject(value)) {
      output[escapedKey] = escapeDottedObject(value);
    } else {
      output[escapedKey] = value;
    }
    return output;
  }, {});
};
