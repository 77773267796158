export const tableOrderBy = (type, orderBy, orderDirection = 'ASC') => {
  return {type, orderBy, orderDirection};
};

export const tableSetPage = (type, page) => {
  return {type, page};
};

export const tableFilter = (type, filter) => {
  return {type, filter};
};

export const tableSetColumns = (type, columns) => ({type, columns});
