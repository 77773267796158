import { apiAction } from 'client/redux/apiHelpers';

export const marketplaceFilter = (type, filter) => {
  return {
    type,
    filter: filter
  };
};

export const marketplaceIndex = types => {
  return apiAction({
    endpoint: '/api/marketplace',
    method: 'GET',
    types
  });
};
