import { CONNECTOR_PANEL_READ, CONNECTOR_PANEL_UPDATE } from 'client/redux/connector/constants';
import { connectorRead, connectorUpdate } from 'client/redux/connector/actionCreators';

export const connectorPanelRead = id => dispatch => {
  return dispatch(connectorRead(CONNECTOR_PANEL_READ, id));
};

export const connectorPanelUpdate = (id, update) => dispatch => {
  return dispatch(connectorUpdate(CONNECTOR_PANEL_UPDATE, id, update));
};
