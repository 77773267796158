import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import DashboardNews from 'client/components/Dashboard/DashboardNews';
import FlashAlert from 'client/components/App/FlashAlert';
import DashboardInstances from 'client/components/Dashboard/DashboardInstances';
import ConnectedDashboardWelcomeMessage from 'client/components/Dashboard/ConnectedDashboardWelcomeMessage';

export default class Dashboard extends Component {

  render() {
    return (
      <MainContent>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Content>
          <FlashAlert />
          <ConnectedDashboardWelcomeMessage />
          <div className="row">
            <div className="col-md-6">
              <DashboardInstances />
            </div>
            <div className="col-md-6">
              <DashboardNews />
            </div>
          </div>
        </Content>
      </MainContent>
    );
  }
}
