import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';
import ObjectRow from 'client/components/Helpers/ObjectRow';
import _ from 'lodash';

const ObjectGraph = ({mwDevice}) => {
  return (
    <>
      <ObjectRow subject="Device and related objects" object={mwDevice} />
    </>
  );
};

export default class MWTDTesterDevice extends React.Component {

  static propTypes = {
    id: propTypes.string,
    device: propTypes.object,
    onRemove: propTypes.func,
    onSync: propTypes.func
  }

  handleClickSync = ev => {
    this.props.onSync(this.props.id);
  }

  handleClickRemove = ev => {
    this.props.onRemove(this.props.id);
  }

  renderNoticeTableRows(notices) {
    const device = this.props.device || {};
    if(!notices || !notices.length) {
      return (
        <tr>
          <td colSpan="3">
            <em>No action taken with this device</em>
          </td>
        </tr>
      );
    }

    const { wasLast } = device;
    const futureTense = wasLast !== 'synced';

    return notices.map((notice, index) => {
      return (
        <NoticeRow
          key={index}
          notice={notice}
          subject="something"
          futureTense={futureTense}
        />
      );
    });
  }

  renderNoticeTable() {
    const { device } = this.props;
    const { notices, mwDevice } = device;
    if(!notices && !mwDevice) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {mwDevice && <ObjectGraph mwDevice={mwDevice} />}
              {this.renderNoticeTableRows(notices)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { device } = this.props;
    if(!device) return null;
    const { error } = device;
    const deviceName = _.get(device, 'mwDevice.device.name');
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-account xs-mr-5"></i>
            {deviceName}
          </div>
          <span className="btn-group pull-right">
            <ButtonWaiting type="button" onClick={this.handleClickSync} isWaiting={device.isSyncing} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>
              Sync
            </ButtonWaiting>
            <button onClick={this.handleClickRemove} type="button" className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-delete"></i>
              Remove from test
            </button>
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} />
        {this.renderNoticeTable()}
      </div>
    );
  }

};
