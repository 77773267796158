import * as actionCreators from 'client/redux/endpoint/azureOpenAi/actionCreators';
import * as constants from 'client/redux/endpoint/azureOpenAi/constants';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointAzureOpenAiPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(constants.ENDPOINT_AZURE_OPEN_AI_PRELOADED, endpoint));
};

export const endpointAzureOpenAiRead = id => dispatch => {
  return dispatch(actionCreators.endpointAzureOpenAiRead(constants.ENDPOINT_AZURE_OPEN_AI_READ, id));
};

export const endpointAzureOpenAiEditSettings = isEditing => dispatch => {
  return dispatch(actionCreators.endpointAzureOpenAiEditSettings(constants.ENDPOINT_AZURE_OPEN_AI_EDIT_SETTINGS, isEditing));
};

export const endpointAzureOpenAiUpdateSettings = (id, settings) => dispatch => {
  return dispatch(actionCreators.endpointAzureOpenAiUpdateSettings(constants.ENDPOINT_AZURE_OPEN_AI_UPDATE_SETTINGS, id, settings));
};
