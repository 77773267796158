import { ACCOUNT_INVOICE_INDEX } from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: null,
  invoices: null
};

export default function invoiceIndexReducer(state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_INVOICE_INDEX, state, action, {
    requestProp: 'isReading',
    successSpread: true,
    successPickProps: ['invoices']
  });
  return state;
};
