import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import ConnectedPVTDOptionsForm from 'client/components/PVTD/ConnectedPVTDOptionsForm';
import ConnectedPVTDTester from 'client/components/PVTD/ConnectedPVTDTester';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

export default class PVTDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Person Verification → TOPdesk Incidents</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={ConnectedPVTDOptionsForm}
            TesterComponent={ConnectedPVTDTester}
            endpointOrder={['topdesk', 'bankid']}
          />
        </MainContent>
      </Content>
    );
  }

}
