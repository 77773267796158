// import * as actionCreators from './actionCreators';
import { instanceReadStatistics } from 'client/redux/instance/actionCreators';
import { INSTANCE_OPTD_READ_STATISTICS } from 'client/redux/optd/constants';

export const instanceOptdReadStatistics = (id, statistic, query) => dispatch => {
  switch(statistic) {
    default: return dispatch(instanceReadStatistics(INSTANCE_OPTD_READ_STATISTICS, id, statistic, query));
    // case 'fastAlarmsProcessed': return dispatch(actionCreators.instanceOptdReadStatistics(INSTANCE_OPTD_READ_STATISTICS, id, statistic, query));
  }
};
