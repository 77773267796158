import { USER_PANEL_CREATE} from 'client/redux/user/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isCreating: false,
  error: null,
  user: null
};

export default function newPanelReducer(state = defaultState, action) {
  state = reduceByTypes(USER_PANEL_CREATE, state, action, {requestProp: 'isCreating', successSpread: true});
  return state;
};
