import { stringify } from 'querystring-es3';
import { RSAA } from 'redux-api-middleware';
import { SubmissionError } from 'redux-form';
import qs from 'qs';
import _ from 'lodash';

export const apiAction = (outerProps) => {
  const props = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin',
    ...outerProps
  };

  if(props.json) {
    props.body = JSON.stringify(props.json);
    delete props.json;
  }

  return {
    [RSAA]: props
  };
};

/**
 * @description Appends a querystring to the end of the URL if given
 */
export const appendQuery = (url, query) => {
  if(_.keys(query).length > 0) {
    url = url + '?' + stringify(query);
  }
  return url;
};

/**
 * @desc Returns current search string parsed from the state
 */
export const getStateSearch = state => {
  const search = _.get(state, 'router.location.search', '');
  if(!search) return {};
  // slice because "?" is the first char
  return qs.parse(search.slice(1));
};

/**
 * @description Returns true if the passed api response is a validation result error
 */
export const isApiValidationResultError = result => {
  const error = (result || {}).response;
  return Boolean(error && !error.ok && 'validationResult' in error);
};

/**
 * @desc Returns an error message from a response object from the API
 * @param {Object} responseBody
 * @param {Boolean} [alwaysReturnSomething] When true will return an "unknown error string" when nothing could be found, else null
 * @return {String}
 */
export const responseToErrorMessage = (responseBody, alwaysReturnSomething = false) => {
  const responseReason = _.get(responseBody, 'reason');
  const responseErrorName = _.get(responseBody, 'error.name');
  const responseErrorMessage = _.get(responseBody, 'error.message');
  const responseMessage = _.get(responseBody, 'message');
  const tokens = [responseErrorName, responseReason, responseErrorMessage, responseMessage];

  if(tokens.some(token => token)) {
    // if any of the tokens exists
    return tokens.filter(token => token).join(' - ');
  }

  if(responseBody.message) {
    return responseBody.message;
  }

  return alwaysReturnSomething ? 'No specific reason given / unknown error format' : null;
};

/**
 * @description Returns a function that adapts an API error to something that fits with redux-form
 * @param {Function} [options.aliasFn] Optional mapper function that receives the error and should return the key to put the message in
 * @return {Function}
 */
export const getResponseToFormErrorHandler = (options = {}) => {
  const { aliasFn } = {
    aliasFn: _.identity,
    ...options
  };
  return response => {
    if(response && response.error) {
      const body = response.payload.response || {};
      switch(true) {
        case _.keys(body.validationResult).length > 0: {
          const fields = _.keys(body.validationResult).reduce((fields, key) => {
            const resultKey = key === 'undefined' ? '_error' : aliasFn(key);
            _.set(fields, resultKey, body.validationResult[key].msg);
            return fields;
          }, {});
          throw new SubmissionError(fields);
        }
        case Boolean(body.error && body.error.message):
          throw new SubmissionError({_error: body.error.message});
        case !!body.reason:
          throw new SubmissionError({_error: body.reason});
        default:
          throw new SubmissionError({_error: 'Unknown Error'});
      }
    }
    return Promise.resolve(response);
  };
};

/**
 * @description Helper for request/success/failure reducer
 * @param {Array} types request, succces, failure
 * @param {Object} state
 * @param {Object} action
 * @param {Object} [options]
 * @param {String|Function} [options.requestProp='isRequesting'] If string, property in state set to true when requesting, if function, passed state and function
 * @param {Boolean} [options.successSpread=false] If true, success value will be spread over the state, not assigned to a specific variable
 * @param {String|Function} [options.successProp='result'] If string, property in state set to successful result, if function, passed state and function
 * @param {String|Function} [options.errorProp='error'] If string, property in state set to error result, if function, passed state and function
 * @param {Array<String>} [options.successPickProps] Pick these props from the success result and set them on the state object
 * TODO option för successSpread = true som gör att properties som redan finns definierade i state är de enda som ingår
 */
export const reduceByTypes = (types, state, action, { requestProp, successProp, errorProp, successSpread, successPickProps } = {}) => {
  if(!state || !action) {
    return state;
  }

  const [REQUEST, SUCCESS, FAILURE] = types;

  if(!_.isFunction(requestProp)) {
    const requestPropKey = requestProp || 'isRequesting';
    requestProp = (state, action, isRequesting) => ({
      [requestPropKey]: isRequesting
    });
  }

  if(!_.isFunction(successProp)) {
    const successPropKey = successProp || 'result';
    successProp = (state, action, value) => {
      let successResult = {[successPropKey]: value};
      if(successSpread) successResult = {...value};
      if(successPickProps) successResult = _.pick(value, successPickProps);
      return successResult;
    };
  }

  if(!_.isFunction(errorProp)) {
    const errorPropKey = errorProp || 'error';
    errorProp = (state, action, error) => ({
      [errorPropKey]: error
    });
  }

  const mergeRequest = (state, action, isRequesting) => ({
    ...state,
    ...requestProp(state, action, isRequesting)
  });

  const mergeSuccess = (state, action, value) => ({
    ...state,
    ...requestProp(state, action, false),
    ...errorProp(state, action, null),
    ...successProp(state, action, value)
  });

  const mergeError = (state, action, error) => ({
    ...state,
    ...requestProp(state, action, false),
    ...errorProp(state, action, error)
  });

  switch(action.type) {
    case REQUEST: return mergeRequest(state, action, true);
    case SUCCESS: return mergeSuccess(state, action, action.payload || {});
    case FAILURE: return mergeError(state, action, action.payload || {});
    default: return state;
  }
};
