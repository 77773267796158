import {
  INSTANCE_GRTD_TEST_USER_ADD_REQUEST,
  INSTANCE_GRTD_TEST_USER_ADD_SUCCESS,
  INSTANCE_GRTD_TEST_USER_ADD_FAILURE,
  INSTANCE_GRTD_RELOAD_USER_REQUEST,
  INSTANCE_GRTD_RELOAD_USER_SUCCESS,
  INSTANCE_GRTD_RELOAD_USER_FAILURE,
  INSTANCE_GRTD_TEST_CLEAR,
  INSTANCE_GRTD_TEST_USER_REMOVE,
  INSTANCE_GRTD_SYNC_USER_REQUEST,
  INSTANCE_GRTD_SYNC_USER_SUCCESS,
  INSTANCE_GRTD_SYNC_USER_FAILURE
} from 'client/redux/grtd/constants';
import { get } from 'lodash';

const defaultState = {
  error: null,
  isAdding: false,
  users: {}
};

export default function testReducer(state = defaultState, action) {
  switch(action.type) {
    default: return state
    case INSTANCE_GRTD_TEST_USER_ADD_REQUEST: return {
      ...state,
      isAdding: true
    };
    case INSTANCE_GRTD_TEST_USER_ADD_FAILURE: return {
      isAdding: false,
      error: action.error,
      ...state
    };
    case INSTANCE_GRTD_TEST_USER_ADD_SUCCESS: {
      const userId = get(action, 'payload.graphUser.id');
      return {
        ...state,
        isAdding: false,
        users: mutateInUsers(userId, action.payload)
      };
    }
    case INSTANCE_GRTD_TEST_CLEAR: return {
      ...state,
      users: {}
    };
    case INSTANCE_GRTD_TEST_USER_REMOVE: {
      const userId = get(action, 'userId');
      return {
        ...state,
        users: mutateInUsers(userId, null)
      };
    }
    case INSTANCE_GRTD_RELOAD_USER_REQUEST: return {
      ...state,
      users: mutateInUsers(action.meta.userId, {
        isReloading: true,
      })
    };
    case INSTANCE_GRTD_RELOAD_USER_FAILURE: return {
      ...state,
      users: mutateInUsers(action.meta.userId, {
        error: action.payload,
        isReloading: false,
      })
    };
    case INSTANCE_GRTD_RELOAD_USER_SUCCESS: return {
      ...state,
      users: mutateInUsers(action.meta.userId, {
        ...action.payload,
        isReloading: false,
        error: null
      })
    };
    case INSTANCE_GRTD_SYNC_USER_REQUEST: return {
      ...state,
      users: mutateInUsers(action.meta.userId, {
        isSyncing: true
      })
    };
    case INSTANCE_GRTD_SYNC_USER_FAILURE: return {
      ...state,
      users: mutateInUsers(action.meta.userId, {
        error: action.payload,
        isSyncing: false,
        wasLast: getWasLast(action.meta)
      })
    };
    case INSTANCE_GRTD_SYNC_USER_SUCCESS: return {
      ...state,
      users: mutateInUsers(action.meta.userId, {
        ...action.payload,
        isSyncing: false,
        wasLast: getWasLast(action.meta),
        error: null
      })
    };
  }

  function getWasLast(actionMeta) {
    return actionMeta.sync ? 'synced' : 'tested';
  }

  function mutateInUsers(id, props)  {
    const users = state.users || {};
    let user = users[id];
    if(props) {
      user = {...(user || {}), ...props};
    } else {
      user = null;
    }
    return {...users, [id]: user};
  }

};
