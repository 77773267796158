import React  from 'react';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { CharAddon } from 'client/components/Form/Helpers';
import { connect } from 'react-redux';
import { FormReduxInputGroup, FormReduxSelect2 } from 'client/components/Form/FormRedux';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { ModalConfirmButton, ModalContentConfirm } from 'client/components/Helpers/Modal';
import { isFilled, isString } from 'client/redux/validators';
import { Form, Field, reduxForm } from 'redux-form';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import FieldMapFormComponent from 'client/components/FieldMap/FieldMapFormComponent';
import { escapeDottedName } from 'client/redux/formHelpers';
import { BeWizardTitle } from 'client/components/Be/BeWizard';
import { normalizeBoolean } from 'client/redux/formHelpers';
import classNames from 'classnames';
import { targetToLabel } from 'client/redux/field/constants';
import FSTDFieldMapperItemLinksManager from 'client/components/FSTD/components/FSTDFieldMapperItemLinksManager';
import FSTDFieldMapperItemAssignmentsManager from 'client/components/FSTD/components/FSTDFieldMapperItemAssignmentsManager';
import _ from 'lodash';
import { Prompt } from 'react-router';
import 'client/components/FSTD/components/FSTDFieldMapperItem.css';

const validators = {
  isNonEmptyString: [isString(), isFilled()],
};

class FSTDFieldMapperItem extends React.Component {

  static propTypes = {
    id: propTypes.string,
    topdeskEndpointId: propTypes.string.isRequired,
    testFileColumns: propTypes.arrayOf(propTypes.string),
    apiError: propTypes.object,
    isNew: propTypes.bool,
    isEditing: propTypes.bool,
    onDelete: propTypes.func,
    onEdit: propTypes.func,
  }

  static defaultProps = {
    isNew: false,
    isEditing: false,
  }

  handleDelete = () => {
    const { initialValues: {fileName, assetTemplateId}, onDelete, isNew } = this.props;
    return onDelete(fileName, assetTemplateId, isNew);
  }

  isDisabled(fieldName) {
    const { isNew, isEditing, disabled } = this.props;
    return disabled || !isEditing || !isNew;
  }

  renderHeaderForm() {
    const { topdeskEndpointId, id } = this.props;
    return (
      <div className="col-sm-7">
        <div className="header-form xs-mb-0">
          <Field
            base={`${id}FileName`}
            className="form-group-sm fileName xs-mb-0"
            name="fileName"
            placeholder="Enter file name"
            component={FormReduxInputGroup}
            after={<CharAddon value=".csv" />}
            required={true}
            disabled={this.isDisabled('fileName')}
            validate={validators.isNonEmptyString}
          />
          <button className="btn btn-default btn-icon btn-lg btn-direction" disabled={true}>
            <i className="mdi mdi-icon mdi-arrow-right"></i>
          </button>
          <div className="assetTemplate">
            <Field
              base={`${id}AssetTemplateId`}
              name="assetTemplateId"
              component={FormReduxSelect2}
              required={true}
              className="form-group-sm xs-mb-0"
              select2ComponentProps={{endpointId: topdeskEndpointId, forWhat: 'assetTemplateId'}}
              select2Component={EndpointTopdeskRemoteSearcher}
              select2Options={{placeholder: 'Choose Asset Template'}}
              disabled={this.isDisabled('assetTemplateId')}
              validate={validators.isNonEmptyString}
            />
          </div>
        </div>
      </div>
    );
  }

  handleClickEdit = () => {
    const { initialValues: {fileName, assetTemplateId}, isEditing } = this.props;
    this.props.onEdit(fileName, assetTemplateId, !isEditing);
    if(!isEditing) return this.props.reset();
  }

  renderDeleteButton() {
    const { isNew, disabled } = this.props;
    if(isNew) return (
      <button type="button" className="btn btn-default btn-lg" onClick={this.handleDelete}>
        <i className="icon mdi mdi-delete"></i>{' ' }
      </button>
    );
    const confirmProps = {
      title: 'Confirm field map deletion',
      type: 'warning',
      text: <span>Are you sure you want to delete this field map?</span>,
    };
    return (
      <ModalConfirmButton disabled={disabled} className="btn btn-default btn-lg" callback={this.handleDelete} contentProps={confirmProps} Content={ModalContentConfirm}>
        <i className="icon mdi mdi-delete"></i>{' ' }
      </ModalConfirmButton>
    );
  }

  renderSaveButton() {
    const { isNew, isEditing, invalid, isSaving, dirty, pristine, disabled } = this.props;
    if(!isEditing) return null;
    const classes = classNames('btn', 'btn-lg', dirty && !invalid ? 'btn-success' : 'btn-default');
    return (
      <ButtonWaiting isWaiting={isSaving} disabled={disabled || invalid || pristine} className={classes} type="submit">
        <i className="icon icon-left mdi mdi-check"></i>{' '}
        {isNew ? 'Save and open editor' : 'Save'}
      </ButtonWaiting>
    );
  }

  renderEditButton() {
    const { isEditing, isNew } = this.props;
    if(isNew) return null;
    if(isEditing) return (
      <button className="btn btn-default btn-lg" onClick={this.handleClickEdit} type="button">
        <i className="icon icon-left mdi mdi-close"></i>{' '}
        Close
      </button>
    );
    return (
      <button className="btn btn-default btn-lg" onClick={this.handleClickEdit} type="button">
        <i className="icon icon-left mdi mdi-edit"></i>{' '}
        Edit
      </button>
    );
  }

  getDefaultSource(fieldName) {
    switch(fieldName) {
      case 'name': return {type: 'FieldSourceFSTDObject', props: {fieldName: 'ID', ignoreFieldCase: true}};
      default: return {type: 'FieldSourceIgnore'};
    }
  }

  getAvailableSources() {
    const { testFileColumns } = this.props;
    return [
      {type: 'FieldSourceFSTDObject', columns: testFileColumns },
    ];
  }

  renderFieldMap() {
    const { fieldTargets = [], id, topdeskEndpointId, disabled } = this.props;
    if(!fieldTargets || !fieldTargets.length) return <p className="xs-m-0">There is nothing here yet.</p>;
    return fieldTargets.map(target => {
      const { name } = target;
      return (
        <Field
          key={name}
          name={`map.${escapeDottedName(name)}`}
          base={`${id}${name}`}
          component={FieldMapFormComponent}
          label={targetToLabel(target)}
          disabled={disabled}
          target={target}
          defaultSource={this.getDefaultSource(name)}
          availableSources={this.getAvailableSources()}
          endpointIds={{topdeskId: topdeskEndpointId}}
        />
      );
    });
  }

  renderFileMapSettings() {
    const onlyUpdate = _.get(this.props, 'settings.onlyUpdate', false);
    return (
      <Field
        className="xs-m-0"
        base={'fstdOptionsIgnoreUnmappedFiles'}
        name="settings.onlyUpdate"
        label="Non-existing assets"
        component={FormReduxSelect2}
        data={[
          {id: false, text: 'Will be created'},
          {id: true, text: 'Will be ignored'},
        ]}
        defaultValue={onlyUpdate}
        normalize={normalizeBoolean}
      />
    );
  }

  renderBody() {
    const {
      isEditing,
      isReadingFieldTargets,
      isNew,
      topdeskEndpointId,
      disabled,
      id,
    } = this.props;
    if(!isEditing || isNew) return null;
    const { links, testFileColumns, assignments } = this.props;
    return (
      <div className="panel-body">
        <BeLoading className="xs-m-20" loading={isReadingFieldTargets}>
          <div className="row">
            <div className="col-md-6">
              <BeWizardTitle subTitle="Fields that are currently available in the selected TOPdesk Asset Template">Asset Template Fields</BeWizardTitle>
              <div className="well xs-mb-0 form-horizontal">
                {this.renderFieldMap()}
              </div>
            </div>
            <div className="col-md-6">
              <BeWizardTitle subTitle="Maps columns in this file to other TOPdesk Asset Templates">Asset Links</BeWizardTitle>
              <div className="well xs-mb-20">
                <Field
                  name="links"
                  idSuffix={id}
                  component={FSTDFieldMapperItemLinksManager}
                  columns={testFileColumns}
                  topdeskEndpointId={topdeskEndpointId}
                  disabled={disabled}
                  value={links}
                />
              </div>
              <BeWizardTitle subTitle="Maps columns in this file for assigning other TOPdesk objects">Asset Assignments</BeWizardTitle>
              <div className="well xs-mb-20">
                <Field
                  name="assignments"
                  idSuffix={id}
                  component={FSTDFieldMapperItemAssignmentsManager}
                  columns={testFileColumns}
                  disabled={disabled}
                  value={assignments}
                />
              </div>
              <BeWizardTitle subTitle="Special settings for how this file map is handled">Behavior</BeWizardTitle>
              <div className="well xs-mb-0">
                {this.renderFileMapSettings()}
              </div>
            </div>
          </div>
        </BeLoading>
      </div>
    );
  }

  renderApiError() {
    const { apiError } = this.props;
    if(!apiError) return null;
    return (
      <div className="panel-body xs-p-20">
        <BeRemoteErrorAlert className="fstd-fieldmap-error" error={apiError} />
      </div>
    );
  }

  getPanelClassNames() {
    const { isEditing, isNew, dirty } = this.props;
    return [
      'panel', 'panel-border', 'panel-contrast', 'panel-table', 'fstd-fieldmap',
      isEditing ? 'is-editing' : 'is-not-editing',
      isNew ? 'is-new' : 'is-not-new',
      dirty ? 'panel-border-color panel-border-color-success' : '',
    ];
  }

  render() {
    const { onSubmit, dirty } = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(onSubmit)} >
        <Prompt when={dirty} message="Are you sure you want to leave without saving?" />
        <div className={classNames(this.getPanelClassNames())}>
          <div className="panel-heading panel-heading-contrast xs-p-20">
            <div className="row">
              {this.renderHeaderForm()}
              <div className="col-sm-5 sm-mb-0 xs-mb-15">
                <div className="pull-right">
                  <div className="btn-group btn-group-lg">
                    {this.renderSaveButton()}{' '}
                    {this.renderEditButton()}{' '}
                  </div>
                  {' '}{this.renderDeleteButton()}
                </div>
              </div>
            </div>
          </div>
          {this.renderApiError()}
          {this.renderBody()}
        </div>
      </Form>
    );
  }

}

export default connect()(reduxForm({
  enableReinitialize: true,
})(FSTDFieldMapperItem));
