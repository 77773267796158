import { USER_LOG_INDEX, USER_LOG_INDEX_ORDERBY, USER_LOG_INDEX_SET_PAGE, USER_LOG_INDEX_FILTER } from 'client/redux/user/log/constants';
import { userLog } from 'client/redux/user/log/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const userLogIndex = query => dispatch => {
  return dispatch(userLog(USER_LOG_INDEX, query));
};

export const userLogIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(USER_LOG_INDEX_ORDERBY, orderBy, orderDirection));
};

export const userLogIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(USER_LOG_INDEX_SET_PAGE, page));
};

export const userLogIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(USER_LOG_INDEX_FILTER, filter));
};
