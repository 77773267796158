export const SETTINGS_INDEX_REQUEST = 'SETTINGS_INDEX_REQUEST';
export const SETTINGS_INDEX_FAILURE = 'SETTINGS_INDEX_FAILURE';
export const SETTINGS_INDEX_SUCCESS = 'SETTINGS_INDEX_SUCCESS';
export const SETTINGS_INDEX = [SETTINGS_INDEX_REQUEST, SETTINGS_INDEX_SUCCESS, SETTINGS_INDEX_FAILURE];

export const SETTINGS_PANEL_READ_REQUEST = 'SETTINGS_PANEL_READ_REQUEST';
export const SETTINGS_PANEL_READ_FAILURE = 'SETTINGS_PANEL_READ_FAILURE';
export const SETTINGS_PANEL_READ_SUCCESS = 'SETTINGS_PANEL_READ_SUCCESS';
export const SETTINGS_PANEL_READ = [SETTINGS_PANEL_READ_REQUEST, SETTINGS_PANEL_READ_SUCCESS, SETTINGS_PANEL_READ_FAILURE];

export const SETTINGS_PANEL_UPDATE_REQUEST = 'SETTINGS_PANEL_UPDATE_REQUEST';
export const SETTINGS_PANEL_UPDATE_FAILURE = 'SETTINGS_PANEL_UPDATE_FAILURE';
export const SETTINGS_PANEL_UPDATE_SUCCESS = 'SETTINGS_PANEL_UPDATE_SUCCESS';
export const SETTINGS_PANEL_UPDATE = [SETTINGS_PANEL_UPDATE_REQUEST, SETTINGS_PANEL_UPDATE_SUCCESS, SETTINGS_PANEL_UPDATE_FAILURE];
