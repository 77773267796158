import { LOGOUT_SUCCESS, LOGOUT_FAILURE } from 'client/redux/auth/constants';
import { redirectAndFlash } from 'client/redux/flashAlert/actions';

const authRouter = store => dispatch => action => {
  switch(action.type) {
    default: return dispatch(action);
    case LOGOUT_FAILURE: 
      dispatch(action);
      return redirectAndFlash('/login', {
        alertType: 'danger',
        msg: 'There was an error logging out'
      })(dispatch);
    case LOGOUT_SUCCESS:
      dispatch(action);
      return redirectAndFlash('/login', {
        alertType: 'primary',
        msg: 'You are now logged out'
      })(dispatch);
  }
};

export default authRouter;
