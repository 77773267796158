import { SUPPORT_QUESTIONS, SUPPORT_QUESTIONS_FILTER, SUPPORT_QUESTIONS_SUCCESS, SUPPORT_CATEGORY_CREATE, SUPPORT_CATEGORY_UPDATE, SUPPORT_CATEGORY_DELETE, SUPPORT_QUESTION_CREATE, SUPPORT_QUESTION_UPDATE, SUPPORT_QUESTION_DELETE } from 'client/redux/support/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import _ from 'lodash';

const defaultState = {
  error: null,
  filter: {search: ''},
  isReading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  questionsByCategory: null,
  categories: null,
  questions: null,
  hiddenQuestionsById: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(SUPPORT_QUESTION_DELETE, state, action, {requestProp: 'isDeleting', successSpread: true});
  state = reduceByTypes(SUPPORT_QUESTION_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(SUPPORT_QUESTION_CREATE, state, action, {requestProp: 'isCreating', successSpread: true});
  state = reduceByTypes(SUPPORT_CATEGORY_DELETE, state, action, {requestProp: 'isDeleting', successSpread: true});
  state = reduceByTypes(SUPPORT_CATEGORY_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(SUPPORT_CATEGORY_CREATE, state, action, {requestProp: 'isCreating', successSpread: true});
  state = reduceByTypes(SUPPORT_QUESTIONS, state, action, {requestProp: 'isReading', successSpread: true});

  switch(action.type) {
    case SUPPORT_QUESTIONS_SUCCESS: return {
      ...state,
      hiddenQuestionsById: getHiddenQuestionIds(state.questions, action.filter)
    };
    case SUPPORT_QUESTIONS_FILTER: return {
      ...state,
      filter: action.filter,
      hiddenQuestionsById: getHiddenQuestionIds(state.questions, action.filter)
    };
    default:
      return state
  }
};

function getHiddenQuestionIds(questions = [], filter = {}) {
  let search = (filter.search || '').toLowerCase();
  search = search.replace(/[^\w\s]/g, '');
  const match = new RegExp(search, 'gi');
  return _.reduce(questions, (hiddenQuestions, question) => {
    if(!match.test(question.title)) {
      hiddenQuestions[question.id] = true;
    }
    return hiddenQuestions;
  }, {});
}
