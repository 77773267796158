import React from 'react';
import Select2 from 'react-select2-wrapper';
import propTypes from 'prop-types';
import axios from 'axios'
import querystring from 'querystring-es3'
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import _ from 'lodash';

/**
 * @description Helper for creating a component that searches for a remote object
 */
export default function Select2SearchCreator(options = {}) {
  const { getSearchQuery, responseToResult, apiUrl } = options;

  return class Select2Search extends React.PureComponent {

    static propTypes = {
      onChange: propTypes.func.isRequired
    }

    state = {
      value: undefined,
      queryError: null,
      isOpen: false,
      isLoadingWhenClosed: false,
      data: [],
    }

    constructor(props) {
      super(props);
      this.unselectHandler = _.debounce(this._unselectHandler, 30);
      this.selectHandler = _.debounce(this._selectHandler, 30);
    }

    getCurrentValue(ev) {
      const { multiple } = this.props;
      if(multiple) return this.refs.selectRef.el.val();
      return ev ? ev.target.value : this.state.value;
    }

    _unselectHandler = ev => {
      const { name } = this.props;
      const value = this.getCurrentValue(ev);
      if(this.unmounting) return;
      setTimeout(() => {
        this.setState({value}, () => {
          this.props.onChange(value, name);
        });
      }, 1);
    }

    _selectHandler = ev => {
      const { name } = this.props;
      const value = this.getCurrentValue(ev);
      if(this.unmounting) return;
      setTimeout(() => {
        this.setState({value}, () => {
          this.props.onChange(value, name);
        });
      }, 1);
    }

    processData(response) {
      // const value = this.getCurrentValue();
      const results = responseToResult(response, this.props).filter(({id}) => {
        return true;
        // return _.isArray(value) ? !value.includes(id) : value !== id;
      });
      return {results};
    }

    componentDidMount() {
      if(this.props.defaultValue) {
        this.findByValue(this.props.defaultValue);
      }
    }

    componentWillUnmount() {
      this.unmounting = true;
    }

    componentDidUpdate(prevProps) {
      const newDV = this.props.defaultValue;
      if(!_.isEqual(newDV, prevProps.defaultValue) && !_.isEqual(this.state.value, newDV)) {
        if(newDV && newDV.length) {
          this.findByValue(newDV);
        } else {
          this.setState({value: newDV});
        }
      }
    }

    findByValue(value) {
      if(!value) return;
      const { extraQuery = {} } = this.props;
      const query = {id: value, ...extraQuery};
      this.search(query)
        .then(response => {
          if(!this.unmounting) {
            const data = this.processData(response).results;
            this.setState({data});
          }
        }, err => {
          // there was an error, so just set the id and be done with it
          if(!this.unmounting) {
            const data = [{id: value, text: value}];
            this.setState({data});
          }
        });
    }

    getOptions() {
      return {
        minimumInputLength: 3,
        ...this.props.options,
        ajax: {
          transport: this.transport,
          delay: 250
        }
      }
    }

    transport = (params, success, failure) => {
      const { extraQuery } = this.props;
      return this.search(getSearchQuery(params, extraQuery))
        .then(response => success(this.processData(response)))
        .catch(err => failure(err));
    }

    search(query) {
      const { isOpen } = this;
      const qs = querystring.stringify(query);

      const requestOptions = {
        url: _.isFunction(apiUrl) ? apiUrl(this.props) + `?${qs}` : `${apiUrl}?${qs}`,
        method: 'GET',
        headers: {'Accept': 'application/json'},
      };

      if(!isOpen) {
        this.setState({isLoadingWhenClosed: true});
      }

      return axios(requestOptions)
        .catch(err => {
          this.setState({isLoadingWhenClosed: false});
          if (err.response?.data) {
            this.setState({queryError: {response: err.response.data}});
            return err.response.data;
          }
          this.setState({queryError: {response: err.message}});
          return err.message;
        })
        .then(res => {
          this.setState({
            queryError: null,
            isLoadingWhenClosed: false,
          });
          return res.data;
        });
    }

    maybeRenderQueryError() {
      const { queryError } = this.state;
      if(!queryError) return null;
      return <BeRemoteErrorAlert icon={false} className="xs-mt-10" error={queryError} />;
    }

    onOpen = () => {
      this.isOpen = true;
    }

    onClose = () => {
      this.isOpen = false;
    }

    render() {
      const { data, value, isLoadingWhenClosed } = this.state;
      const props = _.pick(this.props, 'className', 'defaultValue', 'id', 'name', 'style', 'disabled', 'multiple', 'value', 'defaultValue');
      const style = {width: '100%', ...(this.props.style || {})};
      return (
        <BeLoading loading={isLoadingWhenClosed}>
          <Select2
            {...props}
            data={data}
            value={value}
            style={style}
            options={this.getOptions()}
            onSelect={this.selectHandler}
            onUnselect={this.unselectHandler}
            onOpen={this.onOpen}
            onClose={this.onClose}
            ref="selectRef"
          />
          {this.maybeRenderQueryError()}
        </BeLoading>
      );
    }
  
  };

};
