import { combineReducers } from 'redux';

import news from 'client/redux/dashboard/newsReducer';
import instance from 'client/redux/dashboard/instanceReducer';

const dashboardReducer = combineReducers({
  news,
  instance,
});

export default dashboardReducer;
