import { ENDPOINT_MANAGEDWORKPLACE_READ, ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS, ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS, ENDPOINT_MANAGEDWORKPLACE_EDIT_CREDENTIALS } from 'client/redux/endpoint/managedworkplace/constants';
import * as actionCreators from 'client/redux/endpoint/managedworkplace/actionCreators';
import { endpointRead } from 'client/redux/endpoint/actionCreators';

export const endpointManagedworkplaceRead = endpointId => dispatch => {
  return dispatch(endpointRead(ENDPOINT_MANAGEDWORKPLACE_READ, endpointId, 'managedworkplace'));
};

export const endpointManagedworkplaceEditCredentials = isEditing => dispatch => {
  return dispatch(actionCreators.endpointManagedworkplaceEditCredentials(ENDPOINT_MANAGEDWORKPLACE_EDIT_CREDENTIALS, isEditing));
};

export const endpointManagedworkplaceUpdateCredentials = (id, credentials) => dispatch => {
  return dispatch(actionCreators.endpointManagedworkplaceUpdateCredentials(ENDPOINT_MANAGEDWORKPLACE_UPDATE_CREDENTIALS, id, credentials));
};

export const endpointManagedworkplaceTestCredentials = (id) => dispatch => {
  return dispatch(actionCreators.endpointManagedworkplaceTestCredentials(ENDPOINT_MANAGEDWORKPLACE_TEST_CREDENTIALS, id));
};
