import React from 'react';
import propTypes from 'prop-types';
import { StripeAmount, UCFirst } from 'client/components/Helpers/Strings';
import { connect } from 'react-redux';
import * as subscriptionActions from 'client/redux/account/subscriptionActions';

/**
 * @desc Represents a subscription item in a subscription
 */
class AccountSubscriptionItemRow extends React.Component {

  static propTypes = {
    allPlansByStripeId: propTypes.object.isRequired,
    plan: propTypes.string.isRequired,
    subscriptionId: propTypes.string,
    quantity: propTypes.number.isRequired,
    deleted: propTypes.bool,
    canDelete: propTypes.bool,
    disabled: propTypes.bool,
  }

  static defaultProps = {
    canDelete: true,
  }

  handleChangeQuantity = add => {
    const { plan, subscriptionId, quantity } = this.props;
    return this.props.accountSubscriptionChangePlanQuantity(subscriptionId, plan, quantity + add);
  }

  handleToggleDeleted = () => {
    const { plan, subscriptionId, deleted } = this.props;
    return this.props.accountSubscriptionRemovePlan(subscriptionId, plan, !deleted);
  }

  getPlanObject() {
    const { plan, allPlansByStripeId } = this.props;
    return allPlansByStripeId[plan] || {};
  }

  renderDeleteButton() {
    const { canDelete, deleted, disabled } = this.props;
    if(deleted) {
      return (
        <button className="btn btn-default" type="button" onClick={this.handleToggleDeleted} disabled={disabled} title="Undo removing this plan from the subscription">
          <i className="icon mdi mdi-undo icon-left"></i>
        </button>
      )
    } else {
      return (
        <button className="btn btn-default" type="button" onClick={this.handleToggleDeleted} disabled={disabled || !canDelete} title="Remove this plan from the subscription">
          <i className="icon mdi mdi-delete icon-left"></i>
        </button>
      )
    }
  }

  isQuantityButtonDisabled() {
    const { deleted, disabled } = this.props;
    const { type } = this.getPlanObject();
    return deleted || disabled || type !== 'connector';
  }

  render() {
    const { quantity } = this.props;
    const { name, currency, amount, type } = this.getPlanObject();
    return (
      <tr>
        <td>{name}</td>
        <td><UCFirst str={type} /></td>
        <td className="number"><StripeAmount amount={amount} currency={currency} /></td>
        <td className="number">
          <div className="btn-group">
            <button className="btn btn-default" type="button" disabled>{quantity}</button>
            <button
              className="btn btn-default"
              type="button"
              title="Increase amount"
              disabled={this.isQuantityButtonDisabled()}
              onClick={ev => this.handleChangeQuantity(1)}
            >
              <i className="icon mdi mdi-long-arrow-up"></i>
            </button>
            <button
              className="btn btn-default"
              type="button"
              title="Decrease amount"
              disabled={quantity <= 1 || this.isQuantityButtonDisabled()}
              onClick={ev => this.handleChangeQuantity(-1)}
            >
              <i className="icon mdi mdi-long-arrow-down"></i>
            </button>
          </div>
        </td>
        <td className="number"><StripeAmount amount={amount*quantity} currency={currency} /></td>
        <td className="text-right">
          {this.renderDeleteButton()}
        </td>
      </tr>
    );
  }

}

const actions = {
  ...subscriptionActions,
};

export default connect(null, actions)(AccountSubscriptionItemRow);
