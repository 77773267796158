import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import propTypes from 'prop-types';

export default class QRTDOptionsFormFieldMapModal extends Component {

  static defaultProps = {
    open: false,
    isNew: false,
    initialValues: {title: ''},
  }

  static propTypes = {
    open: propTypes.bool.isRequired,
    isNew: propTypes.bool.isRequired,
    isSaving: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    onSave: propTypes.func.isRequired,
    initialValues: propTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = props.initialValues || {title: ''};
  }

  handleChange = ev => {
    this.setState({[ev.target.name]: ev.target.value});
  }

  reset() {
    this.setState({title: ''});
  }

  handleSubmit = ev => {
    ev.preventDefault();
    const form = {...this.state};
    this.reset();
    this.props.onSave(form);
  }

  handleClose = () => {
    this.reset();
    this.props.onClose();
  }

  render() {
    const { open, isSaving, isNew } = this.props;
    const { title } = this.state;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={this.handleClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={this.handleClose} />
          <Modal.Title>
            {isNew && (<span>New QR-code incident form</span>)}
            {!isNew && (<span>Edit QR-code incident form</span>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="qrtdOptionsModalFieldMapTitle">
                Title of the QR-code incident form
              </label>
              <input
                id="qrtdOptionsModalFieldMapTitle"
                className="form-control"
                type="text"
                name="title"
                value={title}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="text-right">
              <ButtonWaiting
                type="button"
                disabled={!title}
                className="btn btn-primary btn-lg"
                isWaiting={isSaving}
                onClick={this.handleSubmit}
              >
                Save
              </ButtonWaiting>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

}
