import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import _ from 'lodash';

export default class AccountSubscriptionFixProblem extends React.Component {

  static propTypes = {
    stripe: propTypes.object.isRequired,
    subscriptionId: propTypes.string.isRequired,
    paymentIntent: propTypes.object.isRequired,
    isReadingPaymentIntent: propTypes.bool.isRequired,
    onVerificationComplete: propTypes.func.isRequired,
  }

  static defaultProps = {
    isReadingPaymentIntent: false,
  }

  state = {
    error: null,
    verifying: false,
  }

  handleClickVerifyStripeSdk = () => {
    const { paymentIntent, stripe } = this.props;
    const secret = _.get(paymentIntent, 'client_secret');
    this.setState({verifying: true});
    stripe.handleCardPayment(secret).then(result => {
      this.setState({verifying: false}, () => {
        if(result.error) {
          this.setState({error: result.error});
        } else {
          this.props.onVerificationComplete();
        }
      });
    });
  }

  showResolution() {
    const { paymentIntent } = this.props;
    const { verifying, error } = this.state;
    switch(paymentIntent.status) {

      default: return (
        <>
          <p>
            Unfortunately we have been unable to automatically determine why the payment for this subscription has failed. Please contact support using the <strong>Support Form</strong> at the top right of this page or send an email to <a href="mailto:support@linknow.io">support@linknow.io</a> so that we can help you as soon as possible.
          </p>
        </>
      );

      // user action required
      case 'requires_action': {
        const nextAction = _.get(paymentIntent, 'next_action.type');
        switch(nextAction) {
          default: return (
            <>
              <p>
                Unfortunately you are unable to perform the required user action at this particular point in time. Please contact support using the <strong>Support Form</strong> at the top right of this page or send an email to <a href="mailto:support@linknow.io">support@linknow.io</a> so that we can help you as soon as possible.
              </p>
            </>
          );
          case 'use_stripe_sdk': return (
            <>
              <p>
                In order for us to be able to process this and future payments from you, you need to complete an extra verification step with your bank called 3D Secure. Performing this action is <strong>mandatory</strong> within 24 hours, after which any instances that are covered by the subscription will be inactivated.
              </p>
              <p>
                In case you miss the deadline you can always create a new subscription identical to the earlier expired one, as long as you make sure to complete any necessary actions required for payment to succeed.
              </p>
              <p>
                <ButtonWaiting className="btn-primary btn-lg" isWaiting={verifying} onClick={this.handleClickVerifyStripeSdk}>
                  <i className="icon icon-left mdi mdi-shield-check"></i>{' '}
                  Complete 3D Secure verification
                </ButtonWaiting>
              </p>
              <BeRemoteErrorAlert error={error} />
            </>
          );
        }
      }

    }
  }

  render() {
    return (
      <div className="text-center" style={{marginTop: '-25px'}}>
        <h3 className="xs-mb-20 xs-mt-0">Your action is required</h3>
        <p>
          Strong Customer Authentication (SCA), a new rule coming into effect on September 14, 2019, as part of PSD2 regulation in Europe, will require changes to how European customers authenticate online payments.
        </p>
        {this.showResolution()}
      </div>
    );
  }

}
