import React  from 'react';
import { InputGroup } from 'react-bootstrap';
import propTypes from 'prop-types';
import _ from 'lodash';

// a simple component holding a addon for showing which char is chosen
export const CharAddon = ({value, transform}) => (
  <InputGroup.Addon style={{fontFamily: 'monospace'}}>
    {transform ? transform(value) : value}
  </InputGroup.Addon>
);

// a component that is uncontrolled as long as initialValue is falsy, controlled after and updates value when input is blurred
export class SemiControlledInput extends React.Component {

  static propTypes = {
    onSetValue: propTypes.func.isRequired,
    initialValue: propTypes.string,
  }

  state = {
    value: '',
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.initialValue && !prevState.value) {
      this.setState({value: this.props.initialValue});
    }
  }

  handleChange = ev => {
    this.setState({value: ev.target.value});
  }

  handleBlur = ev => {
    const { name } = this.props;
    const { value } = this.state;
    this.props.onSetValue(name, value);
  }

  render() {
    const props = _.omit(this.props, 'initialValue', 'onSetValue');
    const { value } = this.state;
    return (
      <input
        {...props}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }

}
