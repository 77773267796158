export const INSTANCE_SWTD_READ_STATISTICS_REQUEST = 'INSTANCE_SWTD_READ_STATISTICS_REQUEST';
export const INSTANCE_SWTD_READ_STATISTICS_SUCCESS = 'INSTANCE_SWTD_READ_STATISTICS_SUCCESS';
export const INSTANCE_SWTD_READ_STATISTICS_FAILURE = 'INSTANCE_SWTD_READ_STATISTICS_FAILURE';
export const INSTANCE_SWTD_READ_STATISTICS = [INSTANCE_SWTD_READ_STATISTICS_REQUEST, INSTANCE_SWTD_READ_STATISTICS_SUCCESS, INSTANCE_SWTD_READ_STATISTICS_FAILURE];

export const INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET = 'INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET';

export const INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST = 'INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST';
export const INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS = 'INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS';
export const INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE = 'INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE';
export const INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ = [INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST, INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS, INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE];

export const INSTANCE_SWTD_OPTIONS_READ_META_REQUEST = 'INSTANCE_SWTD_OPTIONS_READ_META_REQUEST';
export const INSTANCE_SWTD_OPTIONS_READ_META_SUCCESS = 'INSTANCE_SWTD_OPTIONS_READ_META_SUCCESS';
export const INSTANCE_SWTD_OPTIONS_READ_META_FAILURE = 'INSTANCE_SWTD_OPTIONS_READ_META_FAILURE';
export const INSTANCE_SWTD_OPTIONS_READ_META = [INSTANCE_SWTD_OPTIONS_READ_META_REQUEST, INSTANCE_SWTD_OPTIONS_READ_META_SUCCESS, INSTANCE_SWTD_OPTIONS_READ_META_FAILURE];

export const INSTANCE_SWTD_TEST_COMPUTER_ADD_REQUEST = 'INSTANCE_SWTD_TEST_COMPUTER_ADD_REQUEST';
export const INSTANCE_SWTD_TEST_COMPUTER_ADD_SUCCESS = 'INSTANCE_SWTD_TEST_COMPUTER_ADD_SUCCESS';
export const INSTANCE_SWTD_TEST_COMPUTER_ADD_FAILURE = 'INSTANCE_SWTD_TEST_COMPUTER_ADD_FAILURE';
export const INSTANCE_SWTD_TEST_COMPUTER_ADD = [INSTANCE_SWTD_TEST_COMPUTER_ADD_REQUEST, INSTANCE_SWTD_TEST_COMPUTER_ADD_SUCCESS, INSTANCE_SWTD_TEST_COMPUTER_ADD_FAILURE];

export const INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_REQUEST = 'INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_REQUEST';
export const INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_SUCCESS = 'INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_SUCCESS';
export const INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_FAILURE = 'INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_FAILURE';
export const INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH = [INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_REQUEST, INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_SUCCESS, INSTANCE_SWTD_TEST_OBJECT_COMPUTER_OBJECTGRAPH_FAILURE];

export const INSTANCE_SWTD_TEST_COMPUTER_REMOVE = 'INSTANCE_SWTD_TEST_COMPUTER_REMOVE';
export const INSTANCE_SWTD_TEST_CLEAR = 'INSTANCE_SWTD_TEST_CLEAR';

export const INSTANCE_SWTD_TEST_COMPUTER_REQUEST = 'INSTANCE_SWTD_TEST_COMPUTER_REQUEST';
export const INSTANCE_SWTD_TEST_COMPUTER_SUCCESS = 'INSTANCE_SWTD_TEST_COMPUTER_SUCCESS';
export const INSTANCE_SWTD_TEST_COMPUTER_FAILURE = 'INSTANCE_SWTD_TEST_COMPUTER_FAILURE';
export const INSTANCE_SWTD_TEST_COMPUTER = [INSTANCE_SWTD_TEST_COMPUTER_REQUEST, INSTANCE_SWTD_TEST_COMPUTER_SUCCESS, INSTANCE_SWTD_TEST_COMPUTER_FAILURE];

export const INSTANCE_SWTD_SYNC_COMPUTER_REQUEST = 'INSTANCE_SWTD_SYNC_COMPUTER_REQUEST';
export const INSTANCE_SWTD_SYNC_COMPUTER_SUCCESS = 'INSTANCE_SWTD_SYNC_COMPUTER_SUCCESS';
export const INSTANCE_SWTD_SYNC_COMPUTER_FAILURE = 'INSTANCE_SWTD_SYNC_COMPUTER_FAILURE';
export const INSTANCE_SWTD_SYNC_COMPUTER = [INSTANCE_SWTD_SYNC_COMPUTER_REQUEST, INSTANCE_SWTD_SYNC_COMPUTER_SUCCESS, INSTANCE_SWTD_SYNC_COMPUTER_FAILURE];
