export const DASHBOARD_NEWS_LATEST_REQUEST = 'DASHBOARD_NEWS_LATEST_REQUEST';
export const DASHBOARD_NEWS_LATEST_SUCCESS = 'DASHBOARD_NEWS_LATEST_SUCCESS';
export const DASHBOARD_NEWS_LATEST_FAILURE = 'DASHBOARD_NEWS_LATEST_FAILURE';
export const DASHBOARD_NEWS_LATEST = [DASHBOARD_NEWS_LATEST_REQUEST, DASHBOARD_NEWS_LATEST_SUCCESS, DASHBOARD_NEWS_LATEST_FAILURE];

export const DASHBOARD_NEWS_DELETE_REQUEST = 'DASHBOARD_NEWS_DELETE_REQUEST';
export const DASHBOARD_NEWS_DELETE_SUCCESS = 'DASHBOARD_NEWS_DELETE_SUCCESS';
export const DASHBOARD_NEWS_DELETE_FAILURE = 'DASHBOARD_NEWS_DELETE_FAILURE';
export const DASHBOARD_NEWS_DELETE = [DASHBOARD_NEWS_DELETE_REQUEST, DASHBOARD_NEWS_DELETE_SUCCESS, DASHBOARD_NEWS_DELETE_FAILURE];

export const DASHBOARD_INSTANCE_LIST_REQUEST = 'DASHBOARD_INSTANCE_LIST_REQUEST';
export const DASHBOARD_INSTANCE_LIST_SUCCESS = 'DASHBOARD_INSTANCE_LIST_SUCCESS';
export const DASHBOARD_INSTANCE_LIST_FAILURE = 'DASHBOARD_INSTANCE_LIST_FAILURE';
export const DASHBOARD_INSTANCE_LIST = [DASHBOARD_INSTANCE_LIST_REQUEST, DASHBOARD_INSTANCE_LIST_SUCCESS, DASHBOARD_INSTANCE_LIST_FAILURE];

export const DASHBOARD_WELCOME_MESSAGE_HIDE_REQUEST = 'DASHBOARD_WELCOME_MESSAGE_HIDE_REQUEST';
export const DASHBOARD_WELCOME_MESSAGE_HIDE_SUCCESS = 'DASHBOARD_WELCOME_MESSAGE_HIDE_SUCCESS';
export const DASHBOARD_WELCOME_MESSAGE_HIDE_FAILURE = 'DASHBOARD_WELCOME_MESSAGE_HIDE_FAILURE';
export const DASHBOARD_WELCOME_MESSAGE_HIDE = [DASHBOARD_WELCOME_MESSAGE_HIDE_REQUEST, DASHBOARD_WELCOME_MESSAGE_HIDE_SUCCESS, DASHBOARD_WELCOME_MESSAGE_HIDE_FAILURE];
