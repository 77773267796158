import React from 'react';
import propTypes from 'prop-types';
import { fieldSourceToNatural } from 'client/redux/field/constants';
import { FormGroup } from 'react-bootstrap';
import FieldMapModal from 'client/components/FieldMap/FieldMapModal';
import _ from 'lodash';
import 'client/components/FieldMap/FieldMapFormComponent.css';

export default class FieldMapFormComponent extends React.Component {

  static propTypes = {
    base: propTypes.string.isRequired,
    target: propTypes.object,
    defaultSource: propTypes.object,
    endpointIds: propTypes.object.isRequired,
    availableSources: propTypes.array,
    disabled: propTypes.bool,
    showFilter: propTypes.string,
  }

  state = {
    changeModalOpen: false,
  }

  // the source used is the first locked one, or the last defined non-ignored one
  getSource() {
    const { required } = _.get(this.props, 'target.props', {});
    const { defaultSource, input: { value:userSource = {} } } = this.props;
    const defaultSourceIsLocked = _.get(defaultSource, 'props.locked');
    const hasDefaultSource = Boolean(defaultSource);
    if(defaultSourceIsLocked || (required && (!userSource || userSource.type === 'FieldSourceIgnore'))) {
      // use the default source when:
      // 1) the default source is locked
      // 2) the target is required and userSource is unset or ignored
      return {
        source: defaultSource,
        userSource: null,
        isDefaultSource: true,
        defaultSourceIsLocked,
        hasDefaultSource,
      };
    }
    const source = userSource || defaultSource;
    return {
      source,
      userSource: userSource || null,
      isDefaultSource: source === defaultSource,
      defaultSourceIsLocked,
      hasDefaultSource,
    };
  }

  getDefaultSources() {
    // we can't ignore a required target
    const required = _.get(this.props, 'target.props.required', false);
    if(required) return [{type: 'FieldSourceConstant'}];
    return [
      {type: 'FieldSourceIgnore'},
      {type: 'FieldSourceConstant'},
    ];
  }

  getAvailableSources() {
    const { availableSources } = this.props;
    const defaultSources = this.getDefaultSources();
    if(!_.isArray(availableSources)) return [...defaultSources];
    return [...defaultSources, ...availableSources];
  }

  getAvailableSourceTypes() {
    const result = this.getAvailableSources().map(({type}) => type);
    return result;
  }

  getSourcePropsByType() {
    const availableSources = this.getAvailableSources();
    return _.keyBy(availableSources, 'type');
  }

  handleChange = newSource => {
    const { input, disabled } = this.props;
    if(!disabled) return input.onChange(newSource);
  }

  handleClickChange = ev => {
    this.setState({changeModalOpen: true});
  }

  handleCloseChangeModal = ev => {
    this.setState({changeModalOpen: false});
  }

  renderChangeButton() {
    const { target } = this.props;
    if(!target) return null;
    const { defaultSourceIsLocked:disabled } = this.getSource();
    const icon = disabled ? <span className="icon mdi mdi-lock"></span> : <span className="icon mdi mdi-settings"></span>
      return (
        <div className="input-group-btn">
          <button
            type="button"
            className="btn btn-default"
            disabled={disabled}
            onClick={this.handleClickChange}
          >
            {icon}
          </button>
        </div>
      );
  }

  render() {
    const { changeModalOpen } = this.state;
    const { label, endpointIds, target, disabled, showFilter } = this.props;
    const { source, userSource, isDefaultSource, hasDefaultSource } = this.getSource();
    const required = _.get(target, 'props.required', false);
    if (showFilter === 'user_defined' && isDefaultSource) return null;
    if (showFilter === 'defined' && isDefaultSource && !hasDefaultSource) return null;
    return (
      <FormGroup className="row ln-field-map">
        <div className="col-lg-4">
          <label className="control-label align-left xs-mb-0 xs-mt-10 md-mt-0" title={label}>
            {label}
            {required ? <span className="text-danger">*</span> : ''}
          </label>
        </div>
        <div className="col-lg-8">
          <div className="input-group input-group-sm">
            <input
              readOnly={true}
              type="text"
              className="form-control"
              value={fieldSourceToNatural(source, required, isDefaultSource)}
            />
            {this.renderChangeButton()}
          </div> 
        </div>
        <FieldMapModal
          isOpen={changeModalOpen}
          source={userSource}
          target={target}
          targetLabel={label}
          disabled={disabled}
          onClose={this.handleCloseChangeModal}
          onSaveSource={this.handleChange}
          endpointIds={endpointIds}
          availableSourceTypes={this.getAvailableSourceTypes()}
          sourcePropsByType={this.getSourcePropsByType()}
        />
      </FormGroup>
    );
  }

}
