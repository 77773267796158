import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
  noticeObjectToNatural,
  noticeActionToNatural,
  countKeyToNatural,
} from 'client/redux/instance/log/constants';
import _ from 'lodash';

function ContextValue ({value}) {
  return <code>{String(value)}</code>
}

export default class InstanceLogDescription extends Component {

  static propTypes = {
    type: propTypes.string.isRequired,
    data: propTypes.object.isRequired,
    maxLineLength: propTypes.any,
  }

  errorMessageList(errorsByMessage) {
    return _.map(errorsByMessage, ({message, errorCount = 1}) => `Error: ${message} (${errorCount})`);
  }

  contextList (typeOfList, context = {}) {
    return Object.keys(context).map(key => (<>{typeOfList}: {key} = <ContextValue value={context[key]} /></>));
  }

  counter(key, value) {
    const naturalKey = countKeyToNatural(key);
    return `${naturalKey}: ${value || 0}`;
  }

  descriptionList() {
    const { data = {} } = this.props;
    if(data.message) return [data.message];
    return _.chain(data)
      .toPairs()
      .sortBy(pair => pair[0])
      .map(([keyName, actions]) => {
        if(_.isFinite(actions)) {
          return this.counter(keyName, actions);
        }

        if(!_.isPlainObject(actions)) {
          return null;
        }

        if (keyName === 'context') {
          return this.contextList('Context', actions);
        }

        if (keyName === 'state') {
          return this.contextList('State', actions);
        }

        if(keyName === 'errorsByMessage') {
          return this.errorMessageList(actions);
        }

        const naturalObject = noticeObjectToNatural(keyName);
        return _.chain(actions)
          .toPairs()
          .sortBy(pair => pair[0])
          .map(([action, count]) => {
            const naturalAction = noticeActionToNatural(action);
            return `${count} ${naturalObject} ${naturalAction}`;
          })
          .value();
      })
      .flatten()
      .compact()
      .value();
  }

  body() {
    const { data, maxLineLength } = this.props;
    if(Object.keys(data).length < 1) return '';
    const { NOTICES_COUNT:count, ERRORS_COUNT:errorCount } = data;
    if(count < 1 && errorCount < 1) return 'No actions taken';
    return this.descriptionList().map((text, i) => {
      const textShort = typeof text === 'string' && text.length > maxLineLength ? text.slice(0, maxLineLength) + '…' : text;
      return (
        <span key={i}>{textShort}<br /></span>
      );
    });
  }

  render() {
    return (
      <span style={{textDecoration: 'none'}}>{this.body()}</span>
    )
  }

}
