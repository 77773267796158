import React from 'react';
import propTypes from 'prop-types';
import LogLevelLabel from 'client/components/Helpers/LogLevelLabel';
import InstanceLogDescription from 'client/components/InstanceLog/components/InstanceLogDescription'
import Icon from 'client/components/Be/Icon';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import LinkQuery from 'client/components/Helpers/LinkQuery';
import Time from 'client/components/Helpers/Time';

export default class InstanceEventTable extends React.Component {

  static propTypes = {
    logs: propTypes.array.isRequired,
  }

  renderNoRows() {
    return (
      <tr>
        <td colSpan="4">Nothing here now</td>
      </tr>
    );
  }

  renderRows() {
    const { logs } = this.props;
    if(!logs.length) return this.renderNoRows();
    return logs.map(log => {
      return (
        <tr key={log.id}>
          <td className="text-nowrap"><LogLevelLabel level={log.level} /></td>
          <td className="text-nowrap"><Time time={log.time} format="YYYY-MM-DD HH:mm" /></td>
          <td><InstanceLogDescription {...log} maxLineLength={100} /></td>
          <td className="text-nowrap text-right">
            {log.level === 'job' && (
              <IsAdmin>
                <LinkQuery
                  className="btn btn-default xs-mr-5"
                  to="/instance/log/fragment"
                  query={{connectorInstanceLogId: log.id}}
                  title="Search for log fragments"
                >
                  <Icon icon="search-for" />
                </LinkQuery>
              </IsAdmin>
            )}
            <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: log, title: log.time, inspector: 'object'}}>
              <i className="icon mdi mdi-search"></i>
            </ModalButton>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped xs-mb-0">
          <thead>
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th>Description</th>
              <th className="actions"></th>
            </tr>
          </thead>
          <tbody className="no-border-x">
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }

}
