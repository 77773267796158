import * as c from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import { LOCATION_CHANGE } from 'connected-react-router';
import _ from 'lodash';

// when action is one of these types, reduceSubscription should be the main reducer
// all of them should have the meta property subscriptionId
const subscriptionTypes = [
  ...c.ACCOUNT_SUBSCRIPTION_CREATE,
  ...c.ACCOUNT_SUBSCRIPTION_UPDATE,
  ...c.ACCOUNT_SUBSCRIPTION_DELETE,
  ...c.ACCOUNT_SUBSCRIPTION_PREVIEW,
  ...c.ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT,
  c.ACCOUNT_SUBSCRIPTION_CHANGE_PLAN_QUANTITY,
  c.ACCOUNT_SUBSCRIPTION_REMOVE_PLAN,
  c.ACCOUNT_SUBSCRIPTION_ADD_PLAN,
  c.ACCOUNT_SUBSCRIPTION_UNDO,
  c.ACCOUNT_SUBSCRIPTION_NEW,
  c.ACCOUNT_SUBSCRIPTION_TOGGLE_FIX_PROBLEM_MODAL,
];

// when action is one of these types, reduceSubscriptionItem should be the main reducer
// all of them should have the property planId
const subscriptionItemTypes = [
  c.ACCOUNT_SUBSCRIPTION_CHANGE_PLAN_QUANTITY,
  c.ACCOUNT_SUBSCRIPTION_REMOVE_PLAN,
  c.ACCOUNT_SUBSCRIPTION_ADD_PLAN,
];

const defaultSubscriptionItemState = {
  id: undefined,
  plan: null,
  quantity: 1,
  deleted: false,
};

export function reduceSubscriptionItem(state = defaultSubscriptionItemState, action) {
  const meta = action.meta;
  switch(action.type) {
    default: break;
    case c.ACCOUNT_SUBSCRIPTION_REMOVE_PLAN: return {
      ...state,
      deleted: meta.deleted,
    };
    case c.ACCOUNT_SUBSCRIPTION_CHANGE_PLAN_QUANTITY: return {
      ...state,
      quantity: meta.quantity,
    };
  }
  return state;
};

export function reduceSubscriptionItemContainer(state = [], action) {
  const { planId } = action.meta;
  let index = state.findIndex(({plan}) => plan === planId);
  if(index === -1) index = state.length;

  if(action.type === c.ACCOUNT_SUBSCRIPTION_ADD_PLAN) {
    return [
      ...state,
      {
        ...defaultSubscriptionItemState,
        plan: planId,
        subscriptionItem: [],
      },
    ];
  }

  state[index] = reduceSubscriptionItem(state[index], action);
  return [...state];
};

const defaultSubscriptionState = {
  subscriptionId: null,
  isNew: false,
  isChanged: false,
  preview: null,
  error: null,
  originalItems: [],
  items: [],
  paymentIntent: null,
  fixProblemModalOpen: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
  isPreviewing: false,
  isReadingPaymentIntent: false,
};

export function reduceSubscription(originalState = defaultSubscriptionState, action) {
  let state = {...originalState};

  state = reduceByTypes(c.ACCOUNT_SUBSCRIPTION_CREATE, state, action, {
    requestProp: 'isCreating',
    successProp: (state, action, response) => ({...state, ...remoteToStateSubscription(response.subscription), ..._.pick(response, 'paymentIntent')}),
  });
  state = reduceByTypes(c.ACCOUNT_SUBSCRIPTION_UPDATE, state, action, {
    requestProp: 'isUpdating',
    successProp: (state, action, response) => ({...state, ...remoteToStateSubscription(response.subscription), ..._.pick(response, 'paymentIntent')}),
  });
  state = reduceByTypes(c.ACCOUNT_SUBSCRIPTION_DELETE, state, action, {
    requestProp: 'isDeleting',
    successProp: (state, action, response) => ({...state, ...remoteToStateSubscription(response.subscription)}),
  });
  state = reduceByTypes(c.ACCOUNT_SUBSCRIPTION_PREVIEW, state, action, {
    requestProp: 'isPreviewing',
    successPickProps: ['preview'],
  });
  state = reduceByTypes(c.ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT, state, action, {
    requestProp: 'isReadingPaymentIntent',
    successPickProps: ['paymentIntent'],
  });

  switch(action.type) {
    default: break;
    case LOCATION_CHANGE:
      state.isNew = false;
      break;
    case c.ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS:
      // we change the id for this subscription from "new" to what was created
      state.subscriptionId = action.payload.subscription.id;
      state.isNew = false;
      state.originalItems = _.cloneDeep(state.items);

      // open the fix problem modal immediately after creation if we managed
      // to get a paymentIntent and sub status incomplete from the server
      if(state.meta && state.meta.status === 'incomplete' && state.paymentIntent) {
        state.fixProblemModalOpen = true;
      }

      break;
    case c.ACCOUNT_SUBSCRIPTION_UNDO:
      state.items = _.cloneDeep(state.originalItems).filter(({isNew}) => !isNew);
      break;

    case c.ACCOUNT_SUBSCRIPTION_TOGGLE_FIX_PROBLEM_MODAL: return {
      ...state,
      fixProblemModalOpen: action.open,
    };

  }

  if(subscriptionItemTypes.includes(action.type)) {
    state.items = reduceSubscriptionItemContainer(state.items, action);
  }

  switch(action.type) {
    default: break;
      // these two actions causes the originalItems to be reset
    case c.ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS:
    case c.ACCOUNT_SUBSCRIPTION_UPDATE_SUCCESS:
      state.originalItems = _.cloneDeep(state.items);
      break;
  }

  state.isChanged = !_.isEqual(state.originalItems, state.items);
  return state;
};

function getSubscriptionIndexByAction(subscriptions, action) {
  const { subscriptionId } = _.get(action, 'meta', {});
  return subscriptions.findIndex(({subscriptionId:id}) => id === subscriptionId);
}

export function reduceSubscriptionContainer(state = [], action) {
  switch(action.type) {
    default: break;
    case c.ACCOUNT_SUBSCRIPTION_NEW: return [
      {...defaultSubscriptionState, isNew: true},
      ...state,
    ];
  }
  let index = getSubscriptionIndexByAction(state, action);
  if(index === -1) return state;;
  state[index] = reduceSubscription(state[index], action);
  return [...state];
}

const defaultState = {
  error: null,
  paymentContext: undefined,
  subscriptions: [],
  subscribablePlans: [],
};

export default function subscriptionReducer(state = defaultState, action) {
  state = reduceByTypes(c.ACCOUNT_SUBSCRIPTION_INDEX, state, action, {
    requestProp: 'isReading',
    successProp: (state, action, response) => {
      const {subscriptions, subscribablePlans, allPlansByStripeId, paymentContext} = response;
      return {
        allPlansByStripeId,
        subscribablePlans,
        paymentContext,
        subscriptions: subscriptions.map(remoteToStateSubscription),
      };
    },
  });

  // determine if we should pass to reduceSubscription
  if(subscriptionTypes.includes(action.type)) {
    const mutated = {...state};
    switch(action.type) {
      default: break;
      case c.ACCOUNT_SUBSCRIPTION_UNDO: {
        // when undoing a new subscription, remove it from items completely
        const index = getSubscriptionIndexByAction(mutated.subscriptions, action);
        if(_.get(mutated.subscriptions, [index, 'isNew'])) {
          return {
            ...mutated,
            subscriptions: _.without(mutated.subscriptions, mutated.subscriptions[index]),
          };
        }
      }
    }
    return {
      ...mutated,
      subscriptions: reduceSubscriptionContainer(mutated.subscriptions, action),
    };
  }

  return state;
};

// converts a remote subscription object to something fitting this object
function remoteToStateSubscription(remoteSubscription) {
  const { id, status, stripeObject = {} } = remoteSubscription;
  const {
    current_period_end:currentPeriodEnd,
    created,
    canceled_at:canceledAt,
    ended_at: endedAt,
    cancel_at_period_end: cancelAtPeriodEnd,
    billing_cycle_anchor: billingCycleAnchor,
  } = stripeObject;
  const items = remoteToStateSubscriptionItems(remoteSubscription)
  const result = {
    ...defaultSubscriptionState,
    subscriptionId: id,
    meta: {status, created, currentPeriodEnd, canceledAt, cancelAtPeriodEnd, billingCycleAnchor, endedAt},
    items,
    originalItems: _.cloneDeep(items),
  };
  return result;
}

// converts a remote subscription object to a list of items
function remoteToStateSubscriptionItems(remoteSubscription) {
  const items = _.get(remoteSubscription, 'stripeObject.items.data', []);
  return items.map(item => ({
    ...defaultSubscriptionItemState,
    id: item.id,
    plan: _.isString(item.plan) ? item.plan : _.get(item, 'plan.id'),
    quantity: item.quantity,
    deleted: false,
  }));
}
