import _ from 'lodash';

/**
 * @description Takes the global state and determines if the current user has a role
 * @param {Object} globalState
 * @param {String} role
 * @return {Boolean}
 */
export const hasRole = (globalState, role) => {
  return _.get(globalState, 'ui.self.role', null) === role;
};

export const isAdmin = globalState => {
  return hasRole(globalState, 'admins');
};

export const isPartner = globalState => {
  return hasRole(globalState, 'partners');
};

export const isUser = globalState => {
  return hasRole(globalState, 'users');
};
