import { ENDPOINT_GRAPH_READ, ENDPOINT_GRAPH_PRELOADED, ENDPOINT_GRAPH_TEST_CONSENT } from 'client/redux/endpoint/graph/constants';
import * as actionCreators from 'client/redux/endpoint/graph/actionCreators';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointGraphPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(ENDPOINT_GRAPH_PRELOADED, endpoint));
};

export const endpointGraphRead = endpointId => dispatch => {
  return dispatch(actionCreators.endpointGraphRead(ENDPOINT_GRAPH_READ, endpointId));
};

export const endpointGraphTestConsent = endpointId => dispatch => {
  return dispatch(actionCreators.endpointGraphTestConsent(ENDPOINT_GRAPH_TEST_CONSENT, endpointId));
};
