import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { isPublishedSelect2 } from 'client/redux/news/constants';
import { FormReduxSelect2, FormReduxInput, FormReduxTextarea } from 'client/components/Form/FormRedux';
import { isFilled, isString } from 'client/redux/validators';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';

const validateFilledString = [isString(), isFilled()];

class NewsPanelForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired
  }

  fieldProps = {base: 'NewsPanelForm', metaOptions: {defaultState: null, ignorePristine: true}}

  render() {
    const { handleSubmit, onSubmit, submitting, invalid, error } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <Field
          {...this.fieldProps}
          type="text"
          name="title"
          component={FormReduxInput}
          validate={validateFilledString}
          label="Title"
        />
        <Field
          {...this.fieldProps}
          type="text"
          name="markdown"
          component={FormReduxTextarea}
          validate={validateFilledString}
          label="Content"
        />
        <Field
          {...this.fieldProps}
          name="isPublished"
          component={FormReduxSelect2}
          label="Published"
          data={isPublishedSelect2}
          select2ComponentProps={{unwrapValue: true}}
        />
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'newsPanel',
  enableReinitialize: true
})(NewsPanelForm);
