import React, { Component } from 'react';
import App from 'client/components/App/App';
import RouteRole from 'client/components/Route/RouteRole';
import { LeftSidebar, Navbar } from 'client/components/App';
import GritterContainer from 'client/components/Gritter/Gritter';
import { Switch, Route, Redirect } from 'react-router-dom';
import { clearBodyClass } from 'client/redux/bodyClass';
import pages from 'client/components/pages';

export default class RouterAuthed extends Component {

  render() {
    clearBodyClass();
    return (
      <App className="be-fixed-sidebar">
        <Navbar />
        <LeftSidebar />
        <Switch>
          <Route exact path="/" component={pages.Dashboard} />
          <Route exact path="/marketplace" component={pages.Marketplace} />
          <Route exact path="/support" component={pages.Support} />
          <Route exact path="/account/invoice/:invoiceId" component={pages.AccountInvoiceRead} />
          <Route exact path="/account/billing/:tab?" component={pages.AccountBilling} />
          <Route exact path="/account/subscriptions" component={pages.AccountSubscription} />
          <Route exact path="/account/children" component={pages.AccountChildren} />
          <Route exact path="/account/settings" component={pages.AccountSettings} />
          <RouteRole anyRole={["admins"]} exact path="/connector" component={pages.ConnectorIndex} />
          <RouteRole anyRole={["admins"]} exact path="/endpoint/log" component={pages.EndpointLogIndex} />
          <RouteRole anyRole={["admins"]} exact path="/endpoint" component={pages.EndpointIndex} />
          <RouteRole anyRole={["admins"]} exact path="/user/log" component={pages.UserLogIndex} />
          <RouteRole anyRole={["admins"]} exact path="/user/subscription" component={pages.UserSubscriptionIndex} />
          <RouteRole anyRole={["admins"]} exact path="/user/invoice" component={pages.UserInvoiceIndex} />
          <RouteRole anyRole={["admins"]} exact path="/user/invoice/:invoiceId" component={pages.UserInvoiceRead} />
          <RouteRole anyRole={["admins"]} exact path="/user" component={pages.UserIndex} />
          <RouteRole anyRole={["admins"]} exact path="/instance/log/fragment" component={pages.InstanceLogFragmentIndex} />
          <RouteRole anyRole={["admins"]} exact path="/instance/log" component={pages.InstanceLogIndex} />
          <RouteRole anyRole={["admins"]} exact path="/instance" component={pages.InstanceIndex} />
          <RouteRole anyRole={["admins"]} exact path="/plan" component={pages.PlanIndex} />
          <RouteRole anyRole={["admins"]} exact path="/settings" component={pages.SettingsIndex} />
          <RouteRole anyRole={["admins"]} exact path="/uiFragments" component={pages.UIFragmentIndex} />
          <Route exact path="/instance/:connectorId/plan" component={pages.ConnectorSelectPlan} />
          <Route exact path="/instance/:instanceType/:instanceId/:tab?" component={pages.InstanceView} />
          <Redirect to="/404" />
        </Switch>
        <GritterContainer />
      </App>
    );
  }

}
