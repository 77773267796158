import { apiAction } from 'client/redux/apiHelpers';

export const endpointFilesRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/files/${id}`,
    method: 'GET',
    types
  });
};

export const endpointFilesReadDavInfo = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/files/${id}/davInfo`,
    method: 'GET',
    types
  });
};
