import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm } from 'redux-form';
import { isNotEmpty, isString, isURL } from 'client/redux/validators';
import { FormErrors } from 'client/components/Form/FormErrors';
import EndpointTestButton from 'client/components/Endpoint/components/EndpointTestButton';

const validateByField = {
  apiUrl: [isString(), isNotEmpty(), isURL()],
  apiKey: [isString(), isNotEmpty()],
};

class EndpointOpmanagerForm extends Component {

  static defaultProps = {
    isEditing: false,
    onEdit: function() {}
  }

  static propTypes = {
    isEditing: propTypes.bool.isRequired,
    onEdit: propTypes.func,
    remoteError: propTypes.object,
    initialValues: propTypes.object.isRequired,
    endpointState: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onTest: propTypes.func.isRequired,
    isTesting: propTypes.bool.isRequired,
    isUpdating: propTypes.bool.isRequired
  }

  hasInitialValues() {
    const { initialValues } = this.props;
    return Boolean(initialValues.apiUrl && initialValues.apiKey);
  }

  isDisabled() {
    return this.hasInitialValues() && !this.props.isEditing;
  }

  fieldProps = {base: 'endpointOpmanagerForm', metaOptions: {defaultState: null, ignorePristine: true}}

  render() {
    const { isTesting, remoteError } = this.props;
    const { testOk, testTimestamp } = this.props.endpointState;
    const isDisabled = this.isDisabled();
    return (
			<Form className="group-border-dashed" onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <FormErrors className="xs-mt-0 xs-mb-10" error={remoteError} />
        <div>
          <Field
            {...this.fieldProps}
            name="apiUrl"
            component={FormReduxInput}
            type="text"
            label="OpManager API URL"
            disabled={isDisabled}
            validate={validateByField.apiUrl}
            helpText="Enter a https URL to the API ending with exactly '/api/json'"
          />
        </div>
        <div>
          <Field
            {...this.fieldProps}
            name="apiKey"
            component={FormReduxInput}
            type="text"
            label="OpManager API Key"
            disabled={isDisabled}
            validate={validateByField.apiKey}
          />
        </div>
        <div className="form-group xs-mb-0">
          <EndpointTestButton
            isTesting={isTesting}
            isDisabled={!isDisabled}
            onTest={this.props.onTest}
            testOk={testOk}
            testTimestamp={testTimestamp}
          />{' '}
          {this.renderSubmitButton()}
          <div className="clearfix"></div>
        </div>
      </Form>
    );
  }

  renderSubmitButton() {
    const { isUpdating, valid, onEdit } = this.props;
    if(this.isDisabled()) {
      return <button className="btn btn-default pull-right" type="button" onClick={onEdit}>Edit credentials</button>;
    } else {
      return (
        <ButtonWaiting type="submit" disabled={!valid} className="btn btn-default pull-right" isWaiting={isUpdating}>
          Save credentials
        </ButtonWaiting>
      );
    }
  }

}

export default reduxForm({form: 'endpointOpmanager', enableReinitialize: true})(EndpointOpmanagerForm);
