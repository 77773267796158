import * as actionCreators from 'client/redux/support/actionCreators';
import { SUPPORT_QUESTIONS, SUPPORT_QUESTIONS_FILTER, SUPPORT_CATEGORY_CREATE, SUPPORT_CATEGORY_UPDATE, SUPPORT_CATEGORY_DELETE, SUPPORT_QUESTION_CREATE, SUPPORT_QUESTION_UPDATE, SUPPORT_QUESTION_DELETE } from 'client/redux/support/constants';

export const supportQuestions = () => dispatch => {
  return dispatch(actionCreators.supportQuestions(SUPPORT_QUESTIONS));
};

export const supportQuestionsFilter = (filter = {}) => dispatch => {
  return dispatch(actionCreators.supportQuestionsFilter(SUPPORT_QUESTIONS_FILTER, filter));
};

export const supportCategoryCreate = () => dispatch => {
  return dispatch(actionCreators.supportCategoryCreate(SUPPORT_CATEGORY_CREATE));
};

export const supportCategoryUpdate = (id, form) => dispatch => {
  return dispatch(actionCreators.supportCategoryUpdate(SUPPORT_CATEGORY_UPDATE, id, form));
};

export const supportCategoryDelete = (id) => dispatch => {
  return dispatch(actionCreators.supportCategoryDelete(SUPPORT_CATEGORY_DELETE, id));
};

export const supportQuestionCreate = (categoryId) => dispatch => {
  return dispatch(actionCreators.supportQuestionCreate(SUPPORT_QUESTION_CREATE, categoryId));
};

export const supportQuestionUpdate = (id, form) => dispatch => {
  return dispatch(actionCreators.supportQuestionUpdate(SUPPORT_QUESTION_UPDATE, id, form));
};

export const supportQuestionDelete = (id) => dispatch => {
  return dispatch(actionCreators.supportQuestionDelete(SUPPORT_QUESTION_DELETE, id));
};
