import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import classNames from 'classnames';

class MainContent extends Component {

  state = {
    hasError: false,
    error: null
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true, error});
  }

  getClasses() {
    const { className } = this.props;
    return classNames('main-content', 'container-fluid', className);
  }

  renderError(error) {
    return (
      <div className={this.getClasses()}>
        <BeRemoteErrorAlert error={error} />
      </div>
    );
  }

  render() {
    const { hasError, error } = this.state;
    if(hasError && error) return this.renderError(error);
    return (
      <div className={this.getClasses()}>
        {this.props.children}
      </div>
    );
  }
}

export default MainContent;
