import React  from 'react';
import propTypes from 'prop-types';
import { FormReduxSelect2, FormReduxInput } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import { isFilled, isString, isUUID } from 'client/redux/validators';
import * as optionsActions from 'client/redux/swtd/optionsActions';
import _ from 'lodash';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import FieldMapFormComponent from 'client/components/FieldMap/FieldMapFormComponent';
import { targetToLabel } from 'client/redux/field/constants';
import BeLoading from 'client/components/Be/BeLoading';
import { escapeDottedName } from 'client/redux/formHelpers';
import Sticky from 'react-stickynode';

const validators = {
  isString: [isString()],
  isNonEmptyString: [isString(), isFilled()],
  isFilledUUID: [isString(), isFilled(), isUUID()],
  isUUID: [isString(), isUUID()]
};
const snowComputerFields = [
  'id',
  'scanIdentifier',
  'hostName',
  'domain',
  'lastScanDate',
  'biosSerialNumber',
  'biosVersion',
  'biosDate',
  'manufacturer',
  'manufacturerWebsite',
  'model',
  'isPortable',
  'infoTransferDate',
  'clientInstallDate',
  'clientVersion',
  'clientSiteName',
  'clientConfigurationName',
  'scannerVersion',
  'quarantineDate',
  'isQuarantineManagementDisabled',
  'operatingSystem',
  'operatingSystemId',
  'operatingSystemServicePack',
  'operatingSystemSerialNumber',
  'ipAddress',
  'isVirtual',
  'hostComputerId',
  'siblingComputerId',
  'isUpdated',
  'is64bit',
  'isServer',
  'hypervisorName',
  'isVdi',
  'processorValueUnit',
  'reportingDate',
  'architecture',
  'organizationId',
  'status',
  'isAutoEditingDisabled',
  'purchaseDate',
  'purchaseValue',
  'purchaseCurrency',
  'purchaseValueBase',
  'invoiceReference',
  'notes',
  'securityCode',
  'updatedDate',
  'isIncludedInWindowsServerRequirement',
  'mostRecentUser',
  'mostFrequentUser',
  'vendor',
];

class ConnectedSWTDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    const assetTemplateId = _.get(this.props, 'initialValues.topdeskComputerAssetTemplateId');
    return Promise.all([
      this.getAssetFieldTargets(assetTemplateId),
      this.props.instanceSwtdOptionsReadMeta(instanceId),
    ]);
  }

  handleReset = ev => {
    this.props.reset();
  }

  render() {
    const { dirty, disabled, invalid, isSetupMode, onSubmit, onNudgeStep, isUpdating } = this.props;
		return (
      <Form id="instanceSwtdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceSwtdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20">
          <div className="row">
            <div className="col-md-6">
              {this.renderOtherSettings()}
            </div>
            <div className="col-md-6">
              {this.renderAssetSettings()}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            </div>
            <div className="col-md-6">
              {this.renderAssetFieldMap()}
            </div>
          </div>
        </div>
      </Form>
		);
  }

  getAssetFieldTargets = assetTemplateId => {
    if(assetTemplateId) {
      const { endpointsByType: { topdesk: { id } } } = this.props;
      return this.props.instanceSwtdOptionsAssetFieldTargetsRead(id, assetTemplateId);
    }
    return this.props.instanceSwtdOptionsAssetFieldTargetsReset();
  }

  handleDeviceAssetTemplateIdChange = (ev, value) => {
    return this.getAssetFieldTargets(value);
  }

  renderAssetSettings() {
    const {
      disabled,
      endpointsByType: { topdesk },
      initialValues: {
        topdeskComputerAssetTemplateId,
        topdeskComputerAssetKeyField,
        topdeskComputerAssetLockAssignmentsField,
      }
    } = this.props;
    return (
      <div className="well xs-pb-0">
        <InstanceHelpHeader
          subTitle="Options for how TOPdesk Assets are created/updated by Snow Computers"
          helpFragmentId="instance_swtd_settings_assets_help"
        >
          TOPdesk Asset Settings
        </InstanceHelpHeader>
        <div>
          <Field
            base="swtdOptionsDeviceAssetTemplateId"
            name="topdeskComputerAssetTemplateId"
            label="Asset Template Name for Snow Computers"
            component={FormReduxSelect2}
            select2ComponentProps={{endpointId: topdesk.id, forWhat: 'assetTemplateId'}}
            select2Component={EndpointTopdeskRemoteSearcher}
            select2Options={{minimumInputLength: 1, minimumResultsForSearch: 0}}
            disabled={disabled}
            defaultValue={topdeskComputerAssetTemplateId}
            validate={validators.isNonEmptyString}
            onChange={this.handleDeviceAssetTemplateIdChange}
            required
          />
          <Field
            base="swtdOptionsDeviceAssetKeyField"
            component={FormReduxInput}
            label="Asset Template Key Field"
            name="topdeskComputerAssetKeyField"
            value={topdeskComputerAssetKeyField || ''}
            disabled={disabled}
            validate={validators.isNonEmptyString}
            helpText="Example: unid"
            required
          />
          <Field
            base="swtdOptionstopdeskComputerAssetLockAssignmentsField"
            component={FormReduxInput}
            label="Asset Template Lock Assignments Field"
            name="topdeskComputerAssetLockAssignmentsField"
            value={topdeskComputerAssetLockAssignmentsField || ''}
            disabled={disabled}
            validate={validators.isString}
            helpText="Field ID of a boolean field on the computer asset that causes assignments to not be updated when true"
            required
          />
        </div>
      </div>
    );
  }

  renderOtherSettings () {
    const {
      disabled,
      initialValues: { snowComputerKeyField }
    } = this.props;
    return (
      <div className="well xs-pb-0">
        <InstanceHelpHeader subTitle="Options related to Snow Computers">
          Snow Computer Settings
        </InstanceHelpHeader>
        <Field
          base="swtdOptionsSnowComputerKeyField"
          name="snowComputerKeyField"
          label="Snow Computer Key Field"
          helpText="Determines the Snow Computer field that is used as key"
          component={FormReduxSelect2}
          data={snowComputerFields.map(field => ({id: field, text: field}))}
          defaultValue={snowComputerKeyField || ''}
          required
          disabled={disabled}
        />
      </div>
    );
  }

  getAvailableSources () {
    // these are the fields available in an Snow Computer
    return [
      {type: 'FieldSourceObject', columns: snowComputerFields },
    ];
  }

  getDefaultSource(fieldName) {
    switch(fieldName) {
      default: return {type: 'FieldSourceIgnore'};
    }
  }

  getAssetTemplateId() {
    const initialName = _.get(this.props, 'initialValues.topdeskComputerAssetTemplateId');
    const currentName = _.get(this.props, 'currentFormValues.topdeskComputerAssetTemplateId');
    return !_.isUndefined(currentName) ? currentName : initialName;
  }

  renderAssetFieldMap() {
    const {
      isReadingFieldTargets,
      errorFieldTargets,
    } = this.props;

    if(!this.getAssetTemplateId()) {
      return null;
    }

    const assetTemplateName = _.get(this.props, 'assetFieldTargets.assetTemplateName');

    return (
      <div className="well form-horizontal xs-mb-10">
        <InstanceHelpHeader
          subTitle="Mapping from a Snow Computer to a TOPdesk Asset"
          helpFragmentId="instance_swtd_settings_asset_fieldmap_help"
        >
          {assetTemplateName}
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorFieldTargets} />
        <BeLoading loading={isReadingFieldTargets} className="xs-mt-20">
          {this.renderAssetFieldMapTargets()}
        </BeLoading>
      </div>
    );
  }

  renderAssetFieldMapTargets() {
    const {
      endpointsByType: { topdesk: { id:topdeskEndpointId }},
      disabled,
    } = this.props;

    const fieldTargets = _.get(this.props, 'assetFieldTargets.fieldTargets', []);
    if(!fieldTargets || !fieldTargets.length) {
      return <p className="xs-m-0">There is nothing here yet.</p>;
    }

    return fieldTargets.map(target => {
      const { name } = target;
      return (
        <Field
          key={`topdeskComputerFieldMap_${name}`}
          name={`topdeskComputerFieldMap.${escapeDottedName(name)}`}
          base={`swtdOptionsTopdeskComputerFieldMap${name}`}
          component={FieldMapFormComponent}
          label={targetToLabel(target)}
          disabled={disabled}
          target={target}
          defaultSource={this.getDefaultSource(name)}
          availableSources={this.getAvailableSources()}
          endpointIds={{topdeskId: topdeskEndpointId}}
        />
      );
    });
  }

}

function mapStateToProps(state) {
  const selector = formValueSelector('swtdOptions');
  // the value of these fields need to be available when rendering
  return {
    ...state.swtd.options,
    currentFormValues: {
      topdeskComputerAssetTemplateId: selector(state, 'topdeskComputerAssetTemplateId'),
    },
  };
}

const actions = {
  ...optionsActions,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'swtdOptions',
  enableReinitialize: true,
})(ConnectedSWTDOptionsForm));
