import { REHYDRATE } from 'redux-persist/constants';
import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  AUTH_SWITCH_USER_REQUEST,
  AUTH_SWITCH_USER_SUCCESS,
  AUTH_SWITCH_USER_FAILURE,
  AUTH_SWITCH_USER_RETURN_REQUEST,
  AUTH_SWITCH_USER_RETURN_SUCCESS,
  AUTH_SWITCH_USER_RETURN_FAILURE,
} from 'client/redux/auth/constants';
import { SIGNUP_SUCCESS } from 'client/redux/signup/constants';

const defaultState = {
  isWaiting: false,
  isLoggedIn: false,
  isRehydrated: false,
  self: null,
  previousSelf: null,
  error: null
};

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case REHYDRATE: return {
      ...state,
      ...action.payload.auth,
      isRehydrated: true
    };
    case AUTH_SWITCH_USER_RETURN_REQUEST: return {
      ...state,
      isWaiting: true
    };
    case AUTH_SWITCH_USER_RETURN_SUCCESS: return {
      ...state,
      ...action.payload,
      previousSelf: null,
      isWaiting: false
    };
    case AUTH_SWITCH_USER_RETURN_FAILURE: return {
      ...state,
      isWaiting: false,
      error: action.payload
    };
    case AUTH_SWITCH_USER_REQUEST: return {
      ...state,
      isWaiting: true
    };
    case AUTH_SWITCH_USER_SUCCESS: return {
      ...state,
      ...action.payload,
      isWaiting: false
    };
    case AUTH_SWITCH_USER_FAILURE: return {
      ...state,
      error: action.payload,
      isWaiting: false
    };
    case SIGNUP_SUCCESS: return {
      ...state,
      ...action.payload,
    };
    case LOGIN_SUCCESS: return {
      ...state,
      ...action.payload,
      isLoggedIn: true
    };
    case LOGOUT_REQUEST: return {
      ...state,
      isWaiting: true
    };
    case LOGOUT_FAILURE: return {
      ...state,
      isWaiting: false,
      error: action.error
    };
    case LOGOUT_SUCCESS: return {
      ...state,
      isWaiting: false,
      isLoggedIn: false,
      self: null,
      previousSelf: null
    };
    default:
      return state
  }
};
