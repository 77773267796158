import { combineReducers } from 'redux';

import test from 'client/redux/fatd/testReducer';
import statistics from 'client/redux/fatd/statisticsReducer';
import options from 'client/redux/fatd/optionsReducer';

const fatdReducer = combineReducers({
  test,
  statistics,
  options,
});

export default fatdReducer;
