import * as constants from 'client/redux/qrtd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  tab: 'assets',
  isReadingAssets: false,
  isReadingLocations: false,
  apiError: null,
  assets: [],
  locations: [],
  templates: [],
  selectedAssetsById: {},
  selectedLocationsById: {},
  assetFilter: {searchTerm: '', nameFragment: '', templateName: '', branchId: '', limit: 10},
  locationFilter: {name: ''},
};

export default function qrCodeCreatorReducer(state = defaultState, action) {

  state = reduceByTypes(constants.INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS, state, action, {
    requestProp: 'isReadingAssets',
    successPickProps: ['assets', 'templates'],
    errorProp: 'apiError',
  });

  state = reduceByTypes(constants.INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS, state, action, {
    requestProp: 'isReadingLocations',
    successPickProps: ['locations'],
    errorProp: 'apiError',
  });

  switch(action.type) {
    default: return state;
    case constants.INSTANCE_QRTD_QRCODECREATOR_SET_TAB: return {
      ...state,
      tab: action.tab,
    };
    case constants.INSTANCE_QRTD_QRCODECREATOR_LOCATION_FILTER: return {
      ...state,
      locationFilter: action.filter,
    };
    case constants.INSTANCE_QRTD_QRCODECREATOR_LOCATION_SET_SELECTED_IDS: return {
      ...state,
      selectedLocationsById: action.ids,
    };
    case constants.INSTANCE_QRTD_QRCODECREATOR_ASSET_FILTER: return {
      ...state,
      assetFilter: action.filter,
    };
    case constants.INSTANCE_QRTD_QRCODECREATOR_ASSET_SET_SELECTED_IDS: return {
      ...state,
      selectedAssetsById: action.ids,
    };
  }
};
