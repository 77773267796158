import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';
import ObjectRow from 'client/components/Helpers/ObjectRow';
import { IsAdmin } from 'client/components/Helpers/IsRole'
import { omit } from 'lodash';

const ObjectGraph = ({objectGraph}) => {
  return (
    <>
      <ObjectRow subject="Computer and related objects" object={objectGraph} />
    </>
  );
};

export default class SWTDTesterComputer extends React.Component {

  static propTypes = {
    id: propTypes.string,
    computer: propTypes.object,
    onRemove: propTypes.func,
    onSync: propTypes.func,
    onTestObjectGraph: propTypes.func,
  }

  handleClickSync = ev => {
    this.props.onSync(this.props.id);
  }

  handleClickRemove = ev => {
    this.props.onRemove(this.props.id);
  }

  handleClickTestObjectGraph = ev => {
    this.props.onTestObjectGraph(this.props.id);
  }

  renderNoticeTable() {
    const { computer } = this.props;
    const { snowComputerObject, objectGraph, notices } = computer;
    if(!snowComputerObject) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {snowComputerObject && <ObjectRow subject="Snow Computer" object={snowComputerObject} />}
              {objectGraph && <ObjectGraph objectGraph={omit(objectGraph, 'ok', 'code')} />}

              {notices && (
                <>
                  {notices.map((notice, index) => (
                    <NoticeRow
                      key={index}
                      notice={notice}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { computer } = this.props;
    if(!computer) return null;
    const { error, snowComputerObject, isGettingObjectGraph } = computer;
    if (!snowComputerObject) return null;
    const name = `ID: ${snowComputerObject.id} - ${snowComputerObject.hostName} ${snowComputerObject.biosSerialNumber}`;
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-devices xs-mr-5"></i>
            {name}
          </div>
          <span className="btn-group pull-right">
            <IsAdmin>
              <ButtonWaiting type="button" onClick={this.handleClickTestObjectGraph} isWaiting={isGettingObjectGraph} className="btn btn-rounded btn-default">
                <i className="icon icon-left mdi mdi-flash"></i>{' '}
                Object Graph
              </ButtonWaiting>
            </IsAdmin>
            <ButtonWaiting type="button" onClick={this.handleClickSync} isWaiting={computer.isSyncing} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>
              Sync
            </ButtonWaiting>
            <button onClick={this.handleClickRemove} type="button" className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-delete"></i>
              Remove from test
            </button>
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} />
        {this.renderNoticeTable()}
      </div>
    );
  }

};
