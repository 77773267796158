import React from 'react';
import { EndpointFastRemoteSearcher, EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';

export default class FATDITesterAddObject extends React.Component {

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    searchForWhat: propTypes.string.isRequired,
    objectType: propTypes.string.isRequired,
    objectId: propTypes.string,
    onAdd: propTypes.func,
    onChange: propTypes.func.isRequired,
    isAdding: propTypes.bool
  }

  handleClick = ev => {
    const { objectType, objectId } = this.props;
    this.props.onAdd(objectType, objectId);
  }

  handleChange = value => {
    const { objectType } = this.props;
    this.props.onChange(objectType, value);
  }

  render() {
    const { endpointId, searchForWhat, objectType, objectId, isAdding } = this.props;
    return (
      <div className="input-group xs-mb-15">
        {['fastCase', 'fastErrand', 'fastOrder'].includes(objectType) && (
          <EndpointFastRemoteSearcher
            id={`fatdiTesterSelect${objectType}`} 
            name="objectId"
            endpointId={endpointId}
            forWhat={searchForWhat}
            onChange={this.handleChange}
            options={{allowClear: true, placeholder: `Search by ${objectType} ID or number`, minimumInputLength: 1}}
            defaultValue={objectId}
          />
        )}
        {objectType === 'topdeskIncident' && (
          <EndpointTopdeskRemoteSearcher
            id={`fatdiTesterSelect${objectType}`} 
            name="objectId"
            endpointId={endpointId}
            forWhat={searchForWhat}
            onChange={this.handleChange}
            options={{allowClear: true, placeholder: 'Search by TOPdesk incident ID or number', minimumInputLength: 1}}
            defaultValue={objectId}
          />
        )}
        <span className="input-group-btn">
          <ButtonWaiting disabled={!objectId} onClick={this.handleClick} type="button" className="btn btn-primary" isWaiting={isAdding}>
            Add Object
          </ButtonWaiting>
        </span>
      </div>
    );
  }

}
