import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownButton } from 'react-bootstrap';
import { instanceTitle } from 'client/redux/instance/instanceHelpers';
import Time from 'client/components/Helpers/Time';
import { Link } from 'react-router-dom';
import BeLoading from 'client/components/Be/BeLoading';
import {
  ModalButton,
  ModalFormattedObject,
  ModalConfirmButton,
  ModalContentConfirm,
} from 'client/components/Helpers/Modal';
import { ConnectorInstanceStatusLabel } from 'client/components/Helpers/StatusLabel';
import InstancePanelModal from 'client/components/Instance/InstancePanelModal';
import {
  TablePanelColumnSelectorDropdown,
  TableCheckbox,
  TableThSort,
  TableCheckboxManager,
  TablePagination,
  TableShowCount,
  TableUserCell,
} from 'client/components/Helpers/Table';
import InstanceTableFilterForm from 'client/components/Instance/components/InstanceTableFilterForm';
import _ from 'lodash';

class InstanceTable extends React.Component {

  static defaultProps = {
    instances: []
  }

  static propTypes = {
    columns: propTypes.array,
    filter: propTypes.object,
    instances: propTypes.array,
    loading: propTypes.bool,
    onDeleteInstance: propTypes.func,
    onCloneInstance: propTypes.func,
    onOrderBy: propTypes.func,
    onRefresh: propTypes.func,
    onSetColumns: propTypes.func,
    onSetPage: propTypes.func,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
  }

  handleModalClosed = (data = {}) => {
    if(data.wasUpdated) return this.props.onRefresh();
    return Promise.resolve();
  }

  columnIsVisible = id => {
    const { columns } = this.props;
    return _.get(_.find(columns, {id}), 'visible', false);
  }

  renderInstances() {
    const {
      instances,
      handleSelectRow,
      isRowSelected,
      onDeleteInstance,
      onCloneInstance,
    } = this.props;
    if(!instances || !instances.length) return (
      <tr>
        <td className="empty" colSpan={100}>No instances to show</td>
      </tr>
    );

    return instances.map(instance => {
      const {
        id,
        createdAt,
        updatedAt,
        statusAt,
        status,
        connectorId,
        Connector:connector,
        User,
      } = instance;
      const connectorShortTitle = _.get(instance, 'Connector.shortTitle', connectorId);
      const connectorTitle = _.get(instance, 'Connector.title', connectorId);
      const visible = this.columnIsVisible;
      return (
        <tr key={id}>
          {visible('select') && (<td>
            <TableCheckbox
              base="instanceTableSelect"
              id={id}
              onSelect={handleSelectRow}
              isSelected={isRowSelected(id)}
            />
          </td>)}
          {visible('id') && (<td className="cell-detail">
            <span>{connectorShortTitle}</span>
            <span className="cell-detail-description">{id}</span>
          </td>)}
          {visible('title') && (<td>{instanceTitle(instance, connector, false)}</td>)}
          {visible('status') && (<td><ConnectorInstanceStatusLabel status={status} /></td>)}
          {visible('statusAt') && (<td>{statusAt && (<Time time={statusAt} format="YYYY-MM-DD HH:mm" />)}</td>)}
          {visible('createdAt') && (<td><Time time={createdAt} format="YYYY-MM-DD HH:mm" /></td>)}
          {visible('updatedAt') && (<td><Time time={updatedAt} format="YYYY-MM-DD HH:mm" /></td>)}
          {visible('userId') && <TableUserCell user={User} />}
          <td className="text-right">
            <DropdownButton title={(<i className="icon mdi mdi-settings"></i>)} pullRight id={`table-row-actions-${instance.id}`}>
              <li>
                <Link to={`/instance/${instance.type}/${instance.id}`}>
                  <i className="icon mdi mdi-sign-in"></i>
                  Show panel
                </Link>
              </li>
              <li>
                <Link to={`/endpoint?connectorInstanceId=${instance.id}`}>
                  <i className="icon mdi mdi-input-power"></i>
                  Show endpoints
                </Link>
              </li>
              <li>
                <Link to={`/instance/log?connectorInstanceId=${instance.id}`}>
                  <i className="icon mdi mdi-view-list"></i>
                  Show instance logs
                </Link>
              </li>
              <li role="separator" className="divider" />
              <li>
                <ModalButton anchor className="btn-link" ModalComponent={ModalFormattedObject} modalProps={{object: instance, title: connectorTitle}}>
                  <i className="icon mdi mdi-search"></i>
                  Inspect
                </ModalButton>
              </li>
							<li>
                <ModalConfirmButton
                  anchor
                  callback={() => onCloneInstance(id)}
                  contentProps={{title: 'Confirm instance clone', text: 'Are you sure you want to clone this instance?', type: 'success', icon: 'mdi-copy'}}
                  Content={ModalContentConfirm}
                >
                  <i className="icon mdi mdi-copy"></i>
                  Clone
                </ModalConfirmButton>
							</li>
              <li>
                <ModalButton anchor className="btn-link" onClose={this.handleModalClosed} ModalComponent={InstancePanelModal} modalProps={{instance}}>
                  <i className="icon mdi mdi-settings"></i>
                  Settings
                </ModalButton>
              </li>
							<li>
                <ModalConfirmButton
                  anchor
                  callback={() => onDeleteInstance(id)}
                  contentProps={{title: 'Confirm instance deletion', text: 'Are you sure you want to delete this instance?', type: 'warning'}}
                  Content={ModalContentConfirm}
                >
                  <i className="icon mdi mdi-delete"></i>
                  Delete
                </ModalConfirmButton>
							</li>
            </DropdownButton>
          </td>
        </tr>
      )
    })
  }

  renderTable() {
    const { handleSelectAll, isAllSelected, orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    const visible = this.columnIsVisible;
    return (
      <div className="be-datatable-body no-overflow">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                {visible('select') && (<th>
                  <TableCheckbox
                    id="instanceTableSelectAll"
                    onSelectAll={handleSelectAll}
                    isSelected={isAllSelected()}
                  />
                </th>)}
                {visible('id') && (<TableThSort column="id" {...sortProps}>Type/ID</TableThSort>)}
                {visible('title') && (<th>Title</th>)}
                {visible('status') && (<TableThSort column="status" {...sortProps}>Status</TableThSort>)}
                {visible('statusAt') && (<TableThSort column="statusAt" {...sortProps}>Status at</TableThSort>)}
                {visible('createdAt') && (<TableThSort column="createdAt" {...sortProps}>Created</TableThSort>)}
                {visible('updatedAt') && (<TableThSort column="updatedAt" {...sortProps}>Updated</TableThSort>)}
                {visible('userId') && (<th>User</th>)}
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderInstances()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['instance', 'instances']} />
          </div>
        </div>
        <div className="col-sm-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { onFilter, filter, onSetColumns, columns } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading panel-heading-divider">
          Table of Instances
          <TablePanelColumnSelectorDropdown
            id="table-columns"
            onSetColumns={onSetColumns}
            columns={columns}
          />
        </div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <InstanceTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

export default TableCheckboxManager(InstanceTable, {
  allIds: function(props) {
    const instances = props.instances || [];
    return instances.map(instance => instance.id);
  } 
});
