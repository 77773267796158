import {
  INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ,
  INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
  INSTANCE_SWTD_OPTIONS_READ_META,
} from 'client/redux/swtd/constants';
import { endpointTopdeskAssetTemplateFieldTargets } from 'client/redux/endpoint/topdesk/actionCreators';
import { instanceSwtdReadMeta } from 'client/redux/swtd/actionCreators';

export const instanceSwtdOptionsAssetFieldTargetsRead = (topdeskEndpointId, assetTemplateId) => dispatch => dispatch(endpointTopdeskAssetTemplateFieldTargets(INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_READ.map(type => ({type, meta: {assetTemplateId}})), topdeskEndpointId, assetTemplateId));

export const instanceSwtdOptionsAssetFieldTargetsReset = () => dispatch => dispatch({
  type: INSTANCE_SWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
});

export const instanceSwtdOptionsReadMeta = instanceId => dispatch => dispatch(instanceSwtdReadMeta(INSTANCE_SWTD_OPTIONS_READ_META, instanceId));
