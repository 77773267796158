import React  from 'react';
import propTypes from 'prop-types';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import { topdeskFieldNameToNatural } from 'client/redux/field/constants';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import { isFilled, isString, isUUID } from 'client/redux/validators';
import * as optionsActions from 'client/redux/optd/optionsActions';
import _ from 'lodash';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import FieldMapFormComponent from 'client/components/FieldMap/FieldMapFormComponent';
import { targetToLabel } from 'client/redux/field/constants';
import BeLoading from 'client/components/Be/BeLoading';
import { escapeDottedName } from 'client/redux/formHelpers';
import Sticky from 'react-stickynode';

const validators = {
  isString: [isString()],
  isNonEmptyString: [isString(), isFilled()],
  isFilledUUID: [isString(), isFilled(), isUUID()],
  isUUID: [isString(), isUUID()]
};

class OPTDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  componentDidMount() {
    const { instanceId } = this.props;
    const assetTemplateId = _.get(this.props, 'initialValues.deviceAssetTemplateId');
    return Promise.all([
      this.getAssetFieldTargets(assetTemplateId),
      this.props.instanceOptdOptionsReadMeta(instanceId),
    ]);
  }

  handleReset = ev => {
    this.props.reset();
  }

  render() {
    const { dirty, disabled, invalid, isSetupMode, onSubmit, onNudgeStep, isUpdating } = this.props;
		return (
      <Form id="instanceOptdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceOptdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20">
          <div className="row">
            <div className="col-md-6">
              {this.renderIncidentSettings()}
            </div>
            <div className="col-md-6">
              {this.renderAssetSettings()}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {this.renderIncidentFieldMap()}
            </div>
            <div className="col-md-6">
              {this.renderAssetFieldMap()}
            </div>
          </div>
        </div>
      </Form>
		);
  }

  renderIncidentSettings() {
    const {
      disabled,
      endpointsByType: { topdesk },
      initialValues: { incidentProcessingStatusWhenClosedId }
    } = this.props;
    return (
      <div className="well">
        <InstanceHelpHeader
          subTitle="Options for how TOPdesk Incidents are created by OpManager Alarms"
          helpFragmentId="instance_optd_settings_incidents_help"
        >
          Incident Settings
        </InstanceHelpHeader>
        <div>
          <Field
            base="optdOptionsIncidentProcessingStatusWhenClosedId"
            component={FormReduxSelect2}
            select2Component={EndpointTopdeskRemoteSearcher}
            select2ComponentProps={{forWhat: 'processingStatus', endpointId: topdesk.id}}
            select2Options={{minimumInputLength: 1, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Do not update processing status'}}
            name="incidentProcessingStatusWhenClosedId"
            defaultValue={incidentProcessingStatusWhenClosedId}
            disabled={disabled}
            label="Incident Processing Status for cleared OpManager Alarm"
            validate={validators.isUUID}
          />
        </div>
      </div>
    );
  }

  renderIncidentFieldMap() {
    const {
      isReadingMeta,
      errorMeta,
    } = this.props;

    return (
      <div className="well form-horizontal xs-mb-10">
        <InstanceHelpHeader
          subTitle="Mapping from an OpManager Alarm to a new TOPdesk Incident"
          helpFragmentId="instance_optd_settings_incident_fieldmap_help"
        >
          Field Map for new Incidents
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorMeta} />
        <BeLoading loading={isReadingMeta} className="xs-mt-20">
          {this.renderIncidentFieldMapTargets()}
        </BeLoading>
      </div>
    );
  }


  renderIncidentFieldMapTargets() {
    const {
      endpointsByType: { topdesk: { id: topdeskEndpointId }},
      disabled,
    } = this.props;

    const fieldMap = _.get(this.props, 'meta.fieldMap.incident');
    const fieldTargets = _.get(this.props, 'meta.fieldMap.incident.targets', []);
    if(!fieldTargets || !fieldTargets.length) {
      return <p className="xs-m-0">There is nothing here yet.</p>;
    }

    return fieldTargets.map(target => {
      const { name } = target;
      let defaultSource = _.get(fieldMap, ['defaultTargetToSourceMap', name]);
      if(!defaultSource) defaultSource = {type: 'FieldSourceIgnore'};
      const defaultValue = _.get(this.props.initialValues, ['incidentFieldMap', name]);
      return (
        <Field
          key={`incidentFieldMap_${name}`}
          name={`incidentFieldMap.${escapeDottedName(name)}`}
          base={`optdOptionsIncidentFieldMap${name}`}
          defaultValue={defaultValue}
          component={FieldMapFormComponent}
          label={topdeskFieldNameToNatural(name)}
          disabled={disabled}
          target={target}
          defaultSource={defaultSource}
          availableSources={this.getAvailableAlarmSources()}
          endpointIds={{topdeskId: topdeskEndpointId}}
        />
      );
    });
  }

  getAssetFieldTargets = assetTemplateId => {
    if(assetTemplateId) {
      const { endpointsByType: { topdesk: { id } } } = this.props;
      return this.props.instanceOptdOptionsAssetFieldTargetsRead(id, assetTemplateId);
    }
    return this.props.instanceOptdOptionsAssetFieldTargetsReset();
  }

  handleDeviceAssetTemplateIdChange = (ev, value) => {
    return this.getAssetFieldTargets(value);
  }

  renderAssetSettings() {
    const {
      disabled,
      endpointsByType: { topdesk },
      initialValues: { deviceAssetTemplateId }
    } = this.props;
    return (
      <div className="well">
        <InstanceHelpHeader
          subTitle="Options for how TOPdesk Assets are created by OpManager Devices"
          helpFragmentId="instance_optd_settings_assets_help"
        >
          Asset Settings
        </InstanceHelpHeader>
        <div>
          <Field
            base="optdOptionsDeviceAssetTemplateId"
            name="deviceAssetTemplateId"
            label="Asset Template Name for OpManager Devices"
            component={FormReduxSelect2}
            select2ComponentProps={{endpointId: topdesk.id, forWhat: 'assetTemplateId'}}
            select2Component={EndpointTopdeskRemoteSearcher}
            select2Options={{minimumInputLength: 1, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Do not create assets'}}
            disabled={disabled}
            defaultValue={deviceAssetTemplateId}
            validate={validators.isString}
            onChange={this.handleDeviceAssetTemplateIdChange}
          />
        </div>
      </div>
    );
  }

  getAvailableAlarmSources() {
    const columns = _.get(this.props, 'meta.alarmFields');
    if(!_.isArray(columns)) return [];
    return [
      {type: 'FieldSourceResolve'},
      {type: 'FieldSourceObject', columns},
    ];
  }

  getAvailableDeviceSources() {
    // these are the fields available in an OpManager Device
    const columns = [
      'addedTime',
      'category',
      'deviceName',
      'displayName',
      'interfaceCount',
      'ipaddress',
      'isNew',
      'isSNMP',
      'mapName',
      'numericStatus',
      'prettyTime',
      'probeName',
      'status',
      'statusNum',
      'statusStr',
      'type',
      'vendorName',
    ];
    return [
      {type: 'FieldSourceObject', columns },
    ];
  }

  getDefaultSource(fieldName) {
    switch(fieldName) {
      case 'name': return {type: 'FieldSourceObject', props: {fieldName: 'entity'}};
      default: return {type: 'FieldSourceIgnore'};
    }
  }

  getAssetTemplateId() {
    const initialName = _.get(this.props, 'initialValues.deviceAssetTemplateId');
    const currentName = _.get(this.props, 'currentFormValues.deviceAssetTemplateId');
    return !_.isUndefined(currentName) ? currentName : initialName;
  }

  renderAssetFieldMap() {
    const {
      isReadingFieldTargets,
      errorFieldTargets,
    } = this.props;

    if(!this.getAssetTemplateId()) {
      return null;
    }

    const assetTemplateName = _.get(this.props, 'assetFieldTargets.assetTemplateName');

    return (
      <div className="well form-horizontal xs-mb-10">
        <InstanceHelpHeader
          subTitle="Mapping from an OpManager Device to a TOPdesk Asset Template"
          helpFragmentId="instance_optd_settings_asset_fieldmap_help"
        >
          {assetTemplateName}
        </InstanceHelpHeader>
        <BeRemoteErrorAlert error={errorFieldTargets} />
        <BeLoading loading={isReadingFieldTargets} className="xs-mt-20">
          {this.renderAssetFieldMapTargets()}
        </BeLoading>
      </div>
    );
  }

  renderAssetFieldMapTargets() {
    const {
      endpointsByType: { topdesk: { id:topdeskEndpointId }},
      disabled,
    } = this.props;

    const fieldTargets = _.get(this.props, 'assetFieldTargets.fieldTargets', []);
    if(!fieldTargets || !fieldTargets.length) {
      return <p className="xs-m-0">There is nothing here yet.</p>;
    }

    return fieldTargets.map(target => {
      const { name } = target;
      return (
        <Field
          key={`deviceAssetFieldMap_${name}`}
          name={`deviceAssetFieldMap.${name}`}
          base={`optdOptionsDeviceAssetTemplateId${name}`}
          component={FieldMapFormComponent}
          label={targetToLabel(target)}
          disabled={disabled}
          target={target}
          defaultSource={this.getDefaultSource(name)}
          availableSources={this.getAvailableDeviceSources()}
          endpointIds={{topdeskId: topdeskEndpointId}}
        />
      );
    });
  }

}

function mapStateToProps(state) {
  const selector = formValueSelector('optdOptions');
  // the value of these fields need to be available when rendering
  return {
    ...state.optd.options,
    currentFormValues: {
      deviceAssetTemplateId: selector(state, 'deviceAssetTemplateId'),
    },
  };
}

const actions = {
  ...optionsActions,
};

export default connect(mapStateToProps, actions)(reduxForm({
  form: 'optdOptions',
  enableReinitialize: true,
})(OPTDOptionsForm));
