import { RESET_PASSWORD } from 'client/redux/resetPassword/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isWaiting: false,
  result: null,
  error: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(RESET_PASSWORD, state, action, {requestProp: 'isWaiting'});
  return state;
};
