import React  from 'react';
import propTypes from 'prop-types';
import { CharAddon } from 'client/components/Form/Helpers';
import { FormReduxSelect2, FormReduxSelect2InputGroup } from 'client/components/Form/FormRedux';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import UIFragmentHelpButton from 'client/components/UIFragment/UIFragmentHelpButton';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { normalizeBoolean } from 'client/redux/formHelpers';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import EndpointTopdeskFieldArchivingReason from 'client/components/Endpoint/components/EndpointTopdeskFieldArchivingReason';
import _ from 'lodash';
import Sticky from 'react-stickynode';

class FSTDOptionsForm extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  handleReset = ev => {
    this.props.reset();
  }

  render() {
    const { dirty, disabled, invalid, isSetupMode, onSubmit, onNudgeStep, isUpdating } = this.props;
		return (
      <Form id="instanceFstdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceFstdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        <div className="xs-mt-20">
          <div className="row">
            <div className="col-md-6">
              {this.renderParseSettings()}
            </div>
            <div className="col-md-6">
              {this.renderBehaviorSettings()}
            </div>
          </div>
        </div>
      </Form>
		);
  }

  renderParseSettings() {
    const {
      disabled,
      initialValues: { csvParseOptions = {}, csvParseEncoding }
    } = this.props;

    const getCurrentValue = key => _.get(this.props, ['currentFormValues', 'csvParseOptions', key], '');

    return (
      <div className="well">
        <InstanceHelpHeader
          subTitle="Options for how CSV files are parsed"
          helpFragmentId="instance_fstd_settings_parse_help"
        >
          Parse Settings
        </InstanceHelpHeader>
        <div>
          <Field
            base={`fstdOptionsCsvParseDelimiter`}
            name="csvParseOptions.delimiter"
            label="Field delimiter"
            component={FormReduxSelect2InputGroup}
            after={<CharAddon value={getCurrentValue('delimiter')} />}
            data={[
              {id: ',', text: 'Comma'},
              {id: ';', text: 'Semicolon'},
            ]}
            defaultValue={csvParseOptions.delimiter}
            disabled={disabled}
          />
          <Field
            base={`fstdOptionsCsvParseRowDelimiter`}
            name="csvParseOptions.rowDelimiter"
            label="Row delimiter"
            after={<CharAddon value={getCurrentValue('rowDelimiter')} transform={rowDelimiterToChars} />}
            component={FormReduxSelect2InputGroup}
            data={[
              {id: '', text: 'Auto discovery'},
              {id: 'windows', text: 'Windows'},
              {id: 'apple', text: 'Apple'},
              {id: 'linux', text: 'Linux'},
            ]}
            defaultValue={csvParseOptions.delimiter}
            disabled={disabled}
          />
          <Field
            base={`fstdOptionsCsvParseQuote`}
            name="csvParseOptions.quote"
            label="Field quote character"
            after={<CharAddon value={getCurrentValue('quote')} />}
            component={FormReduxSelect2InputGroup}
            data={[
              {id: '"', text: 'Double-quote'},
              {id: '\'', text: 'Single-quote'},
            ]}
            defaultValue={csvParseOptions.delimiter}
            disabled={disabled}
          />
          <Field
            base={`fstdOptionsCsvParseEncoding`}
            name="csvParseEncoding"
            label="File encoding"
            component={FormReduxSelect2}
            data={[
              {id: 'utf-8', text: 'UTF-8'},
              {id: 'iso-8859-1', text: 'ISO-8859-1'},
              {id: 'utf-16', text: 'UTF-16'},
              {id: 'utf-16le', text: 'UTF-16LE'},
              {id: 'utf-16be', text: 'UTF-16BE'},
            ]}
            defaultValue={csvParseEncoding}
            helpText="Typically ISO-8859-1 for Windows-sourced files and UTF-8 for everything else"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  renderBehaviorSettings() {
    const {
      disabled,
      endpointsByType: { topdesk },
      initialValues: {
        ignoreUnmappedFiles,
        archiveMissingAssets,
        unlinkMissingLinks,
        onlyUpdateEmptyFields,
        topdeskArchivingReasonId,
        keepFilesAfterProcessing,
        unassignMissingAssignments,
      }
    } = this.props;
    return (
      <div className="well">
        <h3 className="wizard-title xs-mb-20">
          Behavior
          <UIFragmentHelpButton
            className="btn btn-rounded btn-default btn-xs pull-right"
            fragmentId="instance_fstd_settings_behavior_help"
          />
          <span className="panel-subtitle">
            Options for controlling connector behavior
          </span>
        </h3>
        <div>
          <Field
            base={'fstdOptionsIgnoreUnmappedFiles'}
            name="ignoreUnmappedFiles"
            label="Uploaded files without a field map"
            helpFragmentId="instance_fstd_settings_ignoreUnmappedFiles_help"
            component={FormReduxSelect2}
            data={[
              {id: false, text: 'Will be imported with a default field map'},
              {id: true, text: 'Will be ignored'},
            ]}
            defaultValue={ignoreUnmappedFiles}
            normalize={normalizeBoolean}
            disabled={disabled}
          />
          <Field
            base={'fstdOptionsArchiveMissingAssets'}
            name="archiveMissingAssets"
            label="Assets of the same template not in any of the uploaded files"
            helpFragmentId="instance_fstd_settings_archiveMissingAssets_help"
            component={FormReduxSelect2}
            data={[
              {id: false, text: 'Missing assets will not be archived'},
              {id: true, text: 'Missing assets will be archived'},
            ]}
            defaultValue={archiveMissingAssets}
            normalize={normalizeBoolean}
            disabled={disabled}
          />
          <Field
            base={'fstdOptionsUnlinkMissingLinks'}
            name="unlinkMissingLinks"
            label="Links that are empty in the currently processing file"
            helpFragmentId="instance_fstd_settings_unlinkMissingLinks_help"
            component={FormReduxSelect2}
            data={[
              {id: false, text: 'Assets will keep their existing links'},
              {id: true, text: 'Assets will lose any link not defined'},
            ]}
            defaultValue={unlinkMissingLinks}
            normalize={normalizeBoolean}
            disabled={disabled}
          />
          <Field
            base={'fstdOptionsUnassignMissingAssignments'}
            name="unassignMissingAssignments"
            label="Assignments that are empty in the currently processing file"
            helpFragmentId="instance_fstd_settings_unassignMissingAssignments_help"
            component={FormReduxSelect2}
            data={[
              {id: false, text: 'Assets will keep their existing assignments'},
              {id: true, text: 'Assets will lose all assignments of the same type'},
            ]}
            defaultValue={unassignMissingAssignments}
            normalize={normalizeBoolean}
            disabled={disabled}
          />
          <Field
            base={'fstdOptionsOnlyUpdateEmptyFields'}
            name="onlyUpdateEmptyFields"
            label="Rule for updating existing assets"
            helpFragmentId="instance_fstd_settings_onlyUpdateEmptyFields_help"
            component={FormReduxSelect2}
            data={[
              {id: false, text: 'Every asset field will be updated when changed'},
              {id: true, text: 'The field of an existing asset will be updated only if it is empty'},
            ]}
            defaultValue={onlyUpdateEmptyFields}
            normalize={normalizeBoolean}
            disabled={disabled}
          />
          <EndpointTopdeskFieldArchivingReason
            base="fstdOptionsTopdeskArchivingReason"
            endpointId={topdesk.id}
            topdeskArchivingReasonId={topdeskArchivingReasonId}
            disabled={disabled}
            name="topdeskArchivingReasonId"
            placeholderText="Use the first available archiving reason"
            helpText="Set the reason given when TOPdesk Assets are archived"
          />
          <Field
            base={'fstdOptionsKeepFilesAfterProcessing'}
            name="keepFilesAfterProcessing"
            label="Automatically remove files from DAV after processing"
            component={FormReduxSelect2}
            data={[
              {id: false, text: 'Processed files will be removed after import'},
              {id: true, text: 'Processed files will be kept after import'},
            ]}
            placeholderText="Processed files will be removed after import"
            defaultValue={keepFilesAfterProcessing}
            normalize={normalizeBoolean}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

const rowDelimiterToChars = value => {
  switch(value) {
    default: return '?';
    case 'apple': return '\\r';
    case 'windows': return '\\r\\n';
    case 'linux': return '\\n';
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('fstdOptions');
  // the value of these fields need to be available when rendering
  const fields = [
    'csvParseOptions.delimiter',
    'csvParseOptions.quote',
    'csvParseOptions.rowDelimiter',
  ];
  return {
    currentFormValues: selector(state, ...fields)
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'fstdOptions',
  enableReinitialize: true,
})(FSTDOptionsForm));
