import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class SWTDPanel extends React.Component {

  static propTypes = {
    onCall: propTypes.func.isRequired,
  }

  render() {
    const { onCall } = this.props;
    return (
      <div>
        <div>
          <ButtonWaitingPromise onClick={ev => onCall('syncOneInstanceSnowComputers')} type="button" className="btn btn-default">
            Synchronize Snow Computers
          </ButtonWaitingPromise>{' '}
        </div>
      </div>
    );
  }

}
