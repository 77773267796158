import React from 'react';
import propTypes from 'prop-types';
import { graphUserFieldNameToNatural, topdeskSearchRemoteNameToNatural } from 'client/redux/field/constants';
import { EndpointGraphRemoteSearcher, EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import SwitchButton from 'client/components/Form/SwitchButton';
import { AlertWithMessage } from 'client/components/Be/Alert';
import Icon from 'client/components/Be/Icon';
import _ from 'lodash';

export class FieldMapModalInputConstant extends React.Component {

  static propTypes = {
    id: propTypes.string,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
    target: propTypes.object.isRequired,
  }

  getEnumSelectData() {
    const list = _.get(this.props, 'target.props.list') || [];
    return [
      ...list.map(v => ({id: v, text: v})),
    ];
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: { value },
      target,
    } = this.props;
    return (
      <div className="form-group">
        <div className="control-label">
          <label htmlFor={id}>Sets a constant value every time</label>
        </div>
        {target.type === 'FieldTargetEnum' && (
          <Select2Wrap
            id={id}
            name="value"
            className="form-control"
            data={this.getEnumSelectData()}
            options={{dropdownParent: '#fieldMapModalSimple'}}
            onChange={onChange}
            defaultValue={value || ''}
            disabled={disabled}
            required
          />
        )}
        {target.type !== 'FieldTargetEnum' && (
          <input
            id={id}
            disabled={disabled}
            className="form-control"
            name="value"
            value={value || ''}
            onChange={onChange}
          />
        )}
      </div>
    );
  }
}

export class FieldMapModalInputToken extends React.Component {

  static propTypes = {
    id: propTypes.string,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
    target: propTypes.object.isRequired,
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: { value },
    } = this.props;
    const rows = Math.max(4, (value || '').split('\n').length);
    return (
      <div className="form-group">
        <div className="control-label">
          <label htmlFor={id}>Sets a token string</label>
        </div>
        <textarea
          id={id}
          disabled={disabled}
          className="form-control"
          rows={rows}
          name="value"
          value={value || ''}
          onChange={onChange}
        >
        </textarea>
        <div className="help-block">
          Uses <a href="https://mustache.github.io/" target="_blank" rel="noopener noreferrer">Mustache</a> for interpolation
        </div>
      </div>
    );
  }
}

export class FieldMapModalInputUserSelect extends React.Component {

  static propTypes = {
    id: propTypes.string,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  state = {
    newValue: '',
    newLabel: '',
  }

  handleRequiredChange = ev => {
    return this.props.onChange('required', ev.target.checked);
  }

  getAllowedValues() {
    return _.get(this.props, 'sourceProps.allowedValues') || [];
  }

  handleChange = ev => {
    const { name, value } = ev.target;
    this.setState({[name]: value});
  }

  handleClickAdd = () => {
    const { newValue, newLabel } = this.state;
    const allowedValues = this.getAllowedValues();
    const isSetToString = allowedValues.length > 0 && typeof allowedValues[0] === 'string';

    if (isSetToString) {
      const newAllowedValues = allowedValues.map(value => ({value, label: value}));
      this.props.onChange('allowedValues', [
        ...newAllowedValues,
        {value: newValue, label: newLabel || newValue},
      ]);
    } else {
      this.props.onChange('allowedValues', [
        ...allowedValues,
        {value: newValue, label: newLabel || newValue},
      ]);
    }

    this.setState({newValue: '', newLabel: ''});
  }

  handleClickRemoveAllowedValue = valueToRemove => {
    const allowedValues = this.getAllowedValues();
    this.props.onChange('allowedValues', allowedValues.filter(value => value !== valueToRemove));
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: {
        helpText,
        label,
        order,
        placeholder,
        required = false,
      },
    } = this.props;
    const { newValue, newLabel } = this.state;
    const allowedValues = this.getAllowedValues();

    return (
      <div>
        <div className="row">
          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Label'}>Label</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'Label'}
              disabled={disabled}
              name="label"
              value={label || ''}
              onChange={onChange}
            />
          </div>

          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Placeholder'}>Placeholder (optional)</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'Placeholder'}
              disabled={disabled}
              name="placeholder"
              value={placeholder || ''}
              onChange={onChange}
            />
          </div>

          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Order'}>Display Order</label>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              className="form-control"
              id={id + 'Order'}
              disabled={disabled}
              name="order"
              value={order || ''}
              onChange={onChange}
            />
          </div>

        </div>

        <div className="form-group form-group-sm">
          <div className="control-label">
            <label htmlFor={id + 'HelpText'}>Help Text (optional)</label>
          </div>
          <input
            type="text"
            className="form-control"
            id={id + 'HelpText'}
            disabled={disabled}
            name="helpText"
            value={helpText || ''}
            onChange={onChange}
          />
        </div>

        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id + 'Required'}>Is the end user required to specify this field?</label>
          </div>
          <SwitchButton
            id={id + 'Required'}
            className="switch-button-yesno"
            disabled={disabled}
            name="required"
            value={required}
            onChange={this.handleRequiredChange}
          />
        </div>

        <div className="form-group form-group-sm">
          <div className="control-label">
            <label htmlFor={id + 'AllowedValues'}>Allowed values</label>
          </div>
          <table className="table table-bordered table-striped table-condensed xs-mb-10">
            <thead>
              <tr>
                <th>Value</th>
                <th>Label</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allowedValues.map((value, index) => (
                <tr key={index}>
                  <td>
                    {typeof value === 'string' ? value : value.value}
                  </td>
                  <td>
                    {typeof value === 'string' ? value : value.label}
                  </td>
                  <td className="text-right">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.handleClickRemoveAllowedValue(value)}
                      disabled={disabled}
                    >
                      <Icon icon="delete" />
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id={id + 'AllowedValuesKey'}
                    placeholder="Enter the value"
                    disabled={disabled}
                    value={newValue}
                    name="newValue"
                    onChange={this.handleChange}
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id={id + 'AllowedValuesKey'}
                    placeholder="Leave empty to use value as label"
                    disabled={disabled}
                    value={newLabel}
                    name="newLabel"
                    onChange={this.handleChange}
                  />
                </td>
                <td className="text-right">
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={this.handleClickAdd}
                    disabled={!(newValue || '').trim()}
                  >
                    <Icon icon="plus" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export class FieldMapModalInputUserSelectFromResolver extends React.Component {

  static propTypes = {
    id: propTypes.string,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  handleRequiredChange = ev => {
    return this.props.onChange('required', ev.target.checked);
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: {
        helpText,
        label,
        order,
        placeholder,
        groupOn,
        required = false,
      },
    } = this.props;
    return (
      <div>
        <div className="row">
          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Label'}>Label</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'Label'}
              disabled={disabled}
              name="label"
              value={label || ''}
              onChange={onChange}
            />
          </div>

          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Placeholder'}>Placeholder (optional)</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'Placeholder'}
              disabled={disabled}
              name="placeholder"
              value={placeholder || ''}
              onChange={onChange}
            />
          </div>

          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Order'}>Display Order</label>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              className="form-control"
              id={id + 'Order'}
              disabled={disabled}
              name="order"
              value={order || ''}
              onChange={onChange}
            />
          </div>

        </div>

        <div className="form-group form-group-sm">
          <div className="control-label">
            <label htmlFor={id + 'HelpText'}>Help Text (optional)</label>
          </div>
          <input
            type="text"
            className="form-control"
            id={id + 'HelpText'}
            disabled={disabled}
            name="helpText"
            value={helpText || ''}
            onChange={onChange}
          />
        </div>

        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id + 'Required'}>Is the end user required to specify this field?</label>
          </div>
          <SwitchButton
            id={id + 'Required'}
            className="switch-button-yesno"
            disabled={disabled}
            name="required"
            value={required}
            onChange={this.handleRequiredChange}
          />
        </div>

        <div className="row">

          <div className="form-group form-group-sm col-md-6 xs-mb-20">
            <div className="control-label">
              <label htmlFor={id + 'GroupOn'}>Group On (optional)</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'GroupOn'}
              disabled={disabled}
              name="groupOn"
              value={groupOn || ''}
              onChange={onChange}
            />
            <p className="help-block">
              When set will group option values under this field
            </p>
          </div>

        </div>

      </div>
    );
  }
}

export class FieldMapModalInputUserInput extends React.Component {

  static propTypes = {
    id: propTypes.string,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  handleRequiredChange = ev => {
    return this.props.onChange('required', ev.target.checked);
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: {
        defaultValue,
        required = true,
        helpText,
        inputType,
        label,
        order,
        placeholder,
      },
    } = this.props;
    return (
      <div>

        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id + 'InputType'}>Input type</label>
          </div>
          <Select2Wrap
            id={id + 'InputType'}
            name="inputType"
            className="form-control"
            data={[
              {id: 'text', text: 'Text'},
              {id: 'email', text: 'Email'},
              {id: 'textarea', text: 'Textarea'},
              {id: 'checkbox', text: 'Checkbox'},
            ]}
            options={{dropdownParent: '#fieldMapModalSimple'}}
            onChange={onChange}
            defaultValue={inputType}
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id + 'DefaultValue'}>Default value (optional)</label>
          </div>
          <input
            type="text"
            className="form-control"
            id={id + 'DefaultValue'}
            disabled={disabled}
            name="defaultValue"
            value={defaultValue || ''}
            onChange={onChange}
          />
          <p className="help-block">
            This value will be used when the end user does enter anything in the field
          </p>
        </div>

        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id + 'Required'}>Is the end user required to specify this field?</label>
          </div>
          <SwitchButton
            id={id + 'Required'}
            className="switch-button-yesno"
            disabled={disabled}
            name="required"
            value={required}
            onChange={this.handleRequiredChange}
          />
        </div>

        <div className="row">
          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Label'}>Label</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'Label'}
              disabled={disabled}
              name="label"
              value={label || ''}
              onChange={onChange}
            />
          </div>

          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Placeholder'}>Placeholder (optional)</label>
            </div>
            <input
              type="text"
              className="form-control"
              id={id + 'Placeholder'}
              disabled={disabled}
              name="placeholder"
              value={placeholder || ''}
              onChange={onChange}
            />
          </div>

          <div className="form-group form-group-sm col-md-4">
            <div className="control-label">
              <label htmlFor={id + 'Order'}>Display Order</label>
            </div>
            <input
              type="number"
              min="0"
              step="1"
              className="form-control"
              id={id + 'Order'}
              disabled={disabled}
              name="order"
              value={order || ''}
              onChange={onChange}
            />
          </div>

        </div>

        <div className="form-group form-group-sm">
          <div className="control-label">
            <label htmlFor={id + 'HelpText'}>Help Text (optional)</label>
          </div>
          <input
            type="text"
            className="form-control"
            id={id + 'HelpText'}
            disabled={disabled}
            name="helpText"
            value={helpText || ''}
            onChange={onChange}
          />
        </div>

      </div>
    );
  }
}

export class FieldMapModalInputObject extends React.Component {

  static propTypes = {
    id: propTypes.string,
    columns: propTypes.array,
    contextKeys: propTypes.array,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  static defaultProps = {
    columns: [],
    contextKeys: ['object'],
  }

  state = {
    showCustomHelp: false,
  }

  getFieldsData() {
    const { columns, sourceProps: { fieldName: givenFieldName } } = this.props;
    if(!_.isArray(columns)) return [];
    const result = columns.map(columnName => ({
      id: columnName,
      text: columnName,
    }));
    if(!result.find(({id}) => id === givenFieldName)) {
      // could not find the given field name in the data, add it to the top
      result.unshift({id: givenFieldName, text: givenFieldName});
    }
    return result;
  }

  getId(subName) {
    const { id } = this.props;
    return `${id}${subName}`;
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: { fieldName, contextKey },
      contextKeys,
    } = this.props;
    const data = this.getFieldsData();
    return (
      <div>
        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id + 'ContextKey'}>Choose object</label>
          </div>
          <Select2Wrap
            id={id + 'ContextKey'}
            name="contextKey"
            className="form-control"
            data={contextKeys}
            options={{minimumResultsForSearch: Infinity, dropdownParent: `#fieldMapModalSimple`}}
            onChange={onChange}
            defaultValue={contextKey}
            disabled={disabled}
            required
          />
        </div>
        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id}>Select a field from the object or enter a custom field name</label>
          </div>
          <Select2Wrap
            id={id}
            name="fieldName"
            className="form-control"
            data={data}
            options={{tags: true, minimumResultsForSearch: 0, dropdownParent: `#fieldMapModalSimple`}}
            onChange={onChange}
            defaultValue={fieldName}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

export class FieldMapModalInputFSTDObject extends React.Component {

  static propTypes = {
    id: propTypes.string,
    columns: propTypes.array,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  static defaultProps = {
    columns: [],
  }

  state = {
    showCustomHelp: false,
  }

  getFieldsData() {
    const { columns, sourceProps: { fieldName: givenFieldName } } = this.props;
    if(!_.isArray(columns)) return [];
    const result = columns.map(columnName => ({
      id: columnName,
      text: columnName,
    }));
    if(!result.find(({id}) => id === givenFieldName)) {
      // could not find the given field name in the data, add it to the top
      result.unshift({id: givenFieldName, text: givenFieldName});
    }
    return result;
  }

  getId(subName) {
    const { id } = this.props;
    return `${id}${subName}`;
  }

  handleIgnoreFieldCaseChange = ev => {
    return this.props.onChange('ignoreFieldCase', ev.target.checked);
  }

  render() {
    const {
      disabled,
      onChange,
      id,
      sourceProps: { fieldName, ignoreFieldCase = false },
    } = this.props;
    const data = this.getFieldsData();
    const ignoreFieldCaseId = this.getId('ignoreFieldCase');
    return (
      <div>
        <div className="form-group">
          <div className="control-label">
            <label htmlFor={id}>Select a field from the test file or enter a column name</label>
          </div>
          <Select2Wrap
            id={id}
            name="fieldName"
            className="form-control"
            data={data}
            options={{tags: true, minimumResultsForSearch: 0, dropdownParent: `#fieldMapModalSimple`}}
            onChange={onChange}
            defaultValue={fieldName}
            disabled={disabled}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor={ignoreFieldCaseId}>
            Ignore column case in input data?
          </label>
          <SwitchButton
            id={ignoreFieldCaseId}
            className="switch-button-yesno xs-ml-10"
            disabled={disabled}
            value={ignoreFieldCase}
            onChange={this.handleIgnoreFieldCaseChange}
          />
        </div>
      </div>
    );
  }
}

export class FieldMapModalInputGraphUser extends React.Component {

  static propTypes = {
    id: propTypes.string,
    graphFields: propTypes.array.isRequired,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  state = {
    showCustomHelp: false,
  }

  getFieldsData() {
    const { graphFields, sourceProps: { fieldName:givenFieldName } } = this.props;
    if(!_.isArray(graphFields)) return [];
    const result = graphFields.map(({props: {fieldName}}) => ({
      id: fieldName,
      text: graphUserFieldNameToNatural(fieldName),
    }));
    if(!result.find(({id}) => id === givenFieldName)) {
      // could not find the given field name in the data, add it to the top
      result.unshift({id: givenFieldName, text: givenFieldName});
    }
    return result;
  }

  toggleShowCustomHelp = ev => {
    this.setState({showCustomHelp: !this.state.showCustomHelp});
  }

  renderCustomHelp() {
    const showCustomHelp = this.state.showCustomHelp;
    if(!showCustomHelp) return (
      <span className="help-block">
        Did you know you can also add a custom field?{' '}
        <button className="btn-link" type="button" onClick={this.toggleShowCustomHelp}>Show me how</button>
      </span>
    );
    return (
      <AlertWithMessage icon className="xs-mt-20 xs-mb-0" alertType="primary">
        <p><strong>How to add a custom field</strong></p>
        <ul className="xs-pl-10 xs-mb-0">
          <li><a href="https://developer.microsoft.com/en-us/graph/docs/api-reference/v1.0/resources/user" rel="noopener noreferrer" target="_blank">You can find a list of some of the available fields here</a></li>
          <li>Input the field name exactly as it appears in Microsoft Graph</li>
          <li>Press enter to add the field</li>
          <li>It is highly recommended to test the specific field when doing this as an unknown or invalid field name will be silently discarded and not set</li>
        </ul>
      </AlertWithMessage>
    );
  }

  render() {
    const { disabled, onChange, id, sourceProps: { fieldName } } = this.props;
    const data = this.getFieldsData();
    return (
      <div className="form-group">
        <div className="control-label">
          <label htmlFor={id}>Select a field from the Graph user</label>
        </div>
        <Select2Wrap
          id={id}
          name="fieldName"
          className="form-control"
          data={data}
          options={{tags: true, minimumResultsForSearch: 0, dropdownParent: `#fieldMapModalSimple`}}
          onChange={onChange}
          defaultValue={fieldName}
          disabled={disabled}
        />
        {this.renderCustomHelp()}
      </div>
    );
  }
}

export class FieldMapModalInputGraphGroupMember extends React.Component {

  static propTypes = {
    id: propTypes.string,
    graphId: propTypes.string.isRequired,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  handleChange = value => {
    return this.props.onChange('groupId', value);
  }

  render() {
    const { disabled, graphId, id, sourceProps: { groupId } } = this.props;
    return (
      <div className="form-group">
        <div className="control-label">
          <label htmlFor={id}>Field is set to true when the user is a member of this group</label>
        </div>
        <EndpointGraphRemoteSearcher
          className="form-control"
          id={id}
          forWhat="group"
          endpointId={graphId}
          options={{minimumInputLength: 0, minimumResultsForSearch: 0, dropdownParent: `#fieldMapModalSimple`}}
          name="groupId"
          onChange={this.handleChange}
          defaultValue={groupId}
          disabled={disabled}
        />
      </div>
    );
  }

}

export class FieldMapModalInputTopdeskId extends React.Component {

  static propTypes = {
    id: propTypes.string,
    target: propTypes.object.isRequired,
    topdeskEndpointId: propTypes.string.isRequired,
    sourceType: propTypes.string.isRequired,
    sourceProps: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
  }

  handleCreateChange = ev => {
    const create = ev.target.checked;
    const { sourceProps: { targetResolveOptions = {} } } = this.props;
    const update = {...targetResolveOptions, create};
    return this.props.onChange('targetResolveOptions', update);
  }

  handleDefaultValueChange = value => {
    const { sourceProps: { targetResolveOptions = {} } } = this.props;
    const update = {...targetResolveOptions, defaultValue: value};
    return this.props.onChange('targetResolveOptions', update);
  }

  getId(subName) {
    const { id } = this.props;
    return `${id}${subName}`;
  }

  getCapabilities() {
    return _.get(this.props, 'target.resolver', {
      canCreate: false,
      canDefaultValue: false,
      searchRemoteName: null,
    });
  }

  renderCreate() {
    const { disabled } = this.props;
    const { canCreate } = this.getCapabilities();
    const createId = this.getId('create');
    const { create = false } = _.get(this.props, 'sourceProps.targetResolveOptions', {});
    if(!canCreate) return null;
    return (
      <div className="form-group">
        <label className="control-label" htmlFor={createId}>
          Create the object if it does not exist?
        </label>
        <SwitchButton
          id={createId}
          className="switch-button-yesno xs-ml-10"
          disabled={disabled}
          value={create}
          onChange={this.handleCreateChange}
        />
      </div>
    );
  }

  getSelect2Placeholder() {
    const required = _.get(this.props, 'target.props.required', false);
    if(!required) {
      return {id: null, text: 'Field ignored'};
    }

    const searchRemoteName = _.get(this.props, 'target.resolver.searchRemoteName');
    const text = topdeskSearchRemoteNameToNatural(searchRemoteName);
    return {id: null, text};
  }

  getExtraQuery(forWhat) {
    if (forWhat === 'assetDataSet') {
      const { assetPath, query } = _.get(this.props, 'target.resolver', {});
      return {
        ...query,
        assetPath,
      };
    }
    const extraQuery = _.get(this.props, 'target.resolver.searchRemoteQuery');
    return extraQuery;
  }

  getLabel() {
    const { sourceType } = this.props;
    switch(sourceType) {
      default: return 'Default value when source is not set';
      case 'FieldSourceResolve': return 'Default value';
    }
  }

  renderDefaultValue() {
    const { canDefaultValue, searchRemoteName } = this.getCapabilities();
    const placeholder = this.getSelect2Placeholder();
    const id = this.getId('defaultValue');
    const { defaultValue = '' } = _.get(this.props, 'sourceProps.targetResolveOptions', {});
    const { disabled, topdeskEndpointId } = this.props;
    if(!canDefaultValue) return null;
    return (
      <div className="form-group">
        <label className="control-label" htmlFor={id}>
          {this.getLabel()}
        </label>
        <EndpointTopdeskRemoteSearcher
          className="form-control"
          id={id}
          forWhat={searchRemoteName}
          extraQuery={this.getExtraQuery(searchRemoteName)}
          endpointId={topdeskEndpointId}
          options={{minimumInputLength: 0, minimumResultsForSearch: 0, dropdownParent: `#fieldMapModalSimple`, allowClear: true, placeholder}}
          name="defaultValue"
          onChange={this.handleDefaultValueChange}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </div>
    );
  }

  render() {
    const { sourceType } = this.props;
    if(sourceType === 'FieldSource' || sourceType === 'FieldSourceIgnore') {
      return null;
    }
    return (
      <div>
        {this.renderDefaultValue()}
        {this.renderCreate()}
      </div>
    );
  }

}
