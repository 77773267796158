import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import propTypes from 'prop-types';
import moment from 'moment';

export default class DateRangePickerWrap extends Component {

  static defaultProps = {
    onChange: function() {},
    format: 'YYYY-MM-DD',
    setButtonText: 'Set time'
  }

  static propTypes = {
    startName: propTypes.string,
    endName: propTypes.string,
    startValue: propTypes.string,
    endValue: propTypes.string,
    format: propTypes.string,
    onChange: propTypes.func,
    setButtonText: propTypes.node
  }

  handleApply = (ev, picker) => {
    const { startDate, endDate } = picker;
    const { startName, endName, format } = this.props;
    this.props.onChange({target: {name: startName, value: startDate.format(format)}});
    this.props.onChange({target: {name: endName, value: endDate.format(format)}});
  }

  handleCancel = ev => {
    const { startName, endName } = this.props;
    this.props.onChange({target: {name: startName, value: ''}});
    this.props.onChange({target: {name: endName, value: ''}});
  }

  getLocale() {
    const { format } = this.props;
    return {
      format: format,
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Any time',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Custom',
      weekLabel: 'W',
      firstDay: 1,
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    };
  }

  getDateRange(alwaysReturnDates = false) {
    const { startValue, endValue, format } = this.props;
    const startDate = moment(startValue, format);
    const endDate = moment(endValue, format);
    return {
      startDate: startDate.isValid() ? startDate : (alwaysReturnDates ? moment() : ''),
      endDate: endDate.isValid() ? endDate : (alwaysReturnDates ? moment() : '')
    };
  }

  inputValue() {
    const { format } = this.props;
    const { startDate, endDate } = this.getDateRange();
    if(!moment.isMoment(startDate) || !moment.isMoment(endDate)) return '';
    return startDate.format(format) + ' - ' + endDate.format(format);
  }

  render() {
    const { setButtonText } = this.props;
    const { startDate, endDate } = this.getDateRange(true);
    return (
      <DateRangePicker locale={this.getLocale()} buttonClasses={['btn']} startDate={startDate} endDate={endDate} onApply={this.handleApply} onCancel={this.handleCancel} onChange={this.handleApply}>
        <div className="input-group">
          <input className="form-control" type="text" disabled={true} value={this.inputValue()} placeholder="Any time" />
          <span className="input-group-btn">
            <button className="btn btn-default">{setButtonText}</button>
          </span>
        </div>
      </DateRangePicker>
    )
  }

}
