import React, { Component } from 'react';
import * as newsNewPanelActions from 'client/redux/news/newPanelActions';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import NewsPanelForm from 'client/components/News/components/NewsPanelForm';
import { NEWS_PANEL_CREATE_SUCCESS } from 'client/redux/news/constants';
import { gritterAlertPush } from 'client/redux/gritter/actions';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';

class NewsNewPanel extends Component {

  static propTypes = {
    onSave: propTypes.func
  }


  handleCreate = fields => {
    const { onSave } = this.props;
    return this.props.newsNewPanelCreate(fields).then(getResponseToFormErrorHandler()).then(result => {
      if(result.type !== NEWS_PANEL_CREATE_SUCCESS) return;
      this.props.gritterAlertPush('news_create_success', {
        className: 'color success',
        title: 'Creation complete',
        message: 'The news item was created',
        closeAfter: 3000
      });
      return onSave();
    });
  }

  getInitialValues() {
    return {
      title: '',
      markdown: '',
      isPublished: false
    };
  }

  render() {
    const { error } = this.props;
    return (
      <div>
        <BeRemoteErrorAlert error={error} ignoreValidationResult={true} />
        <NewsPanelForm
          onSubmit={this.handleCreate}
          error={error}
          initialValues={this.getInitialValues()}
        />
      </div>
    );
  }

}

const actions = {
  ...newsNewPanelActions,
  gritterAlertPush
};

export default connect(state => state.news.newPanel, actions)(NewsNewPanel);
