import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { AppSparklinesBar, AppSparklinesLine } from 'client/components/Helpers/Sparklines';
import GRTDOptionsForm from 'client/components/GRTD/components/GRTDOptionsForm';
import GRTDTester from 'client/components/GRTD/components/GRTDTester';
import GRTDWidgetStatistic from 'client/components/GRTD/components/GRTDWidgetStatistic';
import InstanceWidgetPlan from 'client/components/Instance/components/InstanceWidgetPlan';
import InstanceWidgetControls from 'client/components/Instance/components/InstanceWidgetControls';
import InstanceDashboard from 'client/components/Instance/InstanceDashboard';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { instanceGrtdTestClear } from 'client/redux/grtd/testActions';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class GRTDModule extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    tab: propTypes.string.isRequired,
  }

  handleSelectTab = newTab => {
    if(newTab === this.props.tab) return;
    const instanceId = this.props.instanceId;
    this.props.push(`/instance/grtd/${instanceId}/${newTab}`);
  }

  componentWillUnmount() {
    // clear everything from the test when we unmount
    this.props.instanceGrtdTestClear();
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Microsoft Graph → TOPdesk</title>
        </Helmet>
        <MainContent>
          <InstanceParentAlert />
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <GRTDWidgetStatistic
                title="Users in sync"
                id={instanceId}
                statistic="graphUsersProcessed"
                query={{duration: 'day', limit: 7}}
                Component={AppSparklinesLine}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <GRTDWidgetStatistic
                title="Actions per month"
                id={instanceId}
                statistic="logSummer"
                query={{duration: 'month', limit: 7}}
                Component={AppSparklinesBar}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetPlan
                id={instanceId}
                shouldReadInstance={false}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetControls
                id={instanceId}
                shouldReadInstance={false}
              />
            </div>
          </div>
          <InstanceDashboard
            id={this.props.instanceId}
            tab={this.props.tab}
            OptionsFormComponent={GRTDOptionsForm}
            TesterComponent={GRTDTester}
            onSelectTab={this.handleSelectTab}
            endpointOrder={['graph', 'topdesk']}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  instanceGrtdTestClear,
  push,
};

export default connect(null, actions)(GRTDModule);
