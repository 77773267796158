import { render } from 'mustache';

export function getMustacheError (str) {
  try {
    render(str || '', {});
  } catch (err) {
    return err.message;
  }
  return false;
}
