import { ACCOUNT_CARD_ADD } from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isAdding: null
};

export default function cardAddReducer(state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_CARD_ADD, state, action, {requestProp: 'isAdding'});
  return state;
};
