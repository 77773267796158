export const INSTANCE_LOG_FRAGMENT_INDEX_REQUEST = 'INSTANCE_LOG_FRAGMENT_INDEX_REQUEST';
export const INSTANCE_LOG_FRAGMENT_INDEX_SUCCESS = 'INSTANCE_LOG_FRAGMENT_INDEX_SUCCESS';
export const INSTANCE_LOG_FRAGMENT_INDEX_FAILURE = 'INSTANCE_LOG_FRAGMENT_INDEX_FAILURE';
export const INSTANCE_LOG_FRAGMENT_INDEX = [INSTANCE_LOG_FRAGMENT_INDEX_REQUEST, INSTANCE_LOG_FRAGMENT_INDEX_SUCCESS, INSTANCE_LOG_FRAGMENT_INDEX_FAILURE];

export const INSTANCE_LOG_FRAGMENT_INDEX_ORDERBY = 'INSTANCE_LOG_FRAGMENT_INDEX_ORDERBY';

export const INSTANCE_LOG_FRAGMENT_INDEX_SET_PAGE = 'INSTANCE_LOG_FRAGMENT_INDEX_SET_PAGE';

export const INSTANCE_LOG_FRAGMENT_INDEX_FILTER = 'INSTANCE_LOG_FRAGMENT_INDEX_FILTER';

export const levelSelect2 = [
  {id: 'error', text: 'Error'},
  {id: 'warning', text: 'Warning'},
  {id: 'info', text: 'Info'},
  {id: 'success', text: 'Success'},
];
