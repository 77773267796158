import { INSTANCE_INDEX, INSTANCE_DELETE, INSTANCE_INDEX_SET_COLUMNS } from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import { reduceSetColumns } from 'client/redux/table/reducerHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isDeleting: false,
  instances: null,
  columns: [
    {id: 'select', name: 'Select', visible: false},
    {id: 'id', name: 'Type/ID', visible: true},
    {id: 'title', name: 'Title', visible: true},
    {id: 'status', name: 'Status', visible: true},
    {id: 'statusAt', name: 'Status at', visible: false},
    {id: 'createdAt', name: 'Created', visible: false},
    {id: 'updatedAt', name: 'Updated', visible: true},
    {id: 'userId', name: 'User', visible: true},
  ],
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_INDEX, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(INSTANCE_DELETE, state, action, {requestProp: 'isDeleting'});
  state = reduceSetColumns(INSTANCE_INDEX_SET_COLUMNS, state, action);
  return state;
};
