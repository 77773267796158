import { combineReducers } from 'redux';

import editPanel from 'client/redux/news/editPanelReducer';
import newPanel from 'client/redux/news/newPanelReducer';

const newsReducer = combineReducers({
  editPanel,
  newPanel
});

export default newsReducer;
