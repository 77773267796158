import React from 'react';
import propTypes from 'prop-types';
import { AlertWithMessage } from 'client/components/Be/Alert';
import { connect } from 'react-redux';

class InstanceParentAlert extends React.Component {

  static propTypes = {
    isParentInstance: propTypes.bool.isRequired,
  }

  render() {
    const { isParentInstance } = this.props;
    if (!isParentInstance) return null;
    return (
			<AlertWithMessage icon className="xs-mb-20" alertType="primary">
        <strong>Note:</strong> This instance belongs to your parent user account. Some features will be disabled.
			</AlertWithMessage>
    );
  }

}

const mapStateToProps = state => ({
  isParentInstance: state?.instance?.view?.isParentInstance === true,
});

export default connect(mapStateToProps)(InstanceParentAlert);
