import { SIGNUP } from 'client/redux/signup/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isWaiting: false,
  result: null,
  error: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(SIGNUP, state, action, {requestProp: 'isWaiting'});
  return state;
};
