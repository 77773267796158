import { combineReducers } from 'redux';

import index from 'client/redux/endpoint/indexReducer';
import logIndex from 'client/redux/endpoint/log/indexReducer';
import topdesk from 'client/redux/endpoint/topdesk/reducer';
import skatteverket from 'client/redux/endpoint/skatteverket/reducer';
import bankid from 'client/redux/endpoint/bankid/reducer';
import opmanager from 'client/redux/endpoint/opmanager/reducer';
import graph from 'client/redux/endpoint/graph/reducer';
import snow from 'client/redux/endpoint/snow/reducer';
import fast from 'client/redux/endpoint/fast/reducer';
import managedworkplace from 'client/redux/endpoint/managedworkplace/reducer';
import files from 'client/redux/endpoint/files/reducer';
import azureOpenAi from 'client/redux/endpoint/azureOpenAi/reducer';
import editPanel from 'client/redux/endpoint/editPanelReducer';

const endpointReducer = combineReducers({
  azureOpenAi,
  bankid,
  editPanel,
  fast,
  files,
  graph,
  index,
  logIndex,
  managedworkplace,
  opmanager,
  skatteverket,
  topdesk,
  snow,
});

export default endpointReducer;
