import React from 'react';
import propTypes from 'prop-types';
import EndpointTopdeskIdField from 'client/components/Endpoint/components/EndpointTopdeskIdField';

export default class EndpointTopdeskFieldArchivingReason extends React.Component {

  static propTypes = {
    base: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    endpointId: propTypes.string.isRequired,
    topdeskArchivingReasonId: propTypes.string,
    disabled: propTypes.bool.isRequired,
    helpText: propTypes.string,
    placeholderText: propTypes.string.isRequired,
  }

  render() {
    const {
      name,
      base,
      endpointId,
      topdeskArchivingReasonId,
      disabled,
      helpText,
      placeholderText,
    } = this.props;
    return (
      <EndpointTopdeskIdField
        base={base}
        name={name}
        searchForWhat="archivingReason"
        label="Archiving Reason"
        endpointId={endpointId}
        placeholderText={placeholderText}
        value={topdeskArchivingReasonId}
        disabled={disabled}
        helpText={helpText}
      />
    );
  }
}
