export const flash = (type, flashOptions) => {
  return {
    type,
    payload: flashOptions
  };
};

export const flashSeen = type => {
  return {type};
};
