import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';
import { withRouter, matchPath } from 'react-router';

class LeftSidebarItem extends Component {

  static defaultProps = {
    activeFor: []
  }

  static propTypes = {
    activeOnExact: propTypes.bool,
    location: propTypes.object.isRequired,
    icon: propTypes.string,
    title: propTypes.node,
    activeFor: propTypes.array
  }

  state = {
    isOpen: false
  }

  handleClickToggle = ev => {
    ev.preventDefault();
    this.setState({isOpen: !this.state.isOpen});
  }

  isActive() {
    const { to, activeOnExact, activeFor, location } = this.props;
    const tests = [to, ...activeFor];
    return tests.some(to => {
      const test = {path: to, exact: activeOnExact};
      return Boolean(matchPath(location.pathname, test));
    });
  }

  componentDidMount() {
    if(this.isActive()) {
      this.setState({isOpen: true});
    }
  }

  render() {
    const { to, children, title, icon } = this.props;
    const { isOpen } = this.state;
    const isActive = false && this.isActive();
    const iconClassNames = classNames('icon', 'mdi', icon);
    const liClassNames = classNames('parent', isActive ? 'active' : '', isOpen ? 'open' : '');
    return (
      <li className={liClassNames}>
        <a href={to} onClick={this.handleClickToggle}>
          <i className={iconClassNames}></i><span>{title}</span>
        </a>
        <Collapse in={isOpen}>
          <div>
            <ul style={{display: 'block'}} className="sub-menu">
              {children}
            </ul>
          </div>
        </Collapse>
      </li>
    )
  }

}

export default withRouter(LeftSidebarItem);
