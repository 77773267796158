import { ENDPOINT_FAST_READ, ENDPOINT_FAST_PRELOADED, ENDPOINT_FAST_TEST_CREDENTIALS, ENDPOINT_FAST_UPDATE_CREDENTIALS, ENDPOINT_FAST_EDIT_CREDENTIALS } from 'client/redux/endpoint/fast/constants';
import * as actionCreators from 'client/redux/endpoint/fast/actionCreators';
import { endpointRead, endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointFastPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(ENDPOINT_FAST_PRELOADED, endpoint));
};

export const endpointFastRead = endpointId => dispatch => {
  return dispatch(endpointRead(ENDPOINT_FAST_READ, endpointId, 'fast'));
};

export const endpointFastEditCredentials = isEditing => dispatch => {
  return dispatch(actionCreators.endpointFastEditCredentials(ENDPOINT_FAST_EDIT_CREDENTIALS, isEditing));
};

export const endpointFastUpdateCredentials = (id, credentials) => dispatch => {
  return dispatch(actionCreators.endpointFastUpdateCredentials(ENDPOINT_FAST_UPDATE_CREDENTIALS, id, credentials));
};

export const endpointFastTestCredentials = (id) => dispatch => {
  return dispatch(actionCreators.endpointFastTestCredentials(ENDPOINT_FAST_TEST_CREDENTIALS, id));
};
