export const ENDPOINT_FAST_PRELOADED = 'ENDPOINT_FAST_PRELOADED';

export const ENDPOINT_FAST_READ_REQUEST = 'ENDPOINT_FAST_READ_REQUEST';
export const ENDPOINT_FAST_READ_SUCCESS = 'ENDPOINT_FAST_READ_SUCCESS';
export const ENDPOINT_FAST_READ_FAILURE = 'ENDPOINT_FAST_READ_FAILURE';
export const ENDPOINT_FAST_READ = [ENDPOINT_FAST_READ_REQUEST, ENDPOINT_FAST_READ_SUCCESS, ENDPOINT_FAST_READ_FAILURE];

export const ENDPOINT_FAST_EDIT_CREDENTIALS = 'ENDPOINT_FAST_EDIT_CREDENTIALS';

export const ENDPOINT_FAST_UPDATE_CREDENTIALS_REQUEST = 'ENDPOINT_FAST_UPDATE_CREDENTIALS_REQUEST';
export const ENDPOINT_FAST_UPDATE_CREDENTIALS_SUCCESS = 'ENDPOINT_FAST_UPDATE_CREDENTIALS_SUCCESS';
export const ENDPOINT_FAST_UPDATE_CREDENTIALS_FAILURE = 'ENDPOINT_FAST_UPDATE_CREDENTIALS_FAILURE';
export const ENDPOINT_FAST_UPDATE_CREDENTIALS = [ENDPOINT_FAST_UPDATE_CREDENTIALS_REQUEST, ENDPOINT_FAST_UPDATE_CREDENTIALS_SUCCESS, ENDPOINT_FAST_UPDATE_CREDENTIALS_FAILURE];

export const ENDPOINT_FAST_TEST_CREDENTIALS_REQUEST = 'ENDPOINT_FAST_TEST_CREDENTIALS_REQUEST';
export const ENDPOINT_FAST_TEST_CREDENTIALS_SUCCESS = 'ENDPOINT_FAST_TEST_CREDENTIALS_SUCCESS';
export const ENDPOINT_FAST_TEST_CREDENTIALS_FAILURE = 'ENDPOINT_FAST_TEST_CREDENTIALS_FAILURE';
export const ENDPOINT_FAST_TEST_CREDENTIALS = [ENDPOINT_FAST_TEST_CREDENTIALS_REQUEST, ENDPOINT_FAST_TEST_CREDENTIALS_SUCCESS, ENDPOINT_FAST_TEST_CREDENTIALS_FAILURE];
