import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceOptdCall = (types, id, method, data = {}) => {
  return apiAction({
    endpoint: `/api/instance/optd/${id}/call/${method}`,
    method: 'POST',
    json: data,
    types
  });
};

export const instanceOptdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/optd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceOptdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/optd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceOptdTestAlarmAdd = (types, id, alarmId) => {
  const meta = {alarmId};
  return apiAction({
    endpoint: `/api/instance/optd/${id}/test/alarm/${alarmId}`,
    method: 'GET',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceOptdTestAlarm = (types, id, alarmId) => {
  const meta = {alarmId, sync: false};
  return apiAction({
    endpoint: `/api/instance/optd/${id}/test/alarm/${alarmId}`,
    method: 'POST',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceOptdSyncAlarm = (types, id, alarmId) => {
  const meta = {alarmId, sync: true};
  return apiAction({
    endpoint: `/api/instance/optd/${id}/sync/alarm/${alarmId}`,
    method: 'POST',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceOptdTestAlarmRemove = (type, alarmId) => {
  return {type, meta: {alarmId}};
};

export const instanceOptdTestClear = type => {
  return {type};
};

export const instanceOptdSearchFieldChange = (type, alarmId) => {
  return {type, alarmId};
};
