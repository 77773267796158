import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const userInvoiceIndex = (types, query = {}) => {
  return apiAction({
    endpoint: appendQuery('/api/user/invoice', query),
    method: 'GET',
    types,
  });
};

export const userInvoiceRead = (types, id, query = {}) => {
  return apiAction({
    endpoint: appendQuery(`/api/user/invoice/${id}`, query),
    method: 'GET',
    types,
  });
};

export const userInvoiceDelete = (types, id, query = {}) => {
  return apiAction({
    endpoint: appendQuery( `/api/user/invoice/${id}`, query),
    method: 'DELETE',
    types: types.map(type => ({type, meta: {id, query}})),
  });
};

export const userInvoiceReadForm = (type, form = {}) => {
  return {
    type,
    form,
  };
};
