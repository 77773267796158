export const INSTANCE_AITD_READ_STATISTICS_REQUEST = 'INSTANCE_AITD_READ_STATISTICS_REQUEST';
export const INSTANCE_AITD_READ_STATISTICS_SUCCESS = 'INSTANCE_AITD_READ_STATISTICS_SUCCESS';
export const INSTANCE_AITD_READ_STATISTICS_FAILURE = 'INSTANCE_AITD_READ_STATISTICS_FAILURE';
export const INSTANCE_AITD_READ_STATISTICS = [INSTANCE_AITD_READ_STATISTICS_REQUEST, INSTANCE_AITD_READ_STATISTICS_SUCCESS, INSTANCE_AITD_READ_STATISTICS_FAILURE];

export const INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_RESET = 'INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_RESET';

export const INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST = 'INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST';
export const INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS = 'INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS';
export const INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE = 'INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE';
export const INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ = [INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_REQUEST, INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_SUCCESS, INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ_FAILURE];

export const INSTANCE_AITD_OPTIONS_READ_META_REQUEST = 'INSTANCE_AITD_OPTIONS_READ_META_REQUEST';
export const INSTANCE_AITD_OPTIONS_READ_META_SUCCESS = 'INSTANCE_AITD_OPTIONS_READ_META_SUCCESS';
export const INSTANCE_AITD_OPTIONS_READ_META_FAILURE = 'INSTANCE_AITD_OPTIONS_READ_META_FAILURE';
export const INSTANCE_AITD_OPTIONS_READ_META = [INSTANCE_AITD_OPTIONS_READ_META_REQUEST, INSTANCE_AITD_OPTIONS_READ_META_SUCCESS, INSTANCE_AITD_OPTIONS_READ_META_FAILURE];

export const INSTANCE_AITD_TEST_INCIDENT_ADD_REQUEST = 'INSTANCE_AITD_TEST_INCIDENT_ADD_REQUEST';
export const INSTANCE_AITD_TEST_INCIDENT_ADD_SUCCESS = 'INSTANCE_AITD_TEST_INCIDENT_ADD_SUCCESS';
export const INSTANCE_AITD_TEST_INCIDENT_ADD_FAILURE = 'INSTANCE_AITD_TEST_INCIDENT_ADD_FAILURE';
export const INSTANCE_AITD_TEST_INCIDENT_ADD = [INSTANCE_AITD_TEST_INCIDENT_ADD_REQUEST, INSTANCE_AITD_TEST_INCIDENT_ADD_SUCCESS, INSTANCE_AITD_TEST_INCIDENT_ADD_FAILURE];

export const INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_REQUEST = 'INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_REQUEST';
export const INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_SUCCESS = 'INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_SUCCESS';
export const INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_FAILURE = 'INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_FAILURE';
export const INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH = [INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_REQUEST, INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_SUCCESS, INSTANCE_AITD_TEST_OBJECT_INCIDENT_OBJECTGRAPH_FAILURE];

export const INSTANCE_AITD_TEST_INCIDENT_REMOVE = 'INSTANCE_AITD_TEST_INCIDENT_REMOVE';
export const INSTANCE_AITD_TEST_CLEAR = 'INSTANCE_AITD_TEST_CLEAR';

export const INSTANCE_AITD_TEST_INCIDENT_REQUEST = 'INSTANCE_AITD_TEST_INCIDENT_REQUEST';
export const INSTANCE_AITD_TEST_INCIDENT_SUCCESS = 'INSTANCE_AITD_TEST_INCIDENT_SUCCESS';
export const INSTANCE_AITD_TEST_INCIDENT_FAILURE = 'INSTANCE_AITD_TEST_INCIDENT_FAILURE';
export const INSTANCE_AITD_TEST_INCIDENT = [INSTANCE_AITD_TEST_INCIDENT_REQUEST, INSTANCE_AITD_TEST_INCIDENT_SUCCESS, INSTANCE_AITD_TEST_INCIDENT_FAILURE];

export const INSTANCE_AITD_SYNC_INCIDENT_REQUEST = 'INSTANCE_AITD_SYNC_INCIDENT_REQUEST';
export const INSTANCE_AITD_SYNC_INCIDENT_SUCCESS = 'INSTANCE_AITD_SYNC_INCIDENT_SUCCESS';
export const INSTANCE_AITD_SYNC_INCIDENT_FAILURE = 'INSTANCE_AITD_SYNC_INCIDENT_FAILURE';
export const INSTANCE_AITD_SYNC_INCIDENT = [INSTANCE_AITD_SYNC_INCIDENT_REQUEST, INSTANCE_AITD_SYNC_INCIDENT_SUCCESS, INSTANCE_AITD_SYNC_INCIDENT_FAILURE];
