import {
  INSTANCE_FSTD_READ_TEST_FILE,
  INSTANCE_FSTD_DELETE_TEST_FILE,
  INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS,
  INSTANCE_FSTD_TEST_FILE_UPLOAD_ERROR,
} from 'client/redux/fstd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isDeleting: false,
  testFile: null,
  testFileError: null,
  error: null,
};

export default function testFileReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_FSTD_READ_TEST_FILE, state, action, {requestProp: 'isReading', successPickProps: ['testFile']});
  state = reduceByTypes(INSTANCE_FSTD_DELETE_TEST_FILE, state, action, {requestProp: 'isDeleting', successPickProps: ['testFile']});
  switch(action.type) {
    default: return state;
    case INSTANCE_FSTD_TEST_FILE_UPLOAD_ERROR: return {
      ...state,
      testFileError: action.error,
    };
    case INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS: return {
      ...state,
      testFile: action.testFile || null,
      testFileError: null,
    };
  }
};
