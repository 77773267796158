import React, { Component } from 'react';
import Icon from 'client/components/Be/Icon';
import { Form, FormSection, Field, reduxForm } from 'redux-form';
import { BeFormFieldErrors } from 'client/components/Be';
import { ButtonWaiting }  from 'client/components/Button/ButtonWaiting';
import AccountAddCardField from 'client/components/Account/components/AccountAddCardField';
import AccountAddressFormSection from 'client/components/Account/components/AccountAddressFormSection';
import propTypes from 'prop-types';
import 'client/components/Account/components/AccountAddCardForm.css';

class AccountAddCardForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired
  }

  render() {
    const { error, onSubmit, invalid, submitting } = this.props;
    return (
      <Form className="group-border-dashed account-add-card-form" onSubmit={this.props.handleSubmit(onSubmit)}>
        <div id="accountAddCardFormCountryDropdownParent"></div>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <Field name="accountAddCardFormCardField" component={AccountAddCardField} />
        <FormSection name="address">
          <AccountAddressFormSection />
        </FormSection>
        <div className="text-center">
          <ButtonWaiting type="submit" className="btn btn-primary btn-lg" isWaiting={submitting} disabled={invalid}>
            <Icon icon="lock" />{' '}
            Save card
          </ButtonWaiting>
        </div>
      </Form>
    );
  }

}

const validateForm = (values, props) => {
  const errors = {};
  if(values.accountAddCardFormCardField !== true) {
    errors.accountAddCardFormCardField = 'Card is not valid';
  }
  return errors;
}

export default reduxForm({
  form: 'accountAddCard',
  enableReinitialize: false,
  validate: validateForm
})(AccountAddCardForm);
