import React, { Component } from 'react';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import classNames from 'classnames';
import { typeToNatural } from 'client/redux/plan/constants';
import _ from 'lodash';
import propTypes from 'prop-types';
import 'client/components/Plans/PlanTable.css';

export class Pricing extends Component {

  static getPlanProps = plan => ({
    amount: _.get(plan, 'stripeObject.amount', 0),
    currency: _.get(plan, 'stripeObject.currency', ''),
    interval: _.get(plan, 'stripeObject.interval', '')
  })

  static getCurrencySign = currency => {
    switch(currency) {
      default:
      case 'eur': return '€';
      case 'usd': return '$';
    }
  }

  static propTypes = {
    plans: propTypes.array.isRequired,
    showPlan: propTypes.object.isRequired
  }

  getIntervalShort(interval) {
    return interval || '';
  }

  getAmount(amount) {
    return Math.round(amount / 100);
  }

  render() {
    const { showPlan } = this.props;
    const { amount, currency, interval } = Pricing.getPlanProps(showPlan);
    return (
      <div className="pricing-table-price">
        <span className="currency">{Pricing.getCurrencySign(currency)}</span>
        <span className="value">{this.getAmount(amount)}</span>
        <span className="frecuency">/{this.getIntervalShort(interval)}</span>
      </div>
    );
  }

}

export class Saving extends Component {

  static propTypes = {
    plans: propTypes.array.isRequired,
    showPlan: propTypes.object.isRequired,
  }

  // getSaving normalizes all plan prices to showPlan interval and compares the price of the showPlan to the first one
  // Normalized, the first one is expected to be the most expensive plan
  getSaving(currentProps) {
    const { plans } = this.props;
    const normalizedPrice = _.map(plans, comparePlan => {
      const compareProps = Pricing.getPlanProps(comparePlan)
      switch(true) {
        default: return compareProps.amount;
        case compareProps.interval === 'year' && currentProps.interval === 'month': return compareProps.amount/12;
        case compareProps.interval === 'month' && currentProps.interval === 'year': return compareProps.amount*12;
      }
    });

    // get the difference compared to current price, then the first choice
    return _.first(normalizedPrice) - currentProps.amount;
  }

  getBadgeClasses(saving = 0) {
    return classNames('badge', saving > 0 ? 'badge-success' : '');
  }

  render() {
    const { showPlan } = this.props;
    const currentProps = Pricing.getPlanProps(showPlan);
    const { interval } = currentProps;
    const saving = Math.max(0, this.getSaving(currentProps)/100);
    return (
      <div className="xs-mb-5">
        <span className={this.getBadgeClasses(saving)}>
          Saves {saving} {Pricing.getCurrencySign(currentProps.currency)} per {interval}
        </span>
      </div>
    );
  }

}

export class PlanTable extends Component {

  static propTypes = {
    Image: propTypes.elementType,
    colorName: propTypes.string,
    plans: propTypes.array.isRequired,
    onSelect: propTypes.func.isRequired,
    showInterval: propTypes.string.isRequired,
    text: propTypes.node,
    selectText: propTypes.node,
  }

  getPlan() {
    const { plans, showInterval } = this.props;
    return plans.find(plan => _.get(plan, 'stripeObject.interval') === showInterval);
  }

  handleSelect = ev => {
    const plan = this.getPlan();
    return this.props.onSelect(plan);
  }

  render() {
    const { className, colorName, Image, plans, selectText, text } = this.props;
    const plan = this.getPlan();
    if(!plan) return null;
    const { name, type } = plan;
    return (
      <div className={className}>
        <div className={classNames('pricing-table', `pricing-table-${colorName}`)}>
          <div className="pricing-table-image">
            <Image />
          </div>
          <div className="pricing-table-title">
            {typeToNatural(type)}
          </div>
          <div className="pricing-table-title-small">
            {name}
          </div>
          <div className="panel-divider panel-divider-xl"></div>
          <Saving plans={plans} showPlan={plan} />
          <Pricing plans={plans} showPlan={plan} />
          <p className="pricing-table-features">
            {text}
          </p>
          <ButtonWaitingPromise
            onClick={this.handleSelect}
            className={classNames('btn', `btn-${colorName}`)}>
            {selectText}
          </ButtonWaitingPromise>
        </div>
      </div>
    );
  }

}
