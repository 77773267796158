import { INSTANCE_FATDI_READ_STATISTICS } from 'client/redux/fatdi/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';
import _ from 'lodash';

const defaultStatistic = {
  isReading: false,
  error: null,
  data: null
};

const reduceOneStatistic = (state = defaultStatistic, action) => {
  state = reduceByTypes(INSTANCE_FATDI_READ_STATISTICS, state, action, {requestProp: 'isReading', successProp: 'data'});
  return state;
}

export default function statisticsReducer(state = {}, action) {
  if(INSTANCE_FATDI_READ_STATISTICS.includes(action.type)) {
    const id = _.get(action, 'meta.statistic');
    return {
      ...state,
      [id]: reduceOneStatistic(state[id], action),
    };
  }
  return state;
};
