import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { isSubscribableSelect2, typeSelect2 } from 'client/redux/plan/constants';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';
import { ConnectorIdSearcher } from 'client/components/Form/Select2Searchers';

class PlanPanelForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired
  }

  fieldProps = {
    base: 'planPanelForm',
    metaOptions: {defaultState: null, ignorePristine: true}
  }

  render() {
    const { handleSubmit, onSubmit, submitting, invalid, error } = this.props;
    const fieldProps = this.fieldProps;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <div>
          <Field
            {...fieldProps}
            name="isSubscribable"
            component={FormReduxSelect2}
            label="Subscribable"
            data={isSubscribableSelect2}
            select2ComponentProps={{unwrapValue: true}}
          />
          <Field
            {...fieldProps}
            name="type"
            component={FormReduxSelect2}
            label="Type"
            select2Options={{placeholder: 'No type defined'}}
            select2ComponentProps={{unwrapValue: true}}
            data={typeSelect2}
          />
          <Field
            {...fieldProps}
            name="connectorIds"
            component={FormReduxSelect2}
            select2ComponentProps={{multiple: true}}
            select2Component={ConnectorIdSearcher}
            select2Options={{dropdownParent: '#planPanelFormConnectorIdsDropdownParent'}}
            label="Connectors"
          />
          <div id="planPanelFormConnectorIdsDropdownParent"></div>
        </div>
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'planPanel',
  enableReinitialize: true
})(PlanPanelForm);
