import * as actionCreators from 'client/redux/uiFragment/actionCreators';
import { UI_FRAGMENTS_READ, UI_FRAGMENTS_UPDATE, UI_FRAGMENTS_CREATE } from 'client/redux/uiFragment/constants';

export const uiFragmentRead = id => dispatch => {
  return dispatch(actionCreators.uiFragmentRead(UI_FRAGMENTS_READ, id));
};

export const uiFragmentUpdate = (id, json) => dispatch => {
  return dispatch(actionCreators.uiFragmentUpdate(UI_FRAGMENTS_UPDATE, id, json));
};

export const uiFragmentCreate = (id, json) => dispatch => {
  return dispatch(actionCreators.uiFragmentCreate(UI_FRAGMENTS_CREATE, id, json));
};
