import { PLAN_INDEX, PLAN_INDEX_ORDERBY, PLAN_INDEX_SET_PAGE, PLAN_INDEX_FILTER, PLAN_INDEX_STRIPE_SYNC, PLAN_DELETE } from 'client/redux/plan/constants';
import * as actions from 'client/redux/plan/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const planIndex = query => dispatch => {
  return dispatch(actions.planIndex(PLAN_INDEX, query));
};

export const planDelete = id => dispatch => {
  return dispatch(actions.planDelete(PLAN_DELETE, id));
};

export const planIndexStripeSync = () => dispatch => {
  return dispatch(actions.planStripeSync(PLAN_INDEX_STRIPE_SYNC));
};

export const planIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(PLAN_INDEX_ORDERBY, orderBy, orderDirection));
};

export const planIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(PLAN_INDEX_SET_PAGE, page));
};

export const planIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(PLAN_INDEX_FILTER, filter));
};
