import React from 'react';
import propTypes from 'prop-types';
import { AlertDismissButton, AlertIcon } from 'client/components/Be/Alert';
import classNames from 'classnames'

export default class AlertControlled extends React.Component {

  static propTypes = {
    alertType: propTypes.oneOf(['success', 'primary', 'warning', 'danger']).isRequired,
    icon: propTypes.bool.isRequired,
    dismissed: propTypes.bool.isRequired,
    onDismiss: propTypes.func.isRequired,
  }

  static defaultProps = {
    alertType: 'primary',
    icon: false,
  }

  render() {
    const {
      children,
      alertType,
      className,
      icon,
      dismissed,
      onDismiss,
    } = this.props;
    const classes = classNames('alert', 'alert-' + alertType, className, icon ? 'alert-icon' : null);
    if(dismissed) return null;
    return (
      <div role="alert" className={classes}>
        {icon && <AlertIcon alertType={alertType} />}
        <div className="message">
          <AlertDismissButton onClick={onDismiss} />
          {children}
        </div>
      </div>
    );
  }

};
