import { NAVBAR_USER_SUPPORT_FORM_SHOW, NAVBAR_USER_SUPPORT_FORM_SUBMIT } from 'client/redux/navbar/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isOpen: false,
  isSubmitting: false,
  error: null,
};

export default function supportFormReducer(state = defaultState, action) {
  state = reduceByTypes(NAVBAR_USER_SUPPORT_FORM_SUBMIT, state, action, {requestProp: 'isSubmitting'});
  switch(action.type) {
    default: return state;
    case NAVBAR_USER_SUPPORT_FORM_SHOW: {
      const newState = {...state, isOpen: action.isOpen};
      return newState;
    }
  }
};
