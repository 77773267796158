import React from 'react';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import propTypes from 'prop-types';
import { BeWizardTitle } from 'client/components/Be/BeWizard';
import { instanceSettingsUpdate } from 'client/redux/instance/settingsActions';
import { instanceSettingsDisabled } from 'client/redux/instance/instanceHelpers';
import { unescapeDottedObject, escapeDottedObject } from 'client/redux/formHelpers';
import { connect } from 'react-redux';

class InstanceOptions extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    onNudgeStep: () => null,
    formComponentProps: {},
  }

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    instanceStatus: propTypes.string.isRequired,
    initialValues: propTypes.object.isRequired,
    endpointsByType: propTypes.object.isRequired,
    connectorType: propTypes.string.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    FormComponent: propTypes.elementType.isRequired,
    formComponentProps: propTypes.object,
    onNudgeStep: propTypes.func,
  }

  onSubmit = newSettings => {
    const unescapedNewSettings = unescapeDottedObject(newSettings);
    const { connectorType, instanceId } = this.props;
    return this.props.instanceSettingsUpdate(connectorType, instanceId, unescapedNewSettings).then(getResponseToFormErrorHandler());
  }

  getInitialValuesForFormComponent = () => {
    const { updatedSettings, updatedSettingsInstanceId, initialValues, instanceId } = this.props;
    if(updatedSettings && updatedSettingsInstanceId === instanceId) return escapeDottedObject(updatedSettings);
    return escapeDottedObject(initialValues);
  }

  render() {
    const {
      FormComponent,
      formComponentProps,
      isSetupMode,
      endpointsByType,
      isUpdating,
      onNudgeStep,
      instanceId,
      instanceStatus,
    } = this.props;
    return (
      <div className="group-border-dashed">
        <BeWizardTitle subTitle="Change settings to customize the connector. You can always change the settings later.">
          Options
        </BeWizardTitle>
        <FormComponent
          instanceId={instanceId}
          isSetupMode={isSetupMode}
          onSubmit={this.onSubmit}
          endpointsByType={endpointsByType}
          onNudgeStep={onNudgeStep}
          initialValues={this.getInitialValuesForFormComponent()}
          isUpdating={isUpdating}
          disabled={instanceSettingsDisabled(instanceStatus)}
          {...formComponentProps}
        />
      </div>
    );
  }

}

const actions = {
  instanceSettingsUpdate,
};

export default connect(state => state.instance.settings, actions)(InstanceOptions);
