import { UI_PROPS, UI_PROPS_SELF } from 'client/redux/ui/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  connectorInstancesByStatus: {},
  connectorsById: {},
  self: null,
  isWaiting: false,
  error: null
};

export default function reducer(state = defaultState, action) {
  state = reduceByTypes(UI_PROPS, state, action, {requestProp: 'isWaiting', successSpread: true});
  state = reduceByTypes(UI_PROPS_SELF, state, action, {requestProp: 'isWaiting', successProp: 'self'});
  return state;
};
