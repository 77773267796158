import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, Content } from 'client/components/App';
import BeLoading from 'client/components/Be/BeLoading';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import MarketplaceFilter from 'client/components/Marketplace/components/MarketplaceFilter';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { push } from 'connected-react-router';
import MarketplaceItem from 'client/components/Marketplace/components/MarketplaceItem';
import { marketplaceFilter, marketplaceIndex } from 'client/redux/marketplace/actions';
import { connectorInstantiate } from 'client/redux/connector/actions';
import { onConnectorInstantiate } from 'client/redux/connector/eventHandlerActions';
import 'client/components/Marketplace/Marketplace.css';

class Marketplace extends Component {

  static propTypes = {
    connectors: propTypes.array
  }

  componentDidMount() {
    this.props.marketplaceIndex();
  }

  handleFilter = newFilter => {
    this.props.marketplaceFilter(newFilter);
  }

  handleInstantiateConnector = connectorId => {
    return this.props.connectorInstantiate(connectorId).then(this.props.onConnectorInstantiate);
  }

  handleSelectPlanConnector = connectorId => {
    return this.props.push(`/instance/${connectorId}/plan`);
  }

  getFilteredConnectors() {
    const { connectors, filter } = this.props;
    const exp = new RegExp(filter.search || '', 'i');
    return connectors.filter(connector => {
      const categoryFn = cat => cat.id === filter.category || !filter.category;
      return connector.Categories.some(categoryFn) && exp.test(connector.title);
    });
  }

  renderMarketplaceItems() {
    const {
      instancesByConnector,
      instantiatingConnectorsById,
      uiFragmentsByConnector,
      connectorsWithSubscription,
      connectorsMaxInstances,
    } = this.props;

    return this.getFilteredConnectors().map(connector => {
      const { id } = connector;
      const instances = instancesByConnector[id];
      const isInstantiating = instantiatingConnectorsById[id];
      const uiFragmentHtml = uiFragmentsByConnector[id];
      const hasSubscription = connectorsWithSubscription[id];
      const maxInstances = connectorsMaxInstances ? connectorsMaxInstances[id] : 1;

      return (
        <MarketplaceItem
          className="marketplace-item"
          key={id}
          connector={connector}
          instances={instances}
          isInstantiating={isInstantiating}
          html={uiFragmentHtml}
          hasSubscription={hasSubscription}
          onInstantiate={this.handleInstantiateConnector}
          onSelectPlan={this.handleSelectPlanConnector}
          maxInstances={maxInstances}
        />
      );
    });
  }

  render() {
    const { error, categories, filter, isWaiting } = this.props;
    return (
      <MainContent>
        <Content>
          <BeRemoteErrorAlert error={error} />
          <Helmet>
            <title>Marketplace</title>
          </Helmet>
          <MarketplaceFilter categories={categories} onFilter={this.handleFilter} filter={filter} />
          <BeLoading loading={isWaiting} className="xs-mt-20">
            <div className="marketplace-item-list">
              {this.renderMarketplaceItems()}
            </div>
          </BeLoading>
        </Content>
      </MainContent>
    );
  }
}

const actions = {
  marketplaceFilter,
  marketplaceIndex,
  connectorInstantiate,
  onConnectorInstantiate,
  push 
};

export default connect(state => state.marketplace, actions)(Marketplace);
