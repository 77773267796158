import Select2SearchCreator from 'client/components/Form/Select2SearchCreator';

/**
 * @description Search a Connector
 */
export const ConnectorIdSearcher = Select2SearchCreator({
  apiUrl: '/api/connector/search',
  getSearchQuery: params => { return {term: params.data.term}; },
  responseToResult: response => {
    const connectors = response.connectors || [];
    const result = connectors.map(item => ({id: item.id, text: item.title || item.shortTitle || item.type}));
    return result;
  }
});

/**
 * @description Search Microsoft Graph objects by Endpoint
 */
export const EndpointGraphRemoteSearcher = Select2SearchCreator({
  apiUrl: props => `/api/endpoint/graph/${props.endpointId}/searchRemote/${props.forWhat}`,
  getSearchQuery: params => { return {term: params.data.term}; },
  responseToResult: (response, props) => {
    const list = response[props.forWhat] || [];
    return list.map(item => ({id: item.id, text: item.displayName || item.id}));
  }
});

/**
 * @description Search OpManager objects by Endpoint
 */
export const EndpointOpmanagerRemoteSearcher = Select2SearchCreator({
  apiUrl: props => `/api/endpoint/opmanager/${props.endpointId}/searchRemote/${props.forWhat}`,
  getSearchQuery: params => { return {name: params.data.term}; },
  responseToResult: (response, props) => {
    return response[props.forWhat] || [];
  }
});

/**
 * @description Search Snow objects by Endpoint
 */
export const EndpointSnowRemoteSearcher = Select2SearchCreator({
  apiUrl: props => `/api/endpoint/snow/${props.endpointId}/searchRemote/${props.forWhat}`,
  getSearchQuery: params => { return {name: params.data.term}; },
  responseToResult: (response, props) => {
    return response[props.forWhat] || [];
  }
});

/**
 * @description Search TOPdesk objects by Endpoint
 */
export const EndpointTopdeskRemoteSearcher = Select2SearchCreator({
  apiUrl: props => `/api/endpoint/topdesk/${props.endpointId}/searchRemote/${props.forWhat}`,
  getSearchQuery: (params, extraQuery = {}) => {
    return {name: params.data.term, ...extraQuery};
  },
  responseToResult: (response, props) => {
    const { forWhat } = props;
    const list = response[forWhat] || [];
    switch(forWhat) {
      default: return list.map(item => ({id: item.id, text: item.name || item.id}));
      case 'assetTemplate':
      case 'assetTemplateId':
      case 'assetDataSet': return list.map(({id, text}) => ({id, text}));
      case 'asset': return list.map(item => ({id: item.unid, text: item.name || item.unid}));
    }
  }
});

/**
 * @description Search Fast API objects by Endpoint
 */
export const EndpointFastRemoteSearcher = Select2SearchCreator({
  apiUrl: props => `/api/endpoint/fast/${props.endpointId}/searchRemote/${props.forWhat}`,
  getSearchQuery: params => { return {term: params.data.term}; },
  responseToResult: (response, props) => {
    const list = response[props.forWhat] || [];
    return list.map(item => {
      const tokens = [item.fullName, item.id, item.number];
      const text = tokens.filter(v => v).join(' /')
      return {id: item.id, text};
    });
  }
});

/**
 * @description Search a User
 */
export const UserIdSearcher = Select2SearchCreator({
  apiUrl: '/api/user/search',
  getSearchQuery: params => { return {term: params.data.term}; },
  responseToResult: response => {
    const users = response.users || [];
    return users.map(user => ({id: user.id, text: user.displayName || user.id}));
  }
});

/**
 * @description Search a Connector Instance
 */
export const ConnectorInstanceIdSearcher = Select2SearchCreator({
  apiUrl: '/api/instance/search',
  getSearchQuery: params => { return {id: params.data.term}; },
  responseToResult: response => {
    const instances = response.instances || [];
    return instances.map(instance => ({id: instance.id, text: instance.id}));
  }
});

/**
 * @description Search an Endpoint
 */
export const EndpointIdSearcher = Select2SearchCreator({
  apiUrl: '/api/endpoint/search',
  getSearchQuery: params => { return {id: params.data.term}; },
  responseToResult: response => {
    const endpoints = response.endpoints || [];
    return endpoints.map(endpoint => ({id: endpoint.id, text: endpoint.id}));
  }
});

export const EndpointTypeSearcher = Select2SearchCreator({
  apiUrl: '/api/endpoint/types',
  getSearchQuery: params => { return {type: params.data.term}; },
  responseToResult: response => {
    const { endpointTypes = [] } = response;
    return endpointTypes.map(type => ({id: type, text: type}));
  }
});
