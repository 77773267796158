import React, { Component } from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { levelSelect2 } from 'client/redux/endpoint/log/constants';
import DateRangePickerWrap from 'client/components/Form/DateRangePickerWrap';
import { EndpointIdSearcher } from 'client/components/Form/Select2Searchers';
import 'react-bootstrap-daterangepicker/css/daterangepicker.css';

export default class EndpointTableFilterForm extends Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  handleChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render() {
    const { level, type, timeStart, timeEnd, endpointId } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <DateRangePickerWrap
              startName="timeStart"
              startValue={timeStart}
              endName="timeEnd"
              endValue={timeEnd}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="endpointLogTableFilterLevel"
              name="level"
              className="form-control"
              data={levelSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any level'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={level}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <input
              id="endpointLogTableFilterType"
              type="text"
              className="form-control"
              name="type"
              onChange={this.handleChange}
              placeholder="Any type"
              value={type}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <EndpointIdSearcher
              id="endpointLogTableFilterEndpointId"
              className="form-control"
              name="endpointId"
              onChange={val => this.handleChange({target: {name: 'endpointId', value: val}})}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any endpoint'}}
              defaultValue={endpointId}
            />
          </div>
        </div>
      </div>
    );
  }

}
