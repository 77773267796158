import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class GRTDPanel extends Component {

  static propTypes = {
    onCall: propTypes.func.isRequired,
  }

  render() {
    const { onCall } = this.props;
    return (
      <div>
        <div>
          <ButtonWaitingPromise onClick={ev => onCall('sync')} type="button" className="btn btn-default">
            Synchronize
          </ButtonWaitingPromise>{' '}
          <ButtonWaitingPromise onClick={ev => onCall('resetGraphDeltaUsersNextUrl')} type="button" className="btn btn-default">
            Reset Graph DeltaUsers
          </ButtonWaitingPromise>{' '}
          <ButtonWaitingPromise onClick={ev => onCall('resetGraphDeltaGroupsNextUrl')} type="button" className="btn btn-default">
            Reset Graph DeltaGroups
          </ButtonWaitingPromise>
        </div>
      </div>
    );
  }

}
