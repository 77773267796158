import React, { Component, PureComponent } from 'react';
import propTypes from 'prop-types';
import { FormReduxSelect2, FormReduxInput } from 'client/components/Form/FormRedux';
import { isFilled, isString, isUUID } from 'client/redux/validators';
import UIFragmentHelpButton from 'client/components/UIFragment/UIFragmentHelpButton';
import { normalizeBoolean } from 'client/redux/formHelpers';
import { EndpointGraphRemoteSearcher } from 'client/components/Form/Select2Searchers';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import InstanceOptionsFormStateAlert from 'client/components/Instance/components/InstanceOptionsFormStateAlert';
import EndpointTopdeskFieldArchivingReason from 'client/components/Endpoint/components/EndpointTopdeskFieldArchivingReason';
import _ from 'lodash';
import GRTDOptionsFormFieldMap from 'client/components/GRTD/components/GRTDOptionsFormFieldMap';
import Sticky from 'react-stickynode';

const validators = {
  isNonEmptyString: [isString(), isFilled()],
  isFilledUUID: [isString(), isFilled(), isUUID()],
  isUUID: [isString(), isUUID()]
};

class GRTDOptionsForm extends Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false
  }

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    currentFormValues: propTypes.object,
    endpointsByType: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    onNudgeStep: propTypes.func,
  }

  handleReset = ev => {
    this.props.reset();
  }

  render() {
    const { dirty, disabled, invalid, isSetupMode, onSubmit, onNudgeStep, isUpdating } = this.props;
		return (
      <Form id="instanceGrtdForm" onSubmit={this.props.handleSubmit(onSubmit)}>
        <Sticky top={81} bottom="#instanceGrtdForm" innerZ={1000}>
          <InstanceOptionsFormStateAlert
            dirty={dirty}
            disabled={disabled}
            invalid={invalid}
            isSetupMode={isSetupMode}
            onNudgeStep={onNudgeStep}
            onReset={this.handleReset}
            isUpdating={isUpdating}
          />
        </Sticky>
        {this.renderGraphFilterSettings()}
        {this.renderAdvancedSettings()}
      </Form>
		);
  }

  endpointIds() {
    const { topdesk: {id: topdeskId}, graph: {id: graphId} } = this.props.endpointsByType;
    return {topdeskId, graphId};
  }

  renderGraphFilterSettings() {
    const { currentFormValues, disabled } = this.props;
    const { graph } = this.props.endpointsByType;
    const { graphFilterForPerson, graphFilterForOperator } = this.props.initialValues;
    return (
      <div className="well xs-mt-20">
        <div className="row">
          <div className="col-md-6">
            <h3 className="wizard-title xs-mb-20">
              Microsoft Graph Person filter
              <UIFragmentHelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                fragmentId="instance_grtd_settings_graph_person_filter_help"
              />
              <span className="panel-subtitle">Set which users in Graph should be created and updated as TOPdesk Persons</span>
            </h3>
            <GRTDOptionsGraphFilterGroup
              endpointId={graph.id}
              settingName="graphFilterForPerson"
              settingValue={graphFilterForPerson || {}}
              disabled={disabled}
              currentType={_.get(currentFormValues, 'graphFilterForPerson.type')}
              typeData={[
                {id: 'noone', text: 'Do not create or update Persons'},
                {id: 'everyone', text: 'Everyone becomes a Person'},
                {id: 'byGroupMembership', text: 'Only members of a specific Graph group'},
                {id: 'byFieldValue', text: 'Only Graph users with a special field value'},
              ]}
            />
          </div>
          <div className="col-md-6">
            <h3 className="wizard-title xs-mb-20">
              Microsoft Graph Operator filter
              <UIFragmentHelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                fragmentId="instance_grtd_settings_graph_operator_filter_help"
              />
              <span className="panel-subtitle">Set which users in Graph should be created and updated as TOPdesk Operators</span>
            </h3>
            <GRTDOptionsGraphFilterGroup
              endpointId={graph.id}
              settingName="graphFilterForOperator"
              settingValue={graphFilterForOperator || {}}
              disabled={disabled}
              currentType={_.get(currentFormValues, 'graphFilterForOperator.type')}
              typeData={[
                {id: 'noone', text: 'Do not create or update Operators'},
                {id: 'everyone', text: 'Everyone becomes a Operator'},
                {id: 'byGroupMembership', text: 'Only members of a specific Graph group'},
                {id: 'byFieldValue', text: 'Only Graph users with a special field value'},
              ]}
            />
          </div>
        </div>
      </div>
    );
  }

  renderAdvancedSettings() {
    const {
      disabled,
      instanceId,
      endpointsByType: { topdesk },
      initialValues: {
        topdeskArchivingReasonId,
        topdeskPersonKeyField,
        topdeskOperatorKeyField,
        setPersonManager,
      },
    } = this.props;
    return (
      <div className="well">
        <h3 className="wizard-title xs-mb-20">
          Advanced settings
          <UIFragmentHelpButton
            className="btn btn-rounded btn-default btn-xs pull-right"
            fragmentId="instance_grtd_settings_advanced_help"
          />
          <span className="panel-subtitle">Change these settings to tune the connector exactly how you want it</span>
        </h3>
        <EndpointTopdeskFieldArchivingReason
          base="grtdOptionsTopdeskArchivingReason"
          endpointId={topdesk.id}
          topdeskArchivingReasonId={topdeskArchivingReasonId}
          disabled={disabled}
          name="topdeskArchivingReasonId"
          placeholderText="Use the first available archiving reason"
          helpText="Set the reason given when TOPdesk Operators or Persons are archived"
        />
        <hr />
        <div className="row">
          <div className="col-md-6">
            <h3 className="wizard-title xs-mb-20">
              TOPdesk Person Key Field
              <UIFragmentHelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                fragmentId="instance_grtd_settings_advanced_key_field"
              />
              <span className="panel-subtitle">Set which field is used to find existing Persons on the first sync</span>
            </h3>
            <GRTDOptionsKeyField
              name="topdeskPersonKeyField"
              disabled={disabled}
              defaultValue={topdeskPersonKeyField}
              data={[
                {id: 'email', text: 'Email'},
                {id: 'networkLoginName', text: 'Network Login Name'},
                {id: 'tasLoginName', text: 'Tas Login Name'},
              ]}
            />
          </div>
          <div className="col-md-6">
            <h3 className="wizard-title xs-mb-20">
              TOPdesk Operator Key Field
              <UIFragmentHelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                fragmentId="instance_grtd_settings_advanced_key_field"
              />
              <span className="panel-subtitle">Set which field is used to find existing Operators on the first sync</span>
            </h3>
            <GRTDOptionsKeyField
              name="topdeskOperatorKeyField"
              disabled={disabled}
              defaultValue={topdeskOperatorKeyField}
              data={[
                {id: 'email', text: 'Email'},
                {id: 'loginName', text: 'Login Name'},
              ]}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <h3 className="wizard-title xs-mb-20">
              TOPdesk Person Manager
              <UIFragmentHelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                fragmentId="instance_grtd_settings_advanced_set_person_manager"
              />
              <span className="panel-subtitle">Set if Microsoft Graph managers should become managers in TOPdesk</span>
            </h3>
            <Field
              base="grtdOptionsSetPersonManager"
              name="setPersonManager"
              component={FormReduxSelect2}
              data={[
                {id: false, text: 'Managers will not be set in TOPdesk'},
                {id: true, text: 'Managers in Graph will manage the same Persons in TOPdesk'},
              ]}
              defaultValue={setPersonManager || ''}
              normalize={normalizeBoolean}
              disabled={disabled}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <GRTDOptionsFormFieldMap
            disabled={disabled}
            instanceId={instanceId}
            endpointIds={this.endpointIds()}
          />
        </div>
      </div>
    );
  }
}

class GRTDOptionsGraphFilterGroup extends PureComponent {

  static propTypes = {
    settingName: propTypes.string.isRequired,
    settingValue: propTypes.object,
    endpointId: propTypes.string.isRequired,
    disabled: propTypes.bool.isRequired,
    currentType: propTypes.string,
    typeData: propTypes.array.isRequired
  }

  maybeRenderGroupId() {
    const { endpointId, disabled, currentType, settingName } = this.props;
    const { groupId } = this.props.settingValue;
    if(currentType !== 'byGroupMembership') return null;
    return (
      <Field
        base={`grtdOptionsGraphFilter${settingName}`}
        component={FormReduxSelect2}
        select2Component={EndpointGraphRemoteSearcher}
        select2ComponentProps={{forWhat: 'group', endpointId}}
        label="Set which Graph group"
        select2Options={{minimumInputLength: 1, placeholder: 'Search group name starting with'}}
        name={`${settingName}.groupId`}
        defaultValue={groupId}
        disabled={disabled}
        validate={validators.isFilledUUID}
      />
    );
  }

  maybeRenderFieldSelector() {
    const { disabled, currentType, settingName } = this.props;
    const { fieldName, fieldValue } = this.props.settingValue;
    // TODO This method causes a value/defaultValue error in console
    if(currentType !== 'byFieldValue') return null;
    return (
      <div>
        <Field
          base={`grtdOptionsGraphFilter${settingName}`}
          component={FormReduxInput}
          label="Field name"
          name={`${settingName}.fieldName`}
          defaultValue={fieldName || ''}
          disabled={disabled}
          required={true}
          helpText="You can use dot-notation to separate nested objects"
          validate={validators.isNonEmptyString}
        />
        <Field
          base={`grtdOptionsGraphFilter${settingName}`}
          component={FormReduxInput}
          label="Field value"
          name={`${settingName}.fieldValue`}
          defaultValue={fieldValue || ''}
          disabled={disabled}
          required={true}
          helpText="Enter the exact required string or a regular expression (advanced)"
          validate={validators.isNonEmptyString}
        />
      </div>
    );
  }

  render() {
    const { disabled, typeData, settingName } = this.props;
    const { type } = this.props.settingValue;
    return (
      <div>
        <Field
          base={`grtdOptionsGraphFilter${settingName}`}
          name={`${settingName}.type`}
          component={FormReduxSelect2}
          data={typeData}
          defaultValue={type}
          disabled={disabled}
        />
        {this.maybeRenderGroupId()}
        {this.maybeRenderFieldSelector()}
      </div>
    );
  }

}

class GRTDOptionsKeyField extends React.Component {

  static propTypes = {
    name: propTypes.string.isRequired,
    disabled: propTypes.bool.isRequired,
    data: propTypes.array.isRequired,
    defaultValue: propTypes.string.isRequired,
  }

  render() {
    const { name, disabled, data, defaultValue } = this.props;
    return (
      <Field
        base={`grtdOptionsTopdeskKeyField${name}`}
        name={name}
        component={FormReduxSelect2}
        data={data}
        defaultValue={defaultValue}
        disabled={disabled}
        required={true}
      />
    );
  }

}

function mapStateToProps(state) {
  const selector = formValueSelector('grtdOptions');
  // the value of these fields need to be available when rendering
  const fields = [
    'graphFilterForOperator.type',
    'graphFilterForPerson.type',
  ];
  return {
    currentFormValues: selector(state, ...fields)
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'grtdOptions',
  enableReinitialize: true,
})(GRTDOptionsForm));
