import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import NoticeRow from 'client/components/Helpers/NoticeRow';

export default class OPTDTesterAlarm extends Component {

  static propTypes = {
    id: propTypes.string,
    alarm: propTypes.object,
    onRemove: propTypes.func,
    onTest: propTypes.func,
    onSync: propTypes.func
  }

  handleClickTest = ev => {
    this.props.onTest(this.props.id);
  }

  handleClickSync = ev => {
    this.props.onSync(this.props.id);
  }

  handleClickRemove = ev => {
    this.props.onRemove(this.props.id);
  }

  renderNoticeTableRows(notices) {
    const alarm = this.props.alarm || {};
    if(!notices || !notices.length) {
      return (
        <tr>
          <td colSpan="3">
            <em>No action taken with this alarm</em>
          </td>
        </tr>
      );
    }

    const { opManagerAlarm: { entity:subject }, wasLast } = alarm;
    const futureTense = wasLast !== 'synced';

    return notices.map((notice, index) => {
      return (
        <NoticeRow
          key={index}
          notice={notice}
          subject={subject}
          futureTense={futureTense}
        />
      );
    });
  }

  renderNoticeTable() {
    const { alarm } = this.props;
    const { notices, wasLast, error } = alarm;
    if(!wasLast && !error) return null;
    return (
      <div className="list-group-item-text">
        <div className="row">
          <table className="table table-condensed table-striped xs-mb-0">
            <thead>
              <tr>
                <th width="15%">Action taken</th>
                <th width="60%">Description</th>
                <th width="25%"></th>
              </tr>
            </thead>
            <tbody>
              {this.renderNoticeTableRows(notices)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { alarm } = this.props;
    if(!alarm) return null;
    const { opManagerAlarm, error } = alarm;
    return (
      <div className="list-group-item xs-pb-0">
        <h4 className="list-group-item-heading xs-mb-15 xs-mt-0">
          <div className="name pull-left" style={{lineHeight: '30px'}}>
            <i className="icon mdi mdi-account xs-mr-5"></i>
            {opManagerAlarm.entity}
          </div>
          <span className="btn-group pull-right">
            <ButtonWaiting type="button" onClick={this.handleClickSync} isWaiting={alarm.isSyncing} className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-flash"></i>
              Sync
            </ButtonWaiting>
            <button onClick={this.handleClickRemove} type="button" className="btn btn-rounded btn-default">
              <i className="icon icon-left mdi mdi-delete"></i>
              Remove from test
            </button>
          </span>
          <div className="clearfix"></div>
        </h4>
        <BeRemoteErrorAlert error={error} />
        {this.renderNoticeTable()}
      </div>
    );
  }

};

