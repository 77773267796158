import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class FATDPanel extends Component {

  static propTypes = {
    onCall: propTypes.func.isRequired,
  }

  render() {
    const { onCall } = this.props;
    return (
      <div>
        <div>
          <ButtonWaitingPromise onClick={ev => onCall('sync')} type="button" className="btn btn-default">
            Synchronize
          </ButtonWaitingPromise>{' '}
        </div>
        <hr />
        <div>
          <div className="btn-group-vertical btn-space">
            <ButtonWaitingPromise onClick={ev => onCall('resetPartnerLastChangedTimestamp')} type="button" className="btn btn-default">
              Reset "Partner Last Changed" timestamp
            </ButtonWaitingPromise>{' '}
            <ButtonWaitingPromise onClick={ev => onCall('resetLeaseContractSinceEndedTimestamp')} type="button" className="btn btn-default">
              Reset "Lease Contract Since" Ended timestamp
            </ButtonWaitingPromise>
          </div>
        </div>
      </div>
    );
  }

}
