import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { AppSparklinesBar } from 'client/components/Helpers/Sparklines';
import OPTDWidgetStatistic from 'client/components/OPTD/components/OPTDWidgetStatistic';
import OPTDOptionsForm from 'client/components/OPTD/components/OPTDOptionsForm';
import OPTDTester from 'client/components/OPTD/components/OPTDTester';
import InstanceWidgetPlan from 'client/components/Instance/components/InstanceWidgetPlan';
import InstanceWidgetControls from 'client/components/Instance/components/InstanceWidgetControls';
import InstanceDashboard from 'client/components/Instance/InstanceDashboard';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { instanceOptdTestClear } from 'client/redux/optd/testActions';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class OPTDModule extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    tab: propTypes.string.isRequired,
  }

  handleSelectTab = newTab => {
    if(newTab === this.props.tab) return;
    const instanceId = this.props.instanceId;
    this.props.push(`/instance/optd/${instanceId}/${newTab}`);
  }

  componentWillUnmount() {
    // clear everything from the test when we unmount
    this.props.instanceOptdTestClear();
  }

  render() {
    const instanceId = this.props.instanceId;
    return (
      <Content>
        <Helmet>
          <title>OpManager → TOPdesk</title>
        </Helmet>
        <MainContent>
          <InstanceParentAlert />
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <OPTDWidgetStatistic
                title="Actions per month"
                id={instanceId}
                statistic="logSummer"
                query={{duration: 'month', limit: 7}}
                Component={AppSparklinesBar}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <OPTDWidgetStatistic
                title="Number of tasks in queue"
                id={instanceId}
                statistic="messagesInOwnQueue"
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetPlan
                id={instanceId}
                shouldReadInstance={false}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetControls
                id={instanceId}
                shouldReadInstance={false}
                hasDeleteQueueContentsButton
              />
            </div>
          </div>
          <InstanceDashboard
            id={this.props.instanceId}
            tab={this.props.tab}
            OptionsFormComponent={OPTDOptionsForm}
            TesterComponent={OPTDTester}
            onSelectTab={this.handleSelectTab}
            endpointOrder={['opmanager', 'topdesk']}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  push,
  instanceOptdTestClear,
};

export default connect(null, actions)(OPTDModule);
