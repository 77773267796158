import React from 'react';
import propTypes from 'prop-types';
import QRTDEditModal from 'client/components/QRTD/QRTDEditModal';
import { QRCode } from 'react-qr-svg';
import { Modal } from 'react-bootstrap';
import * as qrtdHelpers from 'client/helpers/qrtdHelpers';
import { getNumberOfRows } from 'client/helpers/misc';
import { codeSettingsShape } from 'client/components/QRTD/shapes'
import _ from 'lodash';

// todo make sure the right amount of codes end up on the print page
const qrCodesPerPageToWidth = {
  64: 75,
  49: 92,
  36: 108,
  25: 120,
  16: 150,
  9: 183,
  4: 300,
  2: 450,
  1: 600,
};

export function MustacheHelpBlock (props) {
  return (
    <span className="help-block">
      Entered text must be valid Mustache syntax.{' '}
      <a href="https://mustache.github.io/mustache.5.html" target="_blank" rel="noopener noreferrer">
        Mustache guide
      </a>.{' '}
      <a href="https://www.markdownguide.org/" target="_blank" rel="noopener noreferrer">
        Markdown guide
      </a>.
    </span>
  );
}

export class QRTDURLGenerator extends React.Component {

  static propTypes = {
    children: propTypes.elementType.isRequired,
    instanceId: propTypes.string.isRequired,
    assetId: propTypes.string,
    locationId: propTypes.string,
    signingKey: propTypes.string,
    signedUrlsIsRequired: propTypes.bool.isRequired,
    codeSettings: codeSettingsShape.isRequired,
  }

  static defaultProps = {
    codeSettings: {},
  }

  state = {
    url: null,
    isHashingSignature: false,
  }

  generateUrl() {
    const {
      instanceId,
      assetId,
      locationId,
      signedUrlsIsRequired,
      signingKey,
      codeSettings,
    } = this.props;

    const urlProps = {
      instanceId,
      assetId,
      locationId,
      settings: _.pick(codeSettings, 'features', 'fieldMapOrdinal', 'infoTextOrdinal'),
    };
    const url = qrtdHelpers.getUrl(process.env.REACT_APP_QR_URL_BASE, 2, urlProps);
    if(!signedUrlsIsRequired || !signingKey) {
      this.setState({isHashingSignature: false, url});
    } else {
      this.setState({
        isHashingSignature: true,
        url: null,
      }, () => {
        qrtdHelpers.signUrl(signingKey, url).then(url => {
          this.setState({isHashingSignature: false, url});
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const newSignatureKeys = [
      'instanceId',
      'assetId',
      'locationId',
      'signingKey',
      'signedUrlsIsRequired',
      'codeSettings',
    ];
    if(newSignatureKeys.some(key => this.props[key] !== prevProps[key])) {
      this.generateUrl();
    }
  }

  componentDidMount() {
    this.generateUrl();
  }

  render() {
    const { url, isHashingSignature } = this.state;
    const { children } = this.props;
    if(!url || isHashingSignature) return null;
    return (
      <>
        {children(url)}
      </>
    );
  }

}

export class QRTDQrCodePlaceholder extends React.Component {

  static propTypes = {
    render: propTypes.bool.isRequired,
    value: propTypes.string,
    onClickEditButton: propTypes.func.isRequired,
    onClickShowButton: propTypes.func.isRequired,
    bgColor: propTypes.string.isRequired,
    fgColor: propTypes.string.isRequired,
  }

  static defaultProps = {
    render: false,
  }

  render() {
    const {render, style, onClickShowButton, onClickEditButton, value, ...props} = this.props;
    if(!render) return (
      <div className="qr-dummy" style={style}>
        <div className="btn-group">
          <button className="btn btn-default" onClick={onClickShowButton}>Show</button>
          <button className="btn btn-default" onClick={onClickEditButton}>Edit</button>
          <a className="btn btn-default" href={value} rel="noopener noreferrer" target="_blank">Open</a>
        </div>
      </div>
    );
    return (
      <QRCode {...props} style={style} value={value} />
    );
  }

}

export class QRTDPrintCode extends React.Component {

  static propTypes = {
    code: propTypes.object.isRequired,
    codeSettings: codeSettingsShape.isRequired,
    infoTexts: propTypes.array.isRequired,
    incidentFieldMaps: propTypes.array.isRequired,
    onUpdateCodeSettings: propTypes.func.isRequired,
    renderQrCode: propTypes.bool.isRequired,
    size: propTypes.number.isRequired,
    url: propTypes.string.isRequired,
  }

  state = {
    // topText: '',
    // bottomText: '',
    showModalOpen: false,
    editModalOpen: false,
  }

  handleClickShowQrCode = () => {
    this.setState({showModalOpen: true});
  }

  handleCloseShowModal = () => {
    this.setState({showModalOpen: false});
  }

  handleClickEditQrCode = () => {
    this.setState({editModalOpen: true});
  }

  handleCloseEditModal = () => {
    this.setState({editModalOpen: false});
  }

  handleUpdateCodeSettings = codeSettings => {
    const { code: { id } } = this.props;
    return this.props.onUpdateCodeSettings(id, codeSettings);
  }

  handleChangeStrings = ev => {
    const { name, value } = ev.target;
    const { code } = this.props;
    const codeType = getCodeType(code);
    const interpValue = value ? interpolateText(code[codeType], value) : undefined;
    return this.handleUpdateCodeSettings({[name]: interpValue});
  }

  render() {
    const {
      renderQrCode,
      codeSettings,
      size,
      code,
      url,
      infoTexts,
      incidentFieldMaps,
    } = this.props;
    const {
      topTextShow,
      bottomTextShow,
      qrCodeBgColor,
      qrCodeFgColor,
      qrCodeBgExtend
     } = codeSettings;

    const { showModalOpen, editModalOpen } = this.state;
    const width = `${qrCodesPerPageToWidth[size]}px`;
    const height = `${qrCodesPerPageToWidth[size]}px`;
    const containerStyle = qrCodeBgExtend ? {background: qrCodeBgColor, color: qrCodeFgColor} : {};
    return (
      <div className="qrtd-print-page-qr-code-container" style={containerStyle}>
        <QRTDShowModal
          open={showModalOpen}
          url={url}
          onClose={this.handleCloseShowModal}
        />
        <QRTDEditModal
          codeId={code.id}
          open={editModalOpen}
          settings={codeSettings || {}}
          onClose={this.handleCloseEditModal}
          onUpdate={this.handleUpdateCodeSettings}
          incidentFieldMaps={incidentFieldMaps}
          infoTexts={infoTexts}
          codeType={getCodeType(code)}
        />
        {topTextShow && (
          <input
            name="topText"
            value={codeSettings.topText}
            onChange={this.handleChangeStrings}
          />
        )}
        <QRTDQrCodePlaceholder
          render={renderQrCode}
          value={url}
          style={{width, height}} 
          bgColor={qrCodeBgColor}
          fgColor={qrCodeFgColor}
          onClickEditButton={this.handleClickEditQrCode}
          onClickShowButton={this.handleClickShowQrCode}
        />
        {bottomTextShow && this.renderBottomText()}
      </div>
    );
  }

  renderBottomText() {
    const { codeSettings } = this.props;
    const value = codeSettings.bottomText || '';
    const rows = getNumberOfRows(value);
    return (
      <textarea
        name="bottomText"
        onChange={this.handleChangeStrings}
        rows={rows}
        value={value}
      />
    );
  }

}

export class QRTDShowModal extends React.Component {

  static propTypes = {
    url: propTypes.string.isRequired,
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
  }

  render() {
    const { open, url, onClose } = this.props;
    // note: this modal should NOT be static due to no close button
    return (
      <Modal show={open} onHide={onClose}>
        <Modal.Body className="qrtd-show-modal-body">
          <div className="text-center xs-pt-20">
            <QRCode value={url} style={{width: '256px', height: '256px'}} />
            <div className="link-container xs-mt-10">
              <a rel="noopener noreferrer" target="_blank" href={url}>{url}</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

export function interpolateText(object, str = '') {
  return str.replace(/(%\w+?%)/g, match => {
    const token = match.slice(1, -1);
    return _.get(object, token, '');
  });
}

export function getCodeType (code = {}) {
  if (code.asset) return 'asset';
  if (code.location) return 'location';
  return 'empty';
}
