import React, { Component } from 'react';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { BeFormFieldErrors } from 'client/components/Be';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { isFilled, isString } from 'client/redux/validators';
import { FormReduxInput } from 'client/components/Form/FormRedux';

const validateFilledString = [isString(), isFilled()];

class ResetPasswordForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired
  }

  render() {
    const metaOptions = {ignorePristine: true, showSuccess: true};
    return (
			<Form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <div className="form-group">
          <BeFormFieldErrors className="xs-mb-10" errors={this.props.error} />
        </div>
        <div className="row">
          <div className="col-xs-6">
            <Field
              base="resetPasswordForm"
              name="passwordOne"
              component={FormReduxInput}
              type="password"
              placeholder="Password"
              metaOptions={metaOptions}
              validate={validateFilledString}
            />
          </div>
          <div className="col-xs-6">
            <Field
              base="resetPasswordForm"
              name="passwordTwo"
              component={FormReduxInput}
              type="password"
              placeholder="Confirm"
              metaOptions={metaOptions}
              validate={validateFilledString}
            />
          </div>
        </div>
        <div className="form-group xs-pt-5">
          <ButtonWaiting type="submit" disabled={!this.props.valid} className="btn-primary btn-xl btn-block" isWaiting={this.props.submitting}>Change Password</ButtonWaiting>
        </div>
			</Form>
    );
  }

};

const validateForm = (values, props) => {
  const errors = {};
  if(props.pristine) return errors;
  if(values.passwordOne !== values.passwordTwo) {
    errors.passwordTwo = 'Does not match';
  }
  return errors;
}

export default reduxForm({
  form: 'resetPassword',
  validate: validateForm
})(ResetPasswordForm);
