import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceSwtdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/swtd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceSwtdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/swtd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceSwtdTestComputerAdd = (types, id, computerId) => {
  const meta = {computerId};
  return apiAction({
    endpoint: `/api/instance/swtd/${id}/test/computer/${computerId}`,
    method: 'GET',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceSwtdTestObjectGraph = (types, id, snowComputerId) => {
  const meta = {computerId: snowComputerId};
  return apiAction({
    endpoint: `/api/instance/swtd/${id}/test/computer/${snowComputerId}/objectGraph`,
    method: 'GET',
    types: types.map(type => ({type, meta})),
    options: { timeout: 600000 },
  });
};

export const instanceSwtdSyncComputer = (types, id, computerId) => {
  const meta = {computerId, sync: true};
  return apiAction({
    endpoint: `/api/instance/swtd/${id}/sync/computer/${computerId}`,
    method: 'POST',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceSwtdTestComputerRemove = (type, computerId) => {
  return {type, meta: {computerId}};
};

export const instanceSwtdTestClear = type => {
  return {type};
};
