import React, { Component } from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import classNames from 'classnames';

export class FormErrors extends Component {

  static propTypes = {
    ignorePristine: propTypes.bool,
    pristine: propTypes.bool,
    error: propTypes.oneOfType([propTypes.string, propTypes.object]),
  }

  static defaultProps = {
    ignorePristine: true,
    pristine: false,
  }

  renderErrors(error) {
    if(!Array.isArray(error)) error = [error];
    return error.map((error, index) => {
      return (
        <li key={index}>
          {_.isPlainObject(error) ? JSON.stringify(error) : error}
        </li>
      );
    });
  }

  render() {
    const { error, className, ignorePristine, pristine } = this.props;
    if(!error || !error.length) return null;
    if(pristine && ignorePristine) return null;
    const classes = classNames('parsley-errors-list', 'filled', className || '');
    return (
      <ul className={classes}>
        {this.renderErrors(error)}
      </ul>
    );
  }

};
