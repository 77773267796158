import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { StripeTime } from 'client/components/Helpers/Time';
import { instanceStatusToClass, instanceStatusToName } from 'client/redux/instance/instanceHelpers';
import _ from 'lodash';

export const ConnectorInstanceStatusLabel = props => {
  const { status } = props;
  const statusClass = instanceStatusToClass(status);
  const statusName = instanceStatusToName(status);
  return <span className={getClass(props, statusClass)}>{statusName}</span>
};

export const EndpointStatusLabel = props => {
  const { status } = props;
  switch(status) {
    default: return null;
    case 'setup': return <span className={getClass(props, 'primary')}>Setup</span>;
    case 'error': return <span className={getClass(props, 'danger')}>Error</span>;
    case 'ready': return <span className={getClass(props, 'success')}>Ready</span>;
  }
};

export const UserSubscriptionStatusLabel = props => {
  const { status, cancelAtPeriodEnd = false, currentPeriodEnd = null } = props;
  switch(status) {
    default: return null;
    case 'trialing': return <span className={getClass(props, 'primary')}>Trialing</span>;
    case 'incomplete': return <span className={getClass(props, 'warning')}>Action required</span>;
    case 'incomplete_expired': return <span className={getClass(props, 'danger')}>Action expired</span>;
    case 'past_due': return <span className={getClass(props, 'danger')}>Payment past due</span>;
    case 'unpaid': return <span className={getClass(props, 'danger')}>Unpaid</span>;
    case 'canceled': return <span className={getClass(props, 'danger')}>Canceled</span>;
    case 'active': {
      if(cancelAtPeriodEnd && currentPeriodEnd) return <span className={getClass(props, 'warning')}>Active until <StripeTime time={currentPeriodEnd} format="YYYY-MM-DD" /></span>;
      return <span className={getClass(props, 'success')}>Active</span>;
    }
  }
};

export const UserInvoiceStatusLabel = props => {
  const object = _.get(props, 'invoice.stripeObject', {});
  const { paid, forgiven, closed, attempted, due_date:dueDate, billing, amount_due:amountDue } = object;
  if(amountDue <= 0) return <span className={getClass(props, 'success', 'invoice-status')}>Nothing due</span>;
  if(paid) return <span className={getClass(props, 'success', 'invoice-status')}>Paid</span>;
  if(forgiven) return <span className={getClass(props, 'success', 'invoice-status')}>Forgiven</span>;
  if(closed) return <span className={getClass(props, 'primary', 'invoice-status')}>Closed</span>;
  if(billing === 'send_invoice' && dueDate) {
    const isOverdue = moment.unix(dueDate).utc().isBefore(moment());
    if(isOverdue) return <span className={getClass(props, 'danger', 'invoice-status')}>Overdue</span>;
  }
  if(attempted) return <span className={getClass(props, 'danger', 'invoice-status')}>Payment attempted</span>;
  return <span className={getClass(props, 'warning', 'invoice-status')}>Not yet paid</span>;
};

function getClass(props, modifier, moreClasses = []) {
  const { base = 'label', className } = props;
  return classNames(base, `${base}-${modifier}`, className, moreClasses);
}
