import { PLAN_INDEX, PLAN_INDEX_ORDERBY, PLAN_INDEX_SET_PAGE, PLAN_INDEX_FILTER, PLAN_INDEX_STRIPE_SYNC } from 'client/redux/plan/constants';
import { reduceTableActions } from 'client/redux/table/reducerHelpers';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isSyncing: false,
  plans: null,
  filter: {isSubscribable: '', interval: '', type: ''},
  page: 1,
  orderBy: 'updatedAt',
  orderDirection: 'DESC'
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(PLAN_INDEX, state, action, {requestProp: 'isReading', successPickProps: ['plans', 'count', 'limit', 'offset', 'page', 'pages']});
  state = reduceByTypes(PLAN_INDEX_STRIPE_SYNC, state, action, {requestProp: 'isSyncing', successPickProps: []});
  state = reduceTableActions([PLAN_INDEX_FILTER, PLAN_INDEX_SET_PAGE, PLAN_INDEX_ORDERBY], state, action);
  return state;
};
