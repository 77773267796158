import React, { Component } from 'react';
import Icon from 'client/components/Be/Icon';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { ModalSimple, ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import AccountAddCard from 'client/components/Account/components/AccountAddCard';
import BeLoading from 'client/components/Be/BeLoading';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { accountCardList, accountCardDelete } from 'client/redux/account/cardActions';
import propTypes from 'prop-types';
import { gritterAlertPush } from 'client/redux/gritter/actions';

class AccountCards extends Component {

  static propTypes = {
    cards: propTypes.array
  }

  state = {
    addModalOpen: false
  }

  getData = () => {
    return this.props.accountCardList();
  }

  componentDidMount() {
    this.getData();
  }

  handleDeleteCard = id => {
    return this.props.accountCardDelete(id).then(this.getData);
  }

  handleCardAdded = () => {
    this.handleCloseModal();
    this.props.gritterAlertPush('account_add_card_success', {
      className: 'color success',
      title: 'Success',
      message: `A new card has been added`,
      closeAfter: 5000
    });
    return this.getData();
  }

  handleOpenModal = () => {
    this.setState({addModalOpen: true});
  }

  handleCloseModal = () => {
    this.setState({addModalOpen: false});
  }

  renderCards(cards = []) {
    return cards.map((card, index) => {
      const { id, name, exp_year, exp_month, last4 } = card;
      return (
        <span key={index} className="list-group-item">
          <span className="mdi mdi-card icon"></span>
          {name} xxxx-{last4} (Expires {exp_month}/{exp_year})
          <span className="pull-right">
            <ModalConfirmButton className="btn btn-xs btn-rounded btn-default" callback={() => this.handleDeleteCard(id)} contentProps={{title: 'Confirm card deletion', text: 'Are you sure you want to delete the card?'}} Content={ModalContentConfirmWarning}>
              <i className="icon xs-mr-0 mdi mdi-delete"></i>
            </ModalConfirmButton>
          </span>
        </span>
      );
    });
  }

  render() {
    const { addModalOpen } = this.state;
    const { cards, isReading, isDeleting, error } = this.props;
    const shouldRenderList = cards && cards.length > 0;
    return (
      <div>
        <BeRemoteErrorAlert error={error} />
        <BeLoading positionStatic={true} loading={isReading || isDeleting}>
          {shouldRenderList && <div className="list-group">{this.renderCards(cards)}</div>}
        </BeLoading>
        <div className="text-center">
          <button className="btn btn-primary btn-lg" onClick={this.handleOpenModal}>
            <Icon icon="plus-circle-o" />{' '}
            Add card
          </button>
          <ModalSimple title="Add a new card" dialogClassName="colored-header colored-header-primary" isOpen={addModalOpen} onClose={this.handleCloseModal}>
            <Elements locale="en">
              <AccountAddCard onAdd={this.handleCardAdded} />
            </Elements>
          </ModalSimple>
        </div>
      </div>
    );
  }

}

const actions = {
  accountCardList,
  accountCardDelete,
  gritterAlertPush
};

export default connect(state => state.account.card, actions)(AccountCards);
