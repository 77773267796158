import React, { Component } from 'react';
import * as settingsPanelActions from 'client/redux/settings/panelActions';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import classNames from 'classnames';
import _ from 'lodash';

class SettingsPanel extends Component {

  static propTypes = {
    id: propTypes.string.isRequired
  }

  state = {
    error: false
  }

  handleUpdate = ev => {
    const value = this.validateValue();
    if(!value) return;
    return this.props.settingsPanelUpdate(this.props.id, value);
  }

  validateValue = () => {
    const value = _.get(this.state, 'value', _.get(this.props, 'settings.value', '{}'));
    let str;
    try {
      str = JSON.parse(value);
    } catch(err) {
      this.setState({error: true});
      return;
    }
    this.setState({error: false});
    return str;
  }

  handleFieldChange = ev => {
    this.setState({[ev.target.name]: ev.target.value});
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.settingsPanelRead(id);
  }

  renderForm() {
    const { setting } = this.props;
    const defaultValue = JSON.stringify(setting.value || {}, null, 2);
    return (
      <div className={classNames('form-group', this.state.error ? 'has-error': '')}>
        <textarea
          className="form-control"
          name="value"
          defaultValue={defaultValue}
          value={this.state.value}
          onChange={this.handleFieldChange}
          onBlur={this.validateValue}
          style={{fontFamily: 'monospace'}}
          rows="10"
        />
      </div>
    )
  }

  render() {
    const { setting, isUpdating, isReading, error } = this.props;
    if(!setting || isReading) return null;
    return (
      <div>
        <BeRemoteErrorAlert error={error} />
        {this.renderForm()}
        <hr />
        <div className="text-right">
          <ButtonWaiting onClick={this.handleUpdate} type="button" className="btn btn-default" isWaiting={isUpdating}>
            Save
          </ButtonWaiting>
        </div>
      </div>
    );
  }

}

export default connect(state => state.settings.panel, settingsPanelActions)(SettingsPanel);
