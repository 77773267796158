import React from 'react';
import { BeWizard, BeWizardStep } from 'client/components/Be/BeWizard';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import InstanceSetupStepDone from 'client/components/Instance/components/InstanceSetupStepDone';
import InstanceEndpoints from 'client/components/Instance/InstanceEndpoints';
import InstanceOptions from 'client/components/Instance/InstanceOptions';
import { push } from 'connected-react-router';
import { onInstanceSetupActivate } from 'client/redux/instance/eventHandlerActions';
import * as instanceSetupActions from 'client/redux/instance/setupActions';
import * as wizardHelpers from 'client/redux/wizardHelpers';
import { connect } from 'react-redux';
import InstanceTrialAlert from 'client/components/Instance/components/InstanceTrialAlert';
import propTypes from 'prop-types';

class InstanceSetup extends React.Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    connector: propTypes.object,
    wizard: propTypes.object,
    instance: propTypes.object,
    subscription: propTypes.object,
    endpointsByType: propTypes.object,
    endpointOrder: propTypes.array,
    OptionsFormComponent: propTypes.elementType,
    optionsFormComponentProps: propTypes.object,
    TesterComponent: propTypes.elementType,
    stepComponents: propTypes.object,
  }

  getData(id) {
    return this.props.instanceSetupRead(id);
  }

  componentDidMount() {
    this.getData(this.props.id);
  }

  componentDidUpdate (oldProps) {
    if(this.props.id !== oldProps.id) {
      this.getData(this.props.id);
    }
  }

  handleEndpointUpdated = () => {
    return this.getData(this.props.id);
  }

  handleStepChange = activeStepKey => {
    const { id } = this.props;
    const update = {activeStepKey};
    if(activeStepKey === 'test') update.hasSeenTest = true;
    if(activeStepKey === 'options') update.hasSeenOptions = true;
    if(activeStepKey === 'fieldMapper') update.hasSeenFieldMapper = true;
    if(activeStepKey === 'qrCodeCreator') update.hasSeenQrCodeCreator = true;
    return this.props.instanceSetupUpdate(id, update);
  }

  handleNudgeStep = direction => {
    const { wizard } = this.props;
    const nextStep = wizardHelpers.getNudgeStep(wizard, direction);
    if(nextStep) return this.handleStepChange(nextStep.key);
  }

  handleActivate = () => {
    const { id, connector: { type } } = this.props;
    return this.props.instanceSetupUpdateStatus(id, 'active').then(onInstanceSetupActivate).then(() => this.props.push(`/instance/${type}/${id}`));
  }

  renderStepByKey(key) {
    const {
      instance,
      endpointsByType,
      connector,
      OptionsFormComponent,
      optionsFormComponentProps,
      TesterComponent,
      stepComponents = {},
      endpointOrder,
      optionalEndpoints,
    } = this.props;
    if(!instance || !connector || !endpointsByType) return null;

    // properties common to most steps
    const props = {
      instance,
      endpointsByType,
      isSetupMode: true,
    };

    switch(key) {
      default: {
        const stepComponentCreator = stepComponents[key];
        if(!stepComponentCreator) return <div>Nothing here yet</div>;
        return stepComponentCreator(props);
      }
      case 'auth': return <InstanceEndpoints
        {...props}
        endpointOrder={endpointOrder}
        optionalEndpoints={optionalEndpoints}
        onEndpointUpdated={this.handleEndpointUpdated}
      />
      case 'options': return <InstanceOptions
        instanceId={instance.id}
        instanceStatus={instance.status}
        initialValues={instance.settings}
        connectorType={connector.type}
        endpointsByType={endpointsByType}
        FormComponent={OptionsFormComponent}
        formComponentProps={optionsFormComponentProps}
        onNudgeStep={this.handleNudgeStep}
        isSetupMode={true}
      />
      case 'test': return TesterComponent ? <TesterComponent {...props} /> : null;
      case 'done': return <InstanceSetupStepDone onActivate={this.handleActivate} />
    }
  }

  renderSteps() {
    const { wizard: { orderedSteps, activeStepKey } } = this.props;
    return orderedSteps.map(step => {
      const { ord, key } = step;
      return (
        <BeWizardStep key={key} stepKey={key} stepOrd={ord} activeStepKey={activeStepKey} onStepChange={this.handleStepChange}>
          {activeStepKey === key && this.renderStepByKey(key)}
        </BeWizardStep>
      );
    });
  }

  renderInstance() {
    const { id, wizard, error, instance, subscription } = this.props;
    if(!instance || !instance.id) return null;
    return (
      <div>
        <InstanceTrialAlert subscription={subscription} instanceStatus={instance.status} />
        <BeWizard id={id} {...wizard} onStepChange={this.handleStepChange}>
          <BeRemoteErrorAlert error={error} />
          {this.renderSteps()}
        </BeWizard>
      </div>
    );
  }

  render() {
    const { isReading } = this.props;
		return (
      <BeLoading positionStatic={true} loading={isReading}>
        {this.renderInstance()}
      </BeLoading>
		);
  }
}

const actions = {
  ...instanceSetupActions,
  onInstanceSetupActivate,
  push,
};

export default connect(state => state.instance.setup, actions)(InstanceSetup);
