import { combineReducers } from 'redux';

import options from 'client/redux/aitd/optionsReducer';
import statistics from 'client/redux/aitd/statisticsReducer';
import test from 'client/redux/aitd/testReducer';

const aitdReducer = combineReducers({
  options,
  statistics,
  test,
});

export default aitdReducer;
