import { UI_FRAGMENTS_INDEX, UI_FRAGMENTS_INDEX_DELETE } from 'client/redux/uiFragment/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  fragments: null
};

export default function indexReducer(state = defaultState, action) {
  state = reduceByTypes(UI_FRAGMENTS_INDEX, state, action, {
    requestProp: 'isReading',
    successSpread: true,
    successPickProps: ['fragments']
  });
  state = reduceByTypes(UI_FRAGMENTS_INDEX_DELETE, state, action, {
    requestProp: 'isDeleting',
    successPickProps: []
  });
  return state;
};
