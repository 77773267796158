export const INSTANCE_QRTD_READ_STATISTICS_REQUEST = 'INSTANCE_QRTD_READ_STATISTICS_REQUEST';
export const INSTANCE_QRTD_READ_STATISTICS_SUCCESS = 'INSTANCE_QRTD_READ_STATISTICS_SUCCESS';
export const INSTANCE_QRTD_READ_STATISTICS_FAILURE = 'INSTANCE_QRTD_READ_STATISTICS_FAILURE';
export const INSTANCE_QRTD_READ_STATISTICS = [INSTANCE_QRTD_READ_STATISTICS_REQUEST, INSTANCE_QRTD_READ_STATISTICS_SUCCESS, INSTANCE_QRTD_READ_STATISTICS_FAILURE];

export const INSTANCE_QRTD_OPTIONS_READ_META_REQUEST = 'INSTANCE_QRTD_OPTIONS_READ_META_REQUEST';
export const INSTANCE_QRTD_OPTIONS_READ_META_SUCCESS = 'INSTANCE_QRTD_OPTIONS_READ_META_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_READ_META_FAILURE = 'INSTANCE_QRTD_OPTIONS_READ_META_FAILURE';
export const INSTANCE_QRTD_OPTIONS_READ_META = [INSTANCE_QRTD_OPTIONS_READ_META_REQUEST, INSTANCE_QRTD_OPTIONS_READ_META_SUCCESS, INSTANCE_QRTD_OPTIONS_READ_META_FAILURE];

export const INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_REQUEST = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_REQUEST';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_FAILURE = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_FAILURE';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE = [INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_REQUEST, INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS, INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_FAILURE];

export const INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_REQUEST = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_REQUEST';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_FAILURE = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_FAILURE';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE = [INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_REQUEST, INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS, INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_FAILURE];

export const INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_REQUEST = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_REQUEST';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_SUCCESS = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_FAILURE = 'INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_FAILURE';
export const INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE = [INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_REQUEST, INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_SUCCESS, INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE_FAILURE];

export const INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_REQUEST = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_REQUEST';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_SUCCESS = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_FAILURE = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_FAILURE';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_READ = [INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_REQUEST, INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_SUCCESS, INSTANCE_QRTD_OPTIONS_INFOTEXT_READ_FAILURE];

export const INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_REQUEST = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_REQUEST';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_SUCCESS = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_FAILURE = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_FAILURE';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE = [INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_REQUEST, INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_SUCCESS, INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE_FAILURE];

export const INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_REQUEST = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_REQUEST';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_SUCCESS = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_FAILURE = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_FAILURE';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE = [INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_REQUEST, INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_SUCCESS, INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE_FAILURE];

export const INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_REQUEST = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_REQUEST';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_SUCCESS = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_SUCCESS';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_FAILURE = 'INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_FAILURE';
export const INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE = [INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_REQUEST, INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_SUCCESS, INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE_FAILURE];

export const INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_REQUEST = 'INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_REQUEST';
export const INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_SUCCESS = 'INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_SUCCESS';
export const INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_FAILURE = 'INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_FAILURE';
export const INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS = [INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_REQUEST, INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_SUCCESS, INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS_FAILURE];

export const INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_REQUEST = 'INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_REQUEST';
export const INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_SUCCESS = 'INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_SUCCESS';
export const INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_FAILURE = 'INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_FAILURE';
export const INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS = [INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_REQUEST, INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_SUCCESS, INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS_FAILURE];

export const INSTANCE_QRTD_QRCODECREATOR_ASSET_FILTER = 'INSTANCE_QRTD_QRCODECREATOR_ASSET_FILTER';
export const INSTANCE_QRTD_QRCODECREATOR_ASSET_SET_SELECTED_IDS = 'INSTANCE_QRTD_QRCODECREATOR_ASSET_SET_SELECTED_IDS';
export const INSTANCE_QRTD_QRCODECREATOR_LOCATION_FILTER = 'INSTANCE_QRTD_QRCODECREATOR_LOCATION_FILTER';
export const INSTANCE_QRTD_QRCODECREATOR_LOCATION_SET_SELECTED_IDS = 'INSTANCE_QRTD_QRCODECREATOR_LOCATION_SET_SELECTED_IDS';

export const INSTANCE_QRTD_QRCODECREATOR_SET_TAB = 'INSTANCE_QRTD_QRCODECREATOR_SET_TAB';

export const INSTANCE_QRTD_PRINT_PAGE_CREATE_CODE = 'INSTANCE_QRTD_PRINT_PAGE_CREATE_CODE';

export const INSTANCE_QRTD_PRINT_PAGE_UPDATE_SETTING = 'INSTANCE_QRTD_PRINT_PAGE_UPDATE_SETTING';
export const INSTANCE_QRTD_PRINT_PAGE_UPDATE_CODE_PAGE_SETTING = 'INSTANCE_QRTD_PRINT_PAGE_UPDATE_CODE_PAGE_SETTING';

export const INSTANCE_QRTD_PRINT_PAGE_UPDATE_CODE_SETTING = 'INSTANCE_QRTD_PRINT_PAGE_UPDATE_CODE_SETTING';

export const INSTANCE_QRTD_OPTIONS_SET_SELECTED_FIELD_MAP_ORDINAL = 'INSTANCE_QRTD_OPTIONS_SET_SELECTED_FIELD_MAP_ORDINAL';

export const INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_REQUEST = 'INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_REQUEST';
export const INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_SUCCESS = 'INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_SUCCESS';
export const INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_FAILURE = 'INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_FAILURE';
export const INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META = [INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_REQUEST, INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_SUCCESS, INSTANCE_QRTD_PRINT_PAGE_READ_INSTANCE_META_FAILURE];

export const INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_REQUEST = 'INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_REQUEST';
export const INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_SUCCESS = 'INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_SUCCESS';
export const INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_FAILURE = 'INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_FAILURE';
export const INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS = [INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_REQUEST, INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_SUCCESS, INSTANCE_QRTD_PRINT_PAGE_READ_ASSETS_FAILURE];

export const INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_REQUEST = 'INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_REQUEST';
export const INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_SUCCESS = 'INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_SUCCESS';
export const INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_FAILURE = 'INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_FAILURE';
export const INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS = [INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_REQUEST, INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_SUCCESS, INSTANCE_QRTD_PRINT_PAGE_READ_LOCATIONS_FAILURE];

export const INSTANCE_QRTD_IMAGE_UPLOAD_READ_REQUEST = 'INSTANCE_QRTD_IMAGE_READ_REQUEST';
export const INSTANCE_QRTD_IMAGE_UPLOAD_READ_SUCCESS = 'INSTANCE_QRTD_IMAGE_READ_SUCCESS';
export const INSTANCE_QRTD_IMAGE_UPLOAD_READ_FAILURE = 'INSTANCE_QRTD_IMAGE_READ_FAILURE';
export const INSTANCE_QRTD_IMAGE_UPLOAD_READ = [INSTANCE_QRTD_IMAGE_UPLOAD_READ_REQUEST, INSTANCE_QRTD_IMAGE_UPLOAD_READ_SUCCESS, INSTANCE_QRTD_IMAGE_UPLOAD_READ_FAILURE];

export const INSTANCE_QRTD_IMAGE_UPLOAD_DELETE_REQUEST = 'INSTANCE_QRTD_IMAGE_DELETE_REQUEST';
export const INSTANCE_QRTD_IMAGE_UPLOAD_DELETE_SUCCESS = 'INSTANCE_QRTD_IMAGE_DELETE_SUCCESS';
export const INSTANCE_QRTD_IMAGE_UPLOAD_DELETE_FAILURE = 'INSTANCE_QRTD_IMAGE_DELETE_FAILURE';
export const INSTANCE_QRTD_IMAGE_UPLOAD_DELETE = [INSTANCE_QRTD_IMAGE_UPLOAD_DELETE_REQUEST, INSTANCE_QRTD_IMAGE_UPLOAD_DELETE_SUCCESS, INSTANCE_QRTD_IMAGE_UPLOAD_DELETE_FAILURE];

export const INSTANCE_QRTD_IMAGE_UPLOAD_SUCCESS = 'INSTANCE_QRTD_IMAGE_UPLOAD_SUCCESS';
export const INSTANCE_QRTD_IMAGE_UPLOAD_ERROR = 'INSTANCE_QRTD_IMAGE_UPLOAD_ERROR';

export const INSTANCE_QRTD_PRINT_PAGE_SET_CHOSEN_IDS = 'INSTANCE_QRTD_PRINT_PAGE_SET_CHOSEN_IDS';

export const qrCodeFeatures = [
  {value: 'info', label: 'Info'},
  {value: 'reportIncident', label: 'Report incident'},
  {value: 'listIncidents', label: 'List incidents'},
  {value: 'listLinkedAssets', label: 'List linked assets'},
  {value: 'reportIncidentForLinkedAsset', label: 'Report incident for linked assets'},
  {value: 'knowledgeBase', label: 'Knowledge base (WIP)', wip: true},
  {value: 'operationsManagement', label: 'Operations Management (WIP)', wip: true},
];

export const qrCodePrintSizesSelect2 = [
  {id: 2, text: '2 QR-codes per page'},
  {id: 4, text: '4 QR-codes per page'},
  {id: 9, text: '9 QR-codes per page'},
];

export const qrPageTabs = ['info', 'reportIncident', 'listIncidents', 'listLinkedAssets'].map(value => {
  const feature = qrCodeFeatures.find(o => o.value === value) || {};
  return {
    value,
    label: feature.label,
    wip: feature.wip || false,
  };
});
