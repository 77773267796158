import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import _ from 'lodash';
import BeLoading from 'client/components/Be/BeLoading';
import QRTDCodeCreatorAssetTableFilter from 'client/components/QRTD/QRTDCodeCreatorAssetTableFilter';
import { ModalObjectInspectorButton } from 'client/components/Helpers/Modal';
import {
  TableCheckbox,
  TableCheckboxManagerControlled,
  TablePaginationCursor,
} from 'client/components/Helpers/Table';

class QRTDCodeCreatorAssetTable extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    assets: propTypes.array.isRequired,
    templates: propTypes.array.isRequired,
    loading: propTypes.bool.isRequired,
    filter: propTypes.object.isRequired,
    onSetPage: propTypes.func.isRequired,
    endpointTopdeskId: propTypes.string.isRequired,
  }

  static defaultProps = {
    assets: [],
    templates: [],
  }

  render() {
    const {
      assets,
      templates,
      className,
      handleSelectAll,
      handleSelectRow,
      isAllSelected,
      isRowSelected,
      loading,
      onSetPage,
      onFilter,
      filter,
      endpointTopdeskId,
    } = this.props;
    return (
      <div className={classNames('panel-table', className)}>
        <div className="panel-heading panel-heading-divider">
          Assets in TOPdesk
        </div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <QRTDCodeCreatorAssetTableFilter
              templates={templates}
              onFilter={onFilter}
              filter={filter}
              endpointTopdeskId={endpointTopdeskId}
            />
            <div className="be-datatable-body no-overflow">
              <BeLoading loading={loading}>
                <table className="table table-striped table-hover dataTable">
                  <thead>
                    <tr>
                      <th>
                        <TableCheckbox
                          id="qrCodeCreatorTableSelectAllAssets"
                          onSelectAll={handleSelectAll}
                          isSelected={isAllSelected()}
                          beagle={false}
                        />
                      </th>
                      <th>Name/ID</th>
                      <th>Type</th>
                      <th>Summary</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="no-border-x">
                    {assets.length < 1 && <TableEmptyRow numCols={5} />}
                    {assets.length > 0 && assets.map((asset, index) => (
                      <TableRow
                        key={asset.unid}
                        tabIndex={index + 1}
                        asset={asset}
                        templates={templates}
                        handleSelectRow={handleSelectRow}
                        isRowSelected={isRowSelected}
                      />
                    ))}
                  </tbody>
                </table>
              </BeLoading>
              <QRTDCodeCreatorAssetTableFooter
                assets={assets}
                onFilter={onFilter}
                filter={filter}
                onSetPage={onSetPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function TableEmptyRow({numCols}) {
  return (
    <tr>
      <td className="empty" colSpan={numCols}>
        No assets to show
      </td>
    </tr>
  );
}

function TableRow({asset, templates, handleSelectRow, isRowSelected, tabIndex}) {
  return (
    <tr key={asset.unid}>
      <td>
        <TableCheckbox
          base="qrCodeCreatorTableSelectAllAssets"
          id={asset.unid}
          onSelect={handleSelectRow}
          isSelected={isRowSelected(asset.unid)}
          tabIndex={tabIndex}
          beagle={false}
        />
      </td>
      <td>{asset.name}</td>
      <AssetTemplateCell asset={asset} templates={templates}/>
      <td>{asset['@@summary']}</td>
      <td className="text-right">
        <ModalObjectInspectorButton
          object={asset}
          title={asset.name}
        />
      </td>
    </tr>
  );
}

function AssetTemplateCell({asset, templates}) {
  const typeId = _.get(asset, 'type_id');
  const template = templates.find(({id}) => id === typeId);
  return (
    <td>
      {template && (<span>{template.text}</span>)}
      {!template && (<span>{typeId}</span>)}
    </td>
  );
}

class QRTDCodeCreatorAssetTableFooter extends React.Component {

  onChangeLimit = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render () {
    const { assets, onSetPage, filter: { limit } } = this.props;
    const lastValueInSet = getLastAssetName(assets);
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-3">
          <div className="dataTables_length form-group xs-mb-0">
            <Select2Wrap
              id="qrCodeCreatorAssetTableFilterLimit"
              name="limit"
              className="form-control"
              data={[
                {id: 5, text: '5 per page'},
                {id: 10, text: '10 per page'},
                {id: 20, text: '20 per page'},
                {id: 50, text: '50 per page'},
              ]}
              onChange={this.onChangeLimit}
              defaultValue={limit || ''}
            />
          </div>
        </div>
        <div className="col-sm-9">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePaginationCursor
              onSelect={onSetPage}
              lastValueInSet={lastValueInSet}
            />
          </div>
        </div>
      </div>
    );
  };
}

function getLastAssetName(assets) {
  if(assets.length < 1) return null;
  return _.get(_.last(assets), 'name');
}

export default TableCheckboxManagerControlled(QRTDCodeCreatorAssetTable, {
  allIds: function(props) {
    const assets = props.assets || [];
    return assets.map(asset => _.get(asset, 'unid'));
  } 
});
