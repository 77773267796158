import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { TableThSort, TablePagination, TableShowCount } from 'client/components/Helpers/Table';
import { ModalButton, ModalFormattedObject, ModalContentConfirmWarning, ModalConfirmButton } from 'client/components/Helpers/Modal';
import { Bool, StripeAmount, StripeInterval, UCFirst } from 'client/components/Helpers/Strings';
import PlanTableFilterForm from 'client/components/Plan/components/PlanTableFilterForm';
import PlanModal from 'client/components/Plan/components/PlanModal';
import BeLoading from 'client/components/Be/BeLoading';
import _ from 'lodash';

export default class PlanTable extends Component {

  static defaultProps = {
    plans: []
  }

  static propTypes = {
    plans: propTypes.array,
    filter: propTypes.object,
    orderBy: propTypes.string,
    orderDirection: propTypes.string,
    onOrderBy: propTypes.func,
    onSetPage: propTypes.func,
    onRefresh: propTypes.func,
    onDeletePlan: propTypes.func,
    loading: propTypes.bool,
  }

  renderConnectorColumn(connectors = []) {
    if(!connectors.length) return <span>0 connectors</span>;
    if(connectors.length === 1) return <span>{connectors[0].shortTitle}</span>;
    return <span>{connectors.length} connectors</span>;
  }

  renderDeleteButton(id) {
    const { onDeletePlan } = this.props;
    const confirmProps = {
      title: 'Confirm plan deletion',
      text: 'Are you sure you want to locally delete this plan?'
    };
    return (
      <ModalConfirmButton className="btn btn-default" callback={() => onDeletePlan(id)} contentProps={confirmProps} Content={ModalContentConfirmWarning}>
        <i className="icon mdi mdi-delete"></i>
      </ModalConfirmButton>
    );
  }

  renderPlans() {
    const { plans, onRefresh } = this.props;
    if(!plans || !plans.length) return (
      <tr>
        <td className="empty" colSpan={9}>No plans to show</td>
      </tr>
    );
    return plans.map(plan => {
      const { id, name, type, isSubscribable, interval, amount, stripeId, currency } = plan;
      const connectors = _.get(plan, 'Connectors', []);
      return (
        <tr key={id}>
          <td className="cell-detail">
            <span>{stripeId}</span>
            <span className="cell-detail-description">{id}</span>
          </td>
          <td>{name}</td>
          <td>{this.renderConnectorColumn(connectors)}</td>
          <td><UCFirst str={type} /></td>
          <td><Bool value={isSubscribable} /></td>
          <td><StripeAmount amount={amount} currency={currency} /></td>
          <td><StripeInterval interval={interval} /></td>
          <td className="text-right">
            <div className="btn-group">
              <ModalButton className="btn btn-default" ModalComponent={ModalFormattedObject} modalProps={{object: plan, title: 'User plan'}}>
                <i className="icon mdi mdi-search"></i>
              </ModalButton>
              <ModalButton className="btn btn-default" title="Show settings panel" ModalComponent={PlanModal} modalProps={{plan, onPlanWasUpdated: onRefresh}}>
                <span className="icon mdi mdi-settings"></span>
              </ModalButton>
              {this.renderDeleteButton(id)}
            </div>
          </td>
        </tr>
      );
    })
  }

  renderTable() {
    const { orderBy, orderDirection, onOrderBy, loading } = this.props;
    const sortProps = { orderBy, orderDirection, onClick: onOrderBy };
    return (
      <div className="be-datatable-body">
        <BeLoading loading={loading}>
          <table className="table table-striped table-hover dataTable">
            <thead>
              <tr>
                <TableThSort column="stripeId" {...sortProps}>Stripe ID</TableThSort>
                <th>Name</th>
                <th>Connectors</th>
                <th>Type</th>
                <th>Subscribable</th>
                <TableThSort column="amount" {...sortProps}>Amount</TableThSort>
                <th>Interval</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody className="no-border-x">
              {this.renderPlans()}
            </tbody>
          </table>
        </BeLoading>
      </div>
    );
  }

  renderFooter() {
    const { limit, count, page, onSetPage, pages } = this.props;
    return (
      <div className="row be-datatable-footer">
        <div className="col-sm-5 sm-mb-0 xs-mb-15">
          <div className="dataTables_info">
            <TableShowCount limit={limit} count={count} pluralization={['plan', 'plans']} />
          </div>
        </div>
        <div className="col-md-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <TablePagination activePage={page} onSelect={onSetPage} items={pages} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filter, onFilter } = this.props;
    const classes = classNames('panel', 'panel-table', this.props.className);
    return (
      <div className={classes}>
        <div className="panel-heading">Table of Plans</div>
        <div className="panel-body">
          <div className="dataTables_wrapper">
            <PlanTableFilterForm
              onFilter={onFilter}
              filter={filter}
            />
            {this.renderTable()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

};

