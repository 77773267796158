import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const planIndex = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/plan', query),
    method: 'GET',
    types
  });
};

export const planRead = (types, id) => {
  return apiAction({
    endpoint: `/api/plan/${id}`,
    method: 'GET',
    types
  });
};

export const planStripeSync = (types) => {
  return apiAction({
    endpoint: '/api/plan/stripe/sync',
    method: 'POST',
    json: {},
    types
  });
};

export const planUpdate = (types, id, json) => {
  return apiAction({
    endpoint: `/api/plan/${id}`,
    method: 'PATCH',
    json,
    types
  });
};

export const planDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/plan/${id}`,
    method: 'DELETE',
    json: {},
    types
  });
};
