import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import BeLoading from 'client/components/Be/BeLoading';
import { HelpButton } from 'client/components/Helpers/Help';
import * as actions from 'client/redux/endpoint/files/actions';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import bytes from 'bytes';
import { AlertWithMessage } from 'client/components/Be/Alert';

const DavInfo = ({davInfo: {url, username, password, maxStorage}}) => {
  const handleFocus = function(ev) { ev.target.select(); };
  return (
    <form className="group-border-dashed">
      <div className="form-group has-feedback">
        <label htmlFor="endpointFilesForm-url" className="control-label">
          WebDAV URL
        </label>
        <input className="form-control" onFocus={handleFocus} type="text" id="endpointFilesForm-url" value={url} readOnly={true} />
        <p className="help-block">Allocated space: {bytes.format(maxStorage, 'MB')}</p>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group has-feedback">
            <label htmlFor="endpointFilesForm-username" className="control-label">
              Username
            </label>
            <input className="form-control" onFocus={handleFocus} type="text" id="endpointFilesForm-username" value={username} readOnly={true} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group has-feedback">
            <label htmlFor="endpointFilesForm-password" className="control-label">
              Password
            </label>
            <input className="form-control" onFocus={handleFocus} type="text" id="endpointFilesForm-password" value={password} readOnly={true} />
          </div>
        </div>
      </div>
    </form>
  );
};

class EndpointFiles extends Component {

  static defaultProps = {
    onUpdated: function() {},
    isSetupMode: true
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    setupMode: propTypes.bool,
    onUpdated: propTypes.func
  }

  componentDidMount() {
    const { preloadedEndpoint, endpointId } = this.props;
    if(preloadedEndpoint) {
      this.props.endpointFilesPreloaded(preloadedEndpoint);
      this.props.endpointFilesReadDavInfo(endpointId)
    } else {
      this.props.endpointFilesRead(endpointId).then(() => this.props.endpointFilesReadDavInfo(endpointId));
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props;
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointFilesRead(endpointId).then(() => this.props.endpointFilesReadDavInfo(endpointId));
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  renderContent() {
    const { isReading, endpoint, davInfo } = this.props;
    if(isReading || !endpoint || !davInfo) return null;
    return (
      <div>
        <DavInfo davInfo={davInfo} />
      </div>
    );
  }

  renderStatusAlert() {
    const { endpoint } = this.props;
    if(endpoint && endpoint.status === 'error') {
      return (
        <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
          <strong>Error:</strong> There is a problem with this endpoint.
        </AlertWithMessage>
      )
    }
    return null;
  }

  render() {
    const { isReading, isReadingDavInfo, error } = this.props;
    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              Files (WebDAV)
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_files_help'}}
              />
            </div>
            <span className="panel-subtitle">Info about your WebDAV file area</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading className="xs-p-20" loading={isReading || isReadingDavInfo}>
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {this.renderStatusAlert()}
            {this.renderContent()}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.files, actions)(EndpointFiles);
