import React from 'react';
import _ from 'lodash';
import { round } from 'client/helpers/numbers';

/**
 * @description ShortId shortens a UUID with an abbreviation tag
 */
export const ShortId = props => {
  const { id } = props;
  if(!_.isString(id)) return null;
  return (
    <abbr style={{borderBottom: 'none'}} title={id}>
      <ShortIdStr id={id} />
    </abbr>
  );
};

export const ShortIdStr = props => {
  const { id } = props;
  if(!_.isString(id)) return '';
  return _.first(id.split('-'));
};

export const UCFirst = props => {
  const { str } = props;
  if(!_.isString(str) || !str.length) return null;
  const newStr = str[0].toUpperCase() + str.slice(1);
  return <span>{newStr}</span>;
}

/**
 * @description Placeholder displays the placeholder text if the list of children is empty
 */
export const Placeholder = props => {
  const { placeholder, children, isEmpty } = props;
  if(!children || isEmpty) return placeholder || '';
  return <div style={{display: 'inline'}}>{children}</div>;
};

/**
 * @description MailTo displays a anchor with a mailto link
 */
export const MailTo = props => {
  const { email } = props;
  if(!_.isString(email)) return null;
  return (
    <a href={`mailto:${email}`}>{email}</a>
  );
};

/**
 * @description UserRole displays the role of a user 
 */
export const UserRole = props => {
  const { role } = props;
  if(!_.isString(role)) return null;
  switch(role) {
    default: return role;
    case 'nobody': return 'Nobody';
    case 'admins': return 'Admin';
    case 'partners': return 'Partner';
    case 'users': return 'User';
  }
};

export const StripeAmount = props => {
  const { currency, roundTo } = props;
  let { amount } = props;
  if(!_.isNumber(amount)) amount = parseFloat(amount);
  if(!_.isFinite(amount)) return null;
  return <CurrencyAmount amount={amount/100} currency={currency} roundTo={roundTo} />
};

// TODO Number grouping
export const CurrencyAmount = ({amount, currency, roundTo}) => {
  return (
    <span>
      {!_.isUndefined(roundTo) ? round(amount, roundTo) : amount}{' '}
      {currency && <CurrencyName name={currency} />}
    </span>
  );
};

export const StripePercent = ({percent}) => {
  if(!_.isNumber(percent)) return null;
  return (
    <span>{percent} %</span>
  );
};

export const CurrencyName = props => {
  const { name } = props;
  if(!_.isString(name)) return null;
  return <span>{name.toUpperCase()}</span>;
};

export const StripeInterval = props => {
  const { interval } = props;
  if(!_.isString(interval)) return null;
  switch(interval) {
    case 'year': return <span>Yearly</span>;
    case 'month': return <span>Monthly</span>;
    case 'week': return <span>Weekly</span>;
    case 'day': return <span>Daily</span>;
    default: return interval;
  }
};

export const Bool = ({map, value}) => {
  if(!map) map = {0: 'No', 1: 'Yes'};
  return (
    <span>{value ? map[1] : map[0]}</span>
  );
};

export const Pluralize = ({count, plural, singular}) => {
  if(count === 1) return <span>{singular}</span>;
  return <span>{plural}</span>;
};

/**
 * @desc Displays the title of an instance (falling back to connector which should be available)
 */
export const InstanceTitle = ({instance, connector, short}) => {
  const instanceTitle = _.get(instance, 'title');
  if(instanceTitle) return instanceTitle;
  const connectorTitle = _.get(connector, short ? 'shortTitle' : 'title');
  if(connectorTitle) return connectorTitle;
  return _.get(instance, 'type');
};

export const UserInvoiceBillingMethod = ({invoice}) => {
  const method = _.get(invoice, 'stripeObject.billing');
  switch(method) {
    default:
    case 'charge_automatically': return 'Automatic charge';
    case 'send_invoice': return 'Manual invoicing';
  }
};
