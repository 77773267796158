import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import 'client/components/App/PrintPage.css';

export default class PrintPage extends React.Component {

  static propTypes = {
    size: propTypes.string.isRequired,
    padding: propTypes.string.isRequired,
  }

  static defaultProps = {
    padding: '20mm',
  }

  render() {
    const { children, size, padding } = this.props;
    return (
      <div className={classNames('print-page', `print-page-${size}`)} style={{padding}}>
        {children}
      </div>
    );
  }

}
