import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { AlertWithMessage } from 'client/components/Be/Alert';
import InstancePauseAlert from 'client/components/Instance/components/InstancePauseAlert';
import { Prompt } from 'react-router';

export default class InstanceOptionsFormStateAlert extends Component {

  static defaultProps = {
    disabled: false
  }

  static propTypes = {
    dirty: propTypes.bool.isRequired,
    disabled: propTypes.bool.isRequired,
    invalid: propTypes.bool.isRequired,
    isSetupMode: propTypes.bool.isRequired,
    isUpdating: propTypes.bool.isRequired,
    onNudgeStep: propTypes.func
  }

  renderAlert() {
    const { disabled, invalid, dirty, isSetupMode, onNudgeStep, isUpdating, onReset } = this.props;
    const alertProps = {icon: true, className: 'alert-icon xs-mb-0'};

    const Message = props => <span style={{lineHeight: '30px'}}>{props.children}</span>;
    const SaveButton = () => <ButtonWaiting disabled={invalid} type="submit" className="btn btn-success" isWaiting={isUpdating}>Save</ButtonWaiting>;
    const ResetButton = () => onReset ? <button disabled={!dirty} type="reset" onClick={onReset} className="btn btn-default">Undo</button> : null;
    const NextButton = () => <button type="button" className="btn btn-primary" onClick={() => onNudgeStep(1)}>Next step <i className="icon mdi mdi-chevron-right"></i></button>;

    switch(true) {
      default: return null;
      // disabled
      case disabled === true: return (
        <InstancePauseAlert />
      );
      // valid and changed
      case invalid !== true && dirty === true: return (
        <AlertWithMessage {...alertProps} alertType="warning">
          <Message>There are unsaved changes. Would you like to save them?</Message>
          <div className="pull-right">
            <ResetButton />{' '}
            <SaveButton />
          </div>
        </AlertWithMessage>
      );
      // seen in setup for the first time
      case invalid !== true && dirty === false && isSetupMode: return (
        <AlertWithMessage {...alertProps} alertType="primary">
          <Message>If you don't need to change any options you can proceed to the next step.</Message>
          <div className="pull-right">
            <NextButton />
          </div>
        </AlertWithMessage>
      );
      // has errors
      case invalid === true: return (
        <AlertWithMessage {...alertProps} alertType="danger">
          <Message>There are errors in the settings form below. Please correct them before saving.</Message>
          <div className="pull-right">
            <ResetButton />{' '}
            <SaveButton />
          </div>
        </AlertWithMessage>
      );
    }
  }

  render() {
    const { dirty } = this.props;
    return (
      <div>
        <Prompt when={dirty} message="Are you sure you want to leave without saving?" />
        {this.renderAlert()}
      </div>
    );
  }

}
