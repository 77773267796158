import React from 'react';
import propTypes from 'prop-types';
import { ModalSimple } from 'client/components/Helpers/Modal';
import { Elements } from 'react-stripe-elements';
import AccountAddCard from 'client/components/Account/components/AccountAddCard';

export default class AccountAddCardModal extends React.Component {

  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    onCardAdded: propTypes.func.isRequired,
    onClose: propTypes.func.isRequired,
  }

  render() {
    const { isOpen, onCardAdded, onClose } = this.props;
    return (
      <ModalSimple title="Add card" dialogClassName="colored-header colored-header-primary" isOpen={isOpen} onClose={onClose}>
        {isOpen && 
          <Elements locale="en">
            <AccountAddCard onAdd={onCardAdded} />
          </Elements>
        }
      </ModalSimple>
    );
  }

}
