import React from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import classNames from 'classnames';
import EndpointBankidForm from 'client/components/Endpoint/EndpointBankidForm';
import { connect } from 'react-redux';
import * as bankidActions from 'client/redux/endpoint/bankid/actions';
import BeLoading from 'client/components/Be/BeLoading';
import { ModalButton, ModalFormattedObject } from 'client/components/Helpers/Modal';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { ENDPOINT_BANKID_DELETE_SUCCESS } from 'client/redux/endpoint/bankid/constants';
import { ModalConfirmButton, ModalContentConfirmWarning  } from 'client/components/Helpers/Modal';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';
import { AlertWithMessage } from 'client/components/Be/Alert';

class EndpointBankid extends React.Component {

  static defaultProps = {
    onUpdated: function() {},
  }

  static propTypes = {
    endpointId: propTypes.string.isRequired,
    instanceId: propTypes.string.isRequired,
    preloadedEndpoint: propTypes.object,
    onUpdated: propTypes.func,
  }

  componentDidMount() {
    if (this.props.preloadedEndpoint) {
      this.props.endpointBankidPreloaded(this.props.preloadedEndpoint);
    } else {
      this.props.endpointBankidRead(this.props.endpointId);
    }
  }

  componentDidUpdate(prevProps) {
    const { endpointId } = this.props
    if(endpointId !== prevProps.endpointId) {
      this.props.endpointBankidRead(endpointId);
    }
    if (this.props.endpoint?.status !== prevProps.endpoint?.status) {
      this.props.onUpdated(endpointId);
    }
  }

  handleUpdate = (settings = {}) => {
    return this.props.endpointBankidUpdateSettings(this.props.endpointId, settings)
      .then(getResponseToFormErrorHandler())
      .then(result => {
        this.props.endpointBankidEditSettings(false);
      });
  }

  handleClickEdit = ev => {
    this.props.endpointBankidEditSettings(true);
  }

  handleClickDelete = ev => {
    const { instanceId, endpointId } = this.props;
    return this.props.endpointBankidDelete(instanceId, 'bankid').then(result => {
      if (result.type !== ENDPOINT_BANKID_DELETE_SUCCESS) return result;
      return this.props.onUpdated(endpointId);
    });
  }

  render() {
    const {
      endpoint,
      error,
      isEditing,
      isReading,
      isUpdating,
    } = this.props;
    const endpointHasError = endpoint?.status === 'error';
    const endpointHasValidCertificate = certificateIsValid(endpoint);
    return (
      <div>
        <div className="form-group">
          <h3 className="wizard-title">
            <div className="clearfix">
              BankID
              <HelpButton
                className="btn btn-rounded btn-default btn-xs pull-right"
                HelpModal={UIFragmentHelpModal}
                helpModalProps={{fragmentId: 'endpoint_bankid_settings_help'}}
              />
            </div>
            <span className="panel-subtitle">Paste your BankID certificates below</span>
          </h3>
        </div>
        <div className="well xs-mb-10 xs-p-0">
          <BeLoading loading={isReading} className="xs-p-20">
            <BeRemoteErrorAlert error={error} ignoreValidationResult={true} /> 
            {endpointHasError && (
              <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
                <strong>Error:</strong> There is a problem with this endpoint.
              </AlertWithMessage>
            )}
            {endpoint && (
              <>
                {isEditing && (
                  <EndpointBankidForm
                    isDisabled={false}
                    initialValues={endpoint.settings}
                    isEditing={isEditing}
                    endpointState={endpoint.state}
                    onEdit={this.handleClickEdit}
                    onSubmit={this.handleUpdate}
                    isUpdating={isUpdating}
                    remoteError={error}
                  />
                )}
                {!isEditing && (
                  <div className="row">
                    <div className="col-md-6">
                      {endpoint.status !== 'setup' && (
                        <ModalButton
                          className={classNames('btn btn-rounded', {'btn-success': endpointHasValidCertificate, 'btn-warning': !endpointHasValidCertificate})}
                          title="Show fragment"
                          ModalComponent={ModalFormattedObject}
                          modalProps={{object: endpoint.state?.certStatus, title: 'Certificate', inspector: 'object'}}
                        >
                          {endpointHasValidCertificate ? (
                            <>
                              <i className="icon icon-left mdi mdi-check"></i>{' '}
                              Certificate valid
                            </>
                          ) : (
                            <>
                              <i className="icon icon-left mdi mdi-block"></i>{' '}
                              Certificate expired
                            </>
                          )}
                        </ModalButton>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="btn-group pull-right clearfix">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={this.handleClickEdit}
                        >
                          <i className="icon icon-left mdi mdi-edit"></i>
                          {' '}Edit settings
                        </button>
                        <ModalConfirmButton
                          className="btn btn-default"
                          callback={this.handleClickDelete}
                          contentProps={{title: 'Confirm endpoint deletion', text: 'Are you sure you want to delete this endpoint?'}}
                          Content={ModalContentConfirmWarning}
                        >
                          <i className="icon mdi mdi-delete"></i>{' '}
                          Delete
                        </ModalConfirmButton>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </BeLoading>
        </div>
      </div>
    );
  }

}

export default connect(state => state.endpoint.bankid, bankidActions)(EndpointBankid);

function certificateIsValid (endpoint) {
  const certStatus = endpoint?.state?.certStatus;
  if (!certStatus) return null;
  const { valid_from:fromRaw, valid_to:toRaw } = certStatus;
  const now = new Date();
  const validFrom = new Date(fromRaw);
  const validTo = new Date(toRaw);
  return validFrom <= now && now <= validTo;
}
