import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import * as actions from 'client/redux/support/actions';
import SupportAside from 'client/components/Support/components/SupportAside';
import SupportQuestionList from 'client/components/Support/components/SupportQuestionList';
import { isAdmin } from 'client/redux/auth/helpers';
import { SUPPORT_CATEGORY_CREATE_SUCCESS, SUPPORT_CATEGORY_UPDATE_SUCCESS, SUPPORT_CATEGORY_DELETE_SUCCESS, SUPPORT_QUESTION_CREATE_SUCCESS, SUPPORT_QUESTION_UPDATE_SUCCESS, SUPPORT_QUESTION_DELETE_SUCCESS } from 'client/redux/support/constants';
import 'client/components/Support/Support.css';

class Support extends Component {

  getData = () => {
    return this.props.supportQuestions();
  }

  getDataWhenType = (type, returnPromise = false) => result => {
    if(result && result.type === type) {
      const p = this.getData();
      if(returnPromise) return p;
    }
  }

  componentDidMount() {
    return this.getData();
  }

  renderTitle() {
    return (
      <Helmet>
        <title>Support</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  handleCreateCategory = () => {
    return this.props.supportCategoryCreate().then(this.getDataWhenType(SUPPORT_CATEGORY_CREATE_SUCCESS));
  }

  handleUpdateCategory = (categoryId, update) => {
    return this.props.supportCategoryUpdate(categoryId, update).then(this.getDataWhenType(SUPPORT_CATEGORY_UPDATE_SUCCESS, true));
  }

  handleDeleteCategory = categoryId => {
    return this.props.supportCategoryDelete(categoryId).then(this.getDataWhenType(SUPPORT_CATEGORY_DELETE_SUCCESS));
  }

  handleCreateQuestion = categoryId => {
    return this.props.supportQuestionCreate(categoryId).then(this.getDataWhenType(SUPPORT_QUESTION_CREATE_SUCCESS));
  }

  handleUpdateQuestion = (questionId, update) => {
    return this.props.supportQuestionUpdate(questionId, update).then(this.getDataWhenType(SUPPORT_QUESTION_UPDATE_SUCCESS, true));
  }

  handleDeleteQuestion = questionId => {
    return this.props.supportQuestionDelete(questionId).then(this.getDataWhenType(SUPPORT_QUESTION_DELETE_SUCCESS));
  };

  onFilterChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    return this.props.supportQuestionsFilter(newFilter);
  }

  renderAsideHeader() {
    const filter = this.props.filter || {};
    return (
      <div className="be-aside-header">
        <div className="row">
          <div className="col-md-12">
            <div className="be-aside-header-search">
              <div className="input-group input-search input-group-sm">
                <input type="text" name="search" placeholder="Input to search for your question" className="form-control" value={filter.search} onChange={this.onFilterChange} />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-default" disabled={true}>
                    <i className="icon mdi mdi-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { error, questionsByCategory, categories, filter, hiddenQuestionsById, isAdmin } = this.props;
    if(error && !categories) return this.renderError(error);
    return (
      <Content className="be-no-padding">
        {this.renderTitle()}
        <SupportAside
          categories={categories}
          questionsByCategory={questionsByCategory}
          isAdmin={isAdmin}
          filter={filter}
        />
        <MainContent>
          {this.renderAsideHeader()}
          {error &&
            <div className="xs-m-10">
              <BeRemoteErrorAlert error={error} />
            </div>
          }
          <SupportQuestionList
            className="xs-p-20"
            isAdmin={isAdmin}
            categories={categories}
            questionsByCategory={questionsByCategory}
            hiddenQuestionsById={hiddenQuestionsById}
            onCreateCategory={this.handleCreateCategory}
            onDeleteCategory={this.handleDeleteCategory}
            onUpdateCategory={this.handleUpdateCategory}
            onCreateQuestion={this.handleCreateQuestion}
            onDeleteQuestion={this.handleDeleteQuestion}
            onUpdateQuestion={this.handleUpdateQuestion}
            filter={filter}
          />
        </MainContent>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.support,
    isAdmin: isAdmin(state)
  };
}

export default connect(mapStateToProps, actions)(Support);
