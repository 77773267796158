import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

export default class Icon extends React.Component {

  static propTypes = {
    icon: propTypes.string.isRequired
  }

  render() {
    const { icon } = this.props;
    const classes = classNames('icon', 'mdi', 'mdi-' + icon);
    return <i className={classes}></i>;
  }

}

export class IconAlert extends React.Component {

  static propTypes = {
    type: propTypes.string.isRequired,
    icon: propTypes.string,
  }

  static defaultProps = {
    type: 'primary',
  }

  render() {
    const { type, icon, className } = this.props;
    if (icon) {
      return <i className={classNames('mdi', className, icon)}></i>
    }
    const classes = ['mdi', className || ''];
    switch(type) {
      default:
      case 'info': classes.push('mdi-info-outline'); break;
      case 'error': classes.push('mdi-alert-octagon'); break;
      case 'warning': classes.push('mdi-alert-triangle'); break;
      case 'success': classes.push('mdi-thumb-up'); break;
    }
    return <i className={classNames(classes)}></i>
  }

}
