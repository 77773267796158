import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';

/**
 * @desc Simple form container
 */
class FormContainer extends Component {

  static propTypes = {
    form: propTypes.string.isRequired,
    onSubmit: propTypes.func.isRequired,
  }

  render() {
    const { handleSubmit, onSubmit, children } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        {children(this.props)}
      </Form>
    );
  }

}

export default reduxForm({
  enableReinitialize: true,
})(FormContainer);
