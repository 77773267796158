export const ENDPOINT_TOPDESK_PRELOADED = 'ENDPOINT_TOPDESK_PRELOADED';

export const ENDPOINT_TOPDESK_READ_REQUEST = 'ENDPOINT_TOPDESK_READ_REQUEST';
export const ENDPOINT_TOPDESK_READ_SUCCESS = 'ENDPOINT_TOPDESK_READ_SUCCESS';
export const ENDPOINT_TOPDESK_READ_FAILURE = 'ENDPOINT_TOPDESK_READ_FAILURE';
export const ENDPOINT_TOPDESK_READ = [ENDPOINT_TOPDESK_READ_REQUEST, ENDPOINT_TOPDESK_READ_SUCCESS, ENDPOINT_TOPDESK_READ_FAILURE];

export const ENDPOINT_TOPDESK_EDIT_CREDENTIALS = 'ENDPOINT_TOPDESK_EDIT_CREDENTIALS';

export const ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_REQUEST = 'ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_REQUEST';
export const ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_SUCCESS = 'ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_SUCCESS';
export const ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_FAILURE = 'ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_FAILURE';
export const ENDPOINT_TOPDESK_UPDATE_CREDENTIALS = [ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_REQUEST, ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_SUCCESS, ENDPOINT_TOPDESK_UPDATE_CREDENTIALS_FAILURE];

export const ENDPOINT_TOPDESK_TEST_CREDENTIALS_REQUEST = 'ENDPOINT_TOPDESK_TEST_CREDENTIALS_REQUEST';
export const ENDPOINT_TOPDESK_TEST_CREDENTIALS_SUCCESS = 'ENDPOINT_TOPDESK_TEST_CREDENTIALS_SUCCESS';
export const ENDPOINT_TOPDESK_TEST_CREDENTIALS_FAILURE = 'ENDPOINT_TOPDESK_TEST_CREDENTIALS_FAILURE';
export const ENDPOINT_TOPDESK_TEST_CREDENTIALS = [ENDPOINT_TOPDESK_TEST_CREDENTIALS_REQUEST, ENDPOINT_TOPDESK_TEST_CREDENTIALS_SUCCESS, ENDPOINT_TOPDESK_TEST_CREDENTIALS_FAILURE];
