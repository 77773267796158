import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput, FormReduxTextarea, FormReduxSelect2 } from 'client/components/Form/FormRedux';
import { rendererSelect2 } from 'client/redux/uiFragment/constants';

class UIFragmentForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
  }

  fieldProps = {base: 'uiFragmentForm', metaOptions: {defaultState: null}}

  render() {
    const { handleSubmit, onSubmit, dirty, submitting } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <Field
          {...this.fieldProps}
          type="text"
          name="title"
          component={FormReduxInput}
          placeholder="Enter title"
        />
        <Field
          {...this.fieldProps}
          type="text"
          name="markdown"
          component={FormReduxTextarea}
          placeholder="Enter markdown"
        />
        <Field
          {...this.fieldProps}
          name="renderer"
          component={FormReduxSelect2}
          label="Renderer"
          data={rendererSelect2}
          select2ComponentProps={{unwrapValue: true}}
        />
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={!dirty} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'uiFragment',
  enableReinitialize: true
})(UIFragmentForm);
