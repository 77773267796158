import { apiAction } from 'client/redux/apiHelpers';

export const endpointGraphRead = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/graph/${id}`,
    method: 'GET',
    types
  });
};

export const endpointGraphTestConsent = (types, id) => {
  return apiAction({
    endpoint: `/api/endpoint/graph/${id}/testConsent`,
    method: 'POST',
    json: {},
    types
  });
};
