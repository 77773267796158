import React from 'react';
import { setBodyClass } from 'client/redux/bodyClass';
import { SplashFooter, SplashHeader } from 'client/components/Branding';
import { Helmet } from 'react-helmet';
import { App, Content, MainContent } from 'client/components/App';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { redirectAndFlash } from 'client/redux/flashAlert/actions';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { resetPassword } from 'client/redux/resetPassword/actions';
import ResetPasswordForm from 'client/components/ResetPassword/components/ResetPasswordForm';

class ResetPassword extends React.Component {

  handleFormSubmit = formFields => {
    const fields = {...formFields, token: this.props.match.params.token};
    return this.props.resetPassword(fields).then(getResponseToFormErrorHandler()).then(response => {
      return this.resetSuccess();
    });
  }

  componentDidUpdate (prevProps) {
    if (this.props.wasReset !== prevProps.wasReset) {
      this.props.push('/login');
    }
  }

  resetSuccess() {
    return this.props.redirectAndFlash('/login', {
      alertType: 'success',
      strong: 'Password reset!',
      msg: 'You can now login with the new one'
    });
  }

  render() {
    setBodyClass('be-splash-screen');
    return (
      <App className="be-login">
        <Helmet>
          <title>Forgot your password?</title>
        </Helmet>
        <Content>
          <MainContent className="container-fluid">
            <div className="splash-container forgot-password">
              <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
                <SplashHeader className="xs-mb-0">Reset the password for your account</SplashHeader>
                <div className="panel-body xs-pt-0">
                  <ResetPasswordForm token={this.props.match.params.token} onSubmit={this.handleFormSubmit} />
                </div>
              </div>
              <SplashFooter />
            </div>
          </MainContent>
        </Content>
      </App>
    );
  }
};

export default connect(state => state.resetPassword, { resetPassword, redirectAndFlash, push })(ResetPassword);
