import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import {
  INSTANCE_DELETE_SUCCESS,
  INSTANCE_DASHBOARD_DELETE_SUCCESS,
  INSTANCE_SETUP_UPDATE_STATUS_SUCCESS,
  INSTANCE_CLONE_SUCCESS,
} from 'client/redux/instance/constants';

export const onInstanceDelete = deleteActionResult => dispatch => {
  if(deleteActionResult) {
    const { type } = deleteActionResult;
    if(type === INSTANCE_DASHBOARD_DELETE_SUCCESS || type === INSTANCE_DELETE_SUCCESS) {
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'instance_delete_success', {
        className: 'color warning',
        title: 'Deletion succeded',
        message: 'The instance was deleted',
        closeAfter: 3000
      }));
    }
  }
};

export const onInstanceClone = cloneActionResult => dispatch => {
  if(cloneActionResult) {
    const { type } = cloneActionResult;
    if(type === INSTANCE_CLONE_SUCCESS) {
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'instance_clone_success', {
        className: 'color success',
        title: 'Clone succeeded',
        message: 'The instance was cloned',
        closeAfter: 3000
      }));
    }
  }
};

export const onInstanceSetupActivate = updateStatusActionResult => dispatch => {
  if(updateStatusActionResult) {
    const { type, meta } = updateStatusActionResult;
    if(type === INSTANCE_SETUP_UPDATE_STATUS_SUCCESS && meta && meta.newStatus === 'active') {
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'instance_setup_activate_success', {
        className: 'color success',
        title: 'Success',
        message: `The connector has been activated`,
        closeAfter: 5000
      }));
    }
  }
};
