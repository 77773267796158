import React from 'react';
import propTypes from 'prop-types';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';

export default class QRTDCodeCreatorLocationTableFilter extends React.Component {

  static propTypes = {
    endpointTopdeskId: propTypes.string.isRequired,
    filter: propTypes.object.isRequired,
    onFilter: propTypes.func,
  }

  static defaultProps = {
    filter: {},
  }

  handleChange = ev => {
    return this.handleChangeVal(ev.target.value, ev.target.name);
  }

  handleChangeVal = (value, name) => {
    const newFilter = {...this.props.filter, [name]: value};
    return this.props.onFilter(newFilter);
  }

  render() {
    const {
      filter: { name, branchId },
      endpointTopdeskId,
    } = this.props;
    return (
      <div className="row be-datatable-header xs-p-5">
        <div className="col-md-3 xs-pr-10 xs-pl-15">
          <div className="dataTables_length form-group xs-mb-10">
            <input
              id="qrCodeCreatorLocationTableFilterLocationName"
              type="text"
              className="form-control"
              name="name"
              onChange={this.handleChange}
              placeholder="Any location name"
              value={name}
            />
          </div>
        </div>
        <div className="col-md-3 xs-pr-10 xs-pl-10">
          <div className="dataTables_length form-group xs-mb-10">
            <EndpointTopdeskRemoteSearcher
              className="form-control"
              id="qrCodeCreatorLocationTableFilterLocationBranchId"
              forWhat="branch"
              endpointId={endpointTopdeskId}
              options={{minimumInputLength: 0, minimumResultsForSearch: 0, allowClear: true, placeholder: 'Any branch'}}
              name="branchId"
              onChange={this.handleChangeVal}
              defaultValue={branchId || ''}
            />
          </div>
        </div>
      </div>
    );
  }

}
