import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/instance/log/indexActions';
import InstanceLogTable from 'client/components/InstanceLog/components/InstanceLogTable';
import { getStateSearch } from 'client/redux/apiHelpers';
import * as urlHelpers from 'client/helpers/urlHelpers';
import _ from 'lodash';

class InstanceLogIndex extends Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const currQuery = this.getQuery(this.props);
    const prevQuery = this.getQuery(prevProps);
    if(!_.isEqual(currQuery, prevQuery)) {
      this.getData();
    }
  }

  getQuery = props => urlHelpers.parseQuerystring(_.get(props, 'location.search'));

  getData = () => {
    const query = this.getQuery(this.props);
    return this.props.instanceLogIndex(query);
  }

  handleOrderBy = (orderBy, orderDirection) => {
    const url = this.getUrlWithModifiedQuery({orderBy, orderDirection});
    return this.props.push(url);
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    const url = this.getUrlWithModifiedQuery({page});
    return this.props.push(url);
  }

  handleFilter = (filter = {}) => {
    const url = this.getUrlWithModifiedQuery(filter);
    return this.props.push(url);
  }

  getUrlWithModifiedQuery = (query = {}) => {
    const { location } = this.props;
    const newQuery = {
      ...this.getQuery(this.props),
      ...query,
    };
    return urlHelpers.formatLocationWithQuery(location, newQuery);
  }

  render() {
    const {
      isReading,
      logs,
      pages,
      limit,
      offset,
      count,
      error,
    } = this.props;
    const query = this.getQuery(this.props);
    return (
      <Content>
        <Helmet>
          <title>Connector Instance Log Overview</title>
        </Helmet>
        <MainContent>
          {error && (
            <BeRemoteErrorAlert error={error} />
          )}
          {!error && (
            <InstanceLogTable
              className="panel-default panel-border"
              onOrderBy={this.handleOrderBy}
              onSetPage={this.handleSetPage}
              orderBy={query.orderBy}
              orderDirection={query.orderDirection}
              onFilter={this.handleFilter}
              onRefresh={this.getData}
              filter={query}
              logs={logs}
              count={count}
              limit={limit}
              offset={offset}
              page={parseInt(query.page, 10) || 1}
              pages={pages}
              loading={isReading}
            />
          )}
        </MainContent>
      </Content>
    );
  }

}

const mapStateToProps = state => ({
  ...state.instance.logIndex,
  search: getStateSearch(state),
});

const actions = {
  ...indexActions,
  push,
};

export default connect(mapStateToProps, actions)(InstanceLogIndex);
