import AccountSettings from 'client/components/Account/AccountSettings';
import AccountChildren from 'client/components/Account/AccountChildren';
import AccountBilling from 'client/components/Account/AccountBilling';
import AccountSubscription from 'client/components/AccountSubscription/AccountSubscription';
import AccountInvoiceRead from 'client/components/AccountInvoice/AccountInvoiceRead';
import ConfirmUser from 'client/components/ConfirmUser/ConfirmUser';
import ConnectorIndex from 'client/components/ConnectorIndex/ConnectorIndex';
import Dashboard from 'client/components/Dashboard/Dashboard';
import EndpointIndex from 'client/components/Endpoint/EndpointIndex';
import EndpointLogIndex from 'client/components/EndpointLog/EndpointLogIndex';
import ForgotPassword from 'client/components/ForgotPassword/ForgotPassword';
import InstanceIndex from 'client/components/Instance/ConnectedInstanceIndex';
import InstanceView from 'client/components/Instance/InstanceView';
import InstanceLogIndex from 'client/components/InstanceLog/InstanceLogIndex';
import InstanceLogFragmentIndex from 'client/components/InstanceLogFragment/ConnectedInstanceLogFragmentIndex';
import ConnectorSelectPlan from 'client/components/ConnectorSelectPlan/ConnectorSelectPlan';
import Login from 'client/components/Login/Login';
import Marketplace from 'client/components/Marketplace/Marketplace';
import { ErrorForbidden, ErrorNotFound } from 'client/components/ErrorSplash/ErrorSplash';
import ResetPassword from 'client/components/ResetPassword/ResetPassword';
import Signup from 'client/components/Signup/Signup';
import Support from 'client/components/Support/Support';
import UserIndex from 'client/components/User/ConnectedUserIndex';
import UserInvoiceIndex from 'client/components/UserInvoice/UserInvoiceIndex';
import UserInvoiceRead from 'client/components/UserInvoice/UserInvoiceRead';
import UserLogIndex from 'client/components/UserLog/UserLogIndex';
import UserSubscriptionIndex from 'client/components/UserSubscription/UserSubscriptionIndex';
import SettingsIndex from 'client/components/Settings/SettingsIndex';
import PlanIndex from 'client/components/Plan/PlanIndex';
import UIFragmentIndex from 'client/components/UIFragment/UIFragmentIndex';
import ConnectedQRTDPrintPage from 'client/components/QRTD/ConnectedQRTDPrintPage';

const forExport = {
  AccountBilling,
  AccountInvoiceRead,
  AccountSettings,
  AccountChildren,
  AccountSubscription,
  ConfirmUser,
  ConnectedQRTDPrintPage,
  ConnectorIndex,
  ConnectorSelectPlan,
  Dashboard,
  EndpointIndex,
  EndpointLogIndex,
  ErrorForbidden,
  ErrorNotFound,
  ForgotPassword,
  InstanceIndex,
  InstanceLogFragmentIndex,
  InstanceLogIndex,
  InstanceView,
  Login,
  Marketplace,
  PlanIndex,
  ResetPassword,
  SettingsIndex,
  Signup,
  Support,
  UIFragmentIndex,
  UserIndex,
  UserInvoiceIndex,
  UserInvoiceRead,
  UserLogIndex,
  UserSubscriptionIndex,
};

export default forExport;
