import React from 'react';
import { MainContent, Content } from 'client/components/App';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { connect } from 'react-redux';
import { onInstanceDelete, onInstanceClone } from 'client/redux/instance/eventHandlerActions';
import * as instanceIndexActions from 'client/redux/instance/indexActions';
import * as urlHelpers from 'client/helpers/urlHelpers';
import InstanceTable from 'client/components/Instance/components/InstanceTable';
import { getStateSearch } from 'client/redux/apiHelpers';

class ConnectedInstanceIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getDataDebounced = _.debounce(this.getData, 200);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const currQuery = this.getQuery(this.props);
    const prevQuery = this.getQuery(prevProps);
    if(!_.isEqual(currQuery, prevQuery)) {
      this.getData();
    }
  }

  handleDeleteInstance = id => {
    return this.props.instanceDelete(id).then(this.props.onInstanceDelete).then(this.getData);
  }

  handleCloneInstance = id => {
    return this.props.instanceClone(id).then(this.props.onInstanceClone).then(this.getData);
  }

  getQuery = props => urlHelpers.parseQuerystring(_.get(props, 'location.search'));

  getData = () => {
    const query = this.getQuery(this.props);
    return this.props.instanceIndex(query);
  }

  handleOrderBy = (orderBy, orderDirection) => {
    const url = this.getUrlWithModifiedQuery({orderBy, orderDirection});
    return this.props.push(url);
  }

  handleSetPage = page => {
    if(this.props.page === page) return;
    const url = this.getUrlWithModifiedQuery({page});
    return this.props.push(url);
  }

  handleFilter = (filter = {}) => {
    const url = this.getUrlWithModifiedQuery(filter);
    return this.props.push(url);
  }

  handleSetColumns = columns => {
    return this.props.instanceIndexSetColumns(columns);
  }

  getUrlWithModifiedQuery = (query = {}) => {
    const { location } = this.props;
    const newQuery = {
      ...this.getQuery(this.props),
      ...query,
    };
    return urlHelpers.formatLocationWithQuery(location, newQuery);
  }

  renderError(error) {
    return (
      <Content>
        <Helmet>
          <title>Connector Instances Overview</title>
        </Helmet>
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const {
      isReading,
      instances,
      pages,
      limit,
      offset,
      count,
      error,
      columns,
    } = this.props;
    const query = this.getQuery(this.props);
    if(error) return this.renderError(error);
    return (
      <Content>
        <MainContent>
          <Helmet>
            <title>Connector Instances Overview</title>
          </Helmet>
          <InstanceTable
            className="panel-default panel-border"
            columns={columns}
            count={count}
            filter={query}
            instances={instances}
            limit={limit}
            loading={isReading}
            offset={offset}
            onDeleteInstance={this.handleDeleteInstance}
            onCloneInstance={this.handleCloneInstance}
            onFilter={this.handleFilter}
            onOrderBy={this.handleOrderBy}
            onRefresh={this.getData}
            onSetColumns={this.handleSetColumns}
            onSetPage={this.handleSetPage}
            orderBy={query.orderBy}
            orderDirection={query.orderDirection}
            page={parseInt(query.page, 10) || 1}
            pages={pages}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  ...instanceIndexActions,
  onInstanceDelete,
  onInstanceClone,
  push,
};

const mapStateToProps = state => ({
  ...state.instance.index,
  search: getStateSearch(state),
});

export default connect(mapStateToProps, actions)(withRouter(ConnectedInstanceIndex));
