import { NEWS_PANEL_UPDATE } from 'client/redux/news/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isUpdating: false,
  error: null
};

export default function editPanelReducer(state = defaultState, action) {
  state = reduceByTypes(NEWS_PANEL_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true, successPickProps: []});
  return state;
};
