import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';
import NewsNewPanel from 'client/components/News/NewsNewPanel';

export default class NewsNewModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    onNewsWasCreated: propTypes.func
  }

  onSave = () => {
    this.props.onClose();
    this.props.onNewsWasCreated();
  }

  render() {
    const { onClose, open } = this.props;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>Create News Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewsNewPanel onSave={this.onSave} />
        </Modal.Body>
      </Modal>
    );
  }

}
