import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput, FormReduxTextarea } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm } from 'redux-form';
import { isNotEmpty, isString, isURL } from 'client/redux/validators';
import { FormErrors } from 'client/components/Form/FormErrors';

const validateByField = {
  apiUrl: [isString(), isURL()],
  cert: [isString(), isNotEmpty()],
  key: [isString(), isNotEmpty()],
};

class EndpointBankidForm extends Component {

  static defaultProps = {
    isEditing: false,
    onEdit: function() {}
  }

  static propTypes = {
    isEditing: propTypes.bool.isRequired,
    onEdit: propTypes.func,
    remoteError: propTypes.object,
    initialValues: propTypes.object.isRequired,
    endpointState: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    isUpdating: propTypes.bool.isRequired,
  }

  render() {
    const {
      remoteError,
      error,
      isUpdating,
      valid,
      onEdit,
      onSubmit,
      isEditing,
      initialValues,
      handleSubmit,
    } = this.props;

    const hasInitialValues = Boolean(initialValues.cert && initialValues.key);
    const disabled = hasInitialValues && !isEditing;

    const fieldProps = {
      base: 'endpointBankidForm',
      metaOptions: {defaultState: null, ignorePristine: true},
    };

    return (
			<Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <FormErrors className="xs-mt-0 xs-mb-10" error={error || remoteError} />
        <div>
          <Field
            {...fieldProps}
            name="apiUrl"
            component={FormReduxInput}
            type="text"
            label="BankID API URL"
            disabled={disabled}
            validate={validateByField.apiUrl}
            helpText="Leave blank to use the default BankID URL (recommended)"
          />
        </div>
        <Field
          {...fieldProps}
          name="cert"
          component={FormReduxTextarea}
          type="text"
          label="Certificate (x509)"
          disabled={disabled}
          placeholder="Enter the certificate formatted as x509"
          validate={validateByField.cert}
        />
        <Field
          {...fieldProps}
          name="key"
          component={FormReduxTextarea}
          type="text"
          label="Private key (x509)"
          disabled={disabled}
          placeholder="Enter the private key formatted as x509"
          validate={validateByField.key}
        />
        <div className="form-group xs-mb-0">
          {disabled && (
            <button className="btn btn-default pull-right" type="button" onClick={onEdit}>
              Edit settings
            </button>
          )}
          {!disabled && (
            <ButtonWaiting
              type="submit"
              disabled={!valid}
              className="btn btn-default pull-right"
              isWaiting={isUpdating}
            >
              Save and verify
            </ButtonWaiting>
          )}
          <div className="clearfix"></div>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'endpointBankid',
  enableReinitialize: true
})(EndpointBankidForm);
