import { gritterAlertPush } from 'client/redux/gritter/actionCreators';
import { GRITTER_ALERT_PUSH } from 'client/redux/gritter/constants';
import { UI_FRAGMENTS_INDEX_DELETE_SUCCESS } from 'client/redux/uiFragment/constants';

export const onFragmentDelete = deleteActionResult => dispatch => {
  if(deleteActionResult) {
    const { type } = deleteActionResult;
    if(type === UI_FRAGMENTS_INDEX_DELETE_SUCCESS) {
      return dispatch(gritterAlertPush(GRITTER_ALERT_PUSH, 'fragment_delete_success', {
        className: 'color warning',
        title: 'Deletion succeded',
        message: 'The fragment was deleted',
        closeAfter: 3000
      }));
    }
  }
};
