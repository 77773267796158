const _ = require('lodash');
const qs = require('qs');
const url = require('url');
const { sha1 } = require('crypto-hash');
const { Buffer } = require('buffer');

/**
 * @desc Helpers to create and format QR-links
 * WARNING! WARNING! WARNING! WARNING! WARNING! WARNING! 
 * This file is used client-side
 * Modules required here also needs to be available client-side
 * WARNING! WARNING! WARNING! WARNING! WARNING! WARNING! 
 */

/**
 * @desc Returns the correct URL for a QR-code
 * @param {String} urlBase URL to the server
 * @param {Number} [urlVersion=2] Can be 1 or 2, for new codes should always be 2
 * @param {UUID} [props.assetId] Must be set if locationId is not set
 * @param {UUID} [props.locationId] Must be set if assetId is not set
 * @param {UUID} props.instanceId
 * @param {QRTDSettingsLinkTemplate} [props.settings]
 * @param {String} [props.pathSuffix]
 * @return {String}
 */
exports.getUrl = function (urlBase, urlVersion = 2, props = {}) {
  const { instanceId, settings = {}, pathSuffix = '' } = props;

  // make sure these are never included at the same time
  let { assetId, locationId } = props;
  if (assetId) locationId = null;
  if (locationId) assetId = null;

  const parsed = url.parse(urlBase);
  const searchPathname = qs.stringify({
    ..._.reduce(settings, (out, value, key) => {
      switch (key) {
        default:
          out[key] = toQueryValue(value);
          break;

        case 'features':
          if (urlVersion === 1) {
            // version 1 has extended features in "features"
            out.features = toQueryValue(value);
          } else if (urlVersion === 2 && _.isArray(value)) {
            // version 2 has shortened features in "f"
            out.f = value.map(feature => exports.shortenFeature(feature)).filter(v => v).join(',');
          }
          break;

        case 'infoTextOrdinal':
          if (parseInt(value, 10) > 0) out.i = value;
          break;

        case 'fieldMapOrdinal':
          if (parseInt(value, 10) > 0) out.fmo = value;
          break;
      }
      return out;
    }, {}),
    iid: instanceId,
    ...(_.omitBy({
      aid: assetId,
      lid: locationId,
    }, v => !v)),
  });
  return url.format({
    ...parsed,
    pathname: parsed.pathname + '/' + urlVersion + '/' + Buffer.from(searchPathname).toString('base64') + pathSuffix,
  });

  function toQueryValue (value) {
    if (_.isArray(value)) return value.join(',');
    return value;
  }
};

/**
 * @desc Shortens a feature name according to version 2 links
 * @param {String} feature
 * @return {String}
 */
exports.shortenFeature = feature => {
  switch (feature) {
    default: return null;
    case 'info': return 'i';
    case 'listIncidents': return 'li';
    case 'reportIncident': return 'ri';
    case 'listLinkedAssets': return 'lla';
    case 'reportIncidentForLinkedAsset': return 'rifla';
    case 'knowledgeBase': return 'kb';
    case 'operationsManagement': return 'om';
  }
};

/**
 * @desc Unshortens a feature name according to version 2 links
 * @param {String} feature
 * @return {String}
 */
exports.unshortenFeature = feature => {
  switch (feature) {
    default: return null;
    case 'i': return 'info';
    case 'li': return 'listIncidents';
    case 'ri': return 'reportIncident';
    case 'lla': return 'listLinkedAssets';
    case 'rifla': return 'reportIncidentForLinkedAsset';
    case 'kb': return 'knowledgeBase';
    case 'om': return 'operationsManagement';
  }
};

/**
 * @desc Signs a QR-link URL with the signing key, appending the sig as a query parameter
 * @param {String} signingKey
 * @param {String} uri URL that will be the basis of the signature
 * @param {String} [uriToAppend] URL that the signature will be appended to
 * @return {Promise<String>}
 */
exports.signUrl = function (signingKey, uri, uriToAppend) {
  return sha1(uri + signingKey).then(sig => {
    const parsed = url.parse(uriToAppend || uri);
    const parsedSearch = qs.parse(parsed.search, {ignoreQueryPrefix: true});
    parsed.search = qs.stringify({...parsedSearch, sig});
    return url.format(parsed);
  });
};

/**
 * @desc Returns true if urlWithSig was signed with signingKey
 * @param {String} signingKey
 * @param {String} urlWithSig
 * @return {Promise<Boolean>}
 */
exports.validateSignature = async function (signingKey, urlWithSig) {
  const parsedUrl = url.parse(urlWithSig);
  const parsedSearch = qs.parse(parsedUrl.query, {ignoreQueryPrefix: true});
  const urlWithoutSig = url.format({
    ...parsedUrl,
    search: qs.stringify(_.omit(parsedSearch, 'sig')),
  });
  const urlResigned = await exports.signUrl(signingKey, urlWithoutSig);
  return urlResigned === urlWithSig;
};
