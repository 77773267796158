import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ModalButton } from 'client/components/Helpers/Modal';
import classNames from 'classnames';

export class HelpButton extends Component {

  static propTypes = {
    HelpModal: propTypes.elementType.isRequired,
    helpModalProps: propTypes.object,
  }

  static defaultProps = {
    helpModalProps: {}
  }

  render() {
    const { className, helpModalProps, HelpModal } = this.props;
    const classes = classNames(className, 'help-modal-btn');
    return (
      <ModalButton className={classes} ModalComponent={HelpModal} modalProps={helpModalProps}>
        <i className="icon mdi mdi-help"></i>{' '}
        Help
      </ModalButton>
    );
  }

}
