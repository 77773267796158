export const reduceSetColumns = (ACTION_NAME, state, action) => {
  switch(action.type) {
    default: return state;
    case ACTION_NAME: return {
      ...state,
      columns: action.columns,
    };
  }
};

/**
 * @desc Helper to reduce the common table actions FILTER, SET_PAGE and ORDERBY
 */
export const reduceTableActions = ([FILTER, SET_PAGE, ORDERBY], state, action) => {
  switch(action.type) {
    default: return state;
    case FILTER: return {
      ...state,
      filter: action.filter
    };
    case SET_PAGE: return {
      ...state,
      page: action.page
    };
    case ORDERBY: return {
      ...state,
      orderBy: action.orderBy,
      orderDirection: action.orderDirection
    };
  }
};
