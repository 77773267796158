import React  from 'react';
import propTypes from 'prop-types';
import { BeWizardTitle } from 'client/components/Be/BeWizard';
import { connect } from 'react-redux';
import * as fieldMapperActions from 'client/redux/fstd/fieldMapperActions';
import FSTDTestFile from 'client/components/FSTD/FSTDTestFile';
import FSTDFieldMapperItem from 'client/components/FSTD/components/FSTDFieldMapperItem';
import BeLoading from 'client/components/Be/BeLoading';
import { SubmissionError } from 'redux-form';
import { onInstanceFstdFieldMapperDelete } from 'client/redux/fstd/eventHandlerActions';
import { INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS } from 'client/redux/fstd/constants';
import InstancePauseAlert from 'client/components/Instance/components/InstancePauseAlert';
import _ from 'lodash';

class FSTDFieldMapper extends React.Component {

  static defaultProps = {
    isSetupMode: false,
    disabled: false,
  }

  static propTypes = {
    isSetupMode: propTypes.bool.isRequired,
    instance: propTypes.object.isRequired,
    disabled: propTypes.bool,
    endpointsByType: propTypes.object.isRequired,
    onNudgeStep: propTypes.func,
    newFieldMap: propTypes.object,
    testFileName: propTypes.string,
    testFileColumns: propTypes.arrayOf(propTypes.string),
  }

  componentDidMount() {
    const { instance: { id } } = this.props;
    this.props.instanceFstdFieldMapperRead(id);
  }

  handleClickNew = () => {
    const { testFileName } = this.props;
    return this.props.instanceFstdFieldMapperNew(testFileName);
  }

  handleEditFieldMap = (fileName, assetTemplateId, isEditing = true) => {
    this.props.instanceFstdFieldMapperEdit(fileName, assetTemplateId, isEditing);
    if(isEditing) {
      const { endpointsByType: { topdesk: { id:topdeskEndpointId } } } = this.props;
      return this.props.instanceFstdFieldMapperReadFieldTargets(topdeskEndpointId, fileName, assetTemplateId);
    }
  }

  handleSaveFieldMap = (fields, isNew) => {
    const { instance: { id }, fileMap } = this.props;
    const { fileName, assetTemplateId, map = {}, links = {}, assignments = {}, settings } = fields;

    if(isNew) {
      // check so that we are not saving an already defined combination
      const exists = fileMap.find(fileMap => {
        if(fileMap.isNew) return false; // skip self
        return fileMap.fileName === fileName && fileMap.assetTemplateId === assetTemplateId;
      });
      if(exists) {
        const msg = 'This file has already been mapped to this template';
        const errors = {fileName: msg};
        throw new SubmissionError(errors);
      }
    }

    return this.props.instanceFstdFieldMapperSave(id, fileName, assetTemplateId, map, links, assignments, settings).then(result => {
      if(result.type !== INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS) return result;
      if(isNew) {
        const { endpointsByType: { topdesk: { id:topdeskEndpointId } } } = this.props;
        return this.props.instanceFstdFieldMapperReadFieldTargets(topdeskEndpointId, fileName, assetTemplateId);
      }
    });
  }

  handleDeleteFieldMap = (fileName, assetTemplateId, isNew) => {
    const { instance: { id } } = this.props;
    if(isNew) return this.props.instanceFstdFieldMapperNewDelete();
    return this.props.instanceFstdFieldMapperDelete(id, fileName, assetTemplateId).then(this.props.onInstanceFstdFieldMapperDelete);
  }

  renderNewButton() {
    const { disabled } = this.props;
    const hasNew = this.props.fileMap.some(({isNew}) => isNew);
    if(hasNew) return null;
    return (
      <div className="text-center">
        <button
          type="button"
          disabled={disabled}
          onClick={this.handleClickNew}
          className="btn btn-default btn-rounded btn-lg"
        >
          New field map
        </button>
      </div>
    );
  }

  renderFieldMapItems() {
    const { disabled, fileMap = [], endpointsByType: { topdesk }, testFileColumns } = this.props;
    return fileMap.map(fieldMap => {
      const { fileName, assetTemplateId, map, links, assignments, settings, ...rest } = fieldMap;
      const initialValues = { fileName, assetTemplateId, map, links, assignments, settings };
      const id = `${fileName}-${assetTemplateId}`;
      return <FSTDFieldMapperItem
        form={`fstdFieldMapperItem${id}`}
        key={id}
        id={id}
        {...rest}
        disabled={disabled}
        testFileColumns={testFileColumns}
        onEdit={this.handleEditFieldMap}
        onDelete={this.handleDeleteFieldMap}
        onSubmit={fields => this.handleSaveFieldMap(fields, rest.isNew)}
        topdeskEndpointId={topdesk.id}
        initialValues={initialValues}
      />
    });
  }

  render() {
    const { instance, isReading, disabled } = this.props;
		return (
      <div className="group-border-dashed">
        <BeWizardTitle subTitle="Customize how incoming data is mapped to TOPdesk Asset fields">Field Mapper</BeWizardTitle>
        {disabled && <InstancePauseAlert />}
        <hr />
        <FSTDTestFile instanceId={instance.id} />
        <hr />
        <BeLoading loading={isReading}>
          <BeWizardTitle subTitle="Shows which file names are mapped to which TOPdesk Asset templates">Active Field Maps</BeWizardTitle>
          {this.renderFieldMapItems()}
          {this.renderNewButton()}
        </BeLoading>
      </div>
		);
  }
}

const actions = {
  ...fieldMapperActions,
  onInstanceFstdFieldMapperDelete,
};

const mapStateToProps = state => ({
  ...state.fstd.fieldMapper,
  testFileName: _.get(state, 'fstd.testFile.testFile.originalName'),
  testFileColumns: _.get(state, 'fstd.testFile.testFile.data.0', []),
});

export default connect(mapStateToProps, actions)(FSTDFieldMapper);
