import React from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import Time from 'client/components/Helpers/Time';
import { connect } from 'react-redux';
import { accountChildrenDelete, accountChildrenRead } from 'client/redux/account/actions';
import BeLoading from 'client/components/Be/BeLoading';
import {ACCOUNT_CHILDREN_DELETE_SUCCESS} from 'client/redux/account/constants';

class AccountChildren extends React.Component {

  componentDidMount() {
    this.props.accountChildrenRead();
  }

  handleDelete = childUserId => {
    return this.props.accountChildrenDelete(childUserId).then(result => {
      if (result.type === ACCOUNT_CHILDREN_DELETE_SUCCESS) {
        return this.props.accountChildrenRead();
      }
      return result;
    });
  }

  render() {
    const { isLoading, error, accountChildren } = this.props;
    return (
      <MainContent>
        <Content>
          <Helmet>
            <title>Sub Accounts</title>
          </Helmet>
          <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
            <div className="panel-heading panel-heading-divider">
              Sub-Accounts
              <span className="panel-subtitle">
                You can manage your sub-accounts here.
              </span>
            </div>
            <div className="panel-body">
              <p>
                Every sub-account has complete access to all of your instances but does not have access to your billing or your own account settings. They can not delete your own instances, but they can have their own separate instances and billing that you will not be able to see.
              </p>
              <p>
                Have your desired sub-accounts sign up for the Linknow platform themselves and then email <a href="mailto:support@linknow.io">support@linknow.io</a> <strong>using the email addresses of your current account</strong> with a list of the accounts that you want to add and we will arrange to have them added as sub-accounts here.
              </p>
							<BeRemoteErrorAlert error={error} />
              <BeLoading
                positionStatic={true}
                loading={isLoading}
                minHeight="75"
              >
                <table className="table table-border table-responsive">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Last Login</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountChildren && !accountChildren.length && (
                      <tr>
                        <td colSpan={4}>There are no sub-accounts for the current user</td>
                      </tr>
                    )}
                    {accountChildren.map(child => (
                      <tr key={child.id}>
                        <td>{child.displayName}</td>
                        <td>
                          <a href={`mailto:${child.email}`}>{child.email}</a>
                        </td>
                        <td>
                          {child.lastLoginAt && (
                            <Time time={child.lastLoginAt} format="YYYY-MM-DD HH:mm" />
                          )}
                          {!child.lastLoginAt && 'Never'}
                        </td>
                        <td className="text-right">
                          <ModalConfirmButton
                            className="btn btn-default"
                            callback={() => this.handleDelete(child.id)}
                            contentProps={{
                              title: 'Confirm sub-account removal',
                              text: 'Are you sure you want to remove this account as your sub-account?'
                            }}
                            Content={ModalContentConfirmWarning}
                          >
                            <i className="icon mdi mdi-delete"></i>{' ' }
                            Remove
                          </ModalConfirmButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </BeLoading>
            </div>
          </div>
        </Content>
      </MainContent>
    );
  }
}

const actions = {
  accountChildrenDelete,
  accountChildrenRead,
};

export default connect(state => state.account.children, actions)(AccountChildren);
