import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { Placeholder } from 'client/components/Helpers/Strings';

export default class SupportAside extends Component {

  static propTypes = {
    isAdmin: propTypes.bool,
    categories: propTypes.array,
    questionsByCategory: propTypes.object
  }

  state = {
    showContent: true
  }

  toggleShowContent = ev => {
    this.setState({showContent: !this.state.showContent});
  }

  renderCategories() {
    const { categories } = this.props;
    return (categories || []).map(category => {
      return (
        <a key={category.id} href={`#${category.slug}`} className="list-group-item">
          <i className="icon mdi mdi-chevron-right nudge-down"></i>
          <Placeholder placeholder={<em>(No title)</em>}>
            {category.title}
          </Placeholder>
        </a>
      )
    });
  }

  render() {
    return (
      <aside className="page-aside">
        <div className="be-scroller">
          <div className="aside-content">
            <div className="content">
              <div className="aside-header">
                <button data-target=".aside-nav" data-toggle="collapse" type="button" className="navbar-toggle" onClick={this.toggleShowContent}>
                  <span className="icon mdi mdi-caret-down"></span>
                </button>
                <span className="title">
                  All categories
                </span>
                <p className="description">Go directly to a category</p>
              </div>
            </div>
            <Collapse className="side-nav xs-p-10" in={this.state.showContent}>
              <div className="list-group xs-mb-0">
                {this.renderCategories()}
              </div>
            </Collapse>
            <div className="xs-p-10 xs-pt-0 xs-mt-10">
              <address className="text-center">
                You can also send an email to
                <br />
                <a href="mailto:support@linknow.io">support@linknow.io</a>
              </address>
            </div>
          </div>
        </div>
      </aside>
    );
  }

}

