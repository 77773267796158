import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { isFilled, isString, isEmail } from 'client/redux/validators';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { metaToValidationState } from 'client/redux/formHelpers';
import { Form, Field, reduxForm } from 'redux-form';
import MaybeBeFormFieldError from 'client/components/Be/MaybeBeFormFieldError';
import propTypes from 'prop-types';

class AccountSettingsForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
    showPart: propTypes.object.isRequired,
    onTogglePart: propTypes.func.isRequired
  }

  renderFormComponent = data => {
    const id = `accountSettingsForm-${data.input.name}`;
    return (
      <FormGroup controlId={id} validationState={metaToValidationState(data.meta)}>
        <ControlLabel bsClass="col-sm-3 control-label">{data.label}</ControlLabel>
        <div className="col-sm-6">
          <input id={id} className="form-control" defaultValue={data.defaultValue} {...data.input} placeholder={data.placeholder} type={data.type} />
          <FormControl.Feedback />
          <MaybeBeFormFieldError {...data.meta} />
        </div>
      </FormGroup>
    );
  }

  renderEmailReveal() {
    if(this.props.showPart.email) {
      return (
        <div>
          <Field
            name="email.one"
            component={this.renderFormComponent}
            type="text"
            label="New Email"
            placeholder="Enter new email address"
            validate={[isString(), isFilled(), isEmail()]}
          />
          <Field
            name="email.two"
            component={this.renderFormComponent}
            type="text"
            label="Confirm Email"
            placeholder="Confirm new email address"
            validate={[isString(), isFilled(), isEmail()]}
          />
        </div>
      );
    } else {
      return (
        <div className="form-group">
          <label className="control-label col-sm-3">Current Email</label>
          <div className="col-sm-6">
            <div className="input-group">
              <input disabled="disabled" className="form-control" type="email" value={this.props.initialEmail} />
              <span className="input-group-btn">
                <button onClick={ev => { this.props.onTogglePart('email', true); }} type="button" className="btn btn-default">Edit email</button>
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  renderPasswordReveal() {
    if(this.props.showPart.password) {
      return (
        <div>
          <Field
            name="password.now"
            component={this.renderFormComponent}
            type="password"
            label="Current Password"
            placeholder="Enter current password"
            validate={[isString(), isFilled()]}
          />
          <Field
            name="password.one"
            component={this.renderFormComponent}
            type="password"
            label="New Password"
            placeholder="Enter new password"
            validate={[isString(), isFilled()]}
          />
          <Field
            name="password.two"
            component={this.renderFormComponent}
            type="password"
            label="Confirm new password"
            placeholder="Confirm new password"
            validate={[isString(), isFilled()]}
          />
        </div>
      );
    } else {
      return (
        <div className="form-group">
          <label className="control-label col-sm-3">Current Password</label>
          <div className="col-sm-6">
            <div className="input-group">
              <input disabled="disabled" className="form-control" type="password" defaultValue="**********" />
              <span className="input-group-btn">
                <button onClick={ev => this.props.onTogglePart('password', true)} type="button" className="btn btn-default">Edit password</button>
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { invalid, submitting } = this.props;
    return (
      <Form className="form-horizontal group-border-dashed" onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <Field
          name="fullName"
          component={this.renderFormComponent}
          type="text"
          label="Full name"
          placeholder="Enter full name"
          validate={[]}
        />
        {this.renderEmailReveal()}
        {this.renderPasswordReveal()}
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <div className="pull-right">
              <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save profile</ButtonWaiting>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const validateForm = values => {
  const errors = {};
  if(values.password && values.password.one && values.password.two) {
    if(values.password.one !== values.password.two) {
      errors.password = {...(errors.password), two: 'Does not match'};
    }
  }
  if(values.email && values.email.one && values.email.two) {
    if(values.email.one !== values.email.two) {
      errors.email = {...(errors.email), two: 'Does not match'};
    }
  }
  return errors;
}

export default reduxForm({
  form: 'accountSettings',
  validate: validateForm,
  enableReinitialize: true
})(AccountSettingsForm);
