import { apiAction } from 'client/redux/apiHelpers';

export const confirmUser = (types, token) => {
  return apiAction({
    endpoint: '/api/user/confirm',
    method: 'POST',
    types,
    json: {token}
  });
};

