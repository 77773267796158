import { CONNECTOR_SELECT_PLAN, CONNECTOR_SELECT_PLAN_CREATE, CONNECTOR_INSTANTIATE } from 'client/redux/connector/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  isReading: false,
  isSubscribing: false,
  isInstantiating: false,
  connector: null,
  plans: null,
  paymentContext: null,
};

export default function selectPlanReducer(state = defaultState, action) {
  state = reduceByTypes(CONNECTOR_SELECT_PLAN, state, action, {
    requestProp: 'isReading', 
    successPickProps: ['connector', 'plans', 'paymentContext'],
  });
  state = reduceByTypes(CONNECTOR_SELECT_PLAN_CREATE, state, action, {
    requestProp: 'isSubscribing', 
    successProp: 'subscriptionResult',
  });
  state = reduceByTypes(CONNECTOR_INSTANTIATE, state, action, {
    requestProp: 'isInstantiating', 
    successProp: 'instantiateResult',
  });
  return state;
};
