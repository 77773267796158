import React from 'react';
import { escapeDottedName } from 'client/redux/formHelpers';
import FieldMapFormComponent from 'client/components/FieldMap/FieldMapFormComponent';
import FieldMapTargetFieldsFilter from 'client/components/FieldMap/FieldMapTargetFieldsFilter';
import { Field } from 'redux-form';

export default function FieldMapTargetFields (props) {
  const {
    fieldMap,
    fieldName,
    fieldBase,
    getLabel,
    ...otherProps
  } = props;
  const [filter, setFilter] = React.useState('defined');
  if(!fieldMap) return null;
  const { targets = [], defaultTargetToSourceMap } = fieldMap;
  return (
    <div>
      <FieldMapTargetFieldsFilter base={fieldBase} value={filter} onChange={setFilter} />
      {targets && targets.map(target => (
        <Field
          key={target.name}
          target={target}
          defaultSource={defaultTargetToSourceMap[target.name]}
          name={`${fieldName}.${escapeDottedName(target.name)}`}
          base={fieldBase}
          component={FieldMapFormComponent}
          label={getLabel(target.name)}
          showFilter={filter}
          {...otherProps}
        />
      ))}
    </div>
  );
}
