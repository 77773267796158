import { uiProps } from 'client/redux/ui/actionCreators';
import { UI_PROPS } from 'client/redux/ui/constants';
import * as actionCreators from 'client/redux/auth/actionCreators';
import { LOGOUT, LOGIN, AUTH_SWITCH_USER, AUTH_SWITCH_USER_RETURN, AUTH_SWITCH_USER_SUCCESS, AUTH_SWITCH_USER_RETURN_SUCCESS } from 'client/redux/auth/constants';

export const login = form => dispatch => {
  return dispatch(actionCreators.login(LOGIN, form));
};

export const logout = () => dispatch => {
  return dispatch(actionCreators.logout(LOGOUT));
};

export const switchUser = userId => dispatch => {
  return dispatch(actionCreators.switchUser(AUTH_SWITCH_USER, userId)).then(result => {
    if(result && result.type === AUTH_SWITCH_USER_SUCCESS) {
      dispatch(uiProps(UI_PROPS, {refresh: true}));
    }
    return result;
  });
};

export const switchUserReturn = () => dispatch => {
  return dispatch(actionCreators.switchUserReturn(AUTH_SWITCH_USER_RETURN)).then(result => {
    if(result && result.type === AUTH_SWITCH_USER_RETURN_SUCCESS) {
      dispatch(uiProps(UI_PROPS, {refresh: true}));
    }
    return result;
  });
};
