import { SETTINGS_PANEL_READ, SETTINGS_PANEL_UPDATE } from 'client/redux/settings/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  setting: {},
  isUpdating: false,
  isReading: false
};

export default function panelReducer(state = defaultState, action) {
  state = reduceByTypes(SETTINGS_PANEL_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true});
  state = reduceByTypes(SETTINGS_PANEL_READ, state, action, {requestProp: 'isReading', successSpread: true});
  return state;
};
