import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceFstdTestFileUploadSuccess = (type, testFile) => ({
  type,
  testFile,
});

export const instanceFstdTestFileUploadError = (type, error) => ({
  type,
  error,
});

export const instanceFstdFieldMapperNew = (type, currentTestFileName) => ({type, currentTestFileName});

export const instanceFstdFieldMapperEdit = (type, fileName, assetTemplateId, isEditing) => ({type, meta: {fileName, assetTemplateId}, isEditing});

export const instanceFstdFieldMapperNewDelete = (type) => ({type});

export const instanceFstdFieldMapperRead = (types, instanceId) => apiAction({
  endpoint: `/api/instance/fstd/${instanceId}/settings/fileMap`,
  method: 'GET',
  types: types.map(type => ({type, meta: {instanceId}})),
}); 

export const instanceFstdFieldMapperSave = (types, instanceId, fileName, assetTemplateId, map = {}, links = {}, assignments = {}, settings) => apiAction({
  endpoint: `/api/instance/fstd/${instanceId}/settings/fileMap/${fileName}/${assetTemplateId}`,
  method: 'PUT',
  json: {
    map: map || {},
    links: links || {},
    assignments: assignments || {},
    settings,
  },
  types: types.map(type => ({type, meta: {instanceId, fileName, assetTemplateId, assignments}})),
}); 

export const instanceFstdFieldMapperDelete = (types, instanceId, fileName, assetTemplateId) => apiAction({
  endpoint: `/api/instance/fstd/${instanceId}/settings/fileMap/${fileName}/${assetTemplateId}`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {instanceId, fileName, assetTemplateId}})),
});

export const instanceFstdGetTestFile = (types, instanceId) => {
  return apiAction({
    endpoint: `/api/instance/fstd/${instanceId}/test/file`,
    method: 'GET',
    types
  });
};

export const instanceFstdDeleteTestFile = (types, instanceId) => {
  return apiAction({
    endpoint: `/api/instance/fstd/${instanceId}/test/file`,
    method: 'DELETE',
    types
  });
};

export const instanceFstdCall = (types, id, method, data = {}) => {
  return apiAction({
    endpoint: `/api/instance/fstd/${id}/call/${method}`,
    method: 'POST',
    json: data,
    types
  });
};

export const instanceFstdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/fstd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceFstdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/fstd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceFstdFileTest = (types, instanceId, method) => apiAction({
  endpoint: `/api/instance/fstd/${instanceId}/test/file/${method}`,
  method: 'POST',
  json: {},
  types: types.map(type => ({type, meta: {instanceId, method}})),
});
