import { combineReducers } from 'redux';

import options from 'client/redux/mwtd/optionsReducer';
import statistics from 'client/redux/mwtd/statisticsReducer';
import test from 'client/redux/mwtd/testReducer';

const mwtdReducer = combineReducers({
  options,
  statistics,
  test,
});

export default mwtdReducer;
