import * as actionCreators from 'client/redux/fatdi/actionCreators';
import * as constants from 'client/redux/fatdi/constants';

export const instanceFatdiTestObjectAdd = (id, objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdiTestObjectAdd(constants.INSTANCE_FATDI_TEST_OBJECT_ADD, id, objectType, objectId));
};

export const instanceFatdiTestObjectRemove = (objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdiTestObjectRemove(constants.INSTANCE_FATDI_TEST_OBJECT_REMOVE, objectType, objectId));
};

export const instanceFatdiTestObjectGraph = (id, objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdiTestObjectGraph(constants.INSTANCE_FATDI_TEST_OBJECT_FASTOBJECTGRAPH, id, objectType, objectId));
};

export const instanceFatdiSyncObject = (id, objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdiSyncObject(constants.INSTANCE_FATDI_SYNC_OBJECT, id, objectType, objectId));
};

export const instanceFatdiObjectTestClear = () => dispatch => {
  return dispatch(actionCreators.instanceFatdiObjectTestClear(constants.INSTANCE_FATDI_TEST_CLEAR));
};

export const instanceFatdiObjectSearchFieldChange = (objectType, objectId) => dispatch => {
  return dispatch(actionCreators.instanceFatdiObjectSearchFieldChange(constants.INSTANCE_FATDI_TEST_OBJECT_SEARCH_FIELD_CHANGE, objectType, objectId));
};
