import React from 'react';
import { connect } from 'react-redux';
import * as testActions from 'client/redux/pvtd/testActions';
import * as metaActions from 'client/redux/pvtd/metaActions';
import BeLoading from 'client/components/Be/BeLoading';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import Icon from 'client/components/Be/Icon';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';

class PVTDTester extends React.Component {

  componentDidMount() {
    const instanceId = this.props.instance?.id;
    this.props.instancePvtdReadMeta(instanceId);
  }

  render() {
    const {
      isReadingMeta,
      errorMeta,
      meta,
    } = this.props;
    return (
      <div>
        <InstanceHelpHeader helpFragmentId="instance_pvtd_test_help" subTitle="Here you can test the registration form">
          Test
        </InstanceHelpHeader>

        <div role="alert" className="alert alert-primary alert-icon">
          <div className="icon"><span className="mdi mdi-info-outline"></span></div>
          <div className="message">
            <strong>Note:</strong> You can include the below URL as an iframe in your TOPdesk instance
          </div>
        </div>

        <h4>Registration Form Preview</h4>
        <BeRemoteErrorAlert error={errorMeta} />
        <BeLoading loading={isReadingMeta}>
          {meta && meta.pvUrl && (
            <>
              <div className="input-group">
                <input className="form-control" type="text" value={meta.pvUrl} disabled />
                <CopyToClipboard text={meta.pvUrl}>
                  <div className="input-group-btn">
                    <Button title="Copy to clipboard"><Icon icon="copy" /></Button>
                  </div>
                </CopyToClipboard>
              </div>
              <div className="xs-mt-10">
                <iframe src={meta.pvUrl} height="400" title="PV Form" style={{width: '100%'}}></iframe>
              </div>
            </>
          )}
        </BeLoading>

      </div>
    );
  }
}

const actions = {
  ...testActions,
  ...metaActions,
};

export default connect(state => state.pvtd.test, actions)(PVTDTester);
