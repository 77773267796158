import React from 'react';
import { connect } from 'react-redux';
import { AlertWithMessage } from 'client/components/Be/Alert';
import { flashSeen } from 'client/redux/flashAlert/actions';

class FlashAlert extends React.Component {

  componentDidMount () {
    this.props.flashSeen();
  }

  render () {
    const { alert } = this.props;
    return (
      <>
        {alert && (
          <AlertWithMessage alertType={alert.alertType} dismissible={true} icon={true}>
            <strong>{alert.strong}</strong> {alert.msg}
          </AlertWithMessage>
        )}
      </>
    );
  }

}

export default connect(state => state.flashAlert, { flashSeen })(FlashAlert);
