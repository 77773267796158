import { USER_INVOICE_READ, USER_INVOICE_READ_FORM } from 'client/redux/user/invoice/constants';
import * as actionCreators from 'client/redux/user/invoice/actionCreators';

export const userInvoiceRead = (id, query) => dispatch => {
  return dispatch(actionCreators.userInvoiceRead(USER_INVOICE_READ, id, query));
};

export const userInvoiceReadForm = (form = {}) => {
  return actionCreators.userInvoiceReadForm(USER_INVOICE_READ_FORM, form);
};
