import {
  INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ,
  INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
  INSTANCE_MWTD_OPTIONS_READ_META,
} from 'client/redux/mwtd/constants';
import { endpointTopdeskAssetTemplateFieldTargets } from 'client/redux/endpoint/topdesk/actionCreators';
import { instanceMwtdReadMeta } from 'client/redux/mwtd/actionCreators';

export const instanceMwtdOptionsAssetFieldTargetsRead = (topdeskEndpointId, assetTemplateId) => dispatch => dispatch(endpointTopdeskAssetTemplateFieldTargets(INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_READ.map(type => ({type, meta: {assetTemplateId}})), topdeskEndpointId, assetTemplateId));

export const instanceMwtdOptionsAssetFieldTargetsReset = () => dispatch => dispatch({
  type: INSTANCE_MWTD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
});

export const instanceMwtdOptionsReadMeta = instanceId => dispatch => dispatch(instanceMwtdReadMeta(INSTANCE_MWTD_OPTIONS_READ_META, instanceId));
