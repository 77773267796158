import { combineReducers } from 'redux';

import selectPlan from 'client/redux/connector/selectPlanReducer';
import panel from 'client/redux/connector/panelReducer';
import index from 'client/redux/connector/indexReducer';

const connectorReducer = combineReducers({
  index,
  panel,
  selectPlan,
});

export default connectorReducer;
