import React, { Component } from 'react';
import propTypes from 'prop-types';
import BeLoading from 'client/components/Be/BeLoading';

export default class InstanceWidgetStatistic extends Component {

  static defaultProps = {
    componentProps: {}
  }

  static propTypes = {
    title: propTypes.string.isRequired,
    data: propTypes.object,
    Component: propTypes.func,
    componentProps: propTypes.object
  }

  render() {
    const { title, Component, data, componentProps, isReading } = this.props;
    const style = {padding: '20px'};
    return (
      <BeLoading loading={isReading}>
        <div className="widget widget-tile widget-border" style={style}>
          {data && Component && (
            <div className="chart sparkline">
              <Component {...componentProps} data={data} />
            </div>
          )}
          <div className="data-info">
            <div className="desc">{title}</div>
            <div className="value">
              <span className="indicator indicator-equal mdi mdi-chevron-right"></span>
              {data && <span data-toggle="counter" data-end="0" className="number">{data.sum}</span>}
            </div>
          </div>
        </div>
      </BeLoading>
    );
  }

}
