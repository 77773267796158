import React, { Component } from 'react';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import BeLoading from 'client/components/Be/BeLoading';
import * as uiFragmentSingleActions from 'client/redux/uiFragment/singleActions';
import { Modal } from 'react-bootstrap';
import { get } from 'lodash';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import UIFragmentForm from 'client/components/UIFragment/components/UIFragmentForm';

class UIFragmentModal extends Component {

  static propTypes = {
    onClose: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    fragmentId: propTypes.string.isRequired,
    fragment: propTypes.object,
    onFragmentWasUpdated: propTypes.func,
  }

  static defaultProps = {
    onFragmentWasUpdated: () => {},
  }

  state = {
    isEditing: false
  }

  getData(fragmentId) {
    return this.props.uiFragmentRead(fragmentId);
  }

  componentDidUpdate (oldProps) {
    if(this.props.open && this.props.open !== oldProps.open) {
      this.getData(this.props.fragmentId);
    }
  }

  handleSave = fields => {
    const { fragmentId, fragment: { isNew }, uiFragmentUpdate, uiFragmentCreate, onFragmentWasUpdated } = this.props;
    const fn = isNew ? uiFragmentCreate : uiFragmentUpdate;
    return fn(fragmentId, fields).then(res => {
      this.setState({isEditing: false});
      return res;
    }).then(onFragmentWasUpdated);
  }

  handleToggleEditing = ev => {
    this.setState({isEditing: !this.state.isEditing});
  }

  renderBody() {
    const { html, markdown, title, renderer } = this.props.fragment;
    const { isEditing } = this.state;
    if(isEditing) {
      const initialValues = {title, markdown, renderer};
      return (
        <UIFragmentForm
          onSubmit={this.handleSave}
          initialValues={initialValues}
        />
      );
    }
    return <div dangerouslySetInnerHTML={{__html: html}}></div>;
  }

  renderError() {
    const { error } = this.props;
    if(!error) return null;
    return <BeRemoteErrorAlert error={error} />
  }

  render() {
    const { isReading, open, onClose, fragment } = this.props;
    const title = fragment && fragment.title ? fragment.title : 'No title';
    const bodyStyle = isReading ? {padding: '40px'} : {};
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <button onClick={onClose} type="button" data-dismiss="modal" aria-hidden="true" className="close modal-close">
            <span className="mdi mdi-close"></span>
          </button>{' '}
          <IsAdmin>
            <button type="button" onClick={this.handleToggleEditing} className="close xs-mr-10">
              <span className="mdi mdi-edit"></span>
            </button>
          </IsAdmin>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodyStyle}>
          <BeLoading positionStatic={true} loading={isReading}>
            {this.renderError()}
            {fragment && !isReading && this.renderBody()}
          </BeLoading>
        </Modal.Body>
      </Modal>
    );
  }

}

const mapStateToProps = (state, props) => {
  const fragmentId = props.fragmentId;
  const fragment = get(state, ['uiFragment', 'single', 'fragments', fragmentId], {});
  return {...fragment};
}

const actions = {
  ...uiFragmentSingleActions,
}

export default connect(mapStateToProps, actions)(UIFragmentModal);
