export const ACCOUNT_FORM_TOGGLE_PART = 'ACCOUNT_FORM_TOGGLE_PART';
export const ACCOUNT_FORM_HIDE_ALL_PARTS = 'ACCOUNT_FORM_HIDE_ALL_PARTS';

export const ACCOUNT_FIELDS_REQUEST = 'ACCOUNT_FIELDS_REQUEST';
export const ACCOUNT_FIELDS_SUCCESS = 'ACCOUNT_FIELDS_SUCCESS';
export const ACCOUNT_FIELDS_FAILURE = 'ACCOUNT_FIELDS_FAILURE';
export const ACCOUNT_FIELDS = [ACCOUNT_FIELDS_REQUEST, ACCOUNT_FIELDS_SUCCESS, ACCOUNT_FIELDS_FAILURE];

export const ACCOUNT_BILLING_REQUEST = 'ACCOUNT_BILLING_REQUEST';
export const ACCOUNT_BILLING_SUCCESS = 'ACCOUNT_BILLING_SUCCESS';
export const ACCOUNT_BILLING_FAILURE = 'ACCOUNT_BILLING_FAILURE';
export const ACCOUNT_BILLING = [ACCOUNT_BILLING_REQUEST, ACCOUNT_BILLING_SUCCESS, ACCOUNT_BILLING_FAILURE];

export const ACCOUNT_SUBSCRIPTION_INDEX_REQUEST = 'ACCOUNT_SUBSCRIPTION_INDEX_REQUEST';
export const ACCOUNT_SUBSCRIPTION_INDEX_SUCCESS = 'ACCOUNT_SUBSCRIPTION_INDEX_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_INDEX_FAILURE = 'ACCOUNT_SUBSCRIPTION_INDEX_FAILURE';
export const ACCOUNT_SUBSCRIPTION_INDEX = [ACCOUNT_SUBSCRIPTION_INDEX_REQUEST, ACCOUNT_SUBSCRIPTION_INDEX_SUCCESS, ACCOUNT_SUBSCRIPTION_INDEX_FAILURE];

export const ACCOUNT_SUBSCRIPTION_CREATE_REQUEST = 'ACCOUNT_SUBSCRIPTION_CREATE_REQUEST';
export const ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS = 'ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_CREATE_FAILURE = 'ACCOUNT_SUBSCRIPTION_CREATE_FAILURE';
export const ACCOUNT_SUBSCRIPTION_CREATE = [ACCOUNT_SUBSCRIPTION_CREATE_REQUEST, ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS, ACCOUNT_SUBSCRIPTION_CREATE_FAILURE];

export const ACCOUNT_SUBSCRIPTION_UPDATE_REQUEST = 'ACCOUNT_SUBSCRIPTION_UPDATE_REQUEST';
export const ACCOUNT_SUBSCRIPTION_UPDATE_SUCCESS = 'ACCOUNT_SUBSCRIPTION_UPDATE_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_UPDATE_FAILURE = 'ACCOUNT_SUBSCRIPTION_UPDATE_FAILURE';
export const ACCOUNT_SUBSCRIPTION_UPDATE = [ACCOUNT_SUBSCRIPTION_UPDATE_REQUEST, ACCOUNT_SUBSCRIPTION_UPDATE_SUCCESS, ACCOUNT_SUBSCRIPTION_UPDATE_FAILURE];

export const ACCOUNT_SUBSCRIPTION_DELETE_REQUEST = 'ACCOUNT_SUBSCRIPTION_DELETE_REQUEST';
export const ACCOUNT_SUBSCRIPTION_DELETE_SUCCESS = 'ACCOUNT_SUBSCRIPTION_DELETE_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_DELETE_FAILURE = 'ACCOUNT_SUBSCRIPTION_DELETE_FAILURE';
export const ACCOUNT_SUBSCRIPTION_DELETE = [ACCOUNT_SUBSCRIPTION_DELETE_REQUEST, ACCOUNT_SUBSCRIPTION_DELETE_SUCCESS, ACCOUNT_SUBSCRIPTION_DELETE_FAILURE];

export const ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_REQUEST = 'ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_REQUEST';
export const ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_SUCCESS = 'ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_FAILURE = 'ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_FAILURE';
export const ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT = [ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_REQUEST, ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_SUCCESS, ACCOUNT_SUBSCRIPTION_READ_PAYMENT_INTENT_FAILURE];

export const ACCOUNT_SUBSCRIPTION_PREVIEW_REQUEST = 'ACCOUNT_SUBSCRIPTION_PREVIEW_REQUEST';
export const ACCOUNT_SUBSCRIPTION_PREVIEW_SUCCESS = 'ACCOUNT_SUBSCRIPTION_PREVIEW_SUCCESS';
export const ACCOUNT_SUBSCRIPTION_PREVIEW_FAILURE = 'ACCOUNT_SUBSCRIPTION_PREVIEW_FAILURE';
export const ACCOUNT_SUBSCRIPTION_PREVIEW = [ACCOUNT_SUBSCRIPTION_PREVIEW_REQUEST, ACCOUNT_SUBSCRIPTION_PREVIEW_SUCCESS, ACCOUNT_SUBSCRIPTION_PREVIEW_FAILURE];

export const ACCOUNT_SUBSCRIPTION_TOGGLE_FIX_PROBLEM_MODAL = 'ACCOUNT_SUBSCRIPTION_TOGGLE_FIX_PROBLEM_MODAL';

export const ACCOUNT_SUBSCRIPTION_NEW = 'ACCOUNT_SUBSCRIPTION_NEW';

export const ACCOUNT_SUBSCRIPTION_CHANGE_PLAN_QUANTITY = 'ACCOUNT_SUBSCRIPTION_CHANGE_PLAN_QUANTITY';

export const ACCOUNT_SUBSCRIPTION_REMOVE_PLAN = 'ACCOUNT_SUBSCRIPTION_REMOVE_PLAN';

export const ACCOUNT_SUBSCRIPTION_ADD_PLAN = 'ACCOUNT_SUBSCRIPTION_ADD_PLAN';

export const ACCOUNT_SUBSCRIPTION_UNDO = 'ACCOUNT_SUBSCRIPTION_UNDO';

export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';
export const ACCOUNT_UPDATE = [ACCOUNT_UPDATE_REQUEST, ACCOUNT_UPDATE_SUCCESS, ACCOUNT_UPDATE_FAILURE];

export const ACCOUNT_BILLING_UPDATE_REQUEST = 'ACCOUNT_BILLING_UPDATE_REQUEST';
export const ACCOUNT_BILLING_UPDATE_SUCCESS = 'ACCOUNT_BILLING_UPDATE_SUCCESS';
export const ACCOUNT_BILLING_UPDATE_FAILURE = 'ACCOUNT_BILLING_UPDATE_FAILURE';
export const ACCOUNT_BILLING_UPDATE = [ACCOUNT_BILLING_UPDATE_REQUEST, ACCOUNT_BILLING_UPDATE_SUCCESS, ACCOUNT_BILLING_UPDATE_FAILURE];

export const ACCOUNT_ADDRESS_UPDATE_REQUEST = 'ACCOUNT_ADDRESS_UPDATE_REQUEST';
export const ACCOUNT_ADDRESS_UPDATE_SUCCESS = 'ACCOUNT_ADDRESS_UPDATE_SUCCESS';
export const ACCOUNT_ADDRESS_UPDATE_FAILURE = 'ACCOUNT_ADDRESS_UPDATE_FAILURE';
export const ACCOUNT_ADDRESS_UPDATE = [ACCOUNT_ADDRESS_UPDATE_REQUEST, ACCOUNT_ADDRESS_UPDATE_SUCCESS, ACCOUNT_ADDRESS_UPDATE_FAILURE];

export const ACCOUNT_NOTIFICATIONS_REQUEST = 'ACCOUNT_NOTIFICATIONS_REQUEST';
export const ACCOUNT_NOTIFICATIONS_SUCCESS = 'ACCOUNT_NOTIFICATIONS_SUCCESS';
export const ACCOUNT_NOTIFICATIONS_FAILURE = 'ACCOUNT_NOTIFICATIONS_FAILURE';
export const ACCOUNT_NOTIFICATIONS = [ACCOUNT_NOTIFICATIONS_REQUEST, ACCOUNT_NOTIFICATIONS_SUCCESS, ACCOUNT_NOTIFICATIONS_FAILURE];

export const ACCOUNT_NOTIFICATIONS_ORDERBY = 'ACCOUNT_NOTIFICATIONS_ORDERBY';

export const ACCOUNT_NOTIFICATIONS_SET_PAGE = 'ACCOUNT_NOTIFICATIONS_SET_PAGE';

export const ACCOUNT_NOTIFICATIONS_FILTER = 'ACCOUNT_NOTIFICATIONS_FILTER';

export const ACCOUNT_CARD_LIST_REQUEST = 'ACCOUNT_CARD_LIST_REQUEST';
export const ACCOUNT_CARD_LIST_SUCCESS = 'ACCOUNT_CARD_LIST_SUCCESS';
export const ACCOUNT_CARD_LIST_FAILURE = 'ACCOUNT_CARD_LIST_FAILURE';
export const ACCOUNT_CARD_LIST = [ACCOUNT_CARD_LIST_REQUEST, ACCOUNT_CARD_LIST_SUCCESS, ACCOUNT_CARD_LIST_FAILURE];

export const ACCOUNT_CARD_ADD_REQUEST = 'ACCOUNT_CARD_ADD_REQUEST';
export const ACCOUNT_CARD_ADD_SUCCESS = 'ACCOUNT_CARD_ADD_SUCCESS';
export const ACCOUNT_CARD_ADD_FAILURE = 'ACCOUNT_CARD_ADD_FAILURE';
export const ACCOUNT_CARD_ADD = [ACCOUNT_CARD_ADD_REQUEST, ACCOUNT_CARD_ADD_SUCCESS, ACCOUNT_CARD_ADD_FAILURE];

export const ACCOUNT_CARD_DELETE_REQUEST = 'ACCOUNT_CARD_DELETE_REQUEST';
export const ACCOUNT_CARD_DELETE_SUCCESS = 'ACCOUNT_CARD_ADD_SUCCESS';
export const ACCOUNT_CARD_DELETE_FAILURE = 'ACCOUNT_CARD_DELETE_FAILURE';
export const ACCOUNT_CARD_DELETE = [ACCOUNT_CARD_DELETE_REQUEST, ACCOUNT_CARD_DELETE_SUCCESS, ACCOUNT_CARD_DELETE_FAILURE];

export const ACCOUNT_INVOICE_INDEX_REQUEST = 'ACCOUNT_INVOICE_INDEX_REQUEST';
export const ACCOUNT_INVOICE_INDEX_SUCCESS = 'ACCOUNT_INVOICE_INDEX_SUCCESS';
export const ACCOUNT_INVOICE_INDEX_FAILURE = 'ACCOUNT_INVOICE_INDEX_FAILURE';
export const ACCOUNT_INVOICE_INDEX = [ACCOUNT_INVOICE_INDEX_REQUEST, ACCOUNT_INVOICE_INDEX_SUCCESS, ACCOUNT_INVOICE_INDEX_FAILURE];

export const ACCOUNT_INVOICE_READ_REQUEST = 'ACCOUNT_INVOICE_READ_REQUEST';
export const ACCOUNT_INVOICE_READ_SUCCESS = 'ACCOUNT_INVOICE_READ_SUCCESS';
export const ACCOUNT_INVOICE_READ_FAILURE = 'ACCOUNT_INVOICE_READ_FAILURE';
export const ACCOUNT_INVOICE_READ = [ACCOUNT_INVOICE_READ_REQUEST, ACCOUNT_INVOICE_READ_SUCCESS, ACCOUNT_INVOICE_READ_FAILURE];

export const ACCOUNT_CHILDREN_READ_REQUEST = 'ACCOUNT_CHILDREN_READ_REQUEST';
export const ACCOUNT_CHILDREN_READ_SUCCESS = 'ACCOUNT_CHILDREN_READ_SUCCESS';
export const ACCOUNT_CHILDREN_READ_FAILURE = 'ACCOUNT_CHILDREN_READ_FAILURE';
export const ACCOUNT_CHILDREN_READ = [ACCOUNT_CHILDREN_READ_REQUEST, ACCOUNT_CHILDREN_READ_SUCCESS, ACCOUNT_CHILDREN_READ_FAILURE];

export const ACCOUNT_CHILDREN_DELETE_REQUEST = 'ACCOUNT_CHILDREN_DELETE_REQUEST';
export const ACCOUNT_CHILDREN_DELETE_SUCCESS = 'ACCOUNT_CHILDREN_DELETE_SUCCESS';
export const ACCOUNT_CHILDREN_DELETE_FAILURE = 'ACCOUNT_CHILDREN_DELETE_FAILURE';
export const ACCOUNT_CHILDREN_DELETE = [ACCOUNT_CHILDREN_DELETE_REQUEST, ACCOUNT_CHILDREN_DELETE_SUCCESS, ACCOUNT_CHILDREN_DELETE_FAILURE];
