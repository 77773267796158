import React from 'react';
import propTypes from 'prop-types';
import axios from 'axios'
import { ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';
import 'client/components/Form/FileUpload.css';
import { responseToErrorMessage } from 'client/redux/apiHelpers';

export default class FileUpload extends React.Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    error: propTypes.string,
    accept: propTypes.string,
    currentFileName: propTypes.string,
    url: propTypes.string.isRequired,
    onDelete: propTypes.func,
    onUploaded: propTypes.func,
    disabled: propTypes.bool,
  }

  static defaultProps = {
    onUploaded: () => {},
  }

  state = {
    transition: false,
    progress: null,
  }

  handleFileChange = ev => {
    const { url, onUploaded } = this.props;

    const files = _.get(ev, 'target.files', []);

    if(!files.length) {
      return;
    }

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({progress: percentCompleted});
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();
    data.append('file', files[0]);

    this.setState({transition: true});

    ev.target.value = '';

    axios.post(url, data, config).then(response => {
      this.setState({transition: false}, () => this.setState({progress: null}));
      return onUploaded(null, response);
    }).catch(err => {
      let message = err.message;
      if(err.response) {
        const responseBody = _.get(err, 'response.data');
        const remoteMessage = responseToErrorMessage(responseBody, false);
        if(remoteMessage) message = remoteMessage;
      }
      this.setState({transition: false}, () => this.setState({progress: null}));
      return onUploaded(message);
    });
  }

  renderProgressStatus() {
    const { error } = this.props;
    const { progress, transition } = this.state;
    if(progress !== null) {
      const classes = classNames(transition ? '' : 'no-transition');
      return <ProgressBar
        striped
        active
        bsStyle="info"
        className={classes}
        now={progress}
      />
    }
    return <span className="error-message">{error}</span>
  }

  renderCurrentFile() {
    const { currentFileName, onDelete, disabled } = this.props;
    if(!currentFileName) return null;
    return (
      <div className="btn-group">
        <button className="btn btn-default" disabled={true} type="button">
          {currentFileName}
        </button>
        <button className="btn btn-default" onClick={onDelete} disabled={disabled} type="button">
          <i className="icon mdi mdi-delete"></i>
        </button>
      </div>
    )
  }

  render() {
    const { className, accept, id, name, disabled } = this.props;
    const classes = classNames('form-group file-upload', className);
    return (
      <div className={classes}>
        {this.renderCurrentFile()}
        <input
          type="file"
          name={name}
          id={id}
          className="inputfile"
          accept={accept}
          disabled={disabled}
          onChange={this.handleFileChange}
        />
        <label htmlFor={id} className="btn-default">
          <i className="mdi mdi-upload"></i>
          <span>Select new file</span>
        </label>
        <div className="status">
          {this.renderProgressStatus()}
        </div>
      </div>
    );
  }

}
