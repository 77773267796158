import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import { Form, Field, reduxForm } from 'redux-form';
import { isNotEmpty, isString, isURL } from 'client/redux/validators';
import { FormErrors } from 'client/components/Form/FormErrors';

const validateByField = {
  apiUrl: [isString(), isNotEmpty(), isURL()],
  apiKey: [isString(), isNotEmpty()],
};

class EndpointAzureOpenAiForm extends React.Component {

  static defaultProps = {
    isEditing: false,
    onEdit: function() {}
  }

  static propTypes = {
    isEditing: propTypes.bool.isRequired,
    onEdit: propTypes.func,
    remoteError: propTypes.object,
    initialValues: propTypes.object.isRequired,
    endpointState: propTypes.object.isRequired,
    onSubmit: propTypes.func.isRequired,
    isUpdating: propTypes.bool.isRequired,
  }

  render() {
    const {
      remoteError,
      error,
      isUpdating,
      valid,
      onEdit,
      onSubmit,
      isEditing,
      initialValues,
      handleSubmit,
    } = this.props;
    const hasInitialValues = Object.keys(validateByField).every(field => {
      return Boolean(initialValues[field]);
    });
    const isDisabled = Boolean(hasInitialValues && !isEditing);

    const fieldProps = {
      base: 'endpointAzureOpenAiForm',
      metaOptions: {defaultState: null, ignorePristine: true},
    };

    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <FormErrors className="xs-mt-0 xs-mb-10" error={error || remoteError} />
        <div>
          <Field
            {...fieldProps}
            name="apiUrl"
            component={FormReduxInput}
            type="text"
            label="API URL"
            disabled={isDisabled}
            validate={validateByField.apiUrl}
            helpText="Example: https://itsaoai.openai.azure.com/openai/deployments/ITS_FIRST_AI/extensions/chat/completions?api-version=2023-08-01-preview"
            required
          />
          <Field
            {...fieldProps}
            name="apiKey"
            component={FormReduxInput}
            type="text"
            label="API key"
            disabled={isDisabled}
            validate={validateByField.apiKey}
            helpText="Example: zq06S32f6Nhz9oKi4fdvSLvfnOYHAzH0"
            required
          />
        </div>
        <div className="form-group xs-mb-0">
          {isDisabled && (
            <button className="btn btn-default pull-right" type="button" onClick={onEdit}>
              Edit settings
            </button>
          )}
          {!isDisabled && (
            <ButtonWaiting
              type="submit"
              disabled={!valid}
              className="btn btn-default pull-right"
              isWaiting={isUpdating}
            >
              Save
            </ButtonWaiting>
          )}
          <div className="clearfix"></div>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'endpointAzureOpenAi',
  enableReinitialize: true
})(EndpointAzureOpenAiForm);
