import React from 'react';
import propTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import EndpointEditPanel from 'client/components/Endpoint/EndpointEditPanel';
import EndpointCurlTemplate from 'client/components/Endpoint/EndpointCurlTemplate';

export default class EndpointEditModal extends React.Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    endpoint: propTypes.object,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired,
  }

  state = {
    wasUpdated: false,
  }

  handleClose = () => {
    const { onClose } = this.props;
    const { wasUpdated } = this.state;
    return onClose({wasUpdated});
  }

  handleUpdated = () => {
    this.setState({wasUpdated: true});
  }

  render() {
    const { open, endpoint } = this.props;
    const { id, type } = endpoint;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={this.handleClose} id="endpointPanelModal" backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={this.handleClose} />
          <Modal.Title>{type} ({id})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EndpointEditPanel id={endpoint.id} onUpdated={this.handleUpdated} />
          <EndpointCurlTemplate endpoint={endpoint} />
        </Modal.Body>
      </Modal>
    );
  }

}

