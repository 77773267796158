import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { FormReduxInput } from 'client/components/Form/FormRedux';
import BeFormFieldErrors from 'client/components/Be/BeFormFieldErrors';
import { ButtonWaiting } from 'client/components/Button/ButtonWaiting';
import { AlertWithMessage } from 'client/components/Be/Alert';

class InstanceTitleForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
  }

  render() {
    const { handleSubmit, onSubmit, submitting, invalid, error } = this.props;
    return (
      <Form className="group-border-dashed" onSubmit={handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        <AlertWithMessage icon={true} className="alert-icon" alertType="primary">
          <strong>Note:</strong> The name set here appears on the invoices
        </AlertWithMessage>
        <Field
          base="instanceTitleForm"
          name="title"
          placeholder="Enter a value to change the default title"
          component={FormReduxInput}
          helpText="Leave empty to revert to the default title"
        />
        <div className="text-right">
          <ButtonWaiting type="submit" disabled={invalid} className="btn btn-primary btn-lg" isWaiting={submitting}>Save</ButtonWaiting>
        </div>
      </Form>
    );
  }

}

export default reduxForm({
  form: 'instanceName',
  enableReinitialize: true
})(InstanceTitleForm);
