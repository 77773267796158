export function createCsv(header, ...rows) {
  const csv = [header, ...rows].map(row => row.map(cell => '"' + cell + '"').join(';')).join('\r\n');
  return csv;
}

export function downloadAsCsv(fileName, csvStr) {
  const data = encodeURI('data:text/csv;charset=utf-8,\ufeff' + csvStr);
  const link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function ucfirst (str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

// returns a copy of list
export function moveValueInList (list, value, direction) {
  const currIndex = list.indexOf(value);
  const nextIndex = currIndex + direction;
  if (currIndex === -1 || nextIndex < 0 || nextIndex > list.length) return;

  const copy = [...list];

  if (nextIndex > currIndex) {
    // move up
    const slice = copy.slice(currIndex, nextIndex + 1);
    slice.push(slice.shift());
    copy.splice(currIndex, nextIndex - currIndex + 1, ...slice);
  } else if (nextIndex < currIndex) {
    // move down
    const slice = copy.slice(nextIndex, currIndex + 1);
    slice.unshift(slice.pop());
    copy.splice(nextIndex, currIndex - nextIndex + 1, ...slice);
  }

  return copy;
};

export function getNumberOfRows (str) {
  return typeof str === 'string' ? str.split('\n').length : 1;
}
