import { connect } from 'react-redux';
import ConnectableInstanceWidgetStatistic from 'client/components/Instance/components/ConnectableInstanceWidgetStatistic';
import { instanceMwtdReadStatistics } from 'client/redux/mwtd/statisticsActions';
import _ from 'lodash';

const mapStateToProps =  (state, props) => {
  const id = props.statistic;
  return {
    ...(state.mwtd.statistics[id] || {}),
    data: _.get(state, ['mwtd', 'statistics', id, 'data', id], null)
  };
};

const actions = {
  readStatistic: instanceMwtdReadStatistics,
};

export default connect(mapStateToProps, actions)(ConnectableInstanceWidgetStatistic);
