import React, { Component } from 'react';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import propTypes from 'prop-types';
import { ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { onInstanceDelete } from 'client/redux/instance/eventHandlerActions';
import BeLoading from 'client/components/Be/BeLoading';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import {
  instanceDashboardRead,
  instanceDashboardDelete,
  instanceDashboardUpdateStatus,
  instanceDashboardDeleteQueueContents,
} from 'client/redux/instance/dashboardActions';
import 'client/components/Instance/components/InstanceWidgets.css';

class InstanceWidgetControls extends Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    shouldReadInstance: propTypes.bool.isRequired,
    instance: propTypes.object,
    hasDeleteQueueContentsButton: propTypes.bool,
  }

  static defaultProps = {
    shouldReadInstance: false
  }

  getData(id) {
    return this.props.instanceDashboardRead(id);
  }

  componentDidMount() {
    if(this.props.shouldReadInstance) {
      this.getData(this.props.id);
    }
  }

  handleClickPause = ev => {
    const { id } = this.props;
    return this.props.instanceDashboardUpdateStatus(id, 'paused');
  }

  handleClickActivate = ev => {
    const { id } = this.props;
    return this.props.instanceDashboardUpdateStatus(id, 'active');
  }

  handleClickDelete = ev => {
    const { id } = this.props;
    return this.props.instanceDashboardDelete(id).then(this.props.onInstanceDelete).then(() => this.props.push('/'));
  }

  handleClickDeleteQueueContents = () => {
    const { id } = this.props;
    return this.props.instanceDashboardDeleteQueueContents(id);
  };

  renderPauseButton() {
    const { instance } = this.props;
    const disabled = !['active', 'paused'].includes(instance.status);
    if(instance.status === 'paused') {
      return (
        <ButtonWaitingPromise
          disabled={disabled}
          type="button"
          onClick={this.handleClickActivate}
          className="btn btn-default xs-mr-5"
        >
          <i className="icon mdi mdi-play-circle-outline"></i>{' '}
          Resume
        </ButtonWaitingPromise>
      );
    } else {
      return (
        <ButtonWaitingPromise
          disabled={disabled}
          type="button"
          onClick={this.handleClickPause}
          className="btn btn-default xs-mr-5"
        >
          <i className="icon mdi mdi-pause-circle-outline"></i>{' '}
          Pause
        </ButtonWaitingPromise>
      );
    }
  }

  renderDeleteButton() {
    const { isParentInstance } = this.props;
    if (isParentInstance) return null;
    const confirmProps = {
      title: 'Confirm instance deletion',
      text: 'Are you sure you want to delete this connector instance? All options will be lost.'
    };
    return (
      <ModalConfirmButton
        className="btn btn-default xs-mr-5"
        callback={this.handleClickDelete}
        contentProps={confirmProps}
        Content={ModalContentConfirmWarning}
      >
        <i className="icon mdi mdi-delete"></i>{' ' }
        Delete
      </ModalConfirmButton>
    );
  }

  renderDeleteQueueContentsButton () {
    const { hasDeleteQueueContentsButton } = this.props;
    if (!hasDeleteQueueContentsButton) return null;
    const confirmProps = {
      title: 'Confirm queue purge',
      text: 'Are you sure you want to purge all tasks from the queue?',
    };
    return (
      <IsAdmin>
        <ModalConfirmButton
          className="btn btn-default xs-mr-5"
          callback={this.handleClickDeleteQueueContents}
          contentProps={confirmProps}
          Content={ModalContentConfirmWarning}
          title="Purge the task queue"
        >
          <i className="icon mdi mdi-cloud-off"></i>{' ' }
          Purge
        </ModalConfirmButton>
      </IsAdmin>
    );
  }
  
  render() {
    const { className, isReading, instance } = this.props;
    const classes = classNames('panel panel-default panel-border height-max xs-pt-30 xs-pb-20', className);
    return (
      <div className={classes}>
        <BeLoading loading={isReading} className="dead-center height-max" style={{flexWrap: 'wrap'}}>
          {instance && (
            <>
              {this.renderDeleteQueueContentsButton()}
              {this.renderPauseButton()}
              {this.renderDeleteButton()}
            </>
          )}
        </BeLoading>
      </div>
    );
  }

}

const actions = {
  instanceDashboardRead,
  instanceDashboardDelete,
  instanceDashboardUpdateStatus,
  instanceDashboardDeleteQueueContents,
  onInstanceDelete,
  push
};

export default connect(state => state.instance.dashboard, actions)(InstanceWidgetControls);
