import { combineReducers } from 'redux';

import index from 'client/redux/instance/indexReducer';
import logIndex from 'client/redux/instance/log/indexReducer';
import logFragmentIndex from 'client/redux/instance/logFragment/indexReducer';
import setup from 'client/redux/instance/setupReducer';
import dashboard from 'client/redux/instance/dashboardReducer';
import view from 'client/redux/instance/viewReducer';
import settings from 'client/redux/instance/settingsReducer';
import titleModal from 'client/redux/instance/titleModalReducer';
import panel from 'client/redux/instance/panelReducer';
import optionalEndpoint from 'client/redux/instance/optionalEndpointReducer';

const instanceReducer = combineReducers({
  dashboard,
  index,
  logFragmentIndex,
  logIndex,
  optionalEndpoint,
  panel,
  settings,
  setup,
  titleModal,
  view,
});

export default instanceReducer;
