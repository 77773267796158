import React, { Component } from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { intervalSelect2, typeSelect2, isSubscribableSelect2 } from 'client/redux/plan/constants';

export default class PlanTableFilterForm extends Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  handleChange = ev => {
    const newFilter = {...this.props.filter, [ev.target.name]: ev.target.value};
    this.props.onFilter(newFilter);
  }

  render() {
    const { interval, type, isSubscribable } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="planTableFilterIsSubscribable"
              name="isSubscribable"
              className="form-control"
              data={isSubscribableSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any subscribable'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={isSubscribable}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="planTableFilterInterval"
              name="interval"
              className="form-control"
              data={intervalSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any interval'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={interval}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="planTableFilterType"
              name="type"
              className="form-control"
              data={typeSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any type'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={type}
            />
          </div>
        </div>
      </div>
    );
  }

}
