import * as constants from 'client/redux/fatd/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';
import { ucfirst } from 'client/helpers/misc';

const defaultState = {
  error: null,
  isAddingFastPartner: false,
  isAddingTopdeskPerson: false,
  searchFields: {fastPartner: '', topdeskPerson: ''},
  fastPartners: {},
  topdeskPersons: {},
};

const defaultObjectState = {
  isSyncing: false,
  isGettingObjectGraph: false,
  error: null,
  objectContainer: null,
  notices: null,
  wasLast: null,
};

const getObjectType = action => _.get(action, 'meta.objectType');
const getObjectId = action => _.get(action, 'meta.objectId');

// reduce a single object
const reduceObject = (state = defaultObjectState, action) => {
  const successPickProps = ['notices', 'fastPartner', 'topdeskPerson'];
  state = reduceByTypes(constants.INSTANCE_FATD_TEST_OBJECT_ADD, state, action, {requestProp: 'isAddingObject', successPickProps});
  state = reduceByTypes(constants.INSTANCE_FATD_SYNC_OBJECT, state, action, {requestProp: 'isSyncing', successPickProps: ['notices']});
  state = reduceByTypes(constants.INSTANCE_FATD_TEST_OBJECT_FASTOBJECTGRAPH, state, action, {requestProp: 'isGettingObjectGraph', successProp: 'objectGraph'});
  if(action.type === constants.INSTANCE_FATD_SYNC_OBJECT_SUCCESS) state.wasLast = 'synced';
  return state;
};

// these actions should pass to reduceObject
const objectActions = [
  ...constants.INSTANCE_FATD_SYNC_OBJECT,
  ...constants.INSTANCE_FATD_TEST_OBJECT_FASTOBJECTGRAPH,
  ...constants.INSTANCE_FATD_TEST_OBJECT_ADD,
];

// reduce an object of objects keyed by id
const reduceObjects = (objects = {}, action) => {
  if(objectActions.includes(action.type)) {
    const objectId = getObjectId(action);
    return {
      ...objects,
      [objectId]: reduceObject(objects[objectId], action),
    };
  }
  switch(action.type) {
    default: return objects;
    case constants.INSTANCE_FATD_TEST_CLEAR: return {};
    case constants.INSTANCE_FATD_TEST_OBJECT_REMOVE: return _.omit(objects, getObjectId(action));
  }
};

export default function testReducer(state = defaultState, action) {
  state = reduceByTypes(constants.INSTANCE_FATD_TEST_OBJECT_ADD, state, action, {
    requestProp: (state, action, isRequesting) => {
      const prop = `isAdding${ucfirst(getObjectType(action))}`;
      return {[prop]: isRequesting};
    },
    successPickProps: [],
  });
  switch(action.type) {
    default: {
      const key = getObjectType(action) + 's';
      return {
        ...state,
        [key]: reduceObjects(state[key], action),
      };
    }
    case constants.INSTANCE_FATD_TEST_OBJECT_SEARCH_FIELD_CHANGE: return {
      ...state,
      searchFields: {
        ...state.searchFields,
        [getObjectType(action)]: getObjectId(action),
      },
    };
  }
};
