import { push } from 'connected-react-router';
import * as actionCreators from 'client/redux/flashAlert/actionCreators';
import { FLASH_ALERT, FLASH_SEEN } from 'client/redux/flashAlert/constants';

export const redirectAndFlash = (to, flashOptions) => dispatch => {
  dispatch(actionCreators.flash(FLASH_ALERT, flashOptions));
  return dispatch(push(to));
};

export const flashSeen = () => dispatch => {
  return dispatch(actionCreators.flashSeen(FLASH_SEEN));
};
