import React, { Component } from 'react';
import propTypes from 'prop-types';
import { MainContent, PageHeader, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import GRTDOptionsForm from 'client/components/GRTD/components/GRTDOptionsForm';
import GRTDTester from 'client/components/GRTD/components/GRTDTester';
import InstanceSetup from 'client/components/Instance/InstanceSetup';
import { connect } from 'react-redux';
import { instanceGrtdTestClear } from 'client/redux/grtd/testActions';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class GRTDSetup extends Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
  }

  componentWillUnmount() {
    // clear everything from the test when we unmount
    this.props.instanceGrtdTestClear();
  }

  render() {
    const { instanceId } = this.props;
    return (
      <Content>
        <Helmet>
          <title>Microsoft Graph → TOPdesk</title>
        </Helmet>
        <PageHeader>Setup Wizard</PageHeader>
        <MainContent>
          <InstanceParentAlert />
          <InstanceSetup
            id={instanceId}
            OptionsFormComponent={GRTDOptionsForm}
            endpointOrder={['graph', 'topdesk']}
            TesterComponent={GRTDTester}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  instanceGrtdTestClear,
};

export default connect(null, actions)(GRTDSetup);
