import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const newsLatest = (types, query) => {
  return apiAction({
    endpoint: appendQuery('/api/news/latest', query),
    method: 'GET',
    types
  });
};

export const newsRead = (types, id) => {
  return apiAction({
    endpoint: `/api/news/${id}`,
    method: 'GET',
    types
  });
};

export const newsCreate = (types, json) => {
  return apiAction({
    endpoint: '/api/news',
    method: 'POST',
    json,
    types
  });
};

export const newsUpdate = (types, id, json) => {
  return apiAction({
    endpoint: `/api/news/${id}`,
    method: 'PATCH',
    json,
    types
  });
};

export const newsDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/news/${id}`,
    method: 'DELETE',
    json: {},
    types
  });
};
