import React from 'react';
import { AlertWithMessage } from 'client/components/Be/Alert';
import propTypes from 'prop-types';
import AccountSubscriptionPanel from 'client/components/AccountSubscription/components/AccountSubscriptionPanel';
import AccountAddCardModal from 'client/components/Account/components/AccountAddCardModal';
import { Link } from 'react-router-dom';
import _ from 'lodash';

export default class AccountSubscriptionManager extends React.Component {

  static propTypes = {
    subscriptions: propTypes.array,
    subscribablePlans: propTypes.array,
    mustAddCardToSubscribe: propTypes.bool,
    allPlansByStripeId: propTypes.object,
    hasNew: propTypes.bool,
    onNew: propTypes.func.isRequired,
  }

  static defaultProps = {
    mustAddCardToSubscribe: true,
  }

  state = {
    addCardModalOpen: false,
  }

  renderSubscriptions(subscriptions) {
    const { subscribablePlans, allPlansByStripeId } = this.props;
    return subscriptions.map(props => {
      const { subscriptionId } = props;
      return <AccountSubscriptionPanel
        key={subscriptionId}
        {...props}
        subscribablePlans={subscribablePlans}
        allPlansByStripeId={allPlansByStripeId}
      />
    });
  }

  get hasPreviousSubscriptions() {
    return _.get(this.props, 'subscriptions.length', 0) > 0;
  }

  handleClickNew = () => {
    const { mustAddCardToSubscribe } = this.props;
    if(mustAddCardToSubscribe) {
      this.setState({addCardModalOpen: true});
    } else {
      return this.props.onNew();
    }
  }

  handleCloseCardModal = () => {
    this.setState({addCardModalOpen: false});
  }

  handleCardAdded = () => {
    this.setState({addCardModalOpen: false}, () => {
      this.props.onNew();
    });
  }

  getCreateText() {
    const isFirst = !this.hasPreviousSubscriptions;
    const { mustAddCardToSubscribe } = this.props;
    switch(true) {
      case isFirst && !mustAddCardToSubscribe: return 'Create your first subscription';
      case isFirst && mustAddCardToSubscribe: return 'Add card and create your first subscription';
      case !isFirst && !mustAddCardToSubscribe: return 'Create another subscription';
      case !isFirst && mustAddCardToSubscribe: return 'Add a card to create another subscription';
      default: return '';
    }
  }

  renderAlert() {
    const hasPrevious = this.hasPreviousSubscriptions;
    const { mustAddCardToSubscribe } = this.props;
    if(!hasPrevious && mustAddCardToSubscribe) {
      return (
        <AlertWithMessage icon={true} className="alert-icon" alertType="warning">
          <strong>Warning:</strong> You need to <Link to="/account/billing" title="Go to the billing page">add a card</Link> in order to create your first subscription.
        </AlertWithMessage>
      );
    } else {
      return (
        <AlertWithMessage icon={true} className="alert-icon" alertType="primary">
          <strong>Note:</strong> No changes to your subscriptions will be made unless you press "Save".{/*  You can preview your next invoice before saving (coming soon). */}
        </AlertWithMessage>
      );
    }
  }

  renderCreate() {
    const { hasNew } = this.props;
    if(hasNew) return null; // can only create one at a time
    const canCreate = Boolean(_.get(this.props, 'subscribablePlans.length', 0));
    return (
      <div className="text-center">
        <button disabled={!canCreate} className="btn btn-default btn-rounded btn-lg xs-mb-20" onClick={this.handleClickNew}>
          {this.getCreateText()}
        </button>
      </div>
    );
  }

  render() {
    const { subscriptions } = this.props;
    const { addCardModalOpen } = this.state;
    if(!subscriptions) return null;
    return (
      <div>
        {this.renderAlert()}
        {this.renderCreate()}
        {this.renderSubscriptions(subscriptions)}
        <AccountAddCardModal isOpen={addCardModalOpen} onClose={this.handleCloseCardModal} onCardAdded={this.handleCardAdded} />
      </div>
    );
  }
}
