import React from 'react';
import { ModalSimple } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';
import { fieldSourceTypeToNatural } from 'client/redux/field/constants';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { Tabs, Tab } from 'react-bootstrap';
import Icon from 'client/components/Be/Icon';
import FieldMapTargetInfo from 'client/components/FieldMap/FieldMapTargetInfo';
import 'client/components/FieldMap/FieldMapModal.css';
import _ from 'lodash';
import {
  FieldMapModalInputConstant,
  FieldMapModalInputFSTDObject,
  FieldMapModalInputGraphGroupMember,
  FieldMapModalInputGraphUser,
  FieldMapModalInputObject,
  FieldMapModalInputTopdeskId,
  FieldMapModalInputUserInput,
  FieldMapModalInputUserSelect,
  FieldMapModalInputUserSelectFromResolver,
  FieldMapModalInputToken,
} from 'client/components/FieldMap/FieldMapModalInputs';

export default class FieldMapModal extends React.Component {

  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    source: propTypes.object,
    target: propTypes.object.isRequired,
    targetLabel: propTypes.string.isRequired,
    endpointIds: propTypes.object.isRequired,
    onClose: propTypes.func.isRequired,
    onSaveSource: propTypes.func.isRequired,
    disabled: propTypes.bool,
    availableSourceTypes: propTypes.array,
    sourcePropsByType: propTypes.object,
  }

  state = {
    tab: 'source',
    changed: false,
    source: {},
  }

  componentDidMount() {
    this.setState({source: {...this.props.source}});
  }

  componentDidUpdate (oldProps) {
    if (this.props.source !== oldProps.source) {
      this.setState({source: {...this.props.source}, changed: false});
    }
  }

  handleSourceFieldChange = currSource => {
    if(!currSource) {
      this.setState({source: null, changed: true});
    } else {
      const { props:newProps, ...update } = currSource;
      const { source:prevSource } = this.state;
      const source = {...prevSource, ...update, props: {...newProps}};
      this.setState({source, changed: true});
    }
  };

  handleClose = ev => {
    return this.props.onClose();
  }

  handleClickSave = ev => {
    const { source } = this.state;
    this.props.onSaveSource(source);
    this.props.onClose();
  }

  handleSelectTab = newTab => {
    return this.setState({tab: newTab});
  }

  // there might be many available source types, but only some are
  // reasonably combined with the current target. for example if the target does
  // not have a resolver, FieldSourceResolve is not valid.
  getReasonableSourceTypes() {
    const { target, availableSourceTypes } = this.props;
    const hasResolver = Boolean(target.resolver);
    return availableSourceTypes.filter(type => {
      if (type === 'FieldSourceResolve') return hasResolver;
      if (type === 'FieldSourceUserSelectFromResolver') return hasResolver || target.type === 'FieldTargetEnum';
      return true;
    });
  }

  render() {
    const {
      isOpen,
      targetLabel,
      endpointIds,
      target,
      disabled,
      onClose,
      sourcePropsByType,
    } = this.props;
    const { changed, source, tab } = this.state;
    if(!isOpen) return null;
    return (
      <ModalSimple
        id="fieldMapModalSimple"
        isOpen={true}
        onClose={this.handleClose}
        dialogClassName="colored-header colored-header-primary"
        bodyClassName="xs-pt-10 ln-field-map-modal-body"
        title={`Change source for field "${targetLabel}"`}
      >

      <Tabs id="field-map-modal-tabs" activeKey={tab} onSelect={this.handleSelectTab}>
        <Tab eventKey="source" title={<span><Icon icon="circle-o" />Source</span>}>
          <FieldMapModalSourceForm
            disabled={disabled}
            source={source}
            target={target}
            onChange={this.handleSourceFieldChange}
            availableSourceTypes={this.getReasonableSourceTypes()}
            sourcePropsByType={sourcePropsByType}
            endpointIds={endpointIds}
          />
        </Tab>
        <Tab eventKey="target" title={<span><Icon icon="square-right" />Target</span>}>
          <FieldMapTargetInfo target={target} />
        </Tab>
      </Tabs>

      <div className="clearfix">
        {disabled && <button className="btn btn-lg btn-default pull-right" onClick={onClose}>Close</button>}
        {!disabled && (
          <button
            className="btn btn-lg btn-primary pull-right"
            disabled={!changed}
            onClick={this.handleClickSave}>
            <i className="icon icon-left mdi mdi-check"></i>{' '}
            Update and Close
          </button>
        )}
      </div>
    </ModalSimple>
    );
  }
}

export class FieldMapModalSourceForm extends React.Component {

  static propTypes = {
    source: propTypes.object,
    target: propTypes.object.isRequired,
    endpointIds: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
    availableSourceTypes: propTypes.arrayOf(propTypes.string),
    sourcePropsByType: propTypes.object,
  }

  static defaultProps = {
    availableSourceTypes: [],
  }

  handleChange = (name, value) => {
    const { source } = this.props;
    if(!source) {
      this.props.onChange(null);
      return;
    }
    const { source: { props = {} } } = this.props;
    if(name && name.target && 'value' in name.target) {
      // first arg is an event
      const { name:evName, value:evValue } = name.target;
      this.props.onChange({props: {...props, [evName]: evValue}});
    } else {
      this.props.onChange({props: {...props, [name]: value}});
    }
  }

  handleCheckboxTypeChange = ev => {
    const { checked, value } = ev.target;
    const targetResolveOptions = _.get(this.props, 'source.props.targetResolveOptions');
    if(checked) this.props.onChange({type: value, props: {targetResolveOptions}});
  }

  // not exactly the most pretty way to get default props, but is
  // required in order to set fieldName to target field name for
  // user selectable fields. this should probably be done somewhere
  // else. maybe even in FieldMapping field resolving...
  getDefaultPropsForSourceType (sourceType) {
    const targetName = this.props.target?.name;
    const targetAllowedValuesList = this.props.target?.props?.list || [];

    switch(sourceType) {
      default: return {};
      case 'FieldSourceUserInput': return {
        fieldName: targetName,
      }
      case 'FieldSourceUserSelectFromResolver': return {
        fieldName: targetName,
      };
      case 'FieldSourceUserSelect': return {
        allowedValues: targetAllowedValuesList,
        fieldName: targetName,
      };

    }
  }

  handleTypeChange = ev => {
    const { value } = ev.target;
    const targetResolveOptions = _.get(this.props, 'source.props.targetResolveOptions');
    if(value === 'null') return this.props.onChange(null);
    const defaultProps = this.getDefaultPropsForSourceType(value);
    this.props.onChange({
      type: value,
      props: {...defaultProps, targetResolveOptions},
    });
  }

  getId(controlId, suffix = '') {
    return `fieldMapModalSourceForm${controlId}${suffix}`;
  }

  getTargetIsRequired() {
    return _.get(this.props, 'target.props.required', false);
  }

  renderTypeSelect() {
    const { disabled, availableSourceTypes } = this.props;
    const currentType = _.get(this.props, 'source.type', null);
    const data = [
      {
        id: 'null',
        text: 'Default',
      },
      ...availableSourceTypes.map(type => ({
        id: type,
        text: fieldSourceTypeToNatural(type),
      }))
    ];
    return (
      <div className="form-group">
        <label className="control-label" htmlFor="fieldMapModalType">Overridden Field Source</label>
        <Select2Wrap
          id="fieldMapModalType"
          name="type"
          className="form-control"
          data={data}
          options={{minimumResultsForSearch: 20}}
          onChange={this.handleTypeChange}
          defaultValue={currentType || 'null'}
          disabled={disabled}
        />
      </div>
    );
  }

  renderProps() {
    const {
      disabled,
      source,
      sourcePropsByType,
      target,
    } = this.props;
    if(!source) return null;

    const componentProps = {
      disabled,
      id: this.getId(source.type),
      sourceProps: source.props || {},
      onChange: this.handleChange,
      target,
      ...(sourcePropsByType[source.type] || {}),
    };

    switch(source.type) {
      default: return null;
      case 'FieldSourceUserSelect': return <FieldMapModalInputUserSelect {...componentProps} />;
      case 'FieldSourceUserSelectFromResolver': return <FieldMapModalInputUserSelectFromResolver {...componentProps} />;
      case 'FieldSourceUserInput': return <FieldMapModalInputUserInput {...componentProps} />;
      case 'FieldSourceConstant': return <FieldMapModalInputConstant {...componentProps} />;
      case 'FieldSourceToken': return <FieldMapModalInputToken {...componentProps} />;
      case 'FieldSourceGraphUser': return <FieldMapModalInputGraphUser {...componentProps} />;
      case 'FieldSourceGraphGroupMember': return <FieldMapModalInputGraphGroupMember {...componentProps} />;
      case 'FieldSourceFSTDObject': return <FieldMapModalInputFSTDObject {...componentProps} />;
      case 'FieldSourceObject': return <FieldMapModalInputObject {...componentProps} />;
    }
  }

  renderTargetResolveOptions() {
    const { disabled, source, target = {}, endpointIds: { topdeskId } } = this.props;
    const required = this.getTargetIsRequired();
    if(!source || !source.type || (source.type === 'FieldSourceIgnore' && !required)) {
      // if the field is not required and the source is ignore
      // we don't need to show resolver options at all
      return null;
    }

    switch(target.type) {
      default: return null;
      case 'FieldTargetTopdeskId': return (
        <div>
          <FieldMapModalInputTopdeskId
            id={this.getId('TopdeskId')}
            target={target}
            onChange={this.handleChange}
            sourceType={source.type}
            sourceProps={source.props || {}}
            topdeskEndpointId={topdeskId}
            disabled={disabled}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderTypeSelect()}
        {this.renderProps()}
        {this.renderTargetResolveOptions()}
      </div>
    );
  }
}
