import React from 'react';
import propTypes from 'prop-types';
import { FormReduxSelect2 } from 'client/components/Form/FormRedux';
import { Field } from 'redux-form';
import { EndpointTopdeskRemoteSearcher } from 'client/components/Form/Select2Searchers';
import { isString, isUUID } from 'client/redux/validators';

const validator = [isString(), isUUID()];

export default class EndpointTopdeskIdField extends React.Component {

  static propTypes = {
    base: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    endpointId: propTypes.string.isRequired,
    value: propTypes.string,
    disabled: propTypes.bool.isRequired,
    helpText: propTypes.string,
    placeholderText: propTypes.string.isRequired,
    searchForWhat: propTypes.string.isRequired,
    label: propTypes.string,
  }

  render() {
    const {
      name,
      base,
      endpointId,
      value,
      disabled,
      helpText,
      placeholderText,
      label,
      searchForWhat,
    } = this.props;
    const select2Options = {
      minimumInputLength: 0,
      minimumResultsForSearch: 0,
      allowClear: true,
      placeholder: {id: '', text: placeholderText},
    };
    return (
      <Field
        base={base}
        component={FormReduxSelect2}
        select2Component={EndpointTopdeskRemoteSearcher}
        select2ComponentProps={{forWhat: searchForWhat, endpointId}}
        select2Options={select2Options}
        name={name}
        defaultValue={value}
        disabled={disabled}
        label={label}
        helpText={helpText}
        validate={validator}
      />
    );
  }
}
