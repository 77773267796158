import React from 'react';
import propTypes from 'prop-types';
import LeftSidebarItem from 'client/components/App/LeftSidebar/LeftSidebarItem';
import { instanceTitle } from 'client/redux/instance/instanceHelpers';

class LeftSidebarInstanceList extends React.Component {

  static propTypes = {
    forKey: propTypes.string.isRequired,
    selfUserId: propTypes.any,
    title: propTypes.node,
    instances: propTypes.array,
    connectorsById: propTypes.object.isRequired
  }

  renderInstances() {
    const { instances, connectorsById, selfUserId } = this.props;
    return (instances || []).map(instance => {
      const { type, id, connectorId } = instance;
      const connector = connectorsById[connectorId];
      const isParentInstance = selfUserId && instance.userId !== selfUserId;
      const icon = isParentInstance ? 'mdi-share' : 'mdi-swap-alt';
      return (
        <LeftSidebarItem key={instance.id} to={`/instance/${type}/${id}`} icon={icon}>
          {instanceTitle(instance, connector, true)}
        </LeftSidebarItem>
      );
    });
  }

  render() {
    const { title, forKey, instances } = this.props;
    if(!instances || !instances.length) return null;
    return (
      <ul className="sidebar-elements ln-instance-list">
        <li key={forKey} className="divider">{title}</li>
        {this.renderInstances()}
      </ul>
    );
  }

}

export default LeftSidebarInstanceList;
