import {
  INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ,
  INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_RESET,
  INSTANCE_AITD_OPTIONS_READ_META,
} from 'client/redux/aitd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReadingFieldTargets: false,
  isReadingMeta: false,
  errorFieldTargets: null,
  errorMeta: null,
  assetFieldTargets: null,
};

export default function optionsReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_READ, state, action, {
    requestProp: 'isReadingFieldTargets',
    errorProp: 'errorFieldTargets',
    successProp: ['assetFieldTargets'],
  });

  state = reduceByTypes(INSTANCE_AITD_OPTIONS_READ_META, state, action, {
    requestProp: 'isReadingMeta',
    errorProp: 'errorMeta',
    successProp: 'meta',
  });

  switch(action.type) {
    default: return state;
    case INSTANCE_AITD_OPTIONS_ASSET_FIELD_TARGETS_RESET: return {
      ...state,
      isReadingFieldTargets: false,
      errorFieldTargets: null,
      assetFieldTargets: null,
    };
  }
};
