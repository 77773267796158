import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import 'client/components/Helpers/ArrayTable.css';

export default class ArrayTable extends React.Component {

  static propTypes = {
    data: propTypes.arrayOf(propTypes.arrayOf(propTypes.string)),
  }

  renderHead() {
    const { data = [] } = this.props;
    const header = _.first(data) || [];
    return header.map((str, i) => <th key={i}>{str}</th>);
  }

  renderBody() {
    const { data = [] } = this.props;
    const rows = _.tail(data);
    return rows.map((row, i) => {
      const td = row.map((str, i) => <td key={i}>{str}</td>);
      return <tr key={i}>{td}</tr>
    });
  }

  render() {
    const { className } = this.props;
    const classes = classNames(className, 'table');
    return (
      <div className="array-table-container">
        <table className={classes}>
          <thead>
            <tr>
              {this.renderHead()}
            </tr>
          </thead>
          <tbody>
            {this.renderBody()}
          </tbody>
        </table>
      </div>
    );
  }

}
