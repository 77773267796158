import { INSTANCE_DASHBOARD_READ, INSTANCE_DASHBOARD_DELETE, INSTANCE_DASHBOARD_UPDATE_STATUS } from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isDeleting: false,
  isUpdating: false,
  instance: null,
  connector: null,
  endpointsByType: null,
  optionalEndpoints: [],
  logs: null,
  plan: null,
  subscription: null,
  isParentInstance: false,
};

export default function dashboardReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_DASHBOARD_READ, state, action, {
    requestProp: 'isReading',
    successSpread: true,
    successPickProps: [
      'instance',
      'connector',
      'endpointsByType',
      'logs',
      'plan',
      'subscription',
      'isParentInstance',
      'optionalEndpoints',
    ]
  });
  state = reduceByTypes(INSTANCE_DASHBOARD_UPDATE_STATUS, state, action, {
    requestProp: 'isUpdating',
    successSpread: true,
    successPickProps: ['instance']
  });
  state = reduceByTypes(INSTANCE_DASHBOARD_DELETE, state, action, {
    requestProp: 'isDeleting',
    successSpread: true,
    successPickProps: []
  });
  return state;
};
