import { USER_INVOICE_INDEX, USER_INVOICE_INDEX_ORDERBY, USER_INVOICE_INDEX_SET_PAGE, USER_INVOICE_INDEX_FILTER, USER_INVOICE_DELETE } from 'client/redux/user/invoice/constants';
import * as actions from 'client/redux/user/invoice/actionCreators';
import { tableOrderBy, tableSetPage, tableFilter } from 'client/redux/table/actionCreators';

export const userInvoiceIndex = query => dispatch => {
  return dispatch(actions.userInvoiceIndex(USER_INVOICE_INDEX, query));
};

export const userInvoiceDelete = (id, query) => dispatch => {
  return dispatch(actions.userInvoiceDelete(USER_INVOICE_DELETE, id, query));
};

export const userInvoiceIndexOrderBy = (orderBy, orderDirection) => dispatch => {
  return dispatch(tableOrderBy(USER_INVOICE_INDEX_ORDERBY, orderBy, orderDirection));
};

export const userInvoiceIndexSetPage = page => dispatch => {
  return dispatch(tableSetPage(USER_INVOICE_INDEX_SET_PAGE, page));
};

export const userInvoiceIndexFilter = filter => dispatch => {
  return dispatch(tableFilter(USER_INVOICE_INDEX_FILTER, filter));
};
