import React from 'react';
import propTypes from 'prop-types';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import { levelSelect2 } from 'client/redux/instance/logFragment/constants';
import DateRangePickerWrap from 'client/components/Form/DateRangePickerWrap';
import { SemiControlledInput } from 'client/components/Form/Helpers';
import 'react-bootstrap-daterangepicker/css/daterangepicker.css';
import * as InstanceLogFragmentHelpers from 'client/components/InstanceLogFragment/InstanceLogFragmentHelpers';

export default class InstanceLogFragmentTableFilterForm extends React.Component {

  static propTypes = {
    onFilter: propTypes.func
  }

  handleChange = ev => {
    this.handleSetValue(ev.target.name, ev.target.value);
  }

  handleSetValue = (name, value) => {
    const newFilter = {...this.props.filter, [name]: value};
    this.props.onFilter(newFilter);
  }

  render() {
    const {
      connectorInstanceLogId,
      level,
      search,
      timeEnd,
      timeStart,
    } = this.props.filter;
    return (
      <div className="row be-datatable-header xs-pb-10">
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <DateRangePickerWrap
              startName="timeStart"
              startValue={timeStart}
              endName="timeEnd"
              endValue={timeEnd}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="dataTables_length form-group">
            <Select2Wrap
              id="instanceLogTableFilterLevel"
              name="level"
              className="form-control"
              data={levelSelect2}
              options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'Any level'}}
              style={{width: '100%'}}
              onChange={this.handleChange}
              defaultValue={level || ''}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dataTables_length form-group">
            <SemiControlledInput
              id="instanceLogTableFilterConnectorInstanceLogId"
              type="text"
              className="form-control"
              name="connectorInstanceLogId"
              onSetValue={this.handleSetValue}
              placeholder="Job ID"
              initialValue={connectorInstanceLogId}
            />
          </div>
        </div>

        <div className="col-md-4">
          <InstanceLogFragmentHelpers.SearchInput
            name="searchData"
            onSetValue={this.handleSetValue}
            initialValue={search}
          />
        </div>
      </div>
    );
  }

}
