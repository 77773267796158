import React from 'react';
import propTypes from 'prop-types';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import { AppSparklinesBar } from 'client/components/Helpers/Sparklines';
import ConnectedAITDWidgetStatistic from 'client/components/AITD/ConnectedAITDWidgetStatistic';
import ConnectedAITDOptionsForm from 'client/components/AITD/ConnectedAITDOptionsForm';
import ConnectedAITDTester from 'client/components/AITD/ConnectedAITDTester';
import InstanceWidgetPlan from 'client/components/Instance/components/InstanceWidgetPlan';
import InstanceWidgetControls from 'client/components/Instance/components/InstanceWidgetControls';
import InstanceDashboard from 'client/components/Instance/InstanceDashboard';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import InstanceParentAlert from 'client/components/Instance/components/InstanceParentAlert';

class ConnectedAITDModule extends React.Component {

  static propTypes = {
    instanceId: propTypes.string.isRequired,
    tab: propTypes.string.isRequired,
  }

  handleSelectTab = newTab => {
    if(newTab === this.props.tab) return;
    const instanceId = this.props.instanceId;
    this.props.push(`/instance/aitd/${instanceId}/${newTab}`);
  }

  render() {
    const instanceId = this.props.instanceId;
    return (
      <Content>
        <Helmet>
          <title>Microsoft Azure AI → TOPdesk</title>
        </Helmet>
        <MainContent>
          <InstanceParentAlert />
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <ConnectedAITDWidgetStatistic
                title="Actions per month"
                id={instanceId}
                statistic="logSummer"
                reducerKey="NOTICE_COUNT"
                query={{duration: 'month', limit: 7}}
                Component={AppSparklinesBar}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <ConnectedAITDWidgetStatistic
                title="Token used per month"
                id={instanceId}
                statistic="logSummer"
                reducerKey="CREDITS_USED"
                query={{duration: 'month', limit: 7, key: 'CREDITS_USED'}}
                Component={AppSparklinesBar}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetPlan
                id={instanceId}
                shouldReadInstance={false}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <InstanceWidgetControls
                id={instanceId}
                shouldReadInstance={false}
              />
            </div>
          </div>
          <InstanceDashboard
            id={this.props.instanceId}
            tab={this.props.tab}
            OptionsFormComponent={ConnectedAITDOptionsForm}
            TesterComponent={ConnectedAITDTester}
            onSelectTab={this.handleSelectTab}
            endpointOrder={['topdesk', 'azureOpenAi']}
          />
        </MainContent>
      </Content>
    );
  }

}

const actions = {
  push,
};

export default connect(null, actions)(ConnectedAITDModule);
