import React, { Component } from 'react';
import classNames from 'classnames';
import 'client/components/Be/BeSpinner/BeSpinner.css';

class BeSpinner extends Component {

  static defaultProps = {
    className: ''
  }

  render() {
    const classes = classNames('be-spinner', this.props.className);
    return (
      <div className={classes}>
        <div className="spinner"></div>
      </div>
    );
  }

}

export default BeSpinner;
