export const ENDPOINT_OPMANAGER_PRELOADED = 'ENDPOINT_OPMANAGER_PRELOADED';

export const ENDPOINT_OPMANAGER_READ_REQUEST = 'ENDPOINT_OPMANAGER_READ_REQUEST';
export const ENDPOINT_OPMANAGER_READ_SUCCESS = 'ENDPOINT_OPMANAGER_READ_SUCCESS';
export const ENDPOINT_OPMANAGER_READ_FAILURE = 'ENDPOINT_OPMANAGER_READ_FAILURE';
export const ENDPOINT_OPMANAGER_READ = [ENDPOINT_OPMANAGER_READ_REQUEST, ENDPOINT_OPMANAGER_READ_SUCCESS, ENDPOINT_OPMANAGER_READ_FAILURE];

export const ENDPOINT_OPMANAGER_EDIT_CREDENTIALS = 'ENDPOINT_OPMANAGER_EDIT_CREDENTIALS';

export const ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_REQUEST = 'ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_REQUEST';
export const ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_SUCCESS = 'ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_SUCCESS';
export const ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_FAILURE = 'ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_FAILURE';
export const ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS = [ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_REQUEST, ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_SUCCESS, ENDPOINT_OPMANAGER_UPDATE_CREDENTIALS_FAILURE];

export const ENDPOINT_OPMANAGER_TEST_CREDENTIALS_REQUEST = 'ENDPOINT_OPMANAGER_TEST_CREDENTIALS_REQUEST';
export const ENDPOINT_OPMANAGER_TEST_CREDENTIALS_SUCCESS = 'ENDPOINT_OPMANAGER_TEST_CREDENTIALS_SUCCESS';
export const ENDPOINT_OPMANAGER_TEST_CREDENTIALS_FAILURE = 'ENDPOINT_OPMANAGER_TEST_CREDENTIALS_FAILURE';
export const ENDPOINT_OPMANAGER_TEST_CREDENTIALS = [ENDPOINT_OPMANAGER_TEST_CREDENTIALS_REQUEST, ENDPOINT_OPMANAGER_TEST_CREDENTIALS_SUCCESS, ENDPOINT_OPMANAGER_TEST_CREDENTIALS_FAILURE];
