import { combineReducers } from 'redux';

import index from 'client/redux/uiFragment/indexReducer';
import single from 'client/redux/uiFragment/singleReducer';

const uiFragmentReducer = combineReducers({
  index,
  single,
});

export default uiFragmentReducer;
