import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import { BeFormFieldErrors } from 'client/components/Be';
import { ButtonWaiting }  from 'client/components/Button/ButtonWaiting';
import { isString } from 'client/redux/validators';
import { FormReduxInputGroup }  from 'client/components/Form/FormRedux';
import { AlertWithMessage } from 'client/components/Be/Alert';
import propTypes from 'prop-types';

const validateByField = {
  vatNumber: [isString()]
}

class AccountBillingSettingsForm extends Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
    taxPercentage: propTypes.number.isRequired,
  }

  static defaultProps = {
    taxPercentage: 0,
  }

  handleSubmit = fields => {
    return this.props.onSubmit(fields).then(() => {
      this.props.reset();
    });
  }

  renderAfterVat() {
    const { submitting, invalid } = this.props;
    return (
      <span className="input-group-btn">
        <ButtonWaiting type="submit" className="btn btn-primary xs-pl-20 xs-pr-20" isWaiting={submitting} disabled={invalid}>
          Save
        </ButtonWaiting>
      </span>
    );
  }

  renderUserTaxPercentageAlert() {
    const { taxPercentage } = this.props;
    return (
      <AlertWithMessage icon={true} className="alert-icon" alertType="primary">
        Your current VAT rate is {taxPercentage} %. Contact support if this is not correct.
      </AlertWithMessage>
    );
  }

  render() {
    const { error, onSubmit } = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(onSubmit)}>
        <BeFormFieldErrors className="xs-mt-0 xs-mb-10" errors={error} />
        {this.renderUserTaxPercentageAlert()}
        <Field
          base="accountBillingSettings"
          name="vatNumber"
          type="text"
          component={FormReduxInputGroup}
          validate={validateByField.vatNumber}
          after={this.renderAfterVat()}
        />
      </Form>
    );
  }

}


export default reduxForm({
  form: 'accountBillingSettings',
  enableReinitialize: true,
})(AccountBillingSettingsForm);
