import React, { Component } from 'react';
import { setBodyClass } from 'client/redux/bodyClass';
import { SplashFooter, SplashHeader } from 'client/components/Branding';
import { Helmet } from 'react-helmet';
import { App, Content, MainContent } from 'client/components/App';
import { connect } from 'react-redux';
import { getResponseToFormErrorHandler } from 'client/redux/apiHelpers';
import { redirectAndFlash } from 'client/redux/flashAlert/actions';
import { forgotPassword } from 'client/redux/forgotPassword/actions';
import ForgotPasswordForm from 'client/components/ForgotPassword/components/ForgotPasswordForm';

class ForgotPassword extends Component {

  handleFormSubmit = fields => {
    return this.props.forgotPassword(fields).then(getResponseToFormErrorHandler());
  }

  componentDidUpdate (oldProps) {
    if (this.props.result?.ok) {
      this.props.redirectAndFlash('/login', {
        alertType: 'primary',
        strong: 'Email sent!',
        msg: 'Please follow the instructions within to reset your password.'
      });
    }
  }

  render() {
    const { isWaiting } = this.props;
    setBodyClass('be-splash-screen');
    return (
      <App className="be-login">
        <Helmet>
          <title>Forgot your password?</title>
        </Helmet>
        <Content>
          <MainContent className="container-fluid">
            <div className="splash-container forgot-password">
              <div className="panel panel-default panel-border panel-border-color panel-border-color-primary">
                <SplashHeader classNameName="panel-heading">Forgot your password?</SplashHeader>
                <div className="panel-body">
                  <ForgotPasswordForm
                    onSubmit={this.handleFormSubmit}
                    onReset={this.handleFormReset}
                    isWaiting={isWaiting}
                  />
                </div>
              </div>
              <SplashFooter />
            </div>
          </MainContent>
        </Content>
      </App>
    );
  }
};

export default connect(state => state.forgotPassword, { forgotPassword, redirectAndFlash })(ForgotPassword);
