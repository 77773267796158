import { CONNECTOR_PANEL_READ, CONNECTOR_PANEL_UPDATE } from 'client/redux/connector/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isUpdating: false,
  error: null,
  connector: null,
};

export default function panelReducer(state = defaultState, action) {
  state = reduceByTypes(CONNECTOR_PANEL_READ, state, action, {requestProp: 'isReading', successSpread: true});
  state = reduceByTypes(CONNECTOR_PANEL_UPDATE, state, action, {requestProp: 'isUpdating', successSpread: true});
  return state;
};
