import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import propTypes from 'prop-types';

class MarketplaceFilter extends Component {

  static propTypes = {
    categories: propTypes.array,
    onFilter: propTypes.func.isRequired,
    filter: propTypes.object.isRequired
  }

  categoriesToSelect2Data() {
    return this.props.categories.map(category => {
      return {id: category.id, text: category.name};
    });
  }

  changeField(field, value) {
    this.props.onFilter({...this.props.filter, [field]: value});
  }

  handleFieldChange = ev => {
    this.changeField(ev.target.name, ev.target.value);
  }

  handleFieldClear = ev => {
    this.changeField(ev.target.name, null);
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="panel panel-default panel-border xs-mb-0">
            <div className="panel-body xs-pb-10">
              <form action="#" className="form-horizontal">
                <div className="col-sm-6 col-md-4 col-xs-12">
                  <div className="form-group">
                    <Select2
                      id="marketplaceFilterCategory"
                      name="category"
                      className="form-control"
                      data={this.categoriesToSelect2Data()}
                      options={{minimumResultsForSearch: 20, allowClear: true, placeholder: 'All categories'}}
                      value={this.props.filter.category}
                      onSelect={this.handleFieldChange}
                      onUnselect={this.handleFieldClear}
                      style={{width: '100%'}}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xs-12 pull-right">
                  <div className="form-group">
                    <div className="input-group input-search">
                      <input type="text" name="search" value={this.props.filter.search} placeholder="Search" className="form-control" onChange={this.handleFieldChange} />
                      <span className="input-group-btn">
                        <button className="btn btn-default">
                          <i className="icon mdi mdi-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default MarketplaceFilter;
