import React, { Component } from 'react';
import RouteRole from 'client/components/Route/RouteRole';
import RouteRedirectTransform from 'client/components/Route/RouteRedirectTransform';
import { Switch, Route, Redirect } from 'react-router-dom';
import pages from 'client/components/pages';
import RouterAuthed from 'client/components/Router/RouterAuthed';

export default class RouterRoot extends Component {

  // helper that redirects to the API host for receiving consent when developing
  consentRoute() {
    const { REACT_APP_GRAPH_CONSENT_REDIRECT_HOST } = process.env;
    if(!REACT_APP_GRAPH_CONSENT_REDIRECT_HOST) return null;
    return <RouteRedirectTransform host={REACT_APP_GRAPH_CONSENT_REDIRECT_HOST} path="/api/endpoint/graph/receiveConsent" />
  }

  render() {
    const allRoles = ['admins', 'partners', 'users'];
    return (
      <Switch>
        {this.consentRoute()}
        <Route exact path="/login" component={pages.Login} />
        <Route exact path="/signup" component={pages.Signup} />
        <Route exact path="/confirm/:token" component={pages.ConfirmUser} />
        <Route exact path="/forgotPassword" component={pages.ForgotPassword} />
        <Route exact path="/resetPassword/:token" component={pages.ResetPassword} />
        <Route exact path="/forbidden" component={pages.ErrorForbidden} />
        <Route exact path="/404" component={pages.ErrorNotFound} />
        <RouteRole anyRole={allRoles} exact path="/instance/qrtd/:instanceId/printCodes" component={pages.ConnectedQRTDPrintPage} />
        <RouteRole anyRole={allRoles} path="/" component={RouterAuthed} />
        <Redirect to="/404" />
      </Switch>
    );
  }

}
