import React from 'react';
import propTypes from 'prop-types';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';

export default class AITDPanel extends React.Component {

  static propTypes = {
    onCall: propTypes.func.isRequired,
  }

  render() {
    const { onCall } = this.props;
    return (
      <div>
        <ButtonWaitingPromise
          onClick={ev => onCall('callbackStartIngestionJob')}
          type="button"
          className="btn btn-default"
        >
          Callback "startIngestionJob"
        </ButtonWaitingPromise>
      </div>
    );
  }

}

