import * as constants from 'client/redux/qrtd/constants';
import { endpointTopdeskSearchRemoteAssets, endpointTopdeskSearchRemoteLocations } from 'client/redux/endpoint/topdesk/actionCreators';

export const instanceQrtdQrCodeCreatorReadAssets = (topdeskEndpointId, query) => dispatch => dispatch(endpointTopdeskSearchRemoteAssets(constants.INSTANCE_QRTD_QRCODECREATOR_READ_ASSETS, topdeskEndpointId, query));

export const instanceQrtdQrCodeCreatorReadLocations = (topdeskEndpointId, query = {}) => dispatch => {
  const { branchId, ...rest } = query;
  if (branchId) {
    rest.query = `branch.id==${branchId}`;
  }
  dispatch(endpointTopdeskSearchRemoteLocations(constants.INSTANCE_QRTD_QRCODECREATOR_READ_LOCATIONS, topdeskEndpointId, rest));
}

export const instanceQrtdQrCodeCreatorAssetFilter = (filter = {}) => dispatch => dispatch({type: constants.INSTANCE_QRTD_QRCODECREATOR_ASSET_FILTER, filter});
export const instanceQrtdQrCodeCreatorAssetSetSelectedIds = (ids = {}) => dispatch => dispatch({type: constants.INSTANCE_QRTD_QRCODECREATOR_ASSET_SET_SELECTED_IDS, ids});

export const instanceQrtdQrCodeCreatorLocationFilter = (filter = {}) => dispatch => dispatch({type: constants.INSTANCE_QRTD_QRCODECREATOR_LOCATION_FILTER, filter});
export const instanceQrtdQrCodeCreatorLocationSetSelectedIds = (ids = {}) => dispatch => dispatch({type: constants.INSTANCE_QRTD_QRCODECREATOR_LOCATION_SET_SELECTED_IDS, ids});

export const instanceQrtdQrCodeCreatorSetTab = tab => dispatch => dispatch({type: constants.INSTANCE_QRTD_QRCODECREATOR_SET_TAB, tab});
