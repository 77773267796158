/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Gravatar } from 'client/components/Helpers/Images';
import { Pagination } from '@react-bootstrap/pagination';
import { Pluralize } from 'client/components/Helpers/Strings';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';

export const TableCheckboxManagerControlled = (WrappedComponent, options = {}) => {
  const { allIds } = options;
  return class TableCheckboxManagerControlled extends React.Component {

    static propTypes = {
      selectedById: propTypes.object.isRequired,
      onSetSelectedById: propTypes.func.isRequired,
    }

    handleSelectRow = (id, shouldBeSelected = false) => {
      const { selectedById = {} } = this.props;
      const newSelectedById = {...selectedById, [id]: shouldBeSelected};
      this.props.onSetSelectedById(newSelectedById);
    }

    handleSelectAll = () => {
      const shouldBeSelected = !this.isAllSelected();
      if(shouldBeSelected) {
        const selectedById = allIds(this.props).reduce((selectedById, key) => {
          selectedById[key] = shouldBeSelected;
          return selectedById;
        }, {});
        this.props.onSetSelectedById({...this.props.selectedById, ...selectedById});
      } else {
        this.props.onSetSelectedById({});
      }
    }

    isRowSelected = id => {
      return Boolean(this.props.selectedById[id]);
    }

    isAllSelected = () => {
      return allIds(this.props).every(id => this.props.selectedById[id]);
    }

    render() {
      const selected = _.reduce(this.props.selectedById, (selected, isSelected, id) => {
        if(isSelected) selected.push(id);
        return selected;
      }, []);
      return (
        <WrappedComponent
          {...this.props}
          handleSelectAll={this.handleSelectAll}
          handleSelectRow={this.handleSelectRow}
          isRowSelected={this.isRowSelected}
          isAllSelected={this.isAllSelected}
          selected={selected}
        />
      );
    }

  };
}

export const TableCheckboxManager = (WrappedComponent, { allIds } = {}) => {
  return class TableCheckboxManager extends React.Component {

    state = {
      selected: {},
    }

    handleSelectRow = (id, shouldBeSelected = false) => {
      const selected = this.state.selected;
      const newSelected = {...selected, [id]: shouldBeSelected};
      this.setState({selected: newSelected});
    }

    handleSelectAll = () => {
      const shouldBeSelected = !this.isAllSelected();
      if(shouldBeSelected) {
        const selected = allIds(this.props).reduce((selected, key) => {
          selected[key] = shouldBeSelected;
          return selected;
        }, {});
        this.setState({selected: {...this.state.selected, ...selected}});
      } else {
        this.setState({selected: {}});
      }
    }

    isRowSelected = id => {
      return Boolean(this.state.selected[id]);
    }

    isAllSelected = () => {
      return allIds(this.props).every(id => this.state.selected[id]);
    }

    render() {
      const { handleSelectAll, handleSelectRow, isRowSelected, isAllSelected } = this;
      const selected = _.reduce(this.state.selected, (selected, isSelected, id) => {
        if(isSelected) selected.push(id);
        return selected;
      }, []);
      return (
        <WrappedComponent
          {...this.props}
          handleSelectAll={handleSelectAll}
          handleSelectRow={handleSelectRow}
          isRowSelected={isRowSelected}
          isAllSelected={isAllSelected}
          selected={selected}
        />
      );
    }

  };
}

export class TableCheckbox extends React.Component {

  static defaultProps = {
    id: '',
    base: '',
    beagle: true,
  }

  static propTypes = {
    id: propTypes.string,
    base: propTypes.string,
    beagle: propTypes.bool.isRequired,
    isSelected: propTypes.bool,
    onSelect: propTypes.func,
    onSelectAll: propTypes.func
  }

  handleChange = ev => {
    const isChecked = ev.target.checked;
    if(this.props.onSelect) this.props.onSelect(this.props.id, isChecked);
    if(this.props.onSelectAll) this.props.onSelectAll();
  }

  render() {
    const { className, id, base, isSelected, beagle, tabIndex } = this.props;
    const classes = classNames(beagle ? 'be-checkbox be-checkbox-sm' : '', className);
    const htmlId = base + id;
    return (
      <div className={classes}>
        <input id={htmlId} type="checkbox" onChange={this.handleChange} checked={isSelected} tabIndex={tabIndex} />
        <label htmlFor={htmlId}></label>
      </div>
    );
  }

}

export class TableThSort extends React.Component {

  static propTypes = {
    column: propTypes.string,
    orderBy: propTypes.string,
    orderDirection: propTypes.oneOf(['ASC', 'DESC']),
    onClick: propTypes.func
  }

  getClassName() {
    const { orderDirection } = this.props;
    if(this.isActive()) {
      return orderDirection === 'ASC' ? 'sorting_asc' : 'sorting_desc';
    } 
    return 'sorting';
  }

  isActive() {
    const { column, orderBy } = this.props;
    return column === orderBy;
  }

  handleClick = ev => {
    const { onClick, column, orderDirection } = this.props;
    const newDirection = this.isActive() && orderDirection === 'DESC' ? 'ASC' : 'DESC';
    onClick(column, newDirection);
  }

  render() {
    const { children } = this.props;
    return (
      <th className={this.getClassName()} onClick={this.handleClick}>
        {children}
      </th>
    );
  }

}

export class TablePagination extends React.Component {

  static propTypes = {
    hideWhenOnePage: propTypes.bool
  }

  render() {
    const { hideWhenOnePage, items } = this.props;
    if(hideWhenOnePage && items <= 1) return null;
    const props = {...this.props};
    delete props.hideWhenOnePage;
    return (
      <Pagination
        first={true}
        last={true}
        next={'Next'}
        prev={'Prev'}
        bsSize="medium"
        maxButtons={6}
        {...props}
      />
    );
  }

}

export class TablePaginationCursor extends React.Component {

  static propTypes = {
    onSelect: propTypes.func.isRequired,
    lastValueInSet: propTypes.string,
  }

  handleClickFirstPage = ev => {
    ev.preventDefault();
    this.props.onSelect(null);
  }

  handleClickNextPage = ev => {
    ev.preventDefault();
    const { lastValueInSet } = this.props;
    this.props.onSelect(lastValueInSet || null);
  }

  render() {
    const { lastValueInSet } = this.props;
    return (
      <ul className="pagination">
        <li>
          <a
            onClick={this.handleClickFirstPage}
            role="button"
            href="#/"
          >
            « First page
          </a>
        </li>
        {lastValueInSet && (
          <li>
            <a
              onClick={this.handleClickNextPage}
              role="button"
              href="#/"
            >
              Next page ›
            </a>
          </li>
        )}
      </ul>
    );
  }

}

export class TableShowCount extends React.Component {

  static propTypes = {
    limit: propTypes.number,
    count: propTypes.number,
    pluralization: propTypes.array
  }

  static defaultProps = {
    pluralization: []
  }

  renderPluralize() {
    const { count } = this.props;
    let [singular, plural] = this.props.pluralization;
    if(!singular) singular = 'item';
    if(!plural) plural = 'items';
    return <Pluralize count={count} singular={singular} plural={plural} />
  }

  render() {
    const { limit, count } = this.props;
    if(!_.isNumber(limit) || !_.isNumber(count)) return null;
    const howMany = Math.min(limit, count);
    return (
      <span>
        Showing {howMany} of {count} {this.renderPluralize()}
      </span>
    );
  }

}

export class TableUserCell extends React.Component {

  static propTypes = {
    user: propTypes.shape({
      id: propTypes.string,
      displayName: propTypes.string,
      gravatarUrl: propTypes.string,
    }),
  }

  static defaultProps = {
    user: {displayName: '-', id: '-'},
  }

  render() {
    const { className } = this.props;
    const user = this.props.user || {displayName: '-', id: '-'};
    const { displayName, id } = user;
    return (
      <td className={classNames('user-avatar user-info cell-detail', className)}>
        <Gravatar user={user} />
        <span>{displayName}</span>
        <span className="cell-detail-description">{id}</span>
      </td>
    );
  }

}

export class TablePanelColumnSelectorDropdown extends React.Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    onSetColumns: propTypes.func.isRequired,
    columns: propTypes.array.isRequired,
  }

  static defaultProps = {
    columns: [],
  }

  handleClickColumn = (ev, id, visible) => {
    const { columns } = this.props;
    ev.preventDefault();
    const index = _.findIndex(columns, {id});
    if(index === -1) return;
    const newColumns = _.cloneDeep(columns);
    newColumns.splice(index, 1, {
      ...columns[index], visible: !visible
    });
    this.props.onSetColumns(newColumns);
  }

  render() {
    const { id, columns } = this.props;
    return (
      <Dropdown id={id} className="tools" pullRight>
        <Dropdown.Toggle noCaret useAnchor>
          <span className="icon mdi mdi-reader"></span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <li className="dropdown-header">Visible columns</li>
          {columns.map(({id, name, visible}) => (
            <li key={id} className={classNames(visible ? 'active' : '')}>
              <a key={id} href="#" onClick={ev => this.handleClickColumn(ev, id, visible)}>
                {name}
              </a>
            </li>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
