import * as actionCreators from 'client/redux/endpoint/topdesk/actionCreators';
import { ENDPOINT_TOPDESK_PRELOADED, ENDPOINT_TOPDESK_READ, ENDPOINT_TOPDESK_EDIT_CREDENTIALS, ENDPOINT_TOPDESK_UPDATE_CREDENTIALS, ENDPOINT_TOPDESK_TEST_CREDENTIALS } from 'client/redux/endpoint/topdesk/constants';
import { endpointPreloaded } from 'client/redux/endpoint/actionCreators';

export const endpointTopdeskPreloaded = endpoint => dispatch => {
  return dispatch(endpointPreloaded(ENDPOINT_TOPDESK_PRELOADED, endpoint));
};

export const endpointTopdeskRead = id => dispatch => {
  return dispatch(actionCreators.endpointTopdeskRead(ENDPOINT_TOPDESK_READ, id));
};

export const endpointTopdeskEditCredentials = isEditing => dispatch => {
  return dispatch(actionCreators.endpointTopdeskEditCredentials(ENDPOINT_TOPDESK_EDIT_CREDENTIALS, isEditing));
};

export const endpointTopdeskUpdateCredentials = (id, credentials) => dispatch => {
  return dispatch(actionCreators.endpointTopdeskUpdateCredentials(ENDPOINT_TOPDESK_UPDATE_CREDENTIALS, id, credentials));
};

export const endpointTopdeskTestCredentials = (id) => dispatch => {
  return dispatch(actionCreators.endpointTopdeskTestCredentials(ENDPOINT_TOPDESK_TEST_CREDENTIALS, id));
};
