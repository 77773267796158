import {
  ACCOUNT_CHILDREN_READ,
  ACCOUNT_CHILDREN_DELETE,
} from 'client/redux/account/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
  accountChildren: [],
  isLoading: false,
  isDeletingById: {},
};

export default function childrenReducer (state = defaultState, action) {
  state = reduceByTypes(ACCOUNT_CHILDREN_READ, state, action, {requestProp: 'isLoading', successSpread: true});
  state = reduceByTypes(ACCOUNT_CHILDREN_DELETE, state, action, {
    requestProp: (state, action, isRequesting) => ({
      ...state,
      isDeletingById: {
        ...state.isDeletingById,
        [action.meta.childUserId]: isRequesting,
      },
    }),
  });
  return state;
};
