import { apiAction } from 'client/redux/apiHelpers';

export const supportQuestionsFilter = (type, filter = {}) => {
  return {type, filter};
};

export const supportQuestions = types => {
  return apiAction({
    endpoint: '/api/support/questions',
    method: 'GET',
    types
  });
};

export const supportCategoryCreate = types => {
  return apiAction({
    endpoint: '/api/support/category',
    method: 'POST',
    json: {},
    types
  });
};

export const supportCategoryUpdate = (types, id, form) => {
  return apiAction({
    endpoint: `/api/support/category/${id}`,
    method: 'PATCH',
    json: form,
    types
  });
};

export const supportCategoryDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/support/category/${id}`,
    method: 'DELETE',
    json: {},
    types
  });
};

export const supportQuestionCreate = (types, categoryId) => {
  return apiAction({
    endpoint: `/api/support/category/${categoryId}/question`,
    method: 'POST',
    json: {},
    types
  });
};

export const supportQuestionUpdate = (types, id, form) => {
  return apiAction({
    endpoint: `/api/support/question/${id}`,
    method: 'PATCH',
    json: form,
    types
  });
};

export const supportQuestionDelete = (types, id) => {
  return apiAction({
    endpoint: `/api/support/question/${id}`,
    method: 'DELETE',
    json: {},
    types
  });
};
