import React, { Component } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

export class ButtonWaiting extends Component {

  static propTypes = {
    isWaiting: propTypes.bool,
    onClick: propTypes.func,
  }

  handleClick = ev => {
    const { onClick, isWaiting } = this.props;
    if(isWaiting) return false;
    return onClick ? onClick(ev) : undefined;
  }

  render() {
    const classes = classNames(this.props.className, 'btn');
    const props = {...this.props};
    delete props.isWaiting;
    return (
      <button type="button" {...props} onClick={this.handleClick} className={classes}>
        {this.props.children}
        {this.props.isWaiting && <i className="icon icon-right mdi mdi-spinner xs-ml-5 xs-mr-0 be-spinner-icon spinning"></i>}
      </button>
    );
  }
}

export class ButtonWaitingPromise extends Component {

  static propTypes = {
    onClick: propTypes.func
  }

  static defaultProps = {
    onClick: function() {}
  }

  state = {
    isWaiting: false
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  handleClick = ev => {
    this.setState({isWaiting: true}, () => {
      const promise = this.props.onClick(ev);
      if(!promise || !promise.then) {
        this.setState({isWaiting: false});
        return;
      }
      promise.then(() => {
        // a bit hackish...
        if(!this.willUnmount) this.setState({isWaiting: false});
      });
    });
  }

  render() {
    const props = {...this.props};
    delete props.onClick;
    delete props.children;
    return (
      <ButtonWaiting {...props} isWaiting={this.state.isWaiting} onClick={this.handleClick}>
        {this.props.children}
      </ButtonWaiting>
    );
  }

}
