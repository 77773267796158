import qs from 'qs';
import url from 'url';

export const parseQuerystring = (query, options = {}) => qs.parse(query, {
  ignoreQueryPrefix: true,
  arrayLimit: 1000,
  ...options,
});

/**
 * @desc Returns a url by looking at the redux-router location object and modifying it with a query
 * @param {Object} location
 * @param {Object} query
 * @return {String}
 */
export const formatLocationWithQuery = (location, query = {}) => {
  const uri = url.format({
    ...location,
    search: qs.stringify(query),
  });
  return uri;
};

export const appendQuery = (uri, query = {}) => {
  return url.format({
    ...url.parse(uri),
    search: qs.stringify(query),
  });
};
