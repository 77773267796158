import { ACCOUNT_BILLING_UPDATE, ACCOUNT_BILLING, ACCOUNT_ADDRESS_UPDATE } from 'client/redux/account/constants';
import * as actionCreators from 'client/redux/account/actionCreators';

export const accountBilling = () => dispatch => {
  return dispatch(actionCreators.accountBilling(ACCOUNT_BILLING));
};

export const accountBillingUpdate = form => dispatch => {
  return dispatch(actionCreators.accountUpdate(ACCOUNT_BILLING_UPDATE, form));
};

export const accountAddressUpdate = form => dispatch => {
  return dispatch(actionCreators.accountUpdate(ACCOUNT_ADDRESS_UPDATE, form));
};
