import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';
import SettingPanel from 'client/components/Settings/SettingsPanel';

export default class SettingsPanelModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    setting: propTypes.object,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired
  }

  render() {
    const { onClose, open, setting } = this.props;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>{setting.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SettingPanel id={setting.id} />
        </Modal.Body>
      </Modal>
    );
  }
}
