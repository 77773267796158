import React from 'react';
import classNames from 'classnames';
import { withRouter, matchPath } from 'react-router';
import { connect } from 'react-redux';
import AppHasLoaded from 'client/components/App/AppHasLoaded';
import { StripeContextLoader } from 'client/components/App/StripeContext';
import 'client/components/App/App.css';

class App extends React.Component {

  getColorClassName() {
    const { color } = this.props;
    switch(color) {
      default: return '';
      case 'primary': return ['be-color-header'];
      case 'success': return ['be-color-header', 'be-color-header-success'];
      case 'danger': return ['be-color-header', 'be-color-header-danger'];
      case 'warning': return ['be-color-header', 'be-color-header-warning'];
    }
  }

  getClassName() {
    const { className, location } = this.props;
    const classes = ['ln-app', 'be-wrapper', this.getColorClassName(), className];
    if(matchPath(location.pathname, {path: '/support', exact: true})) {
      classes.push('be-aside');
    }
    return classNames(classes);
  }

  render() {
    const { children } = this.props;
    return (
      <AppHasLoaded>
        <StripeContextLoader>
          <div className={this.getClassName()}>
            {children}
          </div>
        </StripeContextLoader>
      </AppHasLoaded>
    );
  }

}

export default withRouter(connect(state => state.navbar.color)(App));
