import { INSTANCE_VIEW_READ, INSTANCE_SETUP_UPDATE_STATUS  } from 'client/redux/instance/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isUpdating: false,
  instance: null,
  isParentInstance: false,
};

export default function viewReducer(state = defaultState, action) {
  state = reduceByTypes(INSTANCE_VIEW_READ, state, action, {
    requestProp: 'isReading',
    successPickProps: ['instance', 'isParentInstance'],
  });
  state = reduceByTypes(INSTANCE_SETUP_UPDATE_STATUS, state, action, {
    requestProp: 'isUpdating',
    successSpread: true,
    successPickProps: ['instance'],
  });
  return state;
};
