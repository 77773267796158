import {
  INSTANCE_QRTD_OPTIONS_READ_META,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS,
  INSTANCE_QRTD_OPTIONS_SET_SELECTED_FIELD_MAP_ORDINAL,
  INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_READ,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE,
  INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE,
} from 'client/redux/qrtd/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReadingMeta: false,
  errorMeta: null,
  isCreatingFieldMap: false,
  isDeletingFieldMap: false,
  isUpdatingFieldMap: false,
  isReadingInfoTexts: false,
  isCreatingInfoText: false,
  isDeletingInfoText: false,
  isUpdatingInfoText: false,
  infoTexts: [],
  errorFieldMap: null,
  errorInfoText: null,
  incidentDefaultFieldMap: null,
  incidentFieldMaps: [],
  selectedFieldMapOrdinal: '0',
};

export default function optionsReducer(state = defaultState, action) {

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_READ_META, state, action, {
    requestProp: 'isReadingMeta',
    errorProp: 'errorMeta',
    successPickProps: ['incidentDefaultFieldMap', 'incidentFieldMaps', 'infoTexts'],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE, state, action, {
    requestProp: 'isCreatingFieldMap',
    errorProp: 'errorFieldMap',
    successPickProps: ['incidentFieldMaps'],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_FIELDMAP_UPDATE, state, action, {
    requestProp: 'isUpdatingFieldMap',
    errorProp: 'errorFieldMap',
    successPickProps: ['incidentFieldMaps'],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE, state, action, {
    requestProp: 'isDeletingFieldMap',
    errorProp: 'errorFieldMap',
    successPickProps: ['incidentFieldMaps'],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_INFOTEXT_READ, state, action, {
    requestProp: 'isReadingInfoTexts',
    errorProp: 'errorInfoText',
    successPickProps: ['infoTexts'],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_INFOTEXT_CREATE, state, action, {
    requestProp: 'isCreatingInfoText',
    errorProp: null,
    successPickProps: [],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_INFOTEXT_UPDATE, state, action, {
    requestProp: 'isUpdatingInfoText',
    errorProp: null,
    successPickProps: [],
  });

  state = reduceByTypes(INSTANCE_QRTD_OPTIONS_INFOTEXT_DELETE, state, action, {
    requestProp: 'isDeletingInfoText',
    errorProp: 'errorInfoText',
    successPickProps: [],
  });

  switch(action.type) {
    default: return state;
    case INSTANCE_QRTD_OPTIONS_FIELDMAP_DELETE_SUCCESS: return {
      ...state,
      selectedFieldMapOrdinal: '0',
    };
    case INSTANCE_QRTD_OPTIONS_FIELDMAP_CREATE_SUCCESS: {
      const newOrdinal = _.get(action, 'payload.newOrdinal') || '0';
      return {
        ...state,
        selectedFieldMapOrdinal: newOrdinal,
      }
    };
    case INSTANCE_QRTD_OPTIONS_SET_SELECTED_FIELD_MAP_ORDINAL: return {
      ...state,
      selectedFieldMapOrdinal: action.ordinal,
    };
  }
};
