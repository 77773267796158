import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const instanceMwtdReadStatistics = (types, id, statistic, query) => {
  return apiAction({
    endpoint: appendQuery(`/api/instance/mwtd/${id}/statistics/${statistic}`, query),
    method: 'GET',
    types: types.map(type => ({type, meta: {statistic}}))
  });
};

export const instanceMwtdReadMeta = (types, id) => {
  return apiAction({
    endpoint: `/api/instance/mwtd/${id}/meta`,
    method: 'GET',
    types: types.map(type => ({type, meta: {id}})),
  });
};

export const instanceMwtdTestDeviceAdd = (types, id, deviceId) => {
  const meta = {deviceId};
  return apiAction({
    endpoint: `/api/instance/mwtd/${id}/test/device/${deviceId}`,
    method: 'GET',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceMwtdTestDevice = (types, id, deviceId) => {
  const meta = {deviceId, sync: false};
  return apiAction({
    endpoint: `/api/instance/mwtd/${id}/test/device/${deviceId}`,
    method: 'POST',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceMwtdSyncDevice = (types, id, deviceId) => {
  const meta = {deviceId, sync: true};
  return apiAction({
    endpoint: `/api/instance/mwtd/${id}/sync/device/${deviceId}`,
    method: 'POST',
    types: types.map(type => ({type, meta}))
  });
};

export const instanceMwtdTestDeviceRemove = (type, deviceId) => {
  return {type, meta: {deviceId}};
};

export const instanceMwtdTestClear = type => {
  return {type};
};
