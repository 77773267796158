import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalClose } from 'client/components/Helpers/Modal';
import propTypes from 'prop-types';
import PlanPanel from 'client/components/Plan/PlanPanel';

export default class PanelModal extends Component {

  static defaultProps = {
    open: false
  }

  static propTypes = {
    plan: propTypes.object,
    open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    onPlanWasUpdated: propTypes.func
  }

  render() {
    const { onClose, open, plan, onPlanWasUpdated } = this.props;
    const { name, stripeId } = plan;
    return (
      <Modal dialogClassName="colored-header colored-header-primary" show={open} onHide={onClose} backdrop="static">
        <Modal.Header className="modal-header">
          <ModalClose onClose={onClose} />
          <Modal.Title>{name} ({stripeId})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlanPanel id={plan.id} onUpdated={onPlanWasUpdated} />
        </Modal.Body>
      </Modal>
    );
  }

}
