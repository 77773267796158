export const USER_INDEX_REQUEST = 'USER_INDEX_REQUEST';
export const USER_INDEX_SUCCESS = 'USER_INDEX_SUCCESS';
export const USER_INDEX_FAILURE = 'USER_INDEX_FAILURE';
export const USER_INDEX = [USER_INDEX_REQUEST, USER_INDEX_SUCCESS, USER_INDEX_FAILURE];

export const USER_INDEX_SET_COLUMNS = 'USER_INDEX_SET_COLUMNS';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';
export const USER_DELETE = [USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAILURE];

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_UPDATE = [USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE];

export const USER_PANEL_READ_REQUEST = 'USER_PANEL_READ_REQUEST';
export const USER_PANEL_READ_SUCCESS = 'USER_PANEL_READ_SUCCESS';
export const USER_PANEL_READ_FAILURE = 'USER_PANEL_READ_FAILURE';
export const USER_PANEL_READ = [USER_PANEL_READ_REQUEST, USER_PANEL_READ_SUCCESS, USER_PANEL_READ_FAILURE];

export const USER_PANEL_UPDATE_REQUEST = 'USER_PANEL_UPDATE_REQUEST';
export const USER_PANEL_UPDATE_SUCCESS = 'USER_PANEL_UPDATE_SUCCESS';
export const USER_PANEL_UPDATE_FAILURE = 'USER_PANEL_UPDATE_FAILURE';
export const USER_PANEL_UPDATE = [USER_PANEL_UPDATE_REQUEST, USER_PANEL_UPDATE_SUCCESS, USER_PANEL_UPDATE_FAILURE];

export const USER_PANEL_CREATE_REQUEST = 'USER_PANEL_CREATE_REQUEST';
export const USER_PANEL_CREATE_SUCCESS = 'USER_PANEL_CREATE_SUCCESS';
export const USER_PANEL_CREATE_FAILURE = 'USER_PANEL_CREATE_FAILURE';
export const USER_PANEL_CREATE = [USER_PANEL_CREATE_REQUEST, USER_PANEL_CREATE_SUCCESS, USER_PANEL_CREATE_FAILURE];

export const USER_PANEL_CALL_REQUEST = 'USER_PANEL_CALL_REQUEST';
export const USER_PANEL_CALL_SUCCESS = 'USER_PANEL_CALL_SUCCESS';
export const USER_PANEL_CALL_FAILURE = 'USER_PANEL_CALL_FAILURE';
export const USER_PANEL_CALL = [USER_PANEL_CALL_REQUEST, USER_PANEL_CALL_SUCCESS, USER_PANEL_CALL_FAILURE];

export const roleSelect2 = [
  {id: 'nobody', text: 'Nobody'},
  {id: 'users', text: 'User'},
  {id: 'partners', text: 'Partner'},
  {id: 'admins', text: 'Admin'},
];

export const isConfirmedSelect2 = [
  {id: false, text: 'Not confirmed'},
  {id: true, text: 'Confirmed'},
];

export const hideBillingSelect2 = [
  {id: false, text: 'Available'},
  {id: true, text: 'Hidden'},
];

export const paymentMethodSelect2 = [
  {id: 'automatic', text: 'Automatic'},
  {id: 'manual', text: 'Manual'},
];

export const paranoidSelect2 = [
  {id: '0', text: 'Include archived'},
  {id: '1', text: 'Hide archived'},
];
