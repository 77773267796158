import { AUTH_ERROR } from 'client/redux/appError/constants';
import { redirectAndFlash } from 'client/redux/flashAlert/actions';

const errorRouter = store => dispatch => action => {
  switch(action.type) {
    default: return dispatch(action);
    case AUTH_ERROR:
      dispatch(action);
      return redirectAndFlash('/login', {
        alertType: 'warning',
        strong: 'Unauthenticated',
        msg: 'Please login to see this resource'
      })(dispatch);
  }
};

export default errorRouter;
