import _ from 'lodash';
import * as constants from 'client/redux/qrtd/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultImageState = {
  isReading: false,
  isDeleting: false,
  imageUrl: null,
  uploadError: null,
  error: null,
};

function reduceImage(state = defaultImageState, action) {
  state = reduceByTypes(constants.INSTANCE_QRTD_IMAGE_UPLOAD_READ, state, action, {requestProp: 'isReading', successPickProps: ['imageUrl']});
  state = reduceByTypes(constants.INSTANCE_QRTD_IMAGE_UPLOAD_DELETE, state, action, {requestProp: 'isDeleting', successPickProps: ['imageUrl']});
  switch(action.type) {
    default: return state;
    case constants.INSTANCE_QRTD_IMAGE_UPLOAD_ERROR: return {
      ...state,
      uploadError: action.error,
    };
    case constants.INSTANCE_QRTD_IMAGE_UPLOAD_SUCCESS: return {
      ...state,
      imageUrl: _.get(action, 'response.data.imageUrl') || null,
    };
  }
}

const defaultState = {
  imagesByType: {},
};

export default function imageUploadReducer(state = defaultState, action) {

  const imageActions = [
    ...constants.INSTANCE_QRTD_IMAGE_UPLOAD_READ,
    ...constants.INSTANCE_QRTD_IMAGE_UPLOAD_DELETE,
    constants.INSTANCE_QRTD_IMAGE_UPLOAD_SUCCESS,
    constants.INSTANCE_QRTD_IMAGE_UPLOAD_ERROR,
  ];

  if(imageActions.includes(action.type)) {
    const { imageType } = action.meta;
    return {
      ...state,
      imagesByType: {
        ...state.imagesByType,
        [imageType]: reduceImage(state.imagesByType[imageType], action),
      },
    };
  }

  return state;
};
