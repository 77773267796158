export const INSTANCE_FSTD_READ_STATISTICS_REQUEST = 'INSTANCE_FSTD_READ_STATISTICS_REQUEST';
export const INSTANCE_FSTD_READ_STATISTICS_SUCCESS = 'INSTANCE_FSTD_READ_STATISTICS_SUCCESS';
export const INSTANCE_FSTD_READ_STATISTICS_FAILURE = 'INSTANCE_FSTD_READ_STATISTICS_FAILURE';
export const INSTANCE_FSTD_READ_STATISTICS = [INSTANCE_FSTD_READ_STATISTICS_REQUEST, INSTANCE_FSTD_READ_STATISTICS_SUCCESS, INSTANCE_FSTD_READ_STATISTICS_FAILURE];

export const INSTANCE_FSTD_READ_META_REQUEST = 'INSTANCE_FSTD_READ_META_REQUEST';
export const INSTANCE_FSTD_READ_META_SUCCESS = 'INSTANCE_FSTD_READ_META_SUCCESS';
export const INSTANCE_FSTD_READ_META_FAILURE = 'INSTANCE_FSTD_READ_META_FAILURE';
export const INSTANCE_FSTD_READ_META = [INSTANCE_FSTD_READ_META_REQUEST, INSTANCE_FSTD_READ_META_SUCCESS, INSTANCE_FSTD_READ_META_FAILURE];

export const INSTANCE_FSTD_READ_TEST_FILE_REQUEST = 'INSTANCE_FSTD_READ_TEST_FILE_REQUEST';
export const INSTANCE_FSTD_READ_TEST_FILE_SUCCESS = 'INSTANCE_FSTD_READ_TEST_FILE_SUCCESS';
export const INSTANCE_FSTD_READ_TEST_FILE_FAILURE = 'INSTANCE_FSTD_READ_TEST_FILE_FAILURE';
export const INSTANCE_FSTD_READ_TEST_FILE = [INSTANCE_FSTD_READ_TEST_FILE_REQUEST, INSTANCE_FSTD_READ_TEST_FILE_SUCCESS, INSTANCE_FSTD_READ_TEST_FILE_FAILURE];

export const INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS = 'INSTANCE_FSTD_TEST_FILE_UPLOAD_SUCCESS';
export const INSTANCE_FSTD_TEST_FILE_UPLOAD_ERROR = 'INSTANCE_FSTD_TEST_FILE_UPLOAD_ERROR';

export const INSTANCE_FSTD_DELETE_TEST_FILE_REQUEST = 'INSTANCE_FSTD_DELETE_TEST_FILE_REQUEST';
export const INSTANCE_FSTD_DELETE_TEST_FILE_SUCCESS = 'INSTANCE_FSTD_DELETE_TEST_FILE_SUCCESS';
export const INSTANCE_FSTD_DELETE_TEST_FILE_FAILURE = 'INSTANCE_FSTD_DELETE_TEST_FILE_FAILURE';
export const INSTANCE_FSTD_DELETE_TEST_FILE = [INSTANCE_FSTD_DELETE_TEST_FILE_REQUEST, INSTANCE_FSTD_DELETE_TEST_FILE_SUCCESS, INSTANCE_FSTD_DELETE_TEST_FILE_FAILURE];

export const INSTANCE_FSTD_FILE_TEST_REQUEST = 'INSTANCE_FSTD_FILE_TEST_REQUEST';
export const INSTANCE_FSTD_FILE_TEST_SUCCESS = 'INSTANCE_FSTD_FILE_TEST_SUCCESS';
export const INSTANCE_FSTD_FILE_TEST_FAILURE = 'INSTANCE_FSTD_FILE_TEST_FAILURE';
export const INSTANCE_FSTD_FILE_TEST = [INSTANCE_FSTD_FILE_TEST_REQUEST, INSTANCE_FSTD_FILE_TEST_SUCCESS, INSTANCE_FSTD_FILE_TEST_FAILURE];

export const INSTANCE_FSTD_FIELDMAPPER_NEW = 'INSTANCE_FSTD_FIELDMAPPER_NEW';
export const INSTANCE_FSTD_FIELDMAPPER_NEW_DELETE = 'INSTANCE_FSTD_FIELDMAPPER_NEW_DELETE';

export const INSTANCE_FSTD_FIELDMAPPER_EDIT = 'INSTANCE_FSTD_FIELDMAPPER_EDIT';

export const INSTANCE_FSTD_FIELDMAPPER_READ_REQUEST = 'INSTANCE_FSTD_FIELDMAPPER_READ_REQUEST';
export const INSTANCE_FSTD_FIELDMAPPER_READ_SUCCESS = 'INSTANCE_FSTD_FIELDMAPPER_READ_SUCCESS';
export const INSTANCE_FSTD_FIELDMAPPER_READ_FAILURE = 'INSTANCE_FSTD_FIELDMAPPER_READ_FAILURE';
export const INSTANCE_FSTD_FIELDMAPPER_READ = [INSTANCE_FSTD_FIELDMAPPER_READ_REQUEST, INSTANCE_FSTD_FIELDMAPPER_READ_SUCCESS, INSTANCE_FSTD_FIELDMAPPER_READ_FAILURE];

export const INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_REQUEST = 'INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_REQUEST';
export const INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_SUCCESS = 'INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_SUCCESS';
export const INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_FAILURE = 'INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_FAILURE';
export const INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS = [INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_REQUEST, INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_SUCCESS, INSTANCE_FSTD_FIELDMAPPER_READ_FIELDTARGETS_FAILURE];

export const INSTANCE_FSTD_FIELDMAPPER_SAVE_REQUEST = 'INSTANCE_FSTD_FIELDMAPPER_SAVE_REQUEST';
export const INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS = 'INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS';
export const INSTANCE_FSTD_FIELDMAPPER_SAVE_FAILURE = 'INSTANCE_FSTD_FIELDMAPPER_SAVE_FAILURE';
export const INSTANCE_FSTD_FIELDMAPPER_SAVE = [INSTANCE_FSTD_FIELDMAPPER_SAVE_REQUEST, INSTANCE_FSTD_FIELDMAPPER_SAVE_SUCCESS, INSTANCE_FSTD_FIELDMAPPER_SAVE_FAILURE];

export const INSTANCE_FSTD_FIELDMAPPER_DELETE_REQUEST = 'INSTANCE_FSTD_FIELDMAPPER_DELETE_REQUEST';
export const INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS = 'INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS';
export const INSTANCE_FSTD_FIELDMAPPER_DELETE_FAILURE = 'INSTANCE_FSTD_FIELDMAPPER_DELETE_FAILURE';
export const INSTANCE_FSTD_FIELDMAPPER_DELETE = [INSTANCE_FSTD_FIELDMAPPER_DELETE_REQUEST, INSTANCE_FSTD_FIELDMAPPER_DELETE_SUCCESS, INSTANCE_FSTD_FIELDMAPPER_DELETE_FAILURE];
