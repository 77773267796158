import React, { Component } from 'react';
import { MainContent, Content } from 'client/components/App';
import { Helmet } from 'react-helmet';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import { connect } from 'react-redux';
import * as indexActions from 'client/redux/settings/indexActions';
import SettingsTable from 'client/components/Settings/components/SettingsTable';

class SettingsIndex extends Component {

  componentDidMount() {
    return this.getData();
  }

  getData = () => {
    return this.props.settingsIndex();
  }

  renderTitle() {
    return (
      <Helmet>
        <title>Settings Overview</title>
      </Helmet>
    );
  }

  renderError(error) {
    return (
      <Content>
        {this.renderTitle()}
        <MainContent>
          <BeRemoteErrorAlert error={error} />
        </MainContent>
      </Content>
    );
  }

  render() {
    const { isReading, error, settings } = this.props;
    if(error) return this.renderError(error);
    return (
      <Content>
        <MainContent>
          {this.renderTitle()}
          <SettingsTable
            loading={isReading}
            settings={settings}
            onRefresh={this.getData}
          />
        </MainContent>
      </Content>
    );
  }

}

export default connect(state => state.settings.index, indexActions)(SettingsIndex);
