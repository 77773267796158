import { connect } from 'react-redux';
import ConnectableInstanceWidgetStatistic from 'client/components/Instance/components/ConnectableInstanceWidgetStatistic';
import { instanceSwtdReadStatistics } from 'client/redux/swtd/statisticsActions';
import _ from 'lodash';

const mapStateToProps =  (state, props) => {
  const id = props.statistic;
  return {
    ...(state.swtd.statistics[id] || {}),
    data: _.get(state, ['swtd', 'statistics', id, 'data', id], null)
  };
};

const actions = {
  readStatistic: instanceSwtdReadStatistics,
};

export default connect(mapStateToProps, actions)(ConnectableInstanceWidgetStatistic);
