import React  from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import Icon from 'client/components/Be/Icon';
import { ModalConfirmButton, ModalContentConfirmWarning } from 'client/components/Helpers/Modal';
import Select2Wrap from 'client/components/Form/Select2Wrap';
import InstanceHelpHeader from 'client/components/Instance/components/InstanceHelpHeader';
import QRTDOptionsFormFieldMapModal from 'client/components/QRTD/QRTDOptionsFormFieldMapModal';
import BeRemoteErrorAlert from 'client/components/Be/BeRemoteErrorAlert';
import BeLoading from 'client/components/Be/BeLoading';
import { topdeskFieldNameToNatural } from 'client/redux/field/constants';
import FieldMapTargetFields from 'client/components/FieldMap/FieldMapTargetFields';

export default class QRTDOptionsIncidentFieldMaps extends React.Component {

  static propTypes = {
    disabled: propTypes.bool.isRequired,
    settingsFormIsDirty: propTypes.bool.isRequired,
    isCreatingFieldMap: propTypes.bool.isRequired,
    isUpdatingFieldMap: propTypes.bool.isRequired,
    isDeletingFieldMap: propTypes.bool.isRequired,
    onCreateFieldMap: propTypes.func.isRequired,
    onDeleteFieldMap: propTypes.func.isRequired,
    onUpdateFieldMap: propTypes.func.isRequired,
    selectedFieldMapOrdinal: propTypes.string.isRequired,
    onChangeSelectedFieldMapOrdinal: propTypes.func.isRequired,
  }

  static defaultProps = {
    changeDisabled: false,
    selectedFieldMapOrdinal: '0',
  }

  state = {
    createModalIsOpen: false,
    editModalIsOpen: false,
  }

  getInitialValuesForEditModal() {
    const { incidentFieldMaps = [], selectedFieldMapOrdinal } = this.props;
    const fieldMap = incidentFieldMaps.find(o => o.ordinal === selectedFieldMapOrdinal);
    if(!fieldMap) return {};
    const { title } = fieldMap;
    return {title};
  }

  handleChangeActiveFieldMap = ev => {
    this.props.onChangeSelectedFieldMapOrdinal(ev.target.value);
  }

  handleCreateFieldMap = form => {
    this.props.onCreateFieldMap(form).then(() => {
      this.toggleCreateModal(false);
    });
  }

  handleUpdateFieldMap = form => {
    return this.props.onUpdateFieldMap(form).then(() => {
      this.toggleEditModal(false);
    });
  }

  handleDeleteFieldMap = () => {
    const ordinal = this.props.selectedFieldMapOrdinal;
    return this.props.onDeleteFieldMap(ordinal);
  }

  toggleEditModal = to => {
    this.setState({editModalIsOpen: to});
  }

  toggleCreateModal = to => {
    this.setState({createModalIsOpen: to});
  }

  renderDeleteButton() {
    const { disabled, selectedFieldMapOrdinal, settingsFormIsDirty } = this.props;
    const confirmProps = {
      title: 'Confirm deletion',
      text: 'Any QR-codes that you have printed pointing to this incident form will point to the default form',
    };
    return (
      <ModalConfirmButton
        className="btn btn-default"
        callback={this.handleDeleteFieldMap}
        contentProps={confirmProps}
        Content={ModalContentConfirmWarning}
        title="Delete this QR-code incident form"
        disabled={disabled || settingsFormIsDirty || isDefaultFieldMap(selectedFieldMapOrdinal)}
      >
        <Icon icon="delete" />
      </ModalConfirmButton>
    );
  }

  getSelectedFieldMap() {
    const { selectedFieldMapOrdinal, incidentDefaultFieldMap, incidentFieldMaps } = this.props;
    if(isDefaultFieldMap(selectedFieldMapOrdinal) || !incidentFieldMaps) {
      return {fieldMap: incidentDefaultFieldMap, fieldMapKey: 'incidentFieldMap'};
    }

    const incidentFieldMap = incidentFieldMaps.find(o => o.ordinal === selectedFieldMapOrdinal);
    if(!incidentFieldMap || !incidentFieldMap.fieldMap) {
      return {fieldMap: null, fieldMapKey: null};
    }

    const { fieldMap, ordinal } = incidentFieldMap;
    return {fieldMap, fieldMapKey: `fieldMaps.${ordinal}.map`};
  }

  render() {
    const { createModalIsOpen, editModalIsOpen } = this.state;
    const {
      disabled,
      endpointsByType,
      errorMeta,
      initialValues,
      isCreatingFieldMap,
      isReadingMeta,
      isUpdatingFieldMap,
      incidentFieldMaps,
      meta,
      selectedFieldMapOrdinal,
      settingsFormIsDirty,
    } = this.props;
    const { fieldMap, fieldMapKey } = this.getSelectedFieldMap();
    return (
      <div className="well xs-mb-10">
        <InstanceHelpHeader
          subTitle="Mapping from 'Report Incident' forms to TOPdesk Incidents"
          helpFragmentId="instance_qrtd_settings_incident_fieldmap_help"
        >
          Field Map for new Incidents
        </InstanceHelpHeader>
        <div className="form-group">
          <label htmlFor="qrtdOptionsSelectCurrentFieldMap">QR-code incident form</label>
          <div className="input-group">
            <Select2Wrap
              id="qrtdOptionsSelectCurrentFieldMap"
              name="selectedFieldMapOrdinal"
              className="form-control"
              data={getFieldMapSelect2List(incidentFieldMaps)}
              style={{width: '100%'}}
              defaultValue={selectedFieldMapOrdinal}
              onChange={this.handleChangeActiveFieldMap}
              disabled={settingsFormIsDirty}
            />
            <div className="input-group-btn">
              <button
                type="button"
                className="btn btn-default"
                title="Change the title of this QR-code incident form"
                onClick={() => this.toggleEditModal(true)}
                disabled={disabled || settingsFormIsDirty || isDefaultFieldMap(selectedFieldMapOrdinal)}
              >
                <Icon icon="edit" />
              </button>
              {editModalIsOpen && <QRTDOptionsFormFieldMapModal
                open={editModalIsOpen}
                onClose={() => this.toggleEditModal(false)}
                onSave={this.handleUpdateFieldMap}
                isSaving={isUpdatingFieldMap}
                initialValues={this.getInitialValuesForEditModal()}
              />}
              {this.renderDeleteButton()}
              <button
                type="button"
                className="btn btn-default"
                title="Create a new QR-code incident form"
                onClick={() => this.toggleCreateModal(true)}
                disabled={disabled || settingsFormIsDirty}
              >
                <Icon icon="plus" />
              </button>
              <QRTDOptionsFormFieldMapModal
                open={createModalIsOpen}
                onClose={() => this.toggleCreateModal(false)}
                onSave={this.handleCreateFieldMap}
                isSaving={isCreatingFieldMap}
                isNew
              />
            </div>
          </div>
          <p className="help-block">
            Save before creating a new incident form or changing the title
          </p>
        </div>
        <BeRemoteErrorAlert error={errorMeta} />
        <BeLoading loading={isReadingMeta} className="xs-mt-20 form-horizontal">
          <IncidentFieldMapTargets
            disabled={disabled}
            endpointsByType={endpointsByType}
            meta={meta}
            initialValues={initialValues}
            fieldMap={fieldMap}
            fieldMapKey={fieldMapKey}
          />
        </BeLoading>
      </div>
    );
  }
}

class IncidentFieldMapTargets extends React.Component {

  static propTypes = {
    fieldMap: propTypes.object,
    fieldMapKey: propTypes.string,
  }

  render() {
    const {
      endpointsByType: { topdesk: { id: topdeskEndpointId }},
      disabled,
      fieldMap,
      fieldMapKey,
    } = this.props;
    const endpointIds = {topdeskId: topdeskEndpointId};
    const availableSources = [
      {type: 'FieldSourceResolve'},
      {
        type: 'FieldSourceObject',
        contextKeys: ['asset'],
        columns: [
          'name',
          'unid',
          'type_id',
          'status',
          'archived',
        ],
      },
      {type: 'FieldSourceUserInput'},
      {type: 'FieldSourceUserSelect'},
      {type: 'FieldSourceUserSelectFromResolver'},
    ];
    return (
      <FieldMapTargetFields
        fieldMap={fieldMap}
        fieldName={fieldMapKey}
        fieldBase="qrtdOptionsIncidentFieldMap"
        disabled={disabled}
        availableSources={availableSources}
        getLabel={topdeskFieldNameToNatural}
        endpointIds={endpointIds}
      />
    );
  }

}

function isDefaultFieldMap(ordinal) {
  return !ordinal || ordinal === '0';
}

export function getFieldMapSelect2List(incidentFieldMaps) {
  const list = [
    {id: 0, text: 'Default'},
    ..._.map(incidentFieldMaps, (obj, ordinal) => {
      return {id: obj.ordinal || ordinal, text: obj.title};
    }),
  ];
  return list;
}
