import React from 'react';
import propTypes from 'prop-types';
import { HelpButton } from 'client/components/Helpers/Help';
import UIFragmentHelpModal from 'client/components/UIFragment/UIFragmentHelpModal';

export default class UIFragmentHelpButton extends React.Component {

  static propTypes = {
    fragmentId: propTypes.string.isRequired,
    className: propTypes.string,
  }

  render() {
    const { className, fragmentId } = this.props;
    return (
      <HelpButton
        className={className}
        HelpModal={UIFragmentHelpModal}
        helpModalProps={{fragmentId}}
      />
    );
  }

}
