import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import * as wizardHelpers from 'client/redux/wizardHelpers';
import _ from 'lodash';

export class BeWizard extends Component {

  static propTypes = {
    id: propTypes.string,
    activeStepKey: propTypes.string,
    steps: propTypes.object,
    orderedSteps: propTypes.array,
    onStepChange: propTypes.func
  }

  handleStepChange = ev => {
    const { onStepChange, activeStepKey } = this.props;
    const nextStepKey = ev.target.getAttribute('data-key');
    if(onStepChange && nextStepKey && nextStepKey !== activeStepKey) {
      onStepChange(nextStepKey)
    };
  }

  handleStepNudge = ev => {
    const { onStepChange } = this.props;
    const direction = ev.target.getAttribute('data-nudge') === 'next' ? 1 : -1;
    const nextStep = wizardHelpers.getNudgeStep(this.props, direction);
    if(onStepChange && nextStep) {
      return onStepChange(nextStep.key);
    }
  }

  renderSteps() {
    const { activeStepKey, orderedSteps } = this.props;
    return orderedSteps.map(step => {
      const onClick = !step.isDisabled ? this.handleStepChange : _.identity;
      const classes = classNames([
        step.key === activeStepKey ? 'active' : '',
        step.isComplete ? 'complete' : '',
        !step.isDisabled ? 'allowed' : ''
      ]);
      return (
        <li key={step.key} data-key={step.key} data-step={step.ord} className={classes} onClick={onClick}>
          {step.ord+1}) {step.title}<span className="chevron"></span>
        </li>
      );
    });
  }

  getStepByOrd(ord) {
    return wizardHelpers.getStepByOrd(this.props, ord);
  }

  stepIsDisabled(step) {
    if(!step) return true;
    return step.isDisabled;
  }

  stepIsComplete(step) {
    if(!step) return false;
    return step.isComplete;
  }

  renderFooter() {
    const { steps, activeStepKey, orderedSteps } = this.props;
    const activeStep = steps[activeStepKey];
    if(!activeStep) return;
    const prevStep = orderedSteps[activeStep.ord - 1];
    const nextStep = orderedSteps[activeStep.ord + 1];
    const disabledPrev = !prevStep || prevStep.isDisabled;
    const disabledNext = !nextStep || nextStep.isDisabled;
    return (
      <div className="panel-footer xs-p-10" style={{minHeight: '51px'}}>
        {!disabledPrev && <button onClick={this.handleStepNudge} data-nudge="prev" type="button" className="btn btn-default">
          <i className="icon mdi mdi-chevron-left"></i> Previous step
        </button>}
        {!disabledNext && <button onClick={this.handleStepNudge} disabled={disabledNext} data-nudge="next" type="button" className="btn btn-primary pull-right">
          Next step <i className="icon mdi mdi-chevron-right"></i>
        </button>}
        <div className="clearfix"></div>
      </div>
    );
  }

  render() {
    const props = this.props;
    const classes = classNames('row wizard-row', props.className);
    return (
      <div className={classes}>
        <div id={props.id} className="col-md-12 fuelux">
          <div className="block-wizard panel panel-default panel-border xs-mb-0">
            <div className="wizard wizard-ux">
              <ul className="steps">
                {this.renderSteps()}
              </ul>
              <div className="step-content">
                {props.children}
              </div>
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

}

export class BeWizardStep extends Component {

  static defaultProps = {
    onActive: function() {}
  }

  static propTypes = {
    stepOrd: propTypes.number.isRequired,
    stepKey: propTypes.string,
    activeStepKey: propTypes.string,
    onActive: propTypes.func
  }

  render() {
    const { stepKey, stepOrd, children, activeStepKey } = this.props;
    const active = activeStepKey === stepKey;
    const classes = classNames('step-pane', active ? 'active' : '');
    if(active) this.props.onActive(activeStepKey);
    return (
      <div data-step={stepOrd} className={classes}>
        {children}
      </div>
    );
  }

}

export class BeWizardTitle extends Component {

  static propTypes = {
    subTitle: propTypes.node,
  }

  render() {
    const { children, subTitle, className } = this.props;
    const classes = classNames(className, 'wizard-title xs-mb-20');
    return (
      <h3 className={classes}>
        {children}
        {subTitle && <span className="panel-subtitle">{subTitle}</span>}
      </h3>
    );
  }
}
