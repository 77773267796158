import React, { Component } from 'react';
import propTypes from 'prop-types';
import { PanelGroup, Panel } from 'react-bootstrap';
import { IsAdmin } from 'client/components/Helpers/IsRole';
import Highlighter from 'react-highlight-words';
import { ButtonWaitingPromise } from 'client/components/Button/ButtonWaiting';
import _ from 'lodash';
import SupportCategoryHeader from 'client/components/Support/components/SupportCategoryHeader';
import SupportQuestionPanel from 'client/components/Support/components/SupportQuestionPanel';
import { Placeholder } from 'client/components/Helpers/Strings';
import classNames from 'classnames';
import 'client/components/Support/components/SupportQuestionList.css';

export default class SupportQuestionList extends Component {

  static propTypes = {
    isAdmin: propTypes.bool,
    categories: propTypes.array,
    questionsByCategory: propTypes.object,
    hiddenQuestionsById: propTypes.object,
    filter: propTypes.object
  }

  static defaultProps = {
    filter: {},
    hiddenQuestionsById: {}
  }

  getHighlightWords() {
    let highlight = _.get(this.props, 'filter.search', '').toLowerCase();
    highlight = highlight.replace(/[^\w\s]/g, '');
    return [highlight];
  }

  renderQuestionTitle(question) {
    const { title } = question;
    const searchWords = this.getHighlightWords();
    return (
      <Panel.Title
        componentClass="h4"
        toggle
        className="support-question-list-header"
      >
        <i className="icon mdi mdi-chevron-down support-question-list-icon"></i>
        {' '}
        <Placeholder isEmpty={!question.title} placeholder={<em>(No title)</em>}>
          <Highlighter highlightClassName="highlight" searchWords={searchWords} textToHighlight={title} />
        </Placeholder>
      </Panel.Title>
    );
  }

  renderQuestions(questions = []) {
    const { hiddenQuestionsById, onUpdateQuestion, onDeleteQuestion } = this.props;
    return questions.map(question => {
      if(!question || hiddenQuestionsById[question.id]) return null;
      const panelClasses = classNames([
        'panel-border',
        'support-question-list-panel',
        question.isVisible ? '' : 'panel-border panel-border-color panel-border-color-warning',
      ]);
      return (
        <Panel
          className={panelClasses}
          key={question.id}
          eventKey={question.id}
        >
          <Panel.Heading>
            {this.renderQuestionTitle(question)}
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <SupportQuestionPanel
                question={question}
                isFirstQuestion={question.order === 0}
                isLastQuestion={question.order === questions.length - 1}
                onUpdateQuestion={onUpdateQuestion}
                onDeleteQuestion={onDeleteQuestion}
              />
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );
    });
  }

  renderCategories() {
    const { categories, questionsByCategory, isAdmin, onCreateQuestion, onDeleteCategory, onUpdateCategory } = this.props;
    return (categories || []).map(category => {
      const questions = questionsByCategory[category.id];
      const renderedQuestions = this.renderQuestions(questions).filter(n => n);
      // only show the category if it has non-filtered questions or you are an admin
      if(!renderedQuestions.length && !isAdmin) return null;
      return (
        <div key={category.id}>
          <SupportCategoryHeader
            className="xs-mb-10 xs-mt-20"
            category={category}
            onCreateQuestion={onCreateQuestion}
            onUpdateCategory={onUpdateCategory}
            onDeleteCategory={onDeleteCategory}
            isFirstCategory={category.order === 0}
            isLastCategory={category.order === categories.length - 1}
          />
          <PanelGroup id={category.id} accordion className="accordion">
            {renderedQuestions}
          </PanelGroup>
        </div>
      )
    });
  }

  render() {
    const { className, onCreateCategory } = this.props;
    return (
      <div className={className}>
        {this.renderCategories()}
        <IsAdmin>
          <div className="text-center">
            <ButtonWaitingPromise className="btn btn-default" onClick={ev => onCreateCategory()}>
              <i className="icon mdi mdi-plus"></i>{' '}
              Category
            </ButtonWaitingPromise>
          </div>
        </IsAdmin>
      </div>
    );
  }

}
